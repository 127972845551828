/* author: PAL Prashant */

import { DataGrid, GridSortDirection } from "@mui/x-data-grid";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { useEffect, useState } from "react";
import { Colors } from "./Colors";

export interface IColumn {
    field: string;
    headerName: string;
    minWidth?: number;
}

export interface IBasicTable {
    columns: IColumn[];
    apiUrl: IApiUrl;
    id: string;
    sortInfo?: ISortInfo;
    combinedIdParams: string[];
    styleAddons?: Record<string, unknown>;
}

export interface IApiUrl {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    path: any;
    params: string;
}

export interface ISortInfo {
    field: string;
    sort: GridSortDirection;
}

export const TableBasic = (props: IBasicTable): React.JSX.Element => {
    const [data, setData] = useState([]);
    const [tableHeight, setTableHeight] = useState<number>();

    useEffect(() => {
        sendGetRequest({
            path: props.apiUrl.path,
            params: { [props.apiUrl.params]: props.id },
        }).then((response) => {
            setData(response.data);
        });
    }, [props.apiUrl.path, props.id, props.apiUrl.params]);

    const styleCommon = {
        "& .MuiDataGrid-columnSeparator": {
            display: "none",
        },
        position: "sticky !important",
        top: "0",
        margin: "0.7rem",
        overflow: "auto",
        border: "none",
        height: "100%",
        backgroundColor: Colors.grey,
        padding: "10px",

        "& .MuiDataGrid-row": {
            borderBottom: "1px solid" + Colors.borderDark,
            backgroundColor: Colors.white,
            maxHeight: "none !important",
            minHeight: "unset !important",
            width: "100%",
        },

        "& .MuiDataGrid-virtualScroller": {
            height: tableHeight + " !important",
        },

        "& .MuiDataGrid-cell": {
            maxHeight: "none !important",
            minHeight: "unset !important",
            fontSize: "0.8125rem",
            padding: "0.3125rem",
            color: Colors.fontColorTable,
            overflow: "visible !important",
            textOverflow: "unset !important",
            whiteSpace: "break-spaces !important",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "0.875rem",
            fontWeight: "bold",
        },
    };

    const calculateTableHeight = () => {
        const appBarElement: HTMLElement = document.getElementById("appBar") as HTMLElement;
        let appBarHeight: number = 0;
        if (appBarElement) {
            appBarHeight = appBarElement.getBoundingClientRect().height;
        }

        const tableToolBarElement: HTMLElement = document.getElementsByClassName("MuiToolbar-root")[0] as HTMLElement;
        let tableToolBarHeight: number = 0;
        if (tableToolBarElement) {
            tableToolBarHeight = tableToolBarElement.getBoundingClientRect().height;
        }

        const dividerElement: HTMLElement = document.getElementsByClassName(
            "MuiDivider-root MuiDivider-fullWidth css-104ssyd-MuiDivider-root"
        )[1] as HTMLElement;
        let dividerElementHeight: number = 0;
        if (dividerElement) {
            dividerElementHeight =
                dividerElement.getBoundingClientRect().y + dividerElement.getBoundingClientRect().height;
        }

        const tableFooterElement: HTMLElement = document.getElementsByClassName(
            "MuiTableFooter-root"
        )[0] as HTMLElement;
        let tableFooterHeight: number = 0;
        if (tableFooterElement) {
            tableFooterHeight = tableFooterElement.getBoundingClientRect().height;
        }

        const tableWrapperElement: HTMLElement = document.getElementById("tableWrapper") as HTMLElement;
        let marginHeight: number = 0;
        if (tableWrapperElement) {
            //multiplied by 2 for top and bottom margin
            marginHeight = 2 * Number(window.getComputedStyle(tableWrapperElement).margin.replaceAll("px", ""));
        }

        const footerElement: HTMLElement = document.getElementById("footer") as HTMLElement;
        let footerHeight: number = 0;
        if (footerElement) {
            footerHeight = footerElement.children[0].getBoundingClientRect().height;
        }

        const UNSELECTABLE_SPACING: number = -33;

        //max window height before scrollbar
        const height = window.innerHeight * (window.innerHeight / document.body.offsetHeight);

        setTableHeight(
            height -
                appBarHeight -
                marginHeight -
                tableToolBarHeight -
                dividerElementHeight -
                tableFooterHeight -
                UNSELECTABLE_SPACING -
                footerHeight
        );
    };

    useEffect(() => {
        window.addEventListener("resize", calculateTableHeight);
        return () => {
            window.removeEventListener("resize", calculateTableHeight);
        };
    }, [calculateTableHeight]);

    useEffect(() => {
        calculateTableHeight();
    }, []);

    return (
        <div
            style={{
                minWidth: "100%",
                height: tableHeight,
            }}
        >
            {" "}
            <DataGrid
                rows={data}
                columns={props.columns}
                rowBuffer={10} // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getRowId={(row: any) =>
                    props.combinedIdParams

                        .map((combineValues: string) => {
                            return row[combineValues];
                        })
                        .join("")
                }
                disableSelectionOnClick
                initialState={
                    props.sortInfo
                        ? {
                              sorting: {
                                  sortModel: [props.sortInfo],
                              },
                          }
                        : undefined
                }
                sx={{
                    ...styleCommon,
                    ...props.styleAddons,
                }}
            />{" "}
        </div>
    );
};
