/* author: JESCHKE Moritz */
import { Card, CardContent, Divider, Typography } from "@mui/material";
import { CardIcon } from "./CardIcon";
import { Colors } from "./Colors";
import { createKey } from "./Converter";
import { DataViewV2, IDataViewV2DataSet } from "./DataViewV2";

export interface IDetailviewCard {
    icon: React.JSX.Element | null;
    dataKeys: IDataViewV2DataSet[][];
    data: Record<string, string | number | Date>;
    techKeys?: IDataViewV2DataSet[][];
}

export const DetailviewCard = (props: IDetailviewCard): React.JSX.Element => {
    return (
        <div style={{ marginTop: "1rem" }}>
            <Card
                sx={{
                    background: Colors.grey,
                    "& .MuiCardContent-root:last-child": {
                        padding: "1rem",
                    },
                }}
            >
                <CardContent sx={{ display: "flex" }}>
                    <div>
                        {props.icon !== null ? (
                            <div style={{ marginRight: "1rem" }}>
                                <CardIcon icon={props.icon} />
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns:
                                "repeat(" + (props.dataKeys.length + (props.techKeys ? 1 : 0)) + ", 1fr)",
                            gap: "1rem",
                        }}
                    >
                        {props.dataKeys.map((dataKeys: IDataViewV2DataSet[], index: number) => {
                            return (
                                <div
                                    key={createKey("detailviewCardDatakey", dataKeys[index].field)}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <DataViewV2 fields={dataKeys} data={props.data} />
                                    {props.dataKeys.length - 1 !== index || props.techKeys ? (
                                        <Divider
                                            sx={{
                                                background: Colors.darkGrey,
                                                width: "0.125rem",
                                                marginLeft: "1rem",
                                                marginRight: "1rem",
                                            }}
                                            orientation="vertical"
                                            flexItem
                                        />
                                    ) : null}
                                </div>
                            );
                        })}
                        {props.techKeys ? (
                            <div>
                                <Typography sx={{ fontWeight: "bold", fontSize: "0.875rem" }}>
                                    Technische Daten
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                        gap: "1rem",
                                    }}
                                >
                                    {props.techKeys.map((techKeys: IDataViewV2DataSet[], index: number) => {
                                        return (
                                            <DataViewV2
                                                key={createKey("detailviewCardTechkey", techKeys[index].field)}
                                                fields={techKeys}
                                                data={props.data}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
