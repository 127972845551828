/* author: Aashish Upadhyay*/

import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params, useParams } from "react-router";
import { isYerEditable } from "./YerRequestEdit";

export const ORDER_DETAILS_SECTION: string = "Auftragsdetails";

const YerReportEdit = (type: "single" | "mass"): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const requestId: number = Number(params.requestId);
    const section: string | undefined = params.section;

    const isMass: boolean = type === "mass";

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path:
                type === "single"
                    ? "/request/yer/single/report/byId-with-keydata"
                    : "/request/yer/mass/report/byId-with-keydata",
            params: { requestId: requestId },
        });

    const orderDetailsEditInfo: IEditDataViewUserConfiguration = {
        headline: ORDER_DETAILS_SECTION,
        attributes: [
            {
                field: "reportType",
                headerName: "Bescheinigungstyp",
                dropdown: {
                    keyTableTabname: "REQ_REPORT_TYPE",
                },
                disabled: isYerEditable,
            },
            {
                field: "copies",
                headerName: "Anzahl Kopien",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "nullReport",
                headerName: "Nullerbescheinigung",
                dropdown: {
                    keyTableTabname: "REQ_NULL",
                },
                disabled: isYerEditable,
            },
            {
                field: "adr1",
                headerName: "AddressField1",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "adr2",
                headerName: "AddressField2",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "adr3",
                headerName: "AddressField3",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "adr4",
                headerName: "AddressField4",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "adr5",
                headerName: "AddressField5",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "adr6",
                headerName: "AddressField6",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "adr7",
                headerName: "AddressField7",
                hide: isMass,
                disabled: isYerEditable,
            },
            {
                field: "country",
                headerName: "Steuerlicher Status",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "COUNTRY_TAX",
                },
                hide: isMass,
            },
            {
                field: "billing",
                headerName: "Kostenpflichtig",
                dropdown: {
                    keyTableTabname: "REQ_BILLING",
                },
                disabled: isYerEditable,
            },
            {
                field: "billingCostEUR",
                headerName: "Kostenpflichtiger Betrag in Euro",
                disabled: isYerEditable,
            },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [orderDetailsEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={requestId}
            title={(type === "single" ? "Einzel" : "Masssen") + "aufträge bearbeiten"}
            section={section}
        />
    );
};

export const YerSingleReportEdit = (): React.JSX.Element => YerReportEdit("single");
export const YerMassReportEdit = (): React.JSX.Element => YerReportEdit("mass");
