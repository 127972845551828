/* author: PRASHANT Pal */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn, ITableContextMenu } from "components/Table";
import { useState } from "react";
import { useLocation } from "react-router";
import {
    TED_CALCULATION_POT_INGOING_MENU_ITEM,
    TED_CALCULATION_POT_OUTGOING_MENU_ITEM,
    TED_DEPOT_INGOING_MENU_ITEM,
} from "application/menu-bar/MenuBarItemList";
import { KeyTableConsts } from "components/KeyTableConsts";
import { TedContextMenu } from "./TedContextMenu";
import { TED_SECTION_CALCULATION_POT, TED_SECTION_DEPOT_EXCHANGE } from "./TedEdit";
import { getAddFunction } from "components/VersionsView";

export const getTedColumns = (isCalcPot: boolean, isIngoing: boolean): ITableColumn[] => [
    {
        field: "extTedKey",
        title: "Taxbox-Client Ref.",
        hidden: isIngoing,
    },
    {
        field: "extTranKey",
        title: "Umsatzreferenz",
        hidden: isCalcPot,
    },
    { field: "msgref", title: "Message Referenz" },
    { field: "cbfclientacc", title: "CBF Konto" },
    { field: "msgfnc", title: "Message Function" },
    {
        field: "creationDate",
        title: "Übertragungsdatum",
        type: "date",
    },
    { field: "taxtp", title: "Satzart" },
    { field: "taxid", title: "Taxbox ID" },
    { field: "prevtaxid", title: "Previous Taxbox ID" },
    {
        field: "rltdRef",
        title: "Settlement Referenz",
        hidden: isCalcPot,
    },
    { field: "issuerID", title: "Auftraggeber BIC/BLZ" },
    { field: "issuerAcctsvcr", title: "Auftraggeber Depotstelle (Bank/KAG)" },
    { field: "issuerAcctkey", title: "Auftraggeber Depotnummer" },
    { field: "issuerAcctnm", title: "Auftraggeber Name" },
    { field: "issuerTin", title: "Steuer Id. Nr." },
    { field: "benefID", title: "Empfänger BIC/BLZ" },
    { field: "benefAcctsvcr", title: "Empfänger Depotstelle (Bank/KAG)" },
    { field: "benefAcctkey", title: "Empfänger Depotnummer" },
    { field: "benefAcctnm", title: "Empfänger Name" },
    {
        field: "assettp",
        title: "Asset Type",
        hidden: isCalcPot,
    },
    {
        field: "isin",
        title: "ISIN",
        hidden: isCalcPot,
    },
    {
        field: "nm",
        title: "Bezeichnung des Wertpapiers",
        hidden: isCalcPot,
    },
    {
        field: "trnstp",
        title: "Art des Übertrages",
        hidden: isCalcPot,
    },
    {
        field: "subrogation",
        title: "Gläubigerwechsel",
        hidden: isCalcPot,
    },
    {
        field: "transSpecType",
        title: "Besonderheiten Übertrag",
        hidden: isCalcPot,
    },
    {
        field: "trnsnom",
        title: "Gesamtübertragsmenge",
        hidden: isCalcPot,
    },
    {
        field: "trnsunit",
        title: "Übertragseinheit",
        hidden: isCalcPot,
    },
    {
        field: "setoffPotNom",
        title: "Verrechnungstopfhöhe",
        hidden: !isCalcPot,
    },
    {
        field: "repcnt",
        title: "Wiederholsequenzen",
        hidden: isCalcPot,
    },
    { field: "rjctdrsn", title: "Grund der Abweisung" },
    { field: "rjctdaddtlinf", title: "Abweisungsinfo" },
    { field: "status", title: "Taxbox-Status" },
    { field: "statusDtlKey", title: "Detail-Schlüssel Taxbox-Verarbeitungsstatus" },
    { field: "depTranCentre", title: "Taxbox Kennzeichen" },
    { field: "branch", title: "Filiale" },
    { field: "comment", title: "Taxbox-Kommentar" },
    { field: "cancellationFlag", title: "Storno-Flag" },
    { field: "setoffPotDate", title: "Gültigkeitszeitpunkt", hidden: !isCalcPot, type: "date" },
    {
        field: "accountTransferType",
        title: "int/ex",
        hidden: isCalcPot,
    },
];

// eslint-disable-next-line react/display-name
const MainTed = (taxTp: string) => () => {
    const location: Location = useLocation().state;
    const origin: string = location ? location.origin : sessionStorage.getItem("origin") ?? "";
    sessionStorage.setItem("origin", origin);

    const IDENTIFIER: TableidentifierType = "depotExchangeSearch";

    const isCalcPot: boolean =
        origin === TED_CALCULATION_POT_INGOING_MENU_ITEM || origin === TED_CALCULATION_POT_OUTGOING_MENU_ITEM;
    const isIngoing: boolean =
        origin === TED_DEPOT_INGOING_MENU_ITEM || origin === TED_CALCULATION_POT_INGOING_MENU_ITEM;

    const searchCriterias: ISearchCriteria[] = [
        { field: "taxId", label: "Taxbox ID", freeInputRestrigtion: ["NUMBERS"] },
        {
            field: "extTranKey",
            label: "Umsatzreferenz*",
            hidden: isCalcPot,
        },
        {
            field: "extTedKey",
            label: "Taxbox-Client Ref.*",
            hidden: isIngoing,
        },
        {
            field: "issuerAcctnm",
            label: "Auftraggeber Name",
            type: "autocomplete",
            autocompleteMinLetters: 3,
            optionsRequest: (currentValue: string) =>
                sendGetRequest({
                    path: "/autocomplete/getAll/tedNames",
                    params: { issuerAcctnm: currentValue, benefAcctnm: "" },
                }),
        },
        {
            field: "issuerAcctkey",
            label: "Auftraggeber Depotnummer*",
        },
        {
            field: "benefAcctnm",
            label: "Empfänger Name",
            type: "autocomplete",
            autocompleteMinLetters: 3,
            optionsRequest: (currentValue: string) =>
                sendGetRequest({
                    path: "/autocomplete/getAll/tedNames",
                    params: { issuerAcctnm: "", benefAcctnm: currentValue },
                }),
        },
        {
            field: "benefAcctkey",
            label: "Empfänger Depotnummer*",
        },
        { field: "isin", label: "ISIN*", hidden: isCalcPot },
        {
            field: "",
            label: "Zeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "from", discriminator: "from" },
                    to: { label: "bis", field: "to", discriminator: "to" },
                },
            },
        },
        {
            field: "status",
            label: "Taxbox Status",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.TED_STATUS, allOption: true },
            defaultValue: "*",
        },
        {
            field: "blzIncome",
            label: "Auftraggeber Institut BLZ",
            hidden: !isIngoing,
        },
        {
            field: "bicIncome",
            label: "Auftraggeber Institut BIC",
            hidden: !isIngoing,
        },
        {
            field: "blzOutgoing",
            label: "Empfänger Institut BLZ",
            hidden: isIngoing && !isCalcPot,
        },
        {
            field: "bicOutgoing",
            label: "Empfänger Institut BIC",
            hidden: isIngoing && !isCalcPot,
        },
    ];

    const getSearchDirection = (): "IN" | "OUT" => (isIngoing ? "IN" : "OUT");

    const searchUrls: ISearchUrl[] = [
        {
            path: "/ted/exchange/search",
            additionalParams: {
                taxTp: taxTp,
                searchDirection: getSearchDirection(),
            },
        },
    ];

    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <div>
            <Search
                columns={getTedColumns(isCalcPot, isIngoing)}
                searchCriterias={searchCriterias}
                identifier={IDENTIFIER}
                title={origin + " Suche"}
                searchUrls={searchUrls}
                contextMenu={{
                    element: (
                        <TedContextMenu
                            isDepot={!isCalcPot}
                            searchDirection={getSearchDirection()}
                            contextMenus={contextMenus}
                            setContextmenus={setContextMenus}
                        />
                    ),
                    tableElement: contextMenus["TED"],
                }}
                addFunction={getAddFunction(
                    !isCalcPot ? TED_SECTION_DEPOT_EXCHANGE : TED_SECTION_CALCULATION_POT,
                    getSearchDirection()
                )}
            />
        </div>
    );
};

export const DepotExchangeSearchMask = MainTed("10");

export const CalculationPotSearchMask = MainTed("20,30,40");
