/* author: JESCHKE Moritz */

import { GridComparatorFn } from "@mui/x-data-grid";
import { isValid, parse } from "date-fns";

export const customNumberFormat = (rowData: Record<string, number>, source: string): string | undefined => {
    const number: number = rowData[source];

    //return nothing if field is empty
    if (number === undefined) {
        return;
    }

    //add 2 decimal numbers
    const numberFixed: string = number.toFixed(2);
    //add thousand separator
    const parts = numberFixed.split(".");
    //find out if locale uses comma or dot
    // eslint-disable-next-line no-magic-numbers
    const commaOrDot = (1.1).toLocaleString().substring(1, 2);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, commaOrDot === "." ? "," : ".");

    //combine all
    return parts.join(commaOrDot);
};

export const customNumberFormatV2 = (number: number): string => {
    if (typeof number !== typeof 1) return "NaN";

    //return nothing if field is empty
    if (number === undefined) {
        return "";
    }

    //add 2 decimal numbers
    const num = number.toFixed(2);
    //add thousand separator
    const parts = num.toString().split(".");
    //find out if locale uses comma or dot
    // eslint-disable-next-line no-magic-numbers
    const commaOrDot = (1.1).toLocaleString().substring(1, 2);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, commaOrDot === "." ? "," : ".");

    //combine all
    return parts.join(commaOrDot);
};

/**
 * Custom Comparator for 2 Dates
 * @param date1 Date 1
 * @param date2 Date 2
 * @returns 0 - Dates are equal, -1 - Date 1 is smaller, 1 - Date 1 is bigger
 */
export const customDateComparator: GridComparatorFn = (date1: string, date2: string) => {
    if (date1 === undefined && date2 === undefined) {
        return 0;
    } else if (date1 === undefined) {
        return -1;
    } else if (date2 === undefined) {
        return 1;
    }

    const d1Split = date1.split(".");
    const d1 = new Date(d1Split[1] + "." + d1Split[0] + "." + d1Split[2]);
    const d2Split = date2.split(".");
    const d2 = new Date(d2Split[1] + "." + d2Split[0] + "." + d2Split[2]);

    if (d1.getTime() === d2.getTime()) {
        //dates are equal
        return 0;
    } else if (d1.getTime() < d2.getTime()) {
        //date 1 is smaller than date 2
        return -1;
    } else {
        //date 1 is bigger than date 2
        return 1;
    }
};

/**
 * Takes Date and convert it into DD.MM.YYYY format
 * @param params valueGetter Params
 * @returns Date in format DD.MM.YYYY
 */
export const customDateValueGetter = (params: { value: string }): string => {
    if (params.value === undefined) {
        return params.value;
    }

    const d = new Date(params.value);
    return (
        d.getDate().toString().padStart(2, "0") +
        "." +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "." +
        d.getFullYear()
    );
};

/**
 * Takes Date and convert it into DD.MM.YYYY format
 * @param date Date to convert
 * @returns Date in format DD.MM.YYYY HH:MM:SS
 */
export const customDateTimeValueGetter = (date: string): string => {
    if (!date) return "";
    const dateString = convert(date, "date");

    const d = new Date(date);
    const time =
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0");

    return dateString + " " + time;
};

/**
 * Takes Date and convert it into DD.MM.YYYY format
 * @param date Date to convert
 * @returns Date in format DD.MM.YYYY
 */
export const customDateValueGetterV2 = (date: string): string => {
    if (date === undefined) {
        return date;
    }

    const d = new Date(date);
    //if date is not valid return an empty string
    if (d.toString() === "Invalid Date") {
        return "";
    }
    //creates a date in the format DD.MM.YYYY
    return (
        d.getDate().toString().padStart(2, "0") +
        "." +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "." +
        d.getFullYear()
    );
};
export const customDatesValueGetterV2 = (dates: string[]): string => {
    let newDate = "";
    for (const date of dates) {
        newDate = customDateValueGetterV2(date);
        if (newDate) {
            break;
        }
    }
    return newDate;
};
export const backendDateFormatGetter = (date: Date): string => {
    const d = new Date(date);

    return (
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        d.getDate().toString().padStart(2, "0") +
        "T" +
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0")
    );
};

export const customDateFilterAndSearch = (term: string, date: string): boolean => {
    const customDate: string = customDateValueGetterV2(date);
    if (customDate !== undefined) {
        return customDate.indexOf(term) !== -1;
    }
    return false;
};

export const customDatesFilterAndSearch = (term: string, dates: string[]): boolean => {
    dates.forEach((date: string) => {
        const newDate = customDateValueGetterV2(date);
        if (newDate) {
            return newDate.indexOf(term) !== -1;
        }
    });

    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const customSort = (a: any, b: any, fieldName: string, type?: string): number => {
    let isEqual: boolean = true;
    let aIsGreaterThanB: boolean = true;

    //read changed value when one exists otherwise take current value
    const changedOrOriginalValueA: string = getChangedOrOriginalValue(a, fieldName);
    const changedOrOriginalValueB: string = getChangedOrOriginalValue(b, fieldName);

    if (type === "date") {
        //date comparison
        const d1: Date = new Date(changedOrOriginalValueA);
        const d2: Date = new Date(changedOrOriginalValueB);

        isEqual = d1.getTime() === d2.getTime();
        aIsGreaterThanB = d1.getTime() > d2.getTime();
    } else {
        //number and string comparison
        let v1 = changedOrOriginalValueA;
        let v2 = changedOrOriginalValueB;

        //make string in all lowercase to ignore upper and lower case
        if (typeof v1 === "string" && typeof v2 === "string") {
            v1 = v1.toLowerCase();
            v2 = v2.toLocaleLowerCase();
        }
        isEqual = v1 === v2;
        aIsGreaterThanB = v1 > v2;
    }

    //evaluation
    if (isEqual) {
        return 0;
    } else {
        return aIsGreaterThanB ? 1 : -1;
    }
};

export type ConvertType = "date" | "percent" | "datetime" | "decimal" | "number" | "switch" | undefined;
export const convert = (term: Date | number | string, type: ConvertType): string => {
    if (type === undefined) {
        //if term is undefined return an empty string
        if (term && term.toString() === "undefined") {
            term = "";
        }

        return typeof term === "undefined" ? "" : String(term);
    } else {
        let converted: string;

        switch (type) {
            case "date":
                converted = typeof term === "string" ? customDateValueGetterV2(term) : "";
                break;
            case "datetime":
                converted = typeof term === "string" ? customDateTimeValueGetter(term) : "";
                break;
            case "decimal":
                converted = typeof term === "number" ? customNumberFormatV2(term) : "";
                break;
            case "percent":
                if (typeof term === "number") {
                    converted = isNaN(term) ? "-" : Math.floor(term * 100) + "%";
                } else converted = "";
                break;
            case "number":
                converted = String(term);
                break;
            case "switch":
                converted = String(term);
                break;
            default:
                converted = "ERROR: UNKNOWN DATATYPE";
                break;
        }
        return converted;
    }
};

interface IMerge {
    data: Record<string, unknown>;
    prefix: string;
}

export const mergeJSON = (merge: IMerge[]): Record<string, string> => {
    let mergedJson = {};
    merge.forEach((mergeItem: IMerge) => {
        mergedJson = { ...mergedJson, ...prefixObj(mergeItem.data, mergeItem.prefix) };
    });
    return mergedJson;
};

//TODO :: fix
const prefixObj = (obj: Record<string, unknown> | [] | null, prefix: string): Record<string, unknown> | [] => {
    if (obj === null) {
        return [];
    }
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
            return [
                `${prefix}${key}`,
                typeof value === "object" ? prefixObj(value as Record<string, unknown>, prefix) : value,
            ];
        })
    );
};

export const getChangedValue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    fieldName: string,
    isDataFlatten?: boolean
): string | undefined => {
    let ret: string | undefined;

    if (isDataFlatten) {
        ret = data[fieldName];
    } else {
        if (data.editPendingData) {
            if (data.editPendingData[fieldName]) {
                ret = data.editPendingData[fieldName];
            } else if (data.editPendingData.changedData) {
                if (fieldName.includes(".")) {
                    const flattenedData: Record<string, string> = flattenJson(data.editPendingData.changedData);
                    if (flattenedData[fieldName]) {
                        ret = flattenedData[fieldName];
                    }
                } else ret = data.editPendingData.changedData[fieldName];
            }
        }
    }

    return ret;
};

export const getChangedOrOriginalValue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    fieldName: string
): string => {
    if (fieldName.includes(".")) {
        return getChangedValue(data, fieldName) ?? flattenJson(data as [])[fieldName];
    } else return getChangedValue(data, fieldName) ?? data[fieldName];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasChangedValues = (data: Record<string, any>): boolean =>
    data && data.editPendingData !== undefined && data.editPendingData.changedData !== undefined;

export const hasChangedValuesArray = (data: Record<string, string>[]): boolean => {
    let hasChange: boolean = false;
    for (const d of data) {
        if (hasChangedValues(d)) {
            hasChange = true;
            break;
        }
    }

    return hasChange;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasChangeInSiblings = (data: Record<string, any>): boolean => {
    let hasChange: boolean = false;
    const siblingData: Record<string, unknown>[] = data["editPendingSiblingData"];

    if (siblingData) {
        for (const sibling of siblingData) {
            if (sibling["state"] !== STATE_INITIAL) {
                hasChange = true;
                break;
            }
        }
    }

    return hasChange;
};

export const hasChangeInSiblingsArray = (data: Record<string, unknown>[]): boolean => {
    let hasChange: boolean = false;
    data.forEach((d: Record<string, unknown>) => {
        if (hasChangeInSiblings(d)) hasChange = true;
    });
    return hasChange;
};

export type STATE_TYPES = "" | " " | "T" | "A";
export const STATE_INITIAL: STATE_TYPES = "";
export const STATE_SAVED: STATE_TYPES = " ";
export const STATE_SEND_TO_APPROVAL: STATE_TYPES = "T";
export const STATE_APPROVED: STATE_TYPES = "A";
export const getEditPendingState = (data: Record<string, unknown>): STATE_TYPES =>
    getChangedValue(data, "state") as STATE_TYPES;

//format a string by replacing placeholders with variables
//formatString("Hello {1}{0}", ["!", "World"]);
//OUTPUT: Hello World!

export const formatString = (str: string, varStr: string[]): string => {
    return str.replace(/{(\d+)}/g, (match, number: number) => {
        if (typeof varStr[number] != "undefined") return varStr[number];

        if (process.env.REACT_APP_STATUS === "development") {
            return match;
        } else return "";
    });
};

export const createKey = (topic: string, identifier: string): string => {
    let key: string = topic + "-";
    key += identifier ? identifier : Math.random().toString();
    return key.replaceAll(" ", "");
};

export const flattenJsonList = (object: [][], ignoreFields?: string[]): Record<string, string>[] => {
    const temp: Record<string, string>[] = [];
    object.forEach((res: []) => {
        temp.push(flattenJson(res, ignoreFields));
    });
    return temp;
};

export const flattenJson = (object: [], ignoreFields?: string[]): Record<string, string> => {
    const toReturn: Record<string, string> = {};

    for (const i in object) {
        if (!Object.prototype.hasOwnProperty.call(object, i)) continue;

        if (typeof object[i] == "object" && object[i] !== null && (ignoreFields ? !ignoreFields.includes(i) : true)) {
            const flatObject = flattenJson(object[i], ignoreFields);
            for (const x in flatObject) {
                if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue;

                toReturn[i + "." + x] = flatObject[x];
            }
        } else {
            toReturn[i] = object[i];
        }
    }

    return toReturn;
};

export const unflattenJson = (flatObj: Record<string, string>): Record<string, string> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: Record<string, any> = {};

    for (const key in flatObj) {
        if (Object.prototype.hasOwnProperty.call(flatObj, key)) {
            const keys = key.split(".");
            let nestedObj = result;

            for (let i = 0; i < keys.length - 1; i++) {
                const currentKey = keys[i];
                if (!nestedObj[currentKey] || typeof nestedObj[currentKey] !== "object") {
                    nestedObj[currentKey] = {};
                }
                nestedObj = nestedObj[currentKey];
            }

            nestedObj[keys[keys.length - 1]] = flatObj[key];
        }
    }

    return result;
};
//This Function is generic and can be used in search component and in edit component
export const isValueDateType = (value: string): boolean => {
    return (
        isValid(parse(value, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date())) ||
        isValid(parse(value, "yyyy-MM-dd'T'HH:mm:ss", new Date()))
    );
};

//This function will move the data which is under changedData[FIELDTOEXTRACT] directly under changedData and remove the other changes
export const extractFromEditPendingData = (
    editPendingData: Record<string, unknown>,
    fieldToExtract: string
): Record<string, unknown> => {
    if (!editPendingData) return {};

    const cd: Record<string, string> = editPendingData.changedData as Record<string, string>;

    //delete changedData from Parent
    Object.keys(cd).forEach((key: string) => {
        if (key !== fieldToExtract) delete cd[key];
    });

    //move fieldToExtract key directly under changedData
    Object.keys(cd[fieldToExtract]).forEach((key: string) => {
        cd[key] = (cd[fieldToExtract] as unknown as Record<string, string>)[key];
    });

    editPendingData.changedData = cd;

    return editPendingData;
};

export const getCurrentUrlLocation = (): string =>
    window.location.href.replace(
        window.location.protocol + "//" + window.location.host + process.env.REACT_APP_BASE_NAME,
        ""
    );
