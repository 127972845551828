import { useEffect, useState } from "react";

import { Box, Button, Card, Dialog, DialogActions, DialogContentText } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import DownloadLink from "react-download-link";

interface IProps {
    fiscalUnitId: string;
    setDialogOpen: any;
    isOpen: boolean;
    year: string;
}

export const GenerateJSTBReport = (props: IProps) => {
    const [xml, setXML] = useState("");
    const [msg, setMsg] = useState<string>("");
    const handleClose = () => {
        props.setDialogOpen(false);
    };

    useEffect(() => {
        async function getReport() {
            let parser = new DOMParser();
            let reportXml;

            //TODO :: use variable
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const report = await sendGetRequest({
                path: "/jstbresources/reportGeneration",
                params: { fiscalUnitId: props.fiscalUnitId, year: props.year },
            }).then((response) => {
                //TODO :: use variable
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                reportXml = parser.parseFromString(response.data, "application/xml");
                document.getElementById("Tributum");
                console.log("xml", response.data);
                setXML(response.data);
                if (JSON.stringify(response.data) !== JSON.stringify("")) {
                    setMsg("JSTB Report is generated");
                }
            });
        }

        getReport();
    }, [props.fiscalUnitId, props.year]);

    return (
        <Box>
            <Card>
                {/* <DownloadLink
                    label="Download"
                    filename="jstb.xml"
                    exportFile={() => Promise.resolve(xml)}
                ></DownloadLink> */}
                <Dialog open={props.isOpen} onClose={handleClose}>
                    <DialogContentText>
                        {msg !== "" ? (
                            <div>
                                <br />
                                {msg}
                                <DownloadLink
                                    label="Download"
                                    filename="jstb.xml"
                                    exportFile={() => Promise.resolve(xml)}
                                ></DownloadLink>
                            </div>
                        ) : (
                            <div>XML Not Generated, Click on generate Report</div>
                        )}
                    </DialogContentText>

                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        </Box>
    );
};
