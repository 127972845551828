import { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { AppDispatch } from "application/redux/Store";
import { useAppDispatch } from "application/redux/Hooks";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { ITableColumn, Table } from "components/Table";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";

interface IProps {
    params: {
        impId?: number | undefined;
        yieldId?: number | undefined;
        system: string;
    };
}

export const columns: ITableColumn[] = [
    {
        field: "dwtPerson_1",
        title: "AgSt nach Kirchsteuer, Anteil Ehepartner 1",
        type: "numeric",
    },
    {
        field: "dwtPerson_2",
        title: "AgSt nach Kirchsteuer, Anteil Ehepartner 2",
        type: "numeric",
    },
    {
        field: "soliPerson_1",
        title: "Betrag Soli Ehepartner 1",
        type: "numeric",
    },
    {
        field: "soliPerson_2",
        title: "Betrag Soli Ehepartner 2",
        type: "numeric",
    },
    {
        field: "churchTaxPerson_1",
        title: "Kirchensteuer Person 1",
        type: "numeric",
    },
    {
        field: "churchTaxPerson_2",
        title: "Kirchensteuer Person 2",
        type: "numeric",
    },
    {
        field: "totalShareProfit",
        title: "Summe der Gewinne aus Aktienverkäufen",
        type: "numeric",
    },
    {
        field: "totalOtherProfit",
        title: "Summe der sonstigen positiven Kapitalerträge",
        type: "numeric",
    },
    {
        field: "totalShareLoss",
        title: "Summe der Verluste aus Aktienverkäufen",
        type: "numeric",
    },
    {
        field: "totalOtherLoss",
        title: "Summe VT Sonstige Verluste",
        type: "numeric",
    },
    {
        field: "exemptYieldFsa",
        title: "freigestellter Betrag FSA",
        type: "numeric",
    },
    {
        field: "netTaxableYieldDiv",
        title: "KESt-pflichtiger Betrag bei inl. Beteiligungseinnahmen",
        type: "numeric",
    },
    {
        field: "netTaxableYieldBank",
        title: "Ertrag mit Zahlstellenabzug nach Abstandnahme NV, VVR und FSA",
        type: "numeric",
    },
    /**        { field: "id", headerName: "TB_TAX_ACCOUNT_ID" },
        { field: "taxBalanceId", headerName: "TB_TAX_BALANCE_ID" },
        { field: "fiscalUnitId", headerName: "TB_FISCAL_UNIT_ID" },
        { field: "fsaNvId", headerName: "TB_FSA_NV_ID" },
        { field: "fsaNvBalanceId", headerName: "TB_FSA_NV_BALANCE_ID" },
        { field: "personId_1", headerName: "TB_PERSON_ID_1" },
        { field: "yieldId", headerName: "TB_YIELD_ID" },
        { field: "corrRefYieldId", headerName: "TB_CORR_REF_YIELD_ID" },
        { field: "denominationPerson_1", headerName: "TB_DENOMINATION_PERSON1" },
        { field: "denominationPerson_2", headerName: "TB_DENOMINATION_PERSON2" },
        {
            field: "churchTaxRatePerson_1",
            headerName: "TB_CHURCH_TAX_RATE_PERSON1",
        },
        {
            field: "churchTaxRatePerson_2",
            headerName: "TB_CHURCH_TAX_RATE_PERSON2",
        },
        {
            field: "ratioGrossYieldPerson_1",
            headerName: "TB_RATIO_GROSS_YIELD_PERSON1",
        },
        {
            field: "ratioGrossYieldPerson_2",
            headerName: "TB_RATIO_GROSS_YIELD_PERSON2",
        },
        {
            field: "netTaxableYieldIssuer",
            headerName: "TB_NET_TAXABLE_YIELD_ISSUER",
        },
        { field: "offsetableTaxLoss", headerName: "TB_OFFSETABLE_TAX_LOSS" },
        { field: "exemptYieldNv", headerName: "TB_EXEMPT_YIELD_NV" },
        { field: "exemptYieldFsa", headerName: "TB_EXEMPT_YIELD_FSA" },
        {
            field: "exemptYieldNonResident",
            headerName: "TB_EXEMPT_YIELD_NON_RESIDENT",
        },
        { field: "dwtBefChurchTax", headerName: "TB_DWT_BEF_CHURCH_TAX" },
        { field: "federalStateDwt", headerName: "TB_FEDERAL_STATE_DWT" },
        { field: "dwtAllowance", headerName: "TB_DWT_ALLOWANCE" },
        { field: "soliAllowance", headerName: "TB_SOLI_ALLOWANCE" },
        { field: "dwtByBank", headerName: "TB_DWT_BY_BANK" },
        { field: "dwtSoliByBank", headerName: "TB_DWT_SOLI_BY_BANK" },
        { field: "sadvByTaxOffice", headerName: "TB_SADV_BY_TAX_OFFICE" },
        { field: "sadvByIssuer", headerName: "TB_SADV_BY_ISSUER" },
        { field: "sadvSoliByTaxOffice", headerName: "TB_SADV_SOLI_BY_TAX_OFFICE" },
        { field: "sadvSoliByIssuer", headerName: "TB_SADV_SOLI_BY_ISSUER" },
        { field: "dwtBankCompExemption", headerName: "TB_DWT_BANK_COMP_EXEMPTION" },
        {
            field: "soliBankCompExemption",
            headerName: "TB_SOLI_BANK_COMP_EXEMPTION",
        },
        { field: "dwtBankCompNV", headerName: "TB_DWT_BANK_COMP_NV" },
        { field: "soliBankCompNV", headerName: "TB_SOLI_BANK_COMP_NV" },
        { field: "personId_2", headerName: "TB_PERSON_ID_2" },
        { field: "dwtTaxRatePerson1", headerName: "TB_DWT_TAX_RATE_PERSON1" },
        { field: "dwtTaxRatePerson2", headerName: "TB_DWT_TAX_RATE_PERSON2" },
        {
            field: "dwtBankDiffChurchTax",
            headerName: "TB_DWT_BANK_DIFF_CHURCH_TAX",
        },
        {
            field: "soliBankDiffChurchTax",
            headerName: "TB_SOLI_BANK_DIFF_CHURCH_TAX",
        },
        { field: "dwtBankCompLoss", headerName: "TB_DWT_BANK_COMP_LOSS" },
        { field: "soliBankCompLoss", headerName: "TB_SOLI_BANK_COMP_LOSS" },
        {
            field: "dwtBankCompOffsetableTax",
            headerName: "TB_DWT_BANK_COMP_OFFSET_TAX",
        },
        {
            field: "soliBankCompOffsetableTax",
            headerName: "TB_SOLI_BANK_COMP_OFFSET_TAX",
        },
        {
            field: "exemptYieldLegalFormCategory",
            headerName: "TB_EXEMPT_YIELD_LEGAL_FORM",
        },
        {
            field: "paidOffsetableTaxLoss",
            headerName: "TB_PAID_OFFSETABLE_TAX_LOSS",
        },
        { field: "bankExemptNV", headerName: "TB_BANK_EXEMPT_NV" },
        { field: "bankExemptFSA", headerName: "TB_BANK_EXEMPT_FSA" },
        { field: "bankExemptLegalForm", headerName: "TB_BANK_EXEMPT_LEGAL_FORM" },
        {
            field: "bankExemptNonResident",
            headerName: "TB_BANK_EXEMPT_NON_RESIDENT",
        },
        { field: "bankExemptShareLoss", headerName: "TB_BANK_EXEMPT_SHARE_LOSS" },
        { field: "bankExemptOtherLoss", headerName: "TB_BANK_EXEMPT_OTHER_LOSS" },
        {
            field: "bankExemptOffsetableTax",
            headerName: "TB_BANK_EXEMPT_OFFSET_TAX",
        },
        {
            field: "exemptYieldDoubleTaxation",
            headerName: "TB_EXEMPT_YIELD_DOUBLE_TAX",
        },
        { field: "shortPosition", headerName: "TB_ISSUER_SHORT_POS_INCOME" },
        { field: "dwtShortPosition", headerName: "TB_DWT_SHORT_POSITION" },
        { field: "soliShortPosition", headerName: "TB_SOLI_SHORT_POSITION" },
        {
            field: "exemptYieldNonIntProf",
            headerName: "TB_EXEMPT_YIELD_NON_INT_PROF",
        },
        { field: "evvrShareLoss", headerName: "TB_EVVR_SHARE_LOSS" },
        { field: "evvrOtherLoss", headerName: "TB_EVVR_OTHER_LOSS" },
        { field: "evvrOffsetableTax", headerName: "TB_EVVR_OFFSETABLE_TAX" },
        { field: "evvrTaxableBaseDwt", headerName: "TB_EVVR_TAXABLE_BASE_DWT" },
        {
            field: "evvrCredOffsetableTax",
            headerName: "TB_EVVR_CRED_OFFSETABLE_TAX",
        },
        { field: "incomeNotTaxable", headerName: "TB_INCOME_NOT_TAXABLE" },
        { field: "exemptShareLoss", headerName: "TB_EXEMPT_SHARE_LOSS" },
        { field: "exemptOtherLoss", headerName: "TB_EXEMPT_OTHER_LOSS" },
        { field: "taxLiquidityPerson1", headerName: "TB_TAX_LIQUIDITY_PERSON1" },
        { field: "taxLiquidityPerson2", headerName: "TB_TAX_LIQUIDITY_PERSON2" },
        {
            field: "taxLiquidityShortPosition",
            headerName: "TB_LIQUIDITY_SHORT_POSITION",
        },
        { field: "collTaxCertDwt", headerName: "TB_COLL_TAX_CERT_DWT" },
        { field: "collTaxCertSoli", headerName: "TB_COLL_TAX_CERT_SOLI" },
        { field: "exemptYieldNvLimit", headerName: "TB_EXEMPT_YIELD_NV_LIMIT" },
        { field: "optimizationPotential", headerName: "TB_OPTIMIZATION_POTENTIAL" },
        */
];

export const TaxAccounting = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [tableData, setTableData] = useState([]);
    const IDENTIFIER: TableidentifierType = "taxAccounting";

    //call http request onMount
    useEffect(() => {
        async function fetchData() {
            dispatch(setIsLoading({ isLoading: true }));
            const response = await sendGetRequest({
                path: "/cgresources/taxaccounting",
                params: props.params,
            });
            setTableData(response.data);
            dispatch(setIsLoading({ isLoading: false }));
        }

        fetchData();
    }, [props.params, dispatch]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Box sx={{ height: "100%" }}>
            <Card sx={{ height: "50%" }}>
                <Table columnsDefinition={columns} identifier={IDENTIFIER} tableData={tableData} />
            </Card>
        </Box>
    );
};
