/* author: JESCHKE Moritz */

import MaterialTable, { MTableActions, MTableBodyRow, MTableCell, MTableHeader } from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { useAppDispatch } from "application/redux/Hooks";
import {
    addTableHide,
    removeTableHide,
    setTableSorting,
    TableidentifierType,
} from "application/redux/slices/UserConfigurationSlice";
import { AppDispatch, RootState } from "application/redux/Store";
import { ContextMenu, IContextMenuAction } from "components/ContextMenu";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { Colors } from "./Colors";
import cloneDeep from "lodash/cloneDeep";
import {
    convert,
    customDateValueGetterV2,
    customDatesValueGetterV2,
    customNumberFormatV2,
    customSort,
    flattenJson,
    getChangedOrOriginalValue,
    getChangedValue,
} from "./Converter";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { NavigateFunction, useNavigate } from "react-router";
import { getField } from "./EditComponents/FormEditView";
import { TablePagination } from "@mui/material";

interface ITable {
    tableData: Record<string, string | number>[];
    identifier: TableidentifierType;
    columnsDefinition: ITableColumn[];
    contextMenu?: ITableContextMenu;
    selection?: ITableRowSelection;
    title?: string;
    disableAllSelectable?: boolean;
    disableHideColumn?: boolean;
    pageSizeOptions?: number[] | "alternativ";
    maxHeight?: string;
    onRowClick?: (_event: Record<string, unknown>, rowData: Record<string, string>) => void;
    rowColorScheme?: (rowData: Record<string, string>) => string;
    detailPanel?: IDetailsPanel[];
    customColumnWidth?: boolean;
    isExportable?: boolean;
    showEditPendingStateIcons?: boolean;
    showEditPendingStateIconsForFlattenJSON?: boolean;
    addFunction?: ITableAddRow;
    customEdit?: {
        onRowAdd: (newData: never) => Promise<void>;
        onRowUpdate: (newData: never, oldData: never) => Promise<void>;
        onRowDelete: (oldData: never) => Promise<void>;
    };
}

type TableColumnType = "boolean" | "numeric" | "date" | "datetime" | "time" | "currency" | "percent" | "icon" | "list";

export interface ITableColumn {
    field: string;
    title: string;
    tooltip?: string;
    type?: TableColumnType;
    dateSettings?: { locale: string };
    customSort?: (a: unknown, b: unknown) => number;
    conditionalRender?: (rowData: Record<string, string>) => boolean;
    defaultSort?: "asc" | "desc";
    hidden?: boolean;
    combinedFields?: string[];
    combinedFieldsSeparator?: string;
    combinedFieldsCalculation?: "SUM" | "DIV";
    width?: string;
    icons?: Record<string, IIcon>;
    hiddenByColumnsButton?: boolean;
    filter?: (option: string) => string;
    listDefinition?: {
        listAttribute: string;
        pathToList: string;
    };
    export?: boolean;
}

interface IIcon {
    src: React.JSX.Element;
    color?: string;
    rotateDeg?: number;
}

export interface ITableRowSelection {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect: (rowData: any) => void;
    onDeselect: (rowData: Record<string, string>) => void;
    selectedRows: (number | undefined)[];
    selector: string;
}

export interface ITableContextMenu {
    contextActions: IContextMenuAction[];
    setCurrentRowData?: Dispatch<SetStateAction<Record<string, string | number>>>;
}

export interface ITableContextMenuElement {
    element: React.JSX.Element;
    tableElement: ITableContextMenu;
}

export interface ITableColumnSort {
    columnId: number;
    direction: "asc" | "desc";
}

export interface ITableAddRow {
    topicName: string;
    linkTo: string;
    data?: Record<string, string>;
}

export interface IDetailsPanel {
    tooltip: string;
    render: (rowData: Record<string, string>) => React.JSX.Element;
}
//coming from https://material-table.com/#/docs/all-props
interface IAction {
    disabled?: boolean;
    hidden?: boolean;
    icon?: string | (() => ReactElement);
    iconProps?: Record<string, unknown>;
    isFreeAction?: boolean;
    onClick?: () => void;
    tooltip?: string;
}

export const getContextMenu = (contextMenu?: ITableContextMenu | ITableContextMenuElement): ITableContextMenu => {
    if (contextMenu && (contextMenu as ITableContextMenuElement).tableElement)
        return (contextMenu as ITableContextMenuElement).tableElement;
    return contextMenu as ITableContextMenu;
};

export const Table = (props: ITable): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate: NavigateFunction = useNavigate();

    // eslint-disable-next-line no-magic-numbers
    const PAGE_SIZE_OPTIONS: number[] = [25, 50, 100];
    // eslint-disable-next-line no-magic-numbers
    const PAGE_SIZE_OPTIONS_ALTERNATIV: number[] = [5, 10, 20];
    //action buttons
    const actions: IAction[] = [];
    props.addFunction &&
        actions.push({
            icon: "add",
            tooltip: "Neue " + props.addFunction.topicName + " erstellen",
            isFreeAction: true,
            onClick: () => {
                props.addFunction && navigate(props.addFunction.linkTo, { state: { data: props.addFunction.data } });
            },
        });

    const germanLocalization = {
        body: {
            emptyDataSourceMessage: "Keine Daten vorhanden",
            addTooltip: "Hinzufügen",
            deleteTooltip: "Löschen",
            editTooltip: "Bearbeiten",
            filterRow: {
                filterTooltip: "Filter",
            },
            editRow: {
                deleteText: "Diese Zeile wirklich löschen?",
                cancelTooltip: "Abbrechen",
                saveTooltip: "Speichern",
            },
        },
        grouping: {
            placeholder: "Spalten ziehen ...",
            groupedBy: "Gruppiert nach:",
        },
        header: {
            actions: "Aktionen",
        },
        pagination: {
            labelRows: "Zeilen",
            labelRowsPerPage: "Zeilen pro Seite:",
            firstAriaLabel: "Erste Seite",
            firstTooltip: "Erste Seite",
            previousAriaLabel: "Vorherige Seite",
            previousTooltip: "Vorherige Seite",
            nextAriaLabel: "Nächste Seite",
            nextTooltip: "Nächste Seite",
            lastAriaLabel: "Letzte Seite",
            lastTooltip: "Letzte Seite",
        },
        toolbar: {
            addRemoveColumns: "Spalten ein-/ausblenden",
            nRowsSelected: "{0} Zeile(n) ausgewählt",
            showColumnsTitle: "Spalten ein-/ausblenden",
            showColumnsAriaLabel: "Spalten ein-/ausblenden",
            exportTitle: "Export",
            exportAriaLabel: "Export",
            exportName: "Export als CSV",
            searchTooltip: "Suchen",
            searchPlaceholder: "Suchen",
        },
    };

    //deep copy old array to not override references
    const columns: ITableColumn[] = cloneDeep(props.columnsDefinition);
    //add edit pending state icon column
    if (props.showEditPendingStateIcons || props.showEditPendingStateIconsForFlattenJSON) {
        columns.unshift({
            field: props.showEditPendingStateIcons ? "state" : "editPendingData.state",
            title: "",
            type: "icon",
            //used to make column as small as possible
            width: "0px",
            icons: {
                " ": {
                    src: <SaveIcon />,
                },
                T: {
                    src: <DoneIcon />,
                    color: Colors.orange,
                },
                A: {
                    src: <DoneAllIcon />,
                    color: Colors.darkGreen,
                },
                DEFAULT: {
                    src: <SkipNextIcon />,
                    color: Colors.lightGreen,
                    rotateDeg: 90,
                },
            },
            hiddenByColumnsButton: true,
            export: false,
        });
    }

    //context menu
    const [event, setEvent] = useState<React.MouseEvent<HTMLButtonElement> | null>(null);

    const sorting: ITableColumnSort = useSelector(
        (state: RootState) => state.userConfiguration.tables[props.identifier]
    )?.sorting;

    const hiddenColumns: number[] = useSelector(
        (state: RootState) => state.userConfiguration.tables[props.identifier]
    )?.hidden;

    //concatenate columns
    columns.forEach((column: ITableColumn, index: number) => {
        if (column.combinedFields) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.tableData.forEach((data: any) => {
                const finalData: Record<string, string> = flattenJson(data);
                //get all data elements
                const dataElements: string[] = [finalData[column.field]];

                if (column.combinedFields) {
                    column.combinedFields.forEach((field: string) => {
                        if (finalData[field]) {
                            dataElements.push(finalData[field]);
                        } else if (field.charAt(0) === "-") {
                            //negate combine field value if it starts with a minus
                            dataElements.push(String(Number(finalData[field.slice(1)]) * -1));
                        }
                    });
                    if (column.combinedFieldsCalculation === "SUM") {
                        data[column.field + column.combinedFields.join("")] = dataElements
                            .reduce((a, b) => String(Number(a) + Number(b)))
                            .toString();
                    } else if (column.combinedFieldsCalculation === "DIV") {
                        data[column.field + column.combinedFields.join("")] = dataElements
                            .reduce((a, b) => String(Number(a) / Number(b)))
                            .toString();
                    } else {
                        //join all data elements and write them in new attribute (concatenate one)
                        data[column.field + column.combinedFields.join("")] = dataElements.join(
                            column.combinedFieldsSeparator
                        );
                    }
                }
            });
            //rename columns array field to the concatenate one
            columns[index].field = column.field + column.combinedFields.join("");
        }
    });

    //restore sorting
    if (sorting && sorting.columnId !== -1) {
        columns[sorting.columnId].defaultSort = sorting.direction;
    }

    columns.forEach((column: ITableColumn) => {
        //add customSort to each column
        column.customSort = (a: unknown, b: unknown) => customSort(a, b, column.field, column.type);
        //add hiddenByColumnsButton for hidden columns
        if (column.hidden) column.hiddenByColumnsButton = true;
    });

    //restore hidden columns
    if (hiddenColumns) {
        hiddenColumns.forEach((columnId: number) => {
            columns[columnId].hidden = true;
        });
    }

    //TODO :: upgrade to react 18 to use useId Hook
    let id = 0;
    const getId = () => {
        return "tableCell" + ++id;
    };

    const handleSelection = (select: boolean, rowData: Record<string, string>) => {
        if (select) {
            //selected
            props.selection?.onSelect(rowData);
        } else {
            //deselected
            props.selection?.onDeselect(rowData);
        }
    };

    //fuction to read changedData (from editPending) and display it instead of the original data + call customRender functions for special types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getTableValue = (propsCell: any): string | React.JSX.Element => {
        let value: string = "";

        if (propsCell.rowData && propsCell.columnDef) {
            const fieldName: string = propsCell.columnDef.field;

            const type: TableColumnType = propsCell.columnDef.type;

            let changedOrOriginalValue: string;
            if (type === "list" && Array.isArray(propsCell.rowData[propsCell.columnDef.listDefinition.pathToList])) {
                changedOrOriginalValue = propsCell.rowData[propsCell.columnDef.listDefinition.pathToList]
                    .map((item: Record<string, unknown>) =>
                        getField(item, propsCell.columnDef.listDefinition.listAttribute)
                    )
                    .join(", ");
            } else {
                changedOrOriginalValue = getChangedOrOriginalValue(propsCell.rowData, fieldName);
            }

            //check conditional render (render cell only when true)
            if (
                propsCell.columnDef.conditionalRender ? propsCell.columnDef.conditionalRender(propsCell.rowData) : true
            ) {
                if (type === "date") {
                    //call custom converter based on type
                    if (propsCell.columnDef.combinedFields) {
                        const dates: string[] = [];

                        dates.push(changedOrOriginalValue);

                        propsCell.columnDef.combinedFields.forEach((combinedField: string) => {
                            dates.push(getChangedOrOriginalValue(propsCell.rowData, combinedField));
                        });
                        value = customDatesValueGetterV2(dates);
                    } else {
                        value = customDateValueGetterV2(changedOrOriginalValue);
                    }
                } else if (type === "numeric") {
                    value = customNumberFormatV2(Number(changedOrOriginalValue));
                } else if (type === "percent") {
                    value = convert(Number(changedOrOriginalValue), type);
                } else if (type === "datetime") {
                    value = convert(changedOrOriginalValue, type);
                } else if (type === "icon") {
                    const isDataFlatten: boolean =
                        (props.showEditPendingStateIcons && !props.showEditPendingStateIcons) ||
                        !!props.showEditPendingStateIconsForFlattenJSON;
                    const icon: IIcon =
                        propsCell.columnDef.icons[
                            getChangedValue(propsCell.rowData, fieldName, isDataFlatten) ?? "DEFAULT"
                        ];
                    return (
                        <div
                            style={{
                                color: icon.color,
                                rotate: icon.rotateDeg + "deg",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {icon.src}
                        </div>
                    );
                } else {
                    value = changedOrOriginalValue;
                }

                //apply filter
                if (propsCell.columnDef.filter) value = propsCell.columnDef.filter(value);
            }
        }

        return value;
    };

    const getPagesizeOptions = (): number[] => {
        if (props.pageSizeOptions) {
            return props.pageSizeOptions === "alternativ" ? PAGE_SIZE_OPTIONS_ALTERNATIV : props.pageSizeOptions;
        } else {
            return PAGE_SIZE_OPTIONS;
        }
    };

    const getPagesize = (): number => {
        if (props.pageSizeOptions) {
            return props.pageSizeOptions === "alternativ" ? PAGE_SIZE_OPTIONS_ALTERNATIV[0] : props.pageSizeOptions[0];
        } else {
            return PAGE_SIZE_OPTIONS[0];
        }
    };

    const [contextRowSelector, setContextRowSelector] = useState<string>();
    const resetContextRowSelector = () => {
        setContextRowSelector("");
    };

    return (
        <div id="tableContainer" style={{ height: "100%" }}>
            {props.contextMenu !== undefined ? (
                <ContextMenu
                    contextActions={props.contextMenu.contextActions}
                    event={event}
                    resetContextRowSelector={resetContextRowSelector}
                />
            ) : null}

            {/* There is a TS problem with the library I can't fix. So I have to use this hard way here. - JESCHKE */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}

            <MaterialTable
                title={props.title}
                columns={columns}
                data={props.tableData}
                localization={germanLocalization}
                onRowClick={(
                    rowClickEvent: Record<string, unknown>,
                    rowData: Record<string, string>,
                    togglePanel: () => void
                ) => {
                    if (props.onRowClick) {
                        props.onRowClick(rowClickEvent, rowData);
                        console.log("data2" + rowData);
                    }
                    if (props.selection) {
                        handleSelection(
                            !props.selection.selectedRows.includes(Number(rowData[props.selection.selector])),
                            rowData
                        );
                    }
                    if (props.detailPanel) {
                        togglePanel();
                    }
                }}
                editable={props.customEdit}
                components={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Row: (propsRow: any) => {
                        return (
                            <MTableBodyRow
                                {...propsRow}
                                onContextMenu={(contexMenuEvent: React.MouseEvent<HTMLButtonElement>) => {
                                    if (props.contextMenu !== undefined) {
                                        const contextRowIndex: number =
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            ((contexMenuEvent.target as HTMLElement).parentElement as any)?.rowIndex;
                                        setContextRowSelector(":nth-of-type(" + contextRowIndex + ")");

                                        setEvent(contexMenuEvent);

                                        const element = document.getElementById(
                                            (contexMenuEvent.target as HTMLElement).id
                                        );

                                        if (props.contextMenu.setCurrentRowData) {
                                            props.contextMenu.setCurrentRowData(
                                                props.tableData[Number(element?.getAttribute("tableindex"))]
                                            );
                                        }
                                    }
                                }}
                                sx={{
                                    ":nth-of-type(2n-1)": { background: Colors.grey },
                                    ":hover": {
                                        background: Colors.blue + " !important",
                                        color: Colors.white,
                                        cursor:
                                            props.selection || props.onRowClick || props.detailPanel
                                                ? "pointer"
                                                : "default !important",
                                    },
                                    [contextRowSelector as string]: { background: Colors.blue, color: Colors.white },
                                }}
                            />
                        );
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Cell: (propsCell: any) => {
                        return (
                            <MTableCell
                                {...propsCell}
                                id={getId()}
                                tableindex={propsCell.rowData.tableData.index}
                                sx={
                                    props.selection !== undefined
                                        ? {
                                              background: props.selection.selectedRows.includes(
                                                  propsCell.rowData[props.selection.selector]
                                              )
                                                  ? Colors.darkBlue
                                                  : "initial",
                                              color: props.selection.selectedRows.includes(
                                                  propsCell.rowData[props.selection.selector]
                                              )
                                                  ? Colors.white + "!important"
                                                  : "initial",
                                          }
                                        : {}
                                }
                                value={getTableValue(propsCell)}
                                style={{ whiteSpace: "pre-wrap" }}
                            />
                        );
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Header: (propsHeader: any) => {
                        return (
                            <MTableHeader
                                {...propsHeader}
                                sx={{
                                    whiteSpace: "pre-line",
                                }}
                            />
                        );
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Pagination: (propsPagination: any) => (
                        <TablePagination
                            {...propsPagination}
                            labelDisplayedRows={({ from, to, count }) => from + "-" + to + " von " + count}
                        />
                    ),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Actions: (propsHeader: any) => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <MTableActions {...propsHeader} />
                            </div>
                        );
                    },
                }}
                options={{
                    tableLayout: props.customColumnWidth ? "fixed" : "auto",
                    columnResizable: true,
                    maxBodyHeight: props.maxHeight ? props.maxHeight : "initial",
                    minBodyHeight: props.maxHeight ? props.maxHeight : "initial",
                    //Export to CSV
                    exportMenu: props.isExportable
                        ? [
                              {
                                  label: "Export CSV (Seite)",
                                  exportFunc: (cols: Record<string, string>[], datas: Record<string, string>[]) => {
                                      return ExportCsv(updateColsForExport(cols), datas, "", ";");
                                  },
                              },
                              {
                                  label: "Export CSV (Alles)",
                                  exportFunc: (cols: Record<string, string>[]) => {
                                      return ExportCsv(updateColsForExport(cols), props.tableData, "", ";");
                                  },
                              },
                          ]
                        : [],

                    headerStyle: {
                        position: "sticky",
                        top: 0,
                        zIndex: 998,
                        fontSize: "0.875rem",
                        color: Colors.borderDark,
                        fontWeight: "bold",
                    },
                    rowStyle: (rowData: Record<string, string>) => {
                        return {
                            backgroundColor: props.rowColorScheme ? props.rowColorScheme(rowData) : "",
                            whiteSpace: "nowrap",
                            fontFamily: "Roboto",
                            fontSize: "1rem",
                        };
                    },
                    //JESCHKE :: disable selection with comboboxes; keep in code to enable it later if necessary
                    /* selection: props.selection !== undefined,
                    selectionProps: (rowData: never) =>
                        props.selection !== undefined
                            ? {
                                  checked: props.selection.selectedRows.includes(rowData[props.selection.selector]),
                              }
                            : null, */
                    showTextRowsSelected: false,
                    toolbar: !props.disableHideColumn || props.title || props.isExportable || props.addFunction,
                    showTitle: props.title,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: getPagesizeOptions(),
                    pageSize: getPagesize(),
                    doubleHorizontalScroll: true,
                    showSelectAllCheckbox: !props.disableAllSelectable,
                    search: false,
                    columnsButton: !props.disableHideColumn,
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChangeColumnHidden={(column: any, hidden: boolean) => {
                    if (column.tableData) {
                        if (hidden) {
                            dispatch(addTableHide({ indentifier: props.identifier, columnId: column.tableData.id }));
                        } else {
                            dispatch(removeTableHide({ indentifier: props.identifier, columnId: column.tableData.id }));
                        }
                    }
                }}
                onOrderChange={(sortingByColumn: number, sortDirection: "asc" | "desc") => {
                    dispatch(
                        setTableSorting({
                            identifier: props.identifier,
                            sorting: { columnId: sortingByColumn, direction: sortDirection },
                        })
                    );
                }}
                detailPanel={props.detailPanel}
                // detailPanel={(rowData: Record<string, any>) => {
                //     props.detailPanel ? props.detailPanel.render(rowData) : null;
                // }}
                //JESCHKE :: disable selection with comboboxes; keep in code to enable it later if necessary
                /* // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onSelectionChange={(_data: never[], rowData?: any) => {
                    handleSelection(rowData.tableData.checked, rowData);
                }} */
                actions={actions}
            />
        </div>
    );
};

const updateColsForExport = (cols: Record<string, string>[]): Record<string, string>[] => {
    //remove \n from columnNames and replace with " "
    return cols.map((col: Record<string, string>) => ({ ...col, title: col.title.replaceAll("\n", " ") }));
};
