/* author: SINDHU KANNAN  PAL PRASHANT */
import { Overview } from "components/Overview";
import { IOverviewTile } from "components/OverviewTile";
import { IOverviewData } from "components/OverviewData";
import { RootState } from "application/redux/Store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { IdSelector } from "components/TBD/IdSelector";
import { SecCorpActionDetails, SecSwitchingDetails } from "./TableForSingleRecord";
import { SecurityMasterDetails, SecurityIncomeDetails } from "./SecurityDataDetails";
import { Params, useParams } from "react-router";

export const SecurityOverview = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const securityData: Record<string, string> = useSelector((state: RootState) => state.security);

    const [securityStaticData, setSecurityStaticData] = useState<Record<string, string>[]>([]);
    const [secIncomeData, setSecIncomeData] = useState<Record<string, string>[]>([]);
    const [corporateActionsData, setCorporateActionsData] = useState<Record<string, string>[]>([]);
    const [exchangeData, setExchangeData] = useState<Record<string, string>[]>([]);
    const [securityId, setSecurityId] = useState(params.securityId ? params.securityId : "");

    useEffect(() => {
        sendGetRequest({
            path: "/security/securities/getSecurityOverview",
            params: { securityId: Number(securityId) },
        }).then((response) => {
            setSecurityStaticData([response.data]);
        });

        sendGetRequest({
            path: "/security/secCorpAction/getCorporateActionBySecurityId",
            params: { securityId: Number(securityId) },
        }).then((response) => {
            setCorporateActionsData(response.data);
        });
        sendGetRequest({
            path: "/security/secswitching/getSecSwitchingDataBySecurityId",
            params: { securityId: Number(securityId) },
        }).then((response) => {
            setExchangeData(response.data);
        });
        sendGetRequest({
            path: "/security/income/getSecIncomeDataBySecurityId",
            params: { securityId: Number(securityId) },
        }).then((response) => {
            setSecIncomeData(response.data);
            console.log(exchangeData);
        });
    }, [securityId]);
    const securityInfo: IOverviewData = {
        headline: {
            title: "Informationen",
        },
        attributes: [
            { field: "categoryFlag", headerName: "Kategorie" },
            { field: "wkn", headerName: "WKN" },
            { field: "isin", headerName: "ISIN" },
            { field: "shortName", headerName: "WP-Kurzbezeichnung" },
            { field: "longName", headerName: "WP-Langbezeichnung" },
            { field: "gd198B", headerName: "GD198B" },
        ],
        columnNumber: 4,
        data: securityData,
    };

    const securityStaticDataTile: IOverviewTile = {
        headline: {
            title: "Stammdaten",
        },
        cardDefinition: [
            [
                { field: "country", labels: { mapping: { DEFAULT: "Land" } }, isBold: true },
                { field: "emitter", labels: { mapping: { DEFAULT: "Emittentennummer" } }, isBold: true },
                { field: "secUnit", labels: { mapping: { DEFAULT: "Effektennotiz" } }, isBold: true },
                { field: "currency", labels: { mapping: { DEFAULT: "Währung" } }, isBold: true },
            ],
        ],
        orientation: "keyValuePair",
        idFields: [""],
        typeField: "",
        detailView: (): React.JSX.Element => <SecurityMasterDetails id={securityId} />,
        data: securityStaticData,
    };

    const yieldData: IOverviewTile = {
        headline: {
            title: "Ertragsdaten",
        },

        cardDefinition: [
            [
                {
                    field: "ed005",
                    labels: {
                        mapping: {
                            DEFAULT: "Zahlungsart",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "ed008a",
                    labels: {
                        mapping: {
                            DEFAULT: "Gesamtertrag",
                        },
                    },
                    combine: {
                        mapping: {
                            DEFAULT: {
                                combinedFields: [{ field: "ed011" }],
                                combineFormat: "{0} {1}",
                            },
                        },
                    },
                    isBold: true,
                },
                {
                    labels: {
                        mapping: {
                            DEFAULT: "Ex-Tag/Zahltag",
                        },
                    },
                    field: "exDate",
                    type: "date",
                    combine: {
                        mapping: {
                            DEFAULT: {
                                combinedFields: [{ field: "ed021", type: "date" }],
                                combineFormat: "{0} / {1}",
                            },
                        },
                    },
                    isBold: true,
                },
                {
                    field: "bidData.bidWm",
                    labels: {
                        mapping: {
                            DEFAULT: "BID-Nr.",
                        },
                    },
                    isBold: true,
                },
            ],
        ],
        orientation: "keyValuePair",
        needReadMoreButton: true,
        idFields: ["bidData.bidId"],
        typeField: "",
        detailView: (ids: string[]): React.JSX.Element => <SecurityIncomeDetails id={ids[0]} />,
        data: secIncomeData,
    };
    const corporateActionsDataInfo: IOverviewTile = {
        headline: {
            title: "Kapitalmaßnahmen",
        },
        cardDefinition: [
            [
                {
                    field: "exDate",
                    type: "date",
                    labels: {
                        mapping: {
                            DEFAULT: "Ex-Tag",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "corpLawActivity",
                    labels: {
                        mapping: {
                            DEFAULT: "KZ ges. Vorgänge",
                        },
                    },
                    isBold: true,
                },
            ],
        ],

        orientation: "keyValuePair",
        idFields: ["bidData.bidId"],
        typeField: "",
        detailView: (ids: string[]): React.JSX.Element => (
            <SecCorpActionDetails
                id={+ids[0]}
                wkn={securityData.wkn}
                isin={securityData.isin}
                shortname={securityData.shortName}
            />
        ),
        data: corporateActionsData,
        needReadMoreButton: true,
    };
    const exchangeDataInfo: IOverviewTile = {
        headline: {
            title: "Umtausch",
        },
        cardDefinition: [
            [
                {
                    field: "exDate",
                    type: "date",
                    labels: {
                        mapping: {
                            DEFAULT: "Ex-Tag",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "corpLawActivity",
                    labels: {
                        mapping: {
                            DEFAULT: "KZ ges. Vorgänge",
                        },
                    },
                    isBold: true,
                },
            ],
        ],
        orientation: "keyValuePair",
        idFields: ["bidData.bidId"],
        typeField: "",
        detailView: (ids: string[]): React.JSX.Element => (
            <SecSwitchingDetails
                id={+ids[0]}
                wkn={securityData.wkn}
                isin={securityData.isin}
                shortname={securityData.shortName}
            />
        ),
        data: exchangeData,
        needReadMoreButton: true,
    };

    const tiles: IOverviewTile[] = [securityStaticDataTile, yieldData, corporateActionsDataInfo, exchangeDataInfo];

    return (
        <div>
            {/*TODO: Remove ID Selector once security ID type is changed to number*/}
            <IdSelector label="Security Id" setId={setSecurityId} />
            <Overview title="Übersicht Wertpapiere" generalInfo={securityInfo} tiles={tiles} hideEditButton />
        </div>
    );
};
