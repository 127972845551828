/* author: JESCHKE Moritz */

import { ICard, ICardAttribute, OverviewCard } from "./OverviewCard";

import { IOverviewHeadLine, OverviewHeadLine } from "./OverviewHeadLine";
import { IOverviewDataViewWithData, OverviewDataView } from "./OverviewDataView";
import {
    createKey,
    hasChangeInSiblings,
    hasChangeInSiblingsArray,
    hasChangedValues,
    hasChangedValuesArray,
} from "./Converter";
import { ButtonPrimary } from "./Button";
import { useState } from "react";
import { IOverviewViewStackAdder } from "./Overview";

export interface IOverviewTile extends ICard {
    data: Record<string, string>[];
    headline: IOverviewHeadLine;
    cardDefinition: ICardAttribute[][];
    icons?: {
        mapping: { [key: string]: React.JSX.Element };
    };
    cardWithoutBorder?: IOverviewDataViewWithData;
    needReadMoreButton?: boolean;
    versionView?: React.JSX.Element;
}

type IOverviewTileWithSetter = IOverviewTile & IOverviewViewStackAdder;

export const OverviewTile = (props: IOverviewTileWithSetter): React.JSX.Element => {
    const DEFAULT_CURRENT_DATA_COUNT: number = 2;
    const CURRENT_DATA_LOAD_MORE: number = 10;
    const [currentDataCount, setCurrentDataCount] = useState<number>(DEFAULT_CURRENT_DATA_COUNT);
    const initialData = props.data.slice(0, currentDataCount);
    const data: Record<string, string>[] = props.needReadMoreButton === true ? initialData : props.data;
    const readMore = () => {
        setCurrentDataCount(currentDataCount + CURRENT_DATA_LOAD_MORE);
    };
    const resetAll = () => {
        setCurrentDataCount(DEFAULT_CURRENT_DATA_COUNT);
    };

    return (
        <div style={{ width: "100%" }}>
            <OverviewHeadLine
                title={props.headline.title}
                onInfoClick={props.headline.onInfoClick}
                onTimeStampClick={
                    props.versionView
                        ? () => {
                              props.versionView &&
                                  props.viewStackAdd &&
                                  props.viewStackAdd(props.versionView, props.headline.title);
                          }
                        : undefined
                }
                hasChangedData={
                    hasChangedValues(props.cardWithoutBorder ? props.cardWithoutBorder.data : {}) ||
                    hasChangedValuesArray(props.data) ||
                    hasChangeInSiblings(props.cardWithoutBorder ? props.cardWithoutBorder.data : {}) ||
                    hasChangeInSiblingsArray(props.data)
                }
                hideTimeSliceButton={props.headline.hideTimeSliceButton}
            />
            {props.cardWithoutBorder !== undefined && (
                <OverviewDataView
                    attributes={props.cardWithoutBorder.attributes}
                    columnNumber={props.cardWithoutBorder.columnNumber}
                    data={props.cardWithoutBorder.data}
                />
            )}
            {data.map((CardData: Record<string, string>) => {
                console.log(props.headline.title, "props.headline.title");
                console.log(CardData.type, "CardData");
                return (
                    <OverviewCard
                        key={createKey("overviewTile", CardData[props.idFields[0]])}
                        icon={props.typeField ? props.icons?.mapping[CardData[props.typeField]] : undefined}
                        content={props.cardDefinition}
                        orientation={props.orientation}
                        data={CardData}
                        idFields={props.idFields}
                        typeField={props.typeField ? props.typeField : ""}
                        detailView={props.detailView}
                        onClick={props.onClick}
                        viewStackAdd={props.viewStackAdd}
                        detailViewHeadline={props.headline.title}
                        disabled={props.disabled}
                    />
                );
            })}
            {props.needReadMoreButton && props.data.length !== 0 && (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <ButtonPrimary
                        title={"Weiter"}
                        onClick={readMore}
                        disabled={currentDataCount >= props.data.length}
                    />
                    {currentDataCount !== DEFAULT_CURRENT_DATA_COUNT && (
                        <ButtonPrimary title={"Reset"} onClick={resetAll} />
                    )}
                </div>
            )}
        </div>
    );
};
