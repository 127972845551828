/* author: JESCHKE Moritz */

import { useEffect, useState } from "react";
import { IEditDataKeys, IEditDataViewUserConfiguration, IEditDataViewUserConfigurationWithData } from "./FormEditView";
import { FormEditData, IFormEditDataCommon } from "./FormEditData";
import { Loader } from "components/Loader";
import { RerenderHook, useRerender } from "components/useRerender";
import { useAppDispatch } from "application/redux/Hooks";
import { AppDispatch, RootState } from "application/redux/Store";
import { setAction } from "application/redux/slices/FormStateSlice";
import { useSelector } from "react-redux";
import { ITableColumn } from "components/Table";

interface IEditMask extends IFormEditDataCommon {
    completeEditInfo: IEditDataViewUserConfiguration[];
    section?: string;
    isCancelation?: boolean;
    duplication?: boolean;
}

export const TECHNICAL_EDIT_COLUMNS: ITableColumn[] = [
    { field: "insertTS", title: "Einfügedatum", type: "datetime" },
    { field: "updateTS", title: "Aktualisierungsdatum", type: "datetime" },
    { field: "changeUser", title: "Ändernder Benutzer" },
    { field: "releaseUser", title: " Freigebender Benutzer" },
];

export const EditMask = (props: IEditMask): React.JSX.Element => {
    const rerender: RerenderHook = useRerender();

    const ediGlobal: IEditDataViewUserConfigurationWithData[] = (
        props.completeEditInfo as IEditDataViewUserConfigurationWithData[]
    ).filter((f: IEditDataViewUserConfiguration) => (props.section ? f.headline === props.section : true));

    const [finalEdi, setFinalEdi] = useState<IEditDataViewUserConfigurationWithData[]>();

    const dispatch: AppDispatch = useAppDispatch();
    const formAction = useSelector((state: RootState) => state.formAction); // Assuming your slice is stored as formAction in the root state
    console.log(formAction);

    useEffect(() => {
        if (props.isCancelation) {
            dispatch(setAction({ actionType: "cancel" }));
        } else if (props.duplication) {
            dispatch(setAction({ actionType: "duplicate" }));
        } else if (window.location.href.includes("/new")) {
            dispatch(setAction({ actionType: "new" }));
        } else {
            dispatch(setAction({ actionType: "default" }));
        }
        setFinalEdi(undefined);
        Promise.all(
            ediGlobal.map((edi: IEditDataViewUserConfiguration) =>
                props.section ? edi.versionRequest() : edi.latestVersionRequest()
            )
        )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response: any) => {
                ediGlobal.forEach((edi: IEditDataViewUserConfiguration, index: number) => {
                    //update data with response
                    ediGlobal[index].data = Array.isArray(response[index].data)
                        ? response[index].data
                        : [response[index].data];

                    //updateFields for canceling
                    // eslint-disable-next-line no-prototype-builtins
                    if (edi.updateFields && edi.updateFields.condition) {
                        for (const element of ediGlobal[index].data) {
                            const changedData: Record<string, unknown> = edi.updateFields.method(element);

                            //remove id to trigger new creation on save
                            delete element["id"];

                            (element as Record<string, unknown>)["editPendingData"] = { changedData: changedData };
                        }
                    }

                    //Creates Duplicate transaction
                    if (edi.duplicate && edi.duplicate.condition) {
                        for (const element of ediGlobal[index].data) {
                            //remove id to trigger new creation on save
                            delete element["id"];
                            //remove state of transaction to duplicate transactions that are in Approval state
                            if (element["editPendingData"]) {
                                delete (element["editPendingData"] as unknown as Record<string, string>)["state"];
                            }
                        }
                    }

                    //add technical columns
                    if ((ediGlobal[index].attributes.at(-1) as IEditDataKeys).field !== "releaseUser") {
                        ediGlobal[index].attributes.push(
                            { field: "insertTS", headerName: "Einfügedatum", type: "datetime", displayOnly: true },
                            {
                                field: "updateTS",
                                headerName: "Aktualisierungsdatum",
                                type: "datetime",
                                displayOnly: true,
                            },
                            { field: "changeUser", headerName: "Ändernder Benutzer", displayOnly: true },
                            { field: "releaseUser", headerName: "Freigebender Benutzer", displayOnly: true }
                        );
                    }
                });
            })
            .then(() => {
                setFinalEdi(ediGlobal);
            });
    }, [rerender.render]);

    return finalEdi ? (
        <FormEditData
            id01={props.id01}
            title={props.title}
            editDataInfo={finalEdi}
            rerender={rerender}
            renderOnlySection={props.section}
            parentIdentifier={props.parentIdentifier}
            overrideRootLevelForInsert={props.overrideRootLevelForInsert}
            overrideEditValues={props.overrideEditValues}
            overrideDeleteLevel={props.overrideDeleteLevel}
            overrideApprovalLevel={props.overrideApprovalLevel}
        />
    ) : (
        <Loader />
    );
};
