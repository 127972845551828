/* author: Pal Prashant */

//TODO :: check Person overview if we can import columns instead of redefine them

import { Overview } from "components/Overview";
import { IOverviewTile } from "components/OverviewTile";
import { IOverviewData } from "components/OverviewData";
import { useEffect, useState } from "react";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router";
import { isReponseEmpty, sendGetRequest } from "application/api/HttpRequestHandler";
import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import { FsaDetails } from "pages/customer-static-data/FsaDetails";
import { NvDetails } from "pages/customer-static-data/NvDetails";
import { AccountDetails } from "pages/account-data/AccountDetails";
import { FuVersions } from "./FuVersions";

export const FuOverview = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const navigate: NavigateFunction = useNavigate();

    const fiscalUnitId: number = Number(params.fiscalUnitId);

    const [accountData, setAccountData] = useState([]);
    const [fsaData, setFsaData] = useState({});
    const [nvData, setNvData] = useState({});

    const [genralInfoFuData, setGenralInfoFuData] = useState<Record<string, string>>({});

    const [assignPersonfuData, setAssignPersonfuData] = useState<Record<string, string>[]>([]);

    const personInfo: IOverviewData = {
        headline: {
            title: "Allgemeine Informationen",
        },
        attributes: [
            { field: "fiscalUnit", headerName: "Steuerliche Einheit" },
            { field: "fiscalUnitType", headerName: "Typ der Steuerlichen Einheit" },
            { field: "legalFormCategory", headerName: "Rechtsform" },
            { field: "customerSegment", headerName: "Kundengruppe" },
            { field: "communityFlag", headerName: "Personengemeinschaft" },
            { field: "churchTaxRetention", headerName: "Kirchensteuer" },
            { field: "validFrom", headerName: "Gültig von", type: "date" },
            { field: "validTo", headerName: "Gültig bis", type: "date" },
        ],
        columnNumber: 4,
        data: genralInfoFuData,
        versionView: <FuVersions fiscalUnitId={fiscalUnitId} />,
    };

    const assignPersonInfo: IOverviewTile = {
        headline: {
            title: "Zugeordnete Person(en)",
        },

        cardDefinition: [
            [
                {
                    field: "firstName",
                    labels: {
                        mapping: {
                            MAIN: "MAIN",
                            MCOM: "MCOM",
                            MFIB: "MFIB",
                            FIBE: "FIBE",
                            DUPL: "DUPL",
                        },
                    },
                    isBold: true,
                    combine: {
                        mapping: {
                            DEFAULT: {
                                combinedFields: [{ field: "lastName" }],
                                combineFormat: "{0} {1}",
                            },
                        },
                    },
                },
                {
                    field: "town",

                    labels: {
                        mapping: {
                            DEFAULT: "Ort",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "tin",
                    labels: {
                        mapping: {
                            DEFAULT: "Tin",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "ratioGrossYield",
                    type: "percent",

                    labels: {
                        mapping: {
                            DEFAULT: "Anteil",
                        },
                    },
                    isBold: true,
                },
            ],
        ],
        orientation: "keyValuePair",
        data: assignPersonfuData,
        typeField: "type",
        idFields: ["personId"],
        onClick: (ids: string[]): void => {
            navigate("/overviewPerson/" + ids[0]);
        },
    };

    const getFsaNvData = (): Record<string, string>[] => {
        if (!isReponseEmpty(fsaData) && !isReponseEmpty(nvData)) {
            return [{ ...fsaData }, { ...nvData }];
        } else if (isReponseEmpty(fsaData) && isReponseEmpty(nvData)) {
            return [{ type: "FE" }, { type: "NE" }];
        } else if (isReponseEmpty(fsaData)) {
            return [{ type: "FE" }, { ...nvData }];
        } else {
            return [{ ...fsaData }, { type: "NE" }];
        }
    };
    const taxdetailInfo: IOverviewTile = {
        headline: {
            title: "FSA/NV Daten",
        },
        cardDefinition: [
            [
                {
                    field: "exemptAmount",
                    combine: {
                        mapping: {
                            F: {
                                combinedFields: [{ field: "fiscalYear" }, { field: "taxDate", type: "date" }],
                                combineFormat: "Betrag: {0}€ (Für {1})\nGültig von: {2}-",
                            },
                            FE: {
                                combinedFields: [],
                                combineFormat: "Betrag:\nGültig von:",
                            },
                            N: {
                                combinedFields: [{ field: "nvType" }, { field: "validFrom", type: "date" }],
                                combineFormat: "Typ: {1}\nGültig von: {2} -",
                            },
                            NE: {
                                combinedFields: [],
                                combineFormat: "Typ:\nGültig von:",
                            },
                        },
                    },
                    labels: {
                        mapping: {
                            F: "FSA (eingereicht)",
                            FE: "FSA",
                            N: "NV (eingereicht)",
                            NE: "NV",
                        },
                    },
                },
                {
                    field: "availmentFsa",
                    combine: {
                        mapping: {
                            F: { combinedFields: [], combineFormat: "{0}€" },
                            N: { combinedFields: [], combineFormat: "" },
                        },
                    },
                    labels: {
                        mapping: {
                            F: "FSA (davon ausgenutzt)",
                            FE: "FSA (ausgenutzt)",
                            DEFAULT: "",
                        },
                    },
                },
            ],
        ],
        icons: {
            mapping: {
                F: <ContentPasteRoundedIcon />,
                FE: <ContentPasteRoundedIcon />,
                N: <ReceiptLongRoundedIcon />,
                NE: <ReceiptLongRoundedIcon />,
            },
        },
        orientation: "horizontal",
        idFields: ["fsaNvId", "fsaLimitId", "nvDataId"],
        typeField: "type",
        detailView: (ids: string[], type: string | undefined): React.JSX.Element =>
            type === "F" ? (
                <FsaDetails
                    fsaNvId={Number(ids[0])}
                    fsaLimitId={Number(ids[1])}
                    icon={null}
                    functionTextDisable={true}
                />
            ) : (
                <NvDetails fsaNvId={ids[0]} nvDataId={ids[2]} icon={null} functionTextDisable={true} />
            ),
        data: getFsaNvData(),
        disabled: (type: string | undefined): boolean => type === "FE" || type === "NE",
    };
    const accountInfo: IOverviewTile = {
        headline: {
            title: "Geldkonten/Depots",
            hideTimeSliceButton: true,
        },
        cardDefinition: [
            [
                {
                    field: "accountNr",
                    labels: {
                        mapping: {
                            DEFAULT: "Konto Nr.",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "accountType",
                    labels: {
                        mapping: {
                            DEFAULT: "Kontotyp",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "currency",
                    labels: {
                        mapping: {
                            DEFAULT: "Währung",
                        },
                    },
                    isBold: true,
                },
            ],
        ],
        orientation: "keyValuePair",
        idFields: ["id"],
        typeField: "accountType",
        detailView: (ids: string[]): React.JSX.Element => (
            <AccountDetails accountId={Number(ids[0])} icon={null} functionTextDisable={true} />
        ),
        data: accountData,
    };
    useEffect(() => {
        sendGetRequest({
            path: "/customerstaticdata/fiscalUnit/fuVersionData",
            params: { fiscalUnitId: Number(fiscalUnitId) },
        }).then((response) => {
            console.table(response.data);
            setGenralInfoFuData(response.data);
        });
        sendGetRequest({
            path: "/customerstaticdata/fiscalUnit/fu2PersonsWithPersonAndAddress",
            params: { fiscalUnitId: fiscalUnitId },
        }).then((response) => {
            console.table(response.data);
            setAssignPersonfuData(response.data);
        });
        sendGetRequest({
            path: "/customerstaticdata/fsaNv/getFsaOverviewData",
            params: { fiscalUnitId: fiscalUnitId.toString() },
        }).then((response) => {
            console.table(response.data);
            setFsaData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/fsaNv/getNvOverviewData",
            params: { fiscalUnitId: fiscalUnitId.toString() },
        }).then((response) => {
            console.table(response.data);
            setNvData(response.data);
        });
        sendGetRequest({
            path: "/customerstaticdata/account/getAccountsByFiscalUnitId",
            params: { fiscalUnitId: fiscalUnitId.toString() },
        }).then((response) => {
            console.table(response.data);
            setAccountData(response.data);
        });
    }, [fiscalUnitId]);

    const tiles: IOverviewTile[] = [assignPersonInfo, taxdetailInfo, accountInfo];

    return <Overview title="Übersicht Steuerliche Einheit" generalInfo={personInfo} tiles={tiles} />;
};
