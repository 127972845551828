/* author: JESCHKE Moritz */
import { Colors } from "./Colors";

interface ICardIcon {
    icon: React.JSX.Element | null;
    disabled?: boolean;
}

export const CardIcon = (props: ICardIcon): React.JSX.Element => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div
                style={{
                    minWidth: "35px",
                    maxWidth: "35px",
                    aspectRatio: "1",
                    borderRadius: "50%",
                    background: Colors.white,
                    border: "2px solid " + (props.disabled ? Colors.disabledGrey : Colors.blue),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.disabled ? Colors.disabledGrey : Colors.blue,
                }}
            >
                {props.icon}
            </div>
        </div>
    );
};
