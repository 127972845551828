/* Author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { useEffect, useState } from "react";

interface IProps {
    requestId: string;
}

export const FromYer = (props: IProps) => {
    const [data, setData] = useState<string>();

    useEffect(() => {
        const fetchData = async () => {
            const request = await sendGetRequest({
                path: "/request/yer/getTmdMessageData",
                params: { requestId: props.requestId },
            });
            setData(request.data.message);
            console.log("DATA:\n", request.data.message);
        };

        fetchData();
    }, [props.requestId]);

    return <div>Display XML</div>;
};
