/* author: JESCHKE Moritz */

import { IRule, RuleName } from "./FormEditViewLine";

export const yearValidation: Partial<Record<RuleName, IRule>> = {
    pattern: {
        value: /^\d{4}/,
        customMessage: "Es ist eine Jahreszahl erforderlich!",
    },
    maxLength: { value: 4 },
    minLength: { value: 4 },
};
