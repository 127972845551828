/* author: SINDHUKANNAN */
import { KeyTableConsts } from "components/KeyTableConsts";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const AccountSearchMask = (): React.JSX.Element => {
    const column: ITableColumn[] = [
        { field: "fiscalUnit2AccountData.fiscalUnit", title: "Steuerliche Einheit" },
        { field: "branch", title: "Filialnummer" },
        { field: "accountNr", title: "Kontonummer" },
        { field: "iban", title: "IBAN" },
        { field: "accountType", title: "Kontoart" },
    ];
    const searchCriterias: ISearchCriteria[] = [
        { field: "branch", label: "Filialnummer*" },
        { field: "accountNr", label: "Kontonummer*" },
        { field: "iban", label: "IBAN*" },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
        },
    ];
    const searchUrl: ISearchUrl[] = [{ path: "/customerstaticdata/account/getAccountBySearch" }];

    return (
        <Search
            title={"Kontosuche"}
            searchCriterias={searchCriterias}
            columns={column}
            searchUrls={searchUrl}
            linkto={"/TransactionOverview/"}
            linktoIdentifier={"id"}
            identifier="accountBySearch"
            hideTechnicalColumns
        />
    );
};
