/* author: Pal PRASHANT */

import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn } from "components/Table";
import { VersionsView } from "components/VersionsView";
import { FSA_DATA_SECTION } from "./FsaDataEdit";

interface IFsaVersions {
    fsaNvId: number;
}

export const fsaDataEditIdentifier: string = "fsa ";

export const FsaDataVersions = (props: IFsaVersions): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "identification", title: "FSA Identifikation Nr." },
        { field: "exemptAmount", title: "Freistellungsbetrag" },
        { field: "taxDate", title: "Steuerliches Zuflussdatum", type: "date" },
        { field: "fiscalYear", title: "Steuerjahr" },
        { field: "fsaTyp", title: "FSA Typ" },
        { field: "startingInFiscalYear", title: "Gültig ab" },
        { field: "lastNamePerson1", title: "Person 1 Nachname" },
        { field: "givenNamePerson1", title: "Person 1 Vorname" },
        { field: "birthDatePerson1", title: "Person 1 Geburtsdatum", type: "date" },
        { field: "tinPerson1", title: "Person 1 TIN" },
        { field: "birthNamePerson1", title: "Person 1 Geburtsname" },
        { field: "lastNamePerson2", title: "Person 2 Nachname" },
        { field: "givenNamePerson2", title: "Person 2 Vorname" },
        { field: "birthDatePerson2", title: "Person 2 Geburtsdatum", type: "date" },
        { field: "tinPerson2", title: "Person 2 TIN" },
        { field: "birthNamePerson2", title: "Person 2 Geburtsname" },
        { field: "familyStatus", title: "Familienstand" },
        { field: "execState", title: "Ausführungsstatus" },
    ];

    const IDENTIFIER: TableidentifierType = "fsaDataVersions";

    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/fsaNv/fsaData-all-timeslices",
        params: { fsaNvId: props.fsaNvId },
    };

    return (
        <VersionsView
            columnsDefinition={columns}
            identifier={IDENTIFIER}
            title="Personen Zeitscheiben"
            dataRequest={dataRequest}
            historyPath="fsaDataHistory"
            //TODO :: define edit section
            editSection={FSA_DATA_SECTION}
            editIdentifier={fsaDataEditIdentifier}
        />
    );
};
