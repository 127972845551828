/* author: JESCHKE Moritz */

import { BrowserRouter as Router, Routes as Switch, Route } from "react-router-dom";
import { Appbar } from "application/app-bar/Appbar";
import { AppDispatch, RootState } from "application/redux/Store";
import { Dashboard } from "pages/dashboard/Dashboard";
import { Login, USER_PREFERENCES_SUFFIX } from "pages/login/Login";
import { Transactions } from "pages/showcase/transactions/Transactions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Menubar } from "application/menu-bar/Menubar";
import { useAppDispatch } from "application/redux/Hooks";
import { setClientId, setUser } from "application/redux/slices/UserSlice";
import {
    sendGetRequest,
    sendPostRequestWithUrlEncodedParams,
    setAuthToken,
    setUrl,
} from "application/api/HttpRequestHandler";
import { setUserConfiguration } from "application/redux/slices/UserConfigurationSlice";
import { ClientIdDialog } from "dialogs/ClientIdDialog";
import { Chart2 } from "pages/showcase/test/Chart";
import { Loader } from "components/Loader";
import { TedExchange } from "pages/showcase/ted/TedExchange";
import { Yer } from "pages/showcase/request/yer/Yer";
import { TedRepExchange } from "pages/showcase/ted/TedRepExchange";
import { TedDialog } from "dialogs/TedDialog";
import { PersonSearchMask } from "pages/customer-static-data/PersonSearchMask";
import { DerivativesDetails } from "pages/security-data/DerivativesDetails";
import { PersonOverview } from "pages/customer-static-data/PersonOverview";
import { SecurityIncomeDetails, SecurityMasterDetails } from "pages/security-data/SecurityDataDetails";
import { SecuritySearchMask } from "pages/security-data/SecuritySearchMask";
import { FsaDetails } from "pages/customer-static-data/FsaDetails";
import { AccountDetails } from "pages/account-data/AccountDetails";
import { FuOverview } from "pages/fiscal-unit-data/FuOverview";
import { FiscalUnitSearchMask } from "pages/fiscal-unit-data/FiscalUnitSearchMask";
import { TaxSearchMask } from "pages/tax-data/TaxSearchMask";
import { TransactionOverview } from "pages/transaction-data/TransactionOverview";
import { SecCorpActionDetails, SecSwitchingDetails } from "pages/security-data/TableForSingleRecord";
import { SecurityOverview } from "pages/security-data/SecurityOverview";
import { FailedIncomeTransacitons } from "pages/error-data/FailedIncomeTransactions";
import { FailedSecurityTransactions } from "pages/error-data/FailedSecurityTransactions";
import { OtherProcessingSearch } from "pages/other-processing-data/OtherProcessingSearch";
import { FailedDerivateTransactions } from "pages/error-data/FailedDerivateTransactions";
import { FsaNvSbCalculationpotsDetails } from "pages/other-processing-data/FsaNvSbCalculationpotsDetails";
import { DerivateTransSearchMask } from "pages/transaction-data/DerivateTransSearchMask";
import { SecurityTransactionSearchMask } from "pages/transaction-data/SecurityTransactionSearchMask";
import { BalancesIncomeByTaxYearOverview } from "pages/transaction-data/BalancesIncomeByTaxYearOverview";
import { CalculationPotAtDaedline } from "pages/other-processing-data/CalculationPotAtDeadline";
import { IncomeTranSearch } from "pages/transaction-data/IncomeTranSearch";
import { DonationDataSearchMask } from "pages/donation-data/DonationDataSearchMask";
import { DonationData } from "pages/donation-data/DonationData";
import { CalculationPotSearchMask, DepotExchangeSearchMask } from "pages/ted-data/TedSearchMask";
import { DerivativeTaxAtFlatRate, SecurityTaxAtFlatRate } from "pages/tax-data/TaxAtFlatRateSearchMask";
import { AccountSearchMask } from "pages/account-data/AccountSearchMask";
import { ExportEventHistoryReportSearch } from "pages/customer-reports/ExportEventHistoryReportSearch";
import { EditPendingLinkedPersonInfo } from "pages/customer-static-data/EditPendingLinkedPersonInfo";
import { EditPendingLinkedAccountInfo } from "pages/account-data/EditPendingLinkedAccountInfo";
import { EditPendingLinkedFUInfo } from "pages/fiscal-unit-data/EditPendingLinkedFUInfo";
import { AccountHistory } from "pages/account-data/AccountHistory";
import { NvDataHistory } from "pages/customer-static-data/NvDataHistory";
import { PersonOverviewEdit } from "pages/customer-static-data/PersonOverviewEdit";
import { FuOverviewEdit } from "pages/fiscal-unit-data/FuOverviewEdit";
import { FsaDataHistory } from "pages/customer-static-data/FsaDataHistory";
import { FuHistory } from "pages/fiscal-unit-data/FuHistory";
import { PersonHistory } from "pages/customer-static-data/PersonHistory";
import { KistamHistory } from "pages/customer-static-data/KistamHistory";
import { AddressHistory } from "pages/customer-static-data/AddressHistory";
import { AccountEdit } from "pages/account-data/AccountEdit";
import { EditPendingLinkedSecTranInfo } from "pages/transaction-data/EditPendingLinkedSecTranInfo";
import { FlowControl } from "pages/flow-control/FlowControl";
import { FsaDataEdit } from "pages/customer-static-data/FsaDataEdit";
import { EditPendingLinkedSecurityInfo } from "pages/security-data/EditPendingLinkedSecurityInfo";
import { FsaLimitHistory } from "pages/customer-static-data/FsaLimitHistory";
import { NvDataEdit, NV_DATA_IDENTIFIER } from "pages/customer-static-data/NvDataEdit";
import { EditPendingLinkedDerTranInfo } from "pages/transaction-data/EditPendingLinkedDerTranInfo";
import { EditPendingLinkedIncomeTranInfo } from "pages/transaction-data/EditPendingLinkedIncomeTranInfo";
import { FsaLimitVersionEdit } from "pages/customer-static-data/FsaLimitVersionEdit";
import { fsalimitEditIdentifier } from "pages/customer-static-data/FsaLimitVersion";
import { fsaDataEditIdentifier } from "pages/customer-static-data/FsaDataVersions";

import { SECURITY_INCOME_SECTION, SecurityIncomeEdit } from "pages/security-data/SecurityIncomeEdit";
import { SECURITY_SECTION, SecurityEdit } from "pages/security-data/SecurityEdit";
import { SEC_SWITCHING_SECTION, SecSwitchingEdit } from "pages/security-data/SecSwitchingEdit";
import { SEC_CORP_ACTION_SECTION, SecCorpActionEdit } from "pages/security-data/SecCorpActionEdit";
import {
    DONATION_PERSON_SECTION_DONEE,
    DONATION_PERSON_SECTION_DONOR,
    DonationPersonEditDonee,
    DonationPersonEditDonor,
} from "pages/donation-data/DonationPersonEdit";
import { KestRegistration } from "pages/report-data/KestRegistration";
import { SecurityTransactionEdit, securityTransactionIdentifier } from "pages/transaction-data/SecurityTransactionEdit";
import { DERIVATE_TRAN_SECTION, DerivateTransactionEdit } from "pages/transaction-data/DerivateTransactionEdit";
import { INCOME_SECTION_IDENTIFIER, IncomeTransactionEdit } from "pages/transaction-data/IncomeTransactionEdit";
import { IncomeTransactionItemEdit } from "pages/transaction-data/IncomeTransactionItemEdit";
import { DonationDeclarationSearchMask } from "pages/donation-data/DonationDeclarationSearchMask";
import { DonationSearchReport } from "pages/donation-data/DonationSearchReport";
import { InventoryReconcilationDerivateSearchMask } from "pages/transaction-data/InventoryReconcilationDerivateSearchMask";
import { InventoryReconcilationSecuritySearchMask } from "pages/transaction-data/InventoryReconcilationSecuritySearchMask";
import {
    TedEditCalculationPotIn,
    TedEditCalculationPotOut,
    TedEditDepotExchangeIn,
    TedEditDepotExchangeOut,
} from "pages/ted-data/TedEdit";
import { EditPendingLinkedYer } from "pages/request-data/EditPendingLinkedYer";
import { EditPendingLinkedTed } from "pages/ted-data/EditPendingLinkedTed";
import { YerSingleRequestSearchMask, YerMassRequestSearchMask } from "pages/request-data/YerRequestSearchMask";
import { YerSingleRequestEdit, YerMassRequestEdit, ORDER_SECTION } from "pages/request-data/YerRequestEdit";
import { VALUE_SECTION, YerMassValueEdit, YerSingleValueEdit } from "pages/request-data/YerValueEdit";
import { ORDER_DETAILS_SECTION, YerMassReportEdit, YerSingleReportEdit } from "pages/request-data/YerReportEdit";
import {
    PORTFOLIO_TRANSFER_DETAIL_IDENTIFIER,
    PortfolioTransferDetailsEdit,
} from "pages/ted-data/PortfolioTransferDetailEdit";
import { FkbRequestSearchMask } from "pages/request-data/FkbRequestSearchMask";
import { FsakRequestSearchMask } from "pages/request-data/FsakRequestSearchMask";
import { ManageAddtionalFields } from "pages/request-data/ManageAddtionalFields";
import { DonationDataHistory } from "pages/donation-data/DonationDataHistory";
import { FKB_REQUEST_SECTION, FkbRequestEdit } from "pages/request-data/FkbRequestEdit";
import { EditPendingLinkedDonationDeclaration } from "pages/donation-data/EditPendingLinkedDonationDeclaration";

export const App = (): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();

    //TODO :: remove temp code
    /* ### BEGIN AUTO CONFIGURATION ### */
    const autoConfig = async () => {
        //Auto Login
        if (process.env.REACT_APP_TESTSET_NUMBER !== undefined) {
            setUrl(process.env.REACT_APP_TESTSET_NUMBER);
        }
        const userRole: string = process.env.REACT_APP_USER_ROLE === undefined ? "" : process.env.REACT_APP_USER_ROLE;
        if (["ADMIN", "WRITER", "READER"].includes(userRole)) {
            //get token
            const login = await sendPostRequestWithUrlEncodedParams({
                path: "/preferences/user/login",
                body: {
                    user: userRole.toLocaleLowerCase(),
                    password: userRole.toLocaleLowerCase() + "_user",
                },
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setAuthToken(login.headers.authorization);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setAuthToken(login.headers.refresh);

            const request = await sendGetRequest({
                path: "/preferences/user",
                params: { type: "U", user: userRole.toLocaleLowerCase() + USER_PREFERENCES_SUFFIX },
            });

            dispatch(setUserConfiguration({ userConfig: request.data }));

            //login in to userRole
            dispatch(setUser({ isLogedIn: true, userRole: userRole }));

            //select default client
            dispatch(setClientId({ clientId: "98", clientName: "Test Test Bank98" }));
        }
    };

    useEffect(() => {
        autoConfig();
    }, []);

    /* ### END AUTO CONFIGURATION ### */

    /* ### Variables & States ### */
    const [menuOpen, setMenuOpen] = useState<boolean>(true);
    const menuBarWidth: string = "15%";
    const restWidth: string = 100 - +menuBarWidth.slice(0, -1) + "%";

    const isLogedIn: boolean = useSelector((state: RootState) => state.user.isLogedIn);
    const clientId: string = useSelector((state: RootState) => state.user.clientId);
    const isLoading: boolean = useSelector((state: RootState) => state.application.isLoading);

    /* ### Functions ### */

    const toggleMenuBar = () => {
        setMenuOpen(!menuOpen);

        const menuBar: HTMLElement = document.getElementById("menuBar") as HTMLElement;
        const appBar: HTMLElement = document.getElementById("appBar") as HTMLElement;
        const content: HTMLElement = document.getElementById("content") as HTMLElement;

        if (menuOpen) {
            menuBar.style.display = "none";
            appBar.style.width = "100%";
            content.style.width = "100%";
        } else {
            menuBar.style.display = "initial";
            appBar.style.width = restWidth;
            content.style.width = restWidth;
        }
    };

    /* ### HTML ### */

    if (isLogedIn) {
        return (
            <div style={{ height: "100vh" }}>
                <Router basename={process.env.REACT_APP_BASE_NAME}>
                    <div style={{ width: "100%", height: "100%", display: "flex" }}>
                        <div
                            id="menuBar"
                            style={{
                                width: menuBarWidth,
                                height: "100%",
                                position: "fixed",
                                zIndex: 999,
                                backgroundColor: "white",
                                borderRight: "0.5px solid lightgray",
                                overflow: "auto",
                            }}
                        >
                            <Menubar toggleMenuBar={toggleMenuBar} />
                        </div>

                        <div style={{ width: "100%", height: "100%" }}>
                            <div
                                id="appBar"
                                style={{
                                    width: restWidth,
                                    float: "right",
                                    position: "fixed",
                                    right: 0,
                                    zIndex: 999,
                                    transitionProperty: "width",
                                    transitionDuration: "250ms",
                                    transitionTimingFunction: "ease",
                                }}
                            >
                                <Appbar toggleMenuBar={toggleMenuBar} isMenuOpen={menuOpen} />
                            </div>
                            <div
                                id="content"
                                style={{
                                    width: restWidth,
                                    position: "relative",
                                    top: "60px",
                                    float: "right",
                                    transitionProperty: "width",
                                    transitionDuration: "250ms",
                                    transitionTimingFunction: "ease",
                                }}
                            >
                                {isLoading && <Loader />}
                                <Switch>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="home" element={<Dashboard />} />
                                    <Route path="transaktionen" element={<Transactions />} />
                                    <Route path="report" element={<Chart2 />} />
                                    <Route path="test" element={<Yer />} />
                                    {/* <Route path="securityCorpAction" element={<SecurityCorpAction />} />
                                    <Route path="securitySwitching" element={<SecuritySwitching />} /> */}
                                    <Route path="ted" element={<TedExchange />} />
                                    <Route path="ted2" element={<TedRepExchange />} />
                                    <Route path="tedDialog" element={<TedDialog />} />
                                    <Route path="SecurityMasterData" element={<SecurityMasterDetails id="66" />} />
                                    <Route path="securityIncomeData" element={<SecurityIncomeDetails id="36" />} />
                                    <Route path="personensuchmaske" element={<PersonSearchMask />} />
                                    <Route
                                        path="secReconDataSearchMask"
                                        element={<InventoryReconcilationSecuritySearchMask />}
                                    />
                                    <Route path="manageAdditionalFields" element={<ManageAddtionalFields />} />
                                    <Route path="yerSingleRequestSearchMask" element={<YerSingleRequestSearchMask />} />
                                    <Route
                                        path="overviewBearbeitungsstatusYerSingle"
                                        element={<EditPendingLinkedYer isSingle />}
                                    />
                                    <Route path="yerMassRequestSearchMask" element={<YerMassRequestSearchMask />} />
                                    <Route
                                        path="overviewBearbeitungsstatusYerMass"
                                        element={<EditPendingLinkedYer isSingle={false} />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusPerson"
                                        element={<EditPendingLinkedPersonInfo />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusAccount"
                                        element={<EditPendingLinkedAccountInfo />}
                                    />
                                    <Route path="overviewBearbeitungsstatusFU" element={<EditPendingLinkedFUInfo />} />
                                    <Route
                                        path="overviewBearbeitungsstatusSecTran"
                                        element={<EditPendingLinkedSecTranInfo />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusSecurity"
                                        element={<EditPendingLinkedSecurityInfo />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusDerTran"
                                        element={<EditPendingLinkedDerTranInfo />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusIncomeTran"
                                        element={<EditPendingLinkedIncomeTranInfo />}
                                    />
                                    <Route path="fkbRequestSearchMask" element={<FkbRequestSearchMask />} />
                                    <Route
                                        path={"fkbRequestSearchMask/edit/" + FKB_REQUEST_SECTION + "/:requestId"}
                                        element={<FkbRequestEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusFkbRequest/edit/" +
                                            FKB_REQUEST_SECTION +
                                            "/:requestId"
                                        }
                                        element={<FkbRequestEdit />}
                                    />
                                    <Route path="fsakRequestSearchMask" element={<FsakRequestSearchMask />} />
                                    <Route path="securitySearchMask" element={<SecuritySearchMask />} />
                                    <Route path="derivatives/:derivativeId" element={<DerivativesDetails />} />
                                    <Route path="overviewPerson/:personId" element={<PersonOverview />} />
                                    <Route path="fiscalUnit" element={<FiscalUnitSearchMask />} />
                                    <Route
                                        path="fsaDetail"
                                        element={<FsaDetails fsaNvId={392} fsaLimitId={290} icon={null} />}
                                    />
                                    <Route path="accountSearchMask" element={<AccountSearchMask />} />
                                    <Route path="accountData" element={<AccountDetails accountId={1} icon={null} />} />
                                    <Route path="TransactionOverview/:accountId" element={<TransactionOverview />} />
                                    <Route path="TransactionOverview/:accountId/edit" element={<AccountEdit />} />
                                    <Route path="SecurityOverview/:securityId" element={<SecurityOverview />} />

                                    <Route path="overviewFu/:fiscalUnitId" element={<FuOverview />} />
                                    <Route
                                        path="secCorpActionDetails"
                                        element={<SecCorpActionDetails id={4959} wkn="" isin="" shortname="" />}
                                    />
                                    <Route
                                        path="secSwitchingDetails"
                                        element={<SecSwitchingDetails id={1} wkn="" isin="" shortname="" />}
                                    />
                                    <Route path="failedIncomeTrans" element={<FailedIncomeTransacitons />} />
                                    <Route path="failedSecTrans" element={<FailedSecurityTransactions />} />
                                    <Route path="otherProcessing" element={<OtherProcessingSearch />} />
                                    <Route path="failedDerivateTrans" element={<FailedDerivateTransactions />} />
                                    <Route path="incomeTranSearch" element={<IncomeTranSearch />} />

                                    <Route path="derivateTranSearchMask" element={<DerivateTransSearchMask />} />
                                    <Route path="otherProcessing" element={<OtherProcessingSearch />} />
                                    <Route path="otherProcessing" element={<OtherProcessingSearch />} />
                                    <Route path="failedDerivateTrans" element={<FailedDerivateTransactions />} />
                                    <Route path="donationSearchReport" element={<DonationSearchReport />} />
                                    <Route
                                        path="securityTransactionSearchMask"
                                        element={<SecurityTransactionSearchMask />}
                                    />
                                    <Route path="donationDataSearchMask" element={<DonationDataSearchMask />} />
                                    <Route
                                        path="donationDeclarationSearchMask"
                                        element={<DonationDeclarationSearchMask />}
                                    />
                                    <Route path="donationSearchReport" element={<DonationSearchReport />} />
                                    <Route
                                        path="overviewBearbeitungsstatusDonation"
                                        element={<EditPendingLinkedDonationDeclaration />}
                                    />
                                    <Route
                                        path="balancesIncomeOverview"
                                        element={<BalancesIncomeByTaxYearOverview />}
                                    />
                                    <Route
                                        path="FsaNvSbCalculationpotsDetails"
                                        element={<FsaNvSbCalculationpotsDetails />}
                                    />
                                    <Route path="donationData/:messageId" element={<DonationData />} />
                                    <Route path="calcPotAtDeadline" element={<CalculationPotAtDaedline />} />
                                    <Route path="depotExchangeSearch" element={<DepotExchangeSearchMask />} />
                                    <Route
                                        path="depotExchangeSearch/edit/IN/:section/:tedExchangeId"
                                        element={<TedEditDepotExchangeIn />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedDepotIn"
                                        element={<EditPendingLinkedTed direction="IN" isDepot />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedDepotIn/edit/IN/:section/:tedExchangeId"
                                        element={<TedEditDepotExchangeIn />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedDepotOut"
                                        element={<EditPendingLinkedTed direction="OUT" isDepot />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedDepotOut/edit/OUT/:section/:tedExchangeId"
                                        element={<TedEditDepotExchangeOut />}
                                    />
                                    <Route
                                        path="depotExchangeSearch/edit/OUT/:section/:tedExchangeId"
                                        element={<TedEditDepotExchangeOut />}
                                    />
                                    <Route
                                        path={
                                            "depotExchangeSearch/edit/" +
                                            PORTFOLIO_TRANSFER_DETAIL_IDENTIFIER +
                                            "/:section/:tedRepExchangeId"
                                        }
                                        element={<PortfolioTransferDetailsEdit />}
                                    />
                                    <Route path="calculationPotSearch" element={<CalculationPotSearchMask />} />
                                    <Route
                                        path="calculationPotSearch/edit/IN/:section/:tedExchangeId"
                                        element={<TedEditCalculationPotIn />}
                                    />
                                    <Route
                                        path="calculationPotSearch/edit/OUT/:section/:tedExchangeId"
                                        element={<TedEditCalculationPotOut />}
                                    />
                                    <Route path="exportEventHistory" element={<ExportEventHistoryReportSearch />} />
                                    <Route
                                        path="overviewBearbeitungsstatusTedCalcPotIn"
                                        element={<EditPendingLinkedTed direction="IN" isDepot={false} />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedCalcPotIn/edit/IN/:section/:tedExchangeId"
                                        element={<TedEditCalculationPotIn />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedCalcPotOut"
                                        element={<EditPendingLinkedTed direction="OUT" isDepot={false} />}
                                    />
                                    <Route
                                        path="overviewBearbeitungsstatusTedCalcPotOut/edit/OUT/:section/:tedExchangeId"
                                        element={<TedEditCalculationPotOut />}
                                    />
                                    <Route path="searchTaxAtFlatRateSecurity" element={<SecurityTaxAtFlatRate />} />
                                    <Route path="searchTaxAtFlatRateDerivative" element={<DerivativeTaxAtFlatRate />} />
                                    <Route path="accountHistory/:accountId" element={<AccountHistory />} />
                                    <Route path="nvDataHistory/:nvDataId" element={<NvDataHistory />} />
                                    <Route path="fuHistory/:versionId" element={<FuHistory />} />
                                    <Route path="overviewPerson/:personId/edit" element={<PersonOverviewEdit />} />
                                    <Route path="overviewFu/:fiscalUnitId/edit" element={<FuOverviewEdit />} />
                                    <Route path="fsaDataHistory/:fsaDataId" element={<FsaDataHistory />} />
                                    <Route path="fsaLimitHistory/:fsaLimitId" element={<FsaLimitHistory />} />
                                    <Route path="donationDataHistory/:personId" element={<DonationDataHistory />} />
                                    <Route
                                        path="overviewPerson/:personId/edit/:section/:versionId"
                                        element={<PersonOverviewEdit />}
                                    />

                                    <Route
                                        path={`TransactionOverview/:accountId/edit/${securityTransactionIdentifier}/:section/:impId`}
                                        element={<SecurityTransactionEdit />}
                                    />
                                    <Route
                                        path={`overviewBearbeitungsstatusSecTran/edit/${securityTransactionIdentifier}/:section/:impId`}
                                        element={<SecurityTransactionEdit />}
                                    />
                                    <Route
                                        path={`securityTransactionSearchMask/edit/${securityTransactionIdentifier}/:section/:impId`}
                                        element={<SecurityTransactionEdit />}
                                    />

                                    <Route
                                        path={
                                            "TransactionOverview/:accountId/edit/" + DERIVATE_TRAN_SECTION + "/:impId"
                                        }
                                        element={<DerivateTransactionEdit />}
                                    />
                                    <Route
                                        path={"derivateTranSearchMask/edit/" + DERIVATE_TRAN_SECTION + "/:impId"}
                                        element={<DerivateTransactionEdit />}
                                    />

                                    <Route
                                        path={
                                            "TransactionOverview/:accountId/edit/" +
                                            INCOME_SECTION_IDENTIFIER +
                                            "/:section/:incomeTranId"
                                        }
                                        element={<IncomeTransactionEdit />}
                                    />
                                    <Route
                                        path={
                                            "incomeTranSearch/edit/" +
                                            INCOME_SECTION_IDENTIFIER +
                                            "/:section/:incomeTranId"
                                        }
                                        element={<IncomeTransactionEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusIncomeTran/edit/" +
                                            INCOME_SECTION_IDENTIFIER +
                                            "/:section/:incomeTranId"
                                        }
                                        element={<IncomeTransactionEdit />}
                                    />
                                    <Route
                                        path={"yerMassRequestSearchMask/edit/" + ORDER_SECTION + "/:requestId"}
                                        element={<YerMassRequestEdit />}
                                    />
                                    <Route
                                        path={"yerMassRequestSearchMask/edit/" + VALUE_SECTION + "/:requestId"}
                                        element={<YerMassValueEdit />}
                                    />
                                    <Route
                                        path={"yerMassRequestSearchMask/edit/" + ORDER_DETAILS_SECTION + "/:requestId"}
                                        element={<YerMassReportEdit />}
                                    />
                                    <Route
                                        path={"overviewBearbeitungsstatusYerMass/edit/" + ORDER_SECTION + "/:requestId"}
                                        element={<YerMassRequestEdit />}
                                    />
                                    <Route
                                        path={"overviewBearbeitungsstatusYerMass/edit/" + VALUE_SECTION + "/:requestId"}
                                        element={<YerMassValueEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusYerMass/edit/" +
                                            ORDER_DETAILS_SECTION +
                                            "/:requestId"
                                        }
                                        element={<YerMassReportEdit />}
                                    />
                                    <Route
                                        path={"yerSingleRequestSearchMask/edit/" + ORDER_SECTION + "/:requestId"}
                                        element={<YerSingleRequestEdit />}
                                    />
                                    <Route
                                        path={"yerSingleRequestSearchMask/edit/" + VALUE_SECTION + "/:requestId"}
                                        element={<YerSingleValueEdit />}
                                    />
                                    <Route
                                        path={
                                            "yerSingleRequestSearchMask/edit/" + ORDER_DETAILS_SECTION + "/:requestId"
                                        }
                                        element={<YerSingleReportEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusYerSingle/edit/" + ORDER_SECTION + "/:requestId"
                                        }
                                        element={<YerSingleRequestEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusYerSingle/edit/" + VALUE_SECTION + "/:requestId"
                                        }
                                        element={<YerSingleValueEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusYerSingle/edit/" +
                                            ORDER_DETAILS_SECTION +
                                            "/:requestId"
                                        }
                                        element={<YerSingleReportEdit />}
                                    />
                                    <Route
                                        path={"TransactionOverview/:accountId/edit/item/:section/:incomeTranItemId"}
                                        element={<IncomeTransactionItemEdit />}
                                    />
                                    <Route
                                        path={"incomeTranSearch/edit/item/:section/:incomeTranItemId"}
                                        element={<IncomeTransactionItemEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusIncomeTran/edit/item/:section/:incomeTranItemId"
                                        }
                                        element={<IncomeTransactionItemEdit />}
                                    />

                                    <Route
                                        path={`overviewFu/:personId/edit/${fsaDataEditIdentifier}/:section/:fsaDataId`}
                                        element={<FsaDataEdit />}
                                    />

                                    <Route
                                        path={`overviewPerson/:personId/edit/${fsaDataEditIdentifier}/:section/:fsaDataId`}
                                        element={<FsaDataEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewPerson/:personId/edit/" +
                                            NV_DATA_IDENTIFIER +
                                            "/:section/:nvDataId"
                                        }
                                        element={<NvDataEdit />}
                                    />
                                    <Route
                                        path={"overviewFu/:personId/edit/" + NV_DATA_IDENTIFIER + "/:section/:nvDataId"}
                                        element={<NvDataEdit />}
                                    />
                                    <Route
                                        path={`overviewPerson/:personId/edit/${fsalimitEditIdentifier}/:section/:fsaLimitId`}
                                        element={<FsaLimitVersionEdit />}
                                    />
                                    <Route
                                        path={`overviewFu/:personId/edit/${fsalimitEditIdentifier}/:section/:fsaLimitId`}
                                        element={<FsaLimitVersionEdit />}
                                    />
                                    <Route
                                        path="overviewFu/:fiscalUnitId/edit/:section/:versionId"
                                        element={<FuOverviewEdit />}
                                    />
                                    <Route path="personHistory/:versionId" element={<PersonHistory />} />
                                    <Route path="kistamHistory/:versionId" element={<KistamHistory />} />
                                    <Route path="addressHistory/:versionId" element={<AddressHistory />} />
                                    <Route path="flowControl" element={<FlowControl />} />

                                    <Route
                                        path={
                                            "SecurityOverview/:securityId/edit/" +
                                            SECURITY_INCOME_SECTION +
                                            "/:section/:versionId"
                                        }
                                        element={<SecurityIncomeEdit />}
                                    />
                                    <Route
                                        path={
                                            "SecurityOverview/:securityId/edit/" +
                                            SECURITY_SECTION +
                                            "/:section/:versionId"
                                        }
                                        element={<SecurityEdit />}
                                    />
                                    <Route
                                        path={
                                            "SecurityOverview/:securityId/edit/" +
                                            SEC_SWITCHING_SECTION +
                                            "/:section/:bidId"
                                        }
                                        element={<SecSwitchingEdit />}
                                    />
                                    <Route
                                        path={
                                            "SecurityOverview/:securityId/edit/" +
                                            SEC_CORP_ACTION_SECTION +
                                            "/:section/:bidId"
                                        }
                                        element={<SecCorpActionEdit />}
                                    />
                                    <Route
                                        path={
                                            "donationData/:messageId/edit/" +
                                            DONATION_PERSON_SECTION_DONOR +
                                            "/:personId"
                                        }
                                        element={<DonationPersonEditDonor />}
                                    />
                                    <Route
                                        path={
                                            "donationData/:messageId/edit/" +
                                            DONATION_PERSON_SECTION_DONEE +
                                            "/:personId"
                                        }
                                        element={<DonationPersonEditDonee />}
                                    />
                                    <Route path="kestRegistration" element={<KestRegistration />} />
                                    <Route
                                        path={
                                            "TransactionOverview/:accountId/edit/" + DERIVATE_TRAN_SECTION + "/:impId"
                                        }
                                        element={<DerivateTransactionEdit />}
                                    />
                                    <Route
                                        path={"derivateTranSearchMask/edit/" + DERIVATE_TRAN_SECTION + "/:impId"}
                                        element={<DerivateTransactionEdit />}
                                    />
                                    <Route
                                        path={
                                            "overviewBearbeitungsstatusDerTran/edit/" +
                                            DERIVATE_TRAN_SECTION +
                                            "/:impId"
                                        }
                                        element={<DerivateTransactionEdit />}
                                    />
                                    <Route path="taxSearch" element={<TaxSearchMask />} />
                                    <Route
                                        path="bestandsabgleichDerivateSearch"
                                        element={<InventoryReconcilationDerivateSearchMask />}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </Router>
                {clientId === "" && <ClientIdDialog />}
            </div>
        );
    } else {
        return <Login />;
    }
};
