/* author: Prashant Pal */

import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const FuHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const versionId: number = Number(params.versionId);

    const labels: IHistoryKeys[] = [
        {
            field: "fiscalUnit",
            label: "Steuerliche Einheit",
        },
        {
            field: "fiscalUnitType",
            label: "Typ der Steuerlichen Einheit",
        },
        {
            field: "legalFormCategory",
            label: "Rechtsform",
        },
        {
            field: "communityFlag",
            label: "Personengemeinschaftsflag",
        },
        {
            field: "churchTaxRetention",
            label: "Kirchensteuerabgeltung",
        },
        { field: "validFrom", label: "gültig von", type: "date" },
        { field: "validTo", label: "gültig von", type: "date" },
        {
            field: "customerSegment",
            label: "Kundengruppe",
        },
    ];

    return (
        <HistoryView
            title={"Historie Steuerliche Einheit"}
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/fiscalUnit/history",
                params: { versionId: Number(versionId) },
            }}
            identifier={"fuHistory"}
        />
    );
};
