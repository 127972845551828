/* author: JESCHKE Moritz */

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { useAppDispatch } from "application/redux/Hooks";
import { setTedDialogData } from "application/redux/slices/TedSlice";
import { AppDispatch } from "application/redux/Store";
import { ComboBox } from "components/ComboBox";
import { InputField } from "components/InputField";
import { FromToDatePicker } from "components/FromToDatePicker";
import { useState } from "react";

export const TedDialog = () => {
    const dispatch: AppDispatch = useAppDispatch();

    const [searchDirection, setSearchDirection] = useState<string>("");
    const [searchDirectionOptions] = useState([{ label: "Eingehende" }, { label: "Ausgehende" }]);

    const [benefAcctNm, setBenefAcctNm] = useState<string>("");
    const [benefAcctKey, setBenefAcctKey] = useState<string>("");
    const [issuerAcctNm, setIssuerAcctNm] = useState<string>("");
    const [issuerAcctKey, setIssuerAcctKey] = useState<string>("");

    const [from, setFrom] = useState<Date | null>(new Date());
    const [to, setTo] = useState<Date | null>(new Date());
    const [dateError, setDateError] = useState<boolean>(false);

    const handkleOkPress = () => {
        dispatch(
            setTedDialogData({
                benefAcctNm: benefAcctNm,
                benefAcctKey: benefAcctKey,
                issuerAcctNm: issuerAcctNm,
                issuerAcctKey: issuerAcctKey,
                from: from!,
                to: to!,
            })
        );
    };

    /**
     * Call function if special key is pressed
     * @param event KeyPressEvent
     */
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" && !buttonDisableCheck()) {
            handkleOkPress();
        }
    };

    /**
     * Checks if the button is disabled
     * @returns true | false
     */
    const buttonDisableCheck = (): boolean => {
        return dateError;
    };

    return (
        <Dialog open={true} onKeyDown={handleKeyDown}>
            <DialogTitle>{"Auswahl bankexterne Depotüberträge:"}</DialogTitle>
            <DialogContent>
                <div style={{ marginTop: "1em" }}>
                    <ComboBox
                        label="Suche nach"
                        onChange={(event: SelectChangeEvent<string>) => setSearchDirection(event.target.value)}
                        value={searchDirection}
                        options={searchDirectionOptions}
                        optionIdentifier="label"
                        optionValue={["label"]}
                        optionValueSeparator=""
                    />

                    <Typography>Auftraggeber Depot:</Typography>
                    <div style={{ display: "flex", marginTop: "0.25em" }}>
                        <InputField
                            label="Filialnummer"
                            value={issuerAcctKey}
                            setValue={(event) => setIssuerAcctKey(event.target.value)}
                        />
                        <InputField
                            label="Depotnummer"
                            value={issuerAcctNm}
                            setValue={(event) => setIssuerAcctNm(event.target.value)}
                        />
                    </div>

                    <Typography>Empfänger Depot:</Typography>
                    <div style={{ display: "flex", marginTop: "0.25em" }}>
                        <InputField
                            label="Filialnummer"
                            value={benefAcctKey}
                            setValue={(event) => setBenefAcctKey(event.target.value)}
                        />
                        <InputField
                            label="Depotnummer"
                            value={benefAcctNm}
                            setValue={(event) => setBenefAcctNm(event.target.value)}
                        />
                    </div>

                    <FromToDatePicker from={from} setFrom={setFrom} to={to} setTo={setTo} setDateError={setDateError} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={buttonDisableCheck()} onClick={handkleOkPress}>
                    Ok
                </Button>
                <Button onClick={() => console.log("CANCEL TED DIALOG")}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};
