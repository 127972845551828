/* author: JESCHKE Moritz */

import { EditPendingLinked } from "components/EditPendingLinked";
import { getYerContextMenu, getYerRequestColumns } from "./YerRequestSearchMask";
import { useState } from "react";

interface IEditPendingLinkedYer {
    isSingle: boolean;
}

export const EditPendingLinkedYer = (props: IEditPendingLinkedYer): React.JSX.Element => {
    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    return (
        <EditPendingLinked
            columns={getYerRequestColumns(!props.isSingle)}
            dataRequest={{
                path: "/request/yer/searchEditPending",
                params: {
                    category: props.isSingle ? "S" : "M",
                },
            }}
            identifier={props.isSingle ? "editPendingOverviewSingleRequest" : "editPendingOverviewMassRequest"}
            title={props.isSingle ? "Einzelauftrag" : "Massenauftrag"}
            contextMenu={getYerContextMenu(currentRowData, setCurrentRowData)}
        />
    );
};
