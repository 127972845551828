/* author: PAL Prashant */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";
import { IdSelector } from "components/TBD/IdSelector";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface IVapValueAdjDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    impId: number;
    extTranKey: string;
}

export const VapValueAdjDetails = (props: IVapValueAdjDetails): React.JSX.Element => {
    const [impId, setImpId] = useState<string>(props.impId.toString());
    const IDENTIFIER: TableidentifierType = "vapValueAdjDetails";

    const columns: ITableColumn[] = [
        { field: "extTranKey", title: "Umsatzreferenz Wertberichtigung" },
        {
            field: "executeTime",
            title: "Ausführungstag CA-Maßnahme",
            type: "date",
        },
        { field: "transactionKeyMandant", title: "Umsatzreferenz VAP" },
        {
            field: "date",
            title: "Ausführungstag VAP",
            type: "date",
        },
        {
            field: "executionTime",
            title: "Anschaffungsdatum",
            type: "date",
        },
        { field: "nominal", title: "Referenznominal" },
        { field: "amountEur", title: "Betrag VAP" },
        { field: "aTfrAmountEur", title: "Betrag VAP nach Teilfreistellung" },
        { field: "vapKz", title: "Steuerausländer" },
    ];

    const [vapValueAdjDetailsData, setVapValueAdjDetailsData] = useState<Record<string, string>[]>([]);

    useEffect(() => {
        sendGetRequest({ path: "/cgresources/vap/getVapByImpId", params: { impId: +impId } }).then((response) => {
            setVapValueAdjDetailsData(response.data);
        });
    }, [impId]);
    return (
        <PopUp
            title={"Wertberichtigung VAP zu Umsatzreferenz " + props.extTranKey}
            setOpen={props.setOpen}
            content={
                <div style={{ display: "grid", gap: "3rem" }}>
                    <IdSelector label="impId" setId={setImpId} />
                    <Table
                        columnsDefinition={columns}
                        tableData={vapValueAdjDetailsData}
                        identifier={IDENTIFIER}
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                </div>
            }
        />
    );
};
