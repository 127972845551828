/* author: Aashish Upadhyay  */

import { EditPendingLinked } from "components/EditPendingLinked";
import { ITableColumn } from "components/Table";

export const EditPendingLinkedFUInfo = (): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "fiscalUnit", title: "Steuerliche Einheit" },
        { field: "fiscalUnitType", title: "Typ der steuerlichen Einheit" },
        { field: "legalFormCategory", title: "Rechtsform" },
        { field: "churchTaxRetention", title: "Kirchensteuerabgeltung" },
        { field: "communityFlag", title: "Personengemeinschaftsflag" },
        { field: "customerSegment", title: "Kundengruppe" },
        { field: "validFrom", title: "gültig von", type: "date" },
        { field: "validTo", title: "gültig bis", type: "date" },
    ];

    return (
        <EditPendingLinked
            columns={columns}
            dataRequest={{
                path: "/customerstaticdata/fiscalUnit/searchEditPending",
            }}
            identifier="editPendingOverviewFU"
            title="Steuerliche Einheit"
            navigate={{ linkTo: "overviewFu", idField: "fiscalUnitId" }}
        />
    );
};
