/* author: PRASHANT Pal */
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn, ITableContextMenu } from "components/Table";
import { useState } from "react";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { DERIVATE_TAB, canceledRowColorScheme } from "./TransactionOverview";
import { KeyTableConsts } from "components/KeyTableConsts";

export const derTranColumns: ITableColumn[] = [
    { field: "extBusinessRef", title: "Transaktionsreferenz" },
    { field: "extTranKey", title: "Umsatzreferenz" },
    { field: "derivativeData.derivativeCode", title: "Derivate Code" },
    { field: "derivativeData.shortName", title: "Kurzbezeichnung" },
    { field: "accountData.branch", title: "Filialnummer" },
    { field: "accountData.accountType", title: "Kontoart" },
    { field: "derivativeData.isin", title: "ISIN" },
    { field: "accountData.accountNr", title: "Kontonummer" },
    { field: "extEvent", title: "Geschäftsart" },
    { field: "extEventGroup", title: "Vorgangsgr.-Schlüssel" },
    { field: "taxEvent", title: "StGA" },
    { field: "nominal", title: "Umsatz" },
    { field: "referenceNominal", title: "Referenznominal" },
    { field: "nomCur", title: "Stk/Whrg" },
    { field: "tradeCur", title: "Handels Whrg" },
    { field: "tradeExchRate", title: "DevKurs Handels Whrg" },
    { field: "tradeMarketValue", title: "Kurswert Handels Whrg" },
    { field: "settlCur", title: "Abr.-Whrg" },
    { field: "settlExchRate", title: "DevKurs Abr.-Whrg" },
    { field: "settlMarketValue", title: "Kurswert Abr.-Whrg" },
    { field: "settlAddExpCost", title: "Anschaff. NK Abr.-Whrg" },
    { field: "marketValueEur", title: "Gegenwert EUR" },
    { field: "tradeAddExpCost", title: "NK Abr.-Whrg" },
    { field: "valuationEur", title: "Bewertung am Bewertungstag in EUR" },
    { field: "settlementStockPrice", title: "Börsenkurs Handels Whrg" },
    { field: "addExpCostEur", title: "NK EUR" },
    { field: "refDelSystem", title: "Liefersystem" },
    {
        field: "executionTime",
        title: "Ausführ.-Datum",
        type: "date",
    },
    {
        field: "valutA",
        title: "Valuta",
        type: "date",
    },
    {
        field: "bookingDate",
        title: "Buchungs-Datum",
        type: "date",
    },
    {
        field: "acqExchangeRateDate",
        title: "Tag des ermittelten Devisenkurses",
        type: "date",
    },
    {
        field: "taxBusinessDay",
        title: "Steuer-Datum",
        type: "date",
    },
    {
        field: "instructionDate",
        title: "Weisungs-Datum",
        type: "date",
    },
    {
        field: "settlValueDate",
        title: "Stückevaluta-Datum",
        type: "date",
    },
    { field: "fractionPaymentT", title: "Spitzenausgleich in Handels Whrg" },
    { field: "fractionPaymentE", title: "Spitzenausgleich in EUR" },
    { field: "fractionPaymentS", title: "Spitzenausgleich in Abr.-Whrg" },
    { field: "flagSpeku", title: "Anschaffungsdaten Detail" },
    { field: "flagValueAdj", title: "Wertkorrekturen" },
    { field: "externalVoucherKey", title: "Externe Beleg-Nummer" },
    { field: "fictWithhTax", title: "Fiktive Quellensteuer" },
    { field: "structProdRef", title: "Referenz strukturiertes Produkt" },
    { field: "delBankInEU", title: "Lieferbank in EU" },
    { field: "extDelivery", title: "Externe Lieferung" },
    { field: "depTransfCentre", title: "TaxBox-KZ" },
    { field: "cancellationFlag", title: "StoKZ" },
    { field: "cancellationExtTranKey", title: "Umsatzreferenz stornierter Umsatz" },
    { field: "calculationFlag", title: "Berechenbarkeit" },
    { field: "execState", title: "Ausführungsstatus" },
    { field: "manualCheckRequired", title: "Manuelle Prüfung" },
    { field: "fiscalValuation", title: "Steuerl. bewertbar" },
    { field: "crossCurrencyFlag", title: "Cross-Currency-Geschäft" },
    { field: "multipleCloseFlag", title: "Multiple-Close" },
    { field: "transContractSize", title: "Kontraktgröße im Umsatz" },
    { field: "subrogativeEv", title: "Gläubigerwechsel" },
    { field: "transferReasonEv", title: "Besonderheiten Übertrag" },
    { field: "transferTypeEv", title: "Art Übertrag" },
    { field: "rltdRef", title: "Settl. Referenz Übertrag" },
    { field: "comment", title: "Kommentar" },
    { field: "overCounterFlag", title: "Tafelgeschäft" },
];

export const DerivateTransSearchMask = (): React.JSX.Element => {
    const title = "Derivate Transaktionen";
    const BRANCH_NUMBER_LENGTH = 4;
    const [currentValues, setCurrentValues] = useState<Record<string, string>>({});

    const searchCriterias: ISearchCriteria[] = [
        { field: "transactionKeyMandant", label: "Umsatzreferenz*" },
        { field: "extBusinessRef", label: "Transaktionsreferenz*" },
        { field: "derivativeCode", label: "Derivative Code*" },
        { field: "isin", label: "ISIN*" },
        { field: "branch", label: "Filialnummer" },
        {
            field: "accountNr",
            label: "Kontonummer*",
            // eslint-disable-next-line no-magic-numbers
            disabled: currentValues["branch"] === undefined || currentValues["branch"].length !== BRANCH_NUMBER_LENGTH,
        },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
        },

        {
            field: "",
            label: "Ausführungszeitpunkt",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "executeTime1", discriminator: "from" },
                    to: { label: "bis", field: "executeTime2", discriminator: "to" },
                },
            },
        },
        {
            field: "taxEvent1",
            label: "Steuerliche Geschäftsart von",
            type: "dropDown",
            comboBoxLowestFieldName: "taxEvent2",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TCG },
        },
        {
            field: "taxEvent2",
            label: "Steuerliche Geschäftsart bis",
            type: "dropDown",
            comboBoxHighestFieldName: "taxEvent1",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TCG },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/cgtransactions/search" }];
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <div>
            <Search
                title={title}
                searchCriterias={searchCriterias}
                columns={derTranColumns}
                identifier={"derivateSearch"}
                searchUrls={searchUrls}
                setCurrentValues={setCurrentValues}
                isExportable={true}
                rowColorScheme={canceledRowColorScheme}
                contextMenu={{
                    element: (
                        <TransactionContextMenu
                            contextMenus={contextMenus}
                            setContextmenus={setContextMenus}
                            currentTabText={DERIVATE_TAB}
                        />
                    ),
                    tableElement: contextMenus[DERIVATE_TAB],
                }}
                checkPairFields={[["taxEvent1", "taxEvent2"]]}
            />
        </div>
    );
};

export default DerivateTransSearchMask;
