/* author: Prashant Pal & Aashish Upadhyay */

import { flattenJson } from "components/Converter";
import {
    IDatakeys,
    IHeaderkeys as ICSVHeader,
    rowDisplay,
    columnDisplay,
    generateHeader,
} from "components/CsvGenerator";
import { ICSV, ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const ExportEventHistoryReportSearch = (): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "fiscalUnit", title: "Fiscal unit" },
        { field: "fiscalUnitId", title: "Steuerliche Einheit" },
    ];

    const searchCriterias: ISearchCriteria[] = [
        { field: "fiscalUnit", label: "Steuerliche Einheit*" },
        {
            field: "",
            label: "Zeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "differentTaxTime1", discriminator: "from" },
                    to: { label: "bis", field: "differentTaxTime2", discriminator: "to" },
                    dateRestriction: "year",
                },
            },
        },
    ];
    const searchUrls: ISearchUrl[] = [{ path: "/customerstaticdata/fiscalUnit/fiscalUnitSearchDataWithTaxTime" }];

    const datakeys1: IDatakeys[] = [
        {
            field: "taxBaseData.secTransactionData.accountData.accountNr",
            label: "Depotnummer/Kontonummer (A)",
            alternativeFields: [
                "taxBaseData.derTransactionData.accountData.accountNr",
                "taxBaseData.incomeTransactionData.accountData.accountNr",
            ],
        },
        {
            field: "taxBaseData.secTransactionData.accountData.accountType",
            label: "Kontenart (B)",
            alternativeFields: [
                "taxBaseData.derTransactionData.accountData.accountType",
                "taxBaseData.incomeTransactionData.accountData.accountType",
            ],
        },
        { field: "taxBaseData.yieldId", label: "Steuertran_ID (C)" },
        { field: "taxBaseData.system", label: "Ereignistyp (BMG TYP) (D)" },
        {
            field: "taxBaseData.secTransactionData.securityData.isin",
            label: "Bezeichung Wirtschaftsgut (WKN/ISIN/DocCode) (E)",
            alternativeFields: [
                "taxBaseData.derTransactionData.securityData.isin",
                "taxBaseData.incomeTransactionData.securityData.isin",
            ],
        },
        {
            field: "taxBaseData.secTransactionData.securityData.shortName",
            label: "Kurzbezeichnung (F)",
            alternativeFields: [
                "taxBaseData.derTransactionData.securityData.shortName",
                "taxBaseData.incomeTransactionData.securityData.shortName",
            ],
        },
        { field: "taxEvent", label: "Langtext steuerliche Geschäftsart (Tributum) (G)" },
        {
            field: "taxBaseData.secTransactionData.nominal",
            label: "Nominal bei entgeltlichen Veräußerungen (H)",
            alternativeFields: ["taxBaseData.derTransactionData.nominal", "taxBaseData.incomeTransactionData.nominal"],
        },
        {
            field: "taxBaseData.secTransactionData.settlCur",
            label: "Abrechnungswährung (I)",
            alternativeFields: [
                "taxBaseData.derTransactionData.settlCur",
                "taxBaseData.incomeTransactionData.settlCur",
            ],
        },

        {
            field: "taxBaseData.secTransactionData.settlExchRate",
            label: "Devisenkurs Abrechnungswährung (J)",
            alternativeFields: [
                "taxBaseData.derTransactionData.settlExchRate",
                "taxBaseData.incomeTransactionData.settlExchRate",
            ],
        },
        { field: "taxBaseData.differentTaxTime", label: "Steuerliches Zuflussdatum (K)" },
        { field: "taxBaseData.secTransactionData.executionTime", label: "Verarbeitungszeitpunkt (L)" },
        {
            field: "taxBaseData.secTransactionData.extTranKey",
            label: "Umsatzreferenz / TAX_ID (M)",
            alternativeFields: [
                "taxBaseData.derTransactionData.extTranKey",
                "taxBaseData.incomeTransactionData.extTranKey",
            ],
        },
        {
            field: "taxBaseData.secTransactionData.cancellationFlag",
            label: "StornoKZ (N)",
            alternativeFields: [
                "taxBaseData.derTransactionData.cancellationFlag",
                "taxBaseData.incomeTransactionData.cancellationFlag",
            ],
        },
        { field: "cancellationExtTranKey", label: "Stornoref (O)" },
        { field: "taxBaseData.correctionMark", label: "Korrektur-Kennzeichen (P)" },
        { field: "taxBaseData.sumOfTaxableBaseValues", label: "Bruttoergebnis (Q)" },
        {
            field: "taxBaseData.grossProfit",
            label: "Bruttoergebnis AGS (nur abgeltungsteuerrelevante BMGs) (R)",
        },
        { field: "exemptYieldNv", label: "Freistellung aufgrund von NV (S)" },
        { field: "exemptYieldFsa", label: "Freistellung aufgrund von FSA (T)" },
        {
            field: "exemptYieldNonResident",
            label: "Freistellung aufgrund von Ausländereigenschaft (U)",
        },
        { field: "totalShareLoss", label: "Änderung VVT Aktien (V)" },
        { field: "totalOtherLoss", label: "Änderung VVT Sonst. (W)" },
        { field: "netResult", label: "Nettoergebnis (X)" },
        { field: "capitalGainsTaxPaid", label: "Abgeführte KESt (Y)" },
        { field: "dissipatedKiSt", label: "Abgeführte KiSt (Z)" },
        { field: "dissipatedSolos", label: "Abgeführter Soli (AA)" },
        { field: "offsetableTaxLoss", label: "Änderung QUEST Topf (AB)" },
        { field: "taxBaseData.incomeTypeP20_1", label: "IncomeType-1" },
        { field: "taxBaseData.taxableBase_1", label: "Betrag zu IncomeType-1" },
        { field: "taxBaseData.incomeTypeP20_2", label: "IncomeType-2" },
        { field: "taxBaseData.taxableBase_2", label: "Betrag zu IncomeType-2" },
        { field: "taxBaseData.incomeTypeP20_3", label: "IncomeType-3" },
        { field: "taxBaseData.taxableBase_3", label: "Betrag zu IncomeType-3" },
        { field: "taxBaseData.incomeTypeP20_4", label: "IncomeType-4" },
        { field: "taxBaseData.taxableBase_4", label: "Betrag zu IncomeType-4" },
        { field: "taxBaseData.incomeTypeP20_5", label: "IncomeType-5" },
        { field: "taxBaseData.taxableBase_5", label: "Betrag zu IncomeType-5" },
        { field: "taxBaseData.incomeTypeP20_6", label: "IncomeType-6" },
        { field: "taxBaseData.taxableBase_6", label: "Betrag zu IncomeType-6" },
        { field: "taxBaseData.incomeTypeP20_7", label: "IncomeType-7" },
        { field: "taxBaseData.taxableBase_7", label: "Betrag zu IncomeType-7" },
        { field: "taxBaseData.incomeTypeP20_8", label: "IncomeType-8" },
        { field: "taxBaseData.taxableBase_8", label: "Betrag zu IncomeType-8" },
        { field: "taxBaseData.incomeTypeP20_9", label: "IncomeType-9" },
        { field: "taxBaseData.taxableBase_9", label: "Betrag zu IncomeType-9" },
        { field: "taxBaseData.incomeTypeP20_10", label: "IncomeType-10" },
        { field: "taxBaseData.taxableBase_10", label: "Betrag zu IncomeType-10" },
        { field: "taxBaseData.incomeTypeP20_11", label: "IncomeType-11" },
        { field: "taxBaseData.taxableBase_11", label: "Betrag zu IncomeType-11" },
        { field: "taxBaseData.incomeTypeP20_12", label: "IncomeType-12" },
        { field: "taxBaseData.taxableBase_12", label: "Betrag zu IncomeType-12" },
        { field: "taxBaseData.incomeTypeP20_13", label: "IncomeType-13" },
        { field: "taxBaseData.taxableBase_13", label: "Betrag zu IncomeType-13" },
        { field: "taxBaseData.incomeTypeP20_14", label: "IncomeType-14" },
        { field: "taxBaseData.taxableBase_14", label: "Betrag zu IncomeType-14" },
        { field: "taxBaseData.incomeTypeP20_15", label: "IncomeType-15" },
        { field: "taxBaseData.taxableBase_15", label: "Betrag zu IncomeType-15" },
    ];

    const datakeys2: IDatakeys[] = [
        {
            field: "eventHistoryReportCSVData1.totalExemptFsaDomFrom,eventHistoryReportCSVData2.totalExemptFsaDomFrom",
            label: "Aktuell ausgenutzter FSA",
        },
        {
            field: "eventHistoryReportCSVData1.totalShareDomFrom,eventHistoryReportCSVData2.totalShareDomFrom",
            label: "Topfstand Aktien (Inländer)",
        },
        {
            field: "eventHistoryReportCSVData3.totalShareDomFrom,eventHistoryReportCSVData4.totalShareDomFrom",
            label: "Topfstand Aktien (Ausländer)",
        },
        {
            field: "eventHistoryReportCSVData1.totalOtherDomFrom, eventHistoryReportCSVData2.totalOtherDomFrom",
            label: "Topfstand Sonstige (Inländer)",
        },
        {
            field: "eventHistoryReportCSVData3.totalOtherDomFrom, eventHistoryReportCSVData4.totalOtherDomFrom",
            label: "Topfstand Sonstige (Ausländer)",
        },
        {
            field: "eventHistoryReportCSVData1.offsetableTaxDomFrom, eventHistoryReportCSVData2.offsetableTaxDomFrom",
            label: "Topfstand QUEST (Inländer)",
        },
        {
            field: "eventHistoryReportCSVData3.offsetableTaxDomFrom, eventHistoryReportCSVData4.offsetableTaxDomFrom",
            label: "Topfstand QUEST (Ausländer)",
        },
        {
            field: "eventHistoryReportCSVData1.totalDWTDomFrom, eventHistoryReportCSVData2.totalDWTDomFrom",
            label: "Saldo KESt (Inländer)",
        },
        {
            field: "eventHistoryReportCSVData3.totalDWTDomFrom, eventHistoryReportCSVData4.totalDWTDomFrom",
            label: "Saldo KESt (Ausländer)",
        },
        {
            field: "eventHistoryReportCSVData1.totalChurchTaxDomFrom, eventHistoryReportCSVData2.totalChurchTaxDomFrom",
            label: "Saldo KiSt (Inländer)",
        },
        {
            field: "eventHistoryReportCSVData3.totalChurchTaxDomFrom, eventHistoryReportCSVData4.totalChurchTaxDomFrom",
            label: "Saldo KiSt (Ausländer)",
        },
        {
            field: "eventHistoryReportCSVData1.totalSoliSurchargeDomFrom, eventHistoryReportCSVData2.totalSoliSurchargeDomFrom",
            label: "Saldo Soli (Inländer)",
        },
        {
            field: "eventHistoryReportCSVData3.totalSoliSurchargeDomFrom, eventHistoryReportCSVData4.totalSoliSurchargeDomFrom",
            label: "Saldo Soli (Ausländer)",
        },
    ];

    const datakeys3: IDatakeys[] = [
        {
            field: "fiscalUnitData.fiscalUnitVersionData.legalFormCategory",
            label: "Legal Form Category",
        },
        {
            field: "personDomicile",
            label: "Steuerausländer KZ",
        },
        {
            field: "personChurchTaxRate",
            label: "KIST Abzug und KIST Satz (Person 1/Person 2)",
        },
        {
            field: "kistamChurchTaxRate",
            label: "0.00%",
        },
        {
            field: "grossYieldRatio",
            label: "Aufteilungsverhältnis KIST",
        },
        {
            field: "fsaLimitData.exemptAmount",
            label: "Eingereichter FSA",
        },
        {
            field: "nvData.nvType",
            label: "NV- Bescheinigung",
        },
    ];

    // For Subheaders of the CSV File
    const headerkeys: ICSVHeader[] = [
        {
            label: "Ereignisse von# bis?",
            combinedFields: [
                { field: "differentTaxTime1", replacer: "#" },
                { field: "differentTaxTime2", replacer: "?" },
            ],
            datakeys: datakeys1,
        },
        {
            //, added for column separations in csv file
            label: " , Beginn Umsatzzeitraum, Ende Umsatzzeitraum",
            datakeys: datakeys2,
        },
        {
            label: "Steuerlicher Status zum#",
            combinedFields: [{ field: "differentTaxTime2", replacer: "#" }],
            datakeys: datakeys3,
        },
    ];

    // For Main Header of the CSV File
    const mainHeader: ICSVHeader = {
        label: "Ereignishistorie und berechnete Steuerwerte für steuerliche Einheit#",
        combinedFields: [{ field: "fiscalUnit", replacer: "#" }],
    };

    const CSVInfo: ICSV = {
        fileName: "EventHistoryCSVFile",
        sendRequestForCSVData: (currentRowData, searchCriteriaText) => {
            return [
                {
                    path: "/cgresources/taxaccounting/getTaxAccountingByFiscalUnitId",
                    params: {
                        fuId: currentRowData["fiscalUnitId"],
                        validFrom: searchCriteriaText["differentTaxTime1"],
                        validTo: searchCriteriaText["differentTaxTime2"],
                    },
                },
                {
                    path: "/cgresources/taxaccounting/getTaxAccountingPart2ByFuId",
                    params: {
                        fuId: currentRowData["fiscalUnitId"],
                        validFrom: searchCriteriaText["differentTaxTime1"],
                        validTo: searchCriteriaText["differentTaxTime2"],
                    },
                },
            ];
        },
        prepareCSVData: (
            currentRowData: Record<string, string>,
            searchCriteria: Record<string, string>,
            response: Record<string, string>[]
        ): string => {
            return csvTemplate(currentRowData, searchCriteria, response);
        },
    };
    const csvTemplate = (
        currentRowData: Record<string, string>,
        searchCriteria: Record<string, string>,
        responses: Record<string, string>[]
    ) => {
        const exportCSVDataTemp1: Record<string, string>[] = [];

        //Labels of part2 data whose value has to be replaced with summed value
        const mappingRecord: Record<string, string> = {
            "eventHistoryReportCSVData2.totalExemptFsaDomFrom": "exemptYieldFsa",
            "eventHistoryReportCSVData2.totalDWTDomFrom": "capitalGainsTaxPaid",
            "eventHistoryReportCSVData2.totalChurchTaxDomFrom": "dissipatedKiSt",
            "eventHistoryReportCSVData2.totalSoliSurchargeDomFrom": "dissipatedSolos",
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempResponse1: any = responses[0]?.data;

        // //Getting summed values of the labels
        const summedValue: Record<string, number> = {};
        Object.keys(mappingRecord).forEach((key: string) => {
            let sum: number = 0;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tempResponse1.forEach((response: any) => {
                if (response[mappingRecord[key]]) {
                    sum += parseFloat(response[mappingRecord[key]]);
                }
            });
            summedValue[mappingRecord[key]] = sum;
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempResponse2: any = responses[1]?.data;

        //Assigning the summed value to response data
        Object.keys(tempResponse2).forEach((tempResponseKey: string) => {
            if (tempResponseKey === "eventHistoryReportCSVData2") {
                const calculatedValue: Record<string, number> = {};
                Object.keys(mappingRecord).forEach((key: string) => {
                    calculatedValue[key.substring(key.indexOf(".") + 1, key.length)] = summedValue[mappingRecord[key]];
                });
                tempResponse2[tempResponseKey] = calculatedValue;
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tempResponse1.forEach((response: any) => {
            exportCSVDataTemp1.push(flattenJson(response));
        });

        const exportCSVDataTemp2: Record<string, string>[] = [];
        exportCSVDataTemp2.push(flattenJson(tempResponse2));

        let csvData: string[] = [];
        csvData.push(generateHeader(mainHeader, currentRowData, searchCriteria));
        if (exportCSVDataTemp1.length > 0) {
            csvData.push(generateHeader(headerkeys[0], currentRowData, searchCriteria));
            csvData = csvData.concat(rowDisplay(exportCSVDataTemp1, headerkeys[0]));
        }
        if (exportCSVDataTemp2.length > 0) {
            csvData.push(generateHeader(headerkeys[1], currentRowData, searchCriteria));
            csvData = csvData.concat(columnDisplay(exportCSVDataTemp2, headerkeys[1]));
            csvData.push(generateHeader(headerkeys[2], currentRowData, searchCriteria));
            csvData = csvData.concat(columnDisplay(exportCSVDataTemp2, headerkeys[2]));
        }

        return csvData.join("\n");
    };

    return (
        <Search
            title={"Auswahl Erreignishistorie"}
            searchCriterias={searchCriterias}
            columns={columns}
            searchUrls={searchUrls}
            identifier="exportEventHistoryReportSearch"
            CSV={CSVInfo}
            groupFields={["fiscalUnit", "differentTaxTime1", "differentTaxTime2"]}
            hideTechnicalColumns
        />
    );
};
