/* author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";

export interface IKeyMap {
    label: string;
    value: string;
}

export interface IKeyTableGetter {
    tabname: string;
    allOption?: boolean;
}

const keyTableMap = new Map<string, Map<string, string>>();
const optionsMap = new Map<string, IKeyMap[]>();

export const getOptionsMap = async (tabname: string): Promise<IKeyMap[] | undefined> => {
    if (!optionsMap.has(tabname)) await fetchKeyTable(tabname);

    return optionsMap.get(tabname);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const currrentlyFetching: Map<string, Promise<any>> = new Map();
const fetchKeyTable = async (tabname: string): Promise<void> => {
    //check if the tabname is allready is getting fetched
    if (currrentlyFetching.has(tabname)) {
        await currrentlyFetching.get(tabname);
        return;
    }

    //fetch keytable
    currrentlyFetching.set(
        tabname,
        sendGetRequest({
            path: "/autocomplete/getDropdown/keyTableValues",
            params: { tabname: tabname },
        })
    );

    const response = await currrentlyFetching.get(tabname);

    const responseFinal: IKeyMap[] = response.data;

    const tempMap: Map<string, string> = new Map<string, string>();
    responseFinal.forEach((keyMap: IKeyMap) => {
        tempMap.set(keyMap.value, keyMap.label);
    });
    keyTableMap.set(tabname, tempMap);
    optionsMap.set(tabname, responseFinal);

    //remove from currently fetching
    currrentlyFetching.delete(tabname);
};
