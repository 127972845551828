/* author: JESCHKE Moritz */

import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DifferenceRoundedIcon from "@mui/icons-material/DifferenceRounded";
import ArticleIcon from "@mui/icons-material/Article";
import LayersIcon from "@mui/icons-material/Layers";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SchemaIcon from "@mui/icons-material/Schema";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import { Divider } from "@mui/material";
import DataSaverOffRoundedIcon from "@mui/icons-material/DataSaverOffRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";

import { Dispatch, SetStateAction } from "react";
import { USER_ADMIN } from "pages/login/Login";
import { IMenuItemData, MenuItem } from "./MenuItem";

export const CALCULATION_POT: string = "Umsätze zu Verrechnungstöpfen";
export const BALANCES_INCOMES_TAXES_PER_TAXYEAR: string = "Salden, Erträge und Steuern / Steuerjahr";
export const CALCULATION_POT_PER_DAY: string = "Verrechnungstopfstand per Stichtag";

export const TED_DEPOT_INGOING_MENU_ITEM: string = "Depotüberträge Eingang";
export const TED_DEPOT_OUTGOING_MENU_ITEM: string = "Depotüberträge Ausgang";
export const TED_CALCULATION_POT_INGOING_MENU_ITEM: string = "Verrechnungstöpfe Eingang";
export const TED_CALCULATION_POT_OUTGOING_MENU_ITEM: string = "Verrechnungstöpfe Ausgang";

export interface IMenuBarItemList {
    currentOpenMenus: string[];
    setCurrentOpenMenus: Dispatch<SetStateAction<string[]>>;
}

export const tbd: string = "TO BE DEVELOPED";

// Drawer Menü Items
export const MenuBarItemList = (props: IMenuBarItemList): React.JSX.Element => {
    /* ### VARIABLES & STATES ### */
    let idCount = 0;
    const getId = (): number => {
        return ++idCount;
    };

    const OTHER_PROCESSING_PATH: string = "/otherProcessing";
    const DEPOT_EXCHANGE_SEARCH_PATH: string = "/depotExchangeSearch";
    const CALCULATION_POT_SEARCH_PATH: string = "/calculationPotSearch";
    //TODO :: make constants for menu names to use them in code
    const menuItems: IMenuItemData[] = [
        {
            linkTo: "home",
            icon: <DashboardIcon />,
            name: "Dashboard",
            id: getId(),
        },
        {
            icon: <PersonIcon />,
            name: "Personenstammdaten",
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "personensuchmaske",
                    name: "Personensuche",
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusPerson",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
            ],
        },
        {
            icon: <AccountBalanceWalletRoundedIcon />,
            name: "Konto",
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "accountSearchMask",
                    name: "Kontosuche",
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusAccount",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
            ],
        },
        {
            icon: <LayersIcon />,
            name: "Steuerliche Einheit",
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "fiscalUnit",
                    name: "Steuerliche Einheit - Suche",
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusFU",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
            ],
        },
        {
            icon: <AccountBalanceIcon />,
            name: "Wertpapierstammdaten",
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "securitySearchMask",
                    name: "Wertpapiersuche",
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusSecurity",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
            ],
        },
        {
            icon: <SwapHorizIcon />,
            name: "Transaktionen",
            id: getId(),
            subMenuItem: [
                {
                    name: "Wertpapiere",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "securityTransactionSearchMask",
                            name: "Wertpapiere - Suche",
                            id: getId(),
                        },
                        {
                            linkTo: "overviewBearbeitungsstatusSecTran",
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                    ],
                },
                {
                    name: "Derivate",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "derivateTranSearchMask",
                            name: "Derivate - Suche",
                            id: getId(),
                        },
                        {
                            linkTo: "overviewBearbeitungsstatusDerTran",
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                    ],
                },
                {
                    name: "Erträge",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "incomeTranSearch",
                            name: "Erträge - Suche",
                            id: getId(),
                        },
                        {
                            linkTo: "overviewBearbeitungsstatusIncomeTran",
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                    ],
                },
            ],
        },
        {
            icon: <DifferenceRoundedIcon />,
            name: "Bestandabgleich",
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "secReconDataSearchMask",
                    name: "Wertpapiere",
                    id: getId(),
                },
                {
                    linkTo: "bestandsabgleichDerivateSearch",
                    name: "Derivate",
                    id: getId(),
                },
            ],
        },
        {
            icon: <EuroRoundedIcon />,
            name: "Steuern",
            passOrigin: true,
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "/otherProcessing",
                    name: CALCULATION_POT,
                    id: getId(),
                },
                {
                    linkTo: OTHER_PROCESSING_PATH,
                    name: "Umsätze zu FSA",
                    id: getId(),
                },
                {
                    linkTo: OTHER_PROCESSING_PATH,
                    name: "Umsätze zu NV",
                    id: getId(),
                },
                {
                    linkTo: OTHER_PROCESSING_PATH,
                    name: "Umsätze zu Statusbescheinigungen",
                    id: getId(),
                },
                {
                    linkTo: "/otherProcessing",
                    name: BALANCES_INCOMES_TAXES_PER_TAXYEAR,
                    id: getId(),
                },

                {
                    linkTo: "/otherProcessing",
                    name: CALCULATION_POT_PER_DAY,
                    id: getId(),
                },
                {
                    linkTo: "/searchTaxAtFlatRateSecurity",
                    name: "Pauschalbesteuerte Umsätze Wertpapiere",
                    id: getId(),
                },
                {
                    linkTo: "/searchTaxAtFlatRateDerivative",
                    name: "Pauschalbesteuerte Umsätze Derivate",
                    id: getId(),
                },
                {
                    linkTo: "/taxSearch",
                    name: "Bemessungsgrundlagen - Suche",
                    id: getId(),
                },
            ],
        },
        {
            icon: <ArticleIcon />,
            name: "Taxbox",
            passOrigin: true,
            id: getId(),
            subMenuItem: [
                {
                    linkTo: DEPOT_EXCHANGE_SEARCH_PATH,
                    name: TED_DEPOT_INGOING_MENU_ITEM,
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusTedDepotIn",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
                {
                    linkTo: DEPOT_EXCHANGE_SEARCH_PATH,
                    name: TED_DEPOT_OUTGOING_MENU_ITEM,
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusTedDepotOut",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
                {
                    linkTo: CALCULATION_POT_SEARCH_PATH,
                    name: TED_CALCULATION_POT_INGOING_MENU_ITEM,
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusTedCalcPotIn",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
                {
                    linkTo: CALCULATION_POT_SEARCH_PATH,
                    name: TED_CALCULATION_POT_OUTGOING_MENU_ITEM,
                    id: getId(),
                },
                {
                    linkTo: "overviewBearbeitungsstatusTedCalcPotOut",
                    name: "Bearbeitungsstatus",
                    id: getId(),
                },
            ],
        },
        {
            icon: <ErrorOutlineRoundedIcon />,
            name: "Fehlerliste",
            id: getId(),
            subMenuItem: [
                {
                    name: "Wertpapiere",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "/failedSecTrans",
                            name: "Wertpapiere - Suche",
                            id: getId(),
                        },
                        {
                            linkTo: tbd,
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                    ],
                },
                {
                    name: "Derivate",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "/failedDerivateTrans",
                            name: "Derivate - Suche",
                            id: getId(),
                        },
                        {
                            linkTo: tbd,
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                    ],
                },
                {
                    name: "Erträge",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "/failedIncomeTrans",
                            name: "Erträge - Suche",
                            id: getId(),
                        },
                        {
                            linkTo: tbd,
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                    ],
                },
            ],
        },
        {
            icon: <ViewListRoundedIcon />,
            name: "Auftragsverwaltung",
            id: getId(),
            subMenuItem: [
                {
                    name: "Jahressteuerdokumente",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "yerSingleRequestSearchMask",
                            name: "Einzelauftrag",
                            id: getId(),
                        },
                        {
                            linkTo: "overviewBearbeitungsstatusYerSingle",
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                        {
                            linkTo: "yerMassRequestSearchMask",
                            name: "Massenauftrag",
                            id: getId(),
                        },
                        {
                            linkTo: "overviewBearbeitungsstatusYerMass",
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                        {
                            linkTo: "manageAdditionalFields",
                            name: "Zusatzfelder verwalten",
                            id: getId(),
                            allowedFor: [USER_ADMIN],
                        },
                    ],
                },
                {
                    linkTo: tbd,
                    name: "Schenkung",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "/donationDataSearchMask",
                            name: "Schenkungsdaten",
                            id: getId(),
                        },
                    ],
                },
                {
                    linkTo: tbd,
                    name: "FSAK",
                    id: getId(),
                },
                {
                    linkTo: tbd,
                    name: "FKB",
                    id: getId(),
                },
            ],
        },
        {
            icon: <SummarizeRoundedIcon />,
            name: "Meldungen und Reporte",
            id: getId(),
            subMenuItem: [
                {
                    linkTo: "/exportEventHistory",
                    name: "Export Ereignishistorie",
                    id: getId(),
                },
                {
                    linkTo: "/kestRegistration",
                    name: "KESt-Anmeldung",
                    id: getId(),
                },
                {
                    //linkTo: "/donationDeclarationSearchMask",
                    name: "Schenkungsmeldung",
                    id: getId(),
                    subMenuItem: [
                        {
                            linkTo: "/donationDeclarationSearchMask",
                            name: "Suche Schenkungsmeldung",
                            id: getId(),
                        },
                        {
                            linkTo: "/overviewBearbeitungsstatusDonation",
                            name: "Bearbeitungsstatus",
                            id: getId(),
                        },
                        {
                            linkTo: "/donationSearchReport",
                            name: "Suche Gesamtmeldung",
                            id: getId(),
                        },
                    ],
                },
                {
                    linkTo: "fsakRequestSearchMask",
                    name: "FSAK-Meldung",
                    id: getId(),
                },
                {
                    linkTo: "fkbRequestSearchMask",
                    name: "FKB-Meldung",
                    id: getId(),
                },
            ],
        },
        {
            icon: <SchemaIcon />,
            name: "Flowsteuerung",
            linkTo: "flowControl",
            allowedFor: [USER_ADMIN],
            id: getId(),
        },
    ];

    const tempMenuItems: IMenuItemData[] = [
        {
            linkTo: "test",
            icon: <EngineeringIcon />,
            name: "TestTable",
            id: getId(),
        },
        {
            linkTo: "/derivatives",
            icon: <LayersIcon />,
            name: "Derivatives",
            id: getId(),
        },
        {
            linkTo: "securityMasterData",
            icon: <DataSaverOffRoundedIcon />,
            name: "SecurityMasterData",
            id: getId(),
        },
        {
            linkTo: "securityIncomeData",
            icon: <DataSaverOffRoundedIcon />,
            name: "SecurityIncomeData",
            id: getId(),
        },
        {
            linkTo: "TransactionOverview",
            icon: <ReceiptRoundedIcon />,
            name: "TransactionOverview",
            id: getId(),
        },
        {
            linkTo: "secCorpActionDetails",
            icon: <DataSaverOffRoundedIcon />,
            name: "SecCorpActionDetails",
            id: getId(),
        },
        {
            linkTo: "secSwitchingDetails",
            icon: <DataSaverOffRoundedIcon />,
            name: "SecSwitchingDetails",
            id: getId(),
        },
        {
            linkTo: "failedIncomeTrans",
            icon: <GppBadOutlinedIcon />,
            name: "Failed Income Trans",
            id: getId(),
        },
        {
            linkTo: "fuHistory",
            icon: <EngineeringIcon />,
            name: "Fu History",
            id: getId(),
        },
    ];

    /* ### HTML ### */

    return (
        <div>
            <MenuItem
                currentOpenMenus={props.currentOpenMenus}
                menuItems={menuItems}
                setCurrentOpenMenus={props.setCurrentOpenMenus}
            />

            {/** TODO :: cleanup  */}
            {/** TEMP MENU ITEMS */}
            <div
                style={{
                    backgroundImage: "repeating-linear-gradient(45deg, #FFCCCB, #FFCCCB 22px, white 22px, white 44px)",
                }}
                hidden={localStorage.getItem("disableTempElements") === "true"}
            >
                <Divider sx={{ paddingTop: "3rem" }}>TEMP MENU ITEMS</Divider>

                <MenuItem
                    currentOpenMenus={props.currentOpenMenus}
                    menuItems={tempMenuItems}
                    setCurrentOpenMenus={props.setCurrentOpenMenus}
                />
            </div>
        </div>
    );
};
