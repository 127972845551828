import { Tooltip } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";

interface IOverflowTooltip {
    tooltipText: string;
    element: (ref: React.MutableRefObject<undefined>) => React.JSX.Element;
}

export const ResizeOverflowTooltip = (props: IOverflowTooltip): React.JSX.Element => {
    // Create Ref
    const textElementRef = useRef();

    const compareSize = () => {
        if (textElementRef.current) {
            const current: HTMLElement = textElementRef.current as HTMLElement;
            const compare = current.children[0].scrollWidth > current.children[0].clientWidth;
            setHover(compare);
        }
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener("resize", compareSize);
        },
        []
    );

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip
            title={props.tooltipText}
            followCursor={true}
            disableInteractive
            disableHoverListener={!hoverStatus}
            style={{ fontSize: "2em" }}
        >
            {props.element(textElementRef)}
        </Tooltip>
    );
};
