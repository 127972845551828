/* author: Aashish Upadhyay  */

import { EditPendingLinked } from "components/EditPendingLinked";
import { ITableColumn } from "components/Table";

export const EditPendingLinkedAccountInfo = (): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "branch", title: "Filialnummer" },
        { field: "accountNr", title: "Kontonummer" },
        { field: "accountType", title: "Kontoart" },
        { field: "name", title: "Kontoname" },
        { field: "country", title: "Land des Kontos" },
        { field: "currency", title: "Kontowährung" },
        { field: "iban", title: "IBAN" },
    ];

    return (
        <EditPendingLinked
            columns={columns}
            dataRequest={{
                path: "/customerstaticdata/account/searchEditPending",
            }}
            identifier="editPendingOverviewAccount"
            title="Konto"
            navigate={{ linkTo: "TransactionOverview", idField: "id" }}
        />
    );
};
