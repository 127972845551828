/* author: JESCHKE Moritz */

import { SelectChangeEvent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch } from "application/redux/Hooks";
import { logout, setClientId } from "application/redux/slices/UserSlice";
import { useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { ComboBox } from "components/ComboBox";

export const ClientIdDialog = (): React.JSX.Element => {
    const dispatch = useAppDispatch();

    const [selectedClientId, setSelectedClientId] = useState<string>("");
    const [clientIdOptions, setClientIdOptions] = useState<Record<string, string>[]>([]);

    const getNameFromId = (id: string): string => {
        for (const clientOption of clientIdOptions) {
            if (clientOption.clientId === id) {
                return clientOption.name;
            }
        }
        return "";
    };

    const handkleOkPress = () => {
        dispatch(setClientId({ clientId: selectedClientId, clientName: getNameFromId(selectedClientId) }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const request = await sendGetRequest({ path: "/cgresources/client/getClientNames" });
            setClientIdOptions(request.data);
        };

        fetchData();
    }, []);

    /**
     * Call function if special key is pressed
     * @param event KeyPressEvent
     */
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && !buttonDisableCheck()) {
            handkleOkPress();
        }
    };

    /**
     * Checks if the button is disabled
     * @returns true | false
     */
    const buttonDisableCheck = (): boolean => {
        return selectedClientId === "";
    };

    return (
        <Dialog open={true} onKeyDown={handleKeyDown}>
            <DialogTitle>{"Wählen Sie einen Mandant für die Loginsession aus:"}</DialogTitle>
            <DialogContent>
                <div style={{ marginTop: "1em" }}>
                    <ComboBox
                        label="Mandant"
                        onChange={(event: SelectChangeEvent<string>) => setSelectedClientId(event.target.value)}
                        options={clientIdOptions}
                        value={selectedClientId}
                        optionIdentifier="clientId"
                        optionValue={["identification", "name"]}
                        optionValueSeparator="/"
                        width="100%"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={buttonDisableCheck()} onClick={handkleOkPress}>
                    Ok
                </Button>
                <Button onClick={() => dispatch(logout())}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};
