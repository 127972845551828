/* author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { flattenJsonList } from "components/Converter";
import { PopUp } from "components/PopUp";
import { ITableColumn, ITableRowSelection, Table } from "components/Table";
import { IdSelector } from "components/TBD/IdSelector";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface IPersonalBmgDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    impId: string;
    extTranKey: string;
    system: "F" | "C";
}

export const BaseBmgDetails = (props: IPersonalBmgDetails): React.JSX.Element => {
    const [impId, setImpId] = useState<string>(props.impId);

    const IDENTIFIER_TAX_BASE: TableidentifierType = "taxBaseNC";

    const taxBaseColumns: ITableColumn[] = [
        { field: "fiscalUnitData.fiscalUnit", title: "Steuerliche Einheit" },
        { field: "accountData.branch", title: "Filialnummer" },
        { field: "accountData.accountNr", title: "Kontonummer" },
        { field: "yieldId", title: "Ertrags-Id" },
        { field: "cancellationFlag", title: "StoKZ" },
        { field: "refTaxBaseId", title: "Storno Ertrags-Id" },
        {
            field: "insertTS",
            title: "Einfüge-Datum",
            type: "date",
        },
        {
            field: "updateTS",
            title: "Aktualisierungs-Datum",
            type: "date",
        },
    ];

    const [taxBaseData, setTaxBaseData] = useState<Record<string, string>[]>([]);

    const [taxBaseSelectedYieldId, setTaxBaseSelectedYieldId] = useState<number>();
    const taxBaseSelection: ITableRowSelection = {
        onSelect: (rowData) => {
            setTaxBaseSelectedYieldId(rowData.yieldId);
            setIncomeTypeData(rowData.incomeTypeDataList);
        },
        onDeselect: () => undefined,
        selectedRows: [taxBaseSelectedYieldId],
        selector: "yieldId",
    };

    const IDENTIFIER_INCOME_TYPE: TableidentifierType = "incomeType";

    const incomeTypeColums: ITableColumn[] = [
        { field: "incomeType", title: "Einkommenstyp" },
        { field: "taxableBase", title: "Bemessungsgrundlage in EUR" },
    ];

    const [incomeTypeData, setIncomeTypeData] = useState<Record<string, string | number>[]>([]);

    useEffect(() => {
        sendGetRequest({ path: "/cgresources/taxbase", params: { impId: +impId, system: props.system } }).then(
            (response) => {
                setTaxBaseData(flattenJsonList(response.data, ["incomeTypeDataList"]));
                if (response.data[0]) {
                    setTaxBaseSelectedYieldId(response.data[0].yieldId);
                    setIncomeTypeData(response.data[0].incomeTypeDataList);
                } else setIncomeTypeData([]);
            }
        );
    }, [impId]);

    return (
        <PopUp
            title={"Sachliche Bemessungsgrundlagen für Umsatzreferenz " + props.extTranKey}
            setOpen={props.setOpen}
            content={
                <div style={{ display: "grid", gap: "3rem" }}>
                    <IdSelector label="impId" setId={setImpId} />
                    <Table
                        columnsDefinition={taxBaseColumns}
                        tableData={taxBaseData}
                        identifier={IDENTIFIER_TAX_BASE}
                        selection={taxBaseSelection}
                        title="Bemessungsgrundlagen"
                        disableAllSelectable={true}
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                    <Table
                        columnsDefinition={incomeTypeColums}
                        tableData={incomeTypeData}
                        identifier={IDENTIFIER_INCOME_TYPE}
                        title="Einkommensarten"
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                </div>
            }
        />
    );
};
