/* author: PAL Prashant */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Location, useLocation, useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";
import { kistamDenominationShorten } from "./KistamVersions";
import { yearValidation } from "components/EditComponents/CommonValidations";

export const GENERAL_PERSON_SECTION: string = "Personen Daten";
export const ADDRESS_SECTION: string = "Adressen";
export const KISTAM_SECTION: string = "Kirchensteuer";

export const PersonOverviewEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const personId: number = Number(params.personId);
    const section: string | undefined = params.section;
    const versionId: number = Number(params.versionId === "new" ? -1 : params.versionId);

    const location: Location = useLocation();
    const isNew: boolean = location.state && location.state.isNew;

    const personEditInfo: IEditDataViewUserConfiguration = {
        headline: GENERAL_PERSON_SECTION,
        attributes: [
            {
                field: "lastName",
                headerName: "Nachname",
                rules: {
                    minLength: { value: 3 },
                },
            },
            {
                field: "firstName",
                headerName: "Vorname",
                rules: {
                    minLength: { value: 3 },
                },
            },
            {
                field: "middleName",
                headerName: "Zweitname",
                rules: {
                    minLength: { value: 3 },
                },
            },
            {
                field: "title",
                headerName: "Titel",
                rules: {
                    minLength: { value: 3 },
                },
            },
            {
                field: "nationality",
                headerName: "Nationalität",
                dropdown: {
                    keyTableTabname: "TB_WML02",
                },
            },
            {
                field: "gender",
                headerName: "Geschlecht",
                dropdown: {
                    keyTableTabname: "GENDER",
                },
            },
            {
                field: "tin",
                headerName: "TIN",
                rules: {
                    minLength: { value: 3 },
                },
            },
            {
                field: "federalState",
                headerName: "Bundesland",
                dropdown: {
                    keyTableTabname: "FEDERALSTATE",
                },
            },
            {
                field: "email",
                headerName: "Email",
                rules: {
                    pattern: {
                        value: /^$|^[^@\s]+@[^@\s]+\.[a-zA-Z]{2,}$/,
                        customMessage: "Tragen Sie eine gültige Email Adresse ein!",
                    },
                },
            },
            // { field: "ChurchTaxRate", headerName: "" },
            // { field: "denomination", headerName: "" },
            {
                field: "birthName",
                headerName: "Geburtsname",
                rules: {
                    minLength: { value: 3 },
                },
            },

            {
                field: "birthDate",
                headerName: "Geburtstag",
                type: "date",
            },
            {
                field: "birthPlace",
                headerName: "Geburtsort",
            },
            {
                field: "deathDate",
                headerName: "Todestag",
                type: "date",
            },
            {
                field: "validFrom",
                headerName: "Gültig von",
                type: "date",
            },
            {
                field: "validTo",
                headerName: "Gültig bis",
                type: "date",
            },
            {
                field: "domicile",
                headerName: "Domizil",
                dropdown: {
                    keyTableTabname: "TB_WML02",
                },
            },
        ],
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/person/ForEdit",
                params: { personId: personId },
            }),
        versionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/person/byVersionIdForEdit",
                params: { versionId: versionId, isNew: isNew },
            }),
    };

    const addressEditInfo: IEditDataViewUserConfiguration = {
        headline: ADDRESS_SECTION,
        attributes: [
            {
                field: "addressType",
                headerName: "Art der Adresse",
                dropdown: {
                    keyTableTabname: "ADDRESSTYPE",
                },
            },
            {
                field: "country",
                headerName: "Land",
                dropdown: {
                    keyTableTabname: "TB_WML02",
                },
            },
            {
                field: "zipCode",
                headerName: "PLZ",
                rules: {
                    maxLength: { value: 16 },
                },
            },
            {
                field: "town",
                headerName: "Ort",
                rules: {
                    maxLength: { value: 35 },
                },
            },
            {
                field: "street1",
                headerName: "Strasse",
            },
            {
                field: "houseNumber",
                headerName: "Hausnummer",
                rules: {
                    maxLength: {
                        value: 10,
                    },
                },
            },
            {
                field: "poBox",
                headerName: "Postfach",
                rules: {
                    maxLength: { value: 35 },
                },
            },
            {
                field: "telephone",
                headerName: "Telefon",
                rules: {
                    pattern: {
                        // value: /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/,
                        value: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
                        customMessage: "Tragen Sie eine gültige Telefonnummer ein!",
                    },
                },
            },
            {
                field: "fax",
                headerName: "Fax",
                rules: {
                    maxLength: { value: 35 },
                },
            },
            {
                field: "validFrom",
                headerName: "Gültig von",
                type: "date",
            },
            {
                field: "validTo",
                headerName: "Gültig bis",
                type: "date",
            },
        ],
        tabName: "Adresse",
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/address/getAddressesByPersonIdForEdit",
                params: { personId: personId },
            }),
        versionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/address/ForEdit",
                params: { versionId: versionId, isNew: isNew },
            }),
    };

    const EARLIEST_KISTAM_FISCAL_YEAR: number = 2014;
    const churchTax: IEditDataViewUserConfiguration = {
        headline: KISTAM_SECTION,
        attributes: [
            {
                field: "fiscalYearFrom",
                headerName: "Steuerjahr von",
                rules: yearValidation,
            },
            {
                field: "fiscalYearTo",
                headerName: "Steuerjahr bis",
                rules: yearValidation,
            },

            {
                field: "denomination",
                headerName: "Konfession (von BZST)",
                dropdown: {
                    keyTableTabname: "DENOMINATION_ALL",
                    optionValueFilter: kistamDenominationShorten,
                },
            },
            {
                field: "churchTaxRate",
                headerName: "Kirchensteuer Rate (von BZST)",
            },
        ],
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/kistam/ForEdit",
                params: { personId: personId },
            }),
        versionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/kistam/byVersionIdForEdit",
                params: { versionId: versionId, isNew: isNew },
            }),
        checkInvalidity: [
            {
                condition: (data: Record<string, unknown>): boolean =>
                    !!data["fiscalYearFrom"] && Number(data["fiscalYearFrom"]) <= EARLIEST_KISTAM_FISCAL_YEAR,
                relatedFields: ["fiscalYearFrom"],
            },
            {
                condition: (data: Record<string, unknown>): boolean =>
                    !!data["fiscalYearTo"] && Number(data["fiscalYearTo"]) <= EARLIEST_KISTAM_FISCAL_YEAR,
                relatedFields: ["fiscalYearTo"],
            },
        ],
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [personEditInfo, addressEditInfo, churchTax];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={personId}
            title="Personendaten bearbeiten"
            section={section}
            parentIdentifier="personId"
        />
    );
};
