import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { Typography } from "@mui/material";

export const Chart2 = () => {
    Chart.register(...registerables);

    const [chartData, setChartData] = useState<number[]>();

    useEffect(() => {
        const fetchData = async () => {
            const request = await sendGetRequest({ path: "/jstbresources/reportFetcher/getCount" });
            setChartData([request.data.fuCountWithJstb, request.data.allCount - request.data.fuCountWithJstb]);
        };

        fetchData();
    }, []);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <div style={{ margin: "1em" }}>
            <Typography variant="h6">Verhältnis FUs mit und ohne JSTB Report:</Typography>
            <div>
                <Pie
                    data={{
                        labels: ["FUs mit JSTB", "FUs ohne JSTB"],
                        datasets: [
                            {
                                label: "# of Votes",
                                data: chartData,
                                backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
                                borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        maintainAspectRatio: false,
                    }}
                    width={600}
                    height={400}
                />
            </div>
        </div>
    );
};
