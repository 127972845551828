/* author: JESCHKE Moritz */

import { IconButton, ListItem, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { createKey } from "./Converter";

interface IProps {
    icon?: React.JSX.Element;
    menuItems: IThreeDotMenuItem[];
}

export interface IThreeDotMenuItem {
    name: string;
    action: () => void;
    linkTo?: string;
}

export const ThreeDotMenu = (props: IProps): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{ float: "right" }}
            >
                {props.icon !== undefined ? props.icon : <MoreVertIcon />}
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {props.menuItems.map((menuItem: IThreeDotMenuItem) => {
                    return (
                        <ListItem key={createKey("threedot", menuItem.name)}>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    menuItem.action();
                                    if (menuItem.linkTo) {
                                        navigate(menuItem.linkTo);
                                    }
                                }}
                            >
                                {menuItem.name}
                            </MenuItem>
                        </ListItem>
                    );
                })}
            </Menu>
        </div>
    );
};
