/* author: JESCHKE Moritz */

import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";
import { clearRefreshToken } from "pages/login/Login";
import { clearAuthToken } from "application/api/HttpRequestHandler";

interface ISetClientId {
    payload: {
        clientId: string;
        clientName: string;
    };
    type: string;
}

interface ISetLogedIn {
    payload: {
        isLogedIn: boolean;
        userRole: string;
    };
    type: string;
}

interface IState {
    isLogedIn: boolean;
    userRole: string;
    clientId: string;
    clientName: string;
}

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        isLogedIn: false,
        userRole: "",
        clientId: "",
        clientName: "",
    },
    reducers: {
        setClientId: (state, action: ISetClientId) => {
            return {
                ...state,
                clientId: action.payload.clientId,
                clientName: action.payload.clientName,
            };
        },
        setUser: (state, action: ISetLogedIn) => {
            return {
                ...state,
                isLogedIn: action.payload.isLogedIn,
                userRole: action.payload.userRole,
            };
        },
        logout: (state) => {
            clearAuthToken();
            clearRefreshToken();
            sessionStorage.clear();

            return {
                ...state,
                isLogedIn: false,
                userRole: "",
                clientId: "",
                clientName: "",
            };
        },
    },
});

export const { setClientId, setUser, logout } = UserSlice.actions;
export const userSliceReducer: Reducer<IState, AnyAction> = UserSlice.reducer;
