import { Button, Card, TextField } from "@mui/material";
import { useState } from "react";

interface IIdSelector {
    setId: React.Dispatch<React.SetStateAction<string>>;
    label: string;
    defaultValue?: string;
}

export const IdSelector = (props: IIdSelector): React.JSX.Element => {
    const [componentId, setComponentId] = useState<string>("");

    return (
        <div hidden={localStorage.getItem("disableTempElements") === "true"}>
            <Card
                sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1em",
                    backgroundImage: "repeating-linear-gradient(45deg, #FFCCCB, #FFCCCB 22px, white 22px, white 44px)",
                }}
            >
                <TextField
                    onChange={(e) => setComponentId(e.target.value)}
                    //value={componentId}
                    id="outlined-required"
                    label={props.label}
                    variant="outlined"
                    sx={{ margin: "1em" }}
                    defaultValue={props.defaultValue}
                />
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => props.setId(componentId)}
                    sx={{ margin: "1em" }}
                >
                    Search
                </Button>
            </Card>
        </div>
    );
};
