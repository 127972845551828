import { useState, useEffect } from "react";
import { Box, Card, Typography } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { Vap } from "../vap/Vap";
import { useAppDispatch } from "application/redux/Hooks";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { AppDispatch } from "application/redux/Store";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, ITableContextMenu, Table } from "components/Table";

interface IProps {
    impId: number;
}

export const Specu = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [tableData, setTableData] = useState([]);

    const IDENTIFIER: TableidentifierType = "specu";

    const [specuId, setSpecuId] = useState<number>();
    const [specuUniqueId, setSpecuUniqueId] = useState<number>();

    const columns: ITableColumn[] = [
        /* { field: "specuId", title: "SPECU_ID", type: "number", width: 100 }, */
        /* { field: "specuUniqueId", title: "TB_SPECU_UNIQUE_ID" }, */
        /* { field: "impId", title: "IMP_ID", type: "number", width: 80 }, */
        /* { field: "acquisitionId", title: "TB_ACQUISITION_ID" }, */
        /* { field: "orgImpId", title: "TB_ORG_IMP_ID" }, */
        /* { field: "orgSpecuId", title: "Laufende Spekunummer Ursprung", type: "number", width: 250 }, */
        {
            field: "taxTime",
            title: "Steuerlicher Anschaffungstag",
            type: "date",
        },
        {
            field: "taxNominal",
            title: "Steuerliches Restnominal",
            type: "numeric",
        },
        {
            field: "taxPrice",
            title: "Steuerlicher Anschaffungspreis",
            type: "numeric",
        },
        {
            field: "taxPriceSettl",
            title: "Anschaffungspreis in Abrechnungswährung",
            type: "numeric",
        },
        { field: "taxPriceSettlCur", title: "Abrechnungswährung" },
        {
            field: "taxExpCost",
            title: "Steuerliche Nebenkosten",
            type: "numeric",
        },
        {
            field: "taxExpCostSettl",
            title: "Steuerliche Nebenkosten in Abrechnungswhg",
            type: "numeric",
        },
        {
            field: "retProcFund",
            title: "thesaurierter Ertrag Fond in EURO",
            type: "numeric",
        },
        {
            field: "interimGain",
            title: "Zwischengewinn EURO",
            type: "numeric",
        },
        { field: "isSpecuValuable", title: "Spekuelement bewertbar" },
        { field: "isSpecuTaxFree", title: "Spekuelement steuerfrei" },
        {
            field: "calcRetProcFundEur",
            title: "berechneter thesaurierter, akkumulierter Ertrag in EUR pro Anteil",
            type: "numeric",
        },
        {
            field: "calcPenaltyTaxAmtEur",
            title: "berechneter akkumulierter strafbesteuerter Mehrbetrag pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "penaltyTaxAmountEur",
            title: "akkumulierter strafbesteuer Mehrbetrag in EUR pro Anteil",
            type: "numeric",
        },
        { field: "calculationFlag", title: "Berechenbarkeit Transaktion" },
        { field: "calcRetProcStatus", title: "Berechenbarkeit thes. Erträge" },
        { field: "calcPenaltyTaxStatus", title: "Berechenbarkeit strafbesteuerung" },
        { field: "accuRetProcStatus", title: "Berechenbarkeit akkum. thes. Ertrag" },
        {
            field: "collRetainedProcEur",
            title: "Gesammelte thesaurierte Erträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "collPenaltyProcEur",
            title: "Gesammelte Mehrbeträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "fiscalYearStart",
            title: "GD331 Geschäftsjahr von",
            type: "date",
        },
        {
            field: "fiscalYearEnd",
            title: "GD332 Geschäftsjahr bis",
            type: "date",
        },
        {
            field: "estProfitFundEur",
            title: "Immobiliengewinn bei Fonds in EUR",
            type: "numeric",
        },
        {
            field: "retProcEstEur",
            title: "ID917 Schätzwert für fehlende ausschüttungsgl. Erträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "retProcOldprofitEur",
            title: "ID919 Akkumulierte ausgeschüttete Altveräusserungsgew. pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "retProcSubstEur",
            title: "ID920 Akkumulierte Substanzausschüttungen pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "retProcAdjEur",
            title: "ID921 Bereinigte akkumulierte ausschüttungsgl. Erträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "intGainPenaltyEur",
            title: "Ersatzwert Zwischengewinn pro Anteil in EUR",
            type: "numeric",
        },
        { field: "nullIndicator", title: "Indikator für Anschaffungskosten" },
        { field: "dataSource", title: "Kennzeichen, ob geliefert oder berechnet" },
        {
            field: "paymentTaxDepositId",
            title: "ID Stückzinsen in EUR",
            type: "numeric",
        },
        {
            field: "paymentTaxDepositEur",
            title: "Stückzahlen in EUR",
            type: "numeric",
        },
        { field: "delayFlag", title: "Kennzeichen Verzögerung der Steuerlichen Abrechnung" },
        {
            field: "accruedInterestEur",
            title: "Stückzinsen bei einem internen Depotübertrag",
            type: "numeric",
        },
        {
            field: "ntrlMergerFundsDate",
            title: "Datum steuerneutraler Fondsfusion",
            type: "date",
        },
        { field: "incomeAdjProc", title: "GD863, Kennzeichen Ertragsausgleichverfahren" },
        {
            field: "diffEnrichmentDate",
            title: "Abweichendes Anreicherungsdatum für ID-Felder",
            type: "date",
        },
        { field: "scrpEnrichmentFlag", title: "Flag ob Anreicherung der Thesaurierungswerte erfolgt" },
        { field: "interimEnrichmentFlag", title: "Flag ob Anreicherung des Zwischengewinns erfolgt" },
        { field: "dbaEnrichmentFlag", title: "Flag ob Anreicherung des DBA-Gewinns erfolgt" },
        { field: "acqTaxEvent", title: "TaxEvent der urspr. Anschaffung" },
        {
            field: "acqValuation",
            title: "Bewertungskurs pro Stück der urspr. Anschaffung",
            type: "numeric",
        },
        { field: "changeUser", title: "ChangeUser" },
        { field: "releaseUser", title: "FreigabeUser" },
        {
            field: "insertTs",
            title: "Zeitpunkt des Einfügens",
            type: "date",
        },
        {
            field: "updateTs",
            title: "Zeitpunkt der Änderung",
            type: "date",
        },
        { field: "id905AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID905" },
        { field: "id909AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID909" },
        { field: "id917AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID917" },
        { field: "id919AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID919" },
        { field: "id920AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID920" },
        { field: "id921AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID921" },
        { field: "gv512AutoEnrichment", title: "Comments: Steuerung der automatischen Anreicherung von GV512" },
        {
            field: "fundsSwitchTaxTime",
            title: "Ursprüngliches Anschaffungsdatum",
            type: "date",
        },
        {
            field: "accAssDistrEur",
            title: "Akkumulierte Substanzausschüttung in Euro",
            type: "numeric",
        },
        { field: "originalIsin", title: "Ursprüngliche ISIN aus steuerneutraler Fondsfusion" },
        { field: "mioFunds", title: "Kennzeichen Millionärsfonds" },
        {
            field: "indiAcqCostsE",
            title: "tatsächliche Anschaffungskosten",
            type: "numeric",
        },
        { field: "indiAcqCostsNkz", title: "Nullkennzeichen tatsächlicher Anschaffungskosten" },
    ];

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    const contextMenu: ITableContextMenu = {
        contextActions: [
            {
                label: "VAP Wertberichtigung",
                action: () => {
                    //@ts-ignore
                    setSpecuId(currentRowData.specuId);
                    //@ts-ignore
                    setSpecuUniqueId(currentRowData.specuUniqueId);
                },
            },
        ],
        setCurrentRowData: setCurrentRowData,
    };

    /* useEffect(() => {
    axios
      .get(
        "http://aps02.tributum.soprasteria.com:21833/webservices/cgresources/specu"
      )
      .then((response) => setTableData(response.data));
  }, []); */

    //call http request onMount
    useEffect(() => {
        async function fetchData() {
            dispatch(setIsLoading({ isLoading: true }));
            const response = await sendGetRequest({ path: "/cgresources/specu", params: { impId: props.impId } });
            setTableData(response.data);
            dispatch(setIsLoading({ isLoading: false }));
        }

        fetchData();
    }, [props.impId, dispatch]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Box sx={{ height: "100%" }}>
            <Card sx={{ height: "50%" }}>
                <Table
                    columnsDefinition={columns}
                    identifier={IDENTIFIER}
                    tableData={tableData}
                    contextMenu={contextMenu}
                />
            </Card>
            {specuUniqueId !== undefined ? (
                <div>
                    <br />
                    <Typography>{"Wertberichtigung für die specuId: " + specuId}</Typography>
                    <Vap params={{ specuUniqueId: specuUniqueId }} />
                </div>
            ) : null}
        </Box>
    );
};
