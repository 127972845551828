import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, Table } from "components/Table";

interface IProps {
    data: never[];
}
export const SecIncVap = (props: IProps) => {
    const columns: ITableColumn[] = [
        { field: "secIncVapId", title: "TB_SEC_INC_VAP_ID" },
        { field: "bidId", title: "TB_BID_ID" },
        { field: "securityId", title: "TB_SECURITY_ID" },
        { field: "name", title: "TB_NAME" },
        { field: "validFrom", title: "TB_VALID_FROM" },
        { field: "validTo", title: "TB_VALID_TO" },
        { field: "amount", title: "TB_AMOUNT" },
    ];

    const IDENTIFIER: TableidentifierType = "secIncVap";

    return <Table columnsDefinition={columns} identifier={IDENTIFIER} tableData={props.data} />;
};
