/* author: JESCHKE Moritz */

import { Typography } from "@mui/material";
import { convert, ConvertType, createKey, getChangedOrOriginalValue } from "./Converter";

export interface IDataViewV2 {
    fields: IDataViewV2DataSet[];
    data: Record<string, string | number | Date>;
}

export interface IDataViewV2DataSet {
    field: string;
    label: string;
    type?: ConvertType;
    spacingAfter?: boolean;
    noColonAfter?: boolean;
}

export const DataViewV2 = (props: IDataViewV2): React.JSX.Element => {
    return (
        <table>
            <tbody>
                {props.fields.map((dataSet: IDataViewV2DataSet) => {
                    return (
                        <tr
                            key={createKey("dataviewV2", dataSet.field)}
                            style={dataSet.spacingAfter ? { verticalAlign: "top", height: "2rem" } : {}}
                        >
                            <td>
                                <Typography sx={{ fontWeight: "bold", fontSize: "0.875rem", marginRight: "1rem" }}>
                                    {dataSet.noColonAfter ? dataSet.label : dataSet.label + ":"}
                                </Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontSize: "0.875rem" }}>
                                    {convert(getChangedOrOriginalValue(props.data, dataSet.field), dataSet.type)}
                                </Typography>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
