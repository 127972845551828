/* author: Aashish Upadhyay  */

import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const NvDataHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const nvDataId: number = Number(params.nvDataId);

    const labels: IHistoryKeys[] = [
        { field: "validFrom", label: "Gültig vom", type: "date" },
        { field: "validTo", label: "Gültig bis", type: "date" },
        { field: "nvNr", label: "NV-Bescheinigungsnummer" },
        { field: "nvType", label: "NV-Typ" },
        { field: "deleteFlag", label: "Löschkennzeichen" },
        { field: "lastNamePerson1", label: "Nachname Person 1" },
        { field: "givenNamePerson1", label: "Vorname Person 1" },
        { field: "birthDatePerson1", label: "Geburtsdatum Person 1", type: "date" },
        { field: "tinPerson1", label: "TIN Person 1" },
        { field: "birthNamePerson1", label: "Geburtsname Person 1" },
        { field: "lastNamePerson2", label: "Nachname Person 2" },
        { field: "givenNamePerson2", label: "Vorname Person 2" },
        { field: "birthDatePerson2", label: "Geburtsdatum Person 2", type: "date" },
        { field: "tinPerson2", label: "TIN Person 2" },
        { field: "birthNamePerson2", label: "Geburtsname Person 2" },
        { field: "taxOffice", label: "NV-Finanzamt" },
        { field: "issueDate", label: "NV-Ausstellungsdatum", type: "date" },
    ];

    return (
        <HistoryView
            title={"Historie Nichtveranlagungsbescheinigung (NV)"}
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/fsaNv/nvDataHistory",
                params: { nvDataId: nvDataId },
            }}
            identifier={"nvDataHistory"}
        />
    );
};
