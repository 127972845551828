import { useEffect, useState } from "react";

import { Box, Button, Card, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import DownloadLink from "react-download-link";

interface IProps {
    fiscalUnitId: string;
    year: string;
    setDialogOpen: any;
    isOpen: boolean;
}

export const FetchJSTBReport = (props: IProps) => {
    const [xml, setXML] = useState("");
    const [msg, setMsg] = useState<string>("");
    const [display, setDisplay] = useState<boolean>(false);
    const [save, setSave] = useState<boolean>(false);

    const handleClose = () => {
        props.setDialogOpen(false);
    };

    useEffect(() => {
        async function getReport() {
            let parser = new DOMParser();
            let reportXml;
            console.log("in Fetch class");
            //TODO :: use variable
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const report = await sendGetRequest({
                path: "/jstbresources/reportFetcher",
                params: { fiscalUnitId: props.fiscalUnitId, year: props.year },
            }).then((response) => {
                reportXml = parser.parseFromString(response.data, "application/xml");
                // document.getElementById("Tributum");
                console.log("check format", reportXml);
                console.log("xml", response.data);
                setXML(response.data);

                //  exportFromJSON(response.data, fileName, fields, exportType);

                if (JSON.stringify(response.data) === JSON.stringify("")) {
                    setMsg("JSTB Report is not Present, Please Enter Fiscal Unit Id, year and click on Generate");
                }
            });
        }

        getReport();
    }, [props.fiscalUnitId, props.year]);

    return (
        <Box>
            <Card>
                <Dialog open={props.isOpen} onClose={handleClose}>
                    <DialogTitle>JSTB Report</DialogTitle>
                    {msg !== "" ? (
                        <div>
                            <br />
                            {msg}
                        </div>
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    setDisplay(true);
                                }}
                            >
                                Display
                            </Button>
                            <Button
                                onClick={() => {
                                    setSave(true);
                                }}
                            >
                                Save
                            </Button>
                        </>
                    )}
                    {/* <Button
                        onClick={() => {
                            setDisplay(true);
                        }}
                    >
                        Display
                    </Button>
                    <Button
                        onClick={() => {
                            setSave(true);
                        }}
                    >
                        Save
                    </Button> */}

                    {display ? (
                        <DialogContentText>
                            {JSON.stringify(xml) !== JSON.stringify("") ? (
                                <div>
                                    <br />
                                    <div>{xml}</div>
                                </div>
                            ) : null}
                            {/* {msg !== "" ? (
                                <div>
                                    <br />
                                    {msg}
                                </div>
                            ) : null} */}
                            <Button onClick={handleClose} autoFocus>
                                Cancel
                            </Button>
                        </DialogContentText>
                    ) : null}
                    {save ? (
                        <DialogActions>
                            {msg === "" ? (
                                //@ts-ignore
                                <DownloadLink
                                    label="Download JSTB Report"
                                    filename="jstb.xml"
                                    exportFile={() => Promise.resolve(xml)}
                                >
                                    {console.log("in downloadlink")};
                                </DownloadLink>
                            ) : null}
                        </DialogActions>
                    ) : null}
                </Dialog>
            </Card>
        </Box>
    );
};
