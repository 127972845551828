/* author: JESCHKE Moritz */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { Location, useLocation, useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";

export const AMOUNT_DETATILS_DATA_IDENTIFIER: string = "amountDetails";

export const AMOUNT_DETATILS: string = "Beträge";

export const IncomeTransactionItemEdit = (): JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const section: string | undefined = params.section;
    const incomeTranItemId: number = Number(params.incomeTranItemId);

    const location: Location = useLocation();
    const incomeTranId: number = location.state.rowData["incomeTranId"];

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: "/cgresources/fttransactions/incomeTranItemById-with-keydata",
            params: { id: incomeTranItemId },
        });

    const amountDetailsEdit: IEditDataViewUserConfiguration = {
        headline: AMOUNT_DETATILS,
        attributes: [
            {
                field: "transactionType",
                headerName: "Art des Betrags",
                dropdown: {
                    keyTableTabname: "INCOME_ITEM_TYPE",
                },
            },
            {
                field: "amount",
                headerName: "Betrag",
            },
            {
                field: "currency",
                headerName: "Währung",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            { field: "rate", headerName: "Devisenkurs" },
            {
                field: "itemSource",
                headerName: "Quelle",
                dropdown: {
                    keyTableTabname: "INCOME_ITEM_SOURCE",
                },
                displayOnly: true,
            },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [amountDetailsEdit];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={incomeTranId}
            title="Erträge Transaktionen"
            section={section}
            parentIdentifier="incomeTranId"
        />
    );
};
