/* author: PRASHANT Pal */

import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn, ITableContextMenu } from "components/Table";
import { useState } from "react";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { INCOME_TAB, SPECIAL_ACC_TYPE, canceledRowColorScheme } from "./TransactionOverview";
import { KeyTableConsts } from "components/KeyTableConsts";

export const getIncomeColumns = (filterAccType?: string): ITableColumn[] => {
    let depotAccounts: ITableColumn[] = [];

    if (filterAccType && filterAccType === SPECIAL_ACC_TYPE) {
        depotAccounts = [
            {
                field: "securityData.secCode",
                title: "ISIN / Kurzname",
                combinedFields: ["securityData.shortName"],
                combinedFieldsSeparator: " / ",
            },
        ];
    }

    return [
        { field: "taxEvent", title: "StGA" },
        { field: "bidData.bidWm", title: "externe BID" },
        ...depotAccounts,
        { field: "accountData.branch", title: "Filialnummer" },
        { field: "accountData.accountNr", title: "Kontonummer" },
        {
            field: "influxDate",
            title: "Zuflussdatum",
            type: "date",
        },
        { field: "docCode", title: "externe Referenz" },
        { field: "nominal", title: "Nominal" },
        { field: "currency", title: "Nominalwährung" },
        { field: "nomCur", title: "Gattungswährung" },
        {
            field: "relDate",
            title: "Ertragsdatum",
            type: "date",
        },
        { field: "transactionDisplayType", title: "Transaktionstyp" },
        { field: "cancellationFlag", title: "StoKZ" },
        { field: "cancellationExtTranKey", title: "Umsatzreferenz stornierter Umsatz" },
        { field: "extTranKey", title: "Umsatzreferenz" },
        { field: "accountData.accountType", title: "Kontoart" },
        { field: "refDelSystem", title: "Liefersystem" },
        { field: "obligingnessType", title: "Kulanzzahlungen" },
        { field: "dataContainer", title: "DataContainer" },
        { field: "custodianLocation", title: "Inlands- oder Auslandsverwährung" },
        { field: "foreignCustodySetType", title: "Belieferung (cum/ex)" },
        { field: "refOriImpId", title: "Umsatzreferenz ursprünglicher Umsatz" },
        { field: "extTranKeyAssigned", title: "Vorgängerreferenz" },
        { field: "extTranKeyRoot", title: "Gruppenreferenz / Ursprungstransaktion" },
        { field: "zivRelevance", title: "ZIV-relevant" },
        { field: "overCounterFlag", title: "Tafelgeschäft" },
        { field: "execState", title: "Ausführungsstatus" },
    ];
};

export const IncomeTranSearch = (): React.JSX.Element => {
    const BRANCH_NUMBER_LENGTH = 4;
    const [currentValues, setCurrentValues] = useState<Record<string, string>>({});

    const searchCriterias: ISearchCriteria[] = [
        { field: "extTranKey", label: "Umsatzreferenz*" },

        { field: "isin", label: "ISIN*" },
        { field: "wkn", label: "WKN*" },
        { field: "branch", label: "Filialnummer" },
        {
            field: "accountNr",
            label: "Kontonummer*",
            disabled: currentValues["branch"] === undefined || currentValues["branch"].length !== BRANCH_NUMBER_LENGTH,
        },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
        },

        {
            field: "",
            label: "Ausführungszeitpunkt",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "validFrom", discriminator: "from" },
                    to: { label: "bis", field: "validTo", discriminator: "to" },
                },
            },
        },
        {
            field: "taxEventFrom",
            label: "Steuerliche Geschäftsart von",
            type: "dropDown",
            comboBoxLowestFieldName: "taxEventTo",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TFT },
        },
        {
            field: "taxEventTo",
            label: "Steuerliche Geschäftsart bis",
            type: "dropDown",
            comboBoxHighestFieldName: "taxEventFrom",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TFT },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/fttransactions/search" }];
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <div>
            <Search
                title={"Umsätze Erträge"}
                searchCriterias={searchCriterias}
                columns={getIncomeColumns(SPECIAL_ACC_TYPE)}
                searchUrls={searchUrls}
                identifier={"incomeTranSearch"}
                setCurrentValues={setCurrentValues}
                rowColorScheme={canceledRowColorScheme}
                isExportable={true}
                contextMenu={{
                    element: (
                        <TransactionContextMenu
                            contextMenus={contextMenus}
                            setContextmenus={setContextMenus}
                            currentTabText={INCOME_TAB}
                        />
                    ),
                    tableElement: contextMenus[INCOME_TAB],
                }}
            />
        </div>
    );
};
