/* author:Sindhu Kannan */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, Table } from "components/Table";
import { useEffect, useState } from "react";
interface IErrorDetails {
    impId: number;
    origin: "cgt" | "ftt";
    // resizeTableHeight: () => void;
}
export const ErrorDetails = (props: IErrorDetails): React.JSX.Element => {
    const appBar: HTMLElement = document.getElementById("appBar") as HTMLElement;

    const columns: ITableColumn[] = [
        { field: "errorText", title: "Fehlertext", width: appBar && appBar.style.width === "100%" ? "75vw" : "60vw" },
        { field: "module", title: "Tributum Modul" },
    ];

    const [errorData, setErrorData] = useState<Record<string, string>[]>([]);
    const IDENTIFIER: TableidentifierType = "errorDetails";

    useEffect(() => {
        const path: "/cgresources/cgtransactions/errorDetails" | "/cgresources/fttransactions/errorDetails" =
            props.origin === "cgt"
                ? "/cgresources/cgtransactions/errorDetails"
                : "/cgresources/fttransactions/errorDetails";
        sendGetRequest({
            path: path,
            params: { impId: props.impId },
        }).then((response) => {
            setErrorData(response.data);
        });
    }, [props.impId, props.origin]);

    return (
        <div style={{ margin: "0rem 4rem" }} hidden={props.impId === -1}>
            <Table
                columnsDefinition={columns}
                tableData={errorData}
                identifier={IDENTIFIER}
                disableHideColumn={true}
                customColumnWidth={true}
            />
        </div>
    );
};
