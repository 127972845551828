/* author: JESCHKE Moritz */

import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";

interface ISetYer {
    payload: {
        fiscalUnit: string;
        execState: string;
        from: Date;
        to: Date;
    };
    type: string;
}

interface ISetData {
    payload: {
        data: [];
    };
    type: string;
}

interface IState {
    fiscalUnit: string;
    execState: string;
    from: Date;
    to: Date;
    yerData: never[];
}

export const YerSlice = createSlice({
    name: "yer",
    initialState: {
        fiscalUnit: "",
        execState: "E",
        from: new Date(),
        to: new Date(),
        yerData: [],
    },
    reducers: {
        setYer: (state, action: ISetYer) => {
            return {
                ...state,
                fiscalUnit: action.payload.fiscalUnit,
                execState: action.payload.execState,
                from: action.payload.from,
                to: action.payload.to,
            };
        },
        setYerData: (state, action: ISetData) => {
            return {
                ...state,
                yerData: action.payload.data,
            };
        },
    },
});

export const { setYer, setYerData } = YerSlice.actions;
export const yerSliceReducer: Reducer<IState, AnyAction> = YerSlice.reducer;
