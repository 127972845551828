/* author: JESCHKE Moritz */

import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import {
    setTransactionData,
    setTransactionDialogData,
    setIncomeData,
    transactionClearData,
    setTransactionFuNr,
} from "application/redux/slices/TransactionSlice";
import { AppDispatch, RootState } from "application/redux/Store";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material";
import { useAppDispatch } from "application/redux/Hooks";
import { useSelector } from "react-redux";
import { FromToDatePicker } from "components/FromToDatePicker";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { AutoCompleteInput } from "components/AutoCompleteInput";
import { ComboBox } from "components/ComboBox";
import { InputField } from "components/InputField";

interface IProps {
    isOpen: boolean;
    setDialogOpen: any;
    accountNr: string;
    accountId: string;
    wkn: string;
    from: Date;
    to: Date;
    branch: string;
    type: string;
}

export const TransactionDialog = (props: IProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch: AppDispatch = useAppDispatch();
    const [from, setFrom] = useState<Date | null>(props.from);
    const [to, setTo] = useState<Date | null>(props.to);
    const [dateError, setDateError] = useState<boolean>(false);
    const [accountNr, setAccountNr] = useState<string>(props.accountNr);

    const [accountId, setAccountId] = useState<string>(props.accountId);
    const [wkn, setWKN] = useState<string>(props.wkn);
    const [branch, setBranch] = useState<string>(props.branch);
    const [type, setType] = useState<string>(props.type);
    const client = useSelector((state: RootState) => state.user.clientId);

    const [accountNrOptions, setAccountNrOptions] = useState([]);
    const AUTOCOMPLETE_MIN_LETTERS = 4;

    const handleClose = () => {
        props.setDialogOpen(false);
    };

    const getData = async () => {
        dispatch(
            setTransactionDialogData({
                accountNr: accountNr,
                accountId: accountId,
                wkn: wkn,
                from: from,
                to: to,
                branch: branch,
                type: type,
            })
        );
        //close dialog
        props.setDialogOpen(false);
        //Send HTTP Request and stores data in table
        const data = await sendGetRequest({
            path: "/cgresources/cgtransactions",
            params: {
                accountId: accountId,
                wkn: wkn,
                from: from !== null ? from!.toISOString().slice(0, 11) + "00:00:00" : "",
                to: to !== null ? to!.toISOString().slice(0, 11) + "23:59:59" : "",
            },
        });

        //store it global
        dispatch(setTransactionData({ data: data.data }));
    };

    const getIncomeData = async () => {
        //Send HTTP Request and stores data in table
        const incomeData = await sendGetRequest({
            path: "/cgresources/fttransactions",
            params: {
                accountId: accountId,
                from: from !== null ? from!.toISOString().slice(0, 11) + "00:00:00" : "",
                to: to !== null ? to!.toISOString().slice(0, 11) + "23:59:59" : "",
            },
        });

        //store it global
        dispatch(setIncomeData({ data: incomeData.data }));
    };

    const getFuFromAccNr = async () => {
        const request = await sendGetRequest({
            path: "/customerstaticdata/fiscalUnit/getFiscalUnitByAccountId",
            params: { accountId: accountId },
        });
        const fu: string = request.data.fiscalUnit;
        const fuId: string = request.data.fiscalUnitId;
        dispatch(setTransactionFuNr({ fuNr: fu, fiscalUnitId: fuId }));
        dispatch(setIsLoading({ isLoading: false }));
    };

    const handkleOkPress = () => {
        //ok is only clickable if accountnummer exists
        if (accountNr !== "") {
            dispatch(setIsLoading({ isLoading: true }));
            dispatch(transactionClearData());
            getData();
            getIncomeData();
            getFuFromAccNr();
        } else {
            alert("Account Nummer darf nicht leer sein.");
        }
    };

    /**
     * Call function if special key is pressed
     * @param event KeyPressEvent
     */
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" && !buttonDisableCheck()) {
            handkleOkPress();
        }
    };

    /**
     * Checks if the button is disabled
     * @returns true | false
     */
    const buttonDisableCheck = (): boolean => {
        return dateError || accountId === "";
    };

    return (
        <Dialog fullScreen={fullScreen} open={props.isOpen} onKeyDown={handleKeyDown}>
            <DialogTitle>Umsatzsuche</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", marginTop: "1em" }}>
                    <InputField
                        value={branch}
                        setValue={(event) => {
                            setBranch(event.target.value);
                            setAccountNr("");
                            setAccountNrOptions([]);
                        }}
                        label="Filiale"
                    />

                    <ComboBox
                        label="Kontoart"
                        onChange={(event: SelectChangeEvent<string>) => {
                            setType(event.target.value);
                            setAccountNr("");
                            setAccountNrOptions([]);
                        }}
                        optionIdentifier="key"
                        optionValue={["value"]}
                        keyTable={{ tabname: "ACCTYPE" }}
                        value={type}
                        width="50%"
                    />
                </div>
                {branch.length !== 4 || isNaN(Number(branch)) ? (
                    <div style={{ color: "red" }}>Branch muss aus 4 Ziffern bestehen.</div>
                ) : null}

                <AutoCompleteInput
                    autocompleteMinLetters={AUTOCOMPLETE_MIN_LETTERS}
                    disabledCheck={branch.length !== 4 || isNaN(Number(branch))}
                    label="Kontonummer"
                    options={accountNrOptions}
                    optionsRequest={(currentValue: string) =>
                        sendGetRequest({
                            path: "/autocomplete/getAll/AccountNr",
                            params: { accountNr: currentValue, branch: branch, type: type, clientId: client },
                        })
                    }
                    setOptions={setAccountNrOptions}
                    setId={setAccountId}
                    setValue={setAccountNr}
                    value={accountNr}
                />
                <InputField value={wkn} setValue={(event) => setWKN(event.target.value)} label="WKN" />

                <FromToDatePicker from={from} setFrom={setFrom} to={to} setTo={setTo} setDateError={setDateError} />
            </DialogContent>
            <DialogActions>
                <Button disabled={buttonDisableCheck()} onClick={handkleOkPress}>
                    Ok
                </Button>
                <Button onClick={handleClose}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};
