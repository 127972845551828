import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, Tab } from "@mui/material";
import { useAppDispatch } from "application/redux/Hooks";
import { setCurrentIncomeTabId } from "application/redux/slices/TransactionSlice";
import { AppDispatch, RootState } from "application/redux/Store";
import { SyntheticEvent, useState } from "react";
import { useSelector } from "react-redux";
import { Amounts } from "./amounts/Amounts";
import { TaxBase } from "../taxengine/bmg/bmg-base/TaxBase";
import { TaxAccounting } from "../taxengine/bmg/bmg-individual/TaxAccounting";
import { SecIncome } from "../security-data/sec-income/SecIncome";

interface IProps {
    bidId: number;
    incomeTranId: number;
}

//structur of a tab
interface ITab {
    tabLabel: string;
    content: (params: IProps) => JSX.Element;
}

export const TftTransactionSingle = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    /* ### VARIABLES & STATES ### */

    //stores all tabs (Label for Tabmenu and the subpage)
    const [tabs] = useState<ITab[]>([
        {
            tabLabel: "Details zum Event",
            content: (params: IProps): JSX.Element => <SecIncome bidId={params.bidId} />,
        },
        // { tabLabel: "Sachliche Bemessungsgrundlage", content: (): JSX.Element => <FactualBMG impId={props.impId} /> },
        {
            tabLabel: "Sachliche Bemessungsgrundlage",
            content: (params: IProps): JSX.Element => <TaxBase impId={params.incomeTranId} system="F" />,
        },
        {
            tabLabel: "Persönliche Bemessungsgrundlage",
            content: (params: IProps): JSX.Element => (
                <TaxAccounting params={{ impId: params.incomeTranId, system: "F" }} />
            ),
        },
        {
            tabLabel: "Beträge",
            content: (): JSX.Element => <Amounts incomeTranId={props.incomeTranId} />,
        },
    ]);

    //stores the current selected tabId
    const currentTabId: number = useSelector(
        (state: RootState) => state.transaction.incomePages[state.transaction.selectedIndex].currentTabId
    );

    /* ### FUNCTIONS ### */

    /**
     * change the currentTab -> switch between them
     * @param _event is unused but necessary to pass second parameter by default
     * @param newTabId Id of the tab, which should be displayed
     */
    const handleChange = (_event: SyntheticEvent<Element, Event>, newTabId: number) => {
        dispatch(setCurrentIncomeTabId({ incomeTranId: props.incomeTranId, newTabId: newTabId }));
    };

    /* ### HTML ### */

    return (
        <Box sx={{ margin: 3, width: "100vp", height: "90%" }}>
            <Card key={1} sx={{ height: "100%" }}>
                <TabContext value={currentTabId.toString()}>
                    {/* Generate all tabs */}
                    <TabList onChange={handleChange}>
                        {tabs.map((tab: ITab, index: number): JSX.Element => {
                            return <Tab label={tab.tabLabel} value={index.toString()} key={index} />;
                        })}
                    </TabList>

                    {/* Generate the contentpanels for the tabs */}
                    {tabs.map((tab: ITab, index: number): JSX.Element => {
                        return (
                            <TabPanel sx={{ height: "90%" }} value={index.toString()} key={index}>
                                {tab.content({
                                    bidId: props.bidId,
                                    incomeTranId: props.incomeTranId,
                                })}
                            </TabPanel>
                        );
                    })}
                </TabContext>
            </Card>
        </Box>
    );
};
