import { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { useAppDispatch } from "application/redux/Hooks";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { AppDispatch } from "application/redux/Store";
import { ITableColumn, Table } from "components/Table";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";

interface IProps {
    params: {
        impId?: number | undefined;
        adjustImpId?: number | undefined;
        specuUniqueId?: number | undefined;
    };
}

export const columns: ITableColumn[] = [
    /* { field: "vapId", headerName: "VAP_ID", type: "number", width: 80 }, */
    /* { field: "vapUniqueId", headerName: "VAP_UNIQUE_ID", type: "number", width: 140 }, */
    /* {
            field: "date",
            headerName: "TB_DATE",
            valueGetter: customDateValueGetter,
            sortComparator: customDateComparator,
        }, */
    /* { field: "bidId", headerName: "BID_ID", type: "number", width: 80 }, */
    /* { field: "impId", headerName: "IMP_ID", type: "number", width: 80 }, */
    /* { field: "origVapImpId", headerName: "TB_ORIG_VAP_IMP_ID" },
        { field: "specuUniqueId", headerName: "TB_SPECU_UNIQUE_ID" },
        { field: "valueAdjUniqueId", headerName: "TB_VALUE_ADJ_UNIQUE_ID" },
        { field: "adjustImpId", headerName: "TB_ADJUST_IMP_ID" },
        { field: "adjustSpecuUniqueId", headerName: "TB_ADJUST_SPECU_UNIQUE_ID" }, */
    {
        field: "amountEur",
        title: "Gegenwert Euro",
        type: "numeric",
    },
    {
        field: "aTfrAmountEur",
        title: "Gegenwert Euro nach TFS",
        type: "numeric",
    },
    {
        field: "nominal",
        title: "Nominal",
        type: "numeric",
    },
    {
        field: "refNominal",
        title: "referenziertes Nominal",
        type: "numeric",
    },
    {
        field: "holdPeriodDate",
        title: "Relevantes Datum zur Haltedauerberechnung VAP",
        type: "date",
    },
    { field: "vapKz", title: "VAP_Kennzeichen" },
    {
        field: "insertTs",
        title: "INSERT_TimeStamp",
        type: "date",
    },
    {
        field: "updateTs",
        title: "Update TimeStamp",
        type: "date",
    },
    { field: "changeUser", title: "ÄnderungsUser" },
    { field: "releaseUser", title: "FreigabeUser" },
];

export const Vap = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [tableData, setTableData] = useState([]);
    const IDENTIFIER: TableidentifierType = "vap";

    //call http request onMount
    useEffect(() => {
        async function fetchData() {
            dispatch(setIsLoading({ isLoading: true }));
            const response = await sendGetRequest({ path: "/cgresources/vap", params: props.params });
            setTableData(response.data);
            dispatch(setIsLoading({ isLoading: false }));
        }

        fetchData();
    }, [props.params, dispatch]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Box sx={{ height: "100%" }}>
            <Card sx={{ height: "50%" }}>
                <Table columnsDefinition={columns} identifier={IDENTIFIER} tableData={tableData} />
            </Card>
        </Box>
    );
};
