/* For New Client  */
import { useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Detailview, IDetailView } from "components/Detailview";
import { NavigateFunction, useNavigate } from "react-router";
import { IOverviewHeadLine } from "components/OverviewHeadLine";

interface IAccountDetails {
    accountId: number;
    icon: React.JSX.Element | null;
    functionTextDisable?: boolean;
}

export const AccountDetails = (props: IAccountDetails): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const [data, setData] = useState<Record<string, string>>({});
    const [fiscalUnit, setFiscalUnit] = useState<{ [key: string]: string }>({});
    const [accountId] = useState<number>(props.accountId);
    //function text disabled
    const detailDataHeadLine: IOverviewHeadLine = {
        title: "Konto zu " + fiscalUnit.fiscalUnit,
        useTimeSliceButtonForHistory: true,
        buttons: [
            {
                title: "Umsätze",
                filled: false,
                onClick: () => navigate("/TransactionOverview/" + accountId),
            },
        ],
        editButton: { linkTo: "/TransactionOverview/" + accountId + "/edit" },
    };
    //function text enabled (update detailDataHeadLine)
    if (!props.functionTextDisable) {
        detailDataHeadLine.fuctionText = {
            label: fiscalUnit.fiscalUnit,
            onTextClick: () => navigate("/overviewFu/" + fiscalUnit.fiscalUnitId),
        };
        detailDataHeadLine.onTimeStampClick = () => navigate("/accountHistory/" + accountId);
        detailDataHeadLine.title = "Konto zu";
    }

    const account: IDetailView = {
        /*technical data*/

        detailData: {
            headline: detailDataHeadLine,
            cards: [
                {
                    icon: props.icon,
                    dataKeys: [
                        [
                            { field: "branch", label: "Filialnummer" },
                            { field: "accountNr", label: "Kontonummer" },
                            { field: "accountType", label: "Kontotyp" },
                            { field: "fiscalUnit2AccountData.identifyingFeature", label: "Kontenzusatz" },
                            { field: "name", label: "Kontoname" },
                            { field: "country", label: "Land des Kontos" },
                            { field: "currency", label: "Kontowährung" },
                            { field: "iban", label: "IBAN" },
                        ],
                    ],
                    techKeys: [
                        [
                            { field: "execState", label: "Ausführungsstatus" },
                            { field: "changeUser", label: "Geändert von" },
                            { field: "releaseUser", label: "Freigegeben von" },
                            { field: "updateTS", label: "Aktualisierungsdatum", type: "date" },
                            { field: "insertTS", label: "Einfügedatum", type: "date" },
                        ],
                    ],
                    data: data,
                },
            ],
        },
    };

    useEffect(() => {
        sendGetRequest({
            path: "/customerstaticdata/account/getAccount",
            params: { accountId: props.accountId },
        }).then((response) => {
            setData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/fiscalUnit/getFiscalUnitByAccountId",
            params: { accountId: String(props.accountId) },
        }).then((response) => {
            setFiscalUnit(response.data);
        });
    }, []);

    return <Detailview detailData={account.detailData} />;
};
