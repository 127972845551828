/* author: JESCHKE Moritz */

import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { IMenuBarItemList, tbd } from "./MenuBarItemList";
import { ResizeOverflowTooltip } from "components/ResizeOverflowTooltip";
import { createKey } from "components/Converter";
import { useSelector } from "react-redux";
import { RootState } from "application/redux/Store";
import { Colors } from "components/Colors";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { USER_ROLES } from "pages/login/Login";

interface IMenuItem extends IMenuBarItemList {
    menuItems: IMenuItemData[];
}

export interface IMenuItemData extends ISubmenuItemData {
    icon: React.JSX.Element;
}

export interface ISubmenuItemData {
    name: string;
    linkTo?: string;
    subMenuItem?: ISubmenuItemData[];
    passOrigin?: boolean;
    id: number;
    allowedFor?: USER_ROLES[];
}

export const MenuItem = (props: IMenuItem): React.JSX.Element => {
    const userRole = useSelector((state: RootState) => state.user.userRole);
    const navigate: NavigateFunction = useNavigate();
    const SUBMENU_INSERT: number = 4;

    const [currentSelectedMenuId, setCurrentSelectedMenuId] = useState<number>(-1);
    const [currentSelectedSubMenuId, setCurrentSelectedSubMenuId] = useState<number>(-1);

    const handleMenuItemClick = (
        menuItem: ISubmenuItemData | IMenuItemData,
        passOrigin?: boolean,
        parentId?: number
    ) => {
        const set: globalThis.Set<string> = new Set(props.currentOpenMenus);
        if (set.has(menuItem.name)) {
            //remove already existing one
            set.delete(menuItem.name);
        } else {
            //add non existing one
            set.add(menuItem.name);
        }
        props.setCurrentOpenMenus(Array.from(set));

        if (!menuItem.subMenuItem) {
            if (parentId) {
                setCurrentSelectedSubMenuId(menuItem.id);
                setCurrentSelectedMenuId(parentId);
            } else {
                setCurrentSelectedMenuId(menuItem.id);
            }
        }

        if (menuItem.linkTo && menuItem.linkTo !== tbd) {
            if (passOrigin) {
                navigate(menuItem.linkTo, { state: { origin: menuItem.name } });
            } else {
                navigate(menuItem.linkTo);
            }
        }
    };

    const expandAndCollapseButtons = (
        subMenuItem: ISubmenuItemData | IMenuItemData,
        currentOpenMenus: string[]
    ): React.JSX.Element | null => {
        if (!subMenuItem.subMenuItem) {
            return null;
        }

        return currentOpenMenus.includes(subMenuItem.name) ? (
            <div style={{ color: Colors.blue }}>
                <ExpandLess />
            </div>
        ) : (
            <div style={{ color: Colors.blue }}>
                <ExpandMore />
            </div>
        );
    };

    const createSubMenuItem = (
        subMenu: ISubmenuItemData[],
        pl: number,
        parentId: number,
        passOrigin: boolean | undefined
    ) => {
        return (
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                    {subMenu.map((subMenuItem: ISubmenuItemData, i: number) => {
                        if (subMenuItem.allowedFor && !subMenuItem.allowedFor.includes(userRole)) return;

                        return (
                            <div key={createKey("menuBarItemList", subMenuItem.name + i)}>
                                <ListItemButton
                                    sx={{ pl: pl }}
                                    onClick={() =>
                                        handleMenuItemClick(subMenuItem, passOrigin ?? subMenuItem.passOrigin, parentId)
                                    }
                                    disabled={subMenuItem.linkTo === tbd && !subMenuItem.subMenuItem}
                                >
                                    <div
                                        style={{
                                            marginRight: "0.375rem",
                                            color:
                                                parentId === currentSelectedMenuId &&
                                                subMenuItem.id === currentSelectedSubMenuId &&
                                                !subMenuItem.subMenuItem
                                                    ? Colors.blue
                                                    : "transparent",
                                        }}
                                    >
                                        •
                                    </div>
                                    <ResizeOverflowTooltip
                                        tooltipText={subMenuItem.name}
                                        element={(ref: React.MutableRefObject<undefined>) => (
                                            <ListItemText
                                                ref={ref}
                                                primary={subMenuItem.name}
                                                sx={{
                                                    color:
                                                        parentId === currentSelectedMenuId &&
                                                        subMenuItem.id === currentSelectedSubMenuId
                                                            ? Colors.blue
                                                            : Colors.borderDark,
                                                    "& .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary":
                                                        {
                                                            fontSize: "0.875rem",
                                                            //fix overflow
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        },
                                                }}
                                            />
                                        )}
                                    />
                                    {expandAndCollapseButtons(subMenuItem, props.currentOpenMenus)}
                                </ListItemButton>
                                {props.currentOpenMenus.includes(subMenuItem.name) &&
                                    subMenuItem.subMenuItem &&
                                    createSubMenuItem(subMenuItem.subMenuItem, pl * 2, parentId, passOrigin)}
                            </div>
                        );
                    })}
                </List>
            </Collapse>
        );
    };

    return (
        <List sx={{ width: "100%" }} component="nav">
            {props.menuItems.map((menuItem: IMenuItemData) => {
                if (menuItem.allowedFor && !menuItem.allowedFor.includes(userRole)) return;

                return (
                    <div
                        key={createKey("menuBarItemList", menuItem.name)}
                        style={{
                            background: menuItem.id === currentSelectedMenuId ? Colors.lightBlue : "initial",
                        }}
                    >
                        <ListItemButton
                            disabled={menuItem.linkTo === tbd && !menuItem.subMenuItem}
                            onClick={() => handleMenuItemClick(menuItem, menuItem.passOrigin)}
                        >
                            <ListItemIcon sx={{ color: Colors.blue }}>{menuItem.icon}</ListItemIcon>
                            <ResizeOverflowTooltip
                                tooltipText={menuItem.name}
                                element={(ref: React.MutableRefObject<undefined>) => (
                                    <ListItemText
                                        ref={ref}
                                        primary={menuItem.name}
                                        sx={{
                                            color:
                                                menuItem.id === currentSelectedMenuId ? Colors.blue : Colors.borderDark,
                                            //fix overflow
                                            "& .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary": {
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            },
                                        }}
                                    />
                                )}
                            />
                            {expandAndCollapseButtons(menuItem, props.currentOpenMenus)}
                        </ListItemButton>
                        {props.currentOpenMenus.includes(menuItem.name) &&
                            menuItem.subMenuItem &&
                            createSubMenuItem(menuItem.subMenuItem, SUBMENU_INSERT, menuItem.id, menuItem.passOrigin)}
                    </div>
                );
            })}
        </List>
    );
};
