/* author: JESCHKE Moritz */

import { useState } from "react";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn, ITableContextMenu } from "components/Table";
import { BaseBmgDetails } from "pages/transaction-data/BaseBmgDetails";
import { TaxResultDetails } from "pages/transaction-data/TaxResultDetails";

const MainTaxAtFlatRate =
    (titleExtention: string, searchPath: string, identifier: TableidentifierType, columns: ITableColumn[]) =>
    // eslint-disable-next-line react/display-name
    (): React.JSX.Element => {
        const searchCriteria: ISearchCriteria[] = [
            {
                field: "",
                label: "Zeitraum",
                type: {
                    TBFromToDatePicker: {
                        prefixParentLabel: true,
                        from: { label: "von", field: "from", discriminator: "from" },
                        to: { label: "bis", field: "to", discriminator: "to" },
                    },
                },
            },
        ];

        const searchUrls: ISearchUrl[] = [{ path: searchPath }];

        const [personalBmgOpen, setPersonalBmgOpen] = useState<boolean>(false);
        const [taxResultOpen, setTaxResultOpen] = useState<boolean>(false);
        const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});
        const contextMenu: ITableContextMenu = {
            contextActions: [
                { label: "Sachliche BMG", action: () => setPersonalBmgOpen(true) },
                { label: "Steuerliche Ergebnisse", action: () => setTaxResultOpen(true) },
            ],
            setCurrentRowData: setCurrentRowData,
        };

        return (
            <div>
                <Search
                    searchCriterias={searchCriteria}
                    columns={columns}
                    searchUrls={searchUrls}
                    identifier={identifier}
                    title={"Suche Pauschal besteuerte " + titleExtention + " Umsätze"}
                    contextMenu={contextMenu}
                    customSearchButtonDisable={(currentSearchCriteria: Record<string, string>) => {
                        return (
                            currentSearchCriteria["from"] !== undefined &&
                            currentSearchCriteria["from"] !== "" &&
                            currentSearchCriteria["to"] !== undefined &&
                            currentSearchCriteria["to"] !== ""
                        );
                    }}
                />
                {personalBmgOpen && (
                    <BaseBmgDetails
                        extTranKey={String(currentRowData.transactionKeyMandant)}
                        //TODO :: check if switch to number is possible
                        impId={String(currentRowData.impId)}
                        system={"C"}
                        setOpen={setPersonalBmgOpen}
                    />
                )}
                {taxResultOpen && (
                    <TaxResultDetails
                        extTranKey={String(currentRowData.transactionKeyMandant)}
                        accountNr={"Kontonummer: " + currentRowData["accountData.accountNr"]}
                        impId={Number(currentRowData.impId)}
                        system={"C"}
                        setOpen={setTaxResultOpen}
                    />
                )}
            </div>
        );
    };

export const SecurityTaxAtFlatRate = MainTaxAtFlatRate(
    "Wertpapier",
    "/security/securities/searchTaxAtFlatRate",
    "searchTaxAtFlatRateSecurity",
    [
        { field: "securityData.secCode", title: "WKN/ISIN" },
        { field: "securityData.shortName", title: "Kurzbezeichnung" },
        { field: "externBusinessReference", title: "Transaktionsreferenz" },
        { field: "transactionKeyMandant", title: "Umsatzreferenz" },
        { field: "tradeEvent", title: "Geschäftsart" },
        { field: "taxEventGroup", title: "Vorgangsgr.-Schlüssel" },
        { field: "taxEvent", title: "StGA" },
        { field: "nominal", title: "Umsatz" },
        { field: "referenceNominal", title: "Referenz-nominal" },
        { field: "nominalCurrency", title: "Stk/Whrg" },
        { field: "tradeMarketValue", title: "Kurswert Handels Whrg" },
        { field: "tradeCurrency", title: "Handels Whrg" },
        { field: "tradeaddExpenseCosts", title: "NK Abr.-Whrg" },
        { field: "tradeaddExpenseCosts", title: "DevKurs Handels Whrg" },
        { field: "settlementCurrency", title: "Abr.-Whrg" },
        { field: "settlementExchangerate", title: "DevKurs Abr.-Whrg" },
        { field: "settlementMarketValue", title: "Kurswert Abr.-Whrg" },
        { field: "settlementaddExpenseCosts", title: "Anschaff. NK Abr.-Whrg" },
        { field: "marketValueEur", title: "Gegenwert EUR" },
        { field: "fractionPaymentTrade", title: "Spitzenausgleich in Handels Whrg" },
        { field: "fractionPaymentEUR", title: "Spitzenausgleich in EUR" },
        { field: "fractionPaymentSafeGuard", title: "Spitzenausgleich in Abr.-Whrg" },
        { field: "retainedProceedsFund", title: "ID905 Thes. Ertr. Handels Whrg" },
        { field: "retainedSettlProceedsFund", title: "ID905 Thes. Ertr. Abr.-Whrg" },
        { field: "retainedEURProceedsFund", title: "ID905 Thes. Ertr. EUR" },
        { field: "estateProfitFund", title: "Immobiliengewinn Handels Whrg" },
        { field: "estateSettlProfitFund", title: "Immobiliengewinn Abr.-Whrg" },
        { field: "estateEURProfitFund", title: "Immobiliengewinn EUR" },
        { field: "refDelSystem", title: "Liefersystem" },
        {
            field: "executeTime",
            title: "Ausführ.-Datum",
            type: "date",
        },
        {
            field: "valutaDate",
            title: "Valuta",
            type: "date",
        },
        {
            field: "taxTime",
            title: "Steuer-Datum",
            type: "date",
        },
        {
            field: "bookingDate",
            title: "Buchungs-Datum",
            type: "date",
        },
        {
            field: "instructionDate",
            title: "Weisungs-Datum",
            type: "date",
        },
        {
            field: "settlementValueDate",
            title: "Stückevaluta-Datum",
            type: "date",
        },
        { field: "reinvestValue", title: "Wiederanlagewert Handels Whrg" },
        { field: "subscriptPrice", title: "Bezugspreis EUR" },
        { field: "subscriptRightValue", title: "BZR/OS Handels Whrg" },
        { field: "detachRatioNumerator", title: "Trennverh. Zähler" },
        { field: "detachRatioDenominator", title: "Trennverh. Nenner" },
        { field: "subscriptRightRatioNumerator", title: "Bezugsverh. Zähler" },
        { field: "subscriptRightRatioDenominator", title: "Bezugsverh. Nenner" },
        { field: "switchingRatioNumerator", title: "Umtauschverh. Zähler" },
        { field: "switchingRatioDenominator", title: "Umtauschverh. Nenner" },
        { field: "valueRatioNumerator", title: "Tilgungsfaktor alt" },
        { field: "valueRatioDenominator", title: "Tilgungsfaktor neu" },
        {
            field: "detachDate",
            title: "Trenntermin",
            type: "date",
        },
        {
            field: "exDate",
            title: "Extag",
            type: "date",
        },
        { field: "valuation", title: "Bewertung am Bewertungstag in EUR" },
        {
            field: "valuationDate",
            title: "Bewertungs-Datum",
            type: "date",
        },
        {
            field: "acquiredValuationDay",
            title: "Tag des ermittelten Wertpapierkurses",
            type: "date",
        },
        { field: "settlementValuation", title: "Bewertung am Bewertungstag in Abr.-Whrg" },
        { field: "cashCompensation", title: "Barausgleich Abr.-Whrg" },
        { field: "marketValueOldShare", title: "Kurs Altaktie" },
        {
            field: "marketValueDateOldShare",
            title: "Datum Kurs Altaktien",
            type: "date",
        },
        { field: "equivalentCashDividend", title: "Gegenwert Bardividende EUR" },
        { field: "execState", title: "Ausführungsstatus" },
        { field: "settlementCapitalIncome", title: "Einkünfte aus KAP" },
        { field: "interimGain", title: "Zwischengewinn Handels Whrg" },
        { field: "interimGainSettl", title: "Zwischengewinn Abr.-Whrg" },
        { field: "interimGainExchRate", title: "Zwischengewinn EUR" },
        { field: "nonCashBenefitS", title: "Geldwerter Vorteil Abr.-Whrg" },
        { field: "nonCashBenefitTrade", title: "Geldwerter Vorteil Handels Whrg" },
        { field: "nonCashBenefitEUR", title: "Geldwerter Vorteil EUR" },
        { field: "accruedInterestTrade", title: "Stückzinsen Abr.-Whrg" },
        { field: "accruedInterestSettl", title: "Stückzinsen Handels Whrg" },
        { field: "accruedInterestEUR", title: "Stückzinsen EUR" },
        { field: "rightValueExchangeRate", title: "DevKurs Whrg BZR/OS" },
        { field: "rightValueCurrency", title: "Whrg BZR/OS" },
        { field: "bidExtern", title: "externe BID" },
        { field: "manualCheckRequired", title: "Man. Prüf." },
        { field: "retProcEstEur", title: "ID917 Schätzwert fehlender aussch. gl. Erträge" },
        { field: "retProcOldProfitEur", title: "ID919\nAkk. ausgesch. Altveräußerungs-gewinne" },
        { field: "retProcSubstEur", title: "ID920\nAkk. Substanz-ausschüttung" },
        { field: "retProcAdjEur", title: "ID921 Bereinigte akkumulierte aussch. gl. Erträge" },
        { field: "nullIndicator", title: "Null-Indikator" },
        {
            field: "neutralMergerFundsDate",
            title: "Datum steuerneutrale Fondsfusion",
            type: "date",
        },
        {
            field: "diffEnrichmentFundsDate",
            title: "Abweichendes Anreicherungsdatum für ID-Felder",
            type: "date",
        },
        { field: "id905AutoEnrichment", title: "ID905\nKZ auto. Anreicherung" },
        { field: "id909AutoEnrichment", title: "ID909\nKZ auto. Anreicherung" },
        { field: "id917AutoEnrichment", title: "ID917\nKZ auto. Anreicherung" },
        { field: "id919AutoEnrichment", title: "ID919\nKZ auto. Anreicherung" },
        { field: "id920AutoEnrichment", title: "ID920\nKZ auto. Anreicherung" },
        { field: "id921AutoEnrichment", title: "ID921\nKZ auto. Anreicherung" },
        { field: "gv512AutoEnrichment", title: "GV512\nKZ auto. Anreicherung" },
        { field: "comment", title: "Kommentar" },
        { field: "overCounterFlag", title: "Tafelgeschäft" },
    ]
);

export const DerivativeTaxAtFlatRate = MainTaxAtFlatRate(
    "Derivate",
    "/security/derivatives/searchTaxAtFlatRate",
    "searchTaxAtFlatRateDerivative",
    [
        { field: "externBusinessReference", title: "Transaktionsreferenz" },
        { field: "transactionKeyMandant", title: "Umsatzreferenz" },
        { field: "derivativeData.derivativeCode", title: "Derivate Code" },
        { field: "tradeEvent", title: "Geschäftsart" },
        { field: "taxEventGroup", title: "Vorgangsgr.-Schlüssel" },
        { field: "taxEvent", title: "StGA" },
        { field: "nominal", title: "Umsatz" },
        { field: "referenceNominal", title: "Referenznominal" },
        { field: "nominalCurrency", title: "Stk/Whrg" },
        { field: "tradeCurrency", title: "Handels Whrg" },
        { field: "tradeExchangerate", title: "DevKurs Handels Whrg" },
        { field: "tradeMarketValue", title: "Kurswert Handels Whrg" },
        { field: "settlementCurrency", title: "Abr.-Whrg" },
        { field: "settlementExchangerate", title: "DevKurs Abr.-Whrg" },
        { field: "settlementMarketValue", title: "Kurswert Abr.-Whrg" },
        { field: "settlementaddExpenseCosts", title: "Anschaff. NK Abr.-Whrg" },
        { field: "marketValueEur", title: "Gegenwert EUR" },
        { field: "tradeaddExpenseCosts", title: "NK Abr.-Whrg" },
        { field: "valuation", title: "Bewertung am Bewertungstag in EUR" },
        { field: "settlementStockPrice", title: "Börsenkurs Handels Whrg" },
        { field: "expenseCostsEUR", title: "NK EUR" },
        { field: "refDelSystem", title: "Liefersystem" },
        {
            field: "executeTime",
            title: "Ausführ.-Datum",
            type: "date",
        },
        {
            field: "valutaDate",
            title: "Valuta",
            type: "date",
        },
        {
            field: "bookingDate",
            title: "Buchungs-Datum",
            type: "date",
        },
        {
            field: "acquiredExchangerateDay",
            title: "Tag des ermittelten Devisenkurses",
            type: "date",
        },
        {
            field: "taxTime",
            title: "Steuer-Datum",
            type: "date",
        },
        {
            field: "instructionDate",
            title: "Weisungs-Datum",
            type: "date",
        },
        {
            field: "settlementValueDate",
            title: "Stückevaluta-Datum",
            type: "date",
        },
        { field: "fractionPaymentTrade", title: "Spitzenausgleich in Handels Whrg" },
        { field: "fractionPaymentEUR", title: "Spitzenausgleich in EUR" },
        { field: "fractionPaymentSafeGuard", title: "Spitzenausgleich in Abr.-Whrg" },
        { field: "flagSpekuElements", title: "Anschaffungsdaten Detail" },
        { field: "flagVAElemente", title: "Wertkorrekturen" },
        { field: "externVoucherKey", title: "Externe Beleg-Nummer" },
        { field: "ficticiousWithholdingTax", title: "Fiktive Quellensteuer" },
        { field: "structuredProductReference", title: "Referenz strukturiertes Produkt" },
        { field: "deliveringBankInEU", title: "Lieferbank in EU" },
        { field: "externalDelivery", title: "Externe Lieferung" },
        { field: "depositTransferCentre", title: "TaxBox-KZ" },
        { field: "cancellationFlag", title: "StoKZ" },
        { field: "transactionKeyMandantCancellation", title: "Umsatzreferenz stornierter Umsatz" },
        { field: "calculationFlag", title: "Berechenbarkeit" },
        { field: "execState", title: "Ausführungsstatus" },
        { field: "manualCheckRequired", title: "Man. Prüf." },
        { field: "fiscalValuation", title: "Steuerl. bewertbar" },
        { field: "cross_currency", title: "Cross-Currency-Flag" },
        { field: "multipleCloseFlag", title: "MultipleCloseFlag" },
        { field: "transOtcContractSize", title: "Kontraktgröße im Umsatz" },
        { field: "subrogationEV", title: "Gläubigerwechsel" },
        { field: "transferReasonEv", title: "Besonderheiten Übertrag" },
        { field: "transferTypeEv", title: "Art Übertrag" },
        { field: "reltdReference", title: "Settl. Referenz Übertrag" },
        { field: "comment", title: "Kommentar" },
        { field: "overCounterFlag", title: "Tafelgeschäft" },
    ]
);
