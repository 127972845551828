/* author: Aashish Upadhyay  */

import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const DonationDataHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const personId: number = Number(params.personId);

    const labels: IHistoryKeys[] = [
        { field: "lastName", label: "Nachname" },
        { field: "givenName", label: "Vorname" },
        { field: "street1", label: "Strasse 1" },
        { field: "street2", label: "Strasse 2" },
        { field: "houseNumber", label: "Hausnummer" },
        { field: "houseNumberAdd", label: "Zusätzliche Hausnummer" },
        { field: "zipCode", label: "PLZ" },
        { field: "town", label: "Ort" },
        { field: "country", label: "Adressland" },
        {
            field: "birthDate",
            label: "Geburtsdatum",
            type: "date",
        },
        { field: "tin", label: "Tax Identification Number (TIN)" },
        { field: "ignoreMatching", label: "Matching ignorieren" },
        { field: "nps", label: "Natürliche Person" },
    ];

    return (
        <HistoryView
            title={"Historie"}
            labels={labels}
            dataRequest={{
                path: "/donationdata/donation/person/history",
                params: { personId: personId },
            }}
            identifier={"donationDataHistory"}
        />
    );
};
