/* author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { yearValidation } from "components/EditComponents/CommonValidations";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params, useParams } from "react-router";

export const SECURITY_SECTION: string = "Gattungs- und Termindaten";

export const SecurityEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const securityId: number = Number(params.securityId);
    const section: string | undefined = params.section;
    const versionId: number = Number(params.versionId);

    const fuEditInfo: IEditDataViewUserConfiguration = {
        headline: SECURITY_SECTION,
        attributes: [
            { field: "isin", headerName: "ISIN - ISIN" },
            { field: "wkn", headerName: "WKN - WKN" },
            { field: "categoryFlag", headerName: "GD685 - CFICode" },
            { field: "shortName", headerName: "GD260  - Kurzbezeichnung" },
            { field: "longname1", headerName: "GD270A - Langbezeichnung 1" },
            { field: "longname2", headerName: "GD270B - Langbezeichnung 2" },
            { field: "validFrom", headerName: "GD090  - Datum Wirksamwerden", type: "date" },
            { field: "secStatus", headerName: "GD100  - Status", dropdown: { keyTableTabname: "TB_WMG54" } },
            { field: "refIsin", headerName: "GD140PI - Ref.-Nr. ISIN" },
            { field: "orgCountry", headerName: "GD160  - Ursprungsland", dropdown: { keyTableTabname: "TB_WML01" } },
            { field: "country", headerName: "GD161  - Emittentenland ISO", dropdown: { keyTableTabname: "TB_WML02" } },
            { field: "currency", headerName: "GD170  - Währung", dropdown: { keyTableTabname: "TB_WMW01" } },
            { field: "currencyp", headerName: "GD170P - Währung P", dropdown: { keyTableTabname: "TB_WMW01" } },
            { field: "depotCurrency", headerName: "GD171  - Depotwährung", dropdown: { keyTableTabname: "TB_WMW02" } },
            {
                field: "depotCurrencyp",
                headerName: "GD171P - Depotwährung P",
                dropdown: { keyTableTabname: "TB_WMW02" },
            },
            { field: "refundParity", headerName: "GD175  - Ersatzparität", dropdown: { keyTableTabname: "TB_WMG01" } },
            { field: "poolFactor", headerName: "GD177A - Poolfaktor" },
            {
                field: "securityClass",
                headerName: "GD190  - Wertpapiergruppe",
                dropdown: { keyTableTabname: "TB_WMG06" },
            },
            { field: "securitySort", headerName: "GD195  - Wertpapierart", dropdown: { keyTableTabname: "TB_WMG51" } },
            {
                field: "securityCharacter",
                headerName: "GD197  - Wertpapiercharakter",
                dropdown: { keyTableTabname: "TB_WMF22" },
            },
            {
                field: "instrumentSeg",
                headerName: "GD198A - Produktart Segm.",
                dropdown: { keyTableTabname: "TB_WMGE8" },
            },
            {
                field: "instrumentGrSeg",
                headerName: "GD198B - Produktgr. Segm.",
                dropdown: { keyTableTabname: "TB_WMGE9" },
            },
            {
                field: "instrumentSort",
                headerName: "GD198C - Instrumentenart",
                dropdown: { keyTableTabname: "TB_WMGF1" },
            },
            {
                field: "instrumentSort1",
                headerName: "GD198D - Zus. 1 Instr.-Art",
                dropdown: { keyTableTabname: "TB_WMGF2" },
            },
            {
                field: "instrumentSort2",
                headerName: "GD198E - Zus. 2 Instr.-Art",
                dropdown: { keyTableTabname: "TB_WMGF3" },
            },
            {
                field: "instrumentSort3",
                headerName: "GD198F - Zus. 3 Instr.-Art",
                dropdown: { keyTableTabname: "TB_WMGF4" },
            },
            {
                field: "instrumentSort4",
                headerName: "GD198G - Zus. 4 Instr.-Art",
                dropdown: { keyTableTabname: "TB_WMGF6" },
            },
            { field: "branch", headerName: "GD200  - Branche", dropdown: { keyTableTabname: "TB_WMG07" } },
            {
                field: "bistaFlag",
                headerName: "GD211  - Bista-Kennzeichen Deutschland/Ausland",
                dropdown: { keyTableTabname: "TB_WMF43" },
            },
            {
                field: "depotStatistic",
                headerName: "GD215  - Depotstatistik",
                dropdown: { keyTableTabname: "TB_WMG93" },
            },
            {
                field: "securityWknClass",
                headerName: "GD217  - WKN-Gliederung",
                dropdown: { keyTableTabname: "TB_WMF56" },
            },
            {
                field: "repProcedureZiv",
                headerName: "GD218  - KZ Meldeverfahren ZIV",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            {
                field: "repProcedureZivCh",
                headerName: "GD218  - KZ Meldeverfahren ZIV Schweiz",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            {
                field: "repProcedureZivLu",
                headerName: "GD218  - KZ Meldeverfahren ZIV Luxemburg",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            {
                field: "emitterCountry",
                headerName: "GD220A - AWV Emittentenland",
                dropdown: { keyTableTabname: "TB_WML01" },
            },
            { field: "securityType", headerName: "GD230  - Wertpapiertyp", dropdown: { keyTableTabname: "TB_WMG11" } },
            { field: "securityTypeL1", headerName: "GD235A - Wertpapiertyp L1" },
            { field: "primaryEmitter", headerName: "GD240  - Ursprungsemittent" },
            { field: "emitter", headerName: "GD245  - Aktuelle Emittentennummer" },
            {
                field: "finInnoIdent",
                headerName: "GD273  - KZ Finanzinnovation",
                dropdown: { keyTableTabname: "TB_WMGH9" },
            },
            {
                field: "secSpecDiv",
                headerName: "GD311  - Sonderheit Zins/Dividendenzahlung",
                dropdown: { keyTableTabname: "TB_WMG13" },
            },
            {
                field: "paymentPart",
                headerName: "GD311A - Sonderheit Zins/Dividendenzahlung",
                dropdown: { keyTableTabname: "TB_WMGG7" },
            },
            {
                field: "partIntAcrued",
                headerName: "GD311B - Sonderheit Stückzinsberechnung",
                dropdown: { keyTableTabname: "TB_WMGG8" },
            },
            { field: "optRight", headerName: "GD352  - Optionsrecht Art", dropdown: { keyTableTabname: "TB_WMF15" } },
            {
                field: "secUnit",
                headerName: "GD440  - Einheit der Effektennotiz",
                dropdown: { keyTableTabname: "TB_WMG19" },
            },
            { field: "par", headerName: "GD460A - Nennwert zum Stück" },
            {
                field: "nonominalvalue",
                headerName: "GD461  - ohne Nennwert",
                dropdown: { keyTableTabname: "TB_WMG71" },
            },
            {
                field: "expressionType",
                headerName: "GD481  - Stückeart bei Stückeausdruck",
                dropdown: { keyTableTabname: "TB_WMG20" },
            },
            {
                field: "flagNewShares",
                headerName: "GD484  - Kennz. Junge Aktien",
                dropdown: { keyTableTabname: "TB_WMG69" },
            },
            {
                field: "taxType",
                headerName: "GD500  - Steuer- bzw. Quellensteuerart",
                dropdown: { keyTableTabname: "TB_WMG22" },
            },
            { field: "fullTaxRate", headerName: "GD501A - Steuer- bzw. Quellensteuersatz voll" },
            { field: "reducedTax", headerName: "GD502A - Steuer bzw. Quellensteuersatz vermindert" },
            { field: "reclaimableTax", headerName: "GD503A - Steuer bzw. Quellensteuersatz rueckforderbar" },
            { field: "zast", headerName: "GD504A - ZAST bei Veräusserung", dropdown: { keyTableTabname: "TB_WMF51" } },
            {
                field: "executionInvstg",
                headerName: "GD504B - Anwendung InvStG",
                dropdown: { keyTableTabname: "TB_WMGC6" },
            },
            { field: "debitInterest", headerName: "GD507  - SolZ Prozentsatz" },
            { field: "virtualTax", headerName: "GD508  - Fiktiver Quellensteuersatz" },
            {
                field: "incomeType",
                headerName: "GD509  - Ertragsart (US-Quellensteuerverfahren)",
                dropdown: { keyTableTabname: "TB_WMF89" },
            },
            { field: "semiIncomeFrom", headerName: "GD511  - Halbeinkünfteverfahren ab", type: "date" },
            {
                field: "exchangeLocation",
                headerName: "GD621  - Heimat / Ausl.Börse",
                dropdown: { keyTableTabname: "TB_WMG32" },
            },
            { field: "capValue", headerName: "GD671  - Kapitalwert" },
            { field: "optionValue", headerName: "GD674  - Rechnerischer Wert des Optionsrechts" },
            { field: "changedToEuro", headerName: "GD770  - Umst. auf EURO", type: "date" },
            { field: "calcNominalValue", headerName: "GD777  - Rechnerischer Nennwert" },
            { field: "interestRate", headerName: "GD801A - Zinssatz Prozent" },
            {
                field: "interestDatePeriod",
                headerName: "GD811  - Zinstermin Periode",
                dropdown: { keyTableTabname: "TB_WMG53" },
            },
            {
                field: "flagPaymentOfInter",
                headerName: "GD819  - Kennz. Zinszahlung im AG 1",
                dropdown: { keyTableTabname: "TB_WMF27" },
            },
            { field: "finalDueDate", headerName: "GD910  - Endfälligkeit", type: "date" },
            { field: "refWkn", headerName: "GD140  - Ref.-Nr. WKN" },
            { field: "flatTaxRate", headerName: "GD505B - Abgeltungsteuersatz auf Depotbestände" },
            { field: "offsWithhTaxRate", headerName: "GD505C - Anrechenbarer Quellensteuersatz" },
            {
                field: "partialIncomeFlag",
                headerName: "GD505D - Anwendung Teileinkünfteverfahren",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            {
                field: "lossOffsetFundId",
                headerName: "GD505E - Kennzeichnung Verlustverrechnungstopf",
                dropdown: { keyTableTabname: "TB_WMGB1" },
            },
            { field: "devCountryOfTax", headerName: "GD169  - Abweichendes Besteuerungsland" },
            {
                field: "fiscalYearStart",
                headerName: "GD331  - Geschäftsjahr von",
                rules: yearValidation,
            },
            { field: "fiscalYearEnd", headerName: "GD332  - Geschäftsjahr bis", rules: yearValidation },
            {
                field: "gd234",
                headerName: "GD234  - Steuerabührende Stelle",
                dropdown: { keyTableTabname: "TB_WMGJ8" },
            },
            { field: "discountFactor", headerName: "GD180  - Diskontierungssatz" },
            {
                field: "bearerRegIdent",
                headerName: "GD483  - Inhaber-/NamensWP",
                dropdown: { keyTableTabname: "TB_WMG50" },
            },
            {
                field: "fundInvFocus",
                headerName: "GD441  - Anlageschw. Fonds",
                rules: {
                    minLength: { value: 11 },
                    maxLength: { value: 11 },
                },
            },
            {
                field: "zivRepProceeds",
                headerName: "GD324B - KZ ZIV Erlöse",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            {
                field: "zivRepRelevance",
                headerName: "GD324A - KZ ZIV Erträge",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            { field: "issuingPrice", headerName: "GD669  - Emissionskurs" },
            {
                field: "issuingCur",
                headerName: "GD670A - Emissionskurs Währung",
                dropdown: { keyTableTabname: "TB_WMW02" },
            },
            {
                field: "issuingPricePerc",
                headerName: "GD670B - Emissionskurs Prozent",
                dropdown: { keyTableTabname: "TB_WMW03" },
            },
            { field: "inflIndexInt", headerName: "GD749  - Inflations Index", displayOnly: true },
            {
                field: "zeroIdentFlag",
                headerName: "GD505F - Kennzeichen Nullmeldung GD505C",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "incomeAdjProc",
                headerName: "GD863  - Kennzeichen Ertragsausgleichsverfahren",
                dropdown: { keyTableTabname: "TB_WMGK6" },
            },
            { field: "execState", headerName: "Ausführungsstatus - Ausführungsstatus", displayOnly: true },
            { field: "gd237", headerName: "GD237  - Ort KG Bundesland", dropdown: { keyTableTabname: "TB_WML07" } },
            {
                field: "gd873",
                headerName: "GD873  - Application of Investment Tax act / Anwendung Investmentsteuergesetz",
                dropdown: { keyTableTabname: "TB_WMGS2" },
            },
            {
                field: "gd968f",
                headerName: "GD968F  - Gesellschaft mit Besteuerungswahlrecht gem. BMF 18.01.2016",
                dropdown: { keyTableTabname: "TB_WMG83" },
            },
            {
                field: "gd504g",
                headerName: "GD504G  - Investmentfonds bzw. Anteilskl. gemäß §10 InvStG für steuerbeg. Anleger",
                dropdown: { keyTableTabname: "TB_WMGY2" },
            },
        ],
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/security/securities/securityForEdit",
                params: { securityId: versionId },
            }),

        versionRequest: () =>
            sendGetRequest({
                path: "/security/securities/securityForEdit",
                params: { securityId: versionId },
            }),
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [fuEditInfo];

    return <EditMask completeEditInfo={completeEditInfo} id01={securityId} title="Wertpapier" section={section} />;
};
