/* author: Sindhu Kannan */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";
import { IdSelector } from "components/TBD/IdSelector";
import { getEditHistoryContextMenu } from "components/VersionsView";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AMOUNT_DETATILS } from "./IncomeTransactionItemEdit";

interface IAmountDetail {
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: number;
    transactionType: string;
}

export const AmountDetails = (props: IAmountDetail): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "transactionType", title: "Art des Betrags" },
        { field: "amount", title: "Betrag" },
        { field: "currency", title: "Währung" },
        { field: "rate", title: "Devisenkurs" },
        { field: "itemSource", title: "Quelle" },
    ];
    const [id, setId] = useState<string>(props.id.toString());

    const IDENTIFIER: TableidentifierType = "amounts";

    const [amountData, setAmountData] = useState<Record<string, string>[]>([]);
    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    useEffect(() => {
        if (props.transactionType && props.transactionType.startsWith("sto")) {
            sendGetRequest({
                path: "/cgresources/fttransactions/stoIncomeTranItem-ByIncomeTranId",
                params: { incomeTranId: id.toString() },
            }).then((response) => {
                setAmountData(response.data);
            });
        } else {
            sendGetRequest({
                path: "/cgresources/fttransactions/items",
                params: { incomeTranId: id.toString() },
            }).then((response) => {
                setAmountData(response.data);
            });
        }
    }, [id]);

    return (
        <PopUp
            setOpen={props.setOpen}
            title="Beträge"
            content={
                <div>
                    <IdSelector label="incomeTranId" setId={setId} />

                    <Table
                        columnsDefinition={columns}
                        identifier={IDENTIFIER}
                        tableData={amountData}
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                        contextMenu={getEditHistoryContextMenu(
                            currentRowData,
                            setCurrentRowData,
                            "",
                            AMOUNT_DETATILS,
                            "item"
                        )}
                        showEditPendingStateIcons
                    />
                </div>
            }
        />
    );
};
