/* author: PAL Prashant */
import {
    FromEditView,
    IEditDataViewUserConfiguration,
    IEditDataViewUserConfigurationWithData,
    IFormConfiGuration,
    updateField,
} from "./FormEditView";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { Footer, IFooter } from "components/Footer";
import { NavigateFunction, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { DialogActions, Typography } from "@mui/material";
import { PopUp } from "components/PopUp";
import { cloneDeep } from "lodash";
import { ButtonSecondary } from "components/Button";
import { RootState } from "application/redux/Store";
import { sendGetRequest, sendPutRequest } from "application/api/HttpRequestHandler";
import { useAppSelector } from "application/redux/Hooks";
import { InputField } from "components/InputField";
import { RerenderHook } from "components/useRerender";
import {
    STATE_APPROVED,
    STATE_INITIAL,
    STATE_SAVED,
    STATE_SEND_TO_APPROVAL,
    STATE_TYPES,
    getChangedValue,
    getCurrentUrlLocation,
    hasChangedValuesArray,
} from "components/Converter";
import { Colors } from "components/Colors";

export const INSERT_ACTION: string = "I";

export const navigateOverviewPageFromEdit = (navigate: NavigateFunction): void => {
    const oldUrl: string = getCurrentUrlLocation();
    const newUrl: string = oldUrl.substring(0, oldUrl.lastIndexOf("/edit"));
    navigate(newUrl);
};

export interface IFormEditData extends IFormEditDataCommon {
    editDataInfo: IEditDataViewUserConfigurationWithData[];
    rerender: RerenderHook;
    renderOnlySection?: string;
}

export interface IFormEditDataCommon {
    title: string;
    id01: number;
    parentIdentifier?: string;
    overrideRootLevelForInsert?: number;
    overrideEditValues?: IOverrideValue[];
    overrideDeleteLevel?: number;
    overrideApprovalLevel?: number;
}

interface IOverrideValue {
    field: string;
    overrideValue: string;
}

export const FormEditData = (props: IFormEditData): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const COMMENT_MAX_LENGTH: number = 200;

    let editDataInfo: IEditDataViewUserConfigurationWithData[] = props.editDataInfo;
    if (props.renderOnlySection) {
        editDataInfo = props.editDataInfo.filter(
            (edi: IEditDataViewUserConfigurationWithData) => edi.headline === props.renderOnlySection
        );
        editDataInfo[0].tabName = undefined;
    }

    const replaceEditPendingData = (data: Record<string, string>, type: "PROD" | "CHANGE"): Record<string, string> => {
        const r: Record<string, string> = data;
        if (data["editPendingData"] && type === "CHANGE") {
            //replace the editPendingData information
            const editPendingData: Record<string, string> = data["editPendingData"] as unknown as Record<
                string,
                string
            >;
            if (editPendingData) {
                //replace changeUser, releaseUser, update ts and insert ts
                r["changeUser"] = editPendingData["changeUser"];
                r["releaseUser"] = editPendingData["releaseUser"];
                r["updateTS"] = editPendingData["updateTS"] ?? editPendingData["insertTS"];

                //replace changes from editPendingData
                const changedData: Record<string, string> = (
                    editPendingData as unknown as Record<string, Record<string, string>>
                )["changedData"];
                if (changedData) {
                    Object.keys(changedData).forEach((key: string) => {
                        if (Object.keys(changedData[key]).length === 1 && Object.keys(changedData[key])[0] === "id")
                            return;
                        r[key] = changedData[key];
                    });
                }
            }
        }

        return r;
    };

    const initilizeData = (type: "PROD" | "CHANGE"): Record<string, Record<string, string>[]> => {
        const ret: Record<string, Record<string, string>[]> = {};

        cloneDeep(editDataInfo).forEach((edi: IEditDataViewUserConfigurationWithData) => {
            //for each topic
            const topic: string = edi.headline;
            const dataArray: Record<string, string>[] = edi.data;
            const newDataArrray = dataArray.map((data: Record<string, string>) => {
                //for each item in topic
                return replaceEditPendingData(data, type);
            });

            ret[topic] = newDataArrray;
        });

        return ret;
    };

    const [productionData, setProductionData] = useState<Record<string, Record<string, string>[]> | undefined>();
    const [editData, setEditData] = useState<Record<string, Record<string, string>[]> | undefined>();
    const [hasChange, setHasChange] = useState<Record<string, boolean>>({});
    const [datePickerError, setDatePickerError] = useState<Record<string, boolean>>({});

    const updateEditData = (topic: string, index: number, key: string, newValue: string) => {
        if (!editData) return;
        const ds: Record<string, Record<string, string>[]> = cloneDeep(editData);
        if (key.includes(".")) {
            //TODO :: this logic will only work if the field is separated with one dot.
            const keySplit: string[] = key.split(".");
            const newArray: Record<string, unknown>[] = newValue.split(",").map((item: string) => {
                return {
                    [keySplit[1]]: item,
                };
            });
            ds[topic][index][keySplit[0]] = newArray as unknown as string;
        } else updateField(ds[topic][index], key, newValue);
        //ds[topic][index][key] = newValue;
        setEditData(ds);

        const hc: Record<string, boolean> = cloneDeep(hasChange);
        hc[topic] = true;
        setHasChange(hc);
    };

    const resetEditData = (topic: string, index: number) => {
        if (!productionData || !editData) return;

        const ds: Record<string, Record<string, string>[]> = cloneDeep(editData);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ds[topic][index] = replaceEditPendingData(productionData[topic][index], "CHANGE");
        setEditData(ds);

        const hc: Record<string, boolean> = cloneDeep(hasChange);
        hc[topic] = false;
        setHasChange(hc);
    };

    const updateDatePickerError = (topic: string, index: number, key: string, newValue: boolean) => {
        setDatePickerError({ ...datePickerError, [topic + index + key]: newValue });
    };

    const getTheUserFromTheDb = (): string => {
        let dbUser: string | undefined = undefined;
        editDataInfo.forEach((edi: IEditDataViewUserConfigurationWithData) => {
            if (dbUser) return;
            edi.data.forEach((record: Record<string, string>) => {
                if (dbUser) return;
                dbUser = getChangedValue(record, "changeUser");
            });
        });

        return (dbUser ?? "").toUpperCase();
    };

    const userFromEdit: string = getTheUserFromTheDb();
    const currentUser: string = useAppSelector((rootState: RootState) => rootState.user.userRole.toUpperCase());

    //comment state if rejcetd edit request
    const checkComment = (): string => {
        let c: string = "";
        editDataInfo.forEach((edi: IEditDataViewUserConfigurationWithData) => {
            if (c !== "") return;
            edi.data.forEach((d: Record<string, string>) => {
                if (d["editPendingData"]) {
                    c = (d["editPendingData"] as unknown as Record<string, STATE_TYPES>)["stateTxMsg"];
                }
            });
        });

        return c ?? "";
    };
    const [comment, setComment] = useState<string>(checkComment());
    const [commentPopUpOpen, setCommentPopUpOpen] = useState<boolean>(false);

    const checkStates = (): STATE_TYPES => {
        let s: STATE_TYPES = STATE_INITIAL;
        editDataInfo.forEach((edi: IEditDataViewUserConfigurationWithData) => {
            if (s !== STATE_INITIAL) return;
            edi.data.forEach((d: Record<string, string>) => {
                if (d["editPendingData"]) {
                    s = (d["editPendingData"] as unknown as Record<string, STATE_TYPES>)["state"];
                }
            });
        });

        return s;
    };

    const [state, setState] = useState<STATE_TYPES>(checkStates());

    const isNewCreated = (data: Record<string, string | string[]>): boolean =>
        getChangedValue(data, "modifyAction") === INSERT_ACTION;

    const onSaveData = () => {
        if (!editData || !productionData) return;

        editDataInfo.forEach((edi: IEditDataViewUserConfiguration) => {
            //check for the sections, which have a change
            if (JSON.stringify(editData[edi.headline]) !== JSON.stringify(productionData[edi.headline])) {
                //create body
                const body: {
                    dataNew: Record<string, string | string[]>;
                    dataOld: Record<string, string | string[]>;
                }[] = [];
                editData[edi.headline].forEach((d: Record<string, string | string[]>, index) => {
                    if (isNewCreated(d)) {
                        //remove id and editPendingData from oldData because it is new created
                        delete productionData[edi.headline][index]["id"];
                        delete productionData[edi.headline][index]["editPendingData"];
                    }

                    if (props.parentIdentifier) {
                        editData[edi.headline][index][props.parentIdentifier] = String(props.id01);
                    }

                    if (props.overrideEditValues) {
                        props.overrideEditValues.forEach((override: IOverrideValue) => {
                            editData[edi.headline][index][override.field] = override.overrideValue;
                        });
                    }

                    // reversed flattern
                    body.push({
                        dataNew: editData[edi.headline][index],
                        dataOld: productionData[edi.headline][index],
                    });
                });

                //TODO :: send requests in parallel
                sendPutRequest({
                    path: ("/cgresources/editPending/save/" + editData[edi.headline][0]["type"]) as never,
                    body: body,
                })
                    .then((response) => {
                        setState(STATE_SAVED);

                        //navigate to new created
                        if (response.data.newId) {
                            const oldUrl: string = getCurrentUrlLocation();
                            const prevUrl: string = oldUrl.substring(0, oldUrl.lastIndexOf("/"));
                            const newUrl: string = prevUrl + "/" + response.data.newId;
                            navigate(newUrl, { state: { isNew: true } });
                        }
                    })
                    .finally(() => props.rerender.rerender());
            }
        });
    };

    const onApproval = () => {
        sendGetRequest({
            path: "/cgresources/editPending/approvalProcess",
            params: {
                approvalStep: STATE_SEND_TO_APPROVAL,
                id01: props.id01,
                comment: "",
                approvalLevel: getApprovalLevel(),
            },
        })
            .then(() => {
                setState(STATE_SEND_TO_APPROVAL);
            })
            .finally(() => {
                props.rerender.rerender();
            });
    };
    const onApproved = () => {
        sendGetRequest({
            path: "/cgresources/editPending/approvalProcess",
            params: {
                approvalStep: STATE_APPROVED,
                id01: props.id01,
                comment: "",
                approvalLevel: getApprovalLevel(),
            },
        })
            .then(() => {
                setState(STATE_APPROVED);
            })
            .finally(() => {
                props.rerender.rerender();
            });
    };

    const onComment = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    const onCommentAction = () => {
        setCommentPopUpOpen(false);
        sendGetRequest({
            path: "/cgresources/editPending/approvalProcess",
            params: {
                approvalStep: " ",
                id01: props.id01,
                comment: comment,
                approvalLevel: getApprovalLevel(),
            },
        })
            .then(() => {
                setState(STATE_SAVED);
            })
            .finally(() => {
                props.rerender.rerender();
            });
    };

    const editForm: UseFormReturn<FieldValues, unknown, undefined> = useForm({
        mode: "all",
    });

    const formSpecificConfiguration: IFormConfiGuration = {
        errors: editForm.formState.errors,
        control: editForm.control,
        register: editForm.register,
        clearErrors: editForm.clearErrors,
    };

    const [invalidFields, setInvalidFields] = useState<Record<string, Set<string>>>({});
    const updateInvalidField = (section: string, newSet: Set<string>) => {
        setInvalidFields({ ...invalidFields, [section]: newSet });
    };

    const hasError = () =>
        //check normal input fields
        JSON.stringify(formSpecificConfiguration.errors) !== JSON.stringify({}) ||
        //check for date errors
        Object.values(datePickerError).filter((f: boolean) => f).length > 0 ||
        //check invalid fields
        Object.values(invalidFields).filter((s: Set<string>) => s.size > 0).length > 0;

    const footer: IFooter = {
        buttons: [
            {
                title: "Speichern",
                onClick: () => editForm.handleSubmit(onSaveData),
                filled: false,
                type: "submit",
                disabled:
                    // !props.isCancelation &&
                    // !props.duplication &&
                    hasError() || Object.values(hasChange).filter((f: boolean) => f).length === 0,
            },
            {
                title: "Zur Freigabe",
                onClick: () => onApproval(),
                filled: false,
                disabled: (state !== STATE_SAVED || Object.values(hasChange).filter((v: boolean) => v).length) !== 0,
            },
            {
                title: "Freigeben",
                onClick: () => onApproved(),
                filled: false,
                disabled: state !== STATE_SEND_TO_APPROVAL || currentUser === userFromEdit,
            },
            {
                title: "Wiedervorlage",
                onClick: () => setCommentPopUpOpen(true),
                filled: false,
                disabled: state !== STATE_SEND_TO_APPROVAL || currentUser === userFromEdit,
            },
            {
                title: "Schließen",
                onClick: () => {
                    confirm("Wollen Sie zurück zur Übersicht?") && navigateOverviewPageFromEdit(navigate);
                },
                filled: false,
            },
            {
                title: "Löschen",
                onClick: () =>
                    sendGetRequest({
                        path: "/cgresources/editPending/delete",
                        params: { deleteLevel: props.overrideDeleteLevel ?? 1, id: props.id01 },
                    }).then(() => {
                        //only for new created onces
                        editData &&
                            isNewCreated(editData[editDataInfo[0].headline][0]) &&
                            navigateOverviewPageFromEdit(navigate);
                        props.rerender.rerender();
                    }),
                filled: false,
                disabled: (() => {
                    let disableButton: boolean = true;
                    editDataInfo.forEach((edi: IEditDataViewUserConfigurationWithData) => {
                        if (!disableButton || !productionData) return;
                        disableButton = !(hasChangedValuesArray(productionData[edi.headline]) && state === STATE_SAVED);
                    });
                    return disableButton;
                })(),
            },
        ],
    };

    const isCommentInvalid = (): boolean => comment.length === 0 || comment.length > COMMENT_MAX_LENGTH;

    const checkEditPendingParents = (): boolean => {
        if (!productionData) return true;

        let disableFurtherChanges = false;
        props.editDataInfo.forEach((edi: IEditDataViewUserConfigurationWithData) => {
            productionData[edi.headline].forEach((data: Record<string, string>) => {
                const eppd: Record<string, unknown>[] = data["editPendingParentData"] as unknown as Record<
                    string,
                    string
                >[];
                if (eppd && eppd.length !== 0) {
                    const editPendingParentState: string = (
                        data["editPendingParentData"][0] as unknown as Record<string, string>
                    )["state"];
                    if (
                        editPendingParentState === STATE_APPROVED ||
                        editPendingParentState === STATE_SEND_TO_APPROVAL
                    ) {
                        disableFurtherChanges = true;
                    }
                }
            });

            if (disableFurtherChanges) {
                return;
            }
        });
        return disableFurtherChanges;
    };
    const disableFurtherEdits = (): boolean =>
        ([STATE_APPROVED, STATE_SEND_TO_APPROVAL] as STATE_TYPES[]).includes(state) || checkEditPendingParents();

    useEffect(() => {
        setProductionData(initilizeData("PROD"));
        setEditData(initilizeData("CHANGE"));
    }, []);

    if (!productionData || !editData) return <div></div>;

    const getApprovalLevel = (): number => getApprovalLevelHelper(editData[editDataInfo[0].headline][0]);

    const getApprovalLevelHelper = (data: Record<string, string | string[]>): number => {
        if (props.overrideRootLevelForInsert && isNewCreated(data)) {
            return props.overrideRootLevelForInsert;
        }
        return props.overrideApprovalLevel ?? 1;
    };

    const getDeleteLevel = (data: Record<string, string | string[]>): number => {
        if (props.overrideRootLevelForInsert && isNewCreated(data)) {
            return props.overrideRootLevelForInsert;
        }
        return props.parentIdentifier ? 2 : 1;
    };

    return (
        <form onSubmit={editForm.handleSubmit(onSaveData)} style={{ padding: "1rem" }}>
            {disableFurtherEdits() && (
                <Typography sx={{ color: Colors.red }}>
                    Bearbeitung nicht möglich, Daten sind in Bearbeitung!
                </Typography>
            )}
            <Typography style={{ fontWeight: "bold", fontSize: "1.4rem", marginBottom: "1rem" }}>
                {props.title}
            </Typography>

            {editDataInfo.map((edi: IEditDataViewUserConfiguration) => {
                return (
                    <FromEditView
                        headline={edi.headline}
                        attributes={edi.attributes}
                        formSpecificConfiguration={formSpecificConfiguration}
                        tabName={edi.tabName}
                        updateEditData={updateEditData}
                        resetEditData={resetEditData}
                        updateDatePickerError={updateDatePickerError}
                        productionData={productionData[edi.headline]}
                        editData={editData[edi.headline]}
                        key={edi.headline}
                        disableFurtherEdits={disableFurtherEdits()}
                        rerender={props.rerender}
                        latestVersionRequest={edi.latestVersionRequest}
                        versionRequest={edi.versionRequest}
                        deleteLevel={getDeleteLevel(editData[edi.headline][0])}
                        isNewCreated={isNewCreated(editData[editDataInfo[0].headline][0])}
                        checkInvalidity={edi.checkInvalidity}
                        invalidFields={invalidFields[edi.headline]}
                        updateInvalidField={updateInvalidField}
                    />
                );
            })}

            <div style={{ width: "50%", marginBottom: "3.5rem" }}>
                <Typography style={{ fontWeight: "bold", fontSize: "1.2rem", marginBottom: "1rem" }}>
                    Kommentar
                </Typography>
                <InputField
                    value={comment}
                    setValue={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setComment(event.target.value);
                    }}
                    label={""}
                    disabled={true}
                    multiline
                />
            </div>

            {commentPopUpOpen && (
                <PopUp
                    setOpen={setCommentPopUpOpen}
                    title={"Kommentar zur Wiedervorlage"}
                    content={
                        <div>
                            <InputField
                                label={""}
                                rows={8}
                                multiline
                                value={comment}
                                setValue={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    setComment(event.target.value);
                                }}
                            />
                            <Typography sx={{ color: isCommentInvalid() ? Colors.red : "initial" }}>
                                {comment.length + "/" + COMMENT_MAX_LENGTH}
                            </Typography>
                        </div>
                    }
                    dialogActionContentFromOtherComponet={
                        <DialogActions>
                            <ButtonSecondary disabled={isCommentInvalid()} title="Ok" onClick={onCommentAction} />
                            <ButtonSecondary title="Schließen" onClick={() => setCommentPopUpOpen(false)} />
                        </DialogActions>
                    }
                    onClick={onComment}
                />
            )}

            <Footer buttons={footer.buttons} />
        </form>
    );
};
