/* author: JESCHKE Moritz */

import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Alert, IconButton, Snackbar, Toolbar, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { sendPutRequest } from "application/api/HttpRequestHandler";
import { useAppDispatch, useAppSelector } from "application/redux/Hooks";
import { transactionClearAll } from "application/redux/slices/TransactionSlice";
import { userConfigClearAll } from "application/redux/slices/UserConfigurationSlice";
import { logout } from "application/redux/slices/UserSlice";
import { AppDispatch, RootState } from "application/redux/Store";
import { ThreeDotMenu, IThreeDotMenuItem } from "components/ThreeDotMenu";
import { RenewTokenDialog } from "dialogs/RenewTokenDialog";
import { USER_PREFERENCES_SUFFIX } from "pages/login/Login";
import { useCallback, useEffect, useState } from "react";

let loginTime: Date;

export const renewLoginTime = (): void => {
    const DEFAULT_LOGIN_TIME_IN_MINUTES: number = 5;

    const LOGIN_TIME_IN_MINUTES: number =
        process.env.REACT_APP_LOGIN_TIME !== undefined
            ? Number(process.env.REACT_APP_LOGIN_TIME)
            : DEFAULT_LOGIN_TIME_IN_MINUTES;
    //convert minutes in millis
    // eslint-disable-next-line no-magic-numbers
    loginTime = new Date(Date.now() + LOGIN_TIME_IN_MINUTES * 60 * 1000);
};

interface IAppbar {
    toggleMenuBar: () => void;
    isMenuOpen: boolean;
}

type SeverityType = "error" | "warning" | "info" | "success";
interface IToast {
    severity: SeverityType;
    text: string;
}

export const Appbar = (props: IAppbar): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();
    const LOGIN_TIME_UPDATE_INTERVAL_IN_MILIS: number = 1000;

    const clientId: string = useAppSelector((state: RootState) => state.user.clientId);
    const clientName: string = useAppSelector((state: RootState) => state.user.clientName);
    const username: string = useAppSelector((state: RootState) => state.user.userRole);
    const userConfig = useAppSelector((state: RootState) => state.userConfiguration);
    const [time, setTime] = useState<string>("");

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const menuItems: IThreeDotMenuItem[] = [
        {
            name: "Benutzereinstellungen speichern",
            action: () => {
                sendPutRequest({
                    path: "/preferences/user/modify",
                    body: {
                        type: "U",
                        user: username.toLowerCase() + USER_PREFERENCES_SUFFIX,
                        backingStore: JSON.stringify(userConfig),
                    },
                })
                    .then(() => {
                        setToast({
                            severity: "success",
                            text: "Die Benutzereinstellung wurde erfolgreich gespeichert.",
                        });
                    })
                    .catch(() => {
                        setToast({ severity: "error", text: "Die Benutzereinstellung wurde nicht gespeichert." });
                    });
            },
        },
        {
            name: "Abmelden",
            action: () => logoutUser(),
            linkTo: "/",
        },
    ];

    const logoutUser = useCallback(() => {
        dispatch(logout());
        dispatch(transactionClearAll());
        dispatch(userConfigClearAll());
    }, [dispatch]);

    useEffect(() => {
        renewLoginTime();

        const interval = window.setInterval(() => {
            //get loginTime in millis & convert milis into secondes
            // eslint-disable-next-line no-magic-numbers
            const remainingTime: string[] = ((loginTime.getTime() - new Date().getTime()) / 60000)
                .toString()
                .split(".");
            //convert to MM:SS Format
            // eslint-disable-next-line no-magic-numbers
            const convertSeconds: string = Math.floor(Number("0." + remainingTime[1]) * 60).toLocaleString("de-DE", {
                minimumIntegerDigits: 2,
            });
            setTime(remainingTime[0] + ":" + convertSeconds);

            if (remainingTime[0] === "1" && convertSeconds === "00") {
                setDialogOpen(true);
            }
            if (remainingTime[0] === "-0") {
                logoutUser();
            }
        }, LOGIN_TIME_UPDATE_INTERVAL_IN_MILIS);

        return () => {
            clearInterval(interval);
        };
    }, [logoutUser]);

    //Toast
    const TOAST_AUTO_HIDE_DURATION_IN_MS: number = 3000;
    const [toast, setToast] = useState<IToast | undefined>();
    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setToast(undefined);
    };

    return (
        <Toolbar
            sx={{
                paddingRight: "24px",
                background: "#1976d2",
                color: "white",
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={props.toggleMenuBar}
                sx={{
                    marginRight: "36px",
                    ...(props.isMenuOpen && { display: "none" }),
                }}
            >
                <MenuIcon />
            </IconButton>

            <Typography>{clientId + clientName !== "" ? clientId + "/" + clientName : ""}</Typography>

            <Typography component="h1" variant="h6" color="inherit" align="center" noWrap sx={{ flexGrow: 1 }}>
                Tributum
            </Typography>

            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography>{time}</Typography>
                <div onClick={async () => renewLoginTime()}>
                    <RefreshIcon sx={{ color: "white", cursor: "pointer" }} />
                </div>
                {"    "}
                <Typography>{username}</Typography>
                <ThreeDotMenu menuItems={menuItems} icon={<AccountCircle sx={{ color: "white" }} />} />
            </div>

            {dialogOpen && <RenewTokenDialog setOpen={setDialogOpen} />}
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={toast !== undefined}
                autoHideDuration={TOAST_AUTO_HIDE_DURATION_IN_MS}
                onClose={handleToastClose}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setToast(undefined);
                            }}
                        >
                            <GridCloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={toast?.severity}
                >
                    {toast?.text}
                </Alert>
            </Snackbar>
        </Toolbar>
    );
};
