/* author: JESCHKE Moritz */

import { Box, Card } from "@mui/material";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, ITableContextMenu, Table } from "components/Table";

export const TedExchange = () => {
    const IDENTIFIER: TableidentifierType = "tedExchange";

    const tableData: [] = [];

    const tedColumns: ITableColumn[] = [
        { field: "TB_EXT_TED_KEY", title: "TED-Client Ref." },
        { field: "TB_EXT_TRAN_KEY", title: "Umsatzreferenz" },
        { field: "TB_MSGREF", title: "Message Referenz" },
        {
            field: "TB_CBFCLIENTACC",
            title: "CBF Konto",
            type: "numeric",
        },
        { field: "TB_MSGFNC", title: "Message Function" },
        { field: "TB_CREDT", title: "Übertragungsdatum" },
        { field: "TB_CRETM", title: "Übertragungsdatum" },
        { field: "TB_TAXTP", title: "Satzart" },
        {
            field: "TB_TAXID",
            title: "Taxbox ID",
            type: "numeric",
        },
        {
            field: "TB_PREVTAXID",
            title: "Previous Taxbox ID",
            type: "numeric",
        },
        { field: "TB_RLTD_REF", title: "Settlement Referenz" },
        { field: "TB_ISSUER_ID", title: "Auftraggeber BIC/BLZ" },
        { field: "TB_ISSUER_ACCTSVCR", title: "Auftraggeber Depotstelle (Bank/KAG)" },
        { field: "TB_ISSUER_ACCTKEY", title: "Auftraggeber Depotnummer" },
        { field: "TB_ISSUER_ACCTNM", title: "Auftraggeber Name" },
        { field: "TB_BENEF_ID", title: "Empfänger BIC/BLZ" },
        { field: "TB_BENEF_ACCTSVCR", title: "Empfänger Depotstelle (Bank/KAG)" },
        { field: "TB_BENEF_ACCTKEY", title: "Empfänger Depotnummer" },
        { field: "TB_BENEF_ACCTNM", title: "Empfänger Name" },
        { field: "TB_ASSETTP", title: "Asset Type" },
        { field: "TB_ISIN", title: "ISIN" },
        { field: "TB_NM", title: "Bezeichnung des Wertpapiers" },
        { field: "TB_TRNSTP", title: "Art des Übertrages" },
        { field: "TB_SUBROGATION", title: "Gläubigerwechsel" },
        { field: "TB_TRANS_SPEC_TYPE", title: "Besonderheiten Übertrag" },
        {
            field: "TB_TRNSNOM",
            title: "Gesamtübertragsmenge",
            type: "numeric",
        },
        { field: "TB_TRNSUNIT", title: "Übertragseinheit" },
        {
            field: "TB_REPCNT",
            title: "Wiederholsequenzen",
            type: "numeric",
        },
        { field: "TB_RJCTDRSN", title: "Grund der Abweisung" },
        { field: "TB_SNDCYCID", title: "Abweisungsinfo" },
        { field: "TB_STATUS", title: "TED-Status" },
        {
            field: "TB_STATUS_DTL_KEY",
            title: "Detail-Schlüssel TED-Verarbeitungsstatus",
            type: "numeric",
        },
        {
            field: "TB_DEP_TRAN_CENTRE",
            title: "Taxbox Kennzeichen",
            type: "numeric",
        },
        { field: "TB_BRANCH", title: "Filiale" },
        { field: "TB_COMMENT", title: "TED-Kommentar" },
        { field: "TB_CANCELLATION_FLAG", title: "Strono-Flag" },
        { field: "TB_ACCOUNT_TRANSFER_TYPE", title: "int/ex" },
    ];

    const contextMenu: ITableContextMenu = {
        contextActions: [{ label: "Anschaffungsdaten", action: () => console.log("test") }],
    };

    return (
        <Box sx={{ height: "100%", margin: "1em" }}>
            <Card sx={{ height: "50%" }}>
                <Table
                    columnsDefinition={tedColumns}
                    identifier={IDENTIFIER}
                    contextMenu={contextMenu}
                    tableData={tableData}
                />
            </Card>
        </Box>
    );
};
