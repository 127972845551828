/* author: Pal Prashant  */

import { OverviewData } from "components/OverviewData";
import { SyntheticEvent, useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { IOverviewHeadLine } from "components/OverviewHeadLine";
import { ITableColumn, ITableContextMenu, Table } from "components/Table";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { IAttribute } from "components/OverviewDataView";
import { Footer, IFooter } from "components/Footer";
import { IdSelector } from "components/TBD/IdSelector";
import { Typography } from "@mui/material";
import { Colors } from "components/Colors";
import { EditButton } from "components/Button";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { secTranColumns } from "./SecurityTransactionSearchMask";
import { getIncomeColumns } from "./IncomeTranSearch";
import { derTranColumns } from "./DerivateTransSearchMask";
import { CustomList } from "components/CustomList";
import { getAddFunction } from "components/VersionsView";
import { DERIVATE_TRAN_SECTION } from "./DerivateTransactionEdit";
import { INCOME_SECTION_IDENTIFIER, INCOME_TRAN_SECTION } from "./IncomeTransactionEdit";
import { securityTransactionIdentifier } from "./SecurityTransactionEdit";
import { CollapsePanel } from "components/CollapsePanel";

export const SECURITY_TAB: string = "Wertpapiere Transaktionen";
export const DERIVATE_TAB: string = "Derivate Transaktionen";
export const INCOME_TAB: string = "Erträge";

export const SPECIAL_ACC_TYPE: string = "Depot";

export const canceledRowColorScheme = (rowData: Record<string, string>): string => {
    const transactionType = rowData["transactionType"];
    const isStoTransaction = transactionType && transactionType.startsWith("sto");
    return isStoTransaction ? Colors.darkGrey : "";
};

export const TransactionOverview = (): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const params: Readonly<Params<string>> = useParams();
    const [accountId, setAccountId] = useState<string>(params.accountId ? params.accountId : "");

    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
    const [currentTabText, setCurrentTabText] = useState<string>(SECURITY_TAB);

    const [generalPersonData, setGeneralPersonData] = useState<Record<string, string>>({});
    const [positionData, setPositionData] = useState<Record<string, string>>({});

    const [securityList, setSecurityList] = useState<Record<string, string>[]>([]);
    const [derivateList, setDerivateList] = useState<Record<string, string>[]>([]);
    const [listSelected, setListSelected] = useState<boolean>(false);
    const [storeConcatData, setStoreConcatData] = useState<Record<string, string>>({});

    const accType: string = generalPersonData.accountType ?? "";

    const headline: IOverviewHeadLine = {
        title: "Kontostammdaten",
        useTimeSliceButtonForHistory: true,
        onTimeStampClick: () => navigate("/accountHistory/" + accountId),
    };
    const headlineForPostions: IOverviewHeadLine = {
        title: "Positionen",
        useTimeSliceButtonForHistory: false,
    };

    const attributes: IAttribute[] = [
        { field: "branch", headerName: "Filialnummer" },
        { field: "accountNr", headerName: accType === SPECIAL_ACC_TYPE ? "Depotnummer" : "Kontonummer" },
        { field: "fiscalUnit2AccountData.identifyingFeature", headerName: "Kontozusatz" },
        { field: "fiscalUnit2AccountData.fiscalUnit", headerName: "Zugeordnete FU" },
        { field: "fiscalUnit2AccountData.validFrom", headerName: "Gültig von", type: "date" },
    ];

    const positionAttributes: IAttribute[] = [
        { field: "nominal", headerName: "Bestandsnominal" },
        { field: "nominalCurrency", headerName: "Stk/Whrg" },
        { field: "lastExecDate", headerName: "Letzter Geschäftstag", type: "date" },
        { field: "instrumentType", headerName: "Instrumentenart" },
    ];

    const TFT_IDENTIFIER: TableidentifierType = "tftTrans";
    const TCG_IDENTIFIER: TableidentifierType = "tcgTrans";
    const DER_IDENTIFIER: TableidentifierType = "derTrans";

    const [tftData, setTftData] = useState<Record<string, string>[]>([]);
    const [tcgData, setTcgData] = useState<Record<string, string>[]>([]);
    const [derData, setDerData] = useState<Record<string, string>[]>([]);

    const footer: IFooter = {
        buttons: [
            { title: "Abbrechen", filled: false, onClick: () => navigate("/home") },
            { title: "Zurück", filled: false, onClick: () => navigate(-1) },
        ],
    };
    const concatObjects = (obj1: Record<string, string>, obj2: Record<string, string>): Record<string, string> => {
        return { ...obj1, ...obj2 };
    };

    const getOverviewDataForSecurityAndDerivative = (): React.JSX.Element => {
        return (
            <OverviewData
                headline={headlineForPostions}
                attributes={positionAttributes}
                columnNumber={5}
                data={positionData}
                width="60%"
            />
        );
    };
    const getTransactionOverviewTable = (
        contextMenu: ITableContextMenu,
        tableData: Record<string, string | number>[],
        columnsDefinition: ITableColumn[],
        identifier: TableidentifierType,
        addFunction: boolean,
        editSection: string
    ) => {
        return (
            <Table
                columnsDefinition={columnsDefinition}
                identifier={identifier}
                tableData={tableData}
                contextMenu={contextMenu}
                maxHeight={tableHeight + "px"}
                rowColorScheme={canceledRowColorScheme}
                isExportable
                showEditPendingStateIcons
                addFunction={addFunction ? getAddFunction(editSection, undefined, storeConcatData) : undefined}
                key={tableData.length}
            />
        );
    };
    const getOverviewData = (
        list: Record<string, string>[],
        fields: string[],
        onListClick: (item: Record<string, string>, selected: boolean) => void
    ) => (
        <>
            {getOverviewDataForSecurityAndDerivative()}

            <CollapsePanel
                label={list === securityList ? "Wertpapiere" : "Derivate"}
                collapseable={
                    <CustomList
                        maxHeight={tableHeight + "px"}
                        data={list}
                        fields={fields}
                        joinFieldsWithChar="/"
                        onListClick={onListClick}
                    />
                }
                content={getTransactionOverviewTable(
                    list === securityList ? contextMenus[SECURITY_TAB] : contextMenus[DERIVATE_TAB],
                    list === securityList ? tcgData : derData,
                    list === securityList ? secTranColumns : derTranColumns,
                    list === securityList ? TCG_IDENTIFIER : DER_IDENTIFIER,
                    listSelected,
                    list === securityList ? securityTransactionIdentifier + "/" + SECURITY_TAB : DERIVATE_TRAN_SECTION
                )}
            />
        </>
    );
    useEffect(() => {
        sendGetRequest({
            path: "/customerstaticdata/account/getAccount",
            params: { accountId: Number(accountId) },
        }).then((response) => {
            console.table(response.data);
            setGeneralPersonData(response.data);
        });
        sendGetRequest({
            path: "/cgresources/fttransactions/income-with-stoincome-transactions",
            params: { accountId: Number(accountId) },
        }).then((response) => setTftData(response.data));
        sendGetRequest({
            path: "/cgresources/cgtransactions/sec-with-stosec-transactions",
            params: { accountId: Number(accountId) },
        }).then((response) => setTcgData(response.data));
        sendGetRequest({
            path: "/cgresources/cgtransactions/der-with-stoder-transactions",
            params: { accountId: Number(accountId) },
        }).then((response) => setDerData(response.data));
        sendGetRequest({
            path: "/security/securities/fromSecTransactionByAccountId",
            params: { accountId: +accountId },
        }).then((response) => {
            setSecurityList(response.data);
        });
        sendGetRequest({
            path: "/security/derivatives/fromDerTransactionByAccountId",
            params: { accountId: +accountId },
        }).then((response) => {
            setDerivateList(response.data);
        });
    }, [accountId]);

    const [tableHeight, setTableHeight] = useState<number>();

    const calculateTableHeight = () => {
        const appBarElement: HTMLElement = document.getElementById("appBar") as HTMLElement;
        let appBarHeight: number = 0;
        if (appBarElement) {
            appBarHeight = appBarElement.getBoundingClientRect().height;
        }

        const tableToolBarElement: HTMLElement = document.getElementsByClassName("MuiToolbar-root")[0] as HTMLElement;
        let tableToolBarHeight: number = 0;
        if (tableToolBarElement) {
            tableToolBarHeight = tableToolBarElement.getBoundingClientRect().height;
        }

        const tabElement: HTMLElement = document.getElementsByClassName(
            "MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary Mui-selected"
        )[0] as HTMLElement;
        let tabElementHeight: number = 0;
        if (tabElement) {
            tabElementHeight = tabElement.getBoundingClientRect().y + tabElement.getBoundingClientRect().height;
        }

        const tableFooterElement: HTMLElement = document.getElementsByClassName(
            "MuiTableFooter-root"
        )[0] as HTMLElement;
        let tableFooterHeight: number = 0;
        if (tableFooterElement) {
            tableFooterHeight = tableFooterElement.getBoundingClientRect().height;
        }

        const tableWrapperElement: HTMLElement = document.getElementById("tableWrapper") as HTMLElement;
        let marginHeight: number = 0;
        if (tableWrapperElement) {
            //multiplied by 2 for top and bottom margin
            marginHeight = 2 * Number(window.getComputedStyle(tableWrapperElement).margin.replaceAll("px", ""));
        }

        const footerElement: HTMLElement = document.getElementById("footer") as HTMLElement;
        let footerHeight: number = 0;
        if (footerElement) {
            footerHeight = footerElement.children[0].getBoundingClientRect().height;
        }

        const UNSELECTABLE_SPACING: number = 69;

        //max window height before scrollbar
        const height = window.innerHeight * (window.innerHeight / document.body.offsetHeight);

        setTableHeight(
            height -
                appBarHeight -
                marginHeight -
                tableToolBarHeight -
                tabElementHeight -
                tableFooterHeight -
                UNSELECTABLE_SPACING -
                footerHeight
        );
    };

    useEffect(() => {
        window.addEventListener("resize", calculateTableHeight);
        return () => {
            window.removeEventListener("resize", calculateTableHeight);
        };
    }, [calculateTableHeight]);

    useEffect(() => {
        calculateTableHeight();
    }, []);

    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});
    const onListClickForSecurity = (item: Record<string, string>, selected: boolean) => {
        setStoreConcatData(concatObjects(generalPersonData, item));
        if (selected) {
            setListSelected(true);
            sendGetRequest({
                path: "/cgresources/cgtransactions/spekuPositionByAccountIdAndInstrumentId",
                params: { accountId: +accountId, instrumentId: +item.id },
            }).then((response) => {
                setPositionData(response.data);
            });
            sendGetRequest({
                path: "/cgresources/cgtransactions/sec-with-stosec-transactions-bySecurityIdAndAccountId",
                params: { securityId: +item.id, accountId: +accountId },
            }).then((response) => {
                setTcgData(response.data);
            });
        } else {
            setPositionData({});
            setListSelected(false);
            sendGetRequest({
                path: "/cgresources/cgtransactions/sec-with-stosec-transactions",
                params: { accountId: Number(accountId) },
            }).then((response) => setTcgData(response.data));
        }
    };
    const onListClickForDerivate = (item: Record<string, string>, selected: boolean) => {
        if (selected) {
            setListSelected(true);
            setStoreConcatData(concatObjects(generalPersonData, item));

            sendGetRequest({
                path: "/cgresources/cgtransactions/der-with-stoder-transactions-byDerivativeIdAndAccountId",
                params: { derivativeId: +item.id, accountId: +accountId },
            }).then((response) => {
                setDerData(response.data);
            });
            sendGetRequest({
                path: "/cgresources/cgtransactions/spekuPositionByAccountIdAndInstrumentId",
                params: { accountId: +accountId, instrumentId: +item.id },
            }).then((response) => {
                setPositionData(response.data);
            });
        } else {
            setPositionData({});
            setListSelected(false);

            sendGetRequest({
                path: "/cgresources/cgtransactions/der-with-stoder-transactions-byDerivativeIdAndAccountId",
                params: { derivativeId: +item.id, accountId: +accountId },
            }).then((response) => {
                setDerData(response.data);
            });
        }
    };
    const securityFields: string[] = ["isin", "shortName"];
    const derivateFields: string[] = ["derivativeCode", "shortName"];

    return (
        <div style={{ margin: "1.875rem 4rem" }}>
            <IdSelector label="Account Id" setId={setAccountId} />
            <Typography sx={{ color: Colors.borderDark, fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                {accType}
                <EditButton />
            </Typography>
            <OverviewData
                headline={headline}
                attributes={attributes}
                columnNumber={5}
                data={generalPersonData}
                width="50%"
            />
            <TabContext value={currentTabIndex.toString()}>
                <TabList
                    onChange={(event: SyntheticEvent<Element, Event>, newIndex: number) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        setCurrentTabText(((event.target as HTMLElement).firstChild as any).data);
                        setCurrentTabIndex(newIndex);
                    }}
                >
                    <Tab label={SECURITY_TAB} value="0" />
                    <Tab label={DERIVATE_TAB} value="1" />
                    <Tab label={INCOME_TAB} value="2" />
                </TabList>

                <TabPanel value="0">{getOverviewData(securityList, securityFields, onListClickForSecurity)}</TabPanel>
                <TabPanel value="1">{getOverviewData(derivateList, derivateFields, onListClickForDerivate)}</TabPanel>
                <TabPanel value="2">
                    {getTransactionOverviewTable(
                        contextMenus[INCOME_TAB],
                        tftData,
                        getIncomeColumns(accType),
                        TFT_IDENTIFIER,
                        false,
                        INCOME_SECTION_IDENTIFIER + "/" + INCOME_TRAN_SECTION
                    )}
                </TabPanel>
            </TabContext>
            <Footer buttons={footer.buttons} />

            <TransactionContextMenu
                currentTabText={currentTabText}
                contextMenus={contextMenus}
                setContextmenus={setContextMenus}
            />
        </div>
    );
};
