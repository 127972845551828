/* author: SindhuKannan */
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, Table } from "components/Table";
import { ComboBox, createComboBoxOptions } from "components/ComboBox";
import { useEffect, useState } from "react";
import { SelectChangeEvent, Typography } from "@mui/material";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import { flattenJson } from "components/Converter";
import { Footer, IFooterButton } from "components/Footer";
import { kistamDenominationShorten } from "pages/customer-static-data/KistamVersions";

export const FsaNvSbCalculationpotsDetails = (): React.JSX.Element => {
    const IDENTIFIER: TableidentifierType = "fsaNvSbCalculationpots";

    const location = useLocation().state;
    const navigate: NavigateFunction = useNavigate();
    const origin = location.origin ? location.origin : "";
    const data = location.data ? location.data : {};
    const extradata = location.extraData ? location.extraData : {};
    const footerButtons: IFooterButton[] = [
        { title: "Abbrechen", filled: false, onClick: () => navigate("/home") },
        {
            title: "Zurück",
            filled: false,
            onClick: () => navigate("/otherProcessing", { state: { origin: origin } }),
        },
    ];

    const columns: ITableColumn[] = [
        { field: "taxBaseData.accountData.branch", title: "Filialnummer" },
        { field: "taxBaseData.accountData.accountNr", title: "Kontonummer" },
        { field: "taxBaseData.accountData.accountType", title: "Kontoart" },
        {
            field: "taxBaseData.secTranData.extTranKey",
            title: "Umsatzreferenz",
            combinedFields: ["taxBaseData.derTranData.extTranKey", "taxBaseData.incomeTranData.extTranKey"],
            combinedFieldsSeparator: "",
        },
        {
            field: "taxBaseData.stoSecTranData.extTranKey",
            title: "Umsatzreferenz des ursprünglichen Umsatzes",
            combinedFields: ["taxBaseData.stoDerTranData.extTranKey", "taxBaseData.stoIncomeTranData.extTranKey"],
            combinedFieldsSeparator: "",
        },
        {
            field: "taxBaseData.secTranData.securityData.secCode",
            title: "Instrument(WKN/ISIN,Derivat)",
            combinedFields: [
                "taxBaseData.derTranData.derivativeData.derivativeCode",
                "taxBaseData.incomeTranData.securityData.secCode",
            ],
            combinedFieldsSeparator: "",
        },
        {
            field: "taxBaseData.secTranData.securityData.shortName",
            title: "Kurzbezeichnung",
            combinedFields: [
                "taxBaseData.derTranData.derivativeData.shortName",
                "taxBaseData.incomeTranData.securityData.shortName",
            ],
            combinedFieldsSeparator: "",
        },
        {
            field: "taxBaseData.secTranData.nominal",
            title: "Umsatz",
            combinedFields: ["taxBaseData.derTranData.nominal", "taxBaseData.incomeTranData.nominal"],
            combinedFieldsSeparator: "",
        },
        {
            field: "taxBaseData.secTranData.executeTime",
            title: "Ausführ.-DT",
            combinedFields: ["taxBaseData.derTranData.executeTime", "taxBaseData.incomeTranData.relDate"],
            combinedFieldsSeparator: "",
            type: "date",
        },
        {
            field: "insertTS",
            title: "Steuerliches Verarbeitungsdatum",
            type: "date",
        },
        {
            field: "taxBaseData.differentTaxTime",
            title: "Steuerliches Zuflussdatum",
            type: "date",
        },
        {
            field: "derTranData.externVoucherKey",
            title: "Ext. Beleg-Nr.",
            combinedFields: ["secTranData.externVoucherKey"],
            combinedFieldsSeparator: "",
        },
        { field: "fsaNvIdentificationData.identification", title: "FSA/NV" },
        { field: "taxBaseData.yieldId", title: "Ertrags-Id" },
        { field: "corrRefYieldId", title: "Referenz Ertrags-Id" },
        { field: "exemptYieldFsa", title: "durch FSA freigestellter Ertrag" },
        { field: "exemptYieldNv", title: "durch NV freigestellter Ertrag" },
        { field: "fsaNvBalanceData.availmentNv", title: "durch NV Freibetrag freigestellter Ertrag" },
        { field: "exemptYieldNonResident", title: "durch Steuerausländereigenschaft freigestellter Ertrag" },
        { field: "exemptYieldLegalFormCategory", title: "durch Rechtsform freigestellter Ertrag" },
        { field: "exemptYieldDoubleTaxation", title: "durch Doppelbesteuerungsabkommen freigestellter Ertrag " },
        {
            field: "paidOffsetableTaxLoss",
            title: "Gesamtsumme der auf Fondsseite bereits angerechneten anrechenbaren Quest ",
        },
        { field: "offsetableTaxLoss", title: "verrechnete Quest" },
        { field: "taxBaseData.offsetableTax", title: "anrechenbare Quest" },
        {
            field: "taxBaseData.foDivOffsetTax",
            title: "Bereits vom Fonds anger. Quest auf ausl. Div. inkl. Veräußerunsgewinne und Termingeschäften  ",
        },
        { field: "taxBaseData.interestOffsetTax", title: "Bereits vom Fonds anger. Quest auf Zinsen" },
        { field: "taxBaseData.domDivOffsetTax", title: "Bereits vom Fonds anger. Quest auf inl. Div." },
        { field: "totalShareProfit", title: "Änderung des Saldos Aktiengewinn" },
        { field: "totalShareLoss", title: "Änderung des Saldos Aktienverlust" },
        { field: "totalOtherProfit", title: "Änderung des Saldos sonstiger Gewinn" },
        { field: "totalOtherLoss", title: "Änderung des Saldos sonstiger Verlust" },
        { field: "totalTerminLoss", title: "Änderung des Saldos Verlust/Termingeschäfte" },
        { field: "total20_6_6Loss", title: "Änderung des Saldos Verlust nach §20 Absatz 6 Satz 6 EStG" },
        { field: "dwtBefChurchTax", title: "AgSt vor Kirchensteuer" },
        { field: "dwtAllowance", title: "vergütete AgSt" },
        { field: "soliAllowance", title: "vergüteter Solibeitrag" },
        { field: "dwtPerson1", title: "AgSt Person 1" },
        { field: "dwtPerson2", title: "AgSt Person 2" },
        { field: "soliPerson1", title: "Solidaritätszuschlag Person 1" },
        { field: "soliPerson2", title: "Solidaritätszuschlag Person 2" },
        { field: "churchTaxPerson1", title: "Kirchensteuer Person 1" },
        { field: "churchTaxPerson2", title: "Kirchensteuer Person 2" },
        { field: "dwtTaxRatePerson1", title: "Steuersatz Person 1" },
        { field: "dwtTaxRatePerson2", title: "Steuersatz Person 2" },
        { field: "denominationPerson1", title: "Konfession Person 1", filter: kistamDenominationShorten },
        { field: "denominationPerson2", title: "Konfession Person 2", filter: kistamDenominationShorten },
        { field: "federalStateDwt", title: "Bundesland für Abführung KEST" },
        { field: "ratioGrossYieldPerson1", title: "Anteil am Ertrag Person 1" },
        { field: "person1.activeFiscalUnit2PersonData.type", title: "Type Person 1" },
        { field: "person2.activeFiscalUnit2PersonData.type", title: "Typ Person 2" },
        { field: "ratioGrossYieldPerson2", title: "Anteil am Ertrag Person 2" },
        { field: "dwtByBank", title: "KeSt auf Ertrag mit Zahlstellenabzug" },
        { field: "dwtSoliByBank", title: "Soli auf KeSt auf Ertrag mit Zahlstellenabzug" },
        { field: "taxBaseData.cancellationFlag", title: "StoKZ" },
        { field: "taxBaseData.grossYieldCash", title: "Ausschüttung/Gewinn" },
        { field: "evvrShareLoss", title: "durch eVVR übertragener Aktienverlust" },
        { field: "evvrOtherLoss", title: "durch eVVR übertragener sonstiger Verlust" },
        { field: "evvrOffsetableTax", title: "durch eVVR übertragene Quellensteuer" },
        { field: "evvrTaxableBaseDwt", title: "Bemessungsgrundlage nach FSA und eVVR-Überträgen" },
        { field: "evvrCredOffsetableTax", title: "angerechnete Quellensteuer nach eVVR" },
        { field: "statusCertDwt", title: "AgSt Fondseingangsseite" },
        { field: "statusCertSoliSurchrg", title: "Solidaritätszuschlag Fondseingangsseite" },
        { field: "exemptStatusCert", title: "durch Statusbescheinigung freigestellter Ertrag" },
        { field: "exemptParagraph10", title: "Durch §10(InvStG) freigestellter Ertrag" },
    ];

    const CURRENT_TAX_YEAR: string = new Date().getFullYear().toString();

    const createTableData = (year: string) => {
        if (!data[year]) {
            return [];
        }

        const temp: Record<string, string>[] = [];
        data[year].forEach((res: []) => {
            temp.push(flattenJson(res));
        });
        return temp;
    };

    const [fsaNvSbCalculationpotsDetailsData, setFsaNvSbCalculationpotsDetailsData] = useState<
        Record<string, string>[]
    >(createTableData(CURRENT_TAX_YEAR));

    const [taxYear, setTaxYear] = useState<string | undefined>(undefined);

    const [tableHeight, setTableHeight] = useState<number>();

    const calculateTableHeight = () => {
        const appBarElement: HTMLElement = document.getElementById("appBar") as HTMLElement;
        let appBarHeight: number = 0;
        if (appBarElement) {
            appBarHeight = appBarElement.getBoundingClientRect().height;
        }

        const tableToolBarElement: HTMLElement = document.getElementsByClassName("MuiToolbar-root")[0] as HTMLElement;
        let tableToolBarHeight: number = 0;
        if (tableToolBarElement) {
            tableToolBarHeight = tableToolBarElement.getBoundingClientRect().height;
        }

        const comboboxElement: HTMLElement = document.getElementsByClassName(
            "MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input"
        )[0] as HTMLElement;
        let comboboxElementHeight: number = 0;
        if (comboboxElement) {
            comboboxElementHeight =
                comboboxElement.getBoundingClientRect().y + comboboxElement.getBoundingClientRect().height;
        }

        const tableFooterElement: HTMLElement = document.getElementsByClassName(
            "MuiTableFooter-root"
        )[0] as HTMLElement;
        let tableFooterHeight: number = 0;
        if (tableFooterElement) {
            tableFooterHeight = tableFooterElement.getBoundingClientRect().height;
        }

        const tableWrapperElement: HTMLElement = document.getElementById("tableWrapper") as HTMLElement;
        let marginHeight: number = 0;
        if (tableWrapperElement) {
            //multiplied by 2 for top and bottom margin
            marginHeight = 2 * Number(window.getComputedStyle(tableWrapperElement).margin.replaceAll("px", ""));
        }

        const footerElement: HTMLElement = document.getElementById("footer") as HTMLElement;
        let footerHeight: number = 0;
        if (footerElement) {
            footerHeight = footerElement.children[0].getBoundingClientRect().height;
        }

        const UNSELECTABLE_SPACING: number = 0;

        //max window height before scrollbar
        const height = window.innerHeight * (window.innerHeight / document.body.offsetHeight);

        setTableHeight(
            height -
                appBarHeight -
                marginHeight -
                tableToolBarHeight -
                comboboxElementHeight -
                tableFooterHeight -
                UNSELECTABLE_SPACING -
                footerHeight
        );
    };

    useEffect(() => {
        window.addEventListener("resize", calculateTableHeight);
        return () => {
            window.removeEventListener("resize", calculateTableHeight);
        };
    }, [calculateTableHeight]);

    useEffect(() => {
        calculateTableHeight();
    }, []);

    const getTitle = (): string => {
        let title: string = origin;

        if (
            fsaNvSbCalculationpotsDetailsData[0] &&
            fsaNvSbCalculationpotsDetailsData[0]["fsaNvIdentificationData.identification"]
        ) {
            title += " " + fsaNvSbCalculationpotsDetailsData[0]["fsaNvIdentificationData.identification"];
        }

        title += " zu Steuerlicher Einheit " + extradata["fiscalUnitData.fiscalUnit"];

        return title;
    };

    return (
        <div style={{ margin: "4rem" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>{getTitle()}</Typography>
            <ComboBox
                label="Steuerjahr"
                onChange={(event: SelectChangeEvent<string>) => {
                    setTaxYear(event.target.value);

                    setFsaNvSbCalculationpotsDetailsData(createTableData(event.target.value));
                }}
                options={createComboBoxOptions(Object.keys(data))}
                value={taxYear}
                defaultValue={CURRENT_TAX_YEAR}
                optionIdentifier="label"
                optionValue={["label"]}
                width="30%"
            />

            <Table
                columnsDefinition={columns}
                identifier={IDENTIFIER}
                tableData={fsaNvSbCalculationpotsDetailsData}
                pageSizeOptions="alternativ"
                maxHeight={tableHeight + "px"}
            />

            <div id="footer">
                <Footer buttons={footerButtons} />
            </div>
        </div>
    );
};
