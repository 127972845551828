/* author: Aashish Upadhyay  */

import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, Table } from "./Table";
import { Footer, IFooter } from "./Footer";
import { NavigateFunction, useNavigate } from "react-router";
import { ConvertType, convert } from "./Converter";
import { useEffect, useState } from "react";
import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Loader } from "./Loader";

export interface IHistoryConverter {
    title: string;
    labels: IHistoryKeys[];
    dataRequest: AllowedRequestsTypeGet;
    identifier: TableidentifierType;
}

export interface IHistoryKeys {
    field: string;
    label: string;
    filter?: (option: string) => string;
    type?: ConvertType;
}

export interface IHistoryResponse {
    data: Record<string, string | number>;
    historyData: Record<string, string | number>[];
}

export const HistoryView = (props: IHistoryConverter): React.JSX.Element => {
    const [historyData, setsHistoryData] = useState<IHistoryResponse>({ data: {}, historyData: [] });
    const [isFetching, setIsFetching] = useState<boolean>(true);

    //extend labels with common columns
    const labels: IHistoryKeys[] = [...props.labels];
    labels.unshift(
        { field: "changeUser", label: "Ändernder Benutzer (Historie)" },
        { field: "releaseUser", label: "Freigebender Benutzer (Historie)" },
        { field: "histValidTo", label: "Gültig bis (Historie)", type: "date" }
    );
    labels.push(
        { field: "insertTS", label: "Einfügedatum", type: "datetime" },
        { field: "updateTS", label: "Aktualisierungsdatum", type: "datetime" }
    );

    useEffect(() => {
        setIsFetching(true);
        props.dataRequest &&
            sendGetRequest(props.dataRequest).then((response) => {
                setsHistoryData(response.data);
                setIsFetching(false);
            });
    }, []);

    const navigate: NavigateFunction = useNavigate();
    const tableData: Record<string, string | number>[] = [];
    const columns: ITableColumn[] = [
        { title: "Feld", field: "label" },
        { title: "", field: "data" },
    ];
    const footer: IFooter = {
        buttons: [
            {
                title: "Zurück",
                filled: false,
                onClick: () => navigate(-1),
            },
        ],
    };

    let rowList: string[];

    //Filters unwanted keys present in endpoint
    const filterFieldList = (fieldList: string[]): string[] => {
        const ignoreFields: string[] = ["id", "type"];
        return fieldList.filter((f: string) => {
            return !ignoreFields.includes(f);
        });
    };

    const applyFilterAndConvert = (row: string, value: string | number): string => {
        let ret: string = String(value ?? "");
        labels.forEach((key: IHistoryKeys) => {
            if (key.field === row) {
                if (key.filter) ret = key.filter(ret);
                if (key.type) ret = convert(ret, key.type);
            }
        });

        return ret;
    };

    if (historyData.data && Object.keys(historyData.historyData).length > 0) {
        //Gets all the keys from ProductionData
        rowList = Object.keys(historyData.data);

        //Gets unique keys other than already available in fieldList from HistoryData
        historyData.historyData.forEach((item: Record<string, string | number>) => {
            const keys: string[] = Object.keys(item);
            keys.forEach((key: string) => {
                if (!rowList.includes(key)) {
                    rowList.push(key);
                }
            });
        });

        rowList = filterFieldList(rowList);

        //Adds Dynamic History Columns to Column definition
        for (let i = 0; i < Object.keys(historyData.historyData).length; i++) {
            columns.push({ title: "", field: "hist" + i });
        }

        const tableColumns: string[] = [];

        columns.forEach((col: ITableColumn) => {
            tableColumns.push(col.field);
        });

        //Gets the labels of the matching fields
        const matchingLabels = (fieldName: string) => {
            console.log(labels);
            const x = labels.filter((label: { field: string }) => label.field === fieldName);
            return x.length === 1 ? x[0].label : "UNKNOWN LABEL";
        };

        //The final data to be displayed in table
        rowList.forEach((row: string) => {
            const obj: Record<string, string | number> = {};
            tableColumns.forEach((column: string, index: number) => {
                if (index === 0) {
                    obj[column] = matchingLabels(row);
                } else if (index === 1) {
                    const value: string | number = historyData.data[row];
                    obj[column] = applyFilterAndConvert(row, value);
                } else {
                    const value: string | number = historyData.historyData[index - 2][row];
                    obj[column] = applyFilterAndConvert(row, value);
                }
            });
            tableData.push(obj);
        });
    }

    return (
        <div style={{ margin: "4rem" }}>
            {isFetching ? (
                <Loader />
            ) : (
                <Table
                    title={props.title}
                    columnsDefinition={columns}
                    tableData={tableData}
                    identifier={props.identifier}
                    disableHideColumn
                />
            )}
            <Footer buttons={footer.buttons} />
        </div>
    );
};
