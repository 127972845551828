/* author: SINDHU KANNAN */
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const FiscalUnitSearchMask = (): React.JSX.Element => {
    const column: ITableColumn[] = [{ field: "fiscalUnit", title: "Steuerliche Einheit" }];
    const searchCriteria: ISearchCriteria[] = [{ field: "fiscalUnit", label: "Steuerliche Einheit*" }];
    const searchUrl: ISearchUrl[] = [
        { path: "/customerstaticdata/fiscalUnit/fiscalUnitSearch", requiredParams: ["fiscalUnit"] },
    ];

    return (
        <Search
            title={"Steuerliche Einheit - Suche"}
            searchCriterias={searchCriteria}
            columns={column}
            searchUrls={searchUrl}
            linkto={"/overviewFu/"}
            linktoIdentifier={"fiscalUnitId"}
            identifier="fiscalUnitSearch"
            hideTechnicalColumns
        />
    );
};
