/* author: Aashish Upadhyay */

import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { KeyTableConsts } from "components/KeyTableConsts";
import { Params, useParams } from "react-router";

export const FKB_REQUEST_SECTION: string = "Kontrollmitteilung FKB";

export const FkbRequestEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const requestId: number = Number(params.requestId);
    const section: string | undefined = params.section;

    const fkbRequestEditInfo: IEditDataViewUserConfiguration = {
        headline: FKB_REQUEST_SECTION,
        attributes: [
            {
                field: "requestType",
                headerName: "Auftragsart",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "REQ_TYPE",
                },
            },
            {
                field: "clientData.clientId",
                headerName: "Mandant",
                dropdown: {
                    keyTableTabname: KeyTableConsts.CLIENT,
                },
            },
            { field: "fromDate", headerName: "Zeitraum von", type: "date" },
            { field: "toDate", headerName: "Zeitraum bis", type: "date" },
            {
                field: "source",
                headerName: "Quelle",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "REQ_SOURCE",
                },
            },
            {
                field: "execState",
                headerName: "Auftragstatus",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "REQ_STATE",
                },
            },
            { field: "insertTS", headerName: "Erfassungsdatum", type: "datetime", displayOnly: true },
        ],
        latestVersionRequest: () =>
            sendGetRequest({ path: "/request/yer/corpcustomer", params: { requestId: requestId } }),
        versionRequest: (() => undefined) as () => unknown as () => GET_REQUEST_TYPE,
    };

    return (
        <EditMask
            completeEditInfo={[fkbRequestEditInfo]}
            id01={requestId}
            overrideDeleteLevel={2}
            title="FKB-Meldungsaufträge bearbeiten"
            section={section}
            parentIdentifier=" " //The parent is not defined and is -1 on Database
            overrideApprovalLevel={2}
        />
    );
};
