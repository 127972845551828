/* author: Prashant Pal */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { flattenJson } from "components/Converter";
import { DetailviewCard, IDetailviewCard } from "components/DetailviewCard";
import { PopUp } from "components/PopUp";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface ISalesDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    extTranKey: string;
}
export const SalesDetails = (props: ISalesDetails): React.JSX.Element => {
    const [salesData, setSalesData] = useState<Record<string, string>>({});

    useEffect(() => {
        sendGetRequest({
            path: "/cgresources/cgtransactions/sectransaction",
            params: { extTranKey: props.extTranKey },
        }).then((response) => {
            setSalesData(flattenJson(response.data));
        });
    }, [props.extTranKey]);

    const detailViewInTwoParts: IDetailviewCard = {
        icon: null,
        dataKeys: [
            [
                { field: "securityData.wkn", label: "WKN/ISIN" },
                { field: "securityData.shortName", label: "Kurzbezeichnung" },
                { field: "externBusinessReference", label: "Transaktionsreferenz" },
                { field: "extTranKey", label: "Umsatzreferenz" },
                { field: "extEvent", label: "Geschäftsart" },
                { field: "extEventGroup", label: "Vorgangsgr.-Schlüssel" },
                { field: "taxEvent", label: "StGA" },
                { field: "nominal", label: "Umsatz" },
                { field: "refNominal", label: "Referenznominal" },
                { field: "nomCur", label: "Stk/Whrg" },
                { field: "tradeMarketValue", label: "Kurswert Handels Whrg" },
                { field: "tradeCur", label: "Handels Whrg" },
                { field: "tradeAddExpCost", label: "NK Abr.-Whrg" },
                { field: "tradeExchRate", label: "DevKurs Handels-Whrg" },
                { field: "settlCur", label: "Abr.-Whrg" },
                { field: "settlExchRate", label: "DevKurs Abr.-Whrg" },
                { field: "settlMarketValue", label: "Kurswert Abr.-Whrg" },
                { field: "settlAddExpCost", label: "Anschaff. NK Abr.-Whrg" },
                { field: "marketValueEur", label: "Gegenwert EUR" },
                { field: "fractionPaymentT", label: "Spitzenausgleich in Handelswährung" },
                { field: "fractionPaymentE", label: "Spitzenausgleich in EUR" },
                { field: "fractionPaymentS", label: "Spitzenausgleich in Abrechnungswährung" },
                { field: "retProcFundT", label: "ID905 Thes. Ertr. Handels Whrg" },
                { field: "retProcFundS", label: "ID905 Thes. Ertr. Abr.-Whrg" },
                { field: "retProcFundE", label: "ID905 Thes. Ertr. EUR" },
                { field: "overCounterFlag", label: "Tafelgeschäft" },

                { field: "estProfitFundT", label: "Immobiliengewinn Handels Whrg" },

                { field: "estProfitFundS", label: "Immobiliengewinn Abr.-Whrg" },
                { field: "estProfitFundE", label: "Immobiliengewinn EUR" },
                { field: "refDelSystem", label: "Liefersystem" },
                {
                    field: "executionTime",
                    label: "Ausfüh.-DT",
                    type: "date",
                },
                {
                    field: "valutA",
                    label: "Valuta",
                    type: "date",
                },
                {
                    field: "taxBusinessDay",
                    label: "Steuer-DT",
                    type: "date",
                },
                {
                    field: "bookingDate",
                    label: "Buch-DT",
                    type: "date",
                },
                {
                    field: "instructionDate",
                    label: "Weisungsdatum",
                    type: "date",
                },
                {
                    field: "settlValueDate",
                    label: "Stückevalutadatum",
                    type: "date",
                },
                { field: "reinvestValueT", label: "Wiederanl.wert Handels-Whrg " },
                { field: "subPriceE", label: "Bezugspreis EUR" },
                { field: "subPriceValT", label: "Wert BZR/OS Handels-Whrg" },

                // { field: "subscriptRightValue", label: "BZR/OS Handels Whrg" },
                { field: "detachRatioN", label: "Trennverh. Zähler" },
                { field: "detachRatioD", label: "Trennverh. Nenner" },
                { field: "subscriptRatioN", label: "Bezugsverh. Zähler" },
                { field: "subscriptRatioD", label: "Bezugsverh. Nenner" },
                { field: "switchRatioN", label: "Umtauschverh. Zähler" },
            ],
            [
                { field: "switchRatioD", label: "Umtauschverh. Nenner" },
                { field: "valueRatioN", label: "Tilgungsfaktor alt" },
                { field: "valueRatioD", label: "Tilgungsfaktor neu" },
                {
                    field: "detachDate",
                    label: "Trenntermin",
                    type: "date",
                },
                {
                    field: "exDate",
                    label: "Extag",
                    type: "date",
                },
                { field: "valuationEur", label: "Bewert-BT am Bew.-DT EUR" },
                {
                    field: "valuationDate",
                    label: "Bewertungs-Datum",
                    type: "date",
                },
                {
                    field: "acqValuationDate",
                    label: "Tag des ermittelten Wertpapierkurses",
                    type: "date",
                },
                { field: "valuationS", label: "Bewertung am Bewertungstag in Abr.-Whrg" },
                { field: "cashCompS", label: "Barausgleich Abr.-Whrg" },
                { field: "marketValueOld", label: "Kurs Altaktie" },
                {
                    field: "marketValueDate",
                    label: "DT zu Kurs Altaktien",
                    type: "date",
                },
                { field: "equiCashDiviE", label: "Gegenwert Bardividende EUR" },
                { field: "execState", label: "Ausführungsstatus" },
                { field: "capIncomeS", label: "Einkünfte aus KAP" },
                { field: "interimGainT", label: "Zwischengewinn Handels Whrg" },
                { field: "interimGainS", label: "Zwischengewinn Abr.-Whrg" },
                { field: "interimGainE", label: "Zwischengewinn EUR" },
                { field: "nonCashBenefitS", label: "Geldwerter Vorteil Abr.-Whrg" },
                { field: "nonCashBenefitT", label: "Geldwerter Vorteil Handels Whrg" },
                { field: "nonCashBenefitE", label: "Geldwerter Vorteil EUR" },
                { field: "accruedInterestT", label: "Stückzinsen Abr.-Whrg" },
                { field: "accruedInterestS", label: "Stückzinsen Handels Whrg" },
                { field: "accruedInterestE", label: "Stückzinsen EUR" },
                { field: "rightValueExcRate", label: "DevKurs Whrg BZR/OS" },
                { field: "rightValueCur", label: "Whrg BZR/OS" },
                { field: "bidData.bidId", label: "externe BID" },
                { field: "manualCheckRequired", label: "Man. Prüf." },
                { field: "retProcEstEur", label: "ID917 Schätzwert fehlende aussch. gl. Erträge" },
                { field: "retProcOldProfitEur", label: "ID919 Akk. ausgesch. Altveräußerungs-gewinne" },
                { field: "retProcSubstEur", label: "ID920 Akk. Substanz-ausschüttung" },
                { field: "retProcAdjEur", label: "ID921 Bereinigte akkumulierte aussch. gl. Erträge" },
                { field: "nullIndicator", label: "Null-Indikator" },
                {
                    field: "ntrlMergerFundsDate",
                    label: "Datum steuerneutrale Fondsfusion",
                    type: "date",
                },
                {
                    field: "diffEnrichmentDate",
                    label: "Abweichendes Anreicherungsdatum für ID-Felder",
                    type: "date",
                },
                { field: "id905AutoEnrichment", label: "ID905 KZ auto. Anreicherung" },
                { field: "id909AutoEnrichment", label: "ID909 KZ auto. Anreicherung" },
                { field: "id917AutoEnrichment", label: "ID917 KZ auto. Anreicherung" },
                { field: "id919AutoEnrichment", label: "ID919 KZ auto. Anreicherung" },
                { field: "id920AutoEnrichment", label: "ID920 KZ auto. Anreicherung" },
                { field: "id921AutoEnrichment", label: "ID921 KZ auto. Anreicherung" },
                { field: "gv512AutoEnrichment", label: "GV512 KZ auto. Anreicherung" },
                { field: "comment", label: "Kommentar" },
            ],
        ],
        data: salesData,
    };
    return (
        <PopUp
            setOpen={props.setOpen}
            title={"Umsatz für Depotnummer: " + props.extTranKey}
            content={
                <DetailviewCard
                    icon={detailViewInTwoParts.icon}
                    dataKeys={detailViewInTwoParts.dataKeys}
                    data={detailViewInTwoParts.data}
                />
            }
        />
    );
};
