/* author: Aashish Upadhyay*/

import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params, useParams } from "react-router";
import { isYerEditable } from "./YerRequestEdit";

export const VALUE_SECTION: string = "Bescheinigungsarten";

const YerRequestEdit = (type: "single" | "mass"): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const requestId: number = Number(params.requestId);
    const section: string | undefined = params.section;

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({ path: "/request/yer/single/value/byId-with-keydata", params: { requestId: requestId } });

    const valueDetailsEditInfo: IEditDataViewUserConfiguration = {
        headline: VALUE_SECTION,
        attributes: [
            {
                field: "value",
                headerName: "Bescheinigungsarten",
                dropdown: {
                    keyTableTabname: "REQ_VALUE",
                    disableNonEditableOptions: true,
                },
                disabled: isYerEditable,
            },
        ],
        tabName: "Bescheinigungsart",
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [valueDetailsEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={requestId}
            title={(type === "single" ? "Einzel" : "Masssen") + "aufträge bearbeiten"}
            section={section}
        />
    );
};

export const YerSingleValueEdit = (): React.JSX.Element => YerRequestEdit("single");
export const YerMassValueEdit = (): React.JSX.Element => YerRequestEdit("mass");
