/* author: PAL Prashant */
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab, Typography } from "@mui/material";
import { ButtonPrimary } from "components/Button";
import { SyntheticEvent, useState } from "react";
import { Control, FieldErrors, FieldValues, UseFormClearErrors, UseFormRegister } from "react-hook-form";
import {
    ConvertType,
    STATE_SAVED,
    createKey,
    getChangedOrOriginalValue,
    getEditPendingState,
    hasChangedValues,
} from "components/Converter";
import { cloneDeep } from "lodash";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { RerenderHook } from "components/useRerender";
import { navigateOverviewPageFromEdit } from "./FormEditData";
import { NavigateFunction, useNavigate } from "react-router";
import { FormEditViewLine, IFormEditViewLineTechnical, IRule, RuleName } from "./FormEditViewLine";
import { useSelector } from "react-redux";
import { RootState } from "application/redux/Store";

export const getField = (data: Record<string, unknown>, fieldName: string): string => {
    if (!fieldName.includes(".")) return String(data[fieldName] ?? "");
    const fieldNameSplit: string[] = fieldName.split(".");
    const d: Record<string, unknown> = data[fieldNameSplit[0]] as Record<string, unknown>;
    if (!d) return "";
    const nextFieldName: string = fieldNameSplit.splice(1).join(".");
    if (Array.isArray(d)) {
        return d.map((item: Record<string, unknown>) => getField(item, nextFieldName)).join(",");
    }
    return getField(d, nextFieldName);
};

export const updateField = (
    data: Record<string, unknown>,
    fieldName: string,
    newValue: string
): Record<string, unknown> => {
    if (!fieldName.includes(".")) {
        data[fieldName] = newValue;
        return data;
    }
    const fieldNameSplit: string[] = fieldName.split(".");
    const d: Record<string, unknown> = data[fieldNameSplit[0]] as Record<string, unknown>;
    if (!d) return {};
    return updateField(d, fieldNameSplit.splice(1).join("."), newValue);
};

export interface IEditDataViewUserConfiguration {
    headline: string;
    attributes: IEditDataKeys[];
    tabName?: string;
    latestVersionRequest: () => GET_REQUEST_TYPE;
    versionRequest: () => GET_REQUEST_TYPE;
    checkInvalidity?: {
        condition: (data: Record<string, unknown>) => boolean;
        relatedFields: string[];
    }[];
    updateFields?: {
        condition: boolean;
        method: (data: Record<string, unknown>) => Record<string, unknown>;
    };
    duplicate?: {
        condition: boolean;
    };
}

export interface IEditDataViewUserConfigurationWithData extends IEditDataViewUserConfiguration {
    data: Record<string, string>[];
}
export interface IEditDataView extends IEditDataViewUserConfiguration, IFormEditViewLineTechnical {
    updateEditData: (topic: string, index: number, key: string, newValue: string) => void;
    resetEditData: (topic: string, index: number) => void;
    updateDatePickerError: (topic: string, index: number, key: string, newValue: boolean) => void;
    productionData: Record<string, string>[];
    editData: Record<string, string>[];
    rerender: RerenderHook;
    deleteLevel: number;
    isNewCreated: boolean;
    invalidFields: Set<string>;
    updateInvalidField: (section: string, newSet: Set<string>) => void;
}

export interface IFormConfiGuration {
    errors: FieldErrors<FieldValues>;
    control: Control<FieldValues>;
    register: UseFormRegister<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
}

export type dataPresendtMode = "basicFormatView" | "tabFormatView";

export interface IEditDataKeys {
    headerName: string;
    field: string;
    rules?: Partial<Record<RuleName, IRule>>;
    type?: ConvertType;
    dropdown?: {
        keyTableTabname: string;
        optionValueFilter?: (option: string) => string;
        disableNonEditableOptions?: boolean;
    };
    displayOnly?: boolean;
    hide?: boolean;
    disabled?: boolean | ((values: Record<string, unknown>, fieldName: string) => boolean);
    required?: (values: Record<string, unknown>) => boolean;
}

export const FromEditView = (props: IEditDataView): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const formAction = useSelector((state: RootState) => state.formAction.actionType); // Assuming your slice is stored as formAction in the root state

    const [resetEnable, setResetEnable] = useState<Record<string, boolean>>({});
    const updateResetButtonEnable = (index: number, newVal: boolean) => {
        const re: Record<string, boolean> = cloneDeep(resetEnable);
        re[index.toString()] = newVal;
        setResetEnable(re);
    };

    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

    const checkInvalidFields = (changedField: string, newData: string, index: number) => {
        //check invalid rules
        const result: Set<string> = new Set<string>();
        const data: Record<string, unknown> = cloneDeep(props.editData[index]);
        data[changedField] = newData;
        props.checkInvalidity &&
            props.checkInvalidity.forEach((invalidCheck) => {
                if (invalidCheck.condition(data)) {
                    invalidCheck.relatedFields.forEach((invalidField: string) => {
                        result.add(invalidField);
                    });
                }
            });

        //check for required fields
        props.attributes.forEach((att: IEditDataKeys) => {
            if (att.required && att.required(data)) {
                !(data[att.field] !== undefined && data[att.field] !== "") && result.add(att.field);
            }
        });

        props.updateInvalidField(props.headline, result);
    };

    const hasChangeInSection = (index: number): boolean => {
        return JSON.stringify(props.editData[index]) !== JSON.stringify(props.productionData[index]);
    };

    const getTopicEdit = (index: number): React.JSX.Element => {
        return (
            <div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "30% 30% 30% 10%",
                        gap: "0.5em 0em",
                    }}
                >
                    {/* Headings */}
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "0.875rem",
                        }}
                    >
                        Feldbezeichnung
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: "0.875rem",
                        }}
                    >
                        Feldinhalt
                    </Typography>
                    {hasChangeInSection(index) && formAction === "default" ? (
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: "0.875rem",
                            }}
                        >
                            Originärer Eintrag
                        </Typography>
                    ) : (
                        <div></div>
                    )}
                    <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "0.5em" }}>
                        <ButtonPrimary
                            title={"Reset"}
                            onClick={() => {
                                updateResetButtonEnable(index, false);
                                props.resetEditData(props.headline, index);

                                //remove errors from section
                                props.attributes.forEach((edi: IEditDataKeys) => {
                                    props.formSpecificConfiguration.clearErrors(edi.field);
                                });
                            }}
                            disabled={!resetEnable[index]}
                        />
                        <ButtonPrimary
                            title={"Löschen"}
                            onClick={() =>
                                sendGetRequest({
                                    path: "/cgresources/editPending/delete",
                                    params: {
                                        deleteLevel: props.deleteLevel,
                                        id: Number(props.editData[index]["id"]),
                                    },
                                }).then(() => {
                                    //only for new created onces
                                    props.isNewCreated && navigateOverviewPageFromEdit(navigate);
                                    props.rerender.rerender();
                                })
                            }
                            disabled={
                                !hasChangedValues(props.productionData[index]) ||
                                getEditPendingState(props.productionData[index]) !== STATE_SAVED
                            }
                        />
                    </div>
                </div>
                {props.attributes.map((attribute: IEditDataKeys) => {
                    if (attribute.hide) return;

                    const isDisabled = (): boolean => {
                        if (typeof attribute.disabled === "boolean") {
                            return attribute.disabled;
                        } else if (attribute.disabled) {
                            return attribute.disabled(props.editData[index], attribute.field);
                        } else {
                            return false;
                        }
                    };

                    return (
                        <FormEditViewLine
                            attribute={attribute}
                            checkInvalidFields={(changedField: string, newData: string) =>
                                checkInvalidFields(changedField, newData, index)
                            }
                            disableFurtherEdits={(props.disableFurtherEdits || isDisabled()) ?? false}
                            formSpecificConfiguration={props.formSpecificConfiguration}
                            hasChangeInSection={hasChangeInSection(index)}
                            hasError={props.invalidFields && props.invalidFields.has(attribute.field)}
                            updateDatePickerError={(newValue: boolean) =>
                                props.updateDatePickerError(props.headline, index, attribute.field, newValue)
                            }
                            updateEditData={(newValue: string) =>
                                props.updateEditData(props.headline, index, attribute.field, newValue)
                            }
                            updateResetButtonEnable={(newValue: boolean) => updateResetButtonEnable(index, newValue)}
                            key={attribute.field}
                            value={getField(props.editData[index], attribute.field)}
                            oldValue={getField(props.productionData[index], attribute.field)}
                            id={getChangedOrOriginalValue(props.editData[index], "id")}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <Typography style={{ fontWeight: "bold", fontSize: "1.2rem", marginBottom: "1rem" }}>
                {props.headline}
            </Typography>

            {props.tabName ? (
                <TabContext value={currentTabIndex.toString()}>
                    <TabList
                        onChange={(_event: SyntheticEvent<Element, Event>, newIndex: number) => {
                            setCurrentTabIndex(newIndex);
                        }}
                    >
                        {props.editData.map((_data: Record<string, string | string[]>, index: number) => {
                            return (
                                <Tab
                                    key={createKey(props.headline, index.toString())}
                                    label={props.tabName + " " + (index + 1)}
                                    value={index.toString()}
                                />
                            );
                        })}
                    </TabList>
                    {props.editData.map((_data: Record<string, string | string[]>, index: number) => {
                        return (
                            <TabPanel key={createKey(props.headline, index.toString())} value={index.toString()}>
                                {getTopicEdit(index)}
                            </TabPanel>
                        );
                    })}
                </TabContext>
            ) : (
                getTopicEdit(0)
            )}
        </div>
    );
};
