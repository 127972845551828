/* author: Aashish Upadhyay */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { Location, useLocation, useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";
import { getChangedOrOriginalValue } from "components/Converter";

export const NV_DATA_IDENTIFIER: string = "nv";

export const NV_SECTION: string = "Allgemeine Informationen";

export const NvDataEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const nvDataId: number = Number(params.nvDataId === "new" ? -1 : params.nvDataId);
    const section: string | undefined = params.section;

    const location: Location = useLocation();
    let rowData: Record<string, unknown> = location.state && location.state.rowData;
    if (!rowData) rowData = location.state.data;
    if (rowData) sessionStorage.setItem("fsaNvId", String(getChangedOrOriginalValue(rowData, "fsaNvId")));
    const isNew: boolean = location.state && location.state.isNew;

    const fsaNvId: number = rowData
        ? Number(getChangedOrOriginalValue(rowData, "fsaNvId"))
        : Number(sessionStorage.getItem("fsaNvId"));

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: "/customerstaticdata/fsaNv/getNvDataForEdit",
            params: { nvDataId: nvDataId, isNew: isNew },
        });

    const nvEditInfo: IEditDataViewUserConfiguration = {
        headline: NV_SECTION,
        attributes: [
            { field: "nvNr", headerName: "NV-Bescheinigungsnummer" },
            {
                field: "nvType",
                headerName: "NV-Typ",
                dropdown: {
                    keyTableTabname: "NVTYPE",
                },
            },
            {
                field: "deleteFlag",
                headerName: "Loeschkennzeichen",
                dropdown: {
                    keyTableTabname: "FSANVDELETEFLAG",
                },
            },
            { field: "lastNamePerson1", headerName: "Nachname Person1" },
            { field: "givenNamePerson1", headerName: "Vorname Person1" },
            { field: "birthDatePerson1", headerName: "Geburtsdatum Person1", type: "date" },
            {
                field: "tinPerson1",
                headerName: "TIN Person1",
                rules: {
                    pattern: {
                        value: /^[1-9]{1}[0-9]{10}$/,
                        customMessage: "Tin muss eine 11 stellige Zahl sein und kann nicht mit 0 beginnen!",
                    },
                },
                type: "number",
            },
            { field: "birthNamePerson1", headerName: "Geburtsname Person1" },
            { field: "lastNamePerson2", headerName: "Nachname Person2" },
            { field: "givenNamePerson2", headerName: "Vorname Person2" },
            { field: "birthDatePerson2", headerName: "Geburtsdatum Person2", type: "date" },
            {
                field: "tinPerson2",
                headerName: "TIN Person2",
                rules: {
                    pattern: {
                        value: /^[1-9]{1}[0-9]{10}$/,
                        customMessage: "Tin muss eine 11 stellige Zahl sein und kann nicht mit 0 beginnen!",
                    },
                },
                type: "number",
            },
            { field: "birthNamePerson2", headerName: "Geburtsname Person2" },
            { field: "taxOffice", headerName: "NV-Finanzamt" },
            { field: "issueDate", headerName: "NV-Ausstellungsdatum", type: "date" },
            { field: "validFrom", headerName: "Gültig vom", type: "date" },
            { field: "validTo", headerName: "Gültig bis", type: "date" },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [nvEditInfo];
    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            parentIdentifier="fsaNvId"
            id01={fsaNvId}
            title="Nichtveranlagungsbescheinigung (NV)"
            section={section}
        />
    );
};
