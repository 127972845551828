/* author: JESCHKE Moritz */

import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";

interface ISetIsLoading {
    payload: {
        isLoading: boolean;
    };
    type: string;
}

interface IState {
    isLoading: boolean;
}

const initialState: IState = {
    isLoading: false,
};

export const ApplicationSlice = createSlice({
    name: "application",
    initialState: initialState,
    reducers: {
        setIsLoading: (state, action: ISetIsLoading) => {
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        },
    },
});

export const { setIsLoading } = ApplicationSlice.actions;
export const applicationSliceReducer: Reducer<IState, AnyAction> = ApplicationSlice.reducer;
