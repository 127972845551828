/* author: Aashish Upadhyay  */

import { EditPendingLinked } from "components/EditPendingLinked";
import { ITableColumn } from "components/Table";

export const EditPendingLinkedSecurityInfo = (): JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "categoryFlag", title: "Kategorie" },
        { field: "wkn", title: "WKN" },
        { field: "isin", title: "ISIN" },
        { field: "derivativeCode", title: "Derivate Code" },
        { field: "longName", title: "Wertpapier Langbezeichnung" },
        { field: "underlying", title: "Underlying ISIN" },
        { field: "gd198B", title: "GD198B" },
    ];

    return (
        <EditPendingLinked
            columns={columns}
            dataRequest={{
                path: "/security/securities/editPendingSecurities",
            }}
            identifier="editPendingOverviewSecurity"
            title="Wertpapierstammdaten"
            navigate={{ linkTo: "SecurityOverview", idField: "id" }}
        />
    );
};
