import { ITableContextMenu } from "components/Table";
import { getEditState, getEditTo } from "components/VersionsView";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TED_SECTION_CALCULATION_POT, TED_SECTION_DEPOT_EXCHANGE } from "./TedEdit";
import { getChangedValue } from "components/Converter";
import { PortfolioTransferDetails } from "./PortfolioTransferDetails";
import { SalesDetails } from "./SalesDetails";

interface ITedContexMenu {
    isDepot: boolean;
    searchDirection: "IN" | "OUT";
    contextMenus: Record<string, ITableContextMenu>;
    setContextmenus: Dispatch<SetStateAction<Record<string, ITableContextMenu>>>;
}

export const TedContextMenu = (props: ITedContexMenu): React.JSX.Element => {
    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});
    const [portfolioTransferOpen, setPortfolioTransferOpen] = useState<boolean>(false);
    const [salesDetailsOpen, setSalesDetailsOpen] = useState<boolean>(false);

    const tedContextMenu: ITableContextMenu = {
        contextActions: [
            {
                label: "Zeige Anschaffungsdaten",
                action: () => setPortfolioTransferOpen(true),
                condition: props.isDepot,
            },
            {
                label: "Umsatz",
                action: () => setSalesDetailsOpen(true),
                condition: props.isDepot,
            },
            {
                label: "Bearbeiten",
                link: {
                    to: getEditTo(
                        Number(currentRowData["id"]),
                        props.isDepot ? TED_SECTION_DEPOT_EXCHANGE : TED_SECTION_CALCULATION_POT,
                        props.searchDirection
                    ),
                    state: getEditState(getChangedValue(currentRowData, "modifyAction") ?? "", currentRowData),
                },
            },
        ],
        setCurrentRowData: setCurrentRowData,
    };

    useEffect(() => {
        props.setContextmenus({
            ["TED"]: tedContextMenu,
        });
    }, [currentRowData]);

    return (
        <div>
            {portfolioTransferOpen && (
                <PortfolioTransferDetails
                    tedExchangeId={+currentRowData.id}
                    setOpen={setPortfolioTransferOpen}
                    extTranKey={String(currentRowData.extTranKey)}
                    isEditable={
                        !["Storniert", "Verarbeitet", "Manuell verschickt", "Ausgeliefert"].includes(
                            currentRowData.status as string
                        )
                    }
                />
            )}
            {salesDetailsOpen && (
                <SalesDetails setOpen={setSalesDetailsOpen} extTranKey={String(currentRowData.extTranKey)} />
            )}
        </div>
    );
};
