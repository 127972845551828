/* author: JESCHKE Moritz */

import { configureStore, combineReducers, EnhancedStore } from "@reduxjs/toolkit";
import { userConfigurationSliceReducer } from "./slices/UserConfigurationSlice";
import { transactionSliceReducer } from "./slices/TransactionSlice";
import { userSliceReducer } from "./slices/UserSlice";
import { yerSliceReducer } from "./slices/YerSlice";
import { applicationSliceReducer } from "./slices/ApplicationSlice";
import { tedSliceReducer } from "./slices/TedSlice";
import { securitySliceReducer } from "./slices/FinancialInstrumentSlice";
import { datePickerErrorMsgReducer } from "./slices/DatePickerErrorSlice";
import { FormStateSliceReducer } from "./slices/FormStateSlice";
export const Store: EnhancedStore = configureStore({
    // all reducers which are declared in ./slices folder
    // so if you add a reducer there you have to add him here too
    reducer: combineReducers({
        transaction: transactionSliceReducer,
        user: userSliceReducer,
        userConfiguration: userConfigurationSliceReducer,
        yer: yerSliceReducer,
        application: applicationSliceReducer,
        ted: tedSliceReducer,
        security: securitySliceReducer,
        datePicker: datePickerErrorMsgReducer,
        formAction: FormStateSliceReducer,
    }),
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ immutableCheck: true, thunk: true, serializableCheck: false }),
    ],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;
