/* author: JESCHKE Moritz */

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, TextFieldProps } from "@mui/material";
import { Colors } from "./Colors";
import { DateValidationError } from "@mui/x-date-pickers/internals";
import { getBorderColor, getBorderWidth } from "./InputField";
import { useState } from "react";

interface IDatePicker {
    onChange?: (newValue: Date | null) => void;
    value: Date | null;
    disabled?: boolean;
    onError?: () => void;
    onAccept?: () => void;
    highlightBorder?: boolean;
    hasError?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur?: (event: any) => void;
}

export const Datepicker = (props: IDatePicker): React.JSX.Element => {
    const [hasInternalError, setHasInternalError] = useState<boolean>();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
            <DatePicker
                label={""}
                value={props.value}
                disabled={props.disabled}
                onChange={(newValue: Date | null) => {
                    if (props.onChange) {
                        props.onChange(newValue);
                    }
                }}
                /* minDate={getMinDate(datePicker)}
                maxDate={getMaxDate(datePicker)} */
                renderInput={(params: TextFieldProps) => (
                    <TextField
                        sx={{
                            color: Colors.blue,
                            borderRadius: "3px",
                            label: { fontSize: "0.8rem" },
                            "& .MuiOutlinedInput-input.MuiInputBase-input": {
                                border: getBorderWidth(props.highlightBorder) + "px solid",
                                borderColor: getBorderColor(
                                    props.disabled,
                                    props.highlightBorder,
                                    props.hasError || hasInternalError
                                ),
                                borderRadius: "3px",
                                backgroundColor: Colors.white,
                                boxSizing: "border-box",
                                color: "#333333",
                                padding: "8.5px 14px",
                                height: "1.875rem",
                            },
                        }}
                        {...params}
                        onBlur={(event) => props.onBlur && props.onBlur(event)}
                    />
                )}
                mask="__.__.____"
                disableFuture={true}
                onError={(err: DateValidationError) => {
                    if (err) {
                        props.onError && props.onError();
                        setHasInternalError(true);
                    } else {
                        props.onAccept && props.onAccept();
                        setHasInternalError(false);
                    }
                }}
                onAccept={() => {
                    props.onAccept && props.onAccept();
                    setHasInternalError(false);
                }}
            />
        </LocalizationProvider>
    );
};
