/* author: Pal PRASHANT */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { yearValidation } from "components/EditComponents/CommonValidations";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Location, useLocation } from "react-router";

import { Params, useParams } from "react-router-dom";

export const FSA_DATA_SECTION: string = "FSA Daten Zeitscheiben";
export const fsaDataIde: string = "fsa ";

export const FsaDataEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const section: string | undefined = params.section;
    const fsaDataId: number = Number(params.fsaDataId);

    const location: Location = useLocation();
    const fsaNvId: number = location.state.rowData["fsaNvId"];

    const fsaDataEdit: IEditDataViewUserConfiguration = {
        headline: FSA_DATA_SECTION,
        attributes: [
            { field: "identification", headerName: "FSA Identifikation Nr." },
            { field: "exemptAmount", headerName: "Freistellungsbetrag" },
            {
                field: "rollOverYear",
                headerName: "Rollover-Jahr",
                rules: yearValidation,
            },
            { field: "taxDate", headerName: "Steuerliches Zuflussdatum", type: "date" },
            { field: "fsaTyp", headerName: "FSA Typ" },
            { field: "startingInFiscalYear", headerName: "Gültig ab" },
            { field: "lastNamePerson1", headerName: "Person 1 Nachname" },
            { field: "givenNamePerson1", headerName: "Person 1 Vorname" },
            {
                field: "birthDatePerson1",
                headerName: "Person 1 Geburtsdatum",
                type: "date",
                // rules: {
                //     required: {
                //         value: "true",
                //     },
                //     // pattern: {
                //     //     value: /\d{4}-\d{2}-\d{2}/,
                //     // },
                // },
            },
            {
                field: "tinPerson1",
                headerName: "Person 1 TIN",
                rules: {
                    minLength: {
                        value: 11,
                    },
                    required: {
                        value: "true",
                    },
                },
            },
            { field: "birthNamePerson1", headerName: "Person 1 Geburtsname" },
            { field: "lastNamePerson2", headerName: "Person 2 Nachname" },
            {
                field: "givenNamePerson2",
                headerName: "Person 2 Vorname",
                rules: {
                    minLength: {
                        value: 11,
                    },
                },
            },
            {
                field: "birthDatePerson2",
                headerName: "Person 2 Geburtsdatum",
                type: "date",
                rules: {
                    required: {
                        value: "true",
                    },
                },
            },
            {
                field: "tinPerson2",
                headerName: "Person 2 TIN",
                rules: {
                    minLength: {
                        value: 11,
                    },
                    required: {
                        value: "true",
                    },
                },
            },
            { field: "birthNamePerson2", headerName: "Person 2 Geburtsname" },
            { field: "familyStatus", headerName: "Familienstand" },
            { field: "execState", headerName: "Ausführungsstatus", displayOnly: true },
        ],

        latestVersionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/fsaNv/fsaDataByFsaNvIdForEdit",
                params: { fsaNvId: fsaNvId },
            }),

        versionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/fsaNv/fsaDataByFsaDataIdForEdit",
                params: { fsaDataId: fsaDataId },
            }),
        checkInvalidity: [
            {
                condition: (data: Record<string, unknown>): boolean => String(data["birthDatePerson1"]) === "",
                relatedFields: ["birthDatePerson1"],
            },
            {
                condition: (data: Record<string, unknown>): boolean => String(data["birthDatePerson2"]) === "",
                relatedFields: ["birthDatePerson2"],
            },
        ],
    };
    const completeEditInfo: IEditDataViewUserConfiguration[] = [fsaDataEdit];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={fsaNvId}
            title="FSA Daten bearbeiten"
            section={section}
            parentIdentifier="fsaNvId"
        />
    );
};
