/* author: JESCHKE Moritz */

import { Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { createKey } from "./Converter";

interface IContextMenu {
    contextActions: IContextMenuAction[];
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | null;
    resetContextRowSelector?: () => void;
}

export interface IContextMenuAction {
    label: string;
    action?: () => void;
    link?: {
        to: string;
        state?: Record<string, unknown>;
    };
    condition?: boolean;
}

const QUADRANTS = {
    TOP_RIGHT: 1,
    TOP_LEFT: 2,
    LOWER_LEFT: 3,
    LOWER_RIGTH: 4,
};

export const ContextMenu = (props: IContextMenu): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const [anchorElContextMenu, setAnchorElContextMenu] = useState<null | HTMLElement>(null);
    const openContextMenu = Boolean(anchorElContextMenu);
    const [xContextMenu, setXContextMenu] = useState<number>(0);
    const [yContextMenu, setYContextMenu] = useState<number>(0);
    const validContexActions: IContextMenuAction[] =
        props.contextActions &&
        props.contextActions.filter(
            (contextAction: IContextMenuAction) => contextAction.condition === undefined || contextAction.condition
        );

    const getQuadrant = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        // eslint-disable-next-line no-magic-numbers
        const clientHeightHalf = document.body.clientHeight * 0.5;
        // eslint-disable-next-line no-magic-numbers
        const clientWidthHalf = document.body.clientWidth * 0.5;

        const x = event.pageX;
        const y = event.pageY;

        if (x > clientWidthHalf && y < clientHeightHalf) {
            return QUADRANTS.TOP_RIGHT;
        } else if (x < clientWidthHalf && y < clientHeightHalf) {
            return QUADRANTS.TOP_LEFT;
        } else if (x < clientWidthHalf && y > clientHeightHalf) {
            return QUADRANTS.LOWER_LEFT;
        } else return QUADRANTS.LOWER_RIGTH;
    };

    const getTextWidth = (text: string, font: string) => {
        let ret: number = -1;
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
        if (context !== null) {
            context.font = font;
            const metrics: TextMetrics = context.measureText(text);
            ret = metrics.width;
        }
        return ret;
    };

    const handleClose = () => {
        setAnchorElContextMenu(null);
        props.resetContextRowSelector && props.resetContextRowSelector();
    };

    useEffect(() => {
        console.log("in contex menu\n", props.event);
        if (props.event !== null) {
            props.event.preventDefault();
            if (!openContextMenu) {
                setAnchorElContextMenu(props.event.target as HTMLElement);

                if ((props.event.target as HTMLElement).parentElement?.nodeName === "TR") {
                    //prevent context menu out of screen
                    const quadrant = getQuadrant(props.event);
                    const longestStringInActions = validContexActions.reduce((a, b) => {
                        return a.label.length > b.label.length ? a : b;
                    });
                    // eslint-disable-next-line no-magic-numbers
                    const contextMenuWidht: number = getTextWidth(longestStringInActions.label, "16px Roboto") + 50;
                    // eslint-disable-next-line no-magic-numbers
                    const contextMenuHeight: number = validContexActions.length * 36 + 18;

                    let x: number = props.event.pageX;
                    let y: number = props.event.pageY;

                    switch (quadrant) {
                        case QUADRANTS.TOP_RIGHT:
                            x = props.event.pageX - contextMenuWidht;
                            break;
                        case QUADRANTS.LOWER_LEFT:
                            y = props.event.pageY - contextMenuHeight;
                            break;
                        case QUADRANTS.LOWER_RIGTH:
                            x = props.event.pageX - contextMenuWidht;
                            y = props.event.pageY - contextMenuHeight;
                            break;
                        default:
                            break;
                    }

                    setXContextMenu(x);
                    setYContextMenu(y);
                }
            } else {
                handleClose();
            }
        }
    }, [props.event]);

    return (
        <Menu
            anchorEl={anchorElContextMenu}
            open={openContextMenu}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            sx={{
                position: "absolute",
                left: xContextMenu,
                top: yContextMenu,
                width: "1000px",
                height: "1000px",
            }}
        >
            {validContexActions &&
                validContexActions.map((action: IContextMenuAction) => {
                    return (
                        <MenuItem
                            key={createKey("contextMenu", action.label)}
                            onClick={() => {
                                action.action && action.action();
                                action.link && navigate(action.link.to, { state: action.link.state });
                                handleClose();
                            }}
                        >
                            {action.label}
                        </MenuItem>
                    );
                })}
        </Menu>
    );
};
