/* author: JESCHKE Moritz */

import { hasChangeInSiblings, hasChangedValues } from "./Converter";
import { IOverviewViewStackAdder } from "./Overview";
import { IAttribute, OverviewDataView } from "./OverviewDataView";
import { flattenJson } from "components/Converter";
import { IOverviewHeadLine, OverviewHeadLine } from "./OverviewHeadLine";

export interface IOverviewData {
    headline: IOverviewHeadLine;
    attributes: IAttribute[];
    columnNumber: number;
    data: Record<string, string>;
    width?: string;
    versionView?: React.JSX.Element;
    flattening?: boolean;
}

type IOverviewDataWithSetter = IOverviewData & IOverviewViewStackAdder;

export const OverviewData = (props: IOverviewDataWithSetter): React.JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const finalData: Record<string, string> = props.flattening ? flattenJson(props.data as unknown as []) : props.data;
    return (
        <div style={{ width: props.width ? props.width : "100%" }}>
            <OverviewHeadLine
                title={props.headline.title}
                onInfoClick={props.headline.onInfoClick}
                onTimeStampClick={
                    props.versionView
                        ? () => {
                              props.versionView &&
                                  props.viewStackAdd &&
                                  props.viewStackAdd(props.versionView, props.headline.title);
                          }
                        : props.headline.onTimeStampClick
                }
                hasChangedData={hasChangedValues(props.data) || hasChangeInSiblings(props.data)}
                useTimeSliceButtonForHistory={props.headline.useTimeSliceButtonForHistory}
            />

            <OverviewDataView attributes={props.attributes} columnNumber={props.columnNumber} data={finalData} />
        </div>
    );
};
