/* author: JESCHKE Moritz  */

import { EditPendingLinked } from "components/EditPendingLinked";
import { getTedColumns } from "./TedSearchMask";
import { ITableContextMenu } from "components/Table";
import { useState } from "react";
import { TedContextMenu } from "./TedContextMenu";
import {
    TED_CALCULATION_POT_INGOING_MENU_ITEM,
    TED_CALCULATION_POT_OUTGOING_MENU_ITEM,
    TED_DEPOT_INGOING_MENU_ITEM,
    TED_DEPOT_OUTGOING_MENU_ITEM,
} from "application/menu-bar/MenuBarItemList";

interface IEditPendingLinkedTed {
    direction: "IN" | "OUT";
    isDepot: boolean;
}

export const EditPendingLinkedTed = (props: IEditPendingLinkedTed): React.JSX.Element => {
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    const getTitle = (): string => {
        if (props.isDepot) {
            if (props.direction === "IN") return TED_DEPOT_INGOING_MENU_ITEM;
            else return TED_DEPOT_OUTGOING_MENU_ITEM;
        } else {
            if (props.direction === "IN") return TED_CALCULATION_POT_INGOING_MENU_ITEM;
            else return TED_CALCULATION_POT_OUTGOING_MENU_ITEM;
        }
    };

    return (
        <div key={props.direction + props.isDepot}>
            <EditPendingLinked
                columns={getTedColumns(!props.isDepot, props.direction === "IN")}
                dataRequest={{
                    path: "/ted/exchange/searchEditPending",
                    params: {
                        searchDirection: props.direction,
                        taxTp: props.isDepot ? "10" : "20,30,40",
                    },
                }}
                identifier="editPendingOverviewPerson"
                title={getTitle()}
                contextMenu={{
                    element: (
                        <TedContextMenu
                            isDepot={props.isDepot}
                            contextMenus={contextMenus}
                            setContextmenus={setContextMenus}
                            searchDirection={props.direction}
                        />
                    ),
                    tableElement: contextMenus["TED"],
                }}
            />
        </div>
    );
};
