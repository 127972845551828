/* author: JESCHKE Moritz */

import { Box, IconButton, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "application/redux/Store";
import { TransactionDialog } from "dialogs/TransactionDialog";
import { useEffect, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import { JSTBDialog } from "dialogs/JSTBDialog";
import { renewLoginTime } from "application/app-bar/Appbar";
import { TftTransaciton } from "pages/showcase/tft/TftTransaction";
import { TcgTransaction } from "pages/showcase/tcg/TcgTransaction";
import { ContextMenu } from "components/ContextMenu";
import { useAppDispatch } from "application/redux/Hooks";
import { setShowTransactioninMenubar } from "application/redux/slices/TransactionSlice";
import { ITableContextMenu } from "components/Table";

export const TransactionOverview = () => {
    /* ### VARIABLES & STATES ### */
    const dispatch: AppDispatch = useAppDispatch();
    const tableTransactionData: never[] = useSelector((state: RootState) => state.transaction.transactionData);
    const tableIncomeData: never[] = useSelector((state: RootState) => state.transaction.incomeData);
    //const [tableData, setTableData] = useState<IData[]>([]);

    //context menu
    const [event, setEvent] = useState<React.MouseEvent<HTMLDivElement> | null>(null);

    //TODO :: link fu to fiscal unit overview page
    const menu: ITableContextMenu = {
        contextActions: [
            {
                label: "Stammdaten",
                action: () => {
                    dispatch(setShowTransactioninMenubar({ showInMenubar: false }));
                },
                link: {
                    to: "/fiscalUnit",
                },
            },
        ],
    };

    const contextMenu: ITableContextMenu = {
        contextActions: [
            {
                label: "Jahressteuerbescheinigung",
                action: () => setJstbDialogOpen(true),
            },
        ],
    };
    const [currentContextMenu, setCurrentContextMenu] = useState(menu);
    const [transactionDialogOpen, setTransactionDialogOpen] = useState<boolean>(
        JSON.stringify(tableTransactionData) === JSON.stringify([]) &&
            JSON.stringify(tableIncomeData) === JSON.stringify([])
    );

    const [jstbDialogOpen, setJstbDialogOpen] = useState<boolean>(false);

    const accountNr = useSelector((state: RootState) => state.transaction.accountNr);
    const accountId = useSelector((state: RootState) => state.transaction.accountId);
    const fuNr = useSelector((state: RootState) => state.transaction.fuNr);
    console.log("FU\n", fuNr);
    const wkn = useSelector((state: RootState) => state.transaction.wkn);
    const from = useSelector((state: RootState) => state.transaction.from);
    const to = useSelector((state: RootState) => state.transaction.to);
    const type = useSelector((state: RootState) => state.transaction.type);
    const branch = useSelector((state: RootState) => state.transaction.branch);
    const fiscalUnitId = useSelector((state: RootState) => state.transaction.fiscalUnitId);
    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Box sx={{ margin: 3, width: "100vp", height: "80vh" }}>
            <ContextMenu contextActions={currentContextMenu.contextActions} event={event} />
            <TransactionDialog
                setDialogOpen={setTransactionDialogOpen}
                isOpen={transactionDialogOpen}
                accountNr={accountNr}
                accountId={accountId}
                wkn={wkn}
                from={from}
                to={to}
                branch={branch}
                type={type}
            />
            <JSTBDialog setDialogOpen={setJstbDialogOpen} isOpen={jstbDialogOpen} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                }}
            >
                <IconButton aria-label="Dialog öffnen" color="primary" onClick={() => setTransactionDialogOpen(true)}>
                    <EventIcon />
                </IconButton>
                <div>
                    <div
                        onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
                            if (menu !== undefined) {
                                setEvent(event);
                                setCurrentContextMenu(menu);
                            }
                        }}
                    >
                        <Typography style={{ marginRight: "1rem" }}>
                            {fuNr !== "" ? "Steuerliche Einheit: " + fuNr : null}
                        </Typography>
                    </div>
                    <div
                        onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
                            if (contextMenu !== undefined) {
                                setEvent(event);
                                setCurrentContextMenu(contextMenu);
                            }
                        }}
                    >
                        <Typography style={{ marginRight: "1rem" }}>
                            {accountNr !== "" && "Kontonummer: " + accountNr}
                        </Typography>
                    </div>
                </div>
            </div>

            <Card sx={{ height: "50%", overflow: "scroll" }}>
                <TcgTransaction setJstbDialogOpen={setJstbDialogOpen} tableTransactionData={tableTransactionData} />
            </Card>
            <br />
            <br />
            <Card sx={{ height: "50%", overflow: "scroll" }}>
                <TftTransaciton tableIncomeData={tableIncomeData} />
            </Card>
        </Box>
    );
};

//TODO :: rework css in general
