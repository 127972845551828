/* author: JESCHKE Moritz */
import Switch from "@mui/material/Switch";
import { Colors } from "./Colors";
import { getBorderColor, getBorderWidth } from "./InputField";

interface ISwitcher {
    checked: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    highlightBorder?: boolean;
    hasError?: boolean;
}

export const Switcher = (props: ISwitcher): React.JSX.Element => {
    return (
        <div
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                (event.target as HTMLElement).children[0] &&
                    ((event.target as HTMLElement).children[0] as HTMLElement).click();
            }}
            style={{
                display: "flex",
                justifyContent: "center",
                background: Colors.grey,
                borderRadius: "3px",
                boxShadow:
                    "0px 0px 0px " +
                    getBorderWidth(props.highlightBorder) +
                    "px " +
                    getBorderColor(props.disabled, props.highlightBorder, props.hasError),
            }}
        >
            <div>
                <Switch
                    size="small"
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                            color: Colors.blue,
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: Colors.darkBlue,
                        },
                    }}
                />
            </div>
        </div>
    );
};
