/* author: JESCHKE Moritz  */

import { SelectChangeEvent, Typography } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { RootState } from "application/redux/Store";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ButtonPrimary } from "components/Button";
import { ComboBox } from "components/ComboBox";
import { IDetailsPanel, ITableColumn, Table } from "components/Table";
import { USER_ADMIN } from "pages/login/Login";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import RefreshIcon from "@mui/icons-material/Refresh";

export const FlowControl = (): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const userRole = useSelector((state: RootState) => state.user.userRole);

    const [flowData, setFlowData] = useState<Record<string, string>[]>([]);

    const flowIds: { ["id"]: string }[] = [{ id: "18" }, { id: "101" }, { id: "111" }];
    const [flowId, setFlowId] = useState<string>(flowIds[0].id);

    const columnFlow: ITableColumn[] = [
        { field: "flowId", title: "Flow Id" },
        { field: "name", title: "Beschreibung" },
        { field: "status", title: "Status" },
    ];

    const columnFlowSteps: ITableColumn[] = [
        { field: "name", title: "Schritt" },
        { field: "stepId", title: "Schritt Id" },
        { field: "status", title: "Status" },
        { field: "position", title: "Position" },
        { field: "workerUnitCount", title: "Worker Units" },
        { field: "workerUnitFinishedCount", title: "beendet" },
        {
            field: "workerUnitCount",
            title: "Fortschritt",
            combinedFields: ["workerUnitFinishedCount"],
            combinedFieldsCalculation: "DIV",
            type: "percent",
        },
        { field: "errorCount", title: "Fehler" },
        { field: "startTS", title: "Start", type: "datetime" },
        { field: "endTS", title: "Ende", type: "datetime" },
    ];

    const detailPanel: IDetailsPanel[] = [
        {
            tooltip: "show data",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render: (rowData: Record<string, any>) => {
                return (
                    <Table
                        columnsDefinition={columnFlowSteps}
                        identifier={IDENTIFIER_FLOW_STEPS}
                        tableData={rowData["rowData"]["steps"]}
                        disableHideColumn
                    />
                );
            },
        },
    ];

    const IDENTIFIER_FLOW: TableidentifierType = "flows";
    const IDENTIFIER_FLOW_STEPS: TableidentifierType = "flowSteps";

    const fetchData = () => {
        sendGetRequest({ path: "/flow/control/status" }).then((response) => {
            setFlowData(response.data);
        });
    };

    useEffect(() => {
        if (userRole !== USER_ADMIN) {
            navigate("/home");
        } else {
            fetchData();
        }
    }, []);

    return (
        <div style={{ margin: "4rem" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                Flowsteuerung
            </Typography>
            <div style={{ display: "flex", alignItems: "flex-end", gap: "1em", marginBottom: "1em" }}>
                <ComboBox
                    label="Flow Id"
                    onChange={(event: SelectChangeEvent<string>) => setFlowId(event.target.value)}
                    value={flowId}
                    optionIdentifier="id"
                    optionValue={["id"]}
                    options={flowIds}
                />
                <ButtonPrimary
                    title="Starten"
                    onClick={() => sendGetRequest({ path: "/flow/control/start", params: { flowId: Number(flowId) } })}
                />

                <div style={{ cursor: "pointer", marginLeft: "auto" }} onClick={() => fetchData()}>
                    <RefreshIcon />
                </div>
            </div>
            <Table
                columnsDefinition={columnFlow}
                identifier={IDENTIFIER_FLOW}
                tableData={flowData}
                detailPanel={detailPanel}
                disableHideColumn
            />
        </div>
    );
};
