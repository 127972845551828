import React, { useState, useEffect } from "react";
import { useAppSelector } from "application/redux/Hooks";
import { RootState } from "application/redux/Store";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const TaxSearchMask = (): React.JSX.Element => {
    const TAXABLE_BASE_AND_INCOME_TYPE_FIELDS_NUMBER: number = 45;
    const [resultList, setResultlist] = useState<Record<string, string>[]>([]);
    const clientId: string = useAppSelector((state: RootState) => state.user.clientId);
    const staticColumns: ITableColumn[] = [
        { field: "fiscalUnitData.fiscalUnit", title: "Steuerliche Einheit" },
        { field: "accountData.branch", title: "Filialnummer" },
        { field: "accountData.accountNr", title: "Kontonummer" },
        { field: "accountData.iban", title: "IBAN" },
        { field: "accountData.accountType", title: "Kontoart" },
        {
            field: "fiscalUnitData.clientData.clientId",
            title: "Mandant",
            combinedFields: ["fiscalUnitData.clientData.name"],
            combinedFieldsSeparator: "/",
        },
        { field: "differentTaxTime", title: "abweichender steuerlicher Geschäftstag", type: "date" },

        { field: "yieldId", title: "ErtragsID" },
        { field: "cancellationFlag", title: "StoKZ" },
        { field: "refYieldId", title: "Strono Ertrags-Id" },
        { field: "refOriYieldId", title: "Eindeutige Referenz für Delta Sto/Neu-Kette" },
        { field: "predecessorYieldId", title: "Referenz auf Vorgänger für Delta Sto/Neu-Kette" },
        { field: "execState", title: "Ausführungsstatus" },
        { field: "deltaLinkedYieldId", title: "Ertrags-Id für Delta Sto/Neu-Kette" },
        {
            field: "incomeType",
            title: "Einkommenstyp",
        },
    ];

    const generateDynamicColumns = (resultList: Record<string, string>[]): ITableColumn[] => {
        const dynamicColumns: ITableColumn[] = [];

        // Iterate over the possible fields
        for (let i = 1; i <= TAXABLE_BASE_AND_INCOME_TYPE_FIELDS_NUMBER; i++) {
            const taxableBaseField = `taxableBase_${i}`;
            const incomeTypeField = `incomeTypeP20_${i}`;

            // Check if any entry in resultList has a value for either taxableBase or incomeType
            const hasValue = resultList.some((entry) => entry[taxableBaseField] || entry[incomeTypeField]);

            // If any entry has a value, include both columns in the dynamicColumns
            if (hasValue) {
                dynamicColumns.push(
                    { field: taxableBaseField, title: `Bemessungsgrundlage Komponente ${i}` },
                    { field: incomeTypeField, title: `Einkommenstyp nach Paragraph20 Komponente ${i}` }
                );
            } else break;
        }

        return dynamicColumns;
    };

    useEffect(() => {
        const dynamicColumns = generateDynamicColumns(resultList);
        setColumns([...staticColumns, ...dynamicColumns]);
    }, [resultList]);

    const [columns, setColumns] = useState<ITableColumn[]>(staticColumns);

    const searchCriterias: ISearchCriteria[] = [
        { field: "fiscalUnit", label: "Steuerliche Einheit*" },
        { field: "branch", label: "Filialnummer" },
        { field: "accountNr", label: "Kontonummer*" },
        { field: "iban", label: "IBAN*" },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: {
                tabname: "ACCTYPE",
                allOption: true,
            },
            defaultValue: "*",
        },

        {
            field: "identification",
            label: "Mandant",
            type: "dropDown",
            keytable: { tabname: "CLIENT", allOption: true },
            defaultValue: clientId,
        },
        {
            field: "incomeType",
            label: "Einkommenstyp",
            type: "dropDown",
            keytable: {
                tabname: "AGSTINCOMETYPES",
                allOption: true,
            },
            defaultValue: "*",
        },
        {
            field: "execState",
            label: "Ausführungsstatus",
            type: "dropDown",
            keytable: {
                tabname: "EXECSTATE",
                allOption: true,
            },
            defaultValue: "*",
        },
        {
            field: "",
            label: "Zeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "from", discriminator: "from" },
                    to: { label: "bis", field: "to", discriminator: "to" },
                },
            },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/taxbase/searchTaxBase" }];

    return (
        <Search
            title="Suche nach Bemessungsgrundlagen"
            searchCriterias={searchCriterias}
            columns={columns}
            searchUrls={searchUrls}
            identifier="taxSearch"
            setResultList={setResultlist}
        />
    );
};
