/* author: JESCHKE Moritz */

import { Autocomplete, TextField } from "@mui/material";
import { GET_REQUEST_TYPE } from "application/api/HttpRequestHandler";

interface IProps {
    value: string;
    setValue: (value: string) => void;
    setId: (id: string) => void;
    options: IAutoCompleteOption[];
    setOptions: (options: []) => void;
    optionsRequest: (currentValue: string) => GET_REQUEST_TYPE;
    autocompleteMinLetters: number;
    disabledCheck: boolean;
    label: string;
}

export interface IAutoCompleteOption {
    label: string;
    id: string;
}

export const AutoCompleteInput = (props: IProps): React.JSX.Element => {
    return (
        <Autocomplete
            value={props.value}
            onInputChange={async (event, value: string, reason: string) => {
                const prevValue = props.value;
                let optionsProps = props.options;

                //set the new value
                props.setValue(value);

                //handle options
                if (reason === "clear") {
                    props.setOptions([]);
                } else if (reason === "input") {
                    if ((event.target as HTMLInputElement).defaultValue.length > value.length) {
                        //delete
                        if (value.length < props.autocompleteMinLetters) {
                            props.setOptions([]);
                        }
                    } else {
                        //writing
                        if (
                            value.length >= props.autocompleteMinLetters &&
                            prevValue.length < props.autocompleteMinLetters
                        ) {
                            const options = await props.optionsRequest(
                                value.substring(0, props.autocompleteMinLetters)
                            );
                            props.setOptions(options.data);
                            optionsProps = options.data;
                        }
                    }
                }

                const id = optionsProps.filter((f) => f.label === value);
                props.setId(id.length === 1 ? id[0].id : "");
            }}
            disabled={props.disabledCheck}
            freeSolo
            options={props.options}
            sx={{ marginBottom: "1em" }}
            renderInput={(params) => <TextField {...params} label={props.label} />}
        />
    );
};
