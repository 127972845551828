/* author: Sindhu Kannan */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";
import { getEditHistoryContextMenu } from "components/VersionsView";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PORTFOLIO_TRANSFER_DETAIL_IDENTIFIER, PORTFOLIO_TRANSFER_DETAIL_SECTION } from "./PortfolioTransferDetailEdit";
import { TECHNICAL_EDIT_COLUMNS } from "components/EditComponents/EditMask";

interface IPortfolioTransferDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    tedExchangeId: number;
    extTranKey: string;
    isEditable: boolean;
}
export const PortfolioTransferDetails = (props: IPortfolioTransferDetails): React.JSX.Element => {
    const columns: ITableColumn[] = [
        {
            field: "acqdt",
            title: "W01 Tag",
            tooltip: "Steuerl. Anschaffungstag",
            type: "date",
        },
        { field: "acqtm", title: "W01 Uhrzeit", tooltip: "Steuerl. Anschaffungsuhrzeit" },
        { field: "histholdingind", title: "W02", tooltip: "Übertrag aus Altbestand (keine Finanzinnovation)" },
        { field: "acqnom", title: "W03", tooltip: "Anschaffungsmenge" },
        { field: "acqamteur", title: "W04", tooltip: "Steuerl. (bereinigter) Anschaffungswert in EUR" },
        { field: "acqamteursign", title: "W05", tooltip: "Kennzeichen Anschaffungswert verfügbar" },
        { field: "acqamt", title: "W06", tooltip: "Steuerl. (bereinigter) Anschaffungswert in Fremdwährung" },
        { field: "acqamtccy", title: "W07", tooltip: "Fremdwährung" },
        { field: "acrinteur", title: "W08", tooltip: "Stückzinsen in EUR" },
        { field: "intprofeur", title: "W09", tooltip: "Zwischengewinn je Anteil in EUR (ID904)" },
        {
            field: "accuprofs94eur",
            title: "W10",
            tooltip: "Akkumulierte thesaurierte Erträge je Anteil in EUR ab 1994 (ID905)",
        },
        {
            field: "accuprofretain",
            title: "W11",
            tooltip: "Schätzwert akkumulierte thesaurierte Erträge je Anteil in EUR (ID917)",
        },
        { field: "accusurpluseur", title: "W12", tooltip: "Akkumulierter Mehrbetrag je Anteil (ID909)" },
        { field: "realprofeur", title: "W13", tooltip: "Immobiliengewinn je Anteil (ID908)" },
        {
            field: "accuproffund",
            title: "W14",
            tooltip: "Akkumulierte ausgeschüttete Altveräußerungsgewinne je Anteil in Fondswährung (ID919)",
        },
        {
            field: "accuproffundccy",
            title: "W15",
            tooltip: " Fondswährung der akkumulierten ausgeschütteten Altveräußerungsgewinne",
        },
        {
            field: "accuprofs09eur",
            title: "W16",
            tooltip: "Bereinigte akkumulierte ausschüttungsgleiche Erträge je Anteil ab 01.01.2009 in EUR (ID921)",
        },
        {
            field: "accusubsprofeur",
            title: "W17",
            tooltip: "Akkumulierte Substanzausschüttung je Anteil in EUR (ID920)",
        },
        {
            field: "zastamteur",
            title: "W18",
            tooltip: "Noch zu verzastender Betrag (im Fall zurückliegender steuerneutraler Fondsfusion) in EUR",
        },
        { field: "ntrlMergerFundsDate", title: "W19", tooltip: "Datum der letzten steuerneutralen Fondsfusion" },
        { field: "scrpDate", title: "W20", tooltip: "Datum der ermittelten Investmentfondswerte" },
        { field: "purchaseStatDate", title: "W21", tooltip: "Anschaffungstag aus fiktiver Veräußerung" },
        {
            field: "accuStatProc",
            title: "W22",
            tooltip: "Akkumulierter thesaurierter Ertrag aus fiktiver Veräußerung am 31.12.2017",
        },
        {
            field: "unrealisedStatProf",
            title: "W23",
            tooltip: "Zwischengewinn aus fiktiver Veräußerung am 31.12.2017",
        },
        {
            field: "statReturn",
            title: "W24",
            tooltip: "Veräußerungsergebnis aus fiktiver Veräußerung am 31.12.2017 aus sonst. Gewinnen/Verlusten",
        },
        {
            field: "shareProfStatReturn",
            title: "W25",
            tooltip: "Veräußerungsergebnis aus fiktiver Veräußerung am 31.12.2017 aus Aktiengewinnen/-verlusten",
        },
        {
            field: "statAssessRepl",
            title: "W26",
            tooltip: "Ersatzbemessungsgrundlage aus fiktiver Veräußerung am 31.12.2017 aus sonst. Gewinnen/Verlusten",
        },
        {
            field: "shareProfStatAssRepl",
            title: "W27",
            tooltip: "Ersatzbemessungsgrundlage aus fiktiver Veräußerung am 31.12.2017 aus Aktiengewinnen/-verlusten",
        },
        {
            field: "statAccuReturnProf",
            title: "W28",
            tooltip: "Akkumuliertes Veräußerungsergebnis aus fiktiven Veräußerungen n.d. 31.12.2017 (Gewinn)",
        },
        {
            field: "statAccuReturnLoss",
            title: "W29",
            tooltip: "Akkumuliertes Veräußerungsergebnis aus fiktiven Veräußerungen n.d. 31.12.2017 (Verlust)",
        },
        {
            field: "statAccuAssessRepl",
            title: "W30",
            tooltip: "Akkumulierte Ersatzbemessungsgrundlage aus fiktiven Veräußerungen n.d. 31.12.2017",
        },
        {
            field: "assessReplIndet",
            title: "W31",
            tooltip: "Ersatzbemessungsgrundlage nicht ermittelbar bei fiktiver Veräußerung am 31.12.2017",
        },
        { field: "allowanceExAnte", title: "W32", tooltip: "Vorabpauschale (VAP)" },
        { field: "originalIsin", title: "W33", tooltip: "Ursprüngliche ISIN aus steuerneutraler Fondsfusion" },
        {
            field: "w34",
            title: "W34",
            tooltip: "Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Mischfonds vor TFS",
        },
        {
            field: "w35",
            title: "W35",
            tooltip: "Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Aktienfonds vor TFS",
        },
        {
            field: "w36",
            title: "W36",
            tooltip:
                "Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Immofonds (überw. inl. Immobilien) vor TFS",
        },
        {
            field: "w37",
            title: "W37",
            tooltip:
                "Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Immofonds (überw. ausl. Immobilien) vor TFS",
        },
        {
            field: "w38",
            title: "W38",
            tooltip: "Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - sonstige Fonds (keine TFS)",
        },
        {
            field: "w39",
            title: "W39",
            tooltip: "Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Mischfonds vor TFS",
        },
        {
            field: "w40",
            title: "W40",
            tooltip: "Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Aktienfonds vor TFS",
        },
        {
            field: "w41",
            title: "W41",
            tooltip:
                "Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Immofonds (überw. inl. Immobilien) vor TFS",
        },
        {
            field: "w42",
            title: "W42",
            tooltip:
                "Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Immofonds (überw. ausl. Immobilien) vor TFS",
        },
        {
            field: "w43",
            title: "W43",
            tooltip:
                "Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - sonstige Fonds (keine TFS)",
        },
        { field: "w44", title: "W44", tooltip: "Millionärsfonds" },
        {
            field: "w45",
            title: "W45",
            tooltip: "Ersatzbemessungsgrundlage nicht ermittelbar bei fikt. Veräußerung n. d. 31.12.2017",
        },
        { field: "w46", title: "W46", tooltip: "Anschaffungskosten vor 2018" },
    ];

    const IDENTIFIER: TableidentifierType = "portfolioTrasnferDetails";

    const [pftData, setPftData] = useState<Record<string, string>[]>([]);

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    useEffect(() => {
        sendGetRequest({ path: "/ted/repexchange", params: { tedExchangeId: props.tedExchangeId } }).then(
            (response) => {
                setPftData(response.data);
            }
        );
    }, [props.tedExchangeId]);
    return (
        <PopUp
            setOpen={props.setOpen}
            title={"Anschaffungsdaten für Umsatzreferenz " + props.extTranKey}
            content={
                <Table
                    columnsDefinition={columns.concat(TECHNICAL_EDIT_COLUMNS)}
                    identifier={IDENTIFIER}
                    tableData={pftData}
                    disableHideColumn={true}
                    pageSizeOptions="alternativ"
                    contextMenu={getEditHistoryContextMenu(
                        currentRowData,
                        setCurrentRowData,
                        "hist",
                        PORTFOLIO_TRANSFER_DETAIL_SECTION,
                        PORTFOLIO_TRANSFER_DETAIL_IDENTIFIER,
                        { isEditable: props.isEditable }
                    )}
                />
            }
        />
    );
};
