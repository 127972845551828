export const KeyTableConsts = {
    TAX_EVENT_TCG: "TAX_EVENT_TCG",
    TAX_EVENT_TFT: "TAXEVENT_TFT",
    TDD_STATE: "TDDSTATE",
    TED_STATUS: "TED_STATUS",
    ACC_TYPE: "ACCTYPE",
    EXEC_STATE: "EXECSTATE",
    AGST_ICNOME_TYPE: "AGSTINCOMETYPES",
    STATE_RECON: "STATE_RECON",
    DELSYS: "DELSYS",
    REASON: "REASON",
    CLIENT: "CLIENT",
    REQ_REPORT_TYPE: "REQ_REPORT_TYPE",
    REQ_VALUE: "REQ_VALUE",
    REQ_STATE: "REQ_STATE",
    TED_ALTBESTAND: "TED_ALTBESTAND",
    TED_FREMDWAEH_FINNO: "TED_FREMDWAEH_FINNO",
    WM_W02: "TB_WMW02",
    MANUAL_CHECK_REQUIRED: "MANUAL_CHECK_REQUIRED",
    CROSS_CURRENCY_FLAG: "CROSS_CURRENCY_FLAG",
};
