/* author: JESCHKE Moritz */

import { SelectChangeEvent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useAppDispatch } from "application/redux/Hooks";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "application/redux/Store";
import { setYer, setYerData } from "application/redux/slices/YerSlice";
import { FromToDatePicker } from "components/FromToDatePicker";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { ComboBox } from "components/ComboBox";
import { AutoCompleteInput } from "components/AutoCompleteInput";

interface IProps {
    isOpen: boolean;
    setDialogOpen: any;
    fiscalUnit: string;
    execState: string;
    from: Date;
    to: Date;
}

export const YerDialog = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [fiscalUnit, setFiscalUnit] = useState<string>(props.fiscalUnit);
    const [fiscalUnitId, setFiscalUnitId] = useState<string>("");
    const [execState, setExecState] = useState<string>(props.execState);
    const [from, setFrom] = useState<Date | null>(props.from);
    const [to, setTo] = useState<Date | null>(props.to);
    const [dateError, setDateError] = useState<boolean>(false);

    //Autocomplete
    const [fiscalUnitOptions, setFiscalUnitOptions] = useState([]);
    const AUTOCOMPLETE_MIN_LETTERS = 4;
    const clientId = useSelector((state: RootState) => state.user.clientId);

    const handkleOkPress = async () => {
        dispatch(setIsLoading({ isLoading: true }));
        handleClose();
        //Save current dialog states
        dispatch(setYer({ fiscalUnit: fiscalUnit, execState: execState, from: from!, to: to! }));

        //send search request
        const data = await sendGetRequest({
            path: "/request/yer/getRequestReportData",
            params: {
                fiscalUnitId: fiscalUnitId,
                from: from !== null ? from!.toISOString().slice(0, 11) + "00:00:00" : "",
                to: to !== null ? to!.toISOString().slice(0, 11) + "23:59:59" : "",
            },
        });
        console.log("REQUEST\n", data);

        dispatch(setYerData({ data: data.data }));

        dispatch(setIsLoading({ isLoading: false }));
    };

    const handleClose = () => {
        props.setDialogOpen(false);
    };

    /**
     * Call function if special key is pressed
     * @param event KeyPressEvent
     */
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" && !buttonDisableCheck()) {
            handkleOkPress();
        }
    };

    /**
     * Checks if the button is disabled
     * @returns true | false
     */
    const buttonDisableCheck = (): boolean => {
        return dateError;
    };

    return (
        <Dialog open={props.isOpen} onKeyDown={handleKeyDown}>
            <DialogTitle>{"Jahresend-Report"}</DialogTitle>
            <DialogContent>
                <div style={{ marginTop: "1em" }}>
                    <AutoCompleteInput
                        autocompleteMinLetters={AUTOCOMPLETE_MIN_LETTERS}
                        label="Fiscal Unit"
                        options={fiscalUnitOptions}
                        setOptions={setFiscalUnitOptions}
                        value={fiscalUnit}
                        setValue={setFiscalUnit}
                        setId={setFiscalUnitId}
                        disabledCheck={false}
                        optionsRequest={(currentValue: string) =>
                            sendGetRequest({
                                path: "/autocomplete/getAll/FiscalUnits",
                                params: { fiscalUnit: currentValue, clientId: clientId },
                            })
                        }
                    />

                    <ComboBox
                        label="Autragsstatus"
                        onChange={(event: SelectChangeEvent<string>) => {
                            setExecState(event.target.value);
                        }}
                        optionIdentifier="key"
                        optionValue={["key", "value"]}
                        optionValueSeparator=" - "
                        keyTable={{ tabname: "EXECSTATE" }}
                        value={execState}
                        width="50%"
                    />

                    <Typography sx={{ marginTop: "0.5em" }}>Von-Bis Zeitraum des Erfassungsdatums:</Typography>

                    <FromToDatePicker from={from} setFrom={setFrom} to={to} setTo={setTo} setDateError={setDateError} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={buttonDisableCheck()} onClick={handkleOkPress}>
                    Ok
                </Button>
                <Button onClick={handleClose}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};
