/* author: JESCHKE Moritz  */

import { SelectChangeEvent } from "@mui/material";
import { ISearchCriteria } from "./Search";
import {
    DatePickerType,
    IDatePicker,
    Orientation,
    SingleDatePickerType,
    TBDatePicker,
    TBDatePickerType,
} from "./TBDatePicker";
import { LabelValue } from "./LabelValue";
import { convert, createKey } from "./Converter";
import { ComboBox } from "./ComboBox";
import { AutoCompleteInputV2 } from "./AutoCompleteInputV2";
import { InputField } from "./InputField";

interface ISearchInputFields {
    searchCriterias: ISearchCriteria[];
    searchCriteriaToggle: boolean;
    searchCriteriaText: Record<string, string>;
    updateSearchCriteriaState: (newState: Record<string, string>) => void;
    getDatePickers: (searchCriteria: ISearchCriteria) => IDatePicker[];
    datePickerPageTitle: string;
    getDatePickerOrientation: (searchCriteria: ISearchCriteria) => Orientation;
}

export const SearchInputFields = (props: ISearchInputFields): React.JSX.Element => {
    const setSearchCriteriaStateFromEvent = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
        searchCriteria: ISearchCriteria
    ) => {
        const newState: Record<string, string> = { ...props.searchCriteriaText };
        newState[searchCriteria.field] = event.target.value === undefined ? "" : event.target.value;
        props.updateSearchCriteriaState(newState);
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "0.25rem 1.675rem",
                flexWrap: "wrap",
                //gridTemplateColumns: "repeat(" + props.searchCriterias.length + ", 1fr)",
                marginBottom: "1.1625rem",
            }}
        >
            {props.searchCriterias.map((searchCriteria: ISearchCriteria) => {
                //clear field, if disabled
                if (searchCriteria.disabled && props.searchCriteriaText[searchCriteria.field]) {
                    const newState: Record<string, string> = { ...props.searchCriteriaText };
                    delete newState[searchCriteria.field];
                    props.updateSearchCriteriaState(newState);
                    console.log("dis", searchCriteria.field);
                }

                if (props.searchCriteriaToggle) {
                    //Searchcriteria Minimized State
                    if (searchCriteria.field === "") {
                        if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "singleDatePicker")) {
                            const datePicker: SingleDatePickerType =
                                searchCriteria.type as DatePickerType as SingleDatePickerType;
                            return (
                                <LabelValue
                                    label={datePicker.singleDatePicker.label}
                                    value={convert(props.searchCriteriaText[datePicker.singleDatePicker.field], "date")}
                                    key={createKey("search", searchCriteria.field)}
                                />
                            );
                        }

                        if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker")) {
                            const from: string = (searchCriteria.type as TBDatePickerType).TBFromToDatePicker.from
                                .field;
                            const to: string = (searchCriteria.type as TBDatePickerType).TBFromToDatePicker.to.field;
                            const fromLabel: string = (searchCriteria.type as TBDatePickerType).TBFromToDatePicker.from
                                .label;

                            const toLabel: string = (searchCriteria.type as TBDatePickerType).TBFromToDatePicker.to
                                .label;
                            return (
                                <div
                                    style={{ display: "flex", gap: "0.25rem 1.175rem" }}
                                    key={createKey("search", searchCriteria.field)}
                                >
                                    {props.searchCriteriaText[from] && (
                                        <LabelValue
                                            label={searchCriteria.label + " " + fromLabel}
                                            value={convert(props.searchCriteriaText[from], "date")}
                                        />
                                    )}
                                    {props.searchCriteriaText[to] && (
                                        <LabelValue
                                            label={searchCriteria.label + " " + toLabel}
                                            value={convert(props.searchCriteriaText[to], "date")}
                                        />
                                    )}
                                </div>
                            );
                        }
                    } else if (props.searchCriteriaText[searchCriteria.field]) {
                        return (
                            <LabelValue
                                label={searchCriteria.label}
                                value={props.searchCriteriaText[searchCriteria.field]}
                                key={createKey("search", searchCriteria.field)}
                            />
                        );
                    }
                } else {
                    //Searchcriteria Maximized State
                    if (searchCriteria.hidden) return null;

                    if (searchCriteria.type === "dropDown" && searchCriteria.keytable) {
                        return (
                            <ComboBox
                                label={searchCriteria.label}
                                onChange={(event: SelectChangeEvent<string>) => {
                                    setSearchCriteriaStateFromEvent(event, searchCriteria);
                                }}
                                value={
                                    props.searchCriteriaText[searchCriteria.field] ?? searchCriteria.defaultValue ?? ""
                                }
                                lowest={props.searchCriteriaText[searchCriteria.comboBoxLowestFieldName ?? ""]}
                                highest={props.searchCriteriaText[searchCriteria.comboBoxHighestFieldName ?? ""]}
                                keyTable={searchCriteria.keytable}
                                optionIdentifier="value"
                                optionValue={["label"]}
                                size="small"
                                disabled={searchCriteria.disabled}
                                defaultValue={searchCriteria.defaultValue}
                                key={createKey("search", searchCriteria.field)}
                            />
                        );
                    } else if (
                        searchCriteria.optionsRequest &&
                        searchCriteria.autocompleteMinLetters &&
                        searchCriteria.type === "autocomplete"
                    ) {
                        return (
                            <AutoCompleteInputV2
                                autocompleteMinLetters={searchCriteria.autocompleteMinLetters}
                                disabledCheck={searchCriteria.disabled}
                                label={searchCriteria.label}
                                value={props.searchCriteriaText[searchCriteria.field] ?? ""}
                                setValue={(value: string) => {
                                    const newState: Record<string, string> = { ...props.searchCriteriaText };
                                    newState[searchCriteria.field] = value;
                                    props.updateSearchCriteriaState(newState);
                                }}
                                optionsRequest={searchCriteria.optionsRequest}
                                disabled={searchCriteria.disabled}
                                key={createKey("search", searchCriteria.field)}
                            />
                        );
                    } else if (searchCriteria.type as DatePickerType) {
                        return (
                            //TODO :: fix
                            // eslint-disable-next-line react/jsx-key
                            <TBDatePicker
                                label={searchCriteria.label}
                                datepickers={props.getDatePickers(searchCriteria)}
                                pageTitle={props.datePickerPageTitle}
                                orientation={props.getDatePickerOrientation(searchCriteria)}
                            />
                        );
                    } else {
                        return (
                            <InputField
                                key={createKey("search", searchCriteria.field)}
                                disabled={searchCriteria.disabled}
                                label={searchCriteria.label}
                                value={props.searchCriteriaText[searchCriteria.field] ?? ""}
                                setValue={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    //fieldLengthLimit is for applying length limit on input field.
                                    if (
                                        searchCriteria.fieldLengthLimit &&
                                        event.target.value.length > searchCriteria.fieldLengthLimit
                                    ) {
                                        return;
                                    }
                                    setSearchCriteriaStateFromEvent(event, searchCriteria);
                                }}
                                inputLimitation={((): string[] => {
                                    const limitation: string[] = [];
                                    if (
                                        searchCriteria.freeInputRestrigtion &&
                                        searchCriteria.freeInputRestrigtion.includes("NUMBERS")
                                    ) {
                                        limitation.push("0-9");
                                    }

                                    if (
                                        searchCriteria.freeInputRestrigtion &&
                                        searchCriteria.freeInputRestrigtion.includes("WILDCARDS")
                                    ) {
                                        limitation.push("*");
                                    }
                                    return limitation;
                                })()}
                                clearButton
                            />
                        );
                    }
                }
            })}
        </div>
    );
};
