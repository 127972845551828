/* author: Bhawna Semwal  */

import { EditPendingLinked } from "components/EditPendingLinked";
import { donationDeclarationColumns } from "./DonationDeclarationSearchMask";

export const EditPendingLinkedDonationDeclaration = (): React.JSX.Element => {
    return (
        <EditPendingLinked
            columns={donationDeclarationColumns}
            dataRequest={{
                path: "/request/donationDeclaration/editPending",
            }}
            identifier="donationDelcarationStatus"
            title="Schenkungsmeldung"
        />
    );
};
