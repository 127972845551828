/* author: JESCHKE Moritz */

import { Divider, Tooltip, Typography } from "@mui/material";
import HistoryToggleOffRoundedIcon from "@mui/icons-material/HistoryToggleOffRounded";
import HMobiledataIcon from "@mui/icons-material/HMobiledata";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Colors } from "./Colors";
import { ButtonPrimary, ButtonSecondary, EditButton, IEditButton } from "./Button";

export interface IOverviewHeadLine {
    title: string;
    fuctionText?: {
        label: string;
        onTextClick: () => void;
    };
    disableIconButtons?: boolean;
    onInfoClick?: () => void;
    onTimeStampClick?: () => void;
    hideTimeSliceButton?: boolean;
    useTimeSliceButtonForHistory?: boolean;
    buttons?: IOverviewButton[];
    hasChangedData?: boolean;
    editButton?: boolean | IEditButton;
}

export interface IOverviewButton {
    title: string;
    filled: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const OverviewHeadLine = (props: IOverviewHeadLine): React.JSX.Element => {
    const getFunctionText = (): React.JSX.Element | null => {
        if (!props.fuctionText) return null;

        if (props.fuctionText.label) {
            return (
                <Typography
                    sx={{ fontWeight: "bold", fontSize: "1rem", color: Colors.blue, cursor: "pointer" }}
                    onClick={props.fuctionText.onTextClick}
                >
                    {" " + props.fuctionText.label}
                </Typography>
            );
        } else {
            return <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>{" -"}</Typography>;
        }
    };

    return (
        <div style={{ marginBottom: "1rem" }}>
            <div style={{ display: "flex", gap: "0.5rem" }}>
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        color: props.hasChangedData ? Colors.lightGreen : "initial",
                    }}
                >
                    {props.title}
                </Typography>
                {getFunctionText()}

                <div style={{ color: Colors.blue, display: "flex", gap: "0.25rem" }}>
                    {!props.disableIconButtons && (
                        <div style={{ display: "flex", gap: "0.25rem" }}>
                            <div
                                style={{
                                    transform: "rotate(180deg)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    cursor: props.onInfoClick !== undefined ? "pointer" : "default",
                                    color: props.onInfoClick !== undefined ? "" : Colors.darkGrey,
                                }}
                                onClick={() => {
                                    if (props.onInfoClick !== undefined) {
                                        props.onInfoClick();
                                    }
                                }}
                            >
                                <ErrorOutlineRoundedIcon />
                            </div>
                            {!props.hideTimeSliceButton && (
                                <div
                                    style={{
                                        cursor: props.onTimeStampClick !== undefined ? "pointer" : "default",
                                        color: props.onTimeStampClick !== undefined ? "" : Colors.darkGrey,
                                    }}
                                    onClick={() => {
                                        if (props.onTimeStampClick !== undefined) {
                                            props.onTimeStampClick();
                                        }
                                    }}
                                >
                                    <Tooltip
                                        title={props.useTimeSliceButtonForHistory ? "Historie" : "Alle Zeitscheiben"}
                                        disableInteractive
                                        placement="top"
                                    >
                                        {props.useTimeSliceButtonForHistory ? (
                                            <HMobiledataIcon />
                                        ) : (
                                            <HistoryToggleOffRoundedIcon />
                                        )}
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}

                    {props.buttons?.map((button: IOverviewButton) => {
                        const customProps = {
                            key: button.title,
                            title: button.title,
                            onClick: button.onClick,
                        };

                        return button.filled ? (
                            <ButtonPrimary {...customProps} />
                        ) : (
                            <ButtonSecondary {...customProps} />
                        );
                    })}
                    {props.editButton && (
                        <EditButton
                            linkTo={typeof props.editButton === "object" ? props.editButton.linkTo : undefined}
                        />
                    )}
                </div>
            </div>
            <Divider sx={{ background: Colors.darkGrey, height: "0.125rem" }} />
        </div>
    );
};
