/* author: JESCHKE Moritz */

import { Typography } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { Detailview, IDetailView } from "components/Detailview";
import { Footer, IFooter } from "components/Footer";
import { IOverviewData, OverviewData } from "components/OverviewData";
import { IdSelector } from "components/TBD/IdSelector";
import { useEffect, useState } from "react";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router";

export const DerivativesDetails = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const navigate: NavigateFunction = useNavigate();
    const [data, setData] = useState<Record<string, string>>({});

    //TBD
    const [derivativeId, setDerivativeId] = useState<string>(params.derivativeId ? params.derivativeId : "");

    const generalInfo: IOverviewData = {
        headline: {
            title: "Informationen",
        },
        attributes: [
            { field: "categoryFlag", headerName: "Kategorie" },
            { field: "isin", headerName: "ISIN" },
            { field: "shortName", headerName: "Kurzbezeichnung" },
            { field: "longName", headerName: "Langbezeichnung" },
            { field: "underlying", headerName: "Underlying ISIN" },
            { field: "underlyingSecurityShortname", headerName: "Underlying Name" },
            { field: "derivativeCode", headerName: "Derivate Code" },
        ],
        columnNumber: 4,
        data: data,
    };

    const footer: IFooter = {
        buttons: [
            { title: "Abbrechen", onClick: () => navigate("/home"), filled: false },
            {
                title: "Zurück",
                onClick: () => {
                    navigate(-1);
                },
                filled: false,
            },
        ],
    };

    const derivates: IDetailView = {
        detailData: {
            headline: {
                title: "Derivate",
            },
            cards: [
                {
                    icon: null,
                    dataKeys: [
                        [
                            { field: "cfiCode", label: "CFI Code" },
                            { field: "exchangeCode", label: "Börsenkürzel" },
                            { field: "derivatType", label: "Derivatetyp" },
                            { field: "optionType", label: "Optionstyp" },
                            { field: "derivatStatus", label: "Derivate Status" },
                            { field: "expDate", label: "Laufzeitende", type: "date" },
                            { field: "expiration", label: "Ablaufdatum (MMYY)" },
                            { field: "strikePrice", label: "Basispreis" },
                            { field: "strikePriceCur", label: "Währung Basispreis" },
                            { field: "contractSize", label: "Kontraktgröße" },
                            { field: "contractSizeUnit", label: "Währung Kontraktgröße" },
                        ],
                        [
                            { field: "derivatVersion", label: "Derivatsversion" },
                            { field: "startDate", label: "Laufzeitbeginn", type: "date" },
                            { field: "underlyingType", label: "Typ des Underlying" },
                            { field: "settlementType", label: "Ausübungsart" },
                            { field: "productName", label: "Name strukturiertes Produkt" },
                            { field: "positionType", label: "Position Type" },
                            { field: "derivativeMethod", label: "Derivate-Methode" },
                            { field: "optionStyle", label: "Option Style" },
                        ],
                    ],
                    techKeys: [
                        [
                            { field: "changeUser", label: "Geändert von" },
                            { field: "releaseUser", label: "Freigegeben von" },
                            { field: "updateTs", label: "Aktualisierungsdatum", type: "date" },
                            { field: "insertTs", label: "Einfügedatum", type: "date" },
                        ],
                    ],
                    data: data,
                },
            ],
        },
    };

    useEffect(() => {
        async function fetchData() {
            const response = await sendGetRequest({
                path: "/security/derivatives",
                params: { derivativeId: derivativeId },
            });

            setData(response.data);
        }

        fetchData();
    }, [derivativeId]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        //TBD
        <div>
            <IdSelector label="Derivative Id" setId={setDerivativeId} />

            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                Übersicht Derivate
            </Typography>

            <OverviewData
                columnNumber={generalInfo.columnNumber}
                attributes={generalInfo.attributes}
                data={generalInfo.data}
                headline={generalInfo.headline}
            />

            <Detailview detailData={derivates.detailData} width={derivates.width} />

            <div id="footer">
                <Footer buttons={footer.buttons} />
            </div>
        </div>
    );
};
