/* author: JESCHKE Moritz */

import { DetailviewCard, IDetailviewCard } from "./DetailviewCard";
import { IOverviewHeadLine, OverviewHeadLine } from "./OverviewHeadLine";

import { Footer, IFooter } from "./Footer";
import { NavigateFunction, useNavigate } from "react-router";
import { hasChangedValues } from "./Converter";
import { IOverviewViewStackAdder } from "./Overview";

export interface IDetailView {
    detailData: {
        headline: IOverviewHeadLine;
        cards: IDetailviewCard[];
    };
    width?: string;
}

export interface IDetailViewWithSetter extends IOverviewViewStackAdder, IDetailView {
    versionView?: React.JSX.Element;
}

export const Detailview = (props: IDetailViewWithSetter): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const footer: IFooter = {
        buttons: [{ title: "Abbrechen", filled: false, onClick: () => navigate("/home") }],
    };

    return (
        <div>
            <div style={{ width: props.width ? props.width : "100%", paddingBottom: "5rem" }}>
                <OverviewHeadLine
                    title={props.detailData.headline.title}
                    fuctionText={props.detailData.headline.fuctionText}
                    onInfoClick={props.detailData.headline.onInfoClick}
                    buttons={props.detailData.headline.buttons}
                    onTimeStampClick={
                        props.versionView
                            ? () => {
                                  props.versionView &&
                                      props.viewStackAdd &&
                                      props.viewStackAdd(props.versionView, props.detailData.headline.title);
                              }
                            : props.detailData.headline.onTimeStampClick
                    }
                    hasChangedData={
                        props.detailData.cards.length === 1 ? hasChangedValues(props.detailData.cards[0].data) : false
                    }
                    useTimeSliceButtonForHistory={props.detailData.headline.useTimeSliceButtonForHistory}
                    editButton={props.detailData.headline.editButton}
                    hideTimeSliceButton={props.detailData.headline.hideTimeSliceButton}
                />
                {props.detailData.cards.map((card: IDetailviewCard) => {
                    return (
                        <DetailviewCard
                            key={""}
                            icon={card.icon}
                            dataKeys={card.dataKeys}
                            techKeys={card.techKeys}
                            data={card.data}
                        />
                    );
                })}
            </div>
            <Footer buttons={footer.buttons} />
        </div>
    );
};
