/* author: JESCHKE Moritz */

import { useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Detailview, IDetailView } from "components/Detailview";
import { IOverviewViewStackAdder } from "components/Overview";
import { IOverviewHeadLine } from "components/OverviewHeadLine";
import { useNavigate } from "react-router";
import { NvVersions } from "./NvVersions";

interface INvDetails extends IOverviewViewStackAdder {
    fsaNvId: string;
    nvDataId: string;
    icon: React.JSX.Element | null;
    functionTextDisable?: boolean;
}

export const NvDetails = (props: INvDetails): React.JSX.Element => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [fiscalUnit, setFiscalUnit] = useState<{ [key: string]: string }>({});

    const detailDataHeadLine: IOverviewHeadLine = !props.functionTextDisable
        ? {
              title: "Nichtveranlagungsbescheinigung (NV) zur",
              fuctionText: {
                  label: fiscalUnit.fiscalUnit,
                  onTextClick: () => navigate("/overviewFu/" + fiscalUnit.fiscalUnitId),
              },
          }
        : { title: "Nichtveranlagungsbescheinigung (NV) zur " + fiscalUnit.fiscalUnit };

    const nv: IDetailView = {
        detailData: {
            headline: detailDataHeadLine,
            cards: [
                {
                    icon: props.icon,
                    dataKeys: [
                        [
                            { field: "nvNr", label: "NV-Bescheinigungsnr." },
                            { field: "nvType", label: "NV-Typ" },
                            { field: "deleteFlag", label: "Löschkennzeichen" },
                            { field: "taxOffice", label: "NV-Finanzamt" },
                            { field: "issueDate", label: "NV-Ausstellungsdatum", type: "date" },
                            { field: "validFrom", label: "Gültig von", type: "date" },
                            { field: "validTo", label: "Gültig bis", type: "date" },
                        ],
                        [
                            { field: "lastNamePerson1", label: "Nachname Person 1" },
                            { field: "givenNamePerson1", label: "Vorname Person 1" },
                            { field: "birthDatePerson1", label: "Geburtsdatum Person 1", type: "date" },
                            { field: "tinPerson1", label: "TIN Person 1" },
                            { field: "birthNamePerson1", label: "Geburtsname Person 1", spacingAfter: true },
                            { field: "lastNamePerson2", label: "Nachname Person 2" },
                            { field: "givenNamePerson2", label: "Vorname Person 2" },
                            { field: "birthDatePerson2", label: "Geburtsdatum Person 2", type: "date" },
                            { field: "tinPerson2", label: "TIN Person 2" },
                            { field: "birthNamePerson2", label: "Geburtsname Person 2" },
                        ],
                    ],
                    techKeys: [
                        [
                            { field: "execState", label: "Ausführungsstatus" },
                            { field: "changeUser", label: "Geändert von" },
                            { field: "releaseUser", label: "Freigegeben von" },
                            { field: "updateTS", label: "Aktualisierungsdatum", type: "date" },
                            { field: "insertTS", label: "Einfügedatum", type: "date" },
                        ],
                    ],
                    data: data,
                },
            ],
        },
    };

    useEffect(() => {
        sendGetRequest({
            path: "/customerstaticdata/fsaNv/getNvData",
            params: { nvDataId: props.nvDataId },
        }).then((response) => {
            setData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/fiscalUnit/getFiscalUnitByNvDataId",
            params: { nvDataId: props.nvDataId },
        }).then((response) => {
            setFiscalUnit(response.data);
        });
    }, []);

    return (
        <Detailview
            viewStackAdd={props.viewStackAdd}
            detailData={nv.detailData}
            versionView={<NvVersions fsaNvId={+props.fsaNvId} />}
        />
    );
};
