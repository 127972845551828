/* author: Aashish Upadhyay  */

import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const FsaDataHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const fsaDataId: number = Number(params.fsaDataId);

    const labels: IHistoryKeys[] = [
        { field: "rolloverYear", label: "Letztes Übernahmejahr SPB" },
        { field: "titlePerson1", label: "Titel Person 1" },
        { field: "titlePerson2", label: "Titel Person 2" },
        { field: "familyStatus", label: "Familienstand" },
        { field: "fsaTyp", label: "FSA Typ" },
        { field: "maxLimit", label: "Max. gesetzliches Limit" },
        { field: "privateCustomer", label: "Privatkunde" },
        { field: "lastNamePerson1", label: "Nachname Person 1" },
        { field: "givenNamePerson1", label: "Vorname Person 1" },
        { field: "birthDatePerson1", label: "Geburtsdatum Person 1", type: "date" },
        { field: "tinPerson1", label: "TIN Person 1" },
        { field: "birthNamePerson1", label: "Geburtsname Person 1" },
        { field: "lastNamePerson2", label: "Nachname Person 2" },
        { field: "givenNamePerson2", label: "Vorname Person 2" },
        { field: "birthDatePerson2", label: "Geburtsdatum Person 2", type: "date" },
        { field: "tinPerson2", label: "TIN Person 2" },
        { field: "birthNamePerson2", label: "Geburtsname Person 2" },
    ];

    return (
        <HistoryView
            title={"Historie FSA Daten"}
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/fsaNv/fsaDataHistory",
                params: { fsaDataId: fsaDataId },
            }}
            identifier={"fsaDataHistory"}
        />
    );
};
