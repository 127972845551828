/* author: JESCHKE Moritz */

import axios, { AxiosResponse } from "axios";
import {
    AllowedRequestsTypeDelete,
    AllowedRequestsTypeGet,
    AllowedRequestsTypePostWithUrlEncodedParams,
    AllowedRequestsTypePut,
} from "./AllowedRequestsType";
import { renewToken } from "pages/login/Login";
import { HttpStatus } from "./HttpStatusCodes";

/* ### CONFIGURATION ### */
//default base url for http requests
let baseUrl: string = "";
const TESTSET_BORDER_APS01: number = 100;
const TESTSET_BORDER_APS02: number = 200;

/* !!! ALL ALLOWED REQUEST AND PARAMETERS ARE STORED IN THE AllowedRequestsType.tsx FILE !!! */

/* ### CODE ### */
let authToken: string = "";

export const clearAuthToken = (): void => {
    authToken = "";
};

export const setAuthToken = (newAuthToken: string): void => {
    authToken = newAuthToken;
};

export const setUrl = (newTestset: string): void => {
    const aps01: string = "http://10.110.96.40:2";
    const aps02: string = "http://10.110.96.71:2";
    const aps03: string = "http://10.110.96.9:2";
    const end: string = "3/webservices";
    if (Number(newTestset) < TESTSET_BORDER_APS01) {
        baseUrl = aps01 + newTestset + end;
    } else if (Number(newTestset) < TESTSET_BORDER_APS02) {
        baseUrl = aps02 + newTestset + end;
    } else {
        baseUrl = aps03 + newTestset + end;
    }
};

export type GET_REQUEST_TYPE = Promise<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | AxiosResponse<any, any>
    | {
          // eslint-disable-next-line @typescript-eslint/ban-types
          data: {};
          status: number;
          statusText: string;
          // eslint-disable-next-line @typescript-eslint/ban-types
          headers: {};
          // eslint-disable-next-line @typescript-eslint/ban-types
          config: {};
          // eslint-disable-next-line @typescript-eslint/ban-types
          request: {};
      }
>;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendGetRequest = async (request: AllowedRequestsTypeGet, resend: boolean = true) => {
    setAuthHeader();

    let response;
    if ("params" in request) {
        //request with params
        //extract params and surpress typescript error (impossible cuz check above)
        const params = request.params;
        response = await axios
            .get(baseUrl + request.path, { params })
            .catch((err: Record<string, unknown>) => handleError(err));

        //if accesstoken is expired resend request
        if (resend && response.status === HttpStatus.UNAUTHORIZED) {
            console.log("Resend...");
            await renewToken();
            setAuthHeader();
            response = await axios
                .get(baseUrl + request.path, { params })
                .catch((err: Record<string, unknown>) => handleError(err));
        }
    } else {
        //request without params
        response = await axios.get(baseUrl + request.path).catch((err: Record<string, unknown>) => handleError(err));

        //if accesstoken is expired resend request
        if (resend && response.status === HttpStatus.UNAUTHORIZED) {
            console.log("Resend...");
            await renewToken();
            setAuthHeader();
            response = await axios
                .get(baseUrl + request.path)
                .catch((err: Record<string, unknown>) => handleError(err));
        }
    }

    return response;
};

export const fileDownload = async (
    request: AllowedRequestsTypeGet,
    fileName: string,
    resend: boolean = true
): Promise<void> => {
    setAuthHeader();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = (request as any).params;

    const response = await axios
        .get(baseUrl + request.path, {
            responseType: "arraybuffer",
            params: params,
        })
        .catch((err: Record<string, unknown>) => handleError(err));

    //if accesstoken is expired resend request
    if (resend && response.status === HttpStatus.UNAUTHORIZED) {
        console.log("Resend...");
        await renewToken();
        fileDownload(request, fileName, false);
    }

    const file: Blob = new Blob([response.data]);
    const a: HTMLAnchorElement = document.createElement("a"),
        url = URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendPutRequest = async (request: AllowedRequestsTypePut, resend: boolean = true) => {
    setAuthHeader();

    let response = await axios
        .put(baseUrl + request.path, request.body, {
            headers: { "Content-Type": "application/json" },
        })

        .catch((err: Record<string, unknown>) => handleError(err));

    console.log(response.data);
    //if accesstoken is expired resend request
    if (resend && response.status === HttpStatus.UNAUTHORIZED) {
        console.log("Resend...");
        await renewToken();
        setAuthHeader();
        response = await axios
            .put(baseUrl + request.path, request.body, {
                headers: { "Content-Type": "application/json" },
            })
            .catch((err: Record<string, unknown>) => handleError(err));
    }
    return response;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendDeleteRequest = async (request: AllowedRequestsTypeDelete, resend: boolean = true) => {
    setAuthHeader();

    let response;

    //request with params
    //extract params and surpress typescript error (impossible cuz check above)
    const params = request.params;
    response = await axios
        .delete(baseUrl + request.path, { params })
        .catch((err: Record<string, unknown>) => handleError(err));

    //if accesstoken is expired resend request
    if (resend && response.status === HttpStatus.UNAUTHORIZED) {
        console.log("Resend...");
        await renewToken();
        setAuthHeader();
        response = await axios
            .delete(baseUrl + request.path, { params })
            .catch((err: Record<string, unknown>) => handleError(err));
    }

    return response;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendPostRequestWithUrlEncodedParams = async (
    request: AllowedRequestsTypePostWithUrlEncodedParams,
    resend: boolean = true
) => {
    setAuthHeader();

    //create params
    const params: URLSearchParams = new URLSearchParams();

    const keys = Object.keys(request.body) as Array<keyof typeof request.body>;
    keys.forEach((key) => {
        params.append(key, request.body[key]);
    });

    //send request
    let response = await axios
        .post(baseUrl + request.path, params)
        .catch((err: Record<string, unknown>) => handleError(err));

    //if accesstoken is expired resend request
    if (resend && response.status === HttpStatus.UNAUTHORIZED) {
        console.log("Resend...");
        await renewToken();
        setAuthHeader();
        response = await axios
            .post(baseUrl + request.path, params)
            .catch((err: Record<string, unknown>) => handleError(err));
    }

    return response;
};

const setAuthHeader = () => {
    axios.defaults.headers.common["Authorization"] = authToken;
};

const handleError = (error: Record<string, unknown>) => {
    console.log("error", error);
    console.log(typeof error);

    //default error
    let status: number = HttpStatus.UNHANDLED_ERROR;
    let statusText: string = "Unhandled error";

    //update error
    switch (error.message) {
        case "Network Error":
            status = HttpStatus.SERVICE_UNAVAILABLE;
            statusText = "Service Unavailable";
            alert("Network Error");
            break;
        case "Request failed with status code 401":
            status = HttpStatus.UNAUTHORIZED;
            statusText = "Unauthorized";
            break;
        default:
            console.log("Unhandled error message:", error.message);
    }

    //generate error and return it
    return {
        // `data` is the response that was provided by the server
        data: {},
        // `status` is the HTTP status code from the server response
        status: status,
        // `statusText` is the HTTP status message from the server response
        statusText: statusText,
        // `headers` the HTTP headers that the server responded with
        // All header names are lower cased and can be accessed using the bracket notation.
        // Example: `response.headers['content-type']`
        headers: {},
        // `config` is the config that was provided to `axios` for the request
        config: {},
        // `request` is the request that generated this response
        // It is the last ClientRequest instance in node.js (in redirects)
        // and an XMLHttpRequest instance in the browser
        request: {},
    };
};

export const isReponseEmpty = (obj: Record<string, unknown>): boolean => {
    return Object.keys(obj).length == 1 && Object.keys(obj)[0] == "type" && obj["type"] == "fsaNvIdentificationData";
};
