/* author: JESCHKE Moritz */

import { Card, IconButton, Typography } from "@mui/material";
import { RootState } from "application/redux/Store";
import { YerDialog } from "dialogs/YerDialog";
import { useState } from "react";
import { useSelector } from "react-redux";
import EventIcon from "@mui/icons-material/Event";
import { FromYer } from "./FromYer";
import { ITableColumn, ITableContextMenu, Table } from "components/Table";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";

export const Yer = () => {
    const IDENTIFIER: TableidentifierType = "yer";
    const yerData = useSelector((state: RootState) => state.yer.yerData);
    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    const [requestId, setRequestId] = useState<string>("");
    const [yerDialogOpen, setYerDialogOpen] = useState<boolean>(yerData.length === 0);

    const fiscalUnit = useSelector((state: RootState) => state.yer.fiscalUnit);
    const execState = useSelector((state: RootState) => state.yer.execState);
    const from = useSelector((state: RootState) => state.yer.from);
    const to = useSelector((state: RootState) => state.yer.to);

    const columns: ITableColumn[] = [
        { field: "extRequestID", title: "Externe Referenz" },
        { field: "requestType", title: "Auftragsart" },
        { field: "fromDate", title: "Zeitraum von" },
        { field: "toDate", title: "Zeitraum bis" },
        {
            field: "processingStartTime",
            title: "Verarbeitungsdatum Beginn",
            type: "date",
        },
        {
            field: "processingEndTime",
            title: "Verarbeitungsdatum Ende",
            type: "date",
        },
        {
            field: "startProcessingDate",
            title: "Starten ab",
            type: "date",
        },
        { field: "mode", title: "Durchführungsmodus" },
        { field: "source", title: "Quelle" },
        { field: "execState", title: "Auftragsstatus" },
        { field: "reportType", title: "Bescheinungstyp" },
        { field: "nullReport", title: "Nullerbescheinigung" },
        {
            field: "copies",
            title: "Anzahl Kopien",
            type: "numeric",
        },
        { field: "adr1", title: "AddressField1" },
        { field: "adr2", title: "AddressField2" },
        { field: "adr3", title: "AddressField3" },
        { field: "adr4", title: "AddressField4" },
        { field: "adr5", title: "AddressField5" },
        { field: "adr6", title: "AddressField6" },
        { field: "adr7", title: "AddressField7" },
    ];

    const contextMenu: ITableContextMenu = {
        //@ts-ignore
        contextActions: [{ label: "Bescheinigungsdaten", action: () => setRequestId(currentRowData.extRequestID) }],
        setCurrentRowData: setCurrentRowData,
    };

    return (
        <div style={{ height: "90%", margin: "1em" }}>
            <YerDialog
                fiscalUnit={fiscalUnit}
                execState={execState}
                from={from}
                to={to}
                isOpen={yerDialogOpen}
                setDialogOpen={setYerDialogOpen}
            />

            <Card sx={{ height: "50%" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <IconButton aria-label="Dialog öffnen" color="primary" onClick={() => setYerDialogOpen(true)}>
                        <EventIcon />
                    </IconButton>
                </div>

                <Table
                    columnsDefinition={columns}
                    tableData={yerData}
                    identifier={IDENTIFIER}
                    contextMenu={contextMenu}
                />
            </Card>

            {requestId !== "" ? (
                <div>
                    <Typography>{"Req Value Daten  für RequestId: " + requestId}</Typography>
                    <FromYer requestId={requestId} />
                </div>
            ) : null}
        </div>
    );
};
