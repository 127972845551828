/* author: PRASHANT Pal  */
import { Typography } from "@mui/material";

export interface ILabelValue {
    label: string;
    value: string;
}

export const LabelValue = (props: ILabelValue): React.JSX.Element => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                }}
            >
                {props.label + ":\xa0"}
            </Typography>

            <Typography
                sx={{
                    fontSize: "1rem",
                }}
            >
                {props.value}
            </Typography>
        </div>
    );
};
