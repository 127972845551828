/* author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { convert } from "components/Converter";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { KeyTableConsts } from "components/KeyTableConsts";
import { Location, Params, useLocation, useParams } from "react-router";

export const PORTFOLIO_TRANSFER_DETAIL_SECTION: string = "Anschaffungsdaten";
export const PORTFOLIO_TRANSFER_DETAIL_IDENTIFIER: string = "portfolioTransferDetail";

export const PortfolioTransferDetailsEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const section: string | undefined = params.section;
    const tedRepExchangeId: number = Number(params.tedRepExchangeId === "new" ? -1 : params.tedRepExchangeId);

    const location: Location = useLocation();
    const rowData: Record<string, string> = location.state && location.state.rowData;
    const extraData: Record<string, unknown> = location.state && location.state.extraData;
    const disableEdit: boolean = !extraData.isEditable;

    const REQUEST = () =>
        sendGetRequest({
            path: "/ted/repexchange/byId-with-keydata",
            params: { tedRepExchangeId: tedRepExchangeId },
        });

    const portfolioTrasnferDetailsEdit: IEditDataViewUserConfiguration = {
        headline: PORTFOLIO_TRANSFER_DETAIL_SECTION,
        attributes: [
            {
                field: "acqdt",
                headerName: "W01 Tag - Steuerl. Anschaffungstag",
                type: "date",
                disabled: disableEdit,
            },
            { field: "acqtm", headerName: "W01 Uhrzeit - Steuerl. Anschaffungsuhrzeit", disabled: disableEdit },
            {
                field: "histholdingind",
                headerName: "W02 - Übertrag aus Altbestand (keine Finanzinnovation)",
                dropdown: { keyTableTabname: KeyTableConsts.TED_ALTBESTAND },
                disabled: disableEdit,
            },
            { field: "acqnom", headerName: "W03 - Anschaffungsmenge", disabled: disableEdit },
            {
                field: "acqamteur",
                headerName: "W04 - Steuerl. (bereinigter) Anschaffungswert in EUR",
                disabled: disableEdit,
            },
            {
                field: "acqamteursign",
                headerName: "W05 - Kennzeichen Anschaffungswert verfügbar",
                dropdown: { keyTableTabname: KeyTableConsts.TED_FREMDWAEH_FINNO },
                disabled: disableEdit,
            },
            {
                field: "acqamt",
                headerName: "W06 - Steuerl. (bereinigter) Anschaffungswert in Fremdwährung",
                disabled: disableEdit,
            },
            {
                field: "acqamtccy",
                headerName: "W07 - Fremdwährung",
                dropdown: { keyTableTabname: KeyTableConsts.WM_W02 },
                disabled: disableEdit,
            },
            { field: "acrinteur", headerName: "W08 - Stückzinsen in EUR", disabled: disableEdit },
            {
                field: "intprofeur",
                headerName: "W09 - Zwischengewinn je Anteil in EUR (ID904)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accuprofs94eur",
                headerName: "W10 - Akkumulierte thesaurierte Erträge je Anteil in EUR ab 1994 (ID905)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accuprofretain",
                headerName: "W11 - Schätzwert akkumulierte thesaurierte Erträge je Anteil in EUR (ID917)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accusurpluseur",
                headerName: "W12 - Akkumulierter Mehrbetrag je Anteil (ID909)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "realprofeur",
                headerName: "W13 - Immobiliengewinn je Anteil (ID908)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accuproffund",
                headerName:
                    "W14 - Akkumulierte ausgeschüttete Altveräußerungsgewinne je Anteil in Fondswährung (ID919)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accuproffundccy",
                headerName: "W15 - Fondswährung der akkumulierten ausgeschütteten Altveräußerungsgewinne",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accuprofs09eur",
                headerName:
                    "W16 - Bereinigte akkumulierte ausschüttungsgleiche Erträge je Anteil ab 01.01.2009 in EUR (ID921)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "accusubsprofeur",
                headerName: "W17 - Akkumulierte Substanzausschüttung je Anteil in EUR (ID920)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "zastamteur",
                headerName:
                    "W18 - Noch zu verzastender Betrag (im Fall zurückliegender steuerneutraler Fondsfusion) in EUR",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "ntrlMergerFundsDate",
                headerName: "W19 - Datum der letzten steuerneutralen Fondsfusion",
                type: "date",
                disabled: disableEdit,
            },
            {
                field: "scrpDate",
                headerName: "W20 - Datum der ermittelten Investmentfondswerte",
                type: "date",
                disabled: disableEdit,
            },
            {
                field: "purchaseStatDate",
                headerName: "W21 - Anschaffungstag aus fiktiver Veräußerung",
                type: "date",
                disabled: disableEdit,
            },
            {
                field: "accuStatProc",
                headerName: "W22 - Akkumulierter thesaurierter Ertrag aus fiktiver Veräußerung am 31.12.2017",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "unrealisedStatProf",
                headerName: "W23 - Zwischengewinn aus fiktiver Veräußerung am 31.12.2017",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "statReturn",
                headerName:
                    "W24 - Veräußerungsergebnis aus fiktiver Veräußerung am 31.12.2017 aus sonst. Gewinnen/Verlusten",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "shareProfStatReturn",
                headerName:
                    "W25 - Veräußerungsergebnis aus fiktiver Veräußerung am 31.12.2017 aus Aktiengewinnen/-verlusten",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "statAssessRepl",
                headerName:
                    "W26 - Ersatzbemessungsgrundlage aus fiktiver Veräußerung am 31.12.2017 aus sonst. Gewinnen/Verlusten",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "shareProfStatAssRepl",
                headerName:
                    "W27 - Ersatzbemessungsgrundlage aus fiktiver Veräußerung am 31.12.2017 aus Aktiengewinnen/-verlusten",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "statAccuReturnProf",
                headerName:
                    "W28 - Akkumuliertes Veräußerungsergebnis aus fiktiven Veräußerungen n.d. 31.12.2017 (Gewinn)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "statAccuReturnLoss",
                headerName:
                    "W29 - Akkumuliertes Veräußerungsergebnis aus fiktiven Veräußerungen n.d. 31.12.2017 (Verlust)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "statAccuAssessRepl",
                headerName: "W30 - Akkumulierte Ersatzbemessungsgrundlage aus fiktiven Veräußerungen n.d. 31.12.2017",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "assessReplIndet",
                headerName: "W31 - Ersatzbemessungsgrundlage nicht ermittelbar bei fiktiver Veräußerung am 31.12.2017",
                type: "switch",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            { field: "allowanceExAnte", headerName: "W32 - Vorabpauschale (VAP)", disabled: disableEdit },
            {
                field: "originalIsin",
                headerName: "W33 - Ursprüngliche ISIN aus steuerneutraler Fondsfusion",
                disabled: disableEdit,
            },
            {
                field: "w34",
                headerName:
                    "W34 - Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Mischfonds vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w35",
                headerName:
                    "W35 - Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Aktienfonds vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w36",
                headerName:
                    "W36 - Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Immofonds (überw. inl. Immobilien) vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w37",
                headerName:
                    "W37 - Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - Immofonds (überw. ausl. Immobilien) vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w38",
                headerName:
                    "W38 - Akk. Veräußerungsergebnis aus fikt. Veräußerungen n. d. 31.12.2017 - sonstige Fonds (keine TFS)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w39",
                headerName:
                    "W39 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Mischfonds vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w40",
                headerName:
                    "W40 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Aktienfonds vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w41",
                headerName:
                    "W41 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Immofonds (überw. inl. Immobilien) vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w42",
                headerName:
                    "W42 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Immofonds (überw. ausl. Immobilien) vor TFS",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w43",
                headerName:
                    "W43 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - sonstige Fonds (keine TFS)",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w44",
                headerName: "W44 - Millionärsfonds",
                type: "switch",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
            {
                field: "w45",
                headerName: "W45 - Ersatzbemessungsgrundlage nicht ermittelbar bei fikt. Veräußerung n. d. 31.12.2017",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
                type: "switch",
            },
            {
                field: "w46",
                headerName: "W46 - Anschaffungskosten vor 2018",
                disabled: (values: Record<string, unknown>, fieldName: string) =>
                    isCellDisabled(values, fieldName) || disableEdit,
            },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
        checkInvalidity: [
            {
                //W21 <> W1
                condition: (data: Record<string, unknown>) => {
                    const purchaseStatDate: string = ((data["purchaseStatDate"] as string) ?? "").includes("T")
                        ? convert(data["purchaseStatDate"] as string, "date")
                        : (data["purchaseStatDate"] as string); //W21
                    const acqdt: string = (data["acqdt"] as string).includes("T")
                        ? convert(data["acqdt"] as string, "date")
                        : (data["acqdt"] as string); //W1
                    return newDate(purchaseStatDate).getTime() === newDate(acqdt).getTime();
                },
                relatedFields: ["purchaseStatDate", "acqdt"],
            },
            {
                //W21 >= 01.01.2018
                condition: (data: Record<string, unknown>) => {
                    const purchaseStatDate: string = ((data["purchaseStatDate"] as string) ?? "").includes("T")
                        ? convert(data["purchaseStatDate"] as string, "date")
                        : (data["purchaseStatDate"] as string); //W21
                    return newDate(purchaseStatDate) < new Date("01.01.2018");
                },
                relatedFields: ["purchaseStatDate"],
            },
            {
                //W22 >= 0
                condition: (data: Record<string, unknown>) => (data["accuStatProc"] as number) < 0,
                relatedFields: ["accuStatProc"],
            },
            {
                //W23 >= 0
                condition: (data: Record<string, unknown>) => (data["unrealisedStatProf"] as number) < 0,
                relatedFields: ["unrealisedStatProf"],
            },
            {
                //W26 >= 0
                condition: (data: Record<string, unknown>) => (data["statAssessRepl"] as number) < 0,
                relatedFields: ["statAssessRepl"],
            },
            {
                //W27 >= 0
                condition: (data: Record<string, unknown>) => (data["shareProfStatAssRepl"] as number) < 0,
                relatedFields: ["shareProfStatAssRepl"],
            },
            {
                //W28 >= 0
                condition: (data: Record<string, unknown>) => (data["statAccuReturnProf"] as number) < 0,
                relatedFields: ["statAccuReturnProf"],
            },
            {
                //W29 >= 0
                condition: (data: Record<string, unknown>) => (data["statAccuReturnLoss"] as number) < 0,
                relatedFields: ["statAccuReturnLoss"],
            },
            {
                //W30 >= 0
                condition: (data: Record<string, unknown>) => (data["statAccuAssessRepl"] as number) < 0,
                relatedFields: ["statAccuAssessRepl"],
            },
            {
                //W32 >= 0
                condition: (data: Record<string, unknown>) => (data["allowanceExAnte"] as number) < 0,
                relatedFields: ["allowanceExAnte"],
            },
            {
                //W39 >= 0
                condition: (data: Record<string, unknown>) => (data["w39"] as number) < 0,
                relatedFields: ["w39"],
            },
            {
                //W40 >= 0
                condition: (data: Record<string, unknown>) => (data["w40"] as number) < 0,
                relatedFields: ["w40"],
            },
            {
                //W41 >= 0
                condition: (data: Record<string, unknown>) => (data["w41"] as number) < 0,
                relatedFields: ["w41"],
            },
            {
                //W42 >= 0
                condition: (data: Record<string, unknown>) => (data["w42"] as number) < 0,
                relatedFields: ["w42"],
            },
            {
                //W43 >= 0
                condition: (data: Record<string, unknown>) => (data["w43"] as number) < 0,
                relatedFields: ["w43"],
            },
            {
                //W28 or W29 filled and DÜ-Jahr >= 2019 -> W34-W38 one is filled
                condition: (data: Record<string, unknown>) => {
                    //W28 filled
                    const w28Filled: boolean =
                        data["statAccuReturnProf"] !== undefined && data["statAccuReturnProf"] !== "";
                    //W28 filled
                    const w29Filled: boolean =
                        data["statAccuReturnLoss"] !== undefined && data["statAccuReturnLoss"] !== "";
                    //DÜ-Jahr > 2019
                    const creationDateGreaterThen2019: boolean =
                        newDate(data["creationDate"] as string).getFullYear() >= YEAR_2019;
                    //W34-W38 one is filled
                    const w34TillW38OneIsFilled: boolean =
                        (data["w34"] !== undefined && data["w34"] !== "") ||
                        (data["w35"] !== undefined && data["w35"] !== "") ||
                        (data["w36"] !== undefined && data["w36"] !== "") ||
                        (data["w37"] !== undefined && data["w37"] !== "") ||
                        (data["w38"] !== undefined && data["w38"] !== "");

                    return (w28Filled || w29Filled) && creationDateGreaterThen2019 && !w34TillW38OneIsFilled;
                },
                relatedFields: ["statAccuReturnProf", "statAccuReturnLoss", "w34", "w35", "w36", "w37", "w38"],
            },
            {
                //W30 filled and DÜ-Jahr >= 2019 -> W39-W43 one is filled
                condition: (data: Record<string, unknown>) => {
                    //W30 filled
                    const w30Filled: boolean =
                        data["statAccuAssessRepl"] !== undefined && data["statAccuAssessRepl"] !== "";
                    //DÜ-Jahr > 2019
                    const creationDateGreaterThen2019: boolean =
                        newDate(data["creationDate"] as string).getFullYear() >= YEAR_2019;
                    //W39-W43 one is filled
                    const w39TillW43OneIsFilled: boolean =
                        (data["w39"] !== undefined && data["w39"] !== "") ||
                        (data["w40"] !== undefined && data["w40"] !== "") ||
                        (data["w41"] !== undefined && data["w41"] !== "") ||
                        (data["w42"] !== undefined && data["w42"] !== "") ||
                        (data["w43"] !== undefined && data["w43"] !== "");

                    return w30Filled && creationDateGreaterThen2019 && !w39TillW43OneIsFilled;
                },
                relatedFields: ["statAccuAssessRepl", "w39", "w40", "w41", "w42", "w43"],
            },
            {
                //W21 = 01.01.2018 && W1 between 01.01.2009 and 31.12.2017 || W21 > 01.01.2018 -> W46 can be filled
                condition: (data: Record<string, unknown>) => {
                    const w1: string = (data["acqdt"] as string).includes("T")
                        ? convert(data["acqdt"] as string, "date")
                        : (data["acqdt"] as string);

                    const purchaseStatDate: string = ((data["purchaseStatDate"] as string) ?? "").includes("T")
                        ? convert(data["purchaseStatDate"] as string, "date")
                        : (data["purchaseStatDate"] as string); //W21

                    const w46Filled: boolean = data["w46"] !== undefined && data["w46"] !== "";

                    return w46Filled && !isW46Editable(newDate(purchaseStatDate), newDate(w1));
                },
                relatedFields: ["acqdt", "purchaseStatDate", "w46"],
            },
        ],
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [portfolioTrasnferDetailsEdit];

    return (
        <EditMask
            id01={Number(rowData["tedExchangeId"])}
            completeEditInfo={completeEditInfo}
            title="Anschaffungsdaten"
            section={section}
        />
    );
};

const ONE_HUNDERT_THOUSAND: number = 100_000;
const YEAR_2019: number = 2019;

export const newDate = (date: string): Date => {
    if (!date) return new Date(date);
    if (date.includes(".")) {
        const d: string[] = date.split(".");
        return new Date(d[1] + "." + d[0] + "." + d[2]);
    }

    return new Date(date);
};

//COPY OF OLD CLIENT CODE AND TRANSLATED INTO TS
/**
 * Prüft ob das handle ein {@link TEDRepExchangeVO} ist; Sonst gibt super zurueck.
 * @see #m_EditableColumns
 */
const isCellDisabled = (data: Record<string, unknown>, fieldName: string): boolean => {
    const purchaseStatDate: string = ((data["purchaseStatDate"] as string) ?? "").includes("T")
        ? convert(data["purchaseStatDate"] as string, "date")
        : (data["purchaseStatDate"] as string); //W21

    //GeS: Sonderlocke für "W44 - Millionärsfonds":
    //W44 darf nur editierbar sein, wenn Betrag ab 100.000,-- (W4) und Zeitraum zwischen 10.11.2007 und 31.12.2008 (W1)
    if (fieldName === "w44") {
        const aquisitionDate: string = (data["acqdt"] as string).includes("T")
            ? convert(data["acqdt"] as string, "date")
            : (data["acqdt"] as string); //W01
        const nominal: number = data["acqamteur"] as number; //W04
        if (
            nominal < ONE_HUNDERT_THOUSAND ||
            newDate(aquisitionDate) < new Date("11.10.2007") || //10.11.2007
            newDate(aquisitionDate) > new Date("12.31.2008")
        ) {
            //31.12.2008
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Nur bei Befüllung des Feldes W21 kann eines oder mehrere der Felder W22/W23/W24/W25/W26/W27 sowie W31 gefüllt sein.
    if (
        fieldName === "accuStatProc" || //W22
        fieldName === "unrealisedStatProf" || //W23
        fieldName === "statReturn" || //W24
        fieldName === "shareProfStatReturn" || //W25
        fieldName === "statAssessRepl" || //W26
        fieldName === "shareProfStatAssRepl" || //W27
        fieldName === "assessReplIndet" //W31
    ) {
        if (purchaseStatDate === undefined || purchaseStatDate === "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Nur wenn das Feld W21 > 01.01.2018 ist, kann eines oder mehrere der Felder W28/W29/W30/W45 gefüllt sein.
    if (
        fieldName === "statAccuReturnProf" || //W28
        fieldName === "statAccuReturnLoss" || //W29
        fieldName === "statAccuAssessRepl" || //W30
        fieldName === "w45" //W45
    ) {
        if (
            purchaseStatDate === undefined ||
            purchaseStatDate === "" ||
            newDate(purchaseStatDate) <= new Date("01.01.2018")
        ) {
            //01.01.2018
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Nur wenn das Feld W21 > 01.01.2018 ist und entweder W28 oder W29 gefüllt ist und Übertragungsdatum-Jahr >= 2019
    //kann eines oder mehrere der Felder W34/W35/W36/W37/W38 gefüllt sein.
    const uebZeitraum: string = convert(data["creationDate"] as string, "date");

    const statAccuReturnProf: string = data["statAccuReturnProf"] as string; //W28
    const statAccuReturnLoss: string = data["statAccuReturnLoss"] as string; //W29
    if (
        fieldName === "w34" || //W34
        fieldName === "w35" || //W35
        fieldName === "w36" || //W36
        fieldName === "w37" || //W37
        fieldName === "w38" //W38
    ) {
        if (
            purchaseStatDate === undefined ||
            purchaseStatDate === "" ||
            uebZeitraum == undefined ||
            uebZeitraum === ""
        ) {
            return true; //nicht editierbar
        } else {
            if (newDate(uebZeitraum).getFullYear() >= YEAR_2019) {
                if (
                    newDate(purchaseStatDate) <= new Date("01.01.2018") || //01.01.2018
                    ((statAccuReturnProf === undefined || statAccuReturnProf === "") &&
                        (statAccuReturnLoss === undefined || statAccuReturnLoss === ""))
                ) {
                    return true; //nicht editierbar
                }
            } else {
                if (newDate(purchaseStatDate) <= new Date("01.01.2018")) {
                    //01.01.2018
                    return true; //nicht editierbar
                }
            }
        }
    }

    //GeS: Sonderlocke: Nur wenn das Feld W21 > 01.01.2018 ist und W30 gefüllt ist wenn Übertragungsdatum-Jahr >= 2019
    //kann eines oder mehrere der Felder W39/W40/W41/W42/W43 gefüllt sein.
    const statAccuAssessRepl: string = data["statAccuAssessRepl"] as string; //W30
    if (
        fieldName === "w39" || //W39
        fieldName === "w40" || //W40
        fieldName === "w41" || //W41
        fieldName === "w42" || //W42
        fieldName === "w43" //W43
    ) {
        if (
            purchaseStatDate === undefined ||
            purchaseStatDate === "" ||
            uebZeitraum === undefined ||
            uebZeitraum === ""
        ) {
            return true; //nicht editierbar
        } else {
            if (newDate(uebZeitraum).getFullYear() >= YEAR_2019) {
                if (
                    newDate(purchaseStatDate) <= new Date("01.01.2018") || //01.01.2018
                    statAccuAssessRepl === undefined ||
                    statAccuAssessRepl === ""
                ) {
                    return true; //nicht editierbar
                }
            } else {
                if (newDate(purchaseStatDate) < new Date("01.01.2018")) {
                    //01.01.2018
                    return true; //nicht editierbar
                }
            }
        }
    }

    //GeS: Sonderlocke: Wenn W24 gefüllt, dann W25/W26/W27 inaktiv.
    const statReturn: string = data["statReturn"] as string; //W24
    if (
        fieldName === "shareProfStatReturn" || //W25
        fieldName === "statAssessRepl" || //W26
        fieldName === "shareProfStatAssRepl" //W27
    ) {
        console.log("w24", statReturn);
        if (statReturn !== undefined && statReturn !== "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Wenn W25 gefüllt, dann W24/W26/W27 inaktiv.
    const shareProfStatReturn: string = data["shareProfStatReturn"] as string; //W25
    if (
        fieldName === "statReturn" || //W24
        fieldName === "statAssessRepl" || //W26
        fieldName === "shareProfStatAssRepl" //W27
    ) {
        if (shareProfStatReturn !== undefined && shareProfStatReturn !== "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Wenn W26 gefüllt, dann W24/W25/W27 inaktiv.
    const statAssessRepl: string = data["statAssessRepl"] as string; //W26
    if (
        fieldName === "statReturn" || //W24
        fieldName === "shareProfStatReturn" || //W25
        fieldName === "shareProfStatAssRepl" //W27
    ) {
        if (statAssessRepl !== undefined && statAssessRepl !== "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Wenn W27 gefüllt, dann W24/W25/W26 inaktiv.
    const shareProfStatAssessRepl: string = data["shareProfStatAssRepl"] as string; //W27
    if (
        fieldName === "statReturn" || //W24
        fieldName === "shareProfStatReturn" || //W25
        fieldName === "statAssessRepl" //W26
    ) {
        if (shareProfStatAssessRepl !== undefined && shareProfStatAssessRepl !== "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Wenn W28 gefüllt, dann W29 inaktiv.
    if (fieldName === "statAccuReturnLoss" /*W29*/) {
        if (statAccuReturnProf !== undefined && statAccuReturnProf !== "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Wenn W29 gefüllt, dann W28 inaktiv.
    if (fieldName === "statAccuReturnProf" /*W28*/) {
        if (statAccuReturnLoss !== undefined && statAccuReturnLoss !== "") {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: W09 bis W18 nicht zu befüllen, wenn W21 gesetzt oder W01 nach dem 31.12.2017
    if (
        fieldName === "intprofeur" || //W09
        fieldName === "accuprofs94eur" || //W10
        fieldName === "accuprofretain" || //W11
        fieldName === "accusurpluseur" || //W12
        fieldName === "realprofeur" || //W13
        fieldName === "accuproffund" || //W14
        fieldName === "accuproffundccy" || //W15
        fieldName === "accuprofs09eur" || //W16
        fieldName === "accusubsprofeur" || //W17
        fieldName === "zastamteur" //W18
    ) {
        const aquisitionDate: string =
            data["acqdt"] && (data["acqdt"] as string).includes("T")
                ? convert(data["acqdt"] as string, "date")
                : (data["acqdt"] as string); //W1
        if (
            (purchaseStatDate !== undefined && purchaseStatDate !== "") ||
            (aquisitionDate !== undefined && aquisitionDate !== "" && newDate(aquisitionDate) > new Date("12.31.2017"))
        ) {
            return true; //nicht editierbar
        }
    }

    //GeS: Sonderlocke: Nur wenn
    //a) das Feld W21 = 01.01.2018 ist und W01 zwischen 01.01.2009 und 31.12.2017
    //ODER
    //b) das Feld W21 > 01.01.2018
    //darf W46 gefüllt sein.
    //siehe gleicher code in checkVOInvalidity()
    if (fieldName === "w46" /*W46*/) {
        //W21
        const aquisitionDate: string =
            data["acqdt"] && (data["acqdt"] as string).includes("T")
                ? convert(data["acqdt"] as string, "date")
                : (data["acqdt"] as string); //W01
        if (!isW46Editable(newDate(purchaseStatDate), newDate(aquisitionDate))) {
            return true; //nicht editierbar
        }
    }

    /**GeS: Sonderlocke für "Übertragungsdatum":
    //dieses ist freigeschaltet für Depotüberträge, die editierbar sind UND in der Spalte TB_EXT_TED_KEY (TED-Client Ref.) etwas mit "CLIENT..." stehen haben.
    if( fieldName === "uebZeitraum") {
        final String extTedKey = getStringFromVO( handle, "extTedKey");
        boolean manuallyCreated = extTedKey.contains( "CLIENT");
        if( !manuallyCreated) {
            return false;
        }
    }**/
    return false;
};

//GeS: Sonderlocke: Nur wenn
//a) das Feld W21 = 01.01.2018 ist und W01 zwischen 01.01.2009 und 31.12.2017
//ODER
//b) das Feld W21 > 01.01.2018
//darf W46 editierbar und gefüllt sein.
const isW46Editable = (w21: Date, w01: Date): boolean => {
    if (w21 === undefined) return false;
    if (w01 === undefined) return false;
    if (
        (w21.getTime() === new Date("01.01.2018").getTime() && //01.01.2018
            w01 > new Date("12.31.2008") && //31.12.2008
            w01 < new Date("01.01.2018")) || //01.01.2018
        w21 > new Date("01.01.2018") //01.01.2018
    ) {
        return true; //editierbar
    }

    return false; //nicht editierbar
};
