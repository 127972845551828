/* author: JESCHKE Moritz */

import { ITableContextMenu } from "components/Table";
import { useState } from "react";
import { DERIVATE_TAB } from "./TransactionOverview";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { EditPendingLinked } from "components/EditPendingLinked";
import { derTranColumns } from "./DerivateTransSearchMask";

export const EditPendingLinkedDerTranInfo = (): React.JSX.Element => {
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <EditPendingLinked
            columns={derTranColumns}
            dataRequest={{
                path: "/cgresources/cgtransactions/searchDerTranEditPending",
            }}
            identifier="editPendingOverviewDerTran"
            title="Derivate"
            contextMenu={{
                element: (
                    <TransactionContextMenu
                        contextMenus={contextMenus}
                        setContextmenus={setContextMenus}
                        currentTabText={DERIVATE_TAB}
                    />
                ),
                tableElement: contextMenus[DERIVATE_TAB],
            }}
        />
    );
};
