/* author: PAL Prashant */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Location, useLocation, useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";
import { KeyTableConsts } from "components/KeyTableConsts";

export const SECURITY_TRAN_SECTION: string = "Wertpapiere Transaktionen";
export const securityTransactionIdentifier: string = "securityTransaction";

type methodReturn = Record<string, unknown>;
export const transactionCancelation = (
    isCancelation: boolean
): {
    condition: boolean;
    method: (data: Record<string, unknown>) => methodReturn;
} => {
    return {
        condition: isCancelation,
        method: (data: Record<string, unknown>) => {
            const newData: Record<string, unknown> = {};
            //update fields to cancel transaction
            newData["cancellationExtTranKey"] = data["extTranKey"];
            //newData["extTranKey"] = "TB-INTERN-" + data["id"];
            //newData["extBusinessRef"] = newData["extTranKey"];
            newData["nominal"] = Number(data["nominal"]) * -1;
            newData["cancellationFlag"] = 5;
            return newData;
        },
    };
};

export const transactionUpdate = (
    dataFromTranOverview: Record<string, string>
): {
    condition: boolean;
    method: (data: Record<string, unknown>) => methodReturn;
} => {
    return {
        condition: window.location.href.includes("/new"),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        method: (data: Record<string, unknown>) => {
            const newData: Record<string, unknown> = {};
            newData["cancellationFlag"] = 0;
            newData["branch"] = dataFromTranOverview["branch"];
            newData["accountNr"] = dataFromTranOverview["accountNr"];
            newData["taxEvent"] = dataFromTranOverview["taxEvent"];
            newData["settlCur"] = "EUR";
            newData["stoKZ"] = 0;
            newData["tradeCur"] = "EUR";
            newData["nomCur"] = "EUR";
            newData["refDelSystem"] = "TB-CLIENT";
            newData["wkn"] = dataFromTranOverview["wkn"];
            newData["isin"] = dataFromTranOverview["isin"];
            newData["derivativeCode"] = dataFromTranOverview["derivativeCode"];
            newData["extBusinessRef"] = dataFromTranOverview["extBusinessRef"];
            newData["extTranKey"] = dataFromTranOverview["extTranKey"];
            newData["flagSpecu"] = "N";
            newData["flagForCurrBond"] = 0;
            newData["reportStatus"] = "N";
            newData["flagValueAdj"] = "N";
            newData["rightValueCur"] = "EUR";

            return newData;
        },
    };
};
export const SecurityTransactionEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const section: string | undefined = params.section;
    const impId: number = Number(params.impId === "new" ? -1 : params.impId);

    const location: Location = useLocation();
    const isNew: boolean = location.state && location.state.isNew;
    const isCancelation: boolean = location.state && location.state.isCancel;
    const isDuplication: boolean = location.state && location.state.isDuplicate;
    const data: Record<string, string> = location.state && location.state.data;
    const REQUEST = () =>
        sendGetRequest({
            path: "/cgresources/cgtransactions/sectransactionById-with-keydata",
            params: { id: impId, isNew: isNew },
        });
    console.log(isNew);
    const securityTransactionEdit: IEditDataViewUserConfiguration = {
        headline: SECURITY_TRAN_SECTION,
        attributes: [
            {
                field: "securityData.secCode",
                headerName: "WKN/ISIN",
                displayOnly: true,
            },
            {
                field: "securityData.shortName",
                headerName: "Kurzname",
                displayOnly: true,
            },
            { field: "extTranKey", headerName: "Transaktionsreferenz", displayOnly: true },
            { field: "extBusinessRef", headerName: "Umsatzreferenz", displayOnly: true },
            { field: "extEvent", headerName: "Geschäftsart" },
            {
                field: "accountData.branch",
                headerName: "Filialnummer",
                displayOnly: !window.location.href.includes("/new"),
            },
            { field: "accountData.accountNr", headerName: "Kontonummer", displayOnly: true },
            { field: "extEventGroup", headerName: "Vorgangsgr.-Schlüssel" },
            {
                field: "taxEvent",
                headerName: "StGA",
                dropdown: {
                    keyTableTabname: "TAX_EVENT_TCG",
                },
                displayOnly: !window.location.href.includes("/new"),
            },
            {
                field: "taxEventType",
                headerName: "steuerliche Geschäftsartenbestimmung",
                dropdown: {
                    keyTableTabname: "GVOTYPE",
                },
                displayOnly: true,
            },
            { field: "nominal", headerName: "Umsatz" },
            { field: "referenceNominal", headerName: "Referenz-nominal" },
            {
                field: "nomCur",
                headerName: "Stk/Whrg",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            { field: "tradeMarketValue", headerName: "Kurswert Handels Whrg" },
            {
                field: "tradeCur",
                headerName: "Han.-Whrg",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            { field: "tradeAddExpCost", headerName: "NK Abr.-Whrg" },
            { field: "settlExchRate", headerName: "DevKurs Abr.-Whrg" },
            { field: "settlMarketValue", headerName: "Kurswert Abr.-Whrg" },
            { field: "settlAddExpCost", headerName: "Anschaff. NK Abr.-Whrg" },
            { field: "marketValueEur", headerName: "Gegenwert EUR" },
            { field: "fractionPaymentT", headerName: "Spitzenausgleich in Handelswährung" },
            { field: "fractionPaymentE", headerName: "Spitzenausgleich in EUR" },
            { field: "fractionPaymentS", headerName: "Spitzenausgleich in Abrechnungswährung" },

            { field: "retProcFundT", headerName: "ID905 Thes. Ertr. Han.-Whrg" },
            { field: "retProcFundS", headerName: "ID905 Thes. Ertr. Abr.-Whrg" },
            { field: "retProcFundE", headerName: "ID905 Thes. Ertr. EUR" },

            { field: "estProfitFundT", headerName: "Immobiliengewinn Handels Whrg" },
            { field: "estProfitFundS", headerName: "Immobiliengewinn Abr.-Whrg" },
            { field: "estProfitFundE", headerName: "Immobiliengewinn EUR" },

            { field: "refDelSystem", headerName: "Liefersystem", dropdown: { keyTableTabname: "DELSYS" } },

            {
                field: "valutA",
                headerName: "Valuta",
                type: "date",
            },
            {
                field: "taxBusinessDay",
                headerName: "Steuer-DT",
                type: "date",
            },
            {
                field: "bookingDate",
                headerName: "Buchungs-Datum",
                type: "date",
            },
            {
                field: "instructionDate",
                headerName: "Weisungsdatum",
                type: "date",
            },
            {
                field: "settlValueDate",
                headerName: "Stückevaluta-Datum",
                type: "date",
            },

            { field: "reinvestValueT", headerName: "Wiederanl.wert Han.-Whrg" },
            { field: "subPriceE", headerName: "Bezugspreis EUR" },

            { field: "subPriceValT", headerName: "Wert BZR/OS Han.-Whrg" },
            { field: "detachRatioN", headerName: "Trennverh. Zähler" },
            { field: "detachRatioD", headerName: "Trennverh. Nenner" },
            { field: "subscriptRatioN", headerName: "Bezugsverh. Zähler" },
            { field: "subscriptRatioD", headerName: "Bezugsverh. Nenner" },
            { field: "switchRatioN", headerName: "Umtauschverh. Zähler" },
            { field: "switchRatioD", headerName: "Umtauschverh. Nenner" },
            { field: "valueRatioN", headerName: "Tilgungsfaktor alt" },
            { field: "valueRatioD", headerName: "Tilgungsfaktor neu" },
            {
                field: "detachDate",
                headerName: "Trenntermin",
                type: "date",
            },
            {
                field: "exDate",
                headerName: "Extag",
                type: "date",
            },
            { field: "valuationEur", headerName: "Bewert-BT am Bew.-DT EUR" },

            {
                field: "valuationDate",
                headerName: "Bewertungs-Datum",
                type: "date",
            },

            { field: "valuationS", headerName: "Bewert-BT am Bew.-DT Abr.-Whrg" },
            { field: "cashCompS", headerName: "Barausgleich Abr.-Whrg" },
            { field: "marketValueOld", headerName: "Kurs Altaktie" },
            {
                field: "marketValueDate",
                headerName: "DT zu Kurs Altaktien",
                type: "date",
            },
            { field: "equiCashDiviE", headerName: "Gegenwert Bardiv. EUR" },
            { field: "capIncomeS", headerName: "Einkünfte aus KAP" },
            { field: "interimGainT", headerName: "Zw. Gewinn Han.-Whrg" },
            { field: "interimGainS", headerName: "Zw. Gewinn Abr.-Whrg" },
            { field: "interimGainE", headerName: "Zw. Gewinn EUR" },

            { field: "nonCashBenefitS", headerName: "Geldwerter Vorteil Abr.-Whrg" },
            { field: "nonCashBenefitT", headerName: "Geldwerter Vorteil Han.-Whrg" },
            { field: "nonCashBenefitE", headerName: "Geldwerter Vorteil EUR" },
            { field: "accruedInterestT", headerName: "Stückzinsen Abr.-Whrg" },
            { field: "accruedInterestS", headerName: "Stückzinsen Han.-Whrg" },
            { field: "accruedInterestE", headerName: "Stückzinsen EUR" },
            { field: "rightValueExcRate", headerName: "DevKurs zur Whrg zum Wert BZR/OS" },
            { field: "rightValueCur", headerName: "Whrg zum Wert BZR/OS", dropdown: { keyTableTabname: "TB_WMW02" } },
            { field: "cancellationFlag", headerName: "StoKZ", dropdown: { keyTableTabname: "STORNOFLAG" } },
            { field: "cancellationExtTranKey", headerName: "Ums-Ref. Sto Umsatz", displayOnly: true },
            { field: "bidData.bidWm", headerName: "externe BID", displayOnly: true },
            {
                field: "manualCheckRequired",
                headerName: "Man. Prüf.",
                dropdown: {
                    keyTableTabname: KeyTableConsts.MANUAL_CHECK_REQUIRED,
                },
            },
            { field: "retProcEstEur", headerName: "ID917 Schätzwert fehlende aussch. gl. Erträge" },
            { field: "retProcOldProfitEur", headerName: "ID919 Akk. Ausgesch. Altveräuß.-gewinne" },
            { field: "retProcAdjEur", headerName: "ID921 Bereinigte akk. aussch. gl. Erträge" },
            { field: "nullIndicator", headerName: "Null-Indikator", dropdown: { keyTableTabname: "NULL_INDICATOR" } },

            { field: "comment", headerName: "Kommentar" },
            {
                field: "overCounterFlag",
                headerName: "Tafelgeschäft",
                dropdown: { keyTableTabname: "OVER_COUNTER_FLAG" },
            },
            { field: "stockProfitFund", headerName: "Aktiengewinn" },
            { field: "retProcSubstEur", headerName: "ID920 Akk. Substanzausschüttung", displayOnly: true },
            { field: "tradeExchRate", headerName: "DevKurs Handels Whrg" },
            {
                field: "settlCur",
                headerName: "Abr.-Whrg",
                dropdown: { keyTableTabname: "TB_WMW02" },
            },
            {
                field: "execState",
                headerName: "Ausführungsstatus",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "EXECSTATE",
                },
            },
            {
                field: "calculationFlag",
                headerName: "Berechenbarkeit",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "CALCFLAG",
                },
            },
            {
                field: "ntrlMergerFundsDate",
                headerName: "Datum steuerneutrale Fondsfusion",
                type: "date",
                displayOnly: true,
            },
            {
                field: "executionTime",
                headerName: "Ausführ.-Datum",
                type: "date",
            },
            {
                field: "totRetWithhTax",
                headerName: "abgezogene Quellensteuer gesamt",
            },
            {
                field: "offsRetWithhTax",
                headerName: "abgezogene anrechenbare Quellensteuer",
            },
            {
                field: "reclRetWithhTax",
                headerName: "abgezogene rückforderbare Quellensteuer",
            },
            {
                field: "calulationFlag",
                headerName: "Berechenbarkeit",
            },
            {
                field: "acqValuationDate",
                headerName: "Tag des ermittelten Wertpapierkurses",
                type: "date",
                displayOnly: true,
            },
            {
                field: "diffEnrichmentDate",
                headerName: "Abweichendes Anreicherungsdatum für ID-Felder",
                type: "date",
                displayOnly: true,
            },
            /*{
                field: "dbaEnrichmentFlag",
                headerName: "Flag ob Anreicherung des DBA-Gewinns erfolgt",
            },
            {
                field: "incEqualProcId",
                headerName: "ID des ID925",
            },
            {
                field: "iepEnrichmentFlag",
                headerName: "Flag ob Anreicherung des ID925 erfolgt",
            },
            {
                field: "rtldRef",
                headerName: "Referenz zum Settlement-Auftrag",
                displayOnly: true,
            },*/
            {
                field: "flagSpecu",
                headerName: "Anschaffungsdaten Detail",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "SPECUFLAG",
                },
            },

            {
                field: "flagForCurrBond",
                headerName: "KZ Fremdwhr-Anleihe",
            },
            {
                field: "flagValueAdj",
                headerName: "Wertkorrekturen",
                dropdown: {
                    keyTableTabname: "ADJFLAG",
                },
                displayOnly: true,
            },
            {
                field: "reportStatus",
                headerName: "Report Status",
                dropdown: {
                    keyTableTabname: "TRANSREPORTSTATUS",
                },
                displayOnly: true,
            },
            {
                field: "id905AutoEnrichment",
                headerName: "KZ auto. Anreicher. ID905",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
            {
                field: "id909AutoEnrichment",
                headerName: "KZ auto. Anreicher. ID909",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
            {
                field: "id917AutoEnrichment",
                headerName: "KZ auto. Anreicher. ID917",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
            {
                field: "id919AutoEnrichment",
                headerName: "KZ auto. Anreicher. ID919",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
            {
                field: "id920AutoEnrichment",
                headerName: "KZ auto. Anreicher. ID920",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
            {
                field: "id921AutoEnrichment",
                headerName: "KZ auto. Anreicher. ID921",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
            {
                field: "gv512AutoEnrichment",
                headerName: "KZ auto. Anreicher. GV512",
                displayOnly: true,
                dropdown: { keyTableTabname: "AUTO_ENRICHMENT_FLAG" },
            },
        ],

        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
        checkInvalidity: [
            {
                condition: (data: Record<string, unknown>): boolean => data["taxEvent"] === undefined,
                relatedFields: ["taxEvent"],
            },
        ],
        updateFields: isCancelation ? transactionCancelation(isCancelation) : transactionUpdate(data),
        duplicate: { condition: isDuplication },
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [securityTransactionEdit];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={impId}
            title="Wertpapiere Transaktionen"
            section={section}
            isCancelation={isCancelation}
            duplication={isDuplication}
            overrideRootLevelForInsert={4}
        />
    );
};
