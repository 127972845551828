/* author: JESCHKE Moritz */

import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const AddressHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const versionId: number = Number(params.versionId);

    const labels: IHistoryKeys[] = [
        { field: "addressType", label: "Art der Adresse" },
        { field: "street1", label: "Strasse" },
        { field: "houseNumber", label: "Hausnummer" },
        { field: "zipCode", label: "PLZ" },
        { field: "town", label: "Ort" },
        { field: "country", label: "Land" },
        { field: "poBox", label: "Postfach" },
        { field: "telephone", label: "Telefon" },
        { field: "fax", label: "Fax" },
        { field: "validFrom", label: "Gültig von", type: "date" },
        { field: "validTo", label: "Gültig bis", type: "date" },
    ];

    const IDENTIFIER: TableidentifierType = "addressHistory";

    return (
        <HistoryView
            dataRequest={{
                path: "/customerstaticdata/address/history",
                params: { versionId: versionId },
            }}
            title={"Historie Adressen"}
            labels={labels}
            identifier={IDENTIFIER}
        />
    );
};
