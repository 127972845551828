/* author: PAL Prashant */

import React, { useState } from "react";
import { createKey } from "./Converter";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Colors } from "./Colors";
import { Typography } from "@mui/material";

interface ListProps {
    data: Record<string, string>[];
    fields: string[];
    onListClick: (item: Record<string, string>, selected: boolean) => void;
    joinFieldsWithChar: string;
    maxHeight: string;
}

export const CustomList = (props: ListProps): React.JSX.Element => {
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const getItemStyle = (isSelected: boolean): React.CSSProperties => ({
        cursor: "pointer",
        fontSize: "0.875rem",
        backgroundColor: isSelected ? Colors.darkGrey : "transparent",
        paddingLeft: "0.5rem",
        maxHeight: props.maxHeight,
    });

    const handleItemClick = (item: Record<string, string>): void => {
        const isSelected = selectedItemId === +item.id;
        setSelectedItemId(isSelected ? null : +item.id);
        props.onListClick(item, !isSelected);
    };

    return (
        <List sx={{ paddingTop: 0, maxHeight: props.maxHeight, overflow: "auto" }}>
            {props.data.map((item: Record<string, string>) => (
                <ListItem
                    key={createKey("list", item.id)}
                    onClick={() => handleItemClick(item)}
                    style={getItemStyle(selectedItemId === +item.id)}
                >
                    <Typography sx={{ fontSize: "0.875rem", lineBreak: "anywhere" }}>
                        {props.fields.map((field: string) => item[field]).join(props.joinFieldsWithChar)}
                    </Typography>
                </ListItem>
            ))}
        </List>
    );
};
