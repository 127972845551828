/* author: PAL Prashant */

import { BALANCES_INCOMES_TAXES_PER_TAXYEAR } from "application/menu-bar/MenuBarItemList";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { Footer, IFooterButton } from "components/Footer";
import { ITableColumn, Table } from "components/Table";
import { useState, useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router";

export const BalancesIncomeByTaxYearOverview = (): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const location = useLocation().state;
    const data: Record<string, string>[] = location.data ? location.data : [];
    const extraData: Record<string, string> = location.extraData ? location.extraData : {};

    const IDENTIFIER: TableidentifierType = "failedDerivateTrans";

    const columns: ITableColumn[] = [
        { field: "fiscalYear", title: "Steuerjahr" },
        { field: "totalExemptFsa", title: "Inanspruchnahme FSA" },
        { field: "totalExemptNv", title: "Inanspruchnahme NV" },
        {
            field: "totalShareProfit",
            title: "Summe Aktiengeschäfte zum Stichtag",
            combinedFields: ["totalShareLoss"],
            combinedFieldsCalculation: "SUM",
        },
        { field: "totalShareProfit", title: "Gewinn Aktiengeschäfte" },
        { field: "totalShareLoss", title: "Verlust Aktiengeschäfte" },
        {
            field: "totalOtherProfit",
            title: "Summe sonst. Geschäfte und Stückzinsen zum Stichtag",
            combinedFields: ["totalOtherLoss"],
            combinedFieldsCalculation: "SUM",
        },

        { field: "totalOtherProfit", title: "Gewinn sonst. Geschäfte" },
        { field: "totalOtherLoss", title: "Verlust sonst. Geschäfte" },
        { field: "totalExemptNonResident", title: "Abstandsnahme wegen Ausländereigenschaft" },
        { field: "totalExemptLegalForm", title: "Abstandsnahme wegen  Rechtsform" },
        { field: "totOffsetableTax", title: "Gesamtsumme Quellensteuer" },
        {
            field: "totOffsetableTax",
            title: "Restguthaben Quellensteuer",
            combinedFields: ["evvrOffsetableTax", "-credOffsetableTax"],
            combinedFieldsCalculation: "SUM",
        },
        { field: "totDwtPerson1", title: "gezahlte AgSt Person 1" },
        { field: "totDwtPerson2", title: "gezahlte AgSt Person 2" },
        { field: "totSoliPerson1", title: "gezahlter Soli Person 1" },
        { field: "totSoliPerson2", title: "gezahlter Soli Person 2" },
        { field: "totSoliPerson1", title: "gezahlte Kirchensteuer Person 1" },
        { field: "totSoliPerson2", title: "gezahlte Kirchensteuer Person 2" },
        { field: "foreigner", title: "Inländer/Ausländer" },
        { field: "evvrShareLoss", title: "durch eVVR übertragener Aktienverlust" },
        { field: "evvrOtherLoss", title: "durch eVVR übertragener sonstiger Verlust" },
        { field: "evvrOffsetableTax", title: "durch eVVR übertragene Quellensteuer" },
        { field: "evvrTaxableBaseDwt", title: "Bemessungsgrundlage nach FSA und eVVR-Überträgen" },
        { field: "evvrCredOffsetableTax", title: "angerechnete Quellensteuer nach eVVR" },
        { field: "statusCertDwt", title: "AgSt Fondseingangseite" },
        { field: "statusCertSoliSurchrg", title: "Soli Zuschlag Fondseingangseite" },
        { field: "exemptStatusCert", title: "durch Statusbescheinigung freigestellter Ertrag" },
        { field: "exemptParagraphTen", title: "durch §10 freigestellter Ertrag " },
        { field: "totalTerminLoss", title: "Verlust Termingeschäft" },
        { field: "totalTwentySixSixloss", title: "Verlust nach §20 VI S6 EStG" },
    ];

    const footerButtons: IFooterButton[] = [
        { title: "Abbrechen", filled: false, onClick: () => navigate("/home") },
        {
            title: "Zurück",
            filled: false,
            onClick: () => navigate("/otherProcessing", { state: { origin: BALANCES_INCOMES_TAXES_PER_TAXYEAR } }),
        },
    ];

    const [tableHeight, setTableHeight] = useState<number>();
    const calculateTableHeight = () => {
        const appBarElement: HTMLElement = document.getElementById("appBar") as HTMLElement;
        let appBarHeight: number = 0;
        if (appBarElement) {
            appBarHeight = appBarElement.getBoundingClientRect().height;
        }

        const tableToolBarElement: HTMLElement = document.getElementsByClassName("MuiToolbar-root")[0] as HTMLElement;
        let tableToolBarHeight: number = 0;
        if (tableToolBarElement) {
            tableToolBarHeight = tableToolBarElement.getBoundingClientRect().height;
        }

        const tableFooterElement: HTMLElement = document.getElementsByClassName(
            "MuiTableFooter-root"
        )[0] as HTMLElement;
        let tableFooterHeight: number = 0;
        if (tableFooterElement) {
            tableFooterHeight = tableFooterElement.getBoundingClientRect().height;
        }

        const tableWrapperElement: HTMLElement = document.getElementById("tableWrapper") as HTMLElement;
        let marginHeight: number = 0;
        if (tableWrapperElement) {
            //multiplied by 2 for top and bottom margin
            marginHeight = 2 * Number(window.getComputedStyle(tableWrapperElement).margin.replaceAll("px", ""));
        }

        const errorDetailListElement: HTMLElement = document.getElementById("errorDetailList") as HTMLElement;
        let errorDetailListHeight: number = 0;
        if (errorDetailListElement) {
            errorDetailListHeight = errorDetailListElement.getBoundingClientRect().height;
        }

        const footerElement: HTMLElement = document.getElementById("footer") as HTMLElement;
        let footerHeight: number = 0;
        if (footerElement) {
            footerHeight = footerElement.children[0].getBoundingClientRect().height;
        }

        const UNSELECTABLE_SPACING: number = 14;

        //max window height before scrollbar
        const height = window.innerHeight * (window.innerHeight / document.body.offsetHeight);

        setTableHeight(
            height -
                appBarHeight -
                marginHeight -
                tableToolBarHeight -
                tableFooterHeight -
                UNSELECTABLE_SPACING -
                errorDetailListHeight -
                footerHeight
        );
    };

    useEffect(() => {
        window.addEventListener("resize", calculateTableHeight);
        return () => {
            window.removeEventListener("resize", calculateTableHeight);
        };
    }, [calculateTableHeight]);

    useEffect(() => {
        calculateTableHeight();
    }, []);

    return (
        <div id="tableWrapper" style={{ margin: "4rem" }}>
            <Table
                columnsDefinition={columns}
                identifier={IDENTIFIER}
                tableData={data}
                maxHeight={tableHeight + "px"}
                title={"Übersicht Salden/Erträge per Steuerjahr für " + extraData["fiscalUnitData.fiscalUnit"]}
            />
            <div id="footer">
                <Footer buttons={footerButtons} />
            </div>
        </div>
    );
};
