/* author: JESCHKE Moritz */

import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";
import { TransactionOverview } from "pages/showcase/transactions/TransactionOverview";
import { TftTransactionSingle } from "pages/showcase/tft/TftTransactionSingle";
import { TcgTransactionSingle } from "pages/showcase/tcg/TcgTransactionSingle";

interface IAddTransaction {
    payload: {
        bidId: number;
        impId: number;
        stga: string;
        tabId: number;
        switchSite: boolean;
        label: string;
    };
    type: string;
}

interface IRemoveTransaction {
    payload: {
        impId: number;
    };
    type: string;
}

interface IAddIncome {
    payload: {
        incomeTranId: number;
        bidId: number;
        tabId: number;
        switchSite: boolean;
        label: string;
    };
    type: string;
}

interface IRemoveIncome {
    payload: {
        incomeTranId: number;
    };
    type: string;
}

interface ISetIndex {
    payload: {
        pageId: number;
        isIncome: boolean;
    };
    type: string;
}

interface ISetIsIncome {
    payload: {
        isIncome: boolean;
    };
    type: string;
}

interface ISetData {
    payload: {
        data: [];
    };
    type: string;
}

interface ISetTransactionTab {
    payload: {
        impId: number;
        newTabId: number;
    };
    type: string;
}

interface ISetIncomeTab {
    payload: {
        incomeTranId: number;
        newTabId: number;
    };
    type: string;
}

interface ISetDialog {
    payload: {
        accountNr: string;
        accountId: string;
        wkn: string;
        from: Date | null;
        to: Date | null;
        type: string;
        branch: string;
    };
    type: string;
}

interface ISetFuNr {
    payload: {
        fuNr: string;
        fiscalUnitId: string;
    };
    type: string;
}

interface ISetShowInMenubar {
    payload: {
        showInMenubar: boolean;
    };
    type: string;
}

interface IState {
    showInMenubar: boolean;
    selectedTransactionRows: number[];
    selectedTransactionLabel: string[];
    selectedIncomeRows: number[];
    selectedIncomeLabel: string[];
    selectedIndex: number;
    isIncome: boolean;
    accountNr: string;
    accountId: string;
    fuNr: string;
    fiscalUnitId: string;
    wkn: string;
    from: Date | null;
    to: Date | null;
    type: string;
    branch: string;
    transactionData: never[];
    incomeData: never[];
    transactionPages: {
        content: React.JSX.Element;
        currentTabId: number;
    }[];
    incomePages: {
        content: React.JSX.Element;
        currentTabId: number;
    }[];
}

const initialState: IState = {
    showInMenubar: false,
    selectedTransactionRows: [],
    selectedTransactionLabel: [],
    selectedIncomeRows: [],
    selectedIncomeLabel: [],
    //index 0 = transactionOverwiew, all other are transactionSingle
    selectedIndex: 0,
    isIncome: false,
    accountNr: "",
    accountId: "",
    fuNr: "",
    fiscalUnitId: "",
    wkn: "",
    from: new Date(),
    to: new Date(),
    type: "D",
    branch: "0001",
    transactionData: [],
    incomeData: [],
    transactionPages: [
        {
            content: <TransactionOverview />,
            currentTabId: 0,
        },
    ],
    incomePages: [
        {
            content: <TransactionOverview />,
            currentTabId: 0,
        },
    ],
};

export const TransactionSlice = createSlice({
    name: "transaction",
    initialState: initialState,
    reducers: {
        //takes impId, tabId and switchSite
        //select transaction by impId and configure the correct tab
        //if already selected only update the tab
        //if switchSite == false then user stays on current site
        addSelectedTransactionRow: (state, action: IAddTransaction) => {
            //add a value to selectedRows
            //check if transaction is already selected
            if (
                state.selectedTransactionRows.filter((f: number) => f === action.payload.impId).toString() ===
                [].toString()
            ) {
                //transaction isn't selected yet => new
                return {
                    ...state,
                    selectedTransactionRows: [...state.selectedTransactionRows, action.payload.impId],
                    selectedTransactionLabel: [...state.selectedTransactionLabel, action.payload.label],
                    transactionPages: [
                        ...state.transactionPages,
                        {
                            content: (
                                <TcgTransactionSingle
                                    bidId={action.payload.bidId}
                                    impId={action.payload.impId}
                                    stga={action.payload.stga}
                                />
                            ),
                            currentTabId: action.payload.tabId,
                        },
                    ],
                    selectedIndex: action.payload.switchSite
                        ? state.selectedTransactionRows.length + 1
                        : state.selectedIndex,
                    isIncome: action.payload.switchSite ? false : state.isIncome,
                };
            } else {
                //transaction is already selected => update only currentTabId and selectedIndex
                const index: number = state.selectedTransactionRows.indexOf(action.payload.impId) + 1;

                state.transactionPages[index] = {
                    ...state.transactionPages[index],
                    currentTabId: action.payload.tabId,
                };
                state.selectedIndex = index;
                state.isIncome = false;
            }
        },
        //takes impId and remove it from selection and the page
        removeSelectedTransactionRow: (state, action: IRemoveTransaction) => {
            //find impId index
            const index: number = state.selectedTransactionRows.indexOf(action.payload.impId) + 1;

            return {
                ...state,
                selectedTransactionRows: [
                    ...state.selectedTransactionRows.filter((f: number) => f !== action.payload.impId),
                ],
                selectedTransactionLabel: [
                    ...state.selectedTransactionLabel.slice(0, index - 1),
                    ...state.selectedTransactionLabel.slice(index),
                ],
                transactionPages: [
                    ...state.transactionPages.slice(0, index),
                    ...state.transactionPages.slice(index + 1),
                ],
                selectedIndex: 0,
                isIncome: false,
            };
        },
        //adds selected row to array
        addSelectedIncomeRow: (state, action: IAddIncome) => {
            //add a value to selectedRows
            //check if transaction is already selected
            if (
                state.selectedIncomeRows.filter((f: number) => f === action.payload.incomeTranId).toString() ===
                [].toString()
            ) {
                //transaction isn't selected yet => new
                return {
                    ...state,
                    selectedIncomeRows: [...state.selectedIncomeRows, action.payload.incomeTranId],
                    selectedIncomeLabel: [...state.selectedIncomeLabel, action.payload.label],
                    incomePages: [
                        ...state.incomePages,
                        {
                            content: (
                                <TftTransactionSingle
                                    bidId={action.payload.bidId}
                                    incomeTranId={action.payload.incomeTranId}
                                />
                            ),
                            currentTabId: action.payload.tabId,
                        },
                    ],
                    selectedIndex: action.payload.switchSite
                        ? state.selectedIncomeRows.length + 1
                        : state.selectedIndex,
                    isIncome: action.payload.switchSite ? true : state.isIncome,
                };
            } else {
                //transaction is already selected => update only currentTabId and selectedIndex
                const index: number = state.selectedIncomeRows.indexOf(action.payload.incomeTranId) + 1;

                state.incomePages[index] = {
                    ...state.incomePages[index],
                    currentTabId: action.payload.tabId,
                };
                state.selectedIndex = index;
                state.isIncome = true;
            }
        },
        //takes securityId and remove it from selection and the page
        removeSelectedIncomeRow: (state, action: IRemoveIncome) => {
            //find impId index
            const index: number = state.selectedIncomeRows.indexOf(action.payload.incomeTranId) + 1;

            return {
                ...state,
                selectedIncomeRows: [
                    ...state.selectedIncomeRows.filter((f: number) => f !== action.payload.incomeTranId),
                ],
                selectedIncomeLabel: [
                    ...state.selectedIncomeLabel.slice(0, index - 1),
                    ...state.selectedIncomeLabel.slice(index),
                ],
                incomePages: [...state.incomePages.slice(0, index), ...state.incomePages.slice(index + 1)],
                selectedIndex: 0,
                isIncome: false,
            };
        },

        //takes pageId and switch to this site (0 = transactionOverview; 1+ = all singleTransaction pages)
        setSelectedTransactionIndex: (state, action: ISetIndex) => {
            return {
                ...state,
                selectedIndex: action.payload.pageId,
                isIncome: action.payload.isIncome,
            };
        },
        setIsIncome: (state, action: ISetIsIncome) => {
            return {
                ...state,
                isIncome: action.payload.isIncome,
            };
        },
        //takes data to update the stored transaction data
        setTransactionData: (state, action: ISetData) => {
            return {
                ...state,
                transactionData: action.payload.data,
            };
        },
        //takes data and set it as the income data
        setIncomeData: (state, action: ISetData) => {
            return {
                ...state,
                incomeData: action.payload.data,
            };
        },

        //takes impId and new TabId to set the currentTab of a transactionSingle site
        setCurrentTransactionTabId: (state, action: ISetTransactionTab) => {
            //find impId index
            const index: number = state.selectedTransactionRows.indexOf(action.payload.impId) + 1;
            state.transactionPages[index].currentTabId = action.payload.newTabId;
        },
        //takes securityId and new TabId to set the currentTab of a incomeSingle site
        setCurrentIncomeTabId: (state, action: ISetIncomeTab) => {
            //find impId index
            const index: number = state.selectedIncomeRows.indexOf(action.payload.incomeTranId) + 1;
            state.incomePages[index].currentTabId = action.payload.newTabId;
        },
        //set the current account number
        setTransactionDialogData: (state, action: ISetDialog) => {
            return {
                ...state,
                accountNr: action.payload.accountNr,
                accountId: action.payload.accountId,
                wkn: action.payload.wkn,
                from: action.payload.from,
                to: action.payload.to,
                type: action.payload.type,
                branch: action.payload.branch,
            };
        },
        setTransactionFuNr: (state, action: ISetFuNr) => {
            return {
                ...state,
                fuNr: action.payload.fuNr,
                fiscalUnitId: action.payload.fiscalUnitId,
            };
        },
        setShowTransactioninMenubar: (state, action: ISetShowInMenubar) => {
            return {
                ...state,
                showInMenubar: action.payload.showInMenubar,
            };
        },
        transactionClearData: (state) => {
            return {
                ...state,
                selectedTransactionRows: initialState.selectedTransactionRows,
                selectedIncomeRows: initialState.selectedIncomeRows,
                transactionData: initialState.transactionData,
                incomeData: initialState.incomeData,
                transactionPages: initialState.transactionPages,
                incomePages: initialState.incomePages,
            };
        },
        transactionClearAll: () => {
            return initialState;
        },
    },
});

export const {
    setSelectedTransactionIndex,
    setIsIncome,
    setTransactionData,
    setIncomeData,
    addSelectedTransactionRow,
    removeSelectedTransactionRow,
    addSelectedIncomeRow,
    removeSelectedIncomeRow,
    setCurrentTransactionTabId,
    setCurrentIncomeTabId,
    setTransactionDialogData,
    setTransactionFuNr,
    setShowTransactioninMenubar,
    transactionClearData,
    transactionClearAll,
} = TransactionSlice.actions;
export const transactionSliceReducer: Reducer<IState, AnyAction> = TransactionSlice.reducer;
