/* author: PRASHANT PAL and UMANG BALUJA*/
import "dayjs/locale/de";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography } from "@mui/material";
import { Colors } from "./Colors";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "application/redux/Store";
import { setDatePickerErrorMsg, clearDatePickerErrorMsg } from "application/redux/slices/DatePickerErrorSlice";
import { useAppDispatch } from "application/redux/Hooks";

interface ITBDatePicker {
    label: string;
    datepickers: IDatePicker[];
    pageTitle: string;
    orientation: Orientation;
    // this is extra property for the datepicker disabled rule
    errorMessageForTheDisabledDatePicker?: boolean;
    //this  is extra property will use if date is valid or not then we can share this information to other components.
    isValidDatePickerValue?: (error: string) => void;
    onChange?: (newValue: Date | null) => void;
}

export interface IDatePickerInfo {
    label: string;
    field: string;
    discriminator: Discriminator;
    disabled?: boolean;
    defaultValue?: DefaultDateValueType;
}
export interface IDatePicker extends IDatePickerInfo {
    // used to concat the ISearchCriteria label with datepicker's label only in case of TBDatePickerType
    prefixParentLabel: boolean;
    value: Date | null;
    setValue: (date: Date | null) => void;
    dateRestriction: DateRestriction; //For applying restriction on To and From datepickers
}
export type DefaultDateValueType = "today";

export type Discriminator = "single" | "from" | "to";
export type DateRestriction = "month" | "year" | undefined;
export type Orientation = "horizontal" | "vertical";

export type DatePickerType = SingleDatePickerType | TBDatePickerType;

export type SingleDatePickerType = {
    singleDatePicker: IDatePickerInfo;
};

export type TBDatePickerType = {
    TBFromToDatePicker: {
        prefixParentLabel: boolean;
        orientation?: Orientation;
        from: IDatePickerInfo;
        to: IDatePickerInfo;
        dateRestriction?: DateRestriction;
    };
};

type Error = "shouldDisableDate" | "disablePast" | "disableFuture" | "minDate" | "maxDate" | "invalidDate" | null;

export const TBDatePicker = (props: ITBDatePicker): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();
    const errorMessage: Record<string, string> = useSelector((state: RootState) => state.datePicker.errMsg);

    /**
     * Changes the Error Messages for the DatePickers
     * @param error Error Type (which one)
     * @param datePicker Error Origin (from where)
     */

    const dateErrorHandler = (error: Error, datePicker: IDatePicker) => {
        if (datePicker.discriminator === "from") {
            dispatch(
                setDatePickerErrorMsg({
                    field: getDatePickerId(datePicker.field),
                    errMsg: errorMsgForFromDatePicker(error, datePicker),
                })
            );
        } else if (datePicker.discriminator === "to") {
            dispatch(
                setDatePickerErrorMsg({
                    field: getDatePickerId(datePicker.field),
                    errMsg: errorMsgForToDatePicker(error, datePicker),
                })
            );
        } else if (datePicker.discriminator === "single") {
            dispatch(
                setDatePickerErrorMsg({
                    field: getDatePickerId(datePicker.field),
                    errMsg: errorMsgForSingleDatePicker(error),
                })
            );
        }
    };

    const errorMsgForFromDatePicker = (error: Error, datePicker: IDatePicker): string => {
        switch (error) {
            case "shouldDisableDate":
                return "Das Von-Datum ist deaktiviert.";
            case "disablePast":
                return "Das Von-Datum darf nicht in der Vergangenheit liegen.";
            case "disableFuture":
                return "Das Von-Datum darf nicht in der Zukunft liegen.";
            case "maxDate":
                if (datePicker.dateRestriction === "year") return "Datum von / bis müssen im selben Jahr sein.";
                else return "Das Von-Datum darf nicht hinter dem Bis-Datum liegen.";
            case "invalidDate":
                return "Das Von-Datum entspricht nicht den Datumkonventionen.";
            case "minDate": {
                if (datePicker.dateRestriction === "year") return "Datum von / bis müssen im selben Jahr sein.";
                else return "Das Von-Datum darf nicht vor dem 01.01.1900 liegen.";
            }

            default:
                return "";
        }
    };
    const errorMsgForToDatePicker = (error: Error, datePicker: IDatePicker): string => {
        switch (error) {
            case "shouldDisableDate":
                return "Das Bis-Datum ist deaktiviert.";
            case "disablePast":
                return "Das Bis-Datum darf nicht in der Vergangenheit liegen.";
            case "disableFuture":
                return "Das Bis-Datum darf nicht in der Zukunft liegen.";
            case "minDate":
                if (datePicker.dateRestriction === "year") return "";
                else return "Das Bis-Datum darf nicht vor dem Von-Datum liegen.";
            case "maxDate": {
                if (datePicker.dateRestriction === "year") return "";
                else return "Das Bis-Datum darf nicht hinter dem Von-Datum liegen.";
            }
            case "invalidDate":
                return "Das Bis-Datum entspricht nicht den Datumkonventionen.";
            default:
                return "";
        }
    };
    const errorMsgForSingleDatePicker = (error: Error): string => {
        switch (error) {
            case "invalidDate":
                return "Das Datum entspricht nicht den Datumkonventionen.";
            case "disableFuture":
                return "Das Datum darf nicht in der Zukunft liegen.";
            default:
                return "";
        }
    };
    // Used to configure the Label attribute of DatePicker Component.
    const getDatePickerLabel = (datePicker: IDatePicker): string => {
        if (datePicker.prefixParentLabel) return props.label + " " + datePicker.label;
        else return datePicker.label;
    };
    // Used to configure the MinDate attribute of DatePicker Component based on DatePicker.
    const getMinDate = (datePicker: IDatePicker): Date | undefined => {
        if (datePicker.discriminator === "single") return undefined;
        else if (datePicker.discriminator === "from") {
            const toDate: Date | undefined = getDate(datePicker);
            if (toDate) {
                const date: Date = new Date(toDate);
                if (datePicker.dateRestriction === "month") return new Date(date.getFullYear(), date.getMonth(), 1);
                else if (datePicker.dateRestriction === "year") return new Date(date.getFullYear(), 0, 1);
            } else return undefined;
        } else if (datePicker.discriminator === "to") return getDate(datePicker);
    };
    // Used to configure the MaxDate attribute of DatePicker Component based on DatePicker.
    const getMaxDate = (datePicker: IDatePicker): Date | undefined => {
        if (datePicker.discriminator === "single") return undefined;
        if (datePicker.discriminator === "from") {
            return getDate(datePicker);
        }
        if (datePicker.discriminator === "to") {
            const fromDate: Date | undefined = getDate(datePicker);
            if (fromDate) {
                const date: Date = new Date(fromDate);
                if (datePicker.dateRestriction === "month")
                    // If the date restriction is set to "month", we want to set the maximum date to the last day of the current month.
                    // Here, 0 represents last day of the month.
                    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
                else if (datePicker.dateRestriction === "year")
                    // If the date restriction is set to "year", we want to set the maximum date to the last day of the current year.
                    // Here, 11 represents December (since month indices are zero-based), and 31 is the last day of December.
                    // eslint-disable-next-line no-magic-numbers
                    return new Date(date.getFullYear(), 11, 31);
            } else return new Date();
            return new Date();
        }
    };
    //Used to fetch Counter Date(if "to" then "from" date is provided or vice versa), only in case of TBDatePickerType.
    const getDate = (datePicker: IDatePicker): Date | undefined => {
        const field = datePicker.field;
        let date: Date | undefined = undefined;
        const counterDatePicker: IDatePicker | undefined = props.datepickers.find((datepicker: IDatePicker) => {
            if (datepicker.field !== field) return datepicker;
        });

        props.datepickers.forEach((item: IDatePicker) => {
            if (item.field === counterDatePicker?.field) date = item.value as Date;
        });
        return date;
    };

    const getDatePickerId = (datePickerField: string): string => {
        return props.pageTitle + datePickerField;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {props.datepickers?.map((datePicker: IDatePicker) => {
                return props.errorMessageForTheDisabledDatePicker ? null : (
                    <Typography key={getDatePickerLabel(datePicker)} style={{ color: Colors.red }}>
                        {errorMessage[getDatePickerId(datePicker.field)]}
                    </Typography>
                );
            })}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                <div style={{ display: "flex", flexDirection: props.orientation === "vertical" ? "column" : "row" }}>
                    {props.datepickers?.map((datePicker: IDatePicker) => {
                        return (
                            <div key={getDatePickerLabel(datePicker)}>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {getDatePickerLabel(datePicker)}
                                </Typography>
                                <DatePicker
                                    key={datePicker.discriminator}
                                    label={""}
                                    value={datePicker.value}
                                    disabled={datePicker.disabled}
                                    onChange={(newValue: Date | null) => {
                                        datePicker.setValue(newValue);

                                        if (props.onChange) {
                                            props.onChange(newValue);
                                        }
                                    }}
                                    minDate={getMinDate(datePicker)}
                                    maxDate={getMaxDate(datePicker)}
                                    renderInput={(params: TextFieldProps) => (
                                        <TextField
                                            sx={{
                                                color: Colors.blue,
                                                marginRight: "10px",
                                                borderRadius: "3px",
                                                label: { fontSize: "0.8rem" },
                                                "& .MuiOutlinedInput-input.MuiInputBase-input": {
                                                    border: "2px solid",
                                                    borderColor: datePicker.disabled ? Colors.darkGrey : Colors.blue,
                                                    borderRadius: "3px",
                                                    backgroundColor: Colors.white,
                                                    boxSizing: "border-box",
                                                    color: "#333333",
                                                    padding: "8.5px 14px",
                                                    height: "1.875rem",
                                                },
                                            }}
                                            {...params}
                                        />
                                    )}
                                    mask="__.__.____"
                                    disableFuture={true}
                                    onError={(err: Error) => {
                                        console.log(err);
                                        if (err && props.isValidDatePickerValue) {
                                            props.isValidDatePickerValue(err);
                                        }
                                        if (err) {
                                            dateErrorHandler(err, datePicker);
                                        } else {
                                            dispatch(
                                                clearDatePickerErrorMsg({ field: getDatePickerId(datePicker.field) })
                                            );
                                        }
                                    }}
                                    onAccept={() => {
                                        dispatch(clearDatePickerErrorMsg({ field: getDatePickerId(datePicker.field) }));
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </LocalizationProvider>
        </div>
    );
};
