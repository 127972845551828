import { Button, SxProps, Theme } from "@mui/material";
import { Colors } from "./Colors";
import { NavigateFunction, useNavigate } from "react-router";

export type ButtonType = "button" | "reset" | "submit" | undefined;

interface IProps {
    title: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    type?: ButtonType;
}

// eslint-disable-next-line react/display-name
const MainButton = (sx: SxProps<Theme>, variant: "contained" | "text") => (props: IProps) => {
    return (
        <Button
            sx={{ textTransform: "none", textSize: "0.8125rem", paddingTop: "0rem", paddingBottom: "0rem", ...sx }}
            variant={variant}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.onClick(event)}
            disabled={props.disabled}
            type={props.type}
        >
            {props.title}
        </Button>
    );
};

export const ButtonPrimary = MainButton(
    {
        background: Colors.blue,
        color: Colors.white,
        height: "1.875rem",
    },
    "contained"
);

export const ButtonSecondary = MainButton(
    {
        background: Colors.white,
        color: Colors.blue,
    },
    "text"
);

export interface IEditButton {
    linkTo?: string;
}
export const EditButton = (props: IEditButton): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    return <ButtonSecondary title={"Bearbeiten"} onClick={() => navigate(props.linkTo ?? "edit")} />;
};
