/* AUTHOR: JESCHKE Moritz */

import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, IconButton } from "@mui/material";

interface ICollapsePanel {
    label: string;
    collapseable: React.JSX.Element;
    content: React.JSX.Element;
}

export const CollapsePanel = (props: ICollapsePanel): React.JSX.Element => {
    const [isHidden, setIsHidden] = useState<boolean>(false);

    const COMPLETE_PORTION: number = 12;
    const COLLAPSE_PORTION: number = 2;

    const getToggleButtonTitle = (): string => {
        return props.label + " " + (isHidden ? "einblenden" : "ausblenden");
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={isHidden ? 0 : COLLAPSE_PORTION}>
                <IconButton onClick={() => setIsHidden(!isHidden)} title={getToggleButtonTitle()}>
                    {isHidden ? <MenuIcon /> : <ChevronLeftIcon />}
                </IconButton>

                <div hidden={isHidden}>{props.collapseable}</div>
            </Grid>
            <Grid item xs={isHidden ? COMPLETE_PORTION : COMPLETE_PORTION - COLLAPSE_PORTION}>
                {props.content}
            </Grid>
        </Grid>
    );
};
