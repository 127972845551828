/* author: JESCHKE Moritz  */

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AmountDetails } from "./AmountDetails";
import { BaseBmgDetails } from "./BaseBmgDetails";
import { SpecuDetails } from "./SpecuDetails";
import { SpecuRucksackDetails } from "./SpecuRucksackDetails";
import { TaxResultDetails } from "./TaxResultDetails";
import { ValueAdjDetails } from "./ValueAdjDetails";
import { VapDetails } from "./VapDetails";
import { VapValueAdjDetails } from "./VapValueAdjDetails";
import { DERIVATE_TAB, INCOME_TAB, SECURITY_TAB, SPECIAL_ACC_TYPE } from "./TransactionOverview";
import { ITableContextMenu } from "components/Table";
import { getChangedValue } from "components/Converter";
import { DERIVATE_TRAN_SECTION } from "./DerivateTransactionEdit";
import { INCOME_SECTION_IDENTIFIER, INCOME_TRAN_SECTION } from "pages/transaction-data/IncomeTransactionEdit";
import { getEditState, getEditTo } from "components/VersionsView";
import { SECURITY_TRAN_SECTION, securityTransactionIdentifier } from "./SecurityTransactionEdit";

interface ITransactionContextMenu {
    currentTabText: string;
    contextMenus: Record<string, ITableContextMenu>;
    setContextmenus: Dispatch<SetStateAction<Record<string, ITableContextMenu>>>;
}

export const TransactionContextMenu = (props: ITransactionContextMenu): React.JSX.Element => {
    const [personalBmgOpen, setPersonalBmgOpen] = useState<boolean>(false);
    const [amountDetailOpen, setAmountDetailOpen] = useState<boolean>(false);
    const [taxResultOpen, setTaxResultOpen] = useState<boolean>(false);
    const [vapResultOpen, setVapResultOpen] = useState<boolean>(false);
    const [specuRucksackResultOpen, setSpecuRucksackResultOpen] = useState<boolean>(false);
    const [vapValueAdjDetailsDataOpen, setVapValueAdjDetailsDataOpen] = useState<boolean>(false);
    const [valueAdjDetailsDataOpen, setValueAdjDetailsDataOpen] = useState<boolean>(false);
    const [specuResultOpen, setSpecuResultOpen] = useState<boolean>(false);

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    const editCancelDuplicateCondition = (rowData: Record<string, unknown>): boolean =>
        rowData["transactionType"] && String(rowData["transactionType"]).startsWith("sto") ? false : true;

    const securityContextMenu: ITableContextMenu = {
        contextActions: [
            { label: "Sachliche BMG", action: () => setPersonalBmgOpen(true) },
            { label: "Steuerliche Ergebnisse", action: () => setTaxResultOpen(true) },
            { label: "Vorabpauschale", action: () => setVapResultOpen(true) },
            { label: "Detaillierte Anschaffungsdaten", action: () => setSpecuRucksackResultOpen(true) },
            {
                label: "Wertberichtigungen VAP",
                action: () => setVapValueAdjDetailsDataOpen(true),
            },
            {
                label: "Wertberichtigungen",
                action: () => setValueAdjDetailsDataOpen(true),
            },
            {
                label: "Detaillierte Substanzausschüttung",
                action: () => setSpecuResultOpen(true),
                condition: currentRowData["taxEvent"]
                    ? currentRowData["taxEvent"].toString().match(/\d+/g)?.includes("5810")
                    : false,
            },
            {
                label: "Bearbeiten Transaktionsdaten",

                link: {
                    to: getEditTo(Number(currentRowData["id"]), SECURITY_TRAN_SECTION, securityTransactionIdentifier),
                    state: getEditState(getChangedValue(currentRowData, "modifyAction") ?? ""),
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
            {
                label: "Stornieren",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), SECURITY_TRAN_SECTION, securityTransactionIdentifier),
                    state: { isCancel: true },
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
            {
                label: "Duplizieren",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), SECURITY_TRAN_SECTION, securityTransactionIdentifier),
                    state: { isDuplicate: true },
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
        ],

        setCurrentRowData: setCurrentRowData,
    };
    const tftContextMenu: ITableContextMenu = {
        contextActions: [
            { label: "Beträge", action: () => setAmountDetailOpen(true) },
            { label: "Sachliche BMG", action: () => setPersonalBmgOpen(true) },
            { label: "Steuerliche Ergebnisse", action: () => setTaxResultOpen(true) },
            {
                label: "Bearbeiten Transaktionen",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), INCOME_TRAN_SECTION, INCOME_SECTION_IDENTIFIER),
                    state: getEditState(getChangedValue(currentRowData, "modifyAction") ?? ""),
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
            {
                label: "Stornieren",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), INCOME_TRAN_SECTION, INCOME_SECTION_IDENTIFIER),
                    state: { isCancel: true },
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
            // {
            //     label: "Duplizieren",
            //     link: {
            //         to: getEditTo(INCOME_TRAN_SECTION, Number(currentRowData["id"]), INCOME_SECTION_IDENTIFIER),
            //         state: { isDuplicate: true },
            //     },
            //     condition: editCancelDuplicateCondition(currentRowData),
            // },
        ],

        setCurrentRowData: setCurrentRowData,
    };

    const derContextMenu: ITableContextMenu = {
        contextActions: [
            { label: "Sachliche BMG", action: () => setPersonalBmgOpen(true) },
            { label: "Steuerliche Ergebnisse", action: () => setTaxResultOpen(true) },
            {
                label: "Wertberichtigungen",
                action: () => setValueAdjDetailsDataOpen(true),
            },
            {
                label: "Bearbeiten Transaktionsdaten",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), DERIVATE_TRAN_SECTION),
                    state: getEditState(getChangedValue(currentRowData, "modifyAction") ?? ""),
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
            {
                label: "Stornieren",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), DERIVATE_TRAN_SECTION),
                    state: { isCancel: true },
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
            {
                label: "Duplizieren",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), DERIVATE_TRAN_SECTION),
                    state: { isDuplicate: true },
                },
                condition: editCancelDuplicateCondition(currentRowData),
            },
        ],

        setCurrentRowData: setCurrentRowData,
    };

    useEffect(() => {
        props.setContextmenus({
            [SECURITY_TAB]: securityContextMenu,
            [DERIVATE_TAB]: derContextMenu,
            [INCOME_TAB]: tftContextMenu,
        });
    }, [currentRowData]);

    const accType: string = currentRowData.accountData
        ? (currentRowData.accountData as unknown as Record<string, string>).accountType
        : "";
    const accountNr: string = currentRowData.accountData
        ? (currentRowData.accountData as unknown as Record<string, string>).accountNr
        : "";

    const getType = (): string => {
        if (props.currentTabText === SECURITY_TAB) {
            return "secTransactionData";
        } else if (props.currentTabText === DERIVATE_TAB) {
            return "derTransactionData";
        } else {
            return "";
        }
    };

    return (
        <div>
            {amountDetailOpen && (
                <AmountDetails
                    id={Number(currentRowData.incomeTranId)}
                    transactionType={String(currentRowData.transactionType)}
                    setOpen={setAmountDetailOpen}
                />
            )}
            {personalBmgOpen && (
                <BaseBmgDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    impId={String(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    system={props.currentTabText === INCOME_TAB ? "F" : "C"}
                    setOpen={setPersonalBmgOpen}
                />
            )}
            {taxResultOpen && (
                <TaxResultDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    accountNr={(accType === SPECIAL_ACC_TYPE ? "Depotnummer" : "Kontonummer") + ": " + accountNr}
                    impId={Number(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    system={props.currentTabText === INCOME_TAB ? "F" : "C"}
                    setOpen={setTaxResultOpen}
                />
            )}
            {vapResultOpen && (
                <VapDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    impId={Number(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    setOpen={setVapResultOpen}
                />
            )}
            {specuRucksackResultOpen && (
                <SpecuRucksackDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    impId={Number(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    setOpen={setSpecuRucksackResultOpen}
                />
            )}
            {vapValueAdjDetailsDataOpen && (
                <VapValueAdjDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    impId={Number(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    setOpen={setVapValueAdjDetailsDataOpen}
                />
            )}
            {valueAdjDetailsDataOpen && (
                <ValueAdjDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    impId={Number(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    setOpen={setValueAdjDetailsDataOpen}
                    type={getType()}
                />
            )}
            {specuResultOpen && (
                <SpecuDetails
                    extTranKey={String(currentRowData.extTranKey)}
                    impId={Number(
                        props.currentTabText === INCOME_TAB ? currentRowData.incomeTranId : currentRowData.impId
                    )}
                    setOpen={setSpecuResultOpen}
                    generalData={{
                        taxEvent: String(currentRowData.taxEvent),
                        depotNumber: accountNr,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        secCode: String((currentRowData.securityData as any).secCode),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        shortname: String((currentRowData.securityData as any).shortName),
                        executionTime: String(currentRowData.executionTime),
                        exDate: String(currentRowData.exDate),
                        detachDate: String(currentRowData.detachDate),
                        refNominal: String(currentRowData.referenceNominal),
                    }}
                />
            )}
        </div>
    );
};
