/* author: JESCHKE Moritz */

import { useState, useEffect } from "react";
import { Footer, IFooterButton } from "./Footer";
import { ISearchCriteria } from "./Search";
import { NavigateFunction, useNavigate } from "react-router";
import { fileDownload } from "application/api/HttpRequestHandler";
import { SearchInputFields } from "./SearchInputFields";
import { Typography } from "@mui/material";

export interface IFileDownload {
    title: string;
    searchCriterias: ISearchCriteria[];
    fileDownloadUrl: string;
    initialData: Record<string, string>;
    fileName: string;
}

export const FileDownload = (props: IFileDownload): React.JSX.Element => {
    const [searchCriteriaText, setSearchCriteriaText] = useState<Record<string, string>>({});

    const navigate: NavigateFunction = useNavigate();
    const footerButtons: IFooterButton[] = [
        { title: "Abbrechen", onClick: () => navigate("/home"), filled: false },
        {
            title: "Download",
            onClick: () =>
                fileDownload({ path: props.fileDownloadUrl as never, params: searchCriteriaText }, props.fileName),
            filled: true,
        },
    ];

    const updateSearchCriteriaState = (newState: Record<string, string>) => {
        setSearchCriteriaText(newState);
    };

    useEffect(() => {
        setSearchCriteriaText(props.initialData);
    }, [props.initialData]);

    return (
        <div style={{ margin: "4rem" }}>
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginBottom: "1rem",
                }}
            >
                {props.title}
            </Typography>
            <SearchInputFields
                searchCriterias={props.searchCriterias}
                searchCriteriaToggle={false}
                searchCriteriaText={searchCriteriaText}
                updateSearchCriteriaState={updateSearchCriteriaState}
                //MoJ:: these fields are for the datepickers -> currently not in use
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                getDatePickers={(_searchCriteria: ISearchCriteria) => []}
                datePickerPageTitle={""}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                getDatePickerOrientation={(_searchCriteria: ISearchCriteria) => "horizontal"}
            />
            <Footer buttons={footerButtons} />
        </div>
    );
};
