/* author: Aashish Upadhyay */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";

export const SEC_CORP_ACTION_SECTION: string = "Kapitalmaßnahmen";

export const SecCorpActionEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const securityId: number = Number(params.securityId);
    const section: string | undefined = params.section;
    const bidId: number = Number(params.bidId);

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: "/security/secCorpAction/byBidIdForEdit",
            params: { bidId: bidId },
        });

    const secCorpActionEditInfo: IEditDataViewUserConfiguration = {
        headline: SEC_CORP_ACTION_SECTION,
        attributes: [
            {
                field: "actionStatus",
                headerName: "KD001 - Kapitalveränderungsstatus",
                dropdown: {
                    keyTableTabname: "TB_WMK12",
                },
            },
            {
                field: "actionType",
                headerName: "KD005 - KapitalveränderungsArt",
                dropdown: {
                    keyTableTabname: "TB_WMK01",
                },
            },
            {
                field: "subRightRatioNumCur",
                headerName: "KD008A  - Whrg Bezugsverhältnis Zähler",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            {
                field: "subRightRatioNum",
                headerName: "KD008B  - Bezugsverhältnis Zähler",
            },
            {
                field: " subRightRatioDenCur",
                headerName: "KD009A  - Whrg Bezugsverhältnis Nenner",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            {
                field: "subRightRatioDen",
                headerName: "KD009B  - Bezugsverhältnis Nenner",
            },
            {
                field: "subScriptPrice",
                headerName: "KD010A  - Bezugspreis",
            },
            {
                field: "subScriptPriceCur",
                headerName: "KD012  - Whrg Bezugspreis",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            {
                field: "detachDate",
                headerName: "KD013  - Trenntermin",
                type: "date",
            },
            {
                field: "subPeriodFrom",
                headerName: "KD014-1  - Bezugsfrist von",
                type: "date",
            },
            {
                field: "subPeriodTo",
                headerName: "KD014-2  - Bezugsfrist bis",
                type: "date",
            },
            {
                field: "exDate",
                headerName: "KD018  - ExTag",
                type: "date",
            },
            {
                field: "wknRightsIssue",
                headerName: "KD021  - Bezugsrecht WKN",
            },
            {
                field: "isinRightsIssue",
                headerName: "KD021PI  - ISIN Bezugsrecht",
            },
            {
                field: "titleWkn",
                headerName: "KD022  - WKN",
            },
            {
                field: "titleIsin",
                headerName: "KD022PI  - ISIN",
            },
            {
                field: "detRatioNumCur",
                headerName: "KD025A  - Whrg Trennverhältnis Zähler",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            {
                field: "detRatioNum",
                headerName: "KD025B  - Trennverhältnis Zähler",
            },
            {
                field: "detRatioDenCur",
                headerName: "KD026A  - Whrg Trennverhätnis Nenner",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            {
                field: "detRatioDen",
                headerName: "KD026B  - Trennverhältnis Nenner",
            },
            {
                field: "subRightPart",
                headerName: "KD028  - Bezugsrechtverwertung",
                dropdown: {
                    keyTableTabname: "TB_WMK05",
                },
            },
            {
                field: "stockHoldDate",
                headerName: "KD031  - Tag der Hauptversammlung",
                type: "date",
            },
            {
                field: "subScriptPricePercent",
                headerName: "KD034  - Prozentkennzeichen KD",
                dropdown: {
                    keyTableTabname: "TB_WMW03",
                },
            },
            {
                field: "tecWknRights",
                headerName: "KD035  - techn. Bezugsrecht WKN",
            },
            {
                field: "tecIsinRights",
                headerName: "KD035PI  - techn. Bezugsrecht ISIN",
            },
            {
                field: "adjustFactor",
                headerName: "KD059  - Bereinigungsfaktor",
            },
            {
                field: "priceBasic",
                headerName: "KD080  - Kursbasis",
            },
            {
                field: "flagAnnualTaxCert",
                headerName: "KD084  - KZ Jahresbescheinigung",
                dropdown: {
                    keyTableTabname: "TB_WMG83",
                },
            },
            {
                field: "corpLawActivity",
                headerName: "KD087  - KZ ges. Vorgänge",
                dropdown: {
                    keyTableTabname: "TB_WMK13",
                },
            },
            {
                field: "corpRegisterDate",
                headerName: "KD088  - Datum HR-Eintrag",
                type: "date",
            },
            {
                field: "kd230",
                headerName: "KD230  - Cluster KM-Matrix",
                dropdown: {
                    keyTableTabname: "TB_WMFA7",
                },
                displayOnly: true,
            },
            {
                field: "beforePar",
                headerName: "KD460A  - Nennwert vor Kapitalmaßnahme",
            },
            {
                field: "afterPar",
                headerName: "KD460B  - Nennwert nach Kapitalmaßnahme",
            },
            {
                field: "beforeWithoutPar",
                headerName: "KD460C  - ohne Nennwert vor Kapitalmaßnahme",
                dropdown: {
                    keyTableTabname: "TB_WMG71",
                },
            },
            {
                field: "afterWithoutPar",
                headerName: "KD460D  - ohne Nennwert nach Kapitalmaßnahme",
                dropdown: {
                    keyTableTabname: "TB_WMG71",
                },
            },
            {
                field: "caSortOrder",
                headerName: "St. KZ CA-Reihenfolge",
                rules: {
                    pattern: {
                        value: /^(?:[1-9][0-9]?|99)?$/,
                        customMessage: "Die Zahl kann nur leer sein oder zwischen 1 und 99 liegen",
                    },
                },
            },
        ],

        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [secCorpActionEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={securityId}
            title="Kapitalmaßnahme bearbeiten"
            section={section}
            parentIdentifier="securityId"
        />
    );
};
