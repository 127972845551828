/* author: JESCHKE Moritz */

import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { VersionsView } from "components/VersionsView";
import { GENERAL_PERSON_SECTION } from "./PersonOverviewEdit";
import { ITableColumn } from "components/Table";

interface IPersonVersions {
    personId: number;
}

export const PersonVersions = (props: IPersonVersions): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "lastName", title: "Nachname" },
        { field: "firstName", title: "Vorname" },
        { field: "middleName", title: "Zweitname" },
        { field: "birthName", title: "Geburtsname" },
        { field: "title", title: "Titel" },
        {
            field: "birthDate",
            title: "Geburtsdatum",
            type: "date",
        },
        { field: "birthPlace", title: "Geburtsort" },
        { field: "nationality", title: "Nationalität" },
        { field: "domicile", title: "steuerl. Wohnsitz" },
        { field: "gender", title: "Geschlecht" },
        { field: "tin", title: "TIN" },
        { field: "deathDate", title: "Sterbedatum", type: "date" },
        { field: "federalState", title: "Bundesland" },
        { field: "mail", title: "E-Mail" },
        { field: "validFrom", title: "Gültig von", type: "date" },
        {
            field: "validTo",
            title: "Gültig bis",
            type: "date",
        },
    ];

    const IDENTIFIER: TableidentifierType = "personVersions";

    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/person/with-all-timeslices",
        params: { personId: props.personId },
    };

    return (
        <VersionsView
            columnsDefinition={columns}
            identifier={IDENTIFIER}
            title="Personen Zeitscheiben"
            dataRequest={dataRequest}
            historyPath="personHistory"
            editSection={GENERAL_PERSON_SECTION}
            addFunction
        />
    );
};
