/* author: JESCHKE Moritz */

import { Typography } from "@mui/material";
import { convert, ConvertType, createKey, getChangedOrOriginalValue } from "./Converter";

export interface IOverviewDataViewWithData extends IOverviewDataView {
    data: Record<string, string>;
}

export interface IOverviewDataView {
    attributes: IAttribute[];
    columnNumber: number;
}

export interface IAttribute {
    headerName: string;
    field: string;
    type?: ConvertType;
    filter?: (option: string) => string;
}

export const OverviewDataView = (props: IOverviewDataViewWithData): React.JSX.Element => {
    const getValue = (attribute: IAttribute): string => {
        let value: string = convert(getChangedOrOriginalValue(props.data, attribute.field), attribute.type);

        if (attribute.filter) value = attribute.filter(value);

        return value === "" ? "—" : value;
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(" + props.columnNumber + ", 1fr)",
                gap: "1rem",
                marginBottom: "1rem",
            }}
        >
            {props.attributes.map((attribute: IAttribute) => {
                return (
                    <div key={createKey("overviewDataView", attribute.field)}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "0.875rem" }}>
                            {attribute.headerName}
                        </Typography>
                        <Typography sx={{ whiteSpace: "pre-line", fontSize: "0.875rem" }}>
                            {getValue(attribute)}
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};
