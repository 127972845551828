/* author: Aashish Upadhyay  */

import { HistoryView, IHistoryKeys } from "components/HistoryView";
import React from "react";
import { Params, useParams } from "react-router";

export const FsaLimitHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const fsaLimitId: number = Number(params.fsaLimitId);

    const labels: IHistoryKeys[] = [
        {
            field: "exemptAmount",
            label: "Freistellungsbetrag",
        },
    ];

    return (
        <HistoryView
            title={"Historie FSA Limit"}
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/fsaNv/fsaLimitHistory",
                params: { fsaLimitId: fsaLimitId },
            }}
            identifier={"fsaLimitHistory"}
        />
    );
};
