/* author: JESCHKE Moritz */

import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { KeyTableConsts } from "components/KeyTableConsts";
import { Params, useParams } from "react-router";

export const AccountEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const accountId: number = Number(params.accountId);
    const section: string | undefined = params.section;

    const accountEditInfo: IEditDataViewUserConfiguration = {
        headline: "Konto",
        attributes: [
            { field: "branch", headerName: "Filialnummer", displayOnly: true },
            { field: "accountNr", headerName: "Kontonummer", displayOnly: true },
            {
                field: "accountType",
                headerName: "Typ",
                dropdown: {
                    keyTableTabname: KeyTableConsts.ACC_TYPE,
                },
            },
            { field: "name", headerName: "Kontoname" },
            { field: "country", headerName: "Kontoland", displayOnly: true },
            { field: "currency", headerName: "Kontowährung", dropdown: { keyTableTabname: "TB_WMW02" } },
            { field: "iban", headerName: "IBAN" },
        ],
        latestVersionRequest: () =>
            sendGetRequest({ path: "/customerstaticdata/account/getAccountForEdit", params: { accountId: accountId } }),
        versionRequest: (() => undefined) as () => unknown as () => GET_REQUEST_TYPE,
    };

    return (
        <EditMask
            completeEditInfo={[accountEditInfo]}
            id01={accountId}
            title="Kontodaten bearbeiten"
            section={section}
        />
    );
};
