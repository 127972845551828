/* author: JESCHKE Moritz */

import { customDateValueGetter } from "components/Converter";
import { useAppDispatch } from "application/redux/Hooks";
import {
    addSelectedTransactionRow,
    removeSelectedTransactionRow,
    setIsIncome,
} from "application/redux/slices/TransactionSlice";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { AppDispatch, RootState } from "application/redux/Store";
import { ITableColumn, ITableContextMenu, ITableRowSelection, Table } from "components/Table";
import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

interface IProps {
    setJstbDialogOpen: Dispatch<SetStateAction<boolean>>;
    tableTransactionData: never[];
}

export const TcgTransaction = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});
    const selectedTransactionRows: never[] = useSelector(
        (state: RootState) => state.transaction.selectedTransactionRows
    );

    const IDENTIFIER: TableidentifierType = "tcg";

    const secTransactionColumns: ITableColumn[] = [
        { field: "wkn", title: "WKN" },
        { field: "isin", title: "ISIN" },
        { field: "transactionKeyMandant", title: "Transaktionskey des Mandanten" },
        { field: "externBusinessReference", title: "externer Geschäftsvorfall" },
        {
            field: "executeTime",
            title: "Ausführungstag",
            type: "date",
        },
        { field: "taxEvent", title: "Steuerliche Geschäftsart" },
        {
            field: "nominal",
            title: "Nominal",
            type: "numeric",
        },
        {
            field: "tradeMarketValue",
            title: "Kurswert Handelswährung",
            type: "numeric",
        },
        {
            field: "tradeaddExpenseCosts",
            title: "Nebenkosten in Handelswährung",
            type: "numeric",
        },
        {
            field: "marketValueEur",
            title: "Kurswert EUR",
            type: "numeric",
        },
        {
            field: "expenseCostsEUR",
            title: "Nebenkosten in EUR",
            type: "numeric",
        },
        {
            field: "valutaDate",
            title: "Valuta",
            type: "date",
        },
        {
            field: "taxBusinessTime",
            title: "Steuerlicher Geschäftstag",
            type: "date",
        },
        {
            field: "instructionDate",
            title: "Weisungsdatum",
            type: "date",
        },
        {
            field: "exDate",
            title: "ExTag",
            type: "date",
        },
        {
            field: "valuationEUR",
            title: "Bewertung in EUR",
            type: "numeric",
        },
        {
            field: "valuationDate",
            title: "Bewertungstag",
            type: "date",
        },
        { field: "calculationFlag", title: "Berechenbarkeit" },
        { field: "execState", title: "Ausführungsstatus" },
        { field: "bidExtern", title: "externe BID" },
        { field: "cancellationFlag", title: "Stornoflag" },
        {
            field: "insertTS",
            title: "Einfügedatum",
            type: "date",
        },
    ];

    const contextMenu: ITableContextMenu = {
        contextActions: [
            {
                label: "Details zum Event",
                action: () => switchTransactionTab(0),
            },
            {
                label: "Sachliche Bemessungsgrundlage",
                action: () => switchTransactionTab(1),
            },
            {
                label: "Persönliche Bemessungsgrundlage",
                action: () => switchTransactionTab(2),
            },
            {
                label: "VAP Wertberichtigung",
                action: () => switchTransactionTab(3),
            },
            {
                label: "Detaillierte Anschaffungsdaten",
                action: () => switchTransactionTab(4),
            },
        ],
        setCurrentRowData: setCurrentRowData,
    };

    const switchTransactionTab = (newTabId: number) => {
        if (currentRowData !== undefined) {
            dispatch(setIsIncome({ isIncome: false }));
            dispatch(
                addSelectedTransactionRow({
                    //@ts-ignore
                    impId: currentRowData.impId,
                    //@ts-ignore
                    bidId: currentRowData.bidId,
                    //@ts-ignore
                    stga: currentRowData.taxEvent.split("/")[0],
                    tabId: newTabId,
                    switchSite: true,
                    label:
                        //@ts-ignore
                        currentRowData.taxEvent.split("/")[0] +
                        " | " +
                        //@ts-ignore
                        customDateValueGetter({ value: currentRowData.executeTime }),
                })
            );
        }
    };

    const selection: ITableRowSelection = {
        onSelect: (rowData: any) => {
            dispatch(
                addSelectedTransactionRow({
                    impId: rowData.impId,
                    bidId: rowData.bidId,
                    stga: rowData.taxEvent.split("/")[0],
                    tabId: 0,
                    switchSite: false,
                    label:
                        rowData.taxEvent.split("/")[0] + " | " + customDateValueGetter({ value: rowData.executeTime }),
                })
            );
        },
        onDeselect: (rowData: any) => {
            dispatch(removeSelectedTransactionRow({ impId: rowData.impId }));
        },
        selectedRows: selectedTransactionRows,
        selector: "impId",
    };

    return (
        <Table
            tableData={props.tableTransactionData}
            identifier={IDENTIFIER}
            columnsDefinition={secTransactionColumns}
            contextMenu={contextMenu}
            selection={selection}
        />
    );
};
