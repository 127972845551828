/* author: JESCHKE Moritz */

import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { useEffect, useState } from "react";
import { ITableColumn, ITableContextMenu, ITableContextMenuElement, Table, getContextMenu } from "./Table";
import { Footer, IFooter } from "./Footer";
import { NavigateFunction, useNavigate } from "react-router";
import { getChangedOrOriginalValue } from "./Converter";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TECHNICAL_EDIT_COLUMNS } from "./EditComponents/EditMask";

interface IEditPendingLinked {
    title: string;
    identifier: TableidentifierType;
    columns: ITableColumn[];
    dataRequest: AllowedRequestsTypeGet;
    navigate?: {
        linkTo: string;
        idField: string;
    };
    contextMenu?: ITableContextMenuElement | ITableContextMenu;
}

export const EditPendingLinked = (props: IEditPendingLinked): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const [tableData, setTableData] = useState<Record<string, string | number>[]>([]);

    const footer: IFooter = {
        buttons: [{ title: "Abbrechen", filled: false, onClick: () => navigate("/home") }],
    };

    const handleRowClick = async (_event: Record<string, unknown>, rowData: Record<string, string>) => {
        props.navigate &&
            navigate("/" + props.navigate.linkTo + "/" + getChangedOrOriginalValue(rowData, props.navigate.idField));
    };

    useEffect(() => {
        sendGetRequest(props.dataRequest).then((response) => {
            setTableData(response.data);
        });
    }, [props.dataRequest]);

    const getContextMenuElement = (): React.JSX.Element | undefined => {
        if (props.contextMenu && (props.contextMenu as ITableContextMenuElement).element) {
            return (props.contextMenu as ITableContextMenuElement).element;
        }
        return undefined;
    };

    return (
        <div style={{ margin: "4rem" }}>
            <Table
                title={"Bearbeitungsstatus " + props.title}
                tableData={tableData}
                identifier={props.identifier}
                columnsDefinition={props.columns.concat(TECHNICAL_EDIT_COLUMNS)}
                onRowClick={props.navigate && handleRowClick}
                showEditPendingStateIcons
                contextMenu={getContextMenu(props.contextMenu)}
            />
            <Footer buttons={footer.buttons} />

            {getContextMenuElement()}
        </div>
    );
};
