/* author: Aashish Upadhyay*/

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { Location, Params, useLocation, useParams } from "react-router";
import { transactionCancelation, transactionUpdate } from "./SecurityTransactionEdit";
import { KeyTableConsts } from "components/KeyTableConsts";

export const DERIVATE_TRAN_SECTION: string = "Derivate Transaktionen";

export const DerivateTransactionEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const impId: number = Number(params.impId === "new" ? -1 : params.impId);
    const section: string | undefined = params.section;

    const location: Location = useLocation();
    const isNew: boolean = location.state && location.state.isNew;
    const isCancelation: boolean = location.state && location.state.isCancel;
    const isDuplication: boolean = location.state && location.state.isDuplicate;
    const data: Record<string, string> = location.state && location.state.data;

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: "/cgresources/cgtransactions/dertransactionById-with-keydata",
            params: { id: impId, isNew: isNew },
        });

    const derivateTransactionEditInfo: IEditDataViewUserConfiguration = {
        headline: DERIVATE_TRAN_SECTION,
        attributes: [
            {
                field: "derivativeData.derivativeCode",
                headerName: "Derivatecode",
                displayOnly: true,
            },
            {
                field: "derivativeData.shortName",
                headerName: "Kurzbezeichnung",
                displayOnly: true,
            },
            { field: "extBusinessRef", headerName: "Transaktionsreferenz", displayOnly: true },
            { field: "extTranKey", headerName: "Umsatzreferenz", displayOnly: true },
            { field: "extEvent", headerName: "Geschäftsart" },
            { field: "extEventGroup", headerName: "Vorgangsgr.-Schlüssel", displayOnly: true },
            {
                field: "taxEvent",
                headerName: "StGA",
                dropdown: {
                    keyTableTabname: "TAX_EVENT_TCG",
                },
                displayOnly: !window.location.href.includes("/new"),
            },
            { field: "accountData.branch", headerName: "Filialnummer", displayOnly: true },
            { field: "accountData.accountNr", headerName: "Kontonummer", displayOnly: true },
            { field: "nominal", headerName: "Umsatz" },
            { field: "referenceNominal", headerName: "Referenznominal", displayOnly: true },
            {
                field: "nomCur",
                headerName: "Stk/Whrg",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            {
                field: "tradeCur",
                headerName: "Handels Whrg",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            { field: "tradeExchRate", headerName: "DevKurs Handels Whrg" },
            { field: "tradeMarketValue", headerName: "Kurswert Handels Whrg" },
            {
                field: "settlCur",
                headerName: "Abr.-Whrg",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            { field: "settlExchRate", headerName: "DevKurs Abr.-Whrg" },
            { field: "settlMarketValue", headerName: "Kurswert Abr.-Whrg" },
            { field: "settlAddExpCost", headerName: "Anschaff. NK Abr.-Whrg" },
            { field: "marketValueEur", headerName: "Gegenwert EUR" },
            { field: "tradeAddExpCost", headerName: "NK Abr.-Whrg" },
            { field: "valuationEur", headerName: "Bewertung am Bewertungstag in EUR" },
            { field: "settlementStockPrice", headerName: "Börsenkurs Handels Whrg" },
            { field: "addExpCostEur", headerName: "NK EUR" },
            {
                field: "refDelSystem",
                headerName: "Liefersystem",
                dropdown: {
                    keyTableTabname: "DELSYS",
                },
            },
            {
                field: "executionTime",
                headerName: "Ausführ.-Datum",
                type: "date",
            },
            {
                field: "valutA",
                headerName: "Valuta",
                type: "date",
            },
            {
                field: "bookingDate",
                headerName: "Buchungs-Datum",
                type: "date",
            },
            {
                field: "acqExchangeRateDate",
                headerName: "Tag des ermittelten Devisenkurses",
                type: "date",
                displayOnly: true,
            },
            {
                field: "taxBusinessDay",
                headerName: "Steuer-Datum",
                type: "date",
            },
            {
                field: "instructionDate",
                headerName: "Weisungs-Datum",
                type: "date",
            },
            {
                field: "settlValueDate",
                headerName: "Stückevaluta-Datum",
                type: "date",
            },
            { field: "fractionPaymentT", headerName: "Spitzenausgleich in Handels Whrg" },
            { field: "fractionPaymentE", headerName: "Spitzenausgleich in EUR" },
            { field: "fractionPaymentS", headerName: "Spitzenausgleich in Abr.-Whrg" },
            {
                field: "overCounterFlag",
                headerName: "Tafelgeschäft",
                dropdown: { keyTableTabname: "OVER_COUNTER_FLAG" },
                displayOnly: true,
            },
            { field: "flagSpeku", headerName: "Anschaffungsdaten Detail", displayOnly: true },
            {
                field: "flagValueAdj",
                headerName: "Wertkorrekturen",
                dropdown: {
                    keyTableTabname: "ADJFLAG",
                },
                displayOnly: true,
            },
            { field: "externalVoucherKey", headerName: "Externe Beleg-Nummer", displayOnly: true },
            { field: "fictWithhTax", headerName: "Fiktive Quellensteuer", displayOnly: true },
            { field: "structProdRef", headerName: "Referenz strukturiertes Produkt" },
            {
                field: "delBankInEU",
                headerName: "Lieferbank in EU",
                dropdown: {
                    keyTableTabname: "DEL_BANK_IN_EU",
                },
            },
            { field: "extDelivery", headerName: "Externe Lieferung" },
            {
                field: "depTransfCentre",
                headerName: "TaxBox-KZ",
                dropdown: {
                    keyTableTabname: "DEPTRANSFCENTRE",
                },
            },
            {
                field: "cancellationFlag",
                headerName: "StoKZ",
                dropdown: {
                    keyTableTabname: "STORNOFLAG",
                },
            },
            { field: "cancellationExtTranKey", headerName: "Umsatzreferenz stornierter Umsatz", displayOnly: true },
            { field: "calculationFlag", headerName: "Berechenbarkeit", displayOnly: true },
            {
                field: "execState",
                headerName: "Ausführungsstatus",
                dropdown: {
                    keyTableTabname: "EXECSTATE",
                },
                displayOnly: true,
            },
            {
                field: "manualCheckRequired",
                headerName: "Man. Prüf.",
                dropdown: {
                    keyTableTabname: KeyTableConsts.MANUAL_CHECK_REQUIRED,
                },
            },
            {
                field: "fiscalValuation",
                headerName: "Steuerl. bewertbar",
                dropdown: {
                    keyTableTabname: "FISCAL_VALUATION",
                },
            },
            {
                field: "crossCurrencyFlag",
                headerName: "Cross-Currency-Flag",
                dropdown: {
                    keyTableTabname: KeyTableConsts.CROSS_CURRENCY_FLAG,
                },
            },
            { field: "multipleCloseFlag", headerName: "MultipleCloseFlag" },
            { field: "transContractSize", headerName: "Kontraktgröße im Umsatz", displayOnly: true },
            {
                field: "subrogativeEv",
                headerName: "Gläubigerwechsel",
                dropdown: {
                    keyTableTabname: "SUBROGATIVE_EV",
                },
                displayOnly: true,
            },
            {
                field: "transferReasonEv",
                headerName: "Besonderheiten Übertrag",
                dropdown: {
                    keyTableTabname: "TRANSFERREASON_EV",
                },
            },
            {
                field: "transferTypeEv",
                headerName: "Art Übertrag",
                dropdown: {
                    keyTableTabname: "TRANSFERTYPE_EV",
                },
            },
            { field: "rltdRef", headerName: "Settl. Referenz Übertrag" },
            { field: "comment", headerName: "Kommentar" },
            {
                field: "reportStatus",
                headerName: "Report Status",
                displayOnly: true,
                dropdown: { keyTableTabname: "TRANSREPORTSTATUS" },
            },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
        updateFields: isCancelation ? transactionCancelation(isCancelation) : transactionUpdate(data),
        duplicate: { condition: isDuplication },
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [derivateTransactionEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={impId}
            title="Derivate Transaktionen"
            section={section}
            isCancelation={isCancelation}
            duplication={isDuplication}
            overrideRootLevelForInsert={4}
        />
    );
};
