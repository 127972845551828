/* author: JESCHKE Moritz */

import { customDateValueGetter } from "components/Converter";
import { useAppDispatch } from "application/redux/Hooks";
import { addSelectedIncomeRow, removeSelectedIncomeRow, setIsIncome } from "application/redux/slices/TransactionSlice";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { AppDispatch, RootState } from "application/redux/Store";
import { ITableColumn, ITableContextMenu, ITableRowSelection, Table } from "components/Table";
import { useState } from "react";
import { useSelector } from "react-redux";

interface IProps {
    tableIncomeData: never[];
}

export const TftTransaciton = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});
    const selectedIncomeRows: never[] = useSelector((state: RootState) => state.transaction.selectedIncomeRows);

    const IDENTIFIER: TableidentifierType = "tft";

    const incomeTransactionColumns: ITableColumn[] = [
        { field: "bidExtern", title: "Externe BID" },
        { field: "wkn", title: "WKN" },
        { field: "isin", title: "ISIN" },
        { field: "taxEvent", title: "Steuerliche Geschäftsart" },
        {
            field: "nominal",
            title: "Nominal",
            type: "numeric",
        },
        { field: "nomCur", title: "Nominal Währung" },
        { field: "currency", title: "Währung" },
        {
            field: "relDate",
            title: "Ertragsdatum",
            type: "date",
        },
        { field: "type", title: "Typ" },
        { field: "cancellationFlag", title: "Stornoflag" },
        { field: "calculationFlag", title: "Status Berechenbarkeit" },
        { field: "execState", title: "Ausführungsstatus" },
        { field: "extTranKey", title: "Transaktionsschlüssel des Mandanten" },
        { field: "extTranKeyRoot", title: "Root Record Number" },
    ];

    const contextMenu: ITableContextMenu = {
        contextActions: [
            { label: "Details zum Event", action: () => switchIncomeTab(0) },
            {
                label: "Sachliche Bemessungsgrundlage",
                action: () => switchIncomeTab(1),
            },
            {
                label: "Persönliche Bemessungsgrundlage",
                action: () => switchIncomeTab(2),
            },
            { label: "Beträge", action: () => switchIncomeTab(3) },
        ],
        setCurrentRowData: setCurrentRowData,
    };

    const switchIncomeTab = (newTabId: number) => {
        dispatch(setIsIncome({ isIncome: true }));
        dispatch(
            addSelectedIncomeRow({
                //@ts-ignore
                incomeTranId: currentRowData.incomeTranId,
                //@ts-ignore
                bidId: currentRowData.bidId,
                tabId: newTabId,
                switchSite: true,
                label:
                    //@ts-ignore
                    currentRowData.taxEvent.split("/")[0] +
                    " | " +
                    //@ts-ignore
                    customDateValueGetter({ value: currentRowData.relDate }),
            })
        );
    };

    const selection: ITableRowSelection = {
        onSelect: (rowData: any) => {
            dispatch(
                addSelectedIncomeRow({
                    bidId: rowData.bidId,
                    tabId: 0,
                    switchSite: false,
                    incomeTranId: rowData.incomeTranId,
                    label: rowData.taxEvent.split("/")[0] + " | " + customDateValueGetter({ value: rowData.relDate }),
                })
            );
        },
        onDeselect: (rowData: any) => {
            dispatch(removeSelectedIncomeRow({ incomeTranId: rowData.incomeTranId }));
        },
        selectedRows: selectedIncomeRows,
        selector: "incomeTranId",
    };

    return (
        <Table
            tableData={props.tableIncomeData}
            identifier={IDENTIFIER}
            columnsDefinition={incomeTransactionColumns}
            contextMenu={contextMenu}
            selection={selection}
        />
    );
};
