/* author: JESCHKE Moritz */

import { Box, Card } from "@mui/material";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, Table } from "components/Table";

export const TedRepExchange = () => {
    const IDENTIFIER: TableidentifierType = "tedRepExchange";

    const tableData: [] = [];

    const tedRepExchangeColumns: ITableColumn[] = [
        { field: "TB_ACQDT", title: "W01 - Steuerl. Anschaffungstag" },
        { field: "TB_ACQTM", title: "W01 - Steuerl. Anschaffungsuhrzeit" },
        { field: "TB_HISTHOLDINGIND", title: "W02 - Übertrag aus Altbestand keine Finanzinnovation" },
        {
            field: "TB_ACQNOM",
            title: "W03 - Anschaffungsmenge",
            type: "numeric",
        },
        { field: "TB_ACQAMTEURSIGN", title: "W05 - Kennzeichen Anschaffungswert verfügbar" },
        {
            field: "TB_ACQAMTEUR",
            title: "W04 - Steuerl. (bereinigter) Anschaffungswert in EUR",
            type: "numeric",
        },
        { field: "TB_ACQAMTCCY", title: "W07 - Fremdwährung" },
        {
            field: "TB_ACQAMT",
            title: "W06 - Steuerl. (bereinigter) Anschaffungswert in Fremdwährung",
            type: "numeric",
        },
        {
            field: "TB_ACRINTEUR",
            title: "W08 - Stückzinsen in EUR",
            type: "numeric",
        },
        {
            field: "TB_INTPROFEUR",
            title: "W09 - Zwischengewinn ja Anteil in EUR (ID904)",
            type: "numeric",
        },
        {
            field: "TB_ACCUPROFS94EUR",
            title: "W10 - Akkumulierte thesaurierte Erträge je Anteil in EUR (ID917)",
            type: "numeric",
        },
        {
            field: "TB_ACCUPROFRETAIN",
            title: "W11 - Schätzwert akkumulierte thesaurierte Erträge ja Anteil in EUR (ID917)",
            type: "numeric",
        },
        {
            field: "TB_ACCUSURPLUSEUR",
            title: "W12 - Akkumulierter Mehrbetrag ja Anteil (ID909)",
            type: "numeric",
        },
        {
            field: "TB_REALPROFEUR",
            title: "W13 - Immobiliengewinn je Anteil (ID908)",
            type: "numeric",
        },
        {
            field: "TB_ACCUPROFFUNDCCY",
            title: "W15 - Fondswährung der akkumulierten ausgeschütteten Altveräußerungsgewinne",
        },
        {
            field: "TB_ACCUPROFFUND",
            title: "W14 - Akkumulierte ausgeschüttete Altveräußerungsgewinne je Anteil in Fondswährung (ID919)",
            type: "numeric",
        },
        {
            field: "TB_ACCUPROFS09EUR",
            title: "W16 - Bereinigte akkumulierte ausschüsttungsgleiche Erträge je Anteil ab 01.01.2009 in EUR (ID921)",
            type: "numeric",
        },
        {
            field: "TB_ACCUSUBSPROFEUR",
            title: "W17 - Akkumulierte Substanzausschüttung je Anteil in EUR (ID920)",
            type: "numeric",
        },
        {
            field: "TB_ZASTAMTEUR",
            title: "W18 - Noch zu verzastender Betrag (im Fall zurückliegender steuerneutraler Fondsfusion) in EUR",
            type: "numeric",
        },
        {
            field: "TB_NTRL_MERGER_FUNDS_DATE",
            title: "W19 - Datum der letzten sterneutralen Fondsfusion",
            type: "date",
        },
        {
            field: "TB_SCRP_DATE",
            title: "W20 - Datum der ermittelten Ivestmentfondswerte",
            type: "date",
        },
        {
            field: "TB_PURCHASE_STATE_DATE",
            title: "W21 - Anschaffungstag ausfiktiver Veräußerung",
            type: "date",
        },
        {
            field: "TB_ACCU_STAT_PROC",
            title: "W22 - Akkumulierter thesaurierter Ertrag aus fiktiver Veräußerungam 31.12.2017",
            type: "numeric",
        },
        {
            field: "TB_UNREALISED_STAT_PROF",
            title: "W23 - Zwischengewinn aus fiktiver Veräußerung am 31.12.2017",
            type: "numeric",
        },
        {
            field: "TB_STAT_RETURN",
            title: "W24 - Veräußerungsergebnis aus fiktiver Veräußerung am 31.12.2017 aus sonst. Gewinnen/Verlusten",
            type: "numeric",
        },
        {
            field: "TB_SHARE_PROF_STAT_RETURN",
            title: "W25 - Veräußerungsergebnis aus fiktiver Veräußerung am 31.12.2017 aus Aktiengewinnen/-verlusten",
            type: "numeric",
        },
        {
            field: "TB_STAT_ASSESS_REPL",
            title: "W26 - Ersatzbemessungsgrundlage aus fiktiver Veräußerung am 31.12.2017 aus sonst. Gewinnen/Verlusten",
            type: "numeric",
        },
        {
            field: "TB_SHARE_PROF_STAT_ASS_REP",
            title: "W27 - Ersatzbessungsgrundlage aus fiktiver Veräußerung am 31.12.2017 aus Aktiengewinnen/-verlusten",
            type: "numeric",
        },
        {
            field: "TB_STAT_ACCU_RETURN_PROF",
            title: "W28 - Akkumuliertes Veräußerungsergebnis aus fiktiven Veräußerungen n.d. 31.12.2017 (Gewinn)",
            type: "numeric",
        },
        {
            field: "TB_STAT_ACCU_RETURN_PROF",
            title: "W29 - Akkumuliertes Veräußerungsergebnis aus fiktiven Veräußerungen n.d. 31.12.2017 (Verlust)",
            type: "numeric",
        },
        {
            field: "TB_STAT_ACCU_ASSESS_REPL",
            title: "W30 - Akkumuliertes Ersatzbemessungsgrundlage aus fikiven Veräußerungen n.d. 31.12.2017",
            type: "numeric",
        },
        {
            field: "TB_ASSESS_REPL_INDET",
            title: "W31 - Ersatzbemessungsgrundlage nicht ermittelbar bei fiktiver Veräußerung am 31.12.2017",
        },
        {
            field: "TB_ALLOWANCE_EX_ANTE",
            title: "W32 - Vorabpauschale (VAP)",
            type: "numeric",
        },
        { field: "TB_ORIGINAL_ISIN", title: "W33 - Ursprüngliche ISIN aus steuerneutraler Fondsfusion" },
        {
            field: "TB_W34",
            title: "W34 - Akk. Veräußerungsergebnis aus fikt. Veräußerung n. d. 31.12.2017 - Mischfonds vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W35",
            title: "W35 - Akk. Veräußerungsergebnis aus fikt. Veräußerung n. d. 31.12.2017 - Aktienfonds vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W36",
            title: "W36 - Akk. Veräußerungsergebnis aus fikt. Veräußerung n. d. 31.12.2017 - Immofonds (überw. inl. Immobilien) vor TFS ",
            type: "numeric",
        },
        {
            field: "TB_W37",
            title: "W37 - Akk. Veräußerungsergebnis aus fikt. Veräußerung n. d. 31.12.2017 - Immofonds (überw. ausl. Immobilien) vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W38",
            title: "W38 - Akk. Veräußerungsergebnis aus fikt. Veräußerung n. d. 31.12.2017 - sonstige Fonds (keine TFS)",
            type: "numeric",
        },
        {
            field: "TB_W39",
            title: "W39 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Mischfonds vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W40",
            title: "W40 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Aktienfonds vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W41",
            title: "W41 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Immofonds (überw. inl. Immobilien) vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W42",
            title: "W42 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - Immofonds (überw. ausl. Immobilien) vor TFS",
            type: "numeric",
        },
        {
            field: "TB_W43",
            title: "W43 - Akk. Ersatzbemessungsgrundlage aus fikt. Veräußerungen n. d. 31.12.17 - sonstige Fonds (keine TFS)",
            type: "numeric",
        },
        { field: "TB_W44", title: "W44 - Millionärsfonds" },
        {
            field: "TB_W45",
            title: "W45 - Ersatzbemessungsgrundlage nicht ermittelbar bei fikt. Veräußerungs n. d. 31.12.2017",
        },
        {
            field: "TB_W46",
            title: "W46 - Anschaffungskosten vor 2018",
            type: "numeric",
        },
    ];

    return (
        <Box sx={{ height: "100%", margin: "1em" }}>
            <Card sx={{ height: "50%" }}>
                <Table columnsDefinition={tedRepExchangeColumns} identifier={IDENTIFIER} tableData={tableData} />
            </Card>
        </Box>
    );
};
