/* author: JESCHKE Moritz  */

import { Box, CircularProgress, Switch, Typography } from "@mui/material";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { Footer, IFooter } from "./Footer";
import { NavigateFunction, useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import { setSecurityData } from "application/redux/slices/FinancialInstrumentSlice";
import { useAppDispatch } from "application/redux/Hooks";
import { AppDispatch, RootState } from "application/redux/Store";
import {
    ITableColumn,
    ITableContextMenu,
    ITableContextMenuElement,
    Table,
    getContextMenu,
    ITableAddRow,
} from "./Table";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { format, isValid, parse } from "date-fns";
import {
    IDatePicker,
    DatePickerType,
    TBDatePickerType,
    SingleDatePickerType,
    DefaultDateValueType,
    Orientation,
} from "./TBDatePicker";
import { useEventListener } from "customHooks/useEventListener";
import { clearDatePickerErrorMsg } from "application/redux/slices/DatePickerErrorSlice";
import { useSelector } from "react-redux";
import { IDatakeys, generateCSV } from "./CsvGenerator";
import { IKeyTableGetter } from "./KeyTableMapper";
import { SearchInputFields } from "./SearchInputFields";
import { getField } from "./EditComponents/FormEditView";
import { Details } from "./Details";
import { TECHNICAL_EDIT_COLUMNS } from "./EditComponents/EditMask";

interface ISearch {
    title: string;
    searchCriterias: ISearchCriteria[];
    columns: ITableColumn[];
    groupFields?: string[];
    rowColorScheme?: (rowData: Record<string, string>) => string;
    columnLogic?: {
        alternativColumns: ITableColumn[][];
        columnDecider: (currentSearchCriteria: Record<string, string>) => number;
    };
    searchUrls: ISearchUrl[];
    identifier: TableidentifierType;
    linkto?: string;
    linktoIdentifier?: string;
    extraData?: string[];
    sendRequestBeforePageSwitch?: (currentRowData: Record<string, string>) => {
        path: string;
        params: Record<string, unknown>;
    };

    alternativSwitch?: {
        checkParam: string;
        alternativeLinkTo: string;
    };
    setCurrentValues?: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    origin?: string;
    contextMenu?: ITableContextMenu | ITableContextMenuElement;
    customSearchButtonDisable?: (currentSearchCriteria: Record<string, string>) => boolean;
    details?: {
        setIdForDetail: Dispatch<SetStateAction<number>>;
        detailIdentifier: string;
    };
    detailsIdentifier?: string;
    checkPairFields?: string[][];
    isExportable?: boolean;
    CSV?: ICSV;
    addFunction?: ITableAddRow;
    setResultList?: React.Dispatch<React.SetStateAction<Record<string, string>[]>>;
    hideTechnicalColumns?: boolean;
}

type freeInputRestrigtionType = "NUMBERS" | "WILDCARDS";
export interface ISearchCriteria {
    label: string;
    field: string;
    disabled?: boolean;
    hidden?: boolean;
    type?: "dropDown" | "autocomplete" | DatePickerType;
    //options?: Record<string, string>[];
    defaultValue?: string;
    optionsRequest?: (currentValue: string) => GET_REQUEST_TYPE;
    keytable?: IKeyTableGetter;
    autocompleteMinLetters?: number;
    linkComboboxField?: string;
    comboBoxLowestFieldName?: string;
    comboBoxHighestFieldName?: string;
    freeInputRestrigtion?: freeInputRestrigtionType[];
    fieldLengthLimit?: number;
}

export interface ISearchUrl {
    path: string;
    requiredParams?: string[];

    // used to store all the params which needs to be ignored from Search(First-Level Search)
    ignoreParams?: string[];
    additionalParams?: Record<string, string>;
}
export interface ICSV {
    fileName: string;
    columnsDefinitions?: IDatakeys[];
    prepareCSVData: (
        currentRowData: Record<string, string>,
        searchCriteriaText: Record<string, string>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any
    ) => string;
    sendRequestForCSVData?: (
        currentRowData: Record<string, string>,
        searchCriteria: Record<string, string>
    ) => {
        path: string;
        params: Record<string, string>;
    }[];
}

export const Search = (props: ISearch): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();

    const datePickerErrorMessages: Record<string, string> = useSelector((state: RootState) => state.datePicker.errMsg);

    const navigate: NavigateFunction = useNavigate();
    const SESSION_STORAGE_SEARCH_CRITERIA: string = props.title + "SearchCriteria";

    // state to keep track of all inputed values for the search criteria
    const [searchCriteriaText, setSearchCriteriaText] = useState<Record<string, string>>({});
    // state to keep track of all inputed Date values for the search criteria
    const [searchCriteriaDate, setSearchCriteriaDate] = useState<Record<string, Date | null>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [resultList, setResultList] = useState<Record<string, string>[]>([]);
    const [isSearched, setIsSearched] = useState<boolean>(false);
    const [tableHeight, setTableHeight] = useState<number>();

    const [detailId, setDetailId] = useState<number>();

    //save current column to prevent switch bug when there are multiple column groups
    const [columns, setColumns] = useState<ITableColumn[]>(props.columns);
    //To delay the actual page Render as in to initiate basic useEffect hooks
    const [render, setRender] = useState<boolean>(false);
    const [searchCriteriaToggle, setSearchCriteriaToggle] = useState(false);
    //validate the Date from DatePicker & then updates the SearchCriteriaState
    console.log("searchCriteriaText", searchCriteriaText);

    const updateSearchCriteriaDateState = (newState: Record<string, Date | null>) => {
        setSearchCriteriaDate(newState);
        const searchCriteriaTextState: Record<string, string> = { ...searchCriteriaText };
        Object.entries(newState).forEach((entry: [string, Date | null]) => {
            if (entry[1] !== null && !isNaN(entry[1].valueOf())) {
                searchCriteriaTextState[entry[0]] = format(Date.parse(entry[1].toISOString()), "yyyy-MM-dd'T'HH:mm:ss");
            } else {
                searchCriteriaTextState[entry[0]] = "";
            }
            updateSearchCriteriaState(searchCriteriaTextState);
        });
    };

    const updateSearchCriteriaState = (newState: Record<string, string>) => {
        setSearchCriteriaText(newState);
        if (props.setCurrentValues) {
            props.setCurrentValues(newState);
        }
    };

    const updateResultListState = (newState: Record<string, string>[]) => {
        setResultList(newState);
        if (props.setResultList) {
            props.setResultList(newState);
        }
    };

    const manageSearchCriteriaParamsWithSessionStorage = () => {
        const restoreSearchCriteriaString: string | null = sessionStorage.getItem(SESSION_STORAGE_SEARCH_CRITERIA);
        let restoreSearchCriteria: Record<string, string> =
            restoreSearchCriteriaString !== null ? JSON.parse(restoreSearchCriteriaString) : {};
        if (Object.entries(restoreSearchCriteria).length > 0) {
            if (Object.entries(searchCriteriaText).length > 0) {
                sessionStorage.setItem(SESSION_STORAGE_SEARCH_CRITERIA, JSON.stringify(searchCriteriaText));
            } else {
                //if SearchCriteriaText is empty then update SearchCriteriaText & SearchCriteriaDate with SessionStorage values
                //update the state of params into SearchCriteriaText
                setSearchCriteriaText(restoreSearchCriteria);
                //update the state of dates into SearchCriteriaDate
                Object.entries(restoreSearchCriteria).forEach((entry: [string, string]) => {
                    if (isValid(parse(entry[1], "yyyy-MM-dd'T'HH:mm:ss", new Date())))
                        searchCriteriaDate[entry[0]] = entry[1] !== null ? new Date(entry[1]) : null;
                });
            }
        } else {
            //when default value gets initialized with empty SessionStorage(also in case of page reset)
            restoreSearchCriteria = searchCriteriaText;
            sessionStorage.setItem(SESSION_STORAGE_SEARCH_CRITERIA, JSON.stringify(restoreSearchCriteria));
        }
        setRender(true);
    };

    useEffect(() => {
        manageSearchCriteriaParamsWithSessionStorage();
    }, [searchCriteriaText]);

    const restore = async () => {
        const restoreSearchCriteriaString: string | null = sessionStorage.getItem(SESSION_STORAGE_SEARCH_CRITERIA);
        const restoreSearchCriteria: Record<string, string> =
            restoreSearchCriteriaString !== null ? JSON.parse(restoreSearchCriteriaString) : {};

        if (restoreSearchCriteria !== null && validateRestoredSearchCriteria(restoreSearchCriteria)) {
            handleSearch(restoreSearchCriteria)();
        } else {
            setIsSearched(false);
            updateResultListState([]);
        }
        updateSearchCriteriaState(restoreSearchCriteria);
    };

    const changeColumns = (restoredSearchParams?: Record<string, string>) => {
        if (props.columnLogic) {
            const columnNumber: number = props.columnLogic.columnDecider(restoredSearchParams ?? searchCriteriaText);

            if (columnNumber === 0) {
                setColumns(props.columns);
            } else {
                setColumns(props.columnLogic.alternativColumns[columnNumber - 1]);
            }
        } else {
            setColumns(props.columns);
        }
    };

    //!! this function return a function! so if you want to call the handleSearchMethod you have to call it twice (call the returned function): handleSearch()();
    const handleSearch = (restoredSearchParams?: Record<string, string>) => async () => {
        setIsLoading(true);
        setResultList([]);

        setDetailId(undefined);
        updateResultListState([]);

        //set columns
        changeColumns(restoredSearchParams);

        //generate params for search request
        const params: Record<string, string | undefined> = {};
        props.searchCriterias.forEach((searchCriteria: ISearchCriteria) => {
            if (restoredSearchParams) {
                //generates param(s) if type of SearchCriteria is DatePickerType Only!
                if (
                    searchCriteria.type &&
                    (Object.prototype.hasOwnProperty.call(searchCriteria.type, "singleDatePicker") ||
                        Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker"))
                ) {
                    generateParamsForDatePicker(searchCriteria, params, restoredSearchParams);
                } else {
                    //generates param for any SearchCriteria except DatePickerType!
                    if (isParamNotInIgnoreList(searchCriteria.field))
                        params[searchCriteria.field] = restoredSearchParams[searchCriteria.field]
                            ? restoredSearchParams[searchCriteria.field]
                            : undefined;
                }
            } else {
                //generates param(s) if type of SearchCriteria is DatePickerType Only!
                if (
                    searchCriteria.type &&
                    (Object.prototype.hasOwnProperty.call(searchCriteria.type, "singleDatePicker") ||
                        Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker"))
                ) {
                    generateParamsForDatePicker(searchCriteria, params, searchCriteriaText);
                } else {
                    //generates param for any SearchCriteria except DatePickerType!
                    if (isParamNotInIgnoreList(searchCriteria.field))
                        params[searchCriteria.field] = searchCriteriaText[searchCriteria.field]
                            ? searchCriteriaText[searchCriteria.field]
                            : undefined;
                }
            }
        });

        //check if url has requiredParams
        const validReq: ISearchUrl[] = [];
        props.searchUrls.forEach((request: ISearchUrl) => {
            if (request.requiredParams) {
                //get all required params
                const parameters: string[] = request.requiredParams.map((param: string) => {
                    return restoredSearchParams ? restoredSearchParams[param] : searchCriteriaText[param];
                });
                //check if atleast one required parms is filled -> send request
                if (parameters.join("") !== "") {
                    validReq.push(request);
                }
            } else {
                //no required params -> send request everytime
                validReq.push(request);
            }
        });

        const validUrl: ISearchUrl[] = [...validReq];
        //iterate over valid request based on logic before (only request with filled out parameters)
        validReq.forEach((request: ISearchUrl, index: number) => {
            //check each required Param
            request.requiredParams?.forEach((param: string) => {
                //check if required param is used/filled
                if (
                    (searchCriteriaText[param] !== undefined && searchCriteriaText[param] !== "") ||
                    (restoredSearchParams &&
                        restoredSearchParams[param] !== undefined &&
                        restoredSearchParams[param] !== "")
                ) {
                    //iterate over valid request again to remove non valid once (based on current required params)
                    validReq.forEach((request2: ISearchUrl, index2: number) => {
                        //only check other valid requests
                        //check if current required params are in current checking list
                        if (index !== index2 && !request2.requiredParams?.includes(param)) {
                            //get index for remove
                            const indexOfReq2: number = validUrl.indexOf(request2);
                            //remove request
                            if (indexOfReq2 !== -1) {
                                validUrl.splice(indexOfReq2, 1);
                            }
                        }
                    });
                }
            });
        });

        //send all requests from the validUrl array
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const responses: AxiosResponse<any, any>[] = await Promise.all(
            validUrl.map((url: ISearchUrl) => {
                //if there are additional Params -> add them
                if (url.additionalParams) {
                    Object.keys(url.additionalParams).forEach((key: string) => {
                        params[key] = url.additionalParams ? url.additionalParams[key] : "";
                    });
                }

                //add default values if not existing
                props.searchCriterias.forEach((searchCriteria: ISearchCriteria) => {
                    if (
                        !params[searchCriteria.field] &&
                        searchCriteria.type === "dropDown" &&
                        searchCriteria.defaultValue &&
                        searchCriteria.defaultValue !== "*"
                    ) {
                        params[searchCriteria.field] = searchCriteria.defaultValue;
                    }
                });

                //remove undefined or empty strings from params
                Object.keys(params).forEach((key: string) => {
                    if (!params[key] || params[key] === "") delete params[key];
                });

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return sendGetRequest({ path: url.path as any, params: params });
            })
        );

        //merge reponses into one array
        let data: Record<string, string>[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responses.forEach((response: AxiosResponse<any, any>) => {
            data = data.concat(response.data);
        });

        setIsLoading(false);
        updateResultListState(data);
        setIsSearched(true);
        calculateTableHeight();
    };

    const generateParamsForDatePicker = (
        searchCriteria: ISearchCriteria,
        params: Record<string, string | undefined>,
        currentSearchCriteriaText: Record<string, string>
    ) => {
        // generate param if DatePicker is of type SingleDatePicker.
        if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "singleDatePicker")) {
            const datePicker: SingleDatePickerType = searchCriteria.type as DatePickerType as SingleDatePickerType;
            if (isParamNotInIgnoreList(datePicker.singleDatePicker.field))
                params[datePicker.singleDatePicker.field] =
                    currentSearchCriteriaText[datePicker.singleDatePicker.field] ?? undefined;
        } else if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker")) {
            // generate param if DatePicker is of type TBFromToDatePicker.
            const datePicker1: TBDatePickerType = searchCriteria.type as DatePickerType as TBDatePickerType;
            const datePickerFromField: string = datePicker1.TBFromToDatePicker.from.field;
            if (isParamNotInIgnoreList(datePickerFromField))
                params[datePickerFromField] = currentSearchCriteriaText[datePickerFromField] ?? undefined;
            const datePicker2: TBDatePickerType = searchCriteria.type as DatePickerType as TBDatePickerType;
            const datePickerToField: string = datePicker2.TBFromToDatePicker.to.field;
            if (isParamNotInIgnoreList(datePickerToField))
                params[datePickerToField] = currentSearchCriteriaText[datePickerToField] ?? undefined;
        }
    };
    //checks if the param needs to be ignored from the Search.
    const isParamNotInIgnoreList = (param: string): boolean => {
        let status: boolean = false;
        props.searchUrls.forEach((item: ISearchUrl) => {
            status = item.ignoreParams !== undefined ? item.ignoreParams.includes(param) : false;
        });
        return !status;
    };
    //Validates RestoredSearchCriteria
    const validateRestoredSearchCriteria = (restoredSearchCriteria: Record<string, string>): boolean => {
        return validateSearchCriteria(restoredSearchCriteria);
    };
    //Validates SearchCriteriaText
    const validateSearchCriteriaText = (): boolean => {
        return validateSearchCriteria(searchCriteriaText);
    };

    //Keeps track of the ignore Param(s) along with values in searchCriteriaText.
    const validateSearchCriteria = (searchCriteria: Record<string, string>): boolean => {
        let isSearchCriteriaValid: boolean = false;
        Object.entries(searchCriteria).forEach((entry: [string, string]) => {
            props.searchUrls.forEach((item: ISearchUrl) => {
                if (
                    // If ignoreParams are empty then check for the value of the item of searchCriteriaText.
                    (!item.ignoreParams && entry[1] && entry[1].length > 0) ||
                    // If ignoreParams aren't empty then ignore the item if in the ignoreParams otherwise check for the value of the item of searchCriteriaText.
                    (!item.ignoreParams?.includes(entry[0]) && entry[1] && entry[1].length > 0)
                ) {
                    isSearchCriteriaValid = true;
                    return isSearchCriteriaValid;
                }
            });
        });
        return isSearchCriteriaValid;
    };

    //check if from and to date value is provided. If only one of them is provided -> disable search button
    const areAllFromAndToDatePairsProvided = (): boolean => {
        let allFromAndToDatePairsProvided: boolean = true;

        props.searchCriterias.forEach((searchCriteria: ISearchCriteria) => {
            //check if search criteria is datepicker
            //check if date picker is from to date picker
            if (
                searchCriteria.field === "" &&
                searchCriteria.type &&
                Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker")
            ) {
                const from: string = (searchCriteria.type as TBDatePickerType).TBFromToDatePicker.from.field;
                const to: string = (searchCriteria.type as TBDatePickerType).TBFromToDatePicker.to.field;

                //debugger;
                //check if only one is filled
                if (
                    (searchCriteriaText[from] !== undefined && searchCriteriaText[from] !== "") !==
                    (searchCriteriaText[to] !== undefined && searchCriteriaText[to] !== "")
                ) {
                    allFromAndToDatePairsProvided = false;
                }
            }
        });

        return allFromAndToDatePairsProvided;
    };

    const checkFieldPairsProvided = (): boolean => {
        let isAllFieldPairsProvided: boolean = true;
        if (props.checkPairFields) {
            props.checkPairFields.forEach((fieldGroups: string[]) => {
                let isAllUndefined = true;
                fieldGroups.forEach((pairFields: string) => {
                    if (searchCriteriaText[pairFields] === undefined) {
                        isAllFieldPairsProvided = false;
                    } else {
                        isAllUndefined = false;
                    }
                });
                if (isAllUndefined) {
                    isAllFieldPairsProvided = true;
                }
            });
        }

        return isAllFieldPairsProvided;
    };

    const isDatePickerErrorOnCurrentPage = (): boolean => {
        let isErrorOnCurrentPage = false;

        for (const key of Object.keys(datePickerErrorMessages)) {
            if (key.includes(props.title)) {
                isErrorOnCurrentPage = true;
                break;
            }
        }

        return isErrorOnCurrentPage;
    };

    const disableSearchButtonBasedOnGroupFields = (): boolean => {
        if (props.groupFields) {
            const allFieldsHaveValue = props.groupFields.every((field) => {
                return (
                    Object.prototype.hasOwnProperty.call(searchCriteriaText, field) && searchCriteriaText[field] !== ""
                );
            });
            return !allFieldsHaveValue;
        }
        return false;
    };

    // enables & disables the Search Button
    const disableSearchButton = (): boolean => {
        if (disableSearchButtonBasedOnGroupFields()) {
            return true;
        }
        if (isDatePickerErrorOnCurrentPage()) {
            return true;
        }
        if (disableSearchButtonBasedOnGroupFields()) {
            return true;
        }

        if (props.customSearchButtonDisable && props.customSearchButtonDisable(searchCriteriaText)) {
            return false;
        }

        return !(areAllFromAndToDatePairsProvided() && validateSearchCriteriaText() && checkFieldPairsProvided());
    };

    const footer: IFooter = {
        buttons: [
            { title: "Abbrechen", onClick: () => navigate("/home"), filled: false },
            {
                title: "Zurücksetzen",
                onClick: () => {
                    if (props.details?.setIdForDetail) {
                        props.details.setIdForDetail(-1);
                    }
                    setIsSearched(false);
                    sessionStorage.removeItem(SESSION_STORAGE_SEARCH_CRITERIA);
                    updateSearchCriteriaDateState({});
                    updateSearchCriteriaState({});
                    updateResultListState([]);
                    setDetailId(undefined);
                },
                filled: false,
            },
            {
                title: "Suchen",
                onClick: handleSearch(),
                filled: true,
                disabled: disableSearchButton(),
            },
        ],
    };

    const calculateTableHeight = () => {
        const appBarElement: HTMLElement = document.getElementById("appBar") as HTMLElement;
        let appBarHeight: number = 0;
        if (appBarElement) {
            appBarHeight = appBarElement.getBoundingClientRect().height;
        }

        const tableToolBarElement: HTMLElement = document.getElementsByClassName("MuiToolbar-root")[0] as HTMLElement;
        let tableToolBarHeight: number = 0;
        if (tableToolBarElement) {
            tableToolBarHeight = tableToolBarElement.getBoundingClientRect().height;
        }

        const tableFooterElement: HTMLElement = document.getElementsByClassName(
            "MuiTableFooter-root"
        )[0] as HTMLElement;
        let tableFooterHeight: number = 0;
        if (tableFooterElement) {
            tableFooterHeight = tableFooterElement.getBoundingClientRect().height;
        }

        const tableWrapperElement: HTMLElement = document.getElementById("tableWrapper") as HTMLElement;
        let marginHeight: number = 0;
        if (tableWrapperElement) {
            //multiplied by 2 for top and bottom margin
            marginHeight = Number(window.getComputedStyle(tableWrapperElement).margin.replaceAll("px", ""));
        }

        const errorDetailListElement: HTMLElement = document.getElementById("errorDetailList") as HTMLElement;
        let errorDetailListHeight: number = 0;
        if (errorDetailListElement) {
            errorDetailListHeight = errorDetailListElement.getBoundingClientRect().height;
        }

        //get element which displays the number of reuslts
        const resultSizeElement: HTMLElement = document.getElementById("resultSize") as HTMLElement;
        let heightTop: number = 0;
        if (resultSizeElement) {
            //resultSize margin bottom without unit (px) (cut off last two characters)
            const resultsizeMarginBottom: number = Number(
                // eslint-disable-next-line no-magic-numbers
                window.getComputedStyle(resultSizeElement).marginBottom.slice(0, -2)
            );
            //y location of resultSize element + height + marginBottom (have to cut off px and convert string to number)
            heightTop =
                resultSizeElement.getBoundingClientRect().y +
                resultSizeElement.getBoundingClientRect().height +
                resultsizeMarginBottom;
        }

        //TODO :: find space
        const UNSELECTABLE_SPACING: number = 34;

        //max window height before scrollbar
        const height: number = window.innerHeight * (window.innerHeight / document.body.offsetHeight);

        const newHeight: number =
            height -
            heightTop -
            appBarHeight -
            marginHeight -
            tableToolBarHeight -
            tableFooterHeight -
            UNSELECTABLE_SPACING -
            errorDetailListHeight;

        setTableHeight(newHeight);
    };

    const handleRowClick = async (_event: Record<string, unknown>, rowData: Record<string, string>) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let response: AxiosResponse<any, any> | undefined;
        if (props.linkto) {
            //preload data for next page
            if (props.sendRequestBeforePageSwitch) {
                const request = props.sendRequestBeforePageSwitch(rowData);
                if (request.path === "undefined") {
                    alert(request.params["msg"]);
                    return;
                }

                response = await sendGetRequest({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    path: request.path as any,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    params: request.params as any,
                });
                if (JSON.stringify(response.data) === JSON.stringify({}) || response.data.length === 0) {
                    alert("Keine Ergebnisse für diese Auswahl gefunden");
                    return;
                }
            }

            //generate extra data
            const extraData: Record<string, string> = {};
            if (props.extraData) {
                props.extraData.forEach((key: string) => {
                    extraData[key] = searchCriteriaText[key] ?? getField(rowData, key);
                });
            }

            //switch page
            const lastChar: string = props.linkto.slice(-1);

            if (props.alternativSwitch && Object.values(rowData).includes(props.alternativSwitch.checkParam)) {
                navigate(
                    props.alternativSwitch.alternativeLinkTo +
                        (lastChar === "/" ? rowData[props.linktoIdentifier ?? "id"] : "")
                );
            } else {
                navigate(props.linkto + (lastChar === "/" ? rowData[props.linktoIdentifier ?? "id"] : ""), {
                    state: { data: response ? response.data : undefined, extraData: extraData, origin: props.origin },
                });
            }

            //TODO :: FIX
            if (props.linkto === "/SecurityOverview/") {
                dispatch(
                    setSecurityData({
                        id: rowData.id,
                        wkn: rowData.wkn,
                        gd198B: rowData.gd198B,
                        categoryFlag: rowData.categoryFlag,
                        isin: rowData.isin,
                        shortName: rowData.shortName,
                        longName: rowData.longName,
                    })
                );
            }
        }

        //Get response of the request and genrate CSV Data
        if (props.CSV) {
            const requests: { path: string; params: Record<string, string> }[] | undefined = props.CSV
                .sendRequestForCSVData
                ? props.CSV.sendRequestForCSVData(rowData, searchCriteriaText)
                : undefined;
            if (requests && requests.length > 0) {
                const responses = await Promise.all(
                    requests.map(async (request: { path: string; params: Record<string, string> }) => {
                        if (request.path === "undefined") {
                            alert(request.params["msg"]);
                            return null;
                        }

                        const responseCsvRequest = await sendGetRequest({
                            path: request.path as never,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            params: request.params as any,
                        });

                        if (
                            JSON.stringify(responseCsvRequest.data) === JSON.stringify({}) ||
                            responseCsvRequest.data.length === 0
                        ) {
                            alert("Keine Ergebnisse für diese Auswahl gefunden");
                            return null;
                        }

                        return responseCsvRequest;
                    })
                );
                const csv: string = props.CSV.prepareCSVData(rowData, searchCriteriaText, responses);

                generateCSV(props.CSV.fileName, csv);
            }
        }

        if (props.details) {
            props.details.setIdForDetail(Number(rowData[props.details.detailIdentifier]));
        }

        if (props.detailsIdentifier) {
            setDetailId(Number(rowData[props.detailsIdentifier]));
        }
    };

    useEffect(() => {
        window.addEventListener("resize", calculateTableHeight);
        calculateTableHeight();
        return () => {
            window.removeEventListener("resize", calculateTableHeight);
        };
    }, [calculateTableHeight]);

    useEffect(() => {
        changeColumns();
    }, [props.columns]);

    // generate the DatePickers based on the type of SearchCriteria
    const getDatePickers = (searchCriteria: ISearchCriteria): IDatePicker[] => {
        const datePickerArray: IDatePicker[] = [];
        // Identification & configuration of the DatePicker as of type SingleDatePicker.
        if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "singleDatePicker")) {
            const datePicker: SingleDatePickerType = searchCriteria.type as DatePickerType as SingleDatePickerType;
            //Configuration of SingleDatePicker
            const d: IDatePicker = {
                label: datePicker.singleDatePicker.label,
                field: datePicker.singleDatePicker.field,
                discriminator: datePicker.singleDatePicker.discriminator,
                disabled: datePicker.singleDatePicker.disabled,
                value:
                    searchCriteriaDate[datePicker.singleDatePicker.field] === undefined &&
                    datePicker.singleDatePicker.defaultValue === "today"
                        ? initializeDatePickerValue(
                              datePicker.singleDatePicker.field,
                              datePicker.singleDatePicker.defaultValue
                          )
                        : searchCriteriaDate[datePicker.singleDatePicker.field] ??
                          initializeDatePickerValue(datePicker.singleDatePicker.field, undefined),
                setValue(date) {
                    const newState: Record<string, Date | null> = { ...searchCriteriaDate };
                    newState[datePicker.singleDatePicker.field] = date;
                    updateSearchCriteriaDateState(newState);
                },
                prefixParentLabel: false,
                dateRestriction: undefined,
            };
            datePickerArray.push(d);
        } else if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker")) {
            // Identification & configuration of the DatePicker as of type TBFromToDatePicker.
            const datePicker: TBDatePickerType = searchCriteria.type as DatePickerType as TBDatePickerType;
            //Configuration of "FromDatePicker"
            const fromDatePicker: IDatePicker = {
                label: datePicker.TBFromToDatePicker.from.label,
                field: datePicker.TBFromToDatePicker.from.field,
                discriminator: datePicker.TBFromToDatePicker.from.discriminator,
                disabled: datePicker.TBFromToDatePicker.from.disabled,
                value:
                    searchCriteriaDate[datePicker.TBFromToDatePicker.from.field] === undefined
                        ? initializeDatePickerValue(
                              datePicker.TBFromToDatePicker.from.field,
                              datePicker.TBFromToDatePicker.from.defaultValue
                          )
                        : searchCriteriaDate[datePicker.TBFromToDatePicker.from.field] ??
                          initializeDatePickerValue(datePicker.TBFromToDatePicker.from.field, undefined),
                setValue(date) {
                    const newState: Record<string, Date | null> = { ...searchCriteriaDate };
                    newState[datePicker.TBFromToDatePicker.from.field] = date;
                    updateSearchCriteriaDateState(newState);
                },
                prefixParentLabel: datePicker.TBFromToDatePicker.prefixParentLabel,
                dateRestriction: datePicker.TBFromToDatePicker.dateRestriction,
            };
            //Configuration of "ToDatePicker"
            const toDatePicker: IDatePicker = {
                label: datePicker.TBFromToDatePicker.to.label,
                field: datePicker.TBFromToDatePicker.to.field,
                discriminator: datePicker.TBFromToDatePicker.to.discriminator,
                disabled: datePicker.TBFromToDatePicker.to.disabled,
                value:
                    searchCriteriaDate[datePicker.TBFromToDatePicker.to.field] === undefined &&
                    datePicker.TBFromToDatePicker.to.defaultValue === "today"
                        ? initializeDatePickerValue(
                              datePicker.TBFromToDatePicker.to.field,
                              datePicker.TBFromToDatePicker.to.defaultValue
                          )
                        : searchCriteriaDate[datePicker.TBFromToDatePicker.to.field] ??
                          initializeDatePickerValue(datePicker.TBFromToDatePicker.to.field, undefined),

                setValue(date) {
                    const newState: Record<string, Date | null> = { ...searchCriteriaDate };
                    newState[datePicker.TBFromToDatePicker.to.field] = date;
                    updateSearchCriteriaDateState(newState);
                },
                prefixParentLabel: datePicker.TBFromToDatePicker.prefixParentLabel,
                dateRestriction: datePicker.TBFromToDatePicker.dateRestriction,
            };
            datePickerArray.push(fromDatePicker);
            datePickerArray.push(toDatePicker);
        }
        // returning the datepicker(s) for TBFromToDatePicker Component.
        return datePickerArray;
    };

    const initializeDatePickerValue = (
        datePickerFieldName: string,
        defaultDateValueType: DefaultDateValueType | undefined
    ) => {
        if (defaultDateValueType === "today") {
            const today: Date = new Date();
            const newState: Record<string, Date | null> = { ...searchCriteriaDate };
            newState[datePickerFieldName] = today;
            updateSearchCriteriaDateState(newState);
            return today;
        }
        dispatch(clearDatePickerErrorMsg({ field: props.title + datePickerFieldName }));
        return null;
    };
    const clearInvalidValuesFromSearchCriteriaDate = () => {
        const searchCriteriaDateState: Record<string, Date | null> = { ...searchCriteriaDate };
        Object.keys(searchCriteriaDateState).forEach((key: string) => {
            const value = searchCriteriaDateState[key];
            if (value && isNaN(value.valueOf())) {
                searchCriteriaDateState[key] = null;
            }
        });
        setSearchCriteriaDate(searchCriteriaDateState);
    };
    const getDatePickerOrientation = (searchCriteria: ISearchCriteria): Orientation => {
        if (Object.prototype.hasOwnProperty.call(searchCriteria.type, "TBFromToDatePicker")) {
            const datePicker: TBDatePickerType = searchCriteria.type as DatePickerType as TBDatePickerType;
            return datePicker.TBFromToDatePicker.orientation ? datePicker.TBFromToDatePicker.orientation : "horizontal";
        }
        return "horizontal";
    };

    useEffect(() => {
        restore();
    }, [props.title]);

    //enable search with enter button
    useEventListener("keydown", (event: KeyboardEvent) => {
        if (event.key === "Enter" && !disableSearchButton()) {
            handleSearch()();
        }
    });

    const getResultList = (): React.JSX.Element | false => {
        if (resultList.length) {
            return (
                <div>
                    <Typography id="resultSize" sx={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                        Ergebnisse {resultList.length > 0 ? "(" + resultList.length + ")" : ""}
                    </Typography>

                    {/** Conditional Rednering + wraping of Datagrid in div to fix error that parent component has a heigth of 0 */}
                    {tableHeight !== 0 && (
                        <div>
                            <Table
                                columnsDefinition={
                                    props.hideTechnicalColumns ? columns : columns.concat(TECHNICAL_EDIT_COLUMNS)
                                }
                                tableData={resultList}
                                identifier={props.identifier}
                                maxHeight={tableHeight + "px"}
                                rowColorScheme={props.rowColorScheme}
                                isExportable={props.isExportable}
                                onRowClick={
                                    props.CSV ||
                                    props.sendRequestBeforePageSwitch ||
                                    props.linkto ||
                                    props.details ||
                                    props.detailsIdentifier
                                        ? handleRowClick
                                        : undefined
                                }
                                contextMenu={getContextMenu(props.contextMenu)}
                                showEditPendingStateIcons
                                addFunction={props.addFunction}
                            />

                            {props.contextMenu && (props.contextMenu as ITableContextMenuElement).element}
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                isSearched && (
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                        Ergebnisse (0)
                    </Typography>
                )
            );
        }
    };

    return render ? (
        <div style={{ margin: "4rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        marginBottom: "1rem",
                    }}
                >
                    {props.title}
                </Typography>
                <Typography sx={{ fontSize: "0.9rem" }}>
                    Suchkriterien ausblenden
                    <Switch
                        checked={searchCriteriaToggle}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchCriteriaToggle(event.target.checked);
                            clearInvalidValuesFromSearchCriteriaDate();
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                </Typography>
            </div>

            <SearchInputFields
                searchCriterias={props.searchCriterias}
                searchCriteriaToggle={searchCriteriaToggle}
                searchCriteriaText={searchCriteriaText}
                updateSearchCriteriaState={updateSearchCriteriaState}
                getDatePickers={getDatePickers}
                datePickerPageTitle={props.title}
                getDatePickerOrientation={getDatePickerOrientation}
            />

            {getResultList()}
            {detailId && <Details requestId={detailId} />}
            {isLoading && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
            <div id="footer">
                <Footer buttons={footer.buttons} />
            </div>
        </div>
    ) : (
        <div></div>
    );
};
