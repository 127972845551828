/* author: Aashish Upadhyay*/

import { KeyTableConsts } from "components/KeyTableConsts";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn, ITableContextMenu } from "components/Table";
import { getEditHistoryContextMenu, getEditState, getEditTo } from "components/VersionsView";
import { Dispatch, SetStateAction, useState } from "react";
import { getChangedValue } from "components/Converter";
import { ORDER_SECTION } from "./YerRequestEdit";
import { VALUE_SECTION } from "./YerValueEdit";
import { ORDER_DETAILS_SECTION } from "./YerReportEdit";

export const getYerRequestColumns = (isMass: boolean): ITableColumn[] => [
    {
        field: "fiscalUnitData.fiscalUnit",
        title: "Steuerliche Einheit",
        hidden: isMass,
    },
    {
        field: "accountData.branch",
        title: "Filalnummer",
        hidden: isMass,
    },
    {
        field: "accountData.accountNr",
        title: "Kontonummer",
        hidden: isMass,
    },
    {
        field: "accountData.accountType",
        title: "Kontoart",
        hidden: isMass,
    },
    {
        field: "",
        title: "Bescheinigungsarten",
        type: "list",
        listDefinition: {
            listAttribute: "value",
            pathToList: "reqValueData",
        },
    },
    { field: "requestReportData.reportType", title: "Bescheinigungstyp" },
    // {
    //     field: "",
    //     title: "Aussteuerungsgrund",
    //     type: "list",
    //     listDefinition: {
    //         listAttribute: "rejectionDataData.rejReason",
    //         pathToList: "rejectionRelationData",
    //     },
    //     hidden: isMass,
    // },
    { field: "fromDate", title: "Zeitraum von", type: "date" },
    { field: "toDate", title: "Zeitraum bis", type: "date" },
    { field: "execState", title: "Auftragstatus" },
    { field: "requestType", title: "Auftragsart" },
    { field: "extRequestId", title: "Externe Referenz" },
    {
        field: "clientData.clientId",
        title: "Mandant",
        hidden: isMass,
    },
    { field: "processingStartTime", title: "Verarbeitungsdatum Beginn", type: "datetime" },
    { field: "processingEndTime", title: "Verarbeitungsdatum Ende", type: "datetime" },
    { field: "startProcessingDate", title: "Starten ab", type: "date" },
    { field: "mode", title: "Durchführungsmodus" },
    { field: "source", title: "Quelle" },
];

export const getYerContextMenu = (
    currentRowData: Record<string, string | number>,
    setCurrentRowData: Dispatch<SetStateAction<Record<string, string | number>>>
): ITableContextMenu => {
    const contextMenu: ITableContextMenu = getEditHistoryContextMenu(
        currentRowData,
        setCurrentRowData,
        "",
        ORDER_SECTION
    );
    contextMenu.contextActions.push({
        label: "Bescheinigungsarten bearbeiten",
        link: {
            to: getEditTo(Number(currentRowData["id"]), VALUE_SECTION),
            state: getEditState(getChangedValue(currentRowData, "modifyAction") ?? "", currentRowData),
        },
    });
    contextMenu.contextActions.push({
        label: "Auftragsdetails bearbeiten",
        link: {
            to: getEditTo(Number(currentRowData["id"]), ORDER_DETAILS_SECTION),
            state: getEditState(getChangedValue(currentRowData, "modifyAction") ?? "", currentRowData),
        },
    });

    return contextMenu;
};

const YerRequestSearchMask = (type: "single" | "mass"): React.JSX.Element => {
    const isMass: boolean = type === "mass";
    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    const searchCriterias: ISearchCriteria[] = [
        {
            field: "fiscalUnit",
            label: "Steuerliche Einheit*",
            hidden: isMass,
        },
        {
            field: "accType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            hidden: isMass,
        },
        {
            field: "branch",
            label: "Filalnummer",
            hidden: isMass,
        },
        {
            field: "accountNr",
            label: "Kontonummer*",
            hidden: isMass,
        },
        {
            field: "execState",
            label: "Auftragstatus",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.REQ_STATE, allOption: true },
        },
        {
            field: "value",
            label: "Bescheinigungsarten",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.REQ_VALUE, allOption: true },
        },
        {
            field: "reportType",
            label: "Bescheinigungstyp",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.REQ_REPORT_TYPE, allOption: true },
        },
        {
            field: "clientId",
            label: "Mandant",
            type: "dropDown",
            keytable: {
                tabname: KeyTableConsts.CLIENT,
                allOption: true,
            },
            hidden: isMass,
        },
        { field: "fromDate", label: "Berichtsjahr", freeInputRestrigtion: ["NUMBERS"], fieldLengthLimit: 4 },
        {
            field: "",
            label: "Zeitraum des Erfassungszeitraumes",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "insertTsFrom", discriminator: "from" },
                    to: { label: "bis", field: "insertTsTo", discriminator: "to" },
                },
            },
        },
        // {
        //     field: "reason",
        //     label: "Aussteuerungsgrund",
        //     type: "dropDown",
        //     keytable: { tabname: KeyTableConsts.REASON, allOption: true },
        //     hidden: isMass,
        // },
    ];

    const searchUrls: ISearchUrl[] = [
        { path: type === "single" ? "/request/yer/getSingleRequest" : "/request/yer/getMassRequest" },
    ];

    return (
        <Search
            title={(type === "single" ? "Einzel" : "Massen") + "aufträge suchen"}
            searchCriterias={searchCriterias}
            columns={getYerRequestColumns(isMass)}
            searchUrls={searchUrls}
            isExportable={true}
            identifier="yerSingleRequestSearchMask"
            contextMenu={getYerContextMenu(currentRowData, setCurrentRowData)}
        />
    );
};

export const YerSingleRequestSearchMask = (): React.JSX.Element => YerRequestSearchMask("single");
export const YerMassRequestSearchMask = (): React.JSX.Element => YerRequestSearchMask("mass");
