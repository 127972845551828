import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn } from "components/Table";
import { VersionsView } from "components/VersionsView";
import { KISTAM_SECTION } from "./PersonOverviewEdit";

export const kistamDenominationShorten = (option: string): string =>
    option ? option.replace(/^(\d+ \/ )/, "") : option;

interface IKistamVersions {
    personId: number;
}

export const KistamVersions = (props: IKistamVersions): React.JSX.Element => {
    const IDENTIFIER: TableidentifierType = "kistamVersions";

    const columns: ITableColumn[] = [
        { field: "fiscalYearFrom", title: "Steuerjahr von" },
        { field: "fiscalYearTo", title: "Steuerjahr bis" },
        { field: "denomination", title: "Konfession (von BZST)", filter: kistamDenominationShorten },
        { field: "churchTaxRate", title: "Kirchensteuer Rate (von BZST)", type: "percent" },
        { field: "execState", title: "Ausführungsstatus" },
    ];

    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/kistam/with-all-timeslices",
        params: { personId: props.personId },
    };

    return (
        <VersionsView
            identifier={IDENTIFIER}
            title="Kirchensteuer Zeitscheiben"
            columnsDefinition={columns}
            dataRequest={dataRequest}
            historyPath="kistamHistory"
            editSection={KISTAM_SECTION}
            addFunction
        />
    );
};
