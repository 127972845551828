/* author: Pal PRASHANT */

import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { KeyTableConsts } from "components/KeyTableConsts";
import { useLocation, Location } from "react-router";

import { Params, useParams } from "react-router-dom";

export const FSA_LIMIT_SECTION: string = "FSA Limit";

export const FsaLimitVersionEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const section: string | undefined = params.section;
    const fsaLimitId: number = Number(params.fsaLimitId);

    const location: Location = useLocation();
    const fsaNvId: number = location.state.rowData["fsaNvId"];

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: "/customerstaticdata/fsaNv/fsaLimitDataForEdit",
            params: { fsaLimitId: fsaLimitId },
        });

    const fsaLimitVersionEdit: IEditDataViewUserConfiguration = {
        headline: FSA_LIMIT_SECTION,
        attributes: [
            {
                field: "exemptAmount",
                headerName: "Freistellungsbetrag",
            },
            {
                field: "taxDate",
                headerName: "Steuerliches Zuflussdatum des SPB",
                type: "date",
                displayOnly: true,
            },
            {
                field: "execState",
                headerName: "Ausführungsstatus",
                dropdown: {
                    keyTableTabname: KeyTableConsts.EXEC_STATE,
                },
                displayOnly: true,
            },

            { field: "identification", headerName: "FSA Identifikation Nr.", displayOnly: true },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
    };
    const completeEditInfo: IEditDataViewUserConfiguration[] = [fsaLimitVersionEdit];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={fsaNvId}
            title="FSA Limit bearbeiten"
            section={section}
            parentIdentifier="fsaNvId"
        />
    );
};
