/* author: JESCHKE Moritz */

import { useState, useEffect } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent, SxProps, Theme, Typography } from "@mui/material";
import { Colors } from "./Colors";
import { getBorderColor, getBorderWidth } from "./InputField";
import { IKeyTableGetter, getOptionsMap } from "./KeyTableMapper";
import { cloneDeep } from "lodash";

export interface IComboMultiBox {
    label: string;
    options?: Record<string, string>[];
    keyTable?: IKeyTableGetter;
    optionIdentifier: string;
    optionValue: string[];
    optionValueSeparator?: string;
    width?: string;
    variant?: "filled" | "outlined" | "standard";
    sx?: SxProps<Theme>;
    size?: "small";
    disabled?: boolean;
    highest?: string;
    lowest?: string;
    optionValueFilter?: (option: string) => string;
    highlightBorder?: boolean;
    hasError?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur?: (event: any) => void;
    disableNonEditableOptions?: boolean;
}

interface ICombobox extends IComboMultiBox {
    value: string | undefined;
    onChange: (event: SelectChangeEvent<string>) => void;
    defaultValue?: string;
}

export const isItemToBeDisabled = (value: string, props: IComboMultiBox): boolean => {
    if (props.lowest && value > props.lowest) {
        return true;
    }
    if (props.highest && value < props.highest) {
        return true;
    }
    return false;
};

export const combineOptionLabel = (option: Record<string, string>, props: IComboMultiBox): string => {
    let label: string = props.optionValue
        .map((value: string) => {
            return option[value];
        })
        .join(props.optionValueSeparator);

    if (props.optionValueFilter) {
        label = props.optionValueFilter(label);
    }

    return label;
};

export const ComboBox = (props: ICombobox): React.JSX.Element => {
    const [options, setOptions] = useState<Record<string, string>[] | undefined>(undefined);

    useEffect(() => {
        const fetchKeyTable = async (keyTable: IKeyTableGetter) => {
            const opt: Record<string, string>[] = cloneDeep(
                (await getOptionsMap(keyTable.tabname)) as unknown as Record<string, string>[]
            );

            if (keyTable.allOption && opt[0] && opt[0].label !== "Alle") {
                opt.unshift({ label: "Alle", value: "*" });
            }

            setOptions(opt);
        };

        if (!options && props.keyTable) {
            fetchKeyTable(props.keyTable);
        }

        if (props.options) {
            setOptions(props.options);
        }
    }, [props.keyTable, props.options]);

    const disableComboBox = () => {
        if (props.disabled) return true;
        if (!props.disableNonEditableOptions) return false;

        let optionIsNotChangeable: boolean = false;

        if (options) {
            const option: Record<string, string> = options.filter(
                (f: Record<string, string>) => f.value === props.value
            )[0];
            if (option) optionIsNotChangeable = !option.editable;
        }

        return props.disabled || optionIsNotChangeable;
    };

    return (
        <div style={{ minWidth: props.width ?? "15%", maxWidth: props.width ?? "25%" }}>
            <Typography
                sx={{
                    fontSize: "0.875rem",
                    color: disableComboBox() ? Colors.darkGrey : Colors.black,
                    fontWeight: "bold",
                }}
            >
                {props.label}
            </Typography>

            <FormControl
                fullWidth
                variant={props.variant ? props.variant : "outlined"}
                disabled={disableComboBox()}
                sx={
                    props.sx
                        ? props.sx
                        : {
                              width: props.width ?? "100%",
                              borderRadius: "3px",
                              "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
                                  border: getBorderWidth(props.highlightBorder) + "px solid",
                                  borderColor: getBorderColor(disableComboBox(), props.highlightBorder, props.hasError),
                                  borderRadius: "3px",
                                  boxSizing: "border-box",
                                  height: "1.875rem",
                                  lineHeight: "0.8rem",
                              },
                          }
                }
            >
                <Select
                    value={props.value}
                    defaultValue={props.defaultValue}
                    onChange={(event: SelectChangeEvent<string>) => props.onChange(event)}
                    size={props.size ?? "medium"}
                    onBlur={props.onBlur}
                    renderValue={(selected) => {
                        if (options && options.length !== 0) {
                            const found: Record<string, string> | undefined = options.find(
                                (x: Record<string, string>) => x[props.optionIdentifier] === selected
                            );

                            return found
                                ? combineOptionLabel(found, props)
                                : "Kein Eintrag in Schlüsseltabelle " +
                                      props.keyTable?.tabname +
                                      " für Schlüssel " +
                                      selected;
                        }
                    }}
                >
                    {options &&
                        options.map((option: Record<string, string>) => {
                            return (
                                <MenuItem
                                    key={option[props.optionIdentifier]}
                                    value={option[props.optionIdentifier]}
                                    disabled={isItemToBeDisabled(option.value, props)}
                                    sx={{
                                        whiteSpace: "pre",
                                        display:
                                            //hide entries which are not editable
                                            Object.hasOwn(option, "editable") && !option["editable"]
                                                ? "none"
                                                : "inherit",
                                    }}
                                >
                                    {combineOptionLabel(option, props)}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </div>
    );
};
export const createComboBoxOptions = (options: string[]): Record<string, string>[] => {
    const ret: Record<string, string>[] = [];
    options.forEach((option: string) => {
        ret.push({ label: option });
    });
    return ret;
};
