/* author: JESCHKE Moritz  */

import { Divider, Typography } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { IOverviewDataViewWithData, OverviewDataView } from "components/OverviewDataView";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface ISpecuDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    extTranKey: string;
    impId: number;
    generalData: Record<string, string>;
}

export const SpecuDetails = (props: ISpecuDetails): React.JSX.Element => {
    const generalInfo: IOverviewDataViewWithData = {
        attributes: [
            { field: "taxEvent", headerName: "Steuerliche Geschäftsart" },
            { field: "depotNumber", headerName: "Depotnummer" },
            { field: "secCode", headerName: "WKN/ISIN" },
            { field: "shortname", headerName: "Kurzbezeichnung" },
            { field: "executionTime", headerName: "Ausführungstag", type: "date" },
            { field: "exDate", headerName: "ExTag", type: "date" },
            { field: "detachDate", headerName: "Trenntermin", type: "date" },
            { field: "refNominal", headerName: "Referenznominal" },
        ],
        columnNumber: 5,
        data: props.generalData,
    };

    const IDENTIFIER: TableidentifierType = "specuDetails";
    const [tableData, setTableData] = useState<Record<string, string>[]>([]);

    const columns: ITableColumn[] = [
        {
            field: "acquisitionDate",
            title: "Anschaffungsdatum",
            type: "date",
        },
        { field: "transactionKeyMandant", title: "Umsatzreferenz der relevanten Anschaffung" },
        { field: "nominal", title: "Steuerliches Restnominal" },
        { field: "acquisitionCost", title: "Steuerliche AK" },
        { field: "acquisitionExpCost", title: "Steuerliche ANK" },
        { field: "acqEvaluable", title: "Element bewertbar" },
        { field: "calcAssetDistr", title: "Wertberichtigung AK durch SubA" },
        { field: "calcAssetDistrExpCost", title: "Wertberichtigung ANK durch SubA" },
        { field: "indiAcqCostsEUR", title: "AK (SubA)" },
        { field: "indiAcqCostsNullFlag", title: "Ak (SubA) bewertbar" },
        { field: "adjustIndiAcqCostsAssetDistribution", title: "Wertberichtigung Ak (SubA) durch SubA" },
        { field: "totalIncomeDistribWithED476G", title: "Ausschüttung" },
        { field: "potentialSalesProceeds", title: "Potentieller VE" },
        { field: "maxTaxFreeAssetDistribution", title: "Max. steuerfr. Ausschüttungsanteil" },
        { field: "taxFreeAssetDistribution", title: "Steuerfr. SubA" },
        { field: "assetDistribution", title: "SubA (ED476H)" },
        { field: "taxableAssetDistribution", title: "Steuerpfl. SubA (in Ertragsaussch. enth.)" },
        { field: "incomeDistributionBeforeTFS", title: "Steuerpfl. Ertragsausschüttung vor TFS" },
        { field: "incomeDistributionAfterTFS", title: "Steuerpfl. Ertragsausschüttung nach TFS" },
    ];

    useEffect(() => {
        sendGetRequest({
            path: "/cgresources/specu/detailed-ad",
            params: {
                impId: props.impId,
            },
        }).then((response) => {
            setTableData(response.data);
        });
    }, [props.impId]);

    return (
        <PopUp
            title={"Details Substanzausschüttung zu Umsatzreferenz: " + props.extTranKey}
            setOpen={props.setOpen}
            content={
                <div>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1rem", marginBottom: "1rem", marginTop: "1rem" }}>
                        {"TCG Substanzausschüttungstransaktion"}
                    </Typography>
                    <OverviewDataView
                        attributes={generalInfo.attributes}
                        columnNumber={generalInfo.columnNumber}
                        data={generalInfo.data}
                    />
                    <Divider />
                    <Typography sx={{ fontWeight: "bold", fontSize: "1rem", marginBottom: "1rem", marginTop: "1rem" }}>
                        {"Substanzausschüttungen"}
                    </Typography>
                    <Table
                        columnsDefinition={columns}
                        tableData={tableData}
                        identifier={IDENTIFIER}
                        disableHideColumn={true}
                        pageSizeOptions={"alternativ"}
                    />
                </div>
            }
        />
    );
};
