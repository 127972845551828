import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { renewLoginTime } from "application/app-bar/Appbar";

interface IProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement<string>;
        },
        ref: React.Ref<unknown>
    ) => {
        return <Slide direction="down" ref={ref} {...props} />;
    }
);

Transition.displayName = "Transition";

export const RenewTokenDialog = (props: IProps): React.JSX.Element => {
    const extendTime = () => {
        renewLoginTime();
        props.setOpen(false);
    };

    /**
     * Call function if special key is pressed
     * @param event KeyPressEvent
     */
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            extendTime();
        }
    };

    return (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            onKeyDown={handleKeyDown}
        >
            <DialogTitle>{"Login Session nur noch 1 Minute gültig"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Die aktuelle Login Session läuft ab. Soll die Zeit verlängert werden?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={extendTime}>Verlängern</Button>
                <Button onClick={() => props.setOpen(false)}>Nicht verlängern</Button>
            </DialogActions>
        </Dialog>
    );
};
