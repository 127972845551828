import "dayjs/locale/de";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Typography } from "@mui/material";

interface IFromToDatePicker {
    from: Date | null;
    setFrom: Dispatch<SetStateAction<Date | null>>;
    to: Date | null;
    setTo: Dispatch<SetStateAction<Date | null>>;
    setDateError: Dispatch<SetStateAction<boolean>>;
}

type Error = "shouldDisableDate" | "disablePast" | "disableFuture" | "minDate" | "maxDate" | "invalidDate" | null;

export const FromToDatePicker = (props: IFromToDatePicker): React.JSX.Element => {
    const [dateErrorMsgFrom, setDateErrorMsgFrom] = useState<string>("");
    const [dateErrorMsgTo, setDateErrorMsgTo] = useState<string>("");

    /**
     * Changes the Error Messages for the DatePickers
     * @param error Error Type (which one)
     * @param trigger Error Origin (from where)
     */
    const dateErrorHandler = (error: Error, trigger: "from" | "to") => {
        if (trigger === "from") {
            //Error Messages for from Date
            switch (error) {
                case "shouldDisableDate":
                    setDateErrorMsgFrom("Das Von-Datum ist deaktiviert.");
                    break;
                case "disablePast":
                    setDateErrorMsgFrom("Das Von-Datum darf nicht in der Vergangenheit liegen.");
                    break;
                case "disableFuture":
                    setDateErrorMsgFrom("Das Von-Datum darf nicht in der Zukunft liegen.");
                    break;
                case "maxDate":
                    setDateErrorMsgFrom("Das Von-Datum darf nicht hinter dem Bis-Datum liegen.");
                    break;
                case "invalidDate":
                    setDateErrorMsgFrom("Das Von-Datum entspricht nicht den Datumkonventionen.");
                    break;
                case "minDate":
                    setDateErrorMsgFrom("Das Von-Datum darf nicht vor dem 01.01.1900 liegen.");
                    break;
                case null:
                    setDateErrorMsgFrom("");
                    break;
            }
        } else {
            //Error Messages for to Date
            switch (error) {
                case "shouldDisableDate":
                    setDateErrorMsgTo("Das Bis-Datum ist deaktiviert.");
                    break;
                case "disablePast":
                    setDateErrorMsgTo("Das Bis-Datum darf nicht in der Vergangenheit liegen.");
                    break;
                case "disableFuture":
                    setDateErrorMsgTo("Das Bis-Datum darf nicht in der Zukunft liegen.");
                    break;
                case "minDate":
                    setDateErrorMsgTo("Das Bis-Datum darf nicht vor dem Von-Datum liegen.");
                    break;
                case "maxDate":
                    setDateErrorMsgTo("Das Bis-Datum darf nicht hinter dem Von-Datum liegen.");
                    break;
                case "invalidDate":
                    setDateErrorMsgTo("Das Bis-Datum entspricht nicht den Datumkonventionen.");
                    break;
                case null:
                    setDateErrorMsgTo("");
                    break;
            }
        }
    };

    useEffect(() => {
        props.setDateError(dateErrorMsgFrom !== "" || dateErrorMsgTo !== "");
    }, [dateErrorMsgFrom, dateErrorMsgTo, props]);

    return (
        <div>
            <Typography style={{ marginBottom: "0.05em", color: "red" }}>{dateErrorMsgFrom}</Typography>
            <Typography style={{ marginBottom: "0.5em", color: "red" }}>{dateErrorMsgTo}</Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                <DatePicker
                    label="von"
                    value={props.from}
                    onChange={(newValue: Date | null) => {
                        console.log(newValue);
                        props.setFrom(newValue);
                        if (newValue === null) {
                            setDateErrorMsgFrom("");
                        }
                    }}
                    renderInput={(params: TextFieldProps) => <TextField {...params} />}
                    mask="__.__.____"
                    maxDate={props.to !== null ? props.to : undefined}
                    disableFuture={true}
                    onError={(err: Error) => dateErrorHandler(err, "from")}
                    onAccept={() => setDateErrorMsgFrom("")}
                />

                <DatePicker
                    label="bis"
                    value={props.to}
                    onChange={(newValue: Date | null) => {
                        props.setTo(newValue);
                        if (newValue === null) {
                            setDateErrorMsgTo("");
                        }
                    }}
                    renderInput={(params: TextFieldProps) => <TextField {...params} />}
                    mask="__.__.____"
                    maxDate={new Date()}
                    minDate={props.from !== null ? props.from : undefined}
                    disableFuture={true}
                    onError={(err: Error) => dateErrorHandler(err, "to")}
                    onAccept={() => setDateErrorMsgTo("")}
                />
            </LocalizationProvider>
        </div>
    );
};
