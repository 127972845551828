export const Colors = {
    blue: "#0067a5",
    darkBlue: "#004873",
    lightBlue: "#ECF8FF",
    grey: "#f2f2f2",
    disabledGrey: "#6a6868",
    darkGrey: "#d7d7d7",
    darkDarkGrey: "#a7a7a7",
    white: "#ffffff",
    black: "#000000",
    red: "#ff0000",
    borderDark: "#333333",
    fontColorTable: "#555555",
    lightGreen: "#02db23",
    darkGreen: "#058017",
    orange: "#f5862c",
};
