/* author: JESCHKE Moritz */

import { Card, CardContent, Typography } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArticleIcon from "@mui/icons-material/Article";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "application/redux/Store";
import { useAppDispatch } from "application/redux/Hooks";
import { addHide } from "application/redux/slices/UserConfigurationSlice";
import { setShowTransactioninMenubar } from "application/redux/slices/TransactionSlice";
import { renewLoginTime } from "application/app-bar/Appbar";
import { Colors } from "components/Colors";
import { createKey } from "components/Converter";

interface ITile {
    name: string;
    icon: React.JSX.Element;
    linkTo: string;
    availableFor: string[];
    onClick?: () => void;
}

export const Dashboard = (): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate: NavigateFunction = useNavigate();

    /* ### VARIABLES & STATES ### */

    const COLUMNS: number = 4;
    const hiddenTiles = useSelector((state: RootState) => state.userConfiguration.dashboard.hide);
    const userRole = useSelector((state: RootState) => state.user.userRole);

    const iconStyle: CSSProperties = {
        margin: "0 auto",
        display: "flex",
        fontSize: "75px",
    };
    const tiles: ITile[] = [
        {
            name: "Personenstammdaten",
            icon: <PersonIcon color="action" style={iconStyle} />,
            linkTo: "/personensuchmaske",
            availableFor: ["admin", "writer", "reader"],
        },
        {
            name: "Wertpapierstammdaten",
            icon: <AccountBalanceIcon color="action" style={iconStyle} />,
            linkTo: "/securitySearchMask",
            availableFor: ["admin", "writer", "reader"],
        },
        {
            name: "Meldungen und Reporte",
            icon: <ArticleIcon color="action" style={iconStyle} />,
            linkTo: "/report",
            availableFor: ["admin", "writer", "reader"],
        },
        {
            name: "Transaktionen",
            icon: <SwapHorizIcon color="action" style={iconStyle} />,
            linkTo: "/transaktionen",
            availableFor: ["admin", "writer", "reader"],
            onClick: () => dispatch(setShowTransactioninMenubar({ showInMenubar: true })),
        },
        {
            name: "Admin",
            icon: <AdminPanelSettingsIcon color="action" style={iconStyle} />,
            linkTo: "/home",
            availableFor: ["admin"],
        },
    ];

    const [hideIconShown, setHideIconShown] = useState<boolean[]>(Array(tiles.length).fill(false));

    /* ### Functions ### */

    /**
     * set hideIconShown Array Element at index to true
     * @param index the tile on which to display the hide icon
     */
    const showIcon = (index: number) => {
        setHideIconShown([...hideIconShown.slice(0, index), true, ...hideIconShown.slice(index + 1)]);
    };

    /**
     * set all hideIconShow Array Element to false
     */
    const hideIcon = () => {
        setHideIconShown(Array(tiles.length).fill(false));
    };

    useEffect(() => {
        renewLoginTime();
    }, []);

    /* ### HTML ### */

    return (
        <div
            style={{
                margin: "4rem",
                display: "grid",
                gridTemplateColumns: "repeat(" + COLUMNS + ", 1fr)",
                gap: "4rem",
            }}
        >
            {tiles.map((tile: ITile, index: number) => {
                if (!hiddenTiles.includes(tile.name) && tile.availableFor.includes(userRole.toLocaleLowerCase())) {
                    return (
                        <Card
                            key={createKey("dashboard", tile.name)}
                            onClick={() => navigate(tile.linkTo)}
                            //show hide icon
                            onMouseEnter={() => showIcon(index)}
                            //hide hide icon
                            onMouseLeave={hideIcon}
                            style={{ cursor: "pointer" }}
                        >
                            <CardContent>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        position: "relative",
                                    }}
                                >
                                    {tile.icon}
                                    {hideIconShown[index] && (
                                        //hide in user configuration on click
                                        <div
                                            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                event.stopPropagation();
                                                dispatch(addHide({ hide: tiles[index].name }));
                                            }}
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                color: Colors.blue,
                                            }}
                                        >
                                            <VisibilityOffIcon />
                                        </div>
                                    )}
                                </div>
                                <Typography variant="body2" align="center" sx={{ textTransform: "uppercase" }}>
                                    {tile.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    );
                } else return null;
            })}
        </div>
    );
};
