import React, { useState, useEffect } from "react";
import { Box, Card, Typography } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { TaxAccounting } from "../bmg-individual/TaxAccounting";
import { AppDispatch } from "application/redux/Store";
import { useAppDispatch } from "application/redux/Hooks";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, ITableContextMenu, Table } from "components/Table";

interface IProps {
    impId: number;
    system: string;
}

export const TaxBase = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [tableData, setTableData] = useState([]);

    const IDENTIFIER: TableidentifierType = "taxBase";

    const [yieldId, setYieldId] = useState<number>();

    const columns: ITableColumn[] = [
        { field: "cancellationFlag", title: "Korrekturkennzeichen" },
        { field: "execState", title: "Bearbeitungsstatus" },
        {
            field: "taxableBase_1",
            title: "Bemessungsgrundlage Komponente 1",
            type: "numeric",
        },
        { field: "incomeTypeP20_1", title: "Einkommenstyp nach §20 Komponente 1" },
        {
            field: "taxableBase_2",
            title: "Bemessungsgrundlage Komponente 2",
            type: "numeric",
        },
        { field: "incomeTypeP20_2", title: "Einkommenstyp nach §20 Komponente 2" },
        {
            field: "taxableBase_3",
            title: "Bemessungsgrundlage Komponente 3",
            type: "numeric",
        },
        { field: "incomeTypeP20_3", title: "Einkommenstyp nach §20 Komponente 3" },
        {
            field: "taxableBase_4",
            title: "Bemessungsgrundlage Komponente 4",
            type: "numeric",
        },
        { field: "incomeTypeP20_4", title: "Einkommenstyp nach §20 Komponente 4" },
        {
            field: "taxableBase_5",
            title: "Bemessungsgrundlage Komponente 5",
            type: "numeric",
        },
        { field: "incomeTypeP20_5", title: "Einkommenstyp nach §20 Komponente 5" },

        // { field: "incomeTypeP20_6", headerName: "TB_INCOME_TYPE_P20_6" },
        // { field: "taxableBase_6", headerName: "TB_TAXABLE_BASE_6" },
        // { field: "incomeTypeP20_7", headerName: "TB_INCOME_TYPE_P20_7" },
        // { field: "taxableBase_7", headerName: "TB_TAXABLE_BASE_7" },
        // { field: "incomeTypeP20_8", headerName: "TB_INCOME_TYPE_P20_8" },
        // { field: "taxableBase_8", headerName: "TB_TAXABLE_BASE_8" },
        // { field: "incomeTypeP20_9", headerName: "TB_INCOME_TYPE_P20_9" },
        // { field: "taxableBase_9", headerName: "TB_TAXABLE_BASE_9" },
        // { field: "incomeTypeP20_10", headerName: "TB_INCOME_TYPE_P20_10" },
        // { field: "taxableBase_10", headerName: "TB_TAXABLE_BASE_10" },
        // { field: "incomeTypeP20_11", headerName: "TB_INCOME_TYPE_P20_11" },
        // { field: "taxableBase_11", headerName: "TB_TAXABLE_BASE_11" },
        // { field: "incomeTypeP20_12", headerName: "TB_INCOME_TYPE_P20_12" },
        // { field: "taxableBase_12", headerName: "TB_TAXABLE_BASE_12" },
        // { field: "incomeTypeP20_13", headerName: "TB_INCOME_TYPE_P20_13" },
        // { field: "taxableBase_13", headerName: "TB_TAXABLE_BASE_13" },
        // { field: "incomeTypeP20_14", headerName: "TB_INCOME_TYPE_P20_14" },
        // { field: "taxableBase_14", headerName: "TB_TAXABLE_BASE_14" },
        // { field: "incomeTypeP20_15", headerName: "TB_INCOME_TYPE_P20_15" },
        // { field: "taxableBase_15", headerName: "TB_TAXABLE_BASE_15" },
        // { field: "incomeTypeP20_16", headerName: "TB_INCOME_TYPE_P20_16" },
        // { field: "taxableBase_16", headerName: "TB_TAXABLE_BASE_16" },
        // { field: "incomeTypeP20_17", headerName: "TB_INCOME_TYPE_P20_17" },
        // { field: "taxableBase_17", headerName: "TB_TAXABLE_BASE_17" },
        // { field: "incomeTypeP20_18", headerName: "TB_INCOME_TYPE_P20_18" },
        // { field: "taxableBase_18", headerName: "TB_TAXABLE_BASE_18" },
        // { field: "incomeTypeP20_19", headerName: "TB_INCOME_TYPE_P20_19" },
        // { field: "taxableBase_19", headerName: "TB_TAXABLE_BASE_19" },
        // { field: "incomeTypeP20_20", headerName: "TB_INCOME_TYPE_P20_20" },
        // { field: "taxableBase_20", headerName: "TB_TAXABLE_BASE_20" },
        // { field: "incomeTypeP20_21", headerName: "TB_INCOME_TYPE_P20_21" },
        // { field: "taxableBase_21", headerName: "TB_TAXABLE_BASE_21" },
        // { field: "incomeTypeP20_22", headerName: "TB_INCOME_TYPE_P20_22" },
        // { field: "taxableBase_22", headerName: "TB_TAXABLE_BASE_22" },
        // { field: "incomeTypeP20_23", headerName: "TB_INCOME_TYPE_P20_23" },
        // { field: "taxableBase_23", headerName: "TB_TAXABLE_BASE_23" },
        // { field: "incomeTypeP20_24", headerName: "TB_INCOME_TYPE_P20_24" },
        // { field: "taxableBase_24", headerName: "TB_TAXABLE_BASE_24" },
        // { field: "incomeTypeP20_25", headerName: "TB_INCOME_TYPE_P20_25" },
        // { field: "taxableBase_25", headerName: "TB_TAXABLE_BASE_25" },
        // { field: "incomeTypeP20_26", headerName: "TB_INCOME_TYPE_P20_26" },
        // { field: "taxableBase_26", headerName: "TB_TAXABLE_BASE_26" },
        // { field: "incomeTypeP20_27", headerName: "TB_INCOME_TYPE_P20_27" },
        // { field: "taxableBase_27", headerName: "TB_TAXABLE_BASE_27" },
        // { field: "incomeTypeP20_28", headerName: "TB_INCOME_TYPE_P20_28" },
        // { field: "taxableBase_28", headerName: "TB_TAXABLE_BASE_28" },
        // { field: "incomeTypeP20_29", headerName: "TB_INCOME_TYPE_P20_29" },
        // { field: "taxableBase_29", headerName: "TB_TAXABLE_BASE_29" },
        // { field: "incomeTypeP20_30", headerName: "TB_INCOME_TYPE_P20_30" },
        // { field: "taxableBase_30", headerName: "TB_TAXABLE_BASE_30" },
        // { field: "incomeTypeP20_31", headerName: "TB_INCOME_TYPE_P20_31" },
        // { field: "taxableBase_31", headerName: "TB_TAXABLE_BASE_31" },
        // { field: "incomeTypeP20_32", headerName: "TB_INCOME_TYPE_P20_32" },
        // { field: "taxableBase_32", headerName: "TB_TAXABLE_BASE_32" },
        // { field: "incomeTypeP20_33", headerName: "TB_INCOME_TYPE_P20_33" },
        // { field: "taxableBase_33", headerName: "TB_TAXABLE_BASE_33" },
        // { field: "incomeTypeP20_34", headerName: "TB_INCOME_TYPE_P20_34" },
        // { field: "taxableBase_34", headerName: "TB_TAXABLE_BASE_34" },
        // { field: "incomeTypeP20_35", headerName: "TB_INCOME_TYPE_P20_35" },
        // { field: "taxableBase_35", headerName: "TB_TAXABLE_BASE_35" },
        // { field: "incomeTypeP20_36", headerName: "TB_INCOME_TYPE_P20_36" },
        // { field: "taxableBase_36", headerName: "TB_TAXABLE_BASE_36" },
        // { field: "incomeTypeP20_37", headerName: "TB_INCOME_TYPE_P20_37" },
        // { field: "taxableBase_37", headerName: "TB_TAXABLE_BASE_37" },
        // { field: "incomeTypeP20_38", headerName: "TB_INCOME_TYPE_P20_38" },
        // { field: "taxableBase_38", headerName: "TB_TAXABLE_BASE_38" },
        // { field: "incomeTypeP20_39", headerName: "TB_INCOME_TYPE_P20_39" },
        // { field: "taxableBase_39", headerName: "TB_TAXABLE_BASE_39" },
        // { field: "incomeTypeP20_40", headerName: "TB_INCOME_TYPE_P20_40" },
        // { field: "taxableBase_40", headerName: "TB_TAXABLE_BASE_40" },
        // { field: "incomeTypeP20_41", headerName: "TB_INCOME_TYPE_P20_41" },
        // { field: "taxableBase_41", headerName: "TB_TAXABLE_BASE_41" },
        // { field: "incomeTypeP20_42", headerName: "TB_INCOME_TYPE_P20_42" },
        // { field: "taxableBase_42", headerName: "TB_TAXABLE_BASE_42" },
        // { field: "incomeTypeP20_43", headerName: "TB_INCOME_TYPE_P20_43" },
        // { field: "taxableBase_43", headerName: "TB_TAXABLE_BASE_43" },
        // { field: "incomeTypeP20_44", headerName: "TB_INCOME_TYPE_P20_44" },
        // { field: "taxableBase_44", headerName: "TB_TAXABLE_BASE_44" },
        // { field: "incomeTypeP20_45", headerName: "TB_INCOME_TYPE_P20_45" },
        // { field: "taxableBase_45", headerName: "TB_TAXABLE_BASE_45" },
        /* {
            field: "differentTaxTime",
            headerName: "TB_DIFFERENT_TAX_TIME",
            valueGetter: customDateValueGetter,
            sortComparator: customDateComparator,
        }, */
        // { field: "grossYieldCash", headerName: "TB_GROSS_YIELD_CASH" },
        // { field: "grossYieldNonCash", headerName: "TB_GROSS_YIELD_NON_CASH" },
        // { field: "foreignIncome", headerName: "TB_FOREIGN_INCOME" },
        // { field: "offsetableTax", headerName: "TB_OFFSETABLE_TAX" },
        // { field: "interestTaxByIssuer", headerName: "TB_INTEREST_TAX_BY_ISSUER" },
        // { field: "dividendTaxByIssuer", headerName: "TB_DIVIDEND_TAX_BY_ISSUER" },
        // { field: "soliTaxByIssuer", headerName: "TB_SOLI_BY_ISSUER" },
        // {
        //     field: "dividendTaxFoCustodian",
        //     headerName: "TB_DIVIDEND_TAX_FO_CUSTODIAN",
        // },
        // { field: "soliFoCustodian", headerName: "TB_SOLI_FO_CUSTODIAN" },
        // { field: "foDivOffsetTax", headerName: "TB_FO_DIV_OFFSET_TAX" },
        // { field: "interestOffsetTax", headerName: "TB_INTEREST_OFFSET_TAX" },
        // { field: "domDivOffsetTax", headerName: "TB_DOM_DIV_OFFSET_TAX" },
        // { field: "delSystem", headerName: "TB_DEL_SYSTEM" },
        // { field: "deltaLinkedYieldId", headerName: "TB_DELTA_LINKED_YIELD_ID" },
        // {
        //     field: "offsettableTaxOnlyCert",
        //     headerName: "TB_OFFSETABLE_TAX_ONLY_CERT",
        // },
        // { field: "taxLiquidity", headerName: "TB_TAX_LIQUIDITY" },
    ];

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    const contextMenu: ITableContextMenu = {
        contextActions: [
            {
                label: "Persönliche Bemessungsgrundlage",
                //@ts-ignore
                action: () => setYieldId(currentRowData.yieldId),
            },
        ],
        setCurrentRowData: setCurrentRowData,
    };

    //call http request onMount
    useEffect(() => {
        async function fetchData() {
            dispatch(setIsLoading({ isLoading: true }));
            const response = await sendGetRequest({
                path: "/cgresources/taxbase",
                params: { impId: props.impId, system: props.system },
            });
            setTableData(response.data);
            dispatch(setIsLoading({ isLoading: false }));
        }

        fetchData();
    }, [props.impId, props.system, dispatch]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Box sx={{ height: "100%" }}>
            <Card sx={{ height: "50%" }}>
                <Table
                    columnsDefinition={columns}
                    identifier={IDENTIFIER}
                    tableData={tableData}
                    contextMenu={contextMenu}
                />
            </Card>

            {yieldId !== undefined ? (
                <div>
                    <br />
                    <Typography>{"Persönliche Bemessungsgrundlagen für die yieldId: " + yieldId}</Typography>
                    <TaxAccounting params={{ yieldId: yieldId, system: props.system }} />
                </div>
            ) : null}
        </Box>
    );
};
