/* author: JESCKHE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { useEffect, useState } from "react";
import { ITableColumn, Table } from "./Table";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";

interface IDetails {
    requestId: number;
}

export const Details = (props: IDetails): React.JSX.Element => {
    const [detailData, setDetailData] = useState<Record<string, string>[]>([]);
    const IDENTIFIER: TableidentifierType = "errorDetails";

    const appBar: HTMLElement = document.getElementById("appBar") as HTMLElement;
    const columns: ITableColumn[] = [
        { field: "msgNr", title: "Fehlertext", width: appBar && appBar.style.width === "100%" ? "75vw" : "60vw" },
        { field: "module", title: "Tributum Modul" },
    ];

    useEffect(() => {
        sendGetRequest({ path: "/request/requestInfo", params: { requestId: props.requestId } }).then((response) => {
            setDetailData(response.data);
        });
    }, [props.requestId]);

    return (
        <Table
            columnsDefinition={columns}
            tableData={detailData}
            identifier={IDENTIFIER}
            disableHideColumn={true}
            customColumnWidth={true}
        />
    );
};
