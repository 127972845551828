/* author: Pal Prashant */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params, useParams } from "react-router";

export const SEC_SWITCHING_SECTION: string = "Umtausch";

export const SecSwitchingEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const securityId: number = Number(params.securityId);
    const section: string | undefined = params.section;
    const bidId: number = Number(params.bidId);

    const secSwitchingInfo: IEditDataViewUserConfiguration = {
        headline: SEC_SWITCHING_SECTION,
        attributes: [
            {
                field: "switchStatus",
                headerName: "UD001 - UmtauschStatus",
                dropdown: {
                    keyTableTabname: "TB_WMU25",
                },
            },
            {
                field: "switchType",
                headerName: "UD006A - UmtauschArt",
                dropdown: {
                    keyTableTabname: "TB_WMU51",
                },
            },
            {
                field: "actionMethod",
                headerName: "UD007 - UmtauschMethode",
                dropdown: {
                    keyTableTabname: "TB_WMU03",
                },
            },
            {
                field: "switchReason",
                headerName: "UD008A - UmtauschGrund",
                dropdown: {
                    keyTableTabname: "TB_WMU20",
                },
            },
            {
                field: "capitalDecreaseType",
                headerName: "UD009 - KapitalherabsetzungsArt",
                dropdown: {
                    keyTableTabname: "TB_WMU05",
                },
            },
            {
                field: "exchangeRatioNumCur",
                headerName: "UD010A - Whrg Umtauschverhältnis Zähler",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            {
                field: "exchangeRatioNum",
                headerName: "UD010D - Umtauschverhältnis Zähler",
            },
            {
                field: "exchangeRatioDenCur",
                headerName: "UD011A - Whrg Umtauschverhältnis Nenner",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            { field: "exchangeRatioDen", headerName: "UD011D - Umtauschverhältnis Nenner" },
            {
                field: "payType",
                headerName: "UD013 - Zuzahlungsart",
                dropdown: {
                    keyTableTabname: "TB_WMU06",
                },
            },
            { field: "amountPaid", headerName: "UD014B - Zuzahlung" },
            {
                field: "amountCur",
                headerName: "UD015 - Währung Zuzahlung",
                dropdown: {
                    keyTableTabname: "TB_WMW01",
                },
            },
            { field: "switchTo", headerName: "UD016-1 - Umtauschfrist von", type: "date" },
            { field: "switchFrom", headerName: "UD016-2 - Umtauschfrist bis", type: "date" },
            { field: "deadline", headerName: "UD019 - Stichtag CBF-Intl", type: "date" },
            { field: "switchWkn", headerName: "UD021 - WKN" },
            { field: "switchIsin", headerName: "UD021PI - ISIN" },
            { field: "exDate", headerName: "UD025 - ExTag", type: "date" },
            { field: "stockHoldDate", headerName: "UD026 - Tag der Hauptversammlung", type: "date" },
            {
                field: "switchRet",
                headerName: "UD030 - Umtauschvorbehalt",
                dropdown: {
                    keyTableTabname: "TB_WMU09",
                },
            },
            { field: "detachDate", headerName: "UD034 - Trenntermin", type: "date" },
            {
                field: "percentSign",
                headerName: "UD036 - Prozentzeichen",
                dropdown: {
                    keyTableTabname: "TB_WMW03",
                },
            },
            {
                field: "complianceType",
                headerName: "UD061 - Erfüllungsart",
                dropdown: {
                    keyTableTabname: "TB_WMU23",
                },
            },
            {
                field: "certificateFlag",
                headerName: "UD084 - KZ Jahresbescheinigung",
                dropdown: {
                    keyTableTabname: "TB_WMG83",
                },
            },
            {
                field: "corpLawActivity",
                headerName: "UD087 - KZ ges. Vorgänge",
                dropdown: {
                    keyTableTabname: "TB_WMU29",
                },
            },
            { field: "corpRegisterDate", headerName: "UD088 - Datum HR-Eintrag", type: "date" },
            {
                field: "ud230",
                headerName: "UD230 - Cluster KM-Matrix",
                displayOnly: true,
            },
            { field: "prioNominal", headerName: "UD460A - Nennwert vor Kapitalmaßnahme" },
            { field: "postNominal", headerName: "UD460B - Nennwert nach Kapitalmaßnahme" },
            {
                field: "noPrioNominal",
                headerName: "UD460C - ohne Nennwert vor Kapitalmaßnahme",
                dropdown: {
                    keyTableTabname: "TB_WMG71",
                },
            },
            {
                field: "noPostNominal",
                headerName: "UD460D - ohne Nennwert nach Kapitalmaßnahme",
                dropdown: {
                    keyTableTabname: "TB_WMG71",
                },
            },
            {
                field: "caSortOrder",
                headerName: "St. KZ CA-Reihenfolge",
                rules: {
                    pattern: {
                        value: /^(?:[1-9][0-9]?|99)?$/,
                        customMessage: "Die Zahl kann nur leer sein oder zwischen 1 und 99 liegen",
                    },
                },
            },
        ],
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/security/secswitching/byBidIdForEdit",
                params: { bidId: bidId },
            }),

        versionRequest: () =>
            sendGetRequest({
                path: "/security/secswitching/byBidIdForEdit",
                params: { bidId: bidId },
            }),
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [secSwitchingInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={securityId}
            title="Umtausch bearbeiten"
            section={section}
            parentIdentifier="securityId"
        />
    );
};
