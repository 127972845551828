import { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { renewLoginTime } from "application/app-bar/Appbar";
import { AppDispatch } from "application/redux/Store";
import { useAppDispatch } from "application/redux/Hooks";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { ITableColumn, Table } from "components/Table";

interface IProps {
    incomeTranId: number;
}

export const Amounts = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();
    const [tableData, setTableData] = useState([]);

    const incomeTranItemColumns: ITableColumn[] = [
        { field: "type", title: "Art des Betrags" },
        {
            field: "amount",
            title: "Betrag",
            type: "numeric",
        },
        { field: "currency", title: "Währung" },
        { field: "rate", title: "Devisenkurs" },
        { field: "itemSource", title: "Quelle" },
        { field: "execState", title: "Ausführungsstatus" },
        /**
        { field: "incomeTranItemId", headerName: "TB_INCOME_TRAN_ITEM_ID" },
        { field: "incomeTranId", headerName: "TB_INCOME_TRAN_ID" },
        { field: "remark", headerName: "TB_REMARK" },
        { field: "changeUser", headerName: "TB_CHANGE_USER" },
        { field: "relaeseUser", headerName: "TB_RELEASE_USER" },
        {
            field: "itemOwner",
            headerName: "TB_ITEM_OWNER",
        },
        {
            field: "insertTS",
            headerName: "TB_INSERT_TS",
            valueGetter: customDateValueGetter,
            sortComparator: customDateComparator,
        },
        {
            field: "updateTS",
            headerName: "TB_UPDATE_TS",
            valueGetter: customDateValueGetter,
            sortComparator: customDateComparator,
        },
        */
    ];

    //call http request onMount
    useEffect(() => {
        async function fetchData() {
            dispatch(setIsLoading({ isLoading: true }));
            const response = await sendGetRequest({
                path: "/cgresources/fttransactionsitems",
                params: { incomeTranId: props.incomeTranId },
            });
            setTableData(response.data);
            dispatch(setIsLoading({ isLoading: false }));
        }

        fetchData();
    }, [props.incomeTranId, dispatch]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Box sx={{ height: "100%" }}>
            <Card sx={{ height: "50%" }}>
                <Table columnsDefinition={incomeTranItemColumns} identifier="amounts" tableData={tableData} />
            </Card>
        </Box>
    );
};
