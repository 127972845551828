/* author: AASHISH UPADHYAY*/

import { customDateValueGetterV2, isValueDateType } from "./Converter";

export interface IHeaderkeys {
    label: string;
    combinedFields?: { field: string; replacer: string }[];
    datakeys?: IDatakeys[];
}

export interface IDatakeys {
    field: string;
    label: string;
    alternativeFields?: string[];
}

// For Part1 Data Display
export const rowDisplay = (exportCSVDataTemp1: Record<string, string>[], subHeader: IHeaderkeys): string[] => {
    // specify how you want to handle null values here
    const replacer = (_key: string, value: null): string => (value === null || value === undefined ? "" : value);
    const SEPARATOR: string = ",";
    const csvHeaders: Record<string, string> = {};
    const csvData: string[] = [];

    //this code block generates the headers for the CSV file by iterating over the dataKeys object.
    if (subHeader.datakeys) {
        for (const key of subHeader.datakeys) {
            if (key.alternativeFields) {
                let tempKey: string = key.field;
                key.alternativeFields.forEach((k: string) => {
                    tempKey = tempKey.concat(SEPARATOR + k);
                });
                csvHeaders[tempKey] = key["label"];
            } else {
                csvHeaders[key["field"]] = key["label"];
            }
        }
    }

    //this code block converts JSON data into a CSV format and allows the user to download the resulting CSV file.
    exportCSVDataTemp1.forEach((item: Record<string, string>) => {
        let resultantRow: string = "";
        Object.keys(csvHeaders).forEach((header: string) => {
            let value: string = "";
            if (header.includes(SEPARATOR)) {
                const headerArr: string[] = header.split(SEPARATOR);
                for (const key of headerArr) {
                    if (item[key] !== undefined && item[key] !== null) {
                        value = item[key];
                        break;
                    }
                }
            } else {
                value = JSON.stringify(item[header], replacer);
            }
            resultantRow = resultantRow.concat(value);
            resultantRow = resultantRow.concat(SEPARATOR);
        });
        csvData.push(resultantRow);
    });
    csvData.unshift(Object.values(csvHeaders).join(SEPARATOR));
    return csvData;
};

// For Part2 Data Display
export const columnDisplay = (exportCSVDataTemp2: Record<string, string>[], subHeader: IHeaderkeys): string[] => {
    const csvData: string[] = [];

    // Generate the fieldData labels and values
    if (subHeader.datakeys) {
        const fieldData: Record<string, string>[] = [];
        subHeader.datakeys.forEach((item) => {
            const field: string = item.field;
            const fieldNames: string[] = field.split(",");
            const fieldObj: Record<string, string> = {};
            fieldObj["Label"] = item.label;
            if (fieldNames[0]) {
                const fieldName: string = fieldNames[0].trim();
                const matchingData0: Record<string, string> | undefined = exportCSVDataTemp2.find(
                    (data: Record<string, string>) => Object.prototype.hasOwnProperty.call(data, fieldName)
                );
                const firstValue: string = matchingData0 ? matchingData0[fieldName] : "";
                fieldObj[fieldName] = firstValue;
            }

            if (fieldNames[1]) {
                const fieldName: string = fieldNames[1].trim();
                const matchingData1: Record<string, string> | undefined = exportCSVDataTemp2.find(
                    (data: Record<string, string>) => Object.prototype.hasOwnProperty.call(data, fieldName)
                );
                const secondValue: string = matchingData1 ? matchingData1[fieldName] : "";
                fieldObj[fieldNames[1]] = secondValue;
            }
            fieldData.push(fieldObj);
        });

        fieldData.forEach((fieldObj) => {
            const fieldValues: string[] = Object.values(fieldObj);
            const csvRow: string = fieldValues.map((value) => `"${value}"`).join(",");
            csvData.push(csvRow);
        });
    }
    return csvData;
};

export const generateHeader = (
    header: IHeaderkeys,
    currentRowData: Record<string, string>,
    searchCriteria: Record<string, string>
): string => {
    // Get the combinedFields data
    const combinedFields: { field: string; replacer: string }[] | undefined = header.combinedFields;

    // Generate the Final header
    let finalHeader: string = header.label;

    // Replacing # with currentRowData or searchCriteria data
    if (combinedFields) {
        combinedFields.forEach((item: { field: string; replacer: string }) => {
            if (Object.prototype.hasOwnProperty.call(currentRowData, item.field)) {
                finalHeader = finalHeader.replace(item.replacer, " " + currentRowData[item.field]);
            } else if (Object.prototype.hasOwnProperty.call(searchCriteria, item.field)) {
                const output: string = isValueDateType(searchCriteria[item.field])
                    ? customDateValueGetterV2(searchCriteria[item.field])
                    : searchCriteria[item.field];

                finalHeader = finalHeader.replace(item.replacer, " " + output);
            }
        });
    }

    return finalHeader;
};

// Final CSV Download
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generateCSV = (filename: string, csvContent: string) => {
    const link: HTMLAnchorElement = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvContent));
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
