/* author: JESCHKE Moritz */

import { useAppSelector } from "application/redux/Hooks";
import { RootState } from "application/redux/Store";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const donationDeclarationColumns: ITableColumn[] = [
    { field: "requestType", title: "Auftragsart" },
    {
        field: "clientData.identification",
        title: "Mandant",
        combinedFields: ["clientData.name"],
        combinedFieldsSeparator: "/",
    },
    { field: "fromDate", title: "Zeitraum von", type: "date" },
    { field: "toDate", title: "Zeitraum bis", type: "date" },
    { field: "source", title: "Quelle" },
    { field: "extRequestId", title: "Externe Referenz" },
    { field: "execState", title: "Auftragsstatus" },
    { field: "reportDeclarationData.execState", title: "Meldestatus Gesamtmeldung" },
    { field: "reportDeclarationData.declarationNumber", title: "Lfd. Nummer Meldung" },
];

export const DonationDeclarationSearchMask = (): React.JSX.Element => {
    const clientId: string = useAppSelector((state: RootState) => state.user.clientId);

    const searchCriterias: ISearchCriteria[] = [
        {
            field: "clientId",
            label: "Mandant",
            type: "dropDown",
            keytable: { tabname: "CLIENT", allOption: true },
            defaultValue: clientId,
        },
        { field: "extractYear", label: "Berichtsjahr", freeInputRestrigtion: ["NUMBERS"], fieldLengthLimit: 4 },
        {
            field: "",
            label: "Erfassungszeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "fromDate", discriminator: "from" },
                    to: { label: "bis", field: "toDate", discriminator: "to" },
                },
            },
        },
        { field: "extRequestId", label: "Externe Referenz" },
    ];

    const searchUrl: ISearchUrl[] = [{ path: "/request/donationDeclaration/search" }];

    return (
        <Search
            title={"Schenkungsmeldungsaufträge suchen"}
            searchCriterias={searchCriterias}
            columns={donationDeclarationColumns}
            identifier={"donationDeclarationSearch"}
            searchUrls={searchUrl}
            detailsIdentifier="id"
        />
    );
};
