/* author: JESCHKE Moritz */

import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";
import { ITableColumnSort } from "components/Table";
import cloneDeep from "lodash/cloneDeep";

interface ISetUserConfiguration {
    payload: {
        userConfig: Record<string, string>;
    };
    type: string;
}

//edit sort and hide
interface ISetTableSorting {
    payload: {
        identifier: TableidentifierType;
        sorting: ITableColumnSort;
    };
    type: string;
}

interface ITableHide {
    payload: {
        indentifier: TableidentifierType;
        columnId: number;
    };
    type: string;
}

interface IAddHide {
    payload: {
        hide: string;
    };
    type: string;
}

interface IState {
    tables: Record<
        string,
        {
            sorting: ITableColumnSort;
            hidden: number[];
        }
    >;
    dashboard: {
        hide: string[];
    };
}

const initialState: IState = {
    tables: {},
    dashboard: {
        hide: [],
    },
};

const DEFAULT_TABLE: {
    sorting: ITableColumnSort;
    hidden: number[];
} = { sorting: { columnId: -1, direction: "asc" }, hidden: [] };

export type TableidentifierType =
    | "tcg"
    | "tft"
    | "amounts"
    | "taxAccounting"
    | "vap"
    | "taxBase"
    | "specu"
    | "secIncome"
    | "secIncVap"
    | "secCorpAction"
    | "secSwitching"
    | "getAccountsByFiscalUnitId"
    | "yer"
    | "tedExchange"
    | "tedRepExchange"
    | "depotIncome"
    | "taxBaseNC"
    | "taxResult"
    | "incomeType"
    | "sparTrans"
    | "dataShare"
    | "tftTrans"
    | "tcgTrans"
    | "failedIncomeTrans"
    | "errorDetails"
    | "failedSecurityTrans"
    | "failedDerivateTrans"
    | "derTrans"
    | "personSearch"
    | "otherProcessingSearch"
    | "securitySearch"
    | "derivateSearch"
    | "secReconDataSearch"
    | "yerSingleRequestSearch"
    | "yerMassRequestSearch"
    | "fsakRequestSearchMask"
    | "yerSingleRequestSearchMask"
    | "yerMassRequestSearchMask"
    | "fsaNvSbCalculationpots"
    | "incomeTranSearch"
    | "securityTransactionSearch"
    | "donationDataSearch"
    | "donationDataForDonor"
    | "donationDataForDonee"
    | "personDetailsForDonorDonee"
    | "depotExchangeSearch"
    | "portfolioTrasnferDetails"
    | "rucksackData"
    | "rucksack"
    | "fiscalUnitSearch"
    | "vapValueAdjDetails"
    | "SalesDetails"
    | "exportEventHistoryReportSearch"
    | "searchTaxAtFlatRateSecurity"
    | "searchTaxAtFlatRateDerivative"
    | "accountBySearch"
    | "valueAdjDetails"
    | "specuDetails"
    | "personVersions"
    | "addressVersions"
    | "kistamVersions"
    | "nvVersions"
    | "fuVersions"
    | "fsaDataVersions"
    | "editPendingOverviewPerson"
    | "editPendingOverviewAccount"
    | "editPendingOverviewFU"
    | "editPendingOverviewSecTran"
    | "editPendingOverviewSecurity"
    | "editPendingOverviewDerTran"
    | "editPendingOverviewIncomeTran"
    | "accountHistory"
    | "accountHistory"
    | "fsaDataHistory"
    | "fsaLimitHistory"
    | "nvDataHistory"
    | "fuHistory"
    | "personHistory"
    | "kistamHistory"
    | "addressHistory"
    | "fsaLimit"
    | "flows"
    | "flowSteps"
    | "incomeSection"
    | "donationDeclarationSearch"
    | "donationDeclarationReportSearch"
    | "details"
    | "inventorySearch"
    | "editPendingOverviewTed"
    | "editPendingOverviewSingleRequest"
    | "editPendingOverviewMassRequest"
    | "taxSearch"
    | "manageAdditionalFields"
    | "donationDataHistory"
    | "donationDelcarationStatus"
    | "editPendingFkbRequest"
    | "fkbRequestSearchMask";

export const UserConfigurationSlice = createSlice({
    name: "userConfiguration",
    initialState: initialState,
    reducers: {
        //set the whole userconfig at once
        setUserConfiguration: (_state, action: ISetUserConfiguration) => {
            if (
                JSON.stringify(action.payload.userConfig) !== JSON.stringify("") &&
                JSON.stringify(action.payload.userConfig) !== JSON.stringify({})
            ) {
                //User config exits -> use this one
                return JSON.parse(action.payload.userConfig.backingStore);
            }
        },
        //set sorting for identifier
        setTableSorting: (state, action: ISetTableSorting) => {
            if (!state.tables[action.payload.identifier]) {
                state.tables[action.payload.identifier] = cloneDeep(DEFAULT_TABLE);
            }
            state.tables[action.payload.identifier].sorting = action.payload.sorting;
        },
        //add column to hide array for identifier
        addTableHide: (state, action: ITableHide) => {
            if (!state.tables[action.payload.indentifier]) {
                state.tables[action.payload.indentifier] = cloneDeep(DEFAULT_TABLE);
            }
            //check if value is already in the list before adding it
            if (!state.tables[action.payload.indentifier].hidden.includes(action.payload.columnId)) {
                state.tables[action.payload.indentifier].hidden.push(action.payload.columnId);
            }
        },
        //remove column from hide array for identifier
        removeTableHide: (state, action: ITableHide) => {
            state.tables[action.payload.indentifier].hidden = state.tables[action.payload.indentifier].hidden.filter(
                (columnId: number) => columnId !== action.payload.columnId
            );
        },
        //add a string to the hide array
        addHide: (state, action: IAddHide) => {
            return {
                ...state,
                dashboard: { hide: [...state.dashboard.hide, action.payload.hide] },
            };
        },
        userConfigClearAll: () => {
            return initialState;
        },
    },
});

export const { setUserConfiguration, setTableSorting, addTableHide, removeTableHide, addHide, userConfigClearAll } =
    UserConfigurationSlice.actions;
export const userConfigurationSliceReducer: Reducer<IState, AnyAction> = UserConfigurationSlice.reducer;
