/* author: PAL Prashant */
import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";

interface IFinancialInstrument {
    id: string;
    categoryFlag: string;
    isin: string;
    shortName: string;
    longName: string;
}
interface ISecurity extends IFinancialInstrument {
    wkn: string;
    gd198B: string;
}
interface ISetSecurityData {
    payload: ISecurity;
    type: string;
}
const initialState: ISecurity = {
    categoryFlag: "",
    wkn: "",
    id: "",
    isin: "",
    shortName: "",
    longName: "",
    gd198B: "",
};

const FinancialInstrumentSlice = createSlice({
    name: "financialInstrument",
    initialState: initialState,
    reducers: {
        setSecurityData: (state, action: ISetSecurityData) => {
            return {
                categoryFlag: action.payload.categoryFlag,
                wkn: action.payload.wkn,
                id: action.payload.id,
                isin: action.payload.isin,
                shortName: action.payload.shortName,
                longName: action.payload.longName,
                gd198B: action.payload.gd198B,
            };
        },
    },
});

export const { setSecurityData } = FinancialInstrumentSlice.actions;
export const securitySliceReducer: Reducer<ISecurity, AnyAction> = FinancialInstrumentSlice.reducer;
