/* author: PAL Prashant */

import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn } from "components/Table";
import { VersionsView } from "components/VersionsView";
import { FU_SECTION } from "pages/fiscal-unit-data/FuOverviewEdit";

interface IFuVersions {
    fiscalUnitId: number;
}

export const FuVersions = (props: IFuVersions): React.JSX.Element => {
    const columns: ITableColumn[] = [
        {
            field: "fiscalUnit",
            title: "Steuerliche Einheit",
        },
        {
            field: "fiscalUnitType",
            title: "Typ der Steuerlichen Einheit",
        },
        {
            field: "legalFormCategory",
            title: "Rechtsform",
        },
        {
            field: "customerSegment",
            title: "Kundengruppe",
        },
        {
            field: "communityFlag",
            title: "Personengemeinschaftsflag",
        },
        {
            field: "churchTaxRetention",
            title: "Kirchensteuerabgeltung",
        },
        { field: "validFrom", title: "Gültig von", type: "date" },
        { field: "validTo", title: "Gültig bis", type: "date" },
    ];

    const IDENTIFIER: TableidentifierType = "fuVersions";

    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/fiscalUnit/fuVersionData-all-timeslices",
        params: { fiscalUnitId: props.fiscalUnitId },
    };

    return (
        <VersionsView
            columnsDefinition={columns}
            identifier={IDENTIFIER}
            title="Übersicht steuerliche Einheiten"
            dataRequest={dataRequest}
            historyPath="fuHistory"
            editSection={FU_SECTION}
        />
    );
};
