/* author: PAL Prashant */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";

export const FU_SECTION: string = "Allgemeine Informationen";

export const FuOverviewEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const fiscalUnitId: number = Number(params.fiscalUnitId);
    const section: string | undefined = params.section;
    const versionId: number = Number(params.versionId);

    const fuEditInfo: IEditDataViewUserConfiguration = {
        headline: FU_SECTION,
        attributes: [
            {
                field: "fiscalUnit",
                headerName: "Steuerliche Einheit",
                displayOnly: true,
            },
            {
                field: "fiscalUnitType",
                headerName: "Typ der Steuerlichen Einheit",
                dropdown: {
                    keyTableTabname: "FISCALUNITTYPE",
                },
            },
            {
                field: "legalFormCategory",
                headerName: "Rechtsform",
                dropdown: {
                    keyTableTabname: "LEGALFORM",
                },
            },
            {
                field: "customerSegment",
                headerName: "Kundengruppe",
                rules: {
                    maxLength: {
                        value: "4",
                    },
                },
            },
            {
                field: "communityFlag",
                headerName: "Personengemeinschaftsflag",
                dropdown: {
                    keyTableTabname: "COMMUNITYFLAG",
                },
            },
            {
                field: "churchTaxRetention",
                headerName: "Kirchensteuerabgeltung",
                dropdown: {
                    keyTableTabname: "CHURCHTAXRETENTION",
                },
            },
            { field: "validFrom", headerName: "Gültig von", type: "date" },
            { field: "validTo", headerName: "Gültig bis", type: "date" },
        ],
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/fiscalUnit/fuVersionDataForEdit",
                params: { fiscalUnitId: fiscalUnitId },
            }),

        versionRequest: () =>
            sendGetRequest({
                path: "/customerstaticdata/fiscalUnit/fuVersionDataForEdit",
                params: { versionId: versionId },
            }),
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [fuEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={fiscalUnitId}
            title="Steuerliche Einheit"
            section={section}
        />
    );
};
