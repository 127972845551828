import { Divider } from "@mui/material";
import { ButtonPrimary, ButtonSecondary, ButtonType } from "./Button";
import { Colors } from "./Colors";

export interface IFooter {
    buttons: IFooterButton[];
}

export interface IFooterButton {
    title: string;
    filled: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    hidden?: boolean;
    type?: ButtonType;
}

export const Footer = (props: IFooter): React.JSX.Element => {
    return (
        <div style={{ position: "fixed", bottom: "0px", right: "0px", background: Colors.white, zIndex: "998" }}>
            <Divider sx={{ width: "100vw", background: Colors.blue, height: "0.375rem" }} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "4rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                {props.buttons.map((button: IFooterButton) => {
                    if (button.hidden) return null;

                    const customProps = {
                        key: button.title,
                        title: button.title,
                        onClick: button.onClick,
                        disabled: button.disabled,
                        type: button.type,
                    };

                    return button.filled ? <ButtonPrimary {...customProps} /> : <ButtonSecondary {...customProps} />;
                })}
            </div>
        </div>
    );
};
