/* author: Aashish Upadhyay */

import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn } from "components/Table";
import { VersionsView } from "components/VersionsView";
import { NV_DATA_IDENTIFIER, NV_SECTION } from "./NvDataEdit";

interface INvVersions {
    fsaNvId: number;
}

export const NvVersions = (props: INvVersions): React.JSX.Element => {
    const IDENTIFIER: TableidentifierType = "nvVersions";

    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/fsaNv/nvData-all-timeslices",
        params: { fsaNvId: props.fsaNvId },
    };

    const columns: ITableColumn[] = [
        { field: "validFrom", title: "Gültig vom", type: "date" },
        { field: "validTo", title: "Gültig bis", type: "date" },
        { field: "nvNr", title: "NV-Bescheinigungsnummer" },
        { field: "nvType", title: "NV-Typ" },
        { field: "deleteFlag", title: "Loeschkennzeichen" },
        { field: "lastNamePerson1", title: "Nachname Person1" },
        { field: "givenNamePerson1", title: "Vorname Person1" },
        { field: "birthDatePerson1", title: "Geburtsdatum Person1", type: "date" },
        { field: "tinPerson1", title: "TIN Person1" },
        { field: "birthNamePerson1", title: "Geburtsname Person1" },
        { field: "lastNamePerson2", title: "Nachname Person2" },
        { field: "givenNamePerson2", title: "Vorname Person2" },
        { field: "birthDatePerson2", title: "Geburtsdatum Person2", type: "date" },
        { field: "tinPerson2", title: "TIN Person2" },
        { field: "birthNamePerson2", title: "Geburtsname Person2" },
        { field: "taxOffice", title: "NV-Finanzamt" },
        { field: "issueDate", title: "NV-Ausstellungsdatum", type: "date" },
        { field: "execState", title: "Ausführungsstatus" },
    ];

    return (
        <VersionsView
            columnsDefinition={columns}
            identifier={IDENTIFIER}
            title="Nichtveranlagungsbescheinigung (NV) Zeitscheiben"
            dataRequest={dataRequest}
            historyPath="nvDataHistory"
            editSection={NV_SECTION}
            editIdentifier={NV_DATA_IDENTIFIER}
            addFunction
            data={{ fsaNvId: String(props.fsaNvId) }}
        />
    );
};
