/* author: JESCHKE Moritz */

import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn } from "components/Table";
import { VersionsView } from "components/VersionsView";
import { ADDRESS_SECTION } from "./PersonOverviewEdit";

interface IAddressVersions {
    personId: number;
    type: string;
}

export const AddressVersions = (props: IAddressVersions): React.JSX.Element => {
    const IDENTIFIER: TableidentifierType = "addressVersions";

    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/address/with-all-timeslices",
        params: { personId: props.personId, type: props.type },
    };

    const columns: ITableColumn[] = [
        { field: "addressType", title: "Art der Adresse" },
        { field: "street1", title: "Strasse" },
        { field: "houseNumber", title: "Hausnummer" },
        { field: "zipCode", title: "PLZ" },
        { field: "town", title: "Ort" },
        { field: "country", title: "Land" },
        { field: "poBox", title: "Postfach" },
        { field: "telephone", title: "Telefon" },
        { field: "fax", title: "Fax" },
        { field: "validFrom", title: "Gültig von", type: "date" },
        { field: "validTo", title: "Gültig bis", type: "date" },
    ];

    return (
        <VersionsView
            columnsDefinition={columns}
            identifier={IDENTIFIER}
            title="Adressen Zeitscheiben"
            dataRequest={dataRequest}
            historyPath="addressHistory"
            editSection={ADDRESS_SECTION}
            addFunction
        />
    );
};
