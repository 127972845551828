/* author:SindhuKannan */

import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { useState } from "react";
import { ErrorDetails } from "./ErrorDetails";
import { isSearchCriteriaTextEmpty } from "./FailedIncomeTransactions";
import { KeyTableConsts } from "components/KeyTableConsts";
import { secTranColumns } from "pages/transaction-data/SecurityTransactionSearchMask";
import { TransactionContextMenu } from "pages/transaction-data/TransactionContextMenu";
import { SECURITY_TAB } from "pages/transaction-data/TransactionOverview";
import { ITableContextMenu } from "components/Table";

export const FailedSecurityTransactions = (): React.JSX.Element => {
    const [impId, setImpId] = useState<number>(-1);

    const searchCriterias: ISearchCriteria[] = [
        { field: "branch", label: "Filialnummer" },
        { field: "accountNr", label: "Kontonummer*" },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
        },
        { field: "wkn", label: "WKN*" },
        { field: "isin", label: "ISIN*" },
        {
            field: "",
            label: "Ausführungszeitpunkt",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "executionTimeFrom", discriminator: "from" },
                    to: { label: "bis", field: "executionTimeTo", discriminator: "to" },
                },
            },
        },
        {
            field: "taxEvent1",
            label: "Steuerliche Geschäftsart von",
            type: "dropDown",
            comboBoxLowestFieldName: "taxEvent2",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TCG },
        },
        {
            field: "taxEvent2",
            label: "Steuerliche Geschäftsart bis",
            type: "dropDown",
            comboBoxHighestFieldName: "taxEvent1",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TCG },
        },
    ];
    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/cgtransactions/getErroneousSecTransactions" }];
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});
    return (
        <div id="tableWrapper" style={{ margin: "4rem" }}>
            <Search
                title="Suche - Fehlerhafte Wertpapier Umsätze"
                searchCriterias={searchCriterias}
                columns={secTranColumns}
                identifier="failedIncomeTrans"
                searchUrls={searchUrls}
                details={{
                    setIdForDetail: setImpId,
                    detailIdentifier: "impId",
                }}
                customSearchButtonDisable={(searchCriteriaText: Record<string, string>) => {
                    return isSearchCriteriaTextEmpty(searchCriteriaText);
                }}
                isExportable={true}
                checkPairFields={[["taxEvent1", "taxEvent2"]]}
                contextMenu={{
                    element: (
                        <TransactionContextMenu
                            contextMenus={contextMenus}
                            setContextmenus={setContextMenus}
                            currentTabText={SECURITY_TAB}
                        />
                    ),
                    tableElement: contextMenus[SECURITY_TAB],
                }}
            />

            <div id="errorDetailList">
                <ErrorDetails origin="cgt" impId={impId} />
            </div>
        </div>
    );
};
