/* author: JESCHKE Moritz */

import { Card, CardContent, Typography } from "@mui/material";
import { CardIcon } from "./CardIcon";
import { Colors } from "./Colors";
import {
    convert,
    ConvertType,
    createKey,
    formatString,
    getChangedOrOriginalValue,
    hasChangedValues,
} from "./Converter";
import { cloneElement } from "react";
import { IOverviewViewStackAdder } from "./Overview";

export interface IOverviewCard extends IOverviewViewStackAdder, ICard {
    data: Record<string, string>;
    icon?: React.JSX.Element;
    content: ICardAttribute[][];
    detailViewHeadline: string;
}
export interface ICard {
    idFields: string[];
    typeField: string;
    orientation: "horizontal" | "vertical" | "keyValuePair";
    detailView?: (ids: string[], type: string | undefined) => React.JSX.Element;
    onClick?: (ids: string[], type: string | undefined) => void;
    disabled?: (type: string | undefined) => boolean;
}
export interface ICardAttribute {
    field: string;
    type?: ConvertType;
    combine?: {
        mapping: {
            [index: string]: {
                combinedFields: ICombineField[];
                combineFormat: string;
            };
        };
    };
    //TODO :: check if still necessary
    isBold?: boolean;
    haveSpaceBetween?: boolean;
    labels?: {
        mapping: { [index: string]: string };
    };
    alignRight?: boolean;
}

interface ICombineField {
    field: string;
    type?: ConvertType;
}

export const OverviewCard = (props: IOverviewCard): React.JSX.Element => {
    let strParts: string[] = [];

    const createLabel = (attribute: ICardAttribute): string => {
        //create label
        let label: string | undefined =
            attribute.labels?.mapping[getChangedOrOriginalValue(props.data, props.typeField)];
        if (label === undefined) {
            //if label dosn't match any type then the default value is used
            label = attribute.labels?.mapping["DEFAULT"];
            if (attribute.labels?.mapping && label === undefined) {
                console.error("NO DEFAULT LABEL GIVEN\n", attribute.labels?.mapping);
            }
        }
        return label ? label : "";
    };

    const createText = (attribute: ICardAttribute): string => {
        //reset strParts
        strParts = [];

        let combinedAttributes: string = "";
        let attributes = attribute.combine?.mapping[getChangedOrOriginalValue(props.data, props.typeField)];
        if (attributes === undefined) {
            //if attributes dosn't match any type then the default value is used
            attributes = attribute.combine?.mapping["DEFAULT"];
            if (attribute.combine?.mapping && attributes === undefined) {
                console.error("NO DEFAULT LABEL GIVEN\n", attribute.combine?.mapping);
            }
        }

        //put main attribute in strParts array at first position
        strParts.push(convert(getChangedOrOriginalValue(props.data, attribute.field), attribute.type));

        // Get strParts which will be placed into the format string
        if (attributes) {
            attributes.combinedFields.forEach((combineAttribute: ICombineField) => {
                strParts.push(
                    convert(getChangedOrOriginalValue(props.data, combineAttribute.field), combineAttribute.type)
                );
            });
        }
        // replace placeholders with strParts in format string
        combinedAttributes = attributes?.combineFormat ? formatString(attributes.combineFormat, strParts) : strParts[0];
        return combinedAttributes;
    };

    const checkForDisable = (): boolean => {
        return props.disabled ? props.disabled(getChangedOrOriginalValue(props.data, props.typeField)) : false;
    };

    const getCardContent = (): React.JSX.Element | React.JSX.Element[] => {
        if (props.orientation === "vertical") {
            return (
                <div>
                    {props.content.map((line: ICardAttribute[]) => {
                        return (
                            <div
                                key={createKey("overviewCardLine", line[0].field)}
                                style={{
                                    display: "flex",
                                    justifyContent: line[0].haveSpaceBetween ? "space-between" : "initial",
                                    width: "100%",
                                }}
                            >
                                {line.map((attribute: ICardAttribute) => {
                                    const label: string = createLabel(attribute);
                                    const combinedAttributes: string = createText(attribute);
                                    return (
                                        <div
                                            key={createKey("overviewCardAttributeVertical", attribute.field)}
                                            style={{
                                                display: "flex",
                                                width: attribute.alignRight ? "100%" : "initial",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: attribute.isBold ? "bold" : "normal",
                                                    fontSize: "0.875rem",
                                                    marginLeft: attribute.alignRight ? "auto" : "initial",
                                                }}
                                            >
                                                {label ? label + ": " : ""}
                                            </Typography>
                                            <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                                                {combinedAttributes}
                                                {" "}
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            );
        }

        if (props.orientation === "horizontal") {
            return props.content[0].map((attribute: ICardAttribute) => {
                const heading: string = createLabel(attribute);
                const combinedAttributes: string = createText(attribute);

                return (
                    <div key={createKey("overviewCardAttributeHorizontal", attribute.field)}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "0.875rem" }}>{heading}</Typography>
                        <Typography sx={{ fontSize: "0.875rem", whiteSpace: "pre-line" }}>
                            {combinedAttributes}{" "}
                        </Typography>
                    </div>
                );
            });
        }

        //default case
        return (
            <table>
                <tbody>
                    {props.content[0].map((attribute: ICardAttribute) => {
                        const label: string = createLabel(attribute);
                        const combinedAttributes: string = createText(attribute);
                        return (
                            <tr key={createKey("XXX", attribute.field)}>
                                <td>
                                    <Typography
                                        sx={{
                                            fontWeight: attribute.isBold ? "bold" : "normal",
                                            fontSize: "0.875rem",
                                            marginLeft: attribute.alignRight ? "auto" : "initial",
                                            marginRight: "1rem",
                                        }}
                                    >
                                        {label ? label + ": " : ""}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography noWrap sx={{ fontSize: "0.875rem" }}>
                                        {combinedAttributes}
                                        {" "}
                                    </Typography>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <Card
            sx={{
                background: checkForDisable() ? Colors.darkGrey : Colors.grey,
                color: checkForDisable() ? Colors.disabledGrey : "initial",
                marginBottom: "1rem",
                cursor: checkForDisable() ? "default" : "pointer",
                width: "100%",
                "& .MuiCardContent-root:last-child": {
                    padding: "1rem",
                },
                border: hasChangedValues(props.data) ? "2px solid " + Colors.lightGreen : "initial",
            }}
            onClick={() => {
                if (checkForDisable()) return;

                //get the id from each idField
                const ids: string[] = [];
                props.idFields.forEach((idField: string) => {
                    ids.push(getChangedOrOriginalValue(props.data, idField));
                });
                if (props.detailView) {
                    let detailPage = props.detailView(ids, getChangedOrOriginalValue(props.data, props.typeField));

                    if (props.icon && detailPage.props.icon === null) {
                        //override icon to props in detail page
                        detailPage = cloneElement(detailPage, { icon: props.icon });
                    }

                    props.viewStackAdd && props.viewStackAdd(detailPage, props.detailViewHeadline);
                }
                if (props.onClick) {
                    props.onClick(ids, getChangedOrOriginalValue(props.data, props.typeField));
                }
            }}
        >
            <CardContent
                sx={{
                    display: "flex",
                    alignItems: "stretch",
                    gap: "1rem",
                }}
            >
                {props.icon !== undefined && <CardIcon icon={props.icon} disabled={checkForDisable()} />}
                {getCardContent()}
            </CardContent>
        </Card>
    );
};
