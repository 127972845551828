import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";

interface IDatePickerErrorState {
    errMsg: Record<string, string>;
}
interface IDatePickerData {
    payload: { field: string };
    type: string;
}
interface IDatePickerErrorData extends IDatePickerData {
    payload: { field: string; errMsg: string };
}
const initialState: IDatePickerErrorState = {
    errMsg: {},
};

const DatePickerErrorSlice = createSlice({
    name: "datepicker",
    initialState: initialState,
    reducers: {
        setDatePickerErrorMsg: (state, action: IDatePickerErrorData) => {
            state.errMsg[action.payload.field] = action.payload.errMsg;
        },
        clearDatePickerErrorMsg: (state, action: IDatePickerData) => {
            delete state.errMsg[action.payload.field];
        },
    },
});

export const { setDatePickerErrorMsg: setDatePickerErrorMsg, clearDatePickerErrorMsg: clearDatePickerErrorMsg } =
    DatePickerErrorSlice.actions;
export const datePickerErrorMsgReducer: Reducer<IDatePickerErrorState, AnyAction> = DatePickerErrorSlice.reducer;
