/* author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params, useLocation, useParams, Location } from "react-router";

const TedEdit =
    (tedSection: string, inoutType: "IN" | "OUT") =>
    // eslint-disable-next-line react/display-name
    (): React.JSX.Element => {
        const params: Readonly<Params<string>> = useParams();
        const tedExchangeId: number = Number(params.tedExchangeId === "new" ? -1 : params.tedExchangeId);
        const section: string | undefined = params.section;

        const removeFieldsForCalculationPots: boolean = tedSection === TED_SECTION_CALCULATION_POT;

        const REQUEST = () =>
            sendGetRequest({
                path: "/ted/exchange/byId-with-keydata",
                params: { tedExchangeId: tedExchangeId, isNew: isNew },
            });

        const location: Location = useLocation();
        const rowData: Record<string, string> = location.state.rowData;
        const isNew: boolean = location.state && location.state.isNew;

        const allowOnlyEditTedStatus = (): boolean =>
            rowData && ["Storniert", "Verarbeitet", "Manuell verschickt", "Ausgeliefert"].includes(rowData["status"]);

        const fuEditInfo: IEditDataViewUserConfiguration = {
            headline: tedSection,
            attributes: [
                {
                    field: "extTedKey",
                    headerName: "Taxbox-Client Ref.",
                    displayOnly: true,
                    hide: removeFieldsForCalculationPots,
                },
                {
                    field: "extTranKey",
                    headerName: "Umsatzreferenz",
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                { field: "msgref", headerName: "Message Referenz", displayOnly: true },
                { field: "cbfclientacc", headerName: "CBF Konto", disabled: allowOnlyEditTedStatus() },
                {
                    field: "msgfnc",
                    headerName: "Message Function",
                    dropdown: { keyTableTabname: "CBFMSGFNC" },
                    displayOnly: true,
                },
                {
                    field: "creationDate",
                    headerName: "Übertragungsdatum",
                    type: "date",
                    displayOnly: true,
                },
                {
                    field: "taxtp",
                    headerName: "Satzart",
                    dropdown: { keyTableTabname: removeFieldsForCalculationPots ? "CBFTAXTYPE" : "CBFTAXTYPE_DEPOT" },
                    displayOnly: window.location.href.includes("/new") ? false : !removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                { field: "taxid", headerName: "Taxbox ID", displayOnly: true },
                { field: "prevtaxid", headerName: "Previous Taxbox ID", displayOnly: true },
                {
                    field: "rltdRef",
                    headerName: "Settlement Referenz",
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "issuerID",
                    headerName: "Auftraggeber BIC/BLZ",
                    dropdown: { keyTableTabname: "CBFIDTYPE" },
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "issuerAcctsvcr",
                    headerName: "Auftraggeber Depotstelle (Bank/KAG)",
                    disabled: allowOnlyEditTedStatus(),
                },
                { field: "issuerAcctkey", headerName: "Auftraggeber Depotnummer", disabled: allowOnlyEditTedStatus() },
                { field: "issuerAcctnm", headerName: "Auftraggeber Name", disabled: allowOnlyEditTedStatus() },
                { field: "issuerTin", headerName: "Steuer Id. Nr.", disabled: allowOnlyEditTedStatus() },
                {
                    field: "benefID",
                    headerName: "Empfänger BIC/BLZ",
                    dropdown: { keyTableTabname: "CBFIDTYPE" },
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "benefAcctsvcr",
                    headerName: "Empfänger Depotstelle (Bank/KAG)",
                    disabled: allowOnlyEditTedStatus(),
                },
                { field: "benefAcctkey", headerName: "Empfänger Depotnummer", disabled: allowOnlyEditTedStatus() },
                { field: "benefAcctnm", headerName: "Empfänger Name", disabled: allowOnlyEditTedStatus() },
                {
                    field: "assettp",
                    headerName: "Asset Type",
                    dropdown: { keyTableTabname: "CBFASSETTYPE" },
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "isin",
                    headerName: "ISIN",
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "nm",
                    headerName: "Bezeichnung des Wertpapiers",
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "trnstp",
                    headerName: "Art des Übertrages",
                    dropdown: { keyTableTabname: "CBFTRNSTYPE" },
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "subrogation",
                    headerName: "Gläubigerwechsel",
                    dropdown: { keyTableTabname: "SUBROGATIVE_TED" },
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "transSpecType",
                    headerName: "Besonderheiten Übertrag",
                    dropdown: { keyTableTabname: "CBFTRANSSPECTYPE" },
                    disabled: allowOnlyEditTedStatus(),
                    hide: removeFieldsForCalculationPots,
                },
                {
                    field: "trnsnom",
                    headerName: "Gesamtübertragsmenge",
                    disabled: allowOnlyEditTedStatus(),
                    hide: removeFieldsForCalculationPots,
                },
                {
                    field: "trnsunit",
                    headerName: "Übertragseinheit",
                    dropdown: { keyTableTabname: "TB_WMW02" },
                    hide: removeFieldsForCalculationPots,
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "repcnt",
                    headerName: "Wiederholsequenzen",
                    displayOnly: true,
                    hide: removeFieldsForCalculationPots,
                },
                {
                    field: "rjctdrsn",
                    headerName: "Grund der Abweisung",
                    displayOnly: true,
                },
                {
                    field: "rjctdaddtlinf",
                    headerName: "Abweisungsinfo",
                    displayOnly: true,
                },
                {
                    field: "status",
                    headerName: "Taxbox-Status",
                    dropdown: { keyTableTabname: "TED_STATUS" },
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "statusDtlKey",
                    headerName: "Detail-Schlüssel Taxbox-Verarbeitungsstatus",
                    displayOnly: true,
                },
                {
                    field: "depTranCentre",
                    headerName: "Taxbox Kennzeichen",
                    dropdown: { keyTableTabname: "TED_TAXBOX" },
                    displayOnly: true,
                },
                { field: "branch", headerName: "Filiale", displayOnly: true },
                { field: "comment", headerName: "Taxbox-Kommentar" },
                {
                    field: "cancellationFlag",
                    headerName: "Storno-Flag",
                    displayOnly: true,
                    dropdown: {
                        keyTableTabname: "STORNOFLAG",
                    },
                },
                {
                    field: "setoffPotDate",
                    headerName: "Gültigkeitszeitpunkt",
                    type: "date",
                    disabled: allowOnlyEditTedStatus(),
                },
                {
                    field: "accountTransferType",
                    headerName: "int/ex",
                    dropdown: { keyTableTabname: "ACCOUNT_TRANSFER_TP" },
                    displayOnly: true,
                },
            ],
            latestVersionRequest: REQUEST,
            versionRequest: REQUEST,
            checkInvalidity: [
                {
                    condition: (data: Record<string, unknown>) => data["taxtp"] === undefined || data["taxtp"] === "",
                    relatedFields: ["taxtp"],
                },
            ],
        };

        const completeEditInfo: IEditDataViewUserConfiguration[] = [fuEditInfo];

        return (
            <EditMask
                completeEditInfo={completeEditInfo}
                id01={tedExchangeId}
                title="Taxbox"
                section={section}
                overrideEditValues={
                    inoutType === "IN"
                        ? [
                              { field: "msgtype", overrideValue: "INBMSG" },
                              {
                                  field: "recid",
                                  overrideValue: "BIC",
                              },
                              { field: "reckey", overrideValue: "1" },
                              { field: "sndid", overrideValue: "BIC" },
                              { field: "sndkey", overrideValue: "1" },
                              { field: "msgref", overrideValue: "1" },
                              { field: "contid", overrideValue: "ONLY" },
                              { field: "objno", overrideValue: "1" },
                          ]
                        : [
                              { field: "msgtype", overrideValue: "OUTREQ" },
                              {
                                  field: "cbfclientacc",
                                  overrideValue: "0",
                              },
                              { field: "objno", overrideValue: "0" },
                              { field: "tstind", overrideValue: " " },
                              { field: "seqno", overrideValue: "0" },
                              { field: "taxid", overrideValue: "0" },
                          ]
                }
            />
        );
    };

export const TED_SECTION_DEPOT_EXCHANGE: string = "Depotüberträge";
export const TedEditDepotExchangeIn = TedEdit(TED_SECTION_DEPOT_EXCHANGE, "IN");
export const TedEditDepotExchangeOut = TedEdit(TED_SECTION_DEPOT_EXCHANGE, "OUT");

export const TED_SECTION_CALCULATION_POT: string = "Verrechnungstöpfe";
export const TedEditCalculationPotIn = TedEdit(TED_SECTION_CALCULATION_POT, "IN");
export const TedEditCalculationPotOut = TedEdit(TED_SECTION_CALCULATION_POT, "OUT");
