/* author: PAL Prashant */
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const SecuritySearchMask = (): React.JSX.Element => {
    const title: string = "Wertpapiersuche";

    const columns: ITableColumn[] = [
        { field: "categoryFlag", title: "Kategorie" },
        { field: "wkn", title: "WKN" },
        { field: "isin", title: "ISIN" },
        { field: "derivativeCode", title: "Derivate Code" },
        { field: "longName", title: "Wertpapier Langbezeichnung" },
        { field: "underlying", title: "Underlying ISIN" },
        { field: "gd198B", title: "GD198B" },
    ];

    const searchCriterias: ISearchCriteria[] = [
        { field: "wkn", label: "WKN*" },
        { field: "isin", label: "ISIN*" },
        { field: "derivativeCode", label: "Derivative Code*" },
        { field: "name", label: "Bezeichnung*" },
        { field: "underlying", label: "Underlying (ISIN)*" },
    ];

    const searchUrls: ISearchUrl[] = [
        { path: "/security/securities/search", requiredParams: ["isin", "wkn", "name"] },
        { path: "/security/derivatives/search", requiredParams: ["isin", "derivativeCode", "name", "underlying"] },
    ];

    return (
        <Search
            title={title}
            searchCriterias={searchCriterias}
            columns={columns}
            searchUrls={searchUrls}
            linkto={"/SecurityOverview/"}
            alternativSwitch={{ checkParam: "Derivate", alternativeLinkTo: "/derivatives/" }}
            identifier="securitySearch"
            hideTechnicalColumns
        />
    );
};
