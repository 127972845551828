//TODO :: refactor Popup

/* author:  Umang Baluja */
import { Box, CircularProgress } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { Footer, IFooter } from "components/Footer";

import { IOverviewButton, IOverviewHeadLine, OverviewHeadLine } from "components/OverviewHeadLine";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";

import { IApiUrl, IColumn, TableBasic } from "components/TableBasic";

import { IdSelector } from "components/TBD/IdSelector";
import { getEditState, getEditTo } from "components/VersionsView";

import { useState } from "react";

import { useNavigate } from "react-router";
import { SEC_CORP_ACTION_SECTION } from "./SecCorpActionEdit";
import { SEC_SWITCHING_SECTION } from "./SecSwitchingEdit";

interface ITableForSingleRecord {
    id: number;
    wkn: string;
    isin: string;
    shortname: string;
}

interface IPopupConfiguration {
    url: IApiUrl;
    title: string;
    columns: ITableColumn[];
    IDENTIFIER?: TableidentifierType;
}
interface IButtonWithPopupConfiguration {
    title: string;
    filled: boolean;
    popupConfiguration?: IPopupConfiguration;
}

//TODO :: rework
interface IPopupMetaData {
    id: number;
    title: string;
    columns: ITableColumn[];
    data: Record<string, string>[];
    url?: IApiUrl;
    IDENTIFIER?: TableidentifierType;
}

const TableForSingleRecord = (
    url: IApiUrl,
    id: number,
    wkn: string,
    isin: string,
    shortname: string,
    section: string,
    buttonsWithPopupConfiguration?: IButtonWithPopupConfiguration[]
): React.JSX.Element => {
    //TODO: To be removed, only for Testing purpose
    const [key, setKey] = useState<string>(id.toString());

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [popupMetaData, setPopupMetaData] = useState<IPopupMetaData>({
        id: -1,
        title: "",
        columns: [],
        data: [],
    });

    const columns: IColumn[] = [
        { field: "label", headerName: "Feldbezeichnung", minWidth: 180 },
        { field: "value", headerName: "Feldinhalt", minWidth: 170 },
        { field: "description", headerName: "Langbezeichnung", minWidth: 1200 },
    ];

    const styleAddons = {
        "& .MuiDataGrid-virtualScrollerRenderZone": {
            transform: "none !important",
            position: "sticky !important",
            top: "0",
        },
    };

    const footer: IFooter = {
        buttons: [{ title: "Abbrechen", filled: false, onClick: () => navigate("/home") }],
    };

    //Load all Buttons & setup popupMetadata
    const overviewButtons: IOverviewButton[] = [];
    overviewButtons.push({
        title: "Bearbeiten",
        filled: false,
        onClick: () => {
            navigate(getEditTo(Number(id), section, section), { state: getEditState("U") });
        },
    });
    buttonsWithPopupConfiguration?.map((button: IButtonWithPopupConfiguration) => {
        overviewButtons.push({
            title: button.title,
            filled: button.filled,
            onClick: () => {
                if (button.popupConfiguration) {
                    popupMetaData.id = +key;
                    popupMetaData.title = button.popupConfiguration.title;
                    popupMetaData.columns = button.popupConfiguration.columns;
                    setIsLoading(true);
                    sendGetRequest({
                        path: button.popupConfiguration.url.path,
                        params: { [button.popupConfiguration.url.params]: key.toString() },
                    }).then((response) => {
                        popupMetaData.data = response.data;
                        setPopupMetaData(popupMetaData);
                        setIsLoading(false);
                        setPopupOpen(true);
                    });
                } else {
                    alert("Implementation Pending!");
                }
            },
        });
    });

    const headline: IOverviewHeadLine = {
        title: "Details",
        buttons: overviewButtons,
    };

    return (
        <div>
            <IdSelector label="Id" setId={setKey} />
            <OverviewHeadLine title={headline.title} buttons={headline.buttons} />

            <TableBasic
                columns={columns}
                styleAddons={styleAddons}
                apiUrl={url}
                id={key.toString()}
                combinedIdParams={["label", "description"]}
            />
            <Footer buttons={footer.buttons} />

            {isLoading && (
                <Box sx={{ position: "absolute", left: "50%", top: "50%", zIndex: "10" }}>
                    <CircularProgress />
                </Box>
            )}
            {!isLoading && popupOpen && (
                <PopUp
                    title={
                        popupMetaData.title +
                        " für WKN: " +
                        wkn +
                        " | ISIN: " +
                        isin +
                        " | WP-Kurzbezeichnung: " +
                        shortname
                    }
                    content={
                        <Table
                            columnsDefinition={popupMetaData.columns}
                            identifier={popupMetaData.IDENTIFIER ? popupMetaData.IDENTIFIER : "tcg"}
                            tableData={popupMetaData.data}
                            disableHideColumn={true}
                        />
                    }
                    setOpen={setPopupOpen}
                />
            )}
        </div>
    );
};

export const SecCorpActionDetails = (props: ITableForSingleRecord): React.JSX.Element => {
    const buttons: IButtonWithPopupConfiguration[] = [
        /* {
            title: "Verknüpfungsfelder KV036",
            filled: false,
            // popupConfiguration: {
            //     url: { path: "", params: "" },
            //     title: "KV036",
            //     columns: [],
            //     // IDENTIFIER: "KV036",
            // },
        }, */
        {
            title: "Verknüpfungsfelder KV085",
            filled: false,
            popupConfiguration: {
                url: { path: "/security/secCorpAction/taxes", params: "bidId" },
                title: "KV085",
                columns: [
                    { field: "secAssignment", title: "Gattung / Kurszuordnung" },
                    { field: "rateDate", title: "Kursdatum", type: "date" },
                    { field: "rateDateTyp", title: "Art Kursdatum" },
                    { field: "rate", title: "Kurs Jahresbescheinigung" },
                    { field: "nullMsgFlag", title: "Nullmeldung KZ" },
                    { field: "currency", title: "Whrg des Kurses" },
                    { field: "flagPercent", title: "Prozent KZ" },
                    { field: "quoteType", title: "Art Notierung" },
                ],
                // IDENTIFIER: "KV085",
            },
        },
        /* {
            title: "Verknüpfungsfelder KV133",
            filled: false,
            // popupConfiguration: {
            //     url: { path: "", params: "" },
            //     title: "KV133",
            //     columns: [],
            //     // IDENTIFIER: "KV133",
            // },
        },
        {
            title: "Verknüpfungsfelder KV134",
            filled: false,
            // popupConfiguration: {
            //     url: { path: "", params: "" },
            //     title: "KV134",
            //     columns: [],
            //     // IDENTIFIER: "KV134",
            // },
        }, */
    ];
    return TableForSingleRecord(
        { path: "/security/secCorpAction/byBidId", params: "bidId" },
        props.id,
        props.wkn,
        props.isin,
        props.shortname,
        SEC_CORP_ACTION_SECTION,
        buttons
    );
};
export const SecSwitchingDetails = (props: ITableForSingleRecord): React.JSX.Element => {
    const buttons: IButtonWithPopupConfiguration[] = [
        {
            title: "Verknüpfungsfelder UV085",
            filled: false,
            popupConfiguration: {
                url: { path: "/security/secswitching/taxes", params: "bidId" },
                title: "UV085",
                columns: [
                    { field: "secAssignment", title: "Gattung/Kurs-Zuordnung" },
                    { field: "rateDate", title: "Datum des Kurses", type: "date" },
                    { field: "rateDateTyp", title: "Art des Kursdatums" },
                    { field: "rate", title: "Kurs Jahresbescheinigung" },
                    { field: "nullMsgFlag", title: "Nullmeldung KZ" },
                    { field: "currency", title: "Kurswährung" },
                    { field: "flagPercent", title: "Prozent KZ" },
                    { field: "quoteType", title: "Art der Notierung" },
                ],
                // IDENTIFIER: "UV085",
            },
        },
        /* {
            title: "Verknüpfungsfelder UV254",
            filled: false,
            // popupConfiguration: {
            //     url: { path: "", params: "" },
            //     title: "UV254",
            //     columns: [],
            //     // IDENTIFIER: "UV254",
            // },
        },
        {
            title: "Verknüpfungsfelder UV255",
            filled: false,
            // popupConfiguration: {
            //     url: { path: "", params: "" },
            //     title: "UV255",
            //     columns: [],
            //     // IDENTIFIER: "UV255",
            // },
        }, */
    ];
    return TableForSingleRecord(
        { path: "/security/secswitching/byBidId", params: "bidId" },
        props.id,
        props.wkn,
        props.isin,
        props.shortname,
        SEC_SWITCHING_SECTION,
        buttons
    );
};
