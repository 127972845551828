/* author: JESCHKE Moritz */

import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { OverviewHeadLine } from "./OverviewHeadLine";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, ITableContextMenu, Table } from "./Table";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { getChangedValue, hasChangedValues } from "./Converter";
import { INSERT_ACTION } from "./EditComponents/FormEditData";
import { TECHNICAL_EDIT_COLUMNS } from "./EditComponents/EditMask";

interface IVersionsView {
    title: string;
    identifier: TableidentifierType;
    columnsDefinition: ITableColumn[];
    dataRequest: AllowedRequestsTypeGet;
    historyPath: string;
    editSection: string;
    editIdentifier?: string;
    addFunction?: boolean;
    data?: Record<string, string>;
}

export const getEditTo = (id: number, editSection?: string, editIdentifier?: string): string =>
    "edit/" + (editIdentifier ? editIdentifier + "/" : "") + (editSection ? editSection + "/" : "") + id;

export const getEditState = (
    modifyAction: string,
    rowData?: Record<string, unknown>,
    extraData?: Record<string, unknown>
): {
    isNew: boolean;
    rowData: {
        [x: string]: unknown;
    };
    extraData: {
        [x: string]: unknown;
    };
} => {
    return {
        isNew: modifyAction === INSERT_ACTION,
        rowData: rowData ?? {},
        extraData: extraData ?? {},
    };
};

export const getEditHistoryContextMenu = (
    currentRowData: Record<string, string | number>,
    setCurrentRowData: Dispatch<SetStateAction<Record<string, string | number>>>,
    historyPath: string,
    editSection: string,
    editIdentifier?: string,
    extraData?: Record<string, unknown>
): ITableContextMenu => {
    return {
        contextActions: [
            {
                label: "Historie",
                link: {
                    to: "/" + historyPath + "/" + currentRowData["id"],
                },
            },
            {
                label: "Bearbeiten",
                link: {
                    to: getEditTo(Number(currentRowData["id"]), editSection, editIdentifier),
                    state: getEditState(
                        getChangedValue(currentRowData, "modifyAction") ?? "",
                        currentRowData,
                        extraData
                    ),
                },
            },
        ],
        setCurrentRowData: setCurrentRowData,
    };
};

export const getAddFunction = (
    editSection: string,
    identifier?: string,
    data?: Record<string, string>
): {
    topicName: string;
    linkTo: string;
    data: Record<string, string>;
} => {
    return {
        topicName: editSection,
        linkTo: "edit/" + (identifier ? identifier + "/" : "") + editSection + "/new",
        data: data ?? {},
    };
};

export const VersionsView = (props: IVersionsView): React.JSX.Element => {
    const [tableData, setTableData] = useState<Record<string, string>[]>([]);
    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    useEffect(() => {
        sendGetRequest(props.dataRequest).then((response) => {
            setTableData(response.data);
        });
    }, []);

    return (
        <div>
            <OverviewHeadLine
                title={props.title}
                disableIconButtons={true}
                hasChangedData={hasChangedValues(tableData)}
            />
            <Table
                columnsDefinition={props.columnsDefinition.concat(TECHNICAL_EDIT_COLUMNS)}
                tableData={tableData}
                identifier={props.identifier}
                showEditPendingStateIcons
                contextMenu={getEditHistoryContextMenu(
                    currentRowData,
                    setCurrentRowData,
                    props.historyPath,
                    props.editSection,
                    props.editIdentifier
                )}
                addFunction={
                    props.addFunction ? getAddFunction(props.editSection, props.editIdentifier, props.data) : undefined
                }
            />
        </div>
    );
};
