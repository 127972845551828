/* author: JESCHKE Moritz */

import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";
import { kistamDenominationShorten } from "./KistamVersions";

export const KistamHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const versionId: number = Number(params.versionId);

    const labels: IHistoryKeys[] = [
        { field: "fiscalYearFrom", label: "Steuerjahr von" },
        { field: "fiscalYearTo", label: "Steuerjahr bis" },
        { field: "denomination", label: "Konfession (von BZST)", filter: kistamDenominationShorten },
        { field: "churchTaxRate", label: "Kirchensteuer Rate (von BZST)", type: "percent" },
        { field: "execState", label: "Ausführungsstatus" },
    ];

    const IDENTIFIER: TableidentifierType = "kistamHistory";

    return (
        <HistoryView
            title="Historie Kirchensteuer"
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/kistam/history",
                params: { versionId: versionId },
            }}
            identifier={IDENTIFIER}
        />
    );
};
