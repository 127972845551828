/* author: PAL Prashant */

import { Typography } from "@mui/material";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { Colors } from "components/Colors";
import { Footer, IFooter } from "components/Footer";
import { IDetailsPanel, ITableColumn, Table } from "components/Table";
import { getAddFunction, getEditHistoryContextMenu } from "components/VersionsView";
import { useEffect, useState } from "react";
import { Location, Params, useLocation, useNavigate, useParams } from "react-router";
import { DONATION_PERSON_SECTION_DONEE, DONATION_PERSON_SECTION_DONOR } from "./DonationPersonEdit";
import { TECHNICAL_EDIT_COLUMNS } from "components/EditComponents/EditMask";

interface IPersonDonationDetails {
    idForPersonDetails: number;
}

export const PersonDetails = (props: IPersonDonationDetails): React.JSX.Element => {
    const [personDetailsData, setPersonDetailsData] = useState<Record<string, string>[]>([]);
    const columnsForPersonDetails: ITableColumn[] = [
        { field: "personInfo", title: "Information zur Person" },
        { field: "personInfoType", title: "Informationstyp zur Person" },
    ];

    useEffect(() => {
        sendGetRequest({
            path: "/donationdata/donation/getDonationPersonDetails",
            params: { personId: Number(props.idForPersonDetails) },
        }).then((response) => {
            setPersonDetailsData(response.data);
        });
    }, [props.idForPersonDetails]);

    return (
        <Table
            tableData={personDetailsData}
            identifier={"personDetailsForDonorDonee"}
            columnsDefinition={columnsForPersonDetails.concat(TECHNICAL_EDIT_COLUMNS)}
        />
    );
};

export const DonationData = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const messageId: number = Number(params.messageId);

    const [dataForDonor, setDataForDonor] = useState<Record<string, string>[]>([]);
    const [dataForDonne, setDataForDonne] = useState<Record<string, string>[]>([]);
    const navigate = useNavigate();

    const location: Location = useLocation();
    const sessionStorageIdenfier: string = "extraDataDonationPerson-" + messageId;
    const extraData: Record<string, unknown> = location.state
        ? location.state.extraData
        : JSON.parse(sessionStorage.getItem(sessionStorageIdenfier) ?? "");
    sessionStorage.setItem(sessionStorageIdenfier, JSON.stringify(extraData));

    const IDENTIFIER_FOR_DONOR: TableidentifierType = "donationDataForDonor";
    const IDENTIFIER_FOR_DONEE: TableidentifierType = "donationDataForDonee";
    const columnsForDonorDonee: ITableColumn[] = [
        { field: "role", title: "Rolle" },
        { field: "roleNr", title: "lfd. Nr. Auftragg./Empf." },
        { field: "nps", title: "Natürliche Person" },
        { field: "lastName", title: "Nachname" },
        { field: "givenName", title: "Vorname" },
        {
            field: "birthDate",
            title: "Geburtsdatum",
            type: "date",
        },
        { field: "tin", title: "Tax Identification Number (TIN)" },
        { field: "street1", title: "Strasse 1" },
        { field: "street2", title: "Strasse 2" },
        { field: "houseNumber", title: "Hausnummer" },
        { field: "houseNumberAdd", title: "Zusätzliche Hausnummer" },
        { field: "zipCode", title: "PLZ" },
        { field: "town", title: "Ort" },
        { field: "country", title: "Adressland" },
        { field: "ignoreMatching", title: "Matching ignorieren" },
    ];

    const detailPanel: IDetailsPanel[] = [
        {
            tooltip: "show data",
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
            render: (rowData: Record<string, any>) => {
                return <PersonDetails idForPersonDetails={rowData.rowData.id} />;
            },
        },
    ];

    const footer: IFooter = {
        buttons: [
            { title: "Abbrechen", filled: false, onClick: () => navigate("/home") },
            {
                title: "Zurück",
                filled: false,
                onClick: () => navigate("/donationDataSearchMask"),
            },
        ],
    };
    useEffect(() => {
        sendGetRequest({
            path: "/donationdata/donation/getDonationDetails/donor",
            params: { donationMessageId: messageId },
        }).then((response) => {
            setDataForDonor(response.data);
        });
        sendGetRequest({
            path: "/donationdata/donation/getDonationDetails/donee",
            params: { donationMessageId: messageId },
        }).then((response) => {
            setDataForDonne(response.data);
        });
    }, [messageId]);

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});
    const contextMenu = (section: string) =>
        getEditHistoryContextMenu(currentRowData, setCurrentRowData, "donationDataHistory", section);

    return (
        <div style={{ margin: "4em" }}>
            <Typography sx={{ color: Colors.borderDark, fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                {"Schenkungdaten Auftraggeber / Empfänger zu Umsatzreferenz " + extraData.extTranKey}
            </Typography>
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
                <Table
                    columnsDefinition={columnsForDonorDonee.concat(TECHNICAL_EDIT_COLUMNS)}
                    identifier={IDENTIFIER_FOR_DONOR}
                    tableData={dataForDonor}
                    title="Auftraggeber"
                    disableAllSelectable={true}
                    detailPanel={detailPanel}
                    contextMenu={contextMenu(DONATION_PERSON_SECTION_DONOR)}
                    showEditPendingStateIcons
                    addFunction={getAddFunction(DONATION_PERSON_SECTION_DONOR)}
                />
                <Table
                    columnsDefinition={columnsForDonorDonee.concat(TECHNICAL_EDIT_COLUMNS)}
                    identifier={IDENTIFIER_FOR_DONEE}
                    tableData={dataForDonne}
                    title="Empfänger"
                    disableAllSelectable={true}
                    detailPanel={detailPanel}
                    contextMenu={contextMenu(DONATION_PERSON_SECTION_DONEE)}
                    showEditPendingStateIcons
                    addFunction={getAddFunction(DONATION_PERSON_SECTION_DONEE)}
                />
            </div>
            <Footer buttons={footer.buttons} />
        </div>
    );
};
