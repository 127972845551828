/* author: Aashish Upadhyay  */

import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const AccountHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const accountId: number = Number(params.accountId);

    const labels: IHistoryKeys[] = [
        { field: "accountType", label: "Kontotyp" },
        { field: "name", label: "Kontoname" },
        { field: "currency", label: "Kontowährung" },
        { field: "iban", label: "IBAN" },
    ];

    return (
        <HistoryView
            title={"Historie Konto"}
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/account/history",
                params: { accountId: accountId },
            }}
            identifier={"accountHistory"}
        />
    );
};
