/* author: JESCHKE Moritz */

import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { HistoryView, IHistoryKeys } from "components/HistoryView";
import { Params, useParams } from "react-router";

export const PersonHistory = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const versionId: number = Number(params.versionId);

    const labels: IHistoryKeys[] = [
        { field: "accountType", label: "Kontotyp" },
        { field: "name", label: "Kontoname" },
        { field: "currency", label: "Kontowährung" },
        { field: "iban", label: "IBAN" },
        { field: "firstName", label: "Vorname" },
        { field: "lastName", label: "Nachname" },
        { field: "middleName", label: "Zweitname" },
        { field: "title", label: "Titel" },
        { field: "nationality", label: "Nationalität" },
        { field: "gender", label: "Geschlecht" },
        { field: "tin", label: "TIN" },
        { field: "federalState", label: "Bundesland" },
        { field: "email", label: "Email" },
        { field: "birthName", label: "Geburtsname" },
        { field: "birthDate", label: "Geburtstag", type: "date" },
        { field: "deathDate", label: "Todestag", type: "date" },
        { field: "validFrom", label: "Gültig von", type: "date" },
        { field: "validTo", label: "Gültig bis", type: "date" },
        { field: "domicile", label: "Domizil" },
    ];

    const IDENTIFIER: TableidentifierType = "personHistory";

    return (
        <HistoryView
            title={"Historie Personendaten"}
            labels={labels}
            dataRequest={{
                path: "/customerstaticdata/person/history",
                params: { versionId: versionId },
            }}
            identifier={IDENTIFIER}
        />
    );
};
