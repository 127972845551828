/* author: SINDHUKANNAN */

import { KeyTableConsts } from "components/KeyTableConsts";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const DonationDataSearchMask = (): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "extTranKey", title: "Umsatzreferenz" },
        { field: "branch", title: "Filialnummer" },
        { field: "donorBicOrBlzFlag", title: "Auftraggeber BIC/BLZ" },
        { field: "donorBicOrBlz", title: "Auftraggeber Depotstelle (Bank/KAG)" },
        { field: "donorAccNr", title: "Auftraggeber Depotnummer" },
        { field: "doneeBicOrBlzFlag", title: "Empfänger BIC/BLZ" },
        { field: "doneeBicOrBlz", title: "Empfänger Depotstelle (Bank/KAG)" },
        { field: "doneeAccNr", title: "Empfänger Depotnummer" },
        { field: "internExternTransferFlag", title: "int/ext" },
        { field: "moreThenTwoDoneesFlag", title: "weitere Empfänger vorhanden" },
        { field: "execState", title: "Ausführungsstatus" },
    ];

    const searchCriterias: ISearchCriteria[] = [
        { field: "extTranKey", label: "Umsatzreferenz*" },
        { field: "donorDepotNumber", label: "Auftraggeber Depotnummer*" },
        { field: "donorLastName", label: "Auftraggeber Nachname" },
        { field: "donorFirstName", label: "Auftraggeber Vorname" },
        { field: "doneeDepotNumber", label: "Empfänger Depotnummer*" },
        { field: "doneeLastName", label: "Empfänger Nachname" },
        { field: "doneeFirstName", label: "Empfänger Vorname" },

        {
            field: "execState",
            label: "Ausführungsstatus",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.TDD_STATE, allOption: true },
            defaultValue: "*",
        },

        {
            field: "",
            label: "Zeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "from", discriminator: "from" },
                    to: { label: "bis", field: "to", discriminator: "to" },
                },
            },
        },
    ];

    const searchUrl: ISearchUrl[] = [{ path: "/donationdata/donation/search" }];

    return (
        <Search
            extraData={["extTranKey"]}
            linkto="/donationData/"
            linktoIdentifier="id"
            title={"Schenkungsdaten Suche"}
            searchCriterias={searchCriterias}
            columns={columns}
            identifier={"donationDataSearch"}
            searchUrls={searchUrl}
        />
    );
};
