import { Overview } from "components/Overview";
import { IOverviewTile } from "components/OverviewTile";
import { IOverviewData } from "components/OverviewData";

import HomeWorkRoundedIcon from "@mui/icons-material/HomeWorkRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import { useEffect, useState } from "react";
import { isReponseEmpty, sendGetRequest } from "application/api/HttpRequestHandler";
import { Params, useNavigate, useParams } from "react-router";
import { AddressDetails } from "./AddressDetails";
import { Typography } from "@mui/material";
import { customDateValueGetter, getChangedOrOriginalValue } from "components/Converter";
import { AccountDetails } from "../account-data/AccountDetails";
import { NvDetails } from "./NvDetails";
import { FsaDetails } from "./FsaDetails";

import ContentPasteRoundedIcon from "@mui/icons-material/ContentPasteRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import { PersonVersions } from "./PersonVersions";
import { KistamVersions, kistamDenominationShorten } from "./KistamVersions";

export const getFsaNvData = (
    fsaData: Record<string, string>,
    nvData: Record<string, string>
): {
    type?: string;
}[] => {
    //Booth filled
    if (!isReponseEmpty(fsaData) && !isReponseEmpty(nvData)) return [{ ...fsaData }, { ...nvData }];
    //Booth empty
    if (isReponseEmpty(fsaData) && isReponseEmpty(nvData)) return [{ type: "FE" }, { type: "NE" }];
    //FSA empty, NV filled
    if (isReponseEmpty(fsaData)) return [{ type: "FE" }, { ...nvData }];
    //FSA filled, NV empty
    else return [{ ...fsaData }, { type: "NE" }];
};

export const PersonOverview = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const navigate = useNavigate();

    const [generalPersonData, setGeneralPersonData] = useState({});
    const [addressData, setAddressData] = useState([]);
    const [taxData, setTaxData] = useState({});
    const [fsaData, setFsaData] = useState({});
    const [nvData, setNvData] = useState({});
    const [accountData, setAccountData] = useState([]);
    const [fiscalUnitData, setFiscalUnitData] = useState([]);
    const personId: number = Number(params.personId) ?? -1;

    useEffect(() => {
        sendGetRequest({
            path: "/customerstaticdata/address/getAddressesByPersonId",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setAddressData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/person",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setGeneralPersonData(processResponse(response.data));
        });

        sendGetRequest({
            path: "/customerstaticdata/account/getAccountsByPersonId",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setAccountData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/kistam",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setTaxData(response.data);
        });
        sendGetRequest({
            path: "/customerstaticdata/fiscalUnit/getFu2PersonByPersonId",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setFiscalUnitData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/fsaNv/getFsaOverviewData",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setFsaData(response.data);
        });

        sendGetRequest({
            path: "/customerstaticdata/fsaNv/getNvOverviewData",
            params: { personId: personId },
        }).then((response) => {
            console.table(response.data);
            setNvData(response.data);
        });
    }, [personId]);

    const personInfo: IOverviewData = {
        headline: {
            title: "Persönliche Informationen",
            onInfoClick: () => {
                alert("info");
            },
        },
        attributes: [
            { field: "lastName", headerName: "Nachname" },
            { field: "firstName", headerName: "Vorname" },
            { field: "middleName", headerName: "Zweitname" },
            { field: "title", headerName: "Titel" },
            { field: "geburt", headerName: "Geburt" },
            { field: "nationality", headerName: "Nationalität" },
            { field: "gender", headerName: "Geschlecht" },
            { field: "birthName", headerName: "Geburtsname" },
        ],
        columnNumber: 4,
        data: generalPersonData,
        versionView: <PersonVersions personId={personId} />,
    };

    const addressInfo: IOverviewTile = {
        headline: {
            title: "Adressen",
        },
        cardDefinition: [
            [{ field: "country" }, { field: "zipCode" }, { field: "town" }],
            [{ field: "street1" }, { field: "houseNumber" }],
        ],
        orientation: "vertical",
        icons: {
            mapping: {
                Meldeadresse: <HomeWorkRoundedIcon />,
                "abweichende Anschrift": <PublicRoundedIcon />,
                "ZIV Anschrift": <Typography sx={{ fontSize: "0.875rem" }}>ZIV</Typography>,
            },
        },

        idFields: ["id"],
        typeField: "addressType",
        detailView: (ids: string[], type: string | undefined): React.JSX.Element => (
            <AddressDetails versionId={Number(ids[0])} personId={personId} addressType={type ?? ""} icon={null} />
        ),
        data: addressData,
    };

    const combineKistamAndTin = (): Record<string, string> => ({
        ...taxData,
        tin: getChangedOrOriginalValue(generalPersonData, "tin"),
    });

    const taxInfo: IOverviewTile = {
        headline: {
            title: "Steuerdaten",
        },
        cardDefinition: [
            [
                {
                    field: "exemptAmount",
                    combine: {
                        mapping: {
                            F: {
                                combinedFields: [{ field: "fiscalYear" }, { field: "taxDate", type: "date" }],
                                combineFormat: "Betrag: {0}€ (Für {1})\nGültig von: {2}-",
                            },
                            FE: {
                                combinedFields: [],
                                combineFormat: "Betrag:\nGültig von:",
                            },
                            N: {
                                combinedFields: [{ field: "nvType" }, { field: "validFrom", type: "date" }],
                                combineFormat: "Typ: {1}\nGültig von: {2} -",
                            },
                            NE: {
                                combinedFields: [],
                                combineFormat: "Typ:\nGültig von:",
                            },
                        },
                    },
                    labels: {
                        mapping: {
                            F: "FSA (eingereicht)",
                            FE: "FSA",
                            N: "NV (eingereicht)",
                            NE: "NV",
                        },
                    },
                },
                {
                    field: "availmentFsa",
                    combine: {
                        mapping: {
                            F: { combinedFields: [], combineFormat: "{0}€" },
                            N: { combinedFields: [], combineFormat: "" },
                        },
                    },
                    labels: {
                        mapping: {
                            F: "FSA (davon ausgenutzt)",
                            FE: "FSA (ausgenutzt)",
                            DEFAULT: "",
                        },
                    },
                },
            ],
        ],
        icons: {
            mapping: {
                F: <ContentPasteRoundedIcon />,
                FE: <ContentPasteRoundedIcon />,
                N: <ReceiptLongRoundedIcon />,
                NE: <ReceiptLongRoundedIcon />,
            },
        },
        orientation: "horizontal",
        idFields: ["fsaNvId", "fsaLimitId", "nvDataId"],
        typeField: "type",
        detailView: (ids: string[], type: string | undefined): React.JSX.Element =>
            type === "F" || type === "FE" ? (
                <FsaDetails fsaNvId={Number(ids[0])} fsaLimitId={Number(ids[1])} icon={null} />
            ) : (
                <NvDetails fsaNvId={ids[0]} nvDataId={ids[2]} icon={null} />
            ),
        data: getFsaNvData(fsaData, nvData),
        cardWithoutBorder: {
            data: combineKistamAndTin(),
            /* data: mergeJSON([
                { data: taxData, prefix: "" },
                { data: generalPersonData, prefix: "" },
            ]), */
            attributes: [
                { field: "tin", headerName: "TIN" },
                { field: "denomination", headerName: "Konfession", filter: kistamDenominationShorten },
                { field: "churchTaxRate", headerName: "Kirchensteuerrate", type: "percent" },
            ],
            columnNumber: 3,
        },
        disabled: (type: string | undefined): boolean => type === "FE" || type === "NE",
        versionView: <KistamVersions personId={personId} />,
    };

    const fuInfo: IOverviewTile = {
        headline: {
            title: "FU Beteiligungen",
        },
        cardDefinition: [
            [
                {
                    field: "type",
                    combine: {
                        mapping: {
                            DEFAULT: {
                                combinedFields: [{ field: "fiscalUnit" }],
                                combineFormat: "{0} - FU {1}",
                            },
                        },
                    },
                },
            ],
            [
                {
                    field: "fiscalUnitType",
                    combine: {
                        mapping: {
                            DEFAULT: {
                                combinedFields: [{ field: "legalFromCategory" }],
                                combineFormat: "Type {0} - {1}",
                            },
                        },
                    },
                },
            ],
        ],
        orientation: "vertical",
        data: fiscalUnitData,
        onClick: (ids: string[]): void => navigate("/overviewFu/" + ids[0]),
        idFields: ["fiscalUnitId"],
        typeField: "",
    };

    const acountInfo: IOverviewTile = {
        headline: {
            title: "Konten",
            hideTimeSliceButton: true,
        },
        cardDefinition: [
            [
                {
                    field: "accountNr",
                    labels: {
                        mapping: {
                            DEFAULT: "Konto Nr.",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "accountType",
                    labels: {
                        mapping: {
                            DEFAULT: "Kontotyp",
                        },
                    },
                    isBold: true,
                },
                {
                    field: "currency",
                    labels: {
                        mapping: {
                            DEFAULT: "Währung",
                        },
                    },
                    isBold: true,
                },
            ],
        ],
        orientation: "keyValuePair",

        idFields: ["id"],
        typeField: "accountType",
        detailView: (ids: string[]): React.JSX.Element => <AccountDetails accountId={Number(ids[0])} icon={null} />,
        data: accountData,
    };

    const processResponse = (data: Record<string, string>) => {
        data.geburt = getBirthRelatedData();
        return data;

        function getBirthRelatedData() {
            const birthDate: { value: string } = { value: getChangedOrOriginalValue(data, "birthDate") };
            const birthPlace = data["birthPlace"];
            const deathDate: { value: string } = { value: getChangedOrOriginalValue(data, "deathDate") };
            let geburt: string = "";
            if (getChangedOrOriginalValue(data, "birthDate") !== undefined)
                geburt = "* " + customDateValueGetter(birthDate) + " in " + birthPlace;
            if (getChangedOrOriginalValue(data, "deathDate") !== undefined)
                geburt = geburt + "\n† " + customDateValueGetter(deathDate);
            return geburt;
        }
    };

    const tiles: IOverviewTile[] = [addressInfo, taxInfo, fuInfo, acountInfo];

    return <Overview title="Übersicht Personen" generalInfo={personInfo} tiles={tiles} />;
};
