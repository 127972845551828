/* author: Bhawna Semwal */

import { useAppSelector } from "application/redux/Hooks";
import { RootState } from "application/redux/Store";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const DonationSearchReport = (): React.JSX.Element => {
    const clientId: string = useAppSelector((state: RootState) => state.user.clientId);

    const columns: ITableColumn[] = [
        { field: "declarationNumber", title: "lfd. Nr. der Meldung" },
        { field: "execState", title: "Meldestatus Gesamtmeldung" },
        { field: "fromDate", title: "Zeitraum von", type: "date" },
        { field: "toDate", title: "Zeitraum bis", type: "date" },
        {
            field: "clientNamesData.identification",
            title: "Mandant",
            combinedFields: ["clientNamesData.name"],
            combinedFieldsSeparator: "/",
        },
    ];

    const searchCriterias: ISearchCriteria[] = [
        {
            field: "clientId",
            label: "Mandant",
            type: "dropDown",
            keytable: { tabname: "CLIENT", allOption: true },
            defaultValue: clientId,
        },
        {
            field: "year",
            label: "Berichtsjahr",
            freeInputRestrigtion: ["NUMBERS"],
            fieldLengthLimit: 4,
        },
    ];

    const searchUrl: ISearchUrl[] = [{ path: "/request/donationDeclaration/report/search" }];

    return (
        <Search
            title={"Gesamtmeldungen"}
            searchCriterias={searchCriterias}
            columns={columns}
            identifier={"donationDeclarationReportSearch"}
            searchUrls={searchUrl}
        />
    );
};
