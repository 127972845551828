/* author: PAL Prashant */
import { TextField, Typography } from "@mui/material";
import { Colors } from "components/Colors";
import { Controller, FieldValues, RegisterOptions, UseFormReturn, useForm } from "react-hook-form";
import { IFormConfiGuration } from "./EditComponents/FormEditView";

interface IInputField {
    value: string;
    setValue?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label: string;
    disabled?: boolean;
    multiline?: boolean;
    rows?: string | number;
    validation?: IInputFieldValidate;
    filter?: (option: string) => string;
    highlightBorder?: boolean;
    hasError?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur?: (event: any) => void;
    inputLimitation?: string[];
    clearButton?: boolean;
}

interface IInputFieldValidate {
    field: string;
    formSpecificConfiguration: IFormConfiGuration;
    rules: RegisterOptions<FieldValues> | undefined;
}

export const getBorderColor = (
    disabled: boolean | undefined,
    highlightBorder: boolean | undefined,
    hasError: boolean | undefined
): string => {
    if (hasError) return Colors.red;
    if (highlightBorder) return Colors.lightGreen;
    if (disabled) return Colors.darkGrey;
    return Colors.blue;
};

export const getBorderWidth = (highlightBorder: boolean | undefined): number => {
    const NORMAL_BORDER: number = 2;
    const THICK_BORDER: number = 4;
    if (highlightBorder) return THICK_BORDER;
    return NORMAL_BORDER;
};

export const InputField = (props: IInputField): React.JSX.Element => {
    const editForm: UseFormReturn<FieldValues, unknown, undefined> = useForm({
        mode: "all",
    });

    return (
        <div>
            <Typography
                sx={{
                    fontSize: "0.875rem",
                    color: props.disabled ? Colors.darkGrey : Colors.black,
                    fontWeight: "bold",
                }}
            >
                {props.label}
            </Typography>

            <Controller
                control={props.validation ? props.validation.formSpecificConfiguration.control : editForm.control}
                name={(props.validation && props.validation.field) ?? ""}
                rules={props.validation && props.validation.rules}
                render={({ field: { ...field } }) => (
                    <TextField
                        {...field}
                        value={props.filter ? props.filter(props.value) : props.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            if (
                                //allow deletion of last character
                                event.target.value.length > 0 &&
                                //prevent change if numberOnly or wildcards is on
                                props.inputLimitation &&
                                props.inputLimitation.length !== 0
                            ) {
                                const regexfilter = "[" + props.inputLimitation.join(",") + "]+$";
                                if (event.target.value.match(new RegExp(regexfilter)) == null) return;
                            }

                            if (props.setValue) {
                                props.setValue(event);
                            }
                            field.onChange(event);
                        }}
                        variant="outlined"
                        size="small"
                        disabled={props.disabled}
                        sx={{
                            width: "100%",
                            color: Colors.blue,
                            borderRadius: "3px",
                            "& .MuiOutlinedInput-input.MuiInputBase-input": {
                                border: getBorderWidth(props.highlightBorder) + "px solid",
                                borderColor: getBorderColor(props.disabled, props.highlightBorder, props.hasError),
                                borderRadius: "3px",
                                backgroundColor: Colors.white,
                                boxSizing: "border-box",
                                color: "#333333",
                                height: "1.875rem",
                            },
                        }}
                        rows={props.rows}
                        multiline={props.multiline}
                        onBlur={(event) => props.onBlur && props.onBlur(event)}
                        inputProps={{ type: props.clearButton ? "search" : "initial" }}
                    />
                )}
            />

            {props.validation && props.validation.formSpecificConfiguration.errors[props.validation.field] && (
                <Typography sx={{ color: Colors.red }}>
                    {props.validation.formSpecificConfiguration.errors[props.validation.field]?.message?.toString()}
                </Typography>
            )}
        </div>
    );
};
