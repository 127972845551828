import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
export const FormStateConsts = {
    EDIT: "edit",
    NEW: "new",
    DUPLICATE: "duplicate",
    CANCEL: "cancel",
};
//prettier-ignore
type ActionType = { actionType: typeof FormStateConsts[keyof typeof FormStateConsts] };
const initialState: ActionType = { actionType: FormStateConsts.EDIT };

export const FormStateSlice = createSlice({
    name: "formAction",
    initialState: initialState,
    reducers: {
        setAction: (state, action: PayloadAction<ActionType>) => {
            return action.payload;
        },
    },
});

export const { setAction } = FormStateSlice.actions;
export const FormStateSliceReducer: Reducer<ActionType, PayloadAction<ActionType>> = FormStateSlice.reducer;
