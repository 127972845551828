/* author: JESCHKE Moritz */

import { Autocomplete, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Colors } from "./Colors";
import { GET_REQUEST_TYPE } from "application/api/HttpRequestHandler";

interface IAutoCompleteV2 {
    value: string;
    setValue: (value: string) => void;
    optionsRequest: (currentValue: string) => GET_REQUEST_TYPE;
    autocompleteMinLetters: number;
    disabledCheck?: boolean;
    label: string;
    disabled?: boolean;
}

export const AutoCompleteInputV2 = (props: IAutoCompleteV2): React.JSX.Element => {
    const [options, setOptions] = useState<Record<string, string>[]>([]);

    return (
        <div style={{ width: "20%" }}>
            <Typography
                sx={{
                    fontSize: "0.875rem",
                    color: props.disabled ? Colors.darkGrey : Colors.black,
                    fontWeight: "bold",
                }}
            >
                {props.label}
            </Typography>
            <Autocomplete
                value={props.value}
                onInputChange={async (event, value: string, reason: string) => {
                    const prevValue = props.value;

                    //handle options
                    if (reason === "clear") {
                        setOptions([]);
                    } else if (reason === "input") {
                        if ((event.target as HTMLInputElement).defaultValue.length > value.length) {
                            //delete
                            if (value.length < props.autocompleteMinLetters) {
                                setOptions([]);
                            }
                        } else {
                            //writing
                            if (
                                value.length >= props.autocompleteMinLetters &&
                                prevValue.length < props.autocompleteMinLetters
                            ) {
                                props
                                    .optionsRequest(value.substring(0, props.autocompleteMinLetters))
                                    .then((response) => {
                                        setOptions(response.data);
                                    });
                            }
                        }
                    }

                    props.setValue(value);
                }}
                disabled={props.disabledCheck}
                freeSolo
                options={options}
                size="small"
                sx={{
                    width: "100%",
                    color: Colors.blue,
                    borderRadius: "3px",
                    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot":
                        {
                            height: "1.875rem",
                            borderRadius: "3px",
                            boxSizing: "border-box",
                        },
                    "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused":
                        {
                            bottom: "0.3125rem",
                            position: "relative",
                        },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid",
                        borderColor: (props.disabled ? Colors.darkGrey : Colors.blue) + "!important",
                    },
                }}
                renderInput={(params) => <TextField {...params} label={""} />}
            />
        </div>
    );
};
