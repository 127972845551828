/* author: JESCHKE Moritz */

import { SelectChangeEvent, Typography } from "@mui/material";
import { IEditDataKeys, IFormConfiGuration } from "./FormEditView";
import { InputField } from "components/InputField";
import { Datepicker } from "components/Datepicker";
import { ComboBox } from "components/ComboBox";
import { backendDateFormatGetter, convert } from "components/Converter";
import { FieldValues, RegisterOptions } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "application/redux/Store";
import { Switcher } from "components/Switcher";
import { ChangeEvent } from "react";

export type RuleName = "minLength" | "maxLength" | "pattern" | "required";
type RuleValue = string | number | RegExp | boolean;
export interface IRule {
    value: RuleValue;
    customMessage?: string;
}

export interface IFormEditViewLineTechnical {
    disableFurtherEdits: boolean;
    formSpecificConfiguration: IFormConfiGuration;
}

interface IFormEditViewLine extends IFormEditViewLineTechnical {
    attribute: IEditDataKeys;
    oldValue: string;
    value: string | string[];
    updateEditData: (newValue: string) => void;
    updateDatePickerError: (newValue: boolean) => void;
    hasError: boolean;
    checkInvalidFields: (changedField: string, newData: string) => void;
    hasChangeInSection: boolean;
    updateResetButtonEnable: (newVal: boolean) => void;
    id: string;
}

export const FormEditViewLine = (props: IFormEditViewLine): React.JSX.Element => {
    const formAction = useSelector((state: RootState) => state.formAction.actionType); // Assuming your slice is stored as formAction in the root state
    console.log("formAction", formAction);
    const getInputFieldValue = (attribute: IEditDataKeys, oldValue?: boolean): string =>
        convert(oldValue ? props.oldValue : (props.value as string), attribute.type);

    const getDate = (): Date | null => {
        if (props.value) return new Date(props.value as string);
        return null;
    };

    const getRules = (rules: Partial<Record<RuleName, IRule>>): RegisterOptions<FieldValues> => {
        //add message
        Object.keys(rules).forEach((key: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (rules[key as RuleName] as any)["message"] =
                (rules[key as RuleName] as IRule).customMessage ??
                getErrorMessage(key as RuleName, (rules[key as RuleName] as IRule).value);
        });

        return rules as unknown as RegisterOptions<FieldValues>;
    };

    const getErrorMessage = (ruleName: RuleName, value: RuleValue): string => {
        switch (ruleName) {
            case "minLength":
                return "Für dieses Feld sind mindestens " + value + " Zeichen notwendig!";
            case "maxLength":
                return "Für dieses Feld sind maximal " + value + " Zeichen zulässig!";
            case "pattern":
                return "Pattern missmatch: " + value;
            case "required":
                return "Das Feld darf nicht leer sein!";
            default:
                return "NO DEFAULT ERROR MESSAGE DEFINED FOR: " + ruleName;
        }
    };

    const hasChangeInField = (): boolean => props.value !== props.oldValue;

    const getLabelElement = (attribute: IEditDataKeys): React.JSX.Element => {
        return (
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                }}
            >
                {attribute.headerName}
            </Typography>
        );
    };

    const disableActiveTimesliceEditForNonExistingTimesclices: boolean =
        props.id === undefined && window.location.href.endsWith("/edit");

    const getEditElement = (attribute: IEditDataKeys): React.JSX.Element => {
        // MUST BE AT FIRST TO REPLACE KEYTABLES
        if (attribute.dropdown) {
            return (
                <ComboBox
                    label=""
                    onChange={(event: SelectChangeEvent<string>) => {
                        props.updateEditData(event.target.value);
                        props.updateResetButtonEnable(true);
                    }}
                    keyTable={{ tabname: attribute.dropdown && attribute.dropdown.keyTableTabname }}
                    optionIdentifier="value"
                    optionValue={["label"]}
                    optionValueFilter={attribute.dropdown.optionValueFilter}
                    value={props.value as string}
                    width="100%"
                    size="small"
                    disabled={
                        props.disableFurtherEdits ||
                        attribute.displayOnly ||
                        disableActiveTimesliceEditForNonExistingTimesclices
                    }
                    hasError={props.hasError}
                    onBlur={(event) => props.checkInvalidFields(attribute.field, event.target.value)}
                    highlightBorder={hasChangeInField()}
                    disableNonEditableOptions={attribute.dropdown.disableNonEditableOptions}
                />
            );
        }

        if (attribute.type === "switch") {
            return (
                //MoJ: zIndex is requeried to overlap green border over production one -> can be removed, when there is a gap between edit and production column
                <div style={{ zIndex: 0 }}>
                    <Switcher
                        checked={getInputFieldValue(attribute) === "true"}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const newValue: boolean = event.target.checked;
                            props.checkInvalidFields(attribute.field, String(newValue));
                            props.updateEditData(String(newValue));
                            props.updateResetButtonEnable(true);
                        }}
                        disabled={
                            props.disableFurtherEdits ||
                            attribute.displayOnly ||
                            disableActiveTimesliceEditForNonExistingTimesclices
                        }
                        highlightBorder={(props.value === "true") !== (props.oldValue === "true")}
                    />
                </div>
            );
        }
        if (attribute.displayOnly || disableActiveTimesliceEditForNonExistingTimesclices) {
            return (
                <InputField
                    label=""
                    value={getInputFieldValue(attribute)}
                    disabled={true}
                    highlightBorder={hasChangeInField()}
                />
            );
        }

        if (!props.disableFurtherEdits && attribute.type === "date") {
            return (
                <Datepicker
                    value={getDate()}
                    onChange={(newValue: Date | null) => {
                        props.checkInvalidFields(attribute.field, String(newValue));
                        props.updateEditData(newValue ? backendDateFormatGetter(newValue) : "");
                        props.updateResetButtonEnable(true);
                    }}
                    onError={() => props.updateDatePickerError(true)}
                    onAccept={() => props.updateDatePickerError(false)}
                    highlightBorder={hasChangeInField()}
                    hasError={props.hasError}
                    onBlur={(event) => props.checkInvalidFields(attribute.field, event.target.value)}
                />
            );
        }

        return (
            <InputField
                value={getInputFieldValue(attribute)}
                validation={{
                    formSpecificConfiguration: props.formSpecificConfiguration,
                    field: attribute.field,
                    rules: attribute.rules && getRules(attribute.rules),
                }}
                setValue={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    props.updateEditData(event.target.value);
                    props.updateResetButtonEnable(true);
                }}
                label={""}
                multiline={false}
                disabled={props.disableFurtherEdits}
                highlightBorder={hasChangeInField()}
                hasError={props.hasError}
                inputLimitation={attribute.type === "number" ? ["0-9"] : undefined}
                onBlur={(event) => props.checkInvalidFields(attribute.field, event.target.value)}
            />
        );
    };

    const getProductionDisplayElement = (attribute: IEditDataKeys): React.JSX.Element => {
        if (!props.hasChangeInSection) return <div></div>;
        if (formAction !== "default") return <div></div>;

        // MUST BE AT FIRST TO REPLACE KEYTABLES
        if (attribute.dropdown) {
            return (
                <ComboBox
                    label=""
                    onChange={() => undefined}
                    keyTable={{ tabname: attribute.dropdown && attribute.dropdown.keyTableTabname }}
                    optionIdentifier="value"
                    optionValue={["label"]}
                    optionValueFilter={attribute.dropdown.optionValueFilter}
                    value={props.oldValue}
                    width="100%"
                    size="small"
                    disabled
                />
            );
        }

        if (attribute.type === "switch") {
            return (
                //MoJ: div is requeried to overlap green border over production one -> can be removed, when there is a gap between edit and production column
                <div>
                    <Switcher checked={props.oldValue === "true"} disabled />
                </div>
            );
        }

        return <InputField value={getInputFieldValue(attribute, true)} disabled={true} label={""} />;
    };
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "30% 30% 30% 10%",
                marginBottom: "0.5em",
            }}
        >
            {getLabelElement(props.attribute)}
            {getEditElement(props.attribute)}
            {getProductionDisplayElement(props.attribute)}
            <div></div>
        </div>
    );
};
