/* author: Aashish Upadhyay*/

import { RootState } from "application/redux/Store";
import { KeyTableConsts } from "components/KeyTableConsts";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";
import { useSelector } from "react-redux";

export const FsakRequestSearchMask = (): React.JSX.Element => {
    const clientId: string = useSelector((state: RootState) => state.user.clientId);
    const fsakRequestColumns: ITableColumn[] = [
        { field: "requestType", title: "Auftragsart" },
        {
            field: "clientData.identification",
            title: "Mandant",
            combinedFields: ["clientData.name"],
            combinedFieldsSeparator: "/",
        },
        { field: "fromDate", title: "Zeitraum von", type: "date" },
        { field: "toDate", title: "Zeitraum bis", type: "date" },
        { field: "insertTS", title: "Erfassungsdatum", type: "datetime" },
        { field: "execState", title: "Auftragstatus" },
        {
            field: "reportDeclarationData.declarationNumber",
            title: "lfd. Nr. der Meldung",
        },
        {
            field: "reportDeclarationData.fiscalYear",
            title: "Melderelevantes Jahr",
        },
        {
            field: "reportDeclarationData.execState",
            title: "Meldestatus Gesamtmeldung",
        },
        {
            field: "reportDeclarationData.errorCounter",
            title: "Anzahl Fehler gesamt",
        },
    ];

    const searchCriterias: ISearchCriteria[] = [
        {
            field: "clientId",
            label: "Mandant",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.CLIENT, allOption: true },
            defaultValue: clientId,
        },
        { field: "year", label: "Berichtsjahr", freeInputRestrigtion: ["NUMBERS"], fieldLengthLimit: 4 },
        {
            field: "",
            label: "Erfassungszeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "fromDate", discriminator: "from" },
                    to: { label: "bis", field: "toDate", discriminator: "to" },
                },
            },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/request/yer/searchFsakDeclaration/" }];

    return (
        <Search
            title={"FSAK-Meldungsaufträge suchen"}
            searchCriterias={searchCriterias}
            columns={fsakRequestColumns}
            searchUrls={searchUrls}
            identifier="fsakRequestSearchMask"
        />
    );
};
