/* author: JESCHKE Moritz */

import { IOverviewData, OverviewData } from "./OverviewData";
import { IOverviewTile, OverviewTile } from "./OverviewTile";
import { Typography } from "@mui/material";
import { Footer, IFooterButton } from "./Footer";
import { NavigateFunction, useNavigate } from "react-router";
import { useState, cloneElement } from "react";
import { createKey } from "./Converter";
import { IAttribute } from "./OverviewDataView";
import { EditButton } from "./Button";
import { isEqual } from "lodash";

interface IOverview {
    title: string;
    generalInfo: IOverviewData;
    tiles: IOverviewTile[];
    hideEditButton?: boolean;
}

export interface IOverviewViewStackAdder {
    viewStackAdd?: (newElement: React.JSX.Element, newHeadline: string) => void;
}

export const Overview = (props: IOverview): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const MAX_ATTRIBUTES_FOR_SMALL_HEADER: number = 6;
    const MAX_ATTRIBUTE_LENGTH_FOR_SMALL_HEADER: number = 15;

    const [viewStack, setViewStack] = useState<{ viewElement: React.JSX.Element; headline: string }[]>([]);
    const viewStackAdd = (newElement: React.JSX.Element, newHeadline: string) => {
        //check if same detailview was allready added last on stack
        if (!isEqual(viewStack[viewStack.length - 1], newElement)) {
            setViewStack([...viewStack, { viewElement: newElement, headline: newHeadline }]);
        }
    };
    const viewStackPop = () => setViewStack(viewStack.slice(0, -1));

    const footer: IFooterButton[] = [
        {
            title: "Abbrechen",
            filled: false,
            onClick: () => navigate("/home"),
        },
        {
            title: "Zurück",
            filled: false,
            onClick: () => (viewStack.length !== 0 ? viewStackPop() : navigate(-1)),
        },
    ];

    return (
        <div style={{ margin: "1rem 4rem" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                {viewStack.length !== 0 ? viewStack[viewStack.length - 1].headline : props.title}
                {!props.hideEditButton && viewStack.length === 0 && <EditButton />}
            </Typography>

            <div
                style={
                    props.generalInfo.attributes.length > MAX_ATTRIBUTES_FOR_SMALL_HEADER &&
                    props.generalInfo.attributes.some((attribute: IAttribute) => {
                        return attribute.headerName.length > MAX_ATTRIBUTE_LENGTH_FOR_SMALL_HEADER;
                    })
                        ? { maxWidth: "100%" }
                        : { maxWidth: "50%" }
                }
            >
                <OverviewData
                    headline={props.generalInfo.headline}
                    attributes={props.generalInfo.attributes}
                    columnNumber={props.generalInfo.columnNumber}
                    data={props.generalInfo.data}
                    viewStackAdd={viewStackAdd}
                    versionView={props.generalInfo.versionView}
                />
            </div>

            {viewStack.length !== 0 ? (
                cloneElement(viewStack[viewStack.length - 1].viewElement, {
                    viewStackAdd: viewStackAdd,
                })
            ) : (
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(" + props.tiles.length + ", 1fr)",
                        gap: "1rem",
                        marginTop: "2rem",
                    }}
                >
                    {props.tiles.map((tile: IOverviewTile) => {
                        return (
                            <OverviewTile
                                key={createKey("overview", tile.headline.title)}
                                headline={tile.headline}
                                cardDefinition={tile.cardDefinition}
                                orientation={tile.orientation}
                                cardWithoutBorder={tile.cardWithoutBorder}
                                data={tile.data}
                                icons={tile.icons}
                                idFields={tile.idFields}
                                onClick={tile.onClick}
                                typeField={tile.typeField}
                                detailView={tile.detailView}
                                viewStackAdd={viewStackAdd}
                                needReadMoreButton={tile.needReadMoreButton}
                                disabled={tile.disabled}
                                versionView={tile.versionView}
                            />
                        );
                    })}
                </div>
            )}

            <Footer buttons={footer} />
        </div>
    );
};
