/* author: JESCHKE Moritz */

import { RootState } from "application/redux/Store";
import { useSelector } from "react-redux";

/**
 * Manage all Transaction pages (one for the transaction overview and one for every selected transaction, which contain the six subpages)
 */
export const Transactions = (): JSX.Element => {
    //current page id and if it is income or transaction, which is displayed (global stored)
    const currentPageId: number = useSelector((state: RootState) => state.transaction.selectedIndex);
    const isIncome: boolean = useSelector((state: RootState) => state.transaction.isIncome);
    //all available pages (global stored)
    const transactionPages = useSelector((state: RootState) => state.transaction.transactionPages);
    const incomePages = useSelector((state: RootState) => state.transaction.incomePages);

    return isIncome ? incomePages[currentPageId].content : transactionPages[currentPageId].content;
};
