import { AnyAction, createSlice, Reducer } from "@reduxjs/toolkit";

interface ISetDialog {
    payload: {
        benefAcctNm: string;
        benefAcctKey: string;
        issuerAcctNm: string;
        issuerAcctKey: string;
        from: Date;
        to: Date;
    };
    type: string;
}

interface IState {
    benefAcctNm: string;
    benefAcctKey: string;
    issuerAcctNm: string;
    issuerAcctKey: string;
    from: Date;
    to: Date;
}

const initialState: IState = {
    benefAcctNm: "",
    benefAcctKey: "",
    issuerAcctNm: "",
    issuerAcctKey: "",
    from: new Date(),
    to: new Date(),
};

export const TedSlice = createSlice({
    name: "ted",
    initialState: initialState,
    reducers: {
        setTedDialogData: (state, action: ISetDialog) => {
            return {
                ...state,
                benefAcctNm: action.payload.benefAcctNm,
                benefAcctKey: action.payload.benefAcctKey,
                issuerAcctNm: action.payload.issuerAcctNm,
                issuerAcctKey: action.payload.issuerAcctKey,
                from: action.payload.from,
                to: action.payload.to,
            };
        },
    },
});

export const { setTedDialogData } = TedSlice.actions;
export const tedSliceReducer: Reducer<IState, AnyAction> = TedSlice.reducer;
