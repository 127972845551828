/* author: Aashish Upadhyay*/

import { KeyTableConsts } from "components/KeyTableConsts";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";

export const InventoryReconcilationSecuritySearchMask = (): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "branch", title: "Filalnummer" },
        { field: "accountNr", title: "Kontonummer" },
        { field: "isin", title: "Instrument" },
        { field: "secPosDiv01", title: "Verwahrart" },
        { field: "secPosDiv02", title: "Sonderheit" },
        { field: "secPosDiv03", title: "Lagerstelle" },
        { field: "secPosDiv04", title: "Stückeart" },
        { field: "secPosDiv05", title: "Sperrenart 1" },
        { field: "secPosDiv06", title: "Sperrfrist 1" },
        { field: "secPosDiv07", title: "Sperrenart 2" },
        { field: "secPosDiv08", title: "Sperrfrist 2" },
        { field: "secPosDiv09", title: "Sonstige 1" },
        { field: "secPosDiv10", title: "Sonstige 2" },
        { field: "amount", title: "Bestandsnominal (Stichtag)" },
        { field: "currency", title: "Stk/Whrg (Stichtag)" },
        { field: "extAmount", title: "Bestandsnominal (Abgleich)" },
        { field: "extAmountCur", title: "Stk/Whrg(Abgleich)" },
        { field: "amountRecon", title: "Bestandsnominal" },
        { field: "amountReconCur", title: "Stk/Whrg" },
        { field: "lastExecState", title: "Letzter Geschäftstag", type: "date" },
        { field: "refDelSystem", title: "Liefersystem" },
        { field: "dateRecon", title: "Stichtag", type: "date" },
        { field: "stateRecon", title: "Abgleichstatus" },
    ];

    const searchCriterias: ISearchCriteria[] = [
        { field: "wkn", label: "WKN*" },
        { field: "isin", label: "ISIN*" },
        {
            field: "stateRecon",
            label: "Abgleichstatus",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.STATE_RECON, allOption: true },
        },
        { field: "accountNr", label: "Kontonummer*" },
        {
            field: "",
            label: "",
            type: {
                singleDatePicker: {
                    label: "Stichtag",
                    field: "dateRecon",
                    discriminator: "single",
                },
            },
        },
        {
            field: "refDelSystem",
            type: "dropDown",
            label: "Liefersystem",
            keytable: { tabname: KeyTableConsts.DELSYS },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/cgtransactions/searchSecReconData" }];

    return (
        <Search
            title={"Bestandsabgleich Wertpapiere"}
            searchCriterias={searchCriterias}
            columns={columns}
            searchUrls={searchUrls}
            isExportable={true}
            identifier="secReconDataSearch"
            hideTechnicalColumns
        />
    );
};
