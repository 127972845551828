/* author: SINDHU KANNAN */
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn, ITableContextMenu } from "components/Table";
import { useState } from "react";
import { SECURITY_TAB, canceledRowColorScheme } from "./TransactionOverview";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { KeyTableConsts } from "components/KeyTableConsts";

export const secTranColumns: ITableColumn[] = [
    { field: "securityData.secCode", title: "WKN/ISIN" },
    { field: "securityData.shortName", title: "Kurzbezeichnung" },
    { field: "accountData.branch", title: "Filialnummer" },
    { field: "accountData.accountNr", title: "Kontonummer" },
    { field: "accountData.accountType", title: "Kontoart" },
    { field: "extBusinessRef", title: "Transaktionsreferenz" },
    { field: "extTranKey", title: "Umsatzreferenz" },
    { field: "extEvent", title: "Geschäftsart" },
    { field: "extEventGroup", title: "Vorgangsgr.-Schlüssel" },
    { field: "taxEvent", title: "StGA" },
    { field: "nominal", title: "Umsatz" },
    { field: "referenceNominal", title: "Referenz-nominal" },
    { field: "nomCur", title: "Stk/Whrg" },
    { field: "tradeMarketValue", title: "Kurswert Handels Whrg" },
    { field: "tradeCur", title: "Handels Whrg" },
    { field: "tradeAddExpCost", title: "NK Abr.-Whrg" },
    { field: "tradeExchRate", title: "DevKurs Handels Whrg" },
    { field: "settlCur", title: "Abr.-Whrg" },
    { field: "settlExchRate", title: "DevKurs Abr.-Whrg" },
    { field: "settlMarketValue", title: "Kurswert Abr.-Whrg" },
    { field: "settlAddExpCost", title: "Anschaff. NK Abr.-Whrg" },
    { field: "marketValueEur", title: "Gegenwert EUR" },
    { field: "fractionPaymentT", title: "Spitzenausgleich in Handels Whrg" },
    { field: "fractionPaymentE", title: "Spitzenausgleich in EUR" },
    { field: "fractionPaymentS", title: "Spitzenausgleich in Abr.-Whrg" },
    { field: "retProcFundT", title: "ID905 Thes. Ertr. Handels Whrg" },
    { field: "retProcFundS", title: "ID905 Thes. Ertr. Abr.-Whrg" },
    { field: "retProcFundE", title: "ID905 Thes. Ertr. EUR" },
    { field: "estProfitFundT", title: "Immobiliengewinn Handels Whrg" },
    { field: "estProfitFundS", title: "Immobiliengewinn Abr.-Whrg" },
    { field: "estProfitFundE", title: "Immobiliengewinn EUR" },
    { field: "refDelSystem", title: "Liefersystem" },
    {
        field: "executionTime",
        title: "Ausführ.-Datum",
        type: "datetime",
    },
    {
        field: "valutA",
        title: "Valuta",
        type: "date",
    },
    {
        field: "taxBusinessDay",
        title: "Steuer-Datum",
        type: "date",
    },
    {
        field: "bookingDate",
        title: "Buchungs-Datum",
        type: "date",
    },
    {
        field: "instructionDate",
        title: "Weisungs-Datum",
        type: "date",
    },
    {
        field: "settlValueDate",
        title: "Stückevaluta-Datum",
        type: "date",
    },
    { field: "reinvestValueT", title: "Wiederanlagewert Handels Whrg" },
    { field: "subPriceT", title: "Bezugspreis EUR" },
    { field: "subPriceValT", title: "BZR/OS Handels Whrg" },
    { field: "detachRatioN", title: "Trennverh. Zähler" },
    { field: "detachRatioD", title: "Trennverh. Nenner" },
    { field: "subscriptRatioN", title: "Bezugsverh. Zähler" },
    { field: "subscriptRatioD", title: "Bezugsverh. Nenner" },
    { field: "switchRatioN", title: "Umtauschverh. Zähler" },
    { field: "switchRatioD", title: "Umtauschverh. Nenner" },
    { field: "valueRatioN", title: "Tilgungsfaktor alt" },
    { field: "valueRatioD", title: "Tilgungsfaktor neu" },
    {
        field: "detachDate",
        title: "Trenntermin",
        type: "date",
    },
    {
        field: "exDate",
        title: "Extag",
        type: "date",
    },
    { field: "valuationEur", title: "Bewertung am Bewertungstag in EUR" },
    {
        field: "valuationDate",
        title: "Bewertungs-Datum",
        type: "date",
    },
    {
        field: "acqValuationDate",
        title: "Tag des ermittelten Wertpapierkurses",
        type: "date",
    },
    { field: "valuationS", title: "Bewertung am Bewertungstag in Abr.-Whrg" },
    { field: "cashCompS", title: "Barausgleich Abr.-Whrg" },
    { field: "marketValueOld", title: "Kurs Altaktie" },
    {
        field: "marketValueDate",
        title: "Datum Kurs Altaktien",
        type: "date",
    },
    { field: "equiCashDiviE", title: "Gegenwert Bardividende EUR" },
    { field: "execState", title: "Ausführungsstatus" },
    { field: "capIncomeS", title: "Einkünfte aus KAP" },
    { field: "interimGainT", title: "Zwischengewinn Handels Whrg" },
    { field: "interimGainS", title: "Zwischengewinn Abr.-Whrg" },
    { field: "interimGainExchRate", title: "Zwischengewinn EUR" },
    { field: "nonCashBenefitS", title: "Geldwerter Vorteil Abr.-Whrg" },
    { field: "nonCashBenefitT", title: "Geldwerter Vorteil Handels Whrg" },
    { field: "nonCashBenefitE", title: "Geldwerter Vorteil EUR" },
    { field: "accruedInterestT", title: "Stückzinsen Abr.-Whrg" },
    { field: "accruedInterestS", title: "Stückzinsen Handels Whrg" },
    { field: "accruedInterestE", title: "Stückzinsen EUR" },
    { field: "rightValueExcRate", title: "DevKurs Whrg BZR/OS" },
    { field: "rightValueCurFlag", title: "Whrg BZR/OS" },
    { field: "cancellationFlag", title: "StoKZ" },
    { field: "cancellationExtTranKey", title: "Umsatzreferenz stornierter Umsatz" },
    { field: "bidData.bidWm", title: "externe BID" },
    { field: "manualCheckRequired", title: "Manuelle Prüfung" },
    { field: "retProcEstEur", title: "ID917 Schätzwert fehlender aussch. gl. Erträge" },
    { field: "retProcOldProfitEur", title: "ID919\nAkk. ausgesch. Altveräußerungs-gewinne" },
    { field: "retProcSubstEur", title: "ID920\nAkk. Substanz-ausschüttung" },
    { field: "retProcAdjEur", title: "ID921 Bereinigte akkumulierte aussch. gl. Erträge" },
    { field: "nullIndicator", title: "Null-Indikator" },
    {
        field: "ntrlMergerFundsDate",
        title: "Datum steuerneutrale Fondsfusion",
        type: "date",
    },
    {
        field: "diffEnrichmentDate",
        title: "Abweichendes Anreicherungsdatum für ID-Felder",
        type: "date",
    },
    { field: "id905AutoEnrichment", title: "ID905\nKZ auto. Anreicherung" },
    { field: "id909AutoEnrichment", title: "ID909\nKZ auto. Anreicherung" },
    { field: "id917AutoEnrichment", title: "ID917\nKZ auto. Anreicherung" },
    { field: "id919AutoEnrichment", title: "ID919\nKZ auto. Anreicherung" },
    { field: "id920AutoEnrichment", title: "ID920\nKZ auto. Anreicherung" },
    { field: "id921AutoEnrichment", title: "ID921\nKZ auto. Anreicherung" },
    { field: "gv512AutoEnrichment", title: "GV512\nKZ auto. Anreicherung" },
    { field: "comment", title: "Kommentar" },
    { field: "overCounterFlag", title: "Tafelgeschäft" },
];

export const SecurityTransactionSearchMask = (): React.JSX.Element => {
    const BRANCH_NUMBER_LENGTH = 4;
    const [currentValues, setCurrentValues] = useState<Record<string, string>>({});

    const searchCriterias: ISearchCriteria[] = [
        { field: "extTranKey", label: "Umsatzreferenz*" },
        { field: "extBusinessRef", label: "Transaktionsreferenz*" },
        { field: "wkn", label: "WKN*" },
        { field: "isin", label: "ISIN*" },
        { field: "branch", label: "Filialnummer" },
        {
            field: "accountNumber",
            label: "Kontonummer*",
            disabled: currentValues["branch"] === undefined || currentValues["branch"].length !== BRANCH_NUMBER_LENGTH,
        },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
        },

        {
            field: "",
            label: "Ausführungszeitpunkt",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "from", discriminator: "from" },
                    to: { label: "bis", field: "to", discriminator: "to" },
                },
            },
        },
        {
            field: "stgaFrom",
            label: "Steuerliche Geschäftsart von",
            type: "dropDown",
            comboBoxLowestFieldName: "stgaTo",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TCG },
        },
        {
            field: "stgaTo",
            label: "Steuerliche Geschäftsart bis",
            type: "dropDown",
            comboBoxHighestFieldName: "stgaFrom",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TCG },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/cgtransactions/secTranSearch" }];
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <Search
            title={"Wertpapier Transaktionen"}
            searchCriterias={searchCriterias}
            columns={secTranColumns}
            identifier={"securityTransactionSearch"}
            searchUrls={searchUrls}
            setCurrentValues={setCurrentValues}
            isExportable={true}
            rowColorScheme={canceledRowColorScheme}
            contextMenu={{
                element: (
                    <TransactionContextMenu
                        contextMenus={contextMenus}
                        setContextmenus={setContextMenus}
                        currentTabText={SECURITY_TAB}
                    />
                ),
                tableElement: contextMenus[SECURITY_TAB],
            }}
            checkPairFields={[["stgaFrom", "stgaTo"]]}
        />
    );
};
