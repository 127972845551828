/* author: PAL Prashant */

import { AllowedRequestsTypeGet } from "application/api/AllowedRequestsType";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn } from "components/Table";
import { VersionsView } from "components/VersionsView";
import { FSA_LIMIT_SECTION } from "./FsaLimitVersionEdit";

interface IFsaLimitVersion {
    fsaLimitId: number;
}
export const fsalimitEditIdentifier: string = "fsalimit ";

export const FsaLimitVersion = (props: IFsaLimitVersion): React.JSX.Element => {
    const columns: ITableColumn[] = [
        {
            field: "exemptAmount",
            title: "Freistellungsbetrag",
        },
        {
            field: "taxDate",
            title: "Steuerliches Zuflussdatum des SPB",
            type: "date",
        },
        {
            field: "fiscalYear",
            title: "Steuerjahr",
        },
        {
            field: "execState",
            title: "Ausführungsstatus",
        },
        { field: "identification", title: "FSA Identifikation Nr." },
    ];

    const IDENTIFIER: TableidentifierType = "fsaLimit";
    const dataRequest: AllowedRequestsTypeGet = {
        path: "/customerstaticdata/fsaNv/fsaLimit-all-timeslices",
        params: { fsaNvId: props.fsaLimitId },
    };

    return (
        <div>
            <VersionsView
                columnsDefinition={columns}
                identifier={IDENTIFIER}
                title="FSA Limit"
                dataRequest={dataRequest}
                historyPath="fsaLimitHistory"
                editSection={FSA_LIMIT_SECTION}
                editIdentifier={fsalimitEditIdentifier}
            />
        </div>
    );
};
