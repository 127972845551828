/* author: JESCHKE Moritz */
import { ChangeEvent, useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    Input,
    InputLabel,
    SelectChangeEvent,
    Switch,
    Typography,
} from "@mui/material";
import {
    sendGetRequest,
    sendPostRequestWithUrlEncodedParams,
    setAuthToken,
    setUrl,
} from "application/api/HttpRequestHandler";
import { useAppDispatch } from "application/redux/Hooks";
import { setUserConfiguration } from "application/redux/slices/UserConfigurationSlice";
import { setUser } from "application/redux/slices/UserSlice";
import { AppDispatch } from "application/redux/Store";
import axios from "axios";
import sopra from "../../img/sopra.png";
import tributum from "../../img/tributum.png";
import { ComboBox } from "components/ComboBox";
import { HttpStatus } from "application/api/HttpStatusCodes";

//Added to the username as a suffix for the user config save and read requests. Is used so the new client has unique usernames in this table, because the old client also writes with the usernames into this table.
//Old client can't work with new client config and other way around.
export const USER_PREFERENCES_SUFFIX = "NewClient";

let refreshToken: string = "";

export const clearRefreshToken = (): void => {
    refreshToken = "";
};

//TODO :: remove with the remove of autologin
export const setRefreshToken = (newRefreshToken: string): void => {
    refreshToken = newRefreshToken;
};

export const renewToken = async (): Promise<void> => {
    const request = await sendGetRequest(
        {
            path: "/preferences/user/renewToken",
            params: { refreshToken: refreshToken },
        },
        false
    );

    //save auth token
    //TODO :: fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setAuthToken(request.headers.authorization);
};

export type USER_ROLES = "ADMIN" | "WRITER" | "VIEWER";
export const USER_ADMIN: USER_ROLES = "ADMIN";

export const Login = (): React.JSX.Element => {
    const dispatch: AppDispatch = useAppDispatch();

    /* ### Variables & States ### */
    const [testset, setTestset] = useState<string>("001");
    const [testSetOptions, setTestSetOptions] = useState<{ ["value"]: string }[]>([]);
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

    const [lastMod, setLastMod] = useState<string>("");
    const [server, setServer] = useState<string>("");

    /* ### Functions ### */

    const getServerInformation = async () => {
        const headers = (await axios.get(window.location.href)).headers;

        setLastMod(headers["last-modified"]);
        setServer(headers.server);
    };

    useEffect(() => {
        if (process.env.REACT_APP_STATUS !== "development") {
            getServerInformation();
        }
    }, []);

    /**
     * Call function if special key is pressed
     * @param event KeyPressEvent
     */
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            login();
        }
    };

    /**
     * check username an password with the backend
     * if correct pull the user config & save auth token in sessionStorage
     */
    const login = async () => {
        //save testsetNumber
        setUrl(testset);

        const loginRequest = await sendPostRequestWithUrlEncodedParams(
            {
                path: "/preferences/user/login",
                body: { user: username, password: password },
            },
            false
        );

        if (loginRequest.status === HttpStatus.OK) {
            //save auth token
            //TODO :: fix
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setAuthToken(loginRequest.headers.authorization);
            //TODO :: fix
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            refreshToken = loginRequest.headers.refresh;

            //get user configuration
            const userConfigRequest = await sendGetRequest({
                path: "/preferences/user",
                params: { type: "U", user: username + USER_PREFERENCES_SUFFIX },
            });
            dispatch(setUserConfiguration({ userConfig: userConfigRequest.data }));
            //login
            dispatch(setUser({ isLogedIn: true, userRole: loginRequest.data.toUpperCase() }));
        } else {
            //username or password wrong => error msg
            setShowErrorMsg(true);
            //clear password
            setPassword("");
        }
    };

    useEffect(() => {
        // Generate a JSON with 1..299 for the testset selection
        const TESTSET_NUMBER_CHAR_COUNT: number = 3;
        const values: string[] = Array.from({ length: 299 }, (x, i) =>
            String(i + 1).padStart(TESTSET_NUMBER_CHAR_COUNT, "0")
        );
        const jsonArray: { ["value"]: string }[] = [];
        values.forEach((value: string) => {
            const json: { ["value"]: string } = { value: "" };
            json.value = value;
            jsonArray.push(json);
        });
        setTestSetOptions(jsonArray);
    }, []);

    /* ### HTML ### */

    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {/* Version and Server Information */}
            <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                {![undefined, ""].includes(server) && (
                    <div>
                        {"Server: " + server}
                        <br />
                    </div>
                )}
                {![undefined, ""].includes(lastMod) && (
                    <div>
                        {"Last Modified: " + lastMod}
                        <br />
                    </div>
                )}
                {![undefined, "unknown"].includes(process.env.REACT_APP_CLIENT_REVISION) &&
                    "Revision: " + process.env.REACT_APP_CLIENT_REVISION}
            </div>

            <FormGroup
                sx={{ border: "1px solid black", padding: "1em", borderRadius: "25px" }}
                onKeyDown={handleKeyDown}
            >
                <img src={sopra} style={{ margin: "auto" }} alt="Sopra Steria Logo" />
                <img src={tributum} style={{ margin: "auto" }} alt="Tributum Logo" />
                <Typography variant="h5" sx={{ margin: "auto" }}>
                    Web Client
                </Typography>

                <div style={{ width: "90%", margin: "1rem" }}>
                    <ComboBox
                        variant="standard"
                        sx={{ margin: "1em" }}
                        label="Testset"
                        onChange={(event: SelectChangeEvent<string>) => setTestset(event.target.value)}
                        optionIdentifier="value"
                        optionValue={["value"]}
                        value={testset}
                        options={testSetOptions}
                        width="100%"
                    />
                </div>

                {showErrorMsg && <Typography style={{ color: "red" }}>Benutzername oder Passwort falsch</Typography>}
                <FormControl sx={{ margin: "1em" }}>
                    <InputLabel htmlFor="username">Benutzername</InputLabel>
                    <Input
                        id="username"
                        aria-describedby="my-helper-text"
                        onChange={(event) => setUsername(event.target.value)}
                    />
                </FormControl>
                <FormControl sx={{ margin: "1em" }}>
                    <InputLabel htmlFor="password">Passwort</InputLabel>
                    <Input
                        value={password}
                        id="password"
                        aria-describedby="my-helper-text"
                        type="password"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </FormControl>
                <Button variant="contained" onClick={login}>
                    Anmelden
                </Button>
                <FormControlLabel
                    control={
                        <Switch
                            onChange={(_event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                localStorage.setItem("disableTempElements", String(checked))
                            }
                            defaultChecked={localStorage.getItem("disableTempElements") === "true"}
                        />
                    }
                    label="Disable temporary elements"
                />
            </FormGroup>
        </div>
    );
};
