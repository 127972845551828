/* author: JESCHKE Moritz */

import { useAppSelector } from "application/redux/Hooks";
import { RootState } from "application/redux/Store";
import { FileDownload } from "components/FileDownload";
import { ISearchCriteria } from "components/Search";

export const KestRegistration = (): React.JSX.Element => {
    const clientId: string = useAppSelector((state: RootState) => state.user.clientId);

    const searchCriteria: ISearchCriteria[] = [
        { field: "month", label: "Meldemonat (mm)" },
        { field: "year", label: "Jahr (yyyy)" },
    ];

    return (
        <FileDownload
            searchCriterias={searchCriteria}
            title="KESt-Anmeldung"
            fileDownloadUrl="/jstbresources/kest/registration"
            initialData={{ clientId: clientId }}
            fileName="KESt Anmeldung.xls"
        />
    );
};
