/* author: JESCHKE Moritz */

import { sendGetRequest } from "application/api/HttpRequestHandler";
import { EditMask } from "components/EditComponents/EditMask";
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params, useParams } from "react-router";

export const SECURITY_INCOME_SECTION: string = "Ertragsdaten";

export const SecurityIncomeEdit = (): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const securityId: number = Number(params.securityId);
    const section: string | undefined = params.section;
    const versionId: number = Number(params.versionId);

    const fuEditInfo: IEditDataViewUserConfiguration = {
        headline: SECURITY_INCOME_SECTION,
        attributes: [
            {
                field: "incomeStatus",
                headerName: "ED001 - Status der Meldung",
                dropdown: { keyTableTabname: "TB_WME13" },
            },
            {
                field: "ed004",
                headerName:
                    "ED004 - Dividendenvorankündigung, Dividendenvorschlag, Wählbarkeit der Währung, Investmenterträgnisausschüttungen und Zinszahlungen",
                dropdown: {
                    keyTableTabname: "TB_WME01",
                },
            },
            {
                field: "ed004a",
                headerName:
                    "ED004A - Dividendenvorankündigung, Dividendenvorschlag, Wählbarkeit der Währung, Investmenterträgnisausschüttungen und Zinszahlungen",
                dropdown: { keyTableTabname: "TB_WME15" },
            },
            { field: "ed005", headerName: "ED005 - Dividendenzahlungsart", dropdown: { keyTableTabname: "TB_WME02" } },
            { field: "ed006", headerName: "ED006 - Dividendenart", dropdown: { keyTableTabname: "TB_WME03" } },
            { field: "exDate", headerName: "ED007 - Ex-Tag", type: "date" },
            { field: "ed008a", headerName: "ED008A - Dividendenertrag gesamt" },
            {
                field: "ed008d",
                headerName: "ED008D - Kennz. Nullmeldung ED008A",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed008e", headerName: "ED008E - thesaurierter Ertrag gesamt" },
            {
                field: "ed008f",
                headerName: "ED008F - Kennz. Nullmeldung ED008e",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed008h", headerName: "ED008H - Substanzausschüttung Privatvermögen" },
            { field: "ed008i", headerName: "ED008I - Substanzausschüttung Betriebsvermögen" },
            { field: "ed008k", headerName: "ED008K - KESt BMG nur für dt. Wahldividenden", displayOnly: true },
            { field: "ed651", headerName: "ED651 - KZ. Gem. &43 Abs.1 Satz 1 Nr 1a AEMG", displayOnly: true },
            { field: "ed009a", headerName: "ED009A - Div./Ertr. 1.Teil" },
            { field: "ed010a", headerName: "ED010A - Div./Ertr. 2.Teil" },
            { field: "ed011", headerName: "ED011 - Ertragswährung", dropdown: { keyTableTabname: "TB_WMW01" } },
            { field: "ed012", headerName: "ED012 - Quellensteuerart", dropdown: { keyTableTabname: "TB_WME04" } },
            { field: "ed013a", headerName: "ED013A - Steuer/Quellensteuer voll" },
            { field: "ed013b", headerName: "ED013B - Fiktiver Quellensteuersatz Prozent" },
            { field: "ed014a", headerName: "ED014A - Steuer/Quellensteuer vermindert" },
            { field: "ed015a", headerName: "ED015A - Steuer/Quellensteuer rückforderbar" },
            { field: "ed016a", headerName: "ED016A - Auslandsspesen" },
            { field: "ed019", headerName: "ED019 - Datum der Haupt- bzw. Generalversammlung", type: "date" },
            { field: "ed020", headerName: "ED020 - Register-Tag", type: "date" },
            {
                field: "ed021",
                headerName: "ED021 - Zahlbarkeitstag (Clearstream Banking Frankfurt-International AB)",
                type: "date",
            },
            { field: "ed024a", headerName: "ED024A - Zahlungszeitraum von", type: "date" },
            { field: "ed025a", headerName: "ED025A - Zahlungszeitraum bis", type: "date" },
            { field: "ed028a", headerName: "ED028A - Kursnotierungsart", dropdown: { keyTableTabname: "TB_WMG37" } },
            { field: "ed028b", headerName: "ED028B - Fester Umrechnungskurs" },
            { field: "ed030a", headerName: "ED030A - KSt-erhöhungsbetrag Par. 52" },
            {
                field: "ed032",
                headerName: "ED032 - Dividende/Ertrag Prozent",
                dropdown: { keyTableTabname: "TB_WMW03" },
            },
            { field: "ed033", headerName: "ED033 - Auslandsspesen Prozent", dropdown: { keyTableTabname: "TB_WMW03" } },
            { field: "ed034", headerName: "ED034 - Höhe Steuergutschrift", dropdown: { keyTableTabname: "TB_WME08" } },
            {
                field: "ed035",
                headerName: "ED035 - Steuer-, bzw. Quellensteuerart",
                dropdown: { keyTableTabname: "TB_WME09" },
            },
            { field: "ed036a", headerName: "ED036A - Dividendenanteil KSt-erhöhungsbetrag" },
            { field: "ed037", headerName: "ED037 - Dividende/Ertrag (EK 45)" },
            {
                field: "ed037a",
                headerName: "ED037A - Kennz. Nullmeldung Dividende/Ertrag (EK45)",
                dropdown: { keyTableTabname: "TB_WME12" },
            },
            { field: "ed037b", headerName: "ED037B - Dividende/Ertrag (EK 40)" },
            {
                field: "ed037c",
                headerName: "ED037C - Kennz. Nullmeldung Dividende/Ertrag (EK40)",
                dropdown: { keyTableTabname: "TB_WME12" },
            },
            { field: "ed039a", headerName: "ED039A - Div./Ertrag Netto" },
            { field: "ed042", headerName: "ED042 - Steuerpfl. Ertrag Par.20 Abs. 1 Nr. 1 EstG" },
            { field: "ed043", headerName: "ED043 - Steuerpfl. Ertrag Par.20 Abs. 1 Nr. 2 EstG" },
            {
                field: "ed044",
                headerName: "ED044 - Steuerpfl. Ertrag Par. 20 Abs. 1 Nr. 2 EStG aus gezeichnetem Kapital",
            },
            { field: "ev045a", headerName: "EV045A - ZaSt.pfl.Betrag 1" },
            { field: "ev045b", headerName: "EV045B - ZaSteuerbetrag 1" },
            { field: "ed047", headerName: "ED047 - ZASt-SATZ auf Depotbestände" },
            { field: "ed048", headerName: "ED048 - Körperschaftsteuerminderungsbetrag (Par.37)" },
            { field: "ed050", headerName: "ED050 - Währung Investment", dropdown: { keyTableTabname: "TB_WMW01" } },
            {
                field: "ed053a",
                headerName:
                    "ED053A - Steuerpflichtiger Ertrag Privatvermögen ohne KSt-Guthaben und einbehaltene KapSt (AV)",
            },
            {
                field: "ed054a",
                headerName:
                    "ED054A - Steuerpflichtiger Ertrag Betriebsvermögen ohne KSt-Guthaben und einbehaltene KapSt (AV)",
            },
            { field: "ed055a", headerName: "ED055A - Anrechenbare Quellensteuer, Privatvermögen" },
            { field: "ed056a", headerName: "ED056A - Anrechenbare Quellensteuer, Betriebsvermögen" },
            { field: "ed057a", headerName: "ED057A - ausländischer Ertragsanteil" },
            { field: "ed058a", headerName: "ED058A - Steuerpflichtiger Ertrag Par. 17,1" },
            { field: "ed059a", headerName: "ED059A - Steuerpflichtiger Ertrag Par. 18,3" },
            { field: "ed060", headerName: "ED060 - Tag des Zuflusses", type: "date" },
            { field: "ed061b", headerName: "ED061B - Dividendenanteil (AV)" },
            {
                field: "ed065a",
                headerName:
                    "ED065A - Steuerpflichtiger Ertrag Privatvermögen inkl. KSt-Guthaben und einbehaltener KapSt (AV)",
            },
            {
                field: "ed066a",
                headerName:
                    "ED066A - Steuerpflichtiger Ertrag Betriebsvermögen inkl. KSt-Guthaben und einbehaltener KapSt (AV)",
            },
            { field: "ed070", headerName: "ED070 - SolZ Prozentsatz" },
            { field: "ed073a", headerName: "ED073A - Dividendenanteil (Halbeinkünfteverfahren)" },
            { field: "ed074", headerName: "ED074 - KESt-Steuerbetrag" },
            {
                field: "ed074a",
                headerName: "ED074A - KZ Nullmeldung KESt-Steuerbetrag",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed075", headerName: "ED075 - deutscher Dividendenanteil EK 01" },
            { field: "ed076", headerName: "ED076 - deutscher Dividendenanteil EK 04" },
            {
                field: "ed077",
                headerName: "ED077 - Ertragsart (US-Quellensteuerverfahren)",
                dropdown: { keyTableTabname: "TB_WMF89" },
            },
            { field: "ed100", headerName: "ED100 - Steuerpfl. Ertrag ohne KapSt (20%) HEV / Privatvermögen" },
            { field: "ed101", headerName: "ED101 - Steuerpfl. Ertrag inkl. KapSt (20%) HEV /Privatvermögen" },
            { field: "ed101a", headerName: "ED101a - Kennz. Nullmeldung ED101a" },
            { field: "ed102", headerName: "ED102 - Steuerpfl. Ertrag ohne KapSt (20%) HEV / Betriebsvermögen" },
            { field: "ed103", headerName: "ED103 - Steuerpfl. Ertrag inkl. KapSt (20%) HEV /Betriebsvermögen" },
            { field: "ed103a", headerName: "ED103a - Kennz. Nullmeldung ED103a" },
            { field: "ed107", headerName: "ED107 - Anrechenbare QueSt nach Priv.- / Betr.-Vermögen" },
            { field: "ed107a", headerName: "ED107A - Anrechenbare QueSt nach Priv.- / Betr.-Vermögen Zinsen" },
            {
                field: "ed109",
                headerName: "ED109 - Ausländischer Ertragsanteil 100% nach Priv.- / Betr.-Vermögen (AV)",
            },
            {
                field: "ed110",
                headerName: "ED110 - Ausländischer Ertragsanteil 50% nach Priv.- / Betr.-Vermögen (HEV)",
            },
            { field: "ed111", headerName: "ED111 - KapSteuersatz für die Mischausschüttung (Fonds)" },
            {
                field: "ed112",
                headerName:
                    "ED112 - Zinsen und andere Erträge nach altem Recht für Privatvermögen. (Par.20 - AV Priv.)",
            },
            { field: "ed112a", headerName: "ED112a - Kennz. Nullmeldung ED112a" },
            {
                field: "ed113",
                headerName:
                    "ED113 - Zinsen und andere Erträge nach altem Recht für Betriebsvermögen. (Par.20 - AV Betr.)",
            },
            { field: "ed113a", headerName: "ED113a - Kennz. Nullmeldung ED113a" },
            {
                field: "ed116",
                headerName:
                    "ED116 - Steuerpflichtiger Dividendenbruttoertrag mit KST, KapSt und SolZ. (Par.20 - AV Priv.)",
            },
            {
                field: "ed117",
                headerName:
                    "ED117 - Steuerpflichtiger Dividendenbruttoertrag mit KST, KapSt und SolZ. (Par.20 - AV Betr.)",
            },
            { field: "ed118", headerName: "ED118 - Ertr.th. o. aR PV" },
            { field: "ed119", headerName: "ED119 - Ertr.th. o. aR BV" },
            {
                field: "ed120",
                headerName:
                    "ED120 - Steuerrelevanter Bruttoertrag inkl. anrechenbare KESt und andere Erträge HEV/Betriebsvermögen",
            },
            { field: "ed124", headerName: "ED124 - VG.A.o.REIT.A BV" },
            { field: "ed125", headerName: "ED125 - steuerpflichtiger Veräusserungsgewinn im Betriebsvermögen" },
            { field: "ed126", headerName: "ED126 - Ausländische Einkünfte (DBA Befreiung)" },
            { field: "ed127", headerName: "ED127 - Ausländische Einkünfte mit anrechenbarer Quellensteuer" },
            { field: "ed128", headerName: "ED128 - Ausländische Einkünfte mit Anrechnung fiktiver Quellensteuer" },
            { field: "ed129", headerName: "ED129 - Abziehbare ausländische Quellensteuer" },
            { field: "ed130", headerName: "ED130 - Fiktiver ausländischer Quellensteuerbetrag" },
            { field: "ed130a", headerName: "ED130A - Fiktiver ausländischer Quellensteuerbetrag (Zinsen)" },
            {
                field: "ed132",
                headerName: "ED132 - Kennz. Nullmeldung in EV045 Pos. A",
                dropdown: { keyTableTabname: "TB_WME12" },
            },
            {
                field: "ed133",
                headerName: "ED133 - Kennz. Nullmeldung in EV045 Pos. B",
                dropdown: { keyTableTabname: "TB_WME12" },
            },
            { field: "ed134", headerName: "ED134 - Thesaurierung Brutto" },
            {
                field: "ed134a",
                headerName: "ED134A - Kennz. Nullmeldung ED134",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed134b", headerName: "ED134B - Thesaurierung (ZA)" },
            {
                field: "ed134c",
                headerName: "ED134C - Kennz. Nullmeldung ED134b",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed135", headerName: "ED135 - Mehrbetrag" },
            { field: "ed136", headerName: "ED136 - Betrag Progressionsvorbehalt" },
            {
                field: "ed137",
                headerName: "ED137 - Währung zum Progressionsvorbehalt",
                dropdown: { keyTableTabname: "TB_WMW02" },
            },
            {
                field: "ed138",
                headerName: "ED138 - Kennz. Ford. Fondverm. ZIV",
                dropdown: { keyTableTabname: "TB_WMGE6" },
            },
            {
                field: "ed138a",
                headerName: "ED138A - Kennz. Ford. Fondverm. ZIV CH",
                dropdown: { keyTableTabname: "TB_WMGE6" },
            },
            {
                field: "ed138b",
                headerName: "ED138B - Kennz. Ford. Fondverm. ZIV AT",
                dropdown: { keyTableTabname: "TB_WMGE6" },
            },
            {
                field: "ed138c",
                headerName: "ED138C - Kennz. Ford. Fondverm. ZIV LU",
                dropdown: { keyTableTabname: "TB_WMGE6" },
            },
            { field: "ed139", headerName: "ED139 - Prozentsatz Fondverm. ZIV" },
            { field: "ed139a", headerName: "ED139A - Prozentsatz Fondverm. ZIV CH" },
            { field: "ed139b", headerName: "ED139B - Prozentsatz Fondverm. ZIV AT" },
            { field: "ed139c", headerName: "ED139C - Prozentsatz Fondverm. ZIV LU" },
            { field: "ed140", headerName: "ED140 - periodische Zinsanteil ZIV" },
            { field: "ed140a", headerName: "ED140A - periodische Zinsanteil ZIV CH" },
            { field: "ed140b", headerName: "ED140B - periodische Zinsanteil ZIV AT" },
            { field: "ed140c", headerName: "ED140C - periodische Zinsanteil ZIV LU" },
            { field: "ed141", headerName: "ED141 - KZ Nullmeldung ZIV", dropdown: { keyTableTabname: "TB_WME14" } },
            {
                field: "ed141a",
                headerName: "ED141A - KZ Nullmeldung ZIV CH",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed141b",
                headerName: "ED141B - KZ Nullmeldung ZIV AT",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed141c",
                headerName: "ED141C - KZ Nullmeldung ZIV LU",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed144", headerName: "ED144 - Schätzwert" },
            { field: "ed154", headerName: "ED154 - Anrechenbare ausländische Fondsausgangsquellensteuer" },
            { field: "ed200", headerName: "ED200 - KapSt-Bemessungsgrundlage aus inl. Dividenden" },
            {
                field: "ed200a",
                headerName: "ED200A - KZ Nullmeldung KZ Nullmeldung KapSt-Betrag",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed201",
                headerName:
                    "ED201 - Thesaurierung Zinsen und Dividenden ohne DBA-befreite Erträge (Par. 7 Abs. 1 Nr. 3 InvStG)",
            },
            {
                field: "ed201a",
                headerName: "ED201A - KZ Nullmeldung Thesaurierung Zinsen und Dividenden",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed202", headerName: "ED202 - Steuerpflichtige Zinsen und sonstige Erträge im Privatvermögen" },
            {
                field: "ed202a",
                headerName: "ED202A - KZ Nullmeldung Steuerpflichtige Zinsen und sonstige Erträge im Privatvermögen",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed203",
                headerName:
                    "ED203 - Steuerpflichtige Zinsen und sonstige Erträge im Betriebsvermögen (ohne Körperschaften)",
            },
            {
                field: "ed203a",
                headerName: "ED203A - KZ Nullmeldung Steuerpflichtige Zinsen und sonstige Erträge",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed204", headerName: "ED204 - Steuerpflichtiger Bruttobetrag" },
            {
                field: "ed204a",
                headerName: "ED204A - KZ Nullmeldung Steuerpflichtiger Bruttobetrag",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed205",
                headerName:
                    "ED205 - Steuerpflichtiger Bruttobetrag inkl. anrechenbare KapSt(Par. 5 Abs. 1 Nr. 1c) cc) InvStG)",
            },
            {
                field: "ed205a",
                headerName: "ED205A - KZ Nullmeldung Steuerpflichtiger Bruttobetrag inkl. anrechenbare KapSt",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed206",
                headerName:
                    "ED206 - Steuerpflichtige Veräusserungs- und Termingeschäftsgewinne gemäß Par. 20 Abs. 2 EStG im Privatvermögen",
            },
            {
                field: "ed206a",
                headerName: "ED206A - KZ Nullmeldung Steuerpflichtige Veräusserungs- und Termingeschäftsgewinne",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed207",
                headerName: "ED207 - KapSt-Bemessungsgrundlage aus inl. Dividenden (Par. 5 Abs.1 Nr. 1d) InvStG)",
            },
            {
                field: "ed207a",
                headerName: "ED207A - KNZ Nullmeldung KapSt-Bemessungsgrundlage aus inl. Dividenden",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed208",
                headerName: "ED208 - KapSt-Bemessungsgrundlage aus ausl. Dividenden (Par. 5 Abs. 1 Nr.1d) InvStG)",
            },
            {
                field: "ed208a",
                headerName: "ED208A - KZ Nullmeldung KapSt-Bemessungsgrundlage aus ausl. Dividenden",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed209",
                headerName: "ED209 - KapSt-Bemessungsgrundlage aus Zinsen, Mieterträgen (Par. 5 Abs. 1 Nr. 1d) InvStG)",
            },
            {
                field: "ed209a",
                headerName: "ED209A - KZ Nullmeldung KapSt-Bemessungsgrundlage aus Zinsen, Mieterträgen",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            {
                field: "ed210",
                headerName: "ED210 - KESt-BG Zahlenfeld",
            },
            {
                field: "ed210a",
                headerName: "ED210A - KZ Nullmeldung",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed211", headerName: "ED211 - Abgeltungsteuersatz auf Depotbestände" },
            { field: "ed212", headerName: "ED212 - Anrechenbarer Quellensteuersatz" },
            { field: "ed214", headerName: "ED214 - Ang. Ausl. QSt aD" },
            { field: "ed215", headerName: "ED215 - Ang. Ausl. QSt dD" },
            { field: "ed216", headerName: "ED216 - Ang. Ausl. QSt Z." },
            {
                field: "ed219",
                headerName:
                    "ED219 - steuerpflichtige inländische Dividenden gem. Teileinkünfteverfahren im Betriebsvermögen",
            },
            {
                field: "ed234",
                headerName: "ED234 - Steuerabführende Stelle",
                dropdown: { keyTableTabname: "TB_WMGJ8" },
            },
            { field: "ed235", headerName: "ED235 - KESt-Grund.Imm.PV" },
            { field: "ed237", headerName: "ED237 - Ort KG Bundesland", dropdown: { keyTableTabname: "TB_WML07" } },
            { field: "ed237a", headerName: "ED237A - Anzuf. StLiquid." },
            { field: "ed302a", headerName: "ED302A - Poolfaktor" },
            {
                field: "ev308a",
                headerName:
                    "EV308A - Rücknahmepreis bzw. Börsenkurs von Investmentfonds in Abwicklung zur Ermittlung der steuerfreien Kapitalrückzahlung gemäß §17 InvStG",
            },
            {
                field: "ev308b",
                headerName: "EV308B - KZ Nullmeld zu A",
                dropdown: {
                    keyTableTabname: "EV308B",
                },
            },
            { field: "ev308c", headerName: "EV308C - Datum Kurs", type: "date" },
            {
                field: "ev308d",
                headerName: "EV308D - Art Kursdatum",
                dropdown: { keyTableTabname: "TB_WMGD7" },
            },
            { field: "ed318", headerName: "ED318 - Ausschütt.beschl.", type: "date" },
            { field: "ed319", headerName: "ED319 - Anr. ausl. QuSt" },
            { field: "ed320", headerName: "ED320 - Fikt.ausl.QuSt" },
            { field: "ed321", headerName: "ED321 - Umr.kurs Steuerl." },
            { field: "ed400d", headerName: "ED400D - REIT-DIVIDENDEN (Betriebsvermögen KSTG)" },
            { field: "ev421a", headerName: "EV421A - Dvidende REIT-Div. EG 1" },
            { field: "ev421b", headerName: "EV421B - Dividende REIT-Div. KV 1" },
            { field: "ev422a", headerName: "EV422A - VG AE.v2009 EG 1" },
            { field: "ev422b", headerName: "EV422B - VG AE.v2009 KG 1" },
            { field: "ed433", headerName: "ED433 - REIT-DIVIDENDEN (PRIVATVERMöGEN)" },
            { field: "ed435", headerName: "ED435 - DIVIDENDENERTRÄGE OHNE REIT-DIVIDENDEN(PRIVATVERMöGEN)" },
            { field: "ed448", headerName: "ED448 - REIT-DIVIDENDEN (Betriebsvermögen ESTG)" },
            { field: "ed476", headerName: "ED476 - Ausschüttung gemäß §2 ABS. 11 InvStG bei Investmentfonds" },
            { field: "ed476a", headerName: "ED476A - Steuerpflichtige Vorabpauschale in Eurogemäß §18 InvStG" },
            {
                field: "ed476b",
                headerName:
                    "ED476B - Kennzeichnung Nullmeldung in ED476A steuerpflichtige Vorabpauschale in Euro gemäß §18 InvStG",
                dropdown: { keyTableTabname: "TB_WME14" },
            },
            { field: "ed476d", headerName: "ED476D - Stpfl.VP i. FW" },
            { field: "ed476e", headerName: "ED476E - KZNullm. ED476D", dropdown: { keyTableTabname: "TB_WME14" } },
            {
                field: "ed476h",
                headerName:
                    "ED476H - Steuerfreie Substanzausschüttung bei Investmentfonds in Abwicklung gemäß §17 InvStG",
            },
            {
                field: "ed476g",
                headerName: "ED476G - Laufende Ausschüttung bei Investmentfonds in Abwicklung gemäß §17 InvStG",
            },
            { field: "ed476k", headerName: "ED476K - Stpf. VP TF AF30" },
            { field: "ed476m", headerName: "ED476M - Spfl. VP TF M15" },
            { field: "ed476p", headerName: "ED476P - Stpf. VP TF IF60" },
            { field: "ed476r", headerName: "ED476R - Stpf. VP TF IF80" },
            {
                field: "ed476s",
                headerName:
                    "ED476S - Anrechenbare Fondsausgangsquellensteuerunter Berücksichtigung der Teilfreistellung gemäß §20 InvStG bei Anlegern im PV",
            },
        ],
        latestVersionRequest: () =>
            sendGetRequest({
                path: "/security/income/getDataForEdit",
                params: { bidId: versionId },
            }),

        versionRequest: () =>
            sendGetRequest({
                path: "/security/income/getDataForEdit",
                params: { bidId: versionId },
            }),
        checkInvalidity: [
            {
                //"A" -> "Ja" (Keytable value)
                condition: (data: Record<string, unknown>): boolean =>
                    (String(data["ev308a"]) === "0") !== (data["ev308b"] === "A"),
                relatedFields: ["ev308a", "ev308b"],
            },
            {
                //"G" -> "Nein" (Keytable value)
                condition: (data: Record<string, unknown>): boolean =>
                    (String(data["ev308a"]) === "") !== (data["ev308b"] === "G"),
                relatedFields: ["ev308a", "ev308b"],
            },
        ],
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [fuEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={securityId}
            title="Wertpapier"
            section={section}
            parentIdentifier="securityId"
        />
    );
};
