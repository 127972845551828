/* author: SINDHU KANNAN */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";
import { IdSelector } from "components/TBD/IdSelector";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface ITaxResultDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    impId: number;
    extTranKey: string;
    accountNr: string;
    system: "F" | "C";
}

export const TaxResultDetails = (props: ITaxResultDetails): React.JSX.Element => {
    const [impId, setImpId] = useState<string>(props.impId.toString());
    const IDENTIFIER: TableidentifierType = "taxResult";

    const columns: ITableColumn[] = [
        {
            field: "dwtPerson1",
            title: "AgSt nach Kirchensteuer",
            combinedFields: ["dwtPerson2"],
            combinedFieldsSeparator: " ",
        },
        { field: "dwtBefChurchTax", title: "AgSt vor Kirchensteuer" },
        {
            field: "soliPerson1",
            title: "Summe Soli",
            combinedFields: ["soliPerson2"],
            combinedFieldsCalculation: "SUM",
        },
        {
            field: "churchTaxPerson1",
            title: "Summe KiSt",
            combinedFields: ["churchTaxPerson2"],
            combinedFieldsCalculation: "SUM",
        },
        { field: "offsetableTaxLoss", title: "Summe QueSt" },
        { field: "totalShareProfit", title: "Änderung Salden Aktiengewinn" },
        { field: "totalOtherProfit", title: "Änderung Salden sonstiger Gewinn" },
        { field: "totalShareLoss", title: "Änderung Salden Aktienverlust" },
        { field: "totalOtherLoss", title: "Änderung Salden sonstiger Verlust" },
        { field: "statusCertDwt", title: "AgSt Fondseingangsseite" },
        { field: "statusCertSoliSurchrg", title: "Soli Fondseingangsseite" },
        { field: "exemptStatusCert", title: "Freigestellter\nErtrag durch Statusbescheinigung" },
        { field: "exemptParagraph10", title: "Freigestellter Ertrag durch §10 (InvStG)" },
        { field: "exemptYieldFsa", title: "Freigestellter Betrag FSA" },
    ];

    const [taxResultData, setTaxResultData] = useState<Record<string, string>[]>([]);

    useEffect(() => {
        sendGetRequest({ path: "/cgresources/taxaccounting", params: { impId: +impId, system: props.system } }).then(
            (response) => {
                setTaxResultData(response.data);
            }
        );
    }, [props.system, impId]);
    return (
        <PopUp
            title={"Steuerliche Ergebnisse für " + props.extTranKey + " | " + props.accountNr}
            setOpen={props.setOpen}
            content={
                <div style={{ display: "grid", gap: "3rem" }}>
                    <IdSelector label="impId" setId={setImpId} />
                    <Table
                        columnsDefinition={columns}
                        tableData={taxResultData}
                        identifier={IDENTIFIER}
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                </div>
            }
        />
    );
};
