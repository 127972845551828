/*author:SINDHU KANNAN*/
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { getChangedOrOriginalValue } from "components/Converter";
import { TECHNICAL_EDIT_COLUMNS } from "components/EditComponents/EditMask";
import { PopUp } from "components/PopUp";
import { ITableColumn, ITableRowSelection, Table } from "components/Table";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface ISpecuRucksackDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    impId: number;
    extTranKey: string;
}
export const SpecuRucksackDetails = (props: ISpecuRucksackDetails): React.JSX.Element => {
    const [specutableData, setSpecuTableData] = useState<Record<string, string>[]>([]);

    const IDENTIFIER_SPECUDATA: TableidentifierType = "specu";

    const specutablecolumns: ITableColumn[] = [
        {
            field: "taxTime",
            title: "Steuerlicher Anschaffungstag",
            type: "date",
        },
        {
            field: "taxNominal",
            title: "Steuerliches Restnominal",
            type: "numeric",
        },
        {
            field: "taxPrice",
            title: "Steuerlicher Anschaffungspreis",
            type: "numeric",
        },
        {
            field: "taxPriceSettl",
            title: "Anschaffungspreis in Abrechnungswährung",
            type: "numeric",
        },
        { field: "taxPriceSettlCur", title: "Abrechnungswährung" },
        {
            field: "taxExpCost",
            title: "Steuerliche Nebenkosten",
            type: "numeric",
        },
        {
            field: "taxExpCostSettl",
            title: "Steuerliche Nebenkosten in Abrechnungswhg",
            type: "numeric",
        },
        {
            field: "retProcFund",
            title: "thesaurierter Ertrag Fond in EURO",
            type: "numeric",
        },
        {
            field: "interimGain",
            title: "Zwischengewinn EURO",
            type: "numeric",
        },
        { field: "isSpecuValuable", title: "Spekuelement bewertbar" },
        { field: "isSpecuTaxFree", title: "Spekuelement steuerfrei" },
        {
            field: "calcRetProcFundEur",
            title: "berechneter thesaurierter, akkumulierter Ertrag in EUR pro Anteil",
            type: "numeric",
        },
        {
            field: "calcPenaltyTaxAmtEur",
            title: "berechneter akkumulierter strafbesteuerter Mehrbetrag pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "penaltyTaxAmountEur",
            title: "akkumulierter strafbesteuer Mehrbetrag in EUR pro Anteil",
            type: "numeric",
        },
        { field: "calculationFlag", title: "Berechenbarkeit Transaktion" },
        { field: "calcRetProcStatus", title: "Berechenbarkeit thes. Erträge" },
        { field: "calcPenaltyTaxStatus", title: "Berechenbarkeit strafbesteuerung" },
        { field: "accuRetProcStatus", title: "Berechenbarkeit akkum. thes. Ertrag" },
        {
            field: "collRetainedProcEur",
            title: "Gesammelte thesaurierte Erträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "collPenaltyProcEur",
            title: "Gesammelte Mehrbeträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "fiscalYearStart",
            title: "GD331 Geschäftsjahr von",
            type: "date",
        },
        {
            field: "fiscalYearEnd",
            title: "GD332 Geschäftsjahr bis",
            type: "date",
        },
        {
            field: "estProfitFundEur",
            title: "Immobiliengewinn bei Fonds in EUR",
            type: "numeric",
        },
        {
            field: "retProcEstEur",
            title: "ID917 Schätzwert für fehlende ausschüttungsgl. Erträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "retProcOldprofitEur",
            title: "ID919 Akkumulierte ausgeschüttete Altveräusserungsgew. pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "retProcSubstEur",
            title: "ID920 Akkumulierte Substanzausschüttungen pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "retProcAdjEur",
            title: "ID921 Bereinigte akkumulierte ausschüttungsgl. Erträge pro Anteil in EUR",
            type: "numeric",
        },
        {
            field: "intGainPenaltyEur",
            title: "Ersatzwert Zwischengewinn pro Anteil in EUR",
            type: "numeric",
        },
        { field: "nullIndicator", title: "Indikator für Anschaffungskosten" },
        { field: "dataSource", title: "Kennzeichen, ob geliefert oder berechnet" },
        {
            field: "paymentTaxDepositId",
            title: "ID Stückzinsen in EUR",
            type: "numeric",
        },
        {
            field: "paymentTaxDepositEur",
            title: "Stückzahlen in EUR",
            type: "numeric",
        },
        { field: "delayFlag", title: "Kennzeichen Verzögerung der steuerlichen Abrechnung" },
        {
            field: "accruedInterestEur",
            title: "Stückzinsen bei einem internen Depotübertrag",
            type: "numeric",
        },
        {
            field: "ntrlMergerFundsDate",
            title: "Datum steuerneutraler Fondsfusion",
            type: "date",
        },
        { field: "incomeAdjProc", title: "GD863, Kennzeichen Ertragsausgleichverfahren" },
        {
            field: "diffEnrichmentDate",
            title: "Abweichendes Anreicherungsdatum für ID-Felder",
            type: "date",
        },
        { field: "scrpEnrichmentFlag", title: "Flag ob Anreicherung der Thesaurierungswerte erfolgt" },
        { field: "interimEnrichmentFlag", title: "Flag ob Anreicherung des Zwischengewinns erfolgt" },
        { field: "dbaEnrichmentFlag", title: "Flag ob Anreicherung des DBA-Gewinns erfolgt" },
        { field: "acqTaxEvent", title: "TaxEvent der urspr. Anschaffung" },
        {
            field: "acqValuation",
            title: "Bewertungskurs pro Stück der urspr. Anschaffung",
            type: "numeric",
        },
        { field: "id905AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID905" },
        { field: "id909AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID909" },
        { field: "id917AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID917" },
        { field: "id919AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID919" },
        { field: "id920AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID920" },
        { field: "id921AutoEnrichment", title: "Steuerung der automatischen Anreicherung von ID921" },
        { field: "gv512AutoEnrichment", title: "Comments: Steuerung der automatischen Anreicherung von GV512" },
        {
            field: "fundsSwitchTaxTime",
            title: "Ursprüngliches Anschaffungsdatum",
            type: "date",
        },
        {
            field: "accAssDistrEur",
            title: "Akkumulierte Substanzausschüttung in Euro",
            type: "numeric",
        },
        { field: "originalIsin", title: "Ursprüngliche ISIN aus steuerneutraler Fondsfusion" },
        { field: "mioFunds", title: "Kennzeichen Millionärsfonds" },
        {
            field: "indiAcqCostsE",
            title: "tatsächliche Anschaffungskosten",
        },
        {
            field: "indiAcqCostsNkz",
            title: "Nullkennzeichen tatsächlicher Anschaffungskosten",
        },
        ...TECHNICAL_EDIT_COLUMNS,
    ];
    useEffect(() => {
        sendGetRequest({
            path: "/cgresources/specu",
            params: { impId: +props.impId },
        }).then((response) => {
            setSpecuTableData(response.data);
        });
    }, [props.impId]);

    const [rucksackSpecuId, setRucksackSpecuId] = useState<number>();
    const specuIdSelection: ITableRowSelection = {
        onSelect: (rowData: Record<string, number>) => {
            sendGetRequest({
                path: "/cgresources/rucksack/getRucksackData",
                params: { specuUniqueId: +rowData.specuUniqueId },
            }).then((response) => {
                setRucksackResultData(response.data);
                setRucksackId(undefined);
                setRucksackDetailData([]);
                setRucksackSpecuId(+rowData.specuUniqueId);
            });
        },
        onDeselect: () => undefined,
        selectedRows: [rucksackSpecuId],
        selector: "specuUniqueId",
    };
    //Rucksack Data
    const [rucksackResultData, setRucksackResultData] = useState<Record<string, string>[]>([]);

    const IDENTIFIER_RUCKSACK_TYPE: TableidentifierType = "rucksack";
    const rucksackTypeColums: ITableColumn[] = [
        {
            field: "taxTime",
            title: "W21 - Anschaffungstag fikt. Veräußerung",
            type: "date",
        },
        {
            field: "missingLumpSumFlag_Type001",
            title: "W31 - Kennz. fehlende Ersatz-BMG am 31.12.2017",
            conditionalRender: (rowData: Record<string, string>) =>
                getChangedOrOriginalValue(rowData, "rucksackType") === "Zwangsumtausch",
        },
        {
            field: "missingLumpSumFlag_TypeNot001",
            title: "W45 - Kennz. Fehlende Ersatz-BMG nach dem 31.12.2017",
            conditionalRender: (rowData: Record<string, string>) =>
                getChangedOrOriginalValue(rowData, "rucksackType") !== "Zwangsumtausch",
        },
        { field: "rucksackType", title: "Art des Rucksacks" },
    ];

    const [rucksackDetailData, setRucksackDetailData] = useState<Record<string, string>[]>([]);
    const [rucksackId, setRucksackId] = useState<number>();
    const ruckSackSelection: ITableRowSelection = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelect: (rowData: Record<string, number | any>) => {
            setRucksackId(rowData.id);
            setRucksackDetailData(rowData.rucksackItemDatas);
        },
        onDeselect: () => undefined,
        selectedRows: [rucksackId],
        selector: "id",
    };
    //Rucksack Data Items
    const IDENTIFIER_RUCKSACK_DATA: TableidentifierType = "rucksackData";
    const rucksackDataColums: ITableColumn[] = [
        { field: "type", title: "Rucksack" },
        { field: "amount", title: "Betrag in EUR" },
    ];
    return (
        <PopUp
            title={"Detaillierte Anschaffungsdaten zu Umsatzreferenz: " + props.extTranKey}
            setOpen={props.setOpen}
            content={
                <div style={{ display: "grid", gap: "1rem", marginTop: "1rem" }}>
                    <div style={{ maxWidth: "90vw" }}>
                        <Table
                            columnsDefinition={specutablecolumns}
                            identifier={IDENTIFIER_SPECUDATA}
                            tableData={specutableData}
                            title="Detaillierte Anschaffungsdaten"
                            selection={specuIdSelection}
                            pageSizeOptions="alternativ"
                        />
                    </div>
                    <Table
                        columnsDefinition={rucksackTypeColums}
                        tableData={rucksackResultData}
                        identifier={IDENTIFIER_RUCKSACK_TYPE}
                        selection={ruckSackSelection}
                        title="Rucksäcke"
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                    <Table
                        columnsDefinition={rucksackDataColums}
                        tableData={rucksackDetailData}
                        identifier={IDENTIFIER_RUCKSACK_DATA}
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                </div>
            }
        />
    );
};
