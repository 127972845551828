/* author: JESCHKE Moritz */

import { Divider, IconButton, List, Toolbar } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MenuBarItemList } from "./MenuBarItemList";
import tributumLogo from "../../img/tributum.png";
import { Colors } from "components/Colors";
import { useState } from "react";
import { ButtonSecondary } from "components/Button";

interface IProps {
    toggleMenuBar: () => void;
}

export const Menubar = (props: IProps): React.JSX.Element => {
    const [currentOpenMenus, setCurrentOpenMenus] = useState<string[]>([]);

    return (
        <div style={{ width: "100%" }}>
            <div style={{ position: "sticky", top: 0, background: Colors.white, zIndex: 999 }}>
                <Toolbar
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        px: [1],
                    }}
                >
                    <img src={tributumLogo} style={{ margin: "auto", maxWidth: "80%" }} alt="Tributum Logo" />
                    <IconButton onClick={props.toggleMenuBar}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <ButtonSecondary title="Alles einklappen" onClick={() => setCurrentOpenMenus([])} />
                <Divider />
            </div>
            <List>
                <MenuBarItemList currentOpenMenus={currentOpenMenus} setCurrentOpenMenus={setCurrentOpenMenus} />
            </List>
        </div>
    );
};
