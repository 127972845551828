/* author: PAL Prashant */
import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { Location, useLocation, useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";
import { transactionCancelation } from "pages/transaction-data/SecurityTransactionEdit";

export const INCOME_TRAN_SECTION: string = "Erträge";

export const INCOME_SECTION_IDENTIFIER: string = "incomeSection";

export const IncomeTransactionEdit = (): JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const section: string | undefined = params.section;
    const incomeTranId: number = Number(params.incomeTranId);
    const location: Location = useLocation();
    const isNew: boolean = location.state && location.state.isNew;
    const isCancelation: boolean = location.state && location.state.isCancel;
    // const isDuplication: boolean = location.state && location.state.isDuplicate;

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: "/cgresources/fttransactions/incomeTransactionById-with-keydata",
            params: { id: incomeTranId, isNew: isNew },
        });

    const incomeTransactionEdit: IEditDataViewUserConfiguration = {
        headline: INCOME_TRAN_SECTION,
        attributes: [
            { field: "taxEvent", headerName: "StGA", displayOnly: true, dropdown: { keyTableTabname: "TAXEVENT_TFT" } },
            {
                field: "influxDate",
                headerName: "Zuflussdatum",
                type: "date",
            },
            { field: "docCode", headerName: "externe Referenz" },
            { field: "nominal", headerName: "Nominal" },
            {
                field: "currency",
                headerName: "Nominalwährung",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
            },
            {
                field: "nomCur",
                headerName: "Gattungswährung",
                dropdown: {
                    keyTableTabname: "TB_WMW02",
                },
                displayOnly: true,
            },
            {
                field: "relDate",
                headerName: "Ertragsdatum",
                type: "date",
            },
            {
                field: "transactionDisplayType",
                headerName: "Transaktionstyp",
                dropdown: {
                    keyTableTabname: "TRANSACTIONTYPE",
                },
            },

            {
                field: "cancellationFlag",
                headerName: "StoKZ",
                dropdown: {
                    keyTableTabname: "STORNOFLAG",
                },
            },

            { field: "cancellationExtTranKey", headerName: "Umsatzreferenz stornierter Umsatz", displayOnly: true },
            { field: "extTranKey", headerName: "Umsatzreferenz", displayOnly: true },
            { field: "refDelSystem", headerName: "Liefersystem", displayOnly: true },
            { field: "obligingnessType", headerName: "Kulanzzahlungen" },
            { field: "custodianLocation", headerName: "Inlands- oder Auslandsverwährung" },
            { field: "foreignCustodySetType", headerName: "Belieferung (cum/ex)" },
            { field: "dataContainer", headerName: "DataContainer", displayOnly: true },
            { field: "refOriImpId", headerName: "Umsatzreferenz ursprünglicher Umsatz", displayOnly: true },
            { field: "extTranKeyAssigned", headerName: "Vorgängerreferenz", displayOnly: true },
            { field: "extTranKeyRoot", headerName: "Gruppenreferenz / Ursprungstransaktion", displayOnly: true },
            { field: "zivRelevance", headerName: "ZIV-relevant", displayOnly: true },
            {
                field: "overCounterFlag",
                headerName: "Tafelgeschäft",
                dropdown: { keyTableTabname: "OVER_COUNTER_FLAG" },
            },
            { field: "bidData.bidWm", headerName: "externe BID", displayOnly: true },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
        updateFields: transactionCancelation(isCancelation),
        // duplicate: { condition: isDuplication },
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [incomeTransactionEdit];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={incomeTranId}
            title="Erträge Transaktionen"
            section={section}
            isCancelation={isCancelation}
            //duplication={isDuplication}
            overrideRootLevelForInsert={2}
        />
    );
};
