/* author: PAL Prashant */

import { Footer, IFooter } from "components/Footer";

import { IOverviewButton, IOverviewHeadLine, OverviewHeadLine } from "components/OverviewHeadLine";

import { IApiUrl, IColumn, TableBasic } from "components/TableBasic";

import { IdSelector } from "components/TBD/IdSelector";
import { getEditState, getEditTo } from "components/VersionsView";

import { useState } from "react";

import { useNavigate } from "react-router";
import { SECURITY_SECTION } from "./SecurityEdit";
import { SECURITY_INCOME_SECTION } from "./SecurityIncomeEdit";

interface ISecurityDataDetails {
    id: string;
}

const SecurityDataDetails = (securityUrl: IApiUrl, securityId: string, editTopic: string) => {
    const columns: IColumn[] = [
        { field: "label", headerName: "Feldbezeichnung", minWidth: 180 },

        { field: "value", headerName: "Feldinhalt", minWidth: 170 },

        { field: "description", headerName: "Langbezeichnung", minWidth: 1200 },
    ];

    const styleAddons = {
        "& .MuiDataGrid-virtualScrollerRenderZone": {
            transform: "none !important",

            position: "sticky !important",

            top: "0",
        },
    };

    const footer: IFooter = {
        buttons: [{ title: "Abbrechen", filled: false, onClick: () => navigate("/home") }],
    };

    const [id, setId] = useState<string>(securityId);

    const headline: IOverviewHeadLine = {
        title: "Details",
    };

    const navigate = useNavigate();

    const buttons: IOverviewButton[] = [
        {
            title: "Bearbeiten",
            filled: false,
            onClick: () => {
                navigate(getEditTo(Number(id), editTopic, editTopic), { state: getEditState("U") });
            },
        },
    ];

    return (
        <div>
            <IdSelector label="securityId" setId={setId} />

            <OverviewHeadLine title={headline.title} buttons={buttons} />

            <TableBasic
                columns={columns}
                styleAddons={styleAddons}
                apiUrl={securityUrl}
                id={id}
                combinedIdParams={["label", "description"]}
            />

            <Footer buttons={footer.buttons} />
        </div>
    );
};

export const SecurityMasterDetails = (props: ISecurityDataDetails): React.JSX.Element =>
    SecurityDataDetails({ path: "/security/securities/security", params: "securityId" }, props.id, SECURITY_SECTION);

export const SecurityIncomeDetails = (props: ISecurityDataDetails): React.JSX.Element =>
    SecurityDataDetails({ path: "/security/income/getData", params: "bidId" }, props.id, SECURITY_INCOME_SECTION);
