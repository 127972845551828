/* author: PAL Prashant */
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { PopUp } from "components/PopUp";
import { ITableColumn, Table } from "components/Table";
import { IdSelector } from "components/TBD/IdSelector";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface IValueAdjDetails {
    setOpen: Dispatch<SetStateAction<boolean>>;
    impId: number;
    extTranKey: string;
    type: string;
}

export const ValueAdjDetails = (props: IValueAdjDetails): React.JSX.Element => {
    const [impId, setImpId] = useState<string>(props.impId.toString());

    const IDENTIFIER: TableidentifierType = "valueAdjDetails";

    const columns: ITableColumn[] = [
        { field: "transactionKeyMandant", title: "Umsatzreferenz des berichtigenden Umsatzes" },
        {
            field: "taxTime",
            title: "Steuerlicher Geschäftstag",
            type: "date",
        },
        {
            field: "valuta",
            title: "Valuta",
            type: "date",
        },
        { field: "valueAdjType", title: "Art der Wertberichtigung" },
        { field: "taxNominal", title: "steuerliches Restnominal" },
        { field: "taxValue", title: "steuerliche Wertkorrektur in EUR" },
        { field: "taxValueSettl", title: "steuerliche Wertkorrektur in Abrechnungswährung" },
        { field: "taxValueTrade", title: "steuerliche Wertkorrektur in Handelswährung" },
        { field: "addExpenses", title: "Nebenkosten in EUR" },
        { field: "addExpensesSettl", title: "Anschaffungsneben- oder Werbungskosten in Abrechnungswährung" },
        { field: "settlCur", title: "Abrechnungswährung" },
        { field: "settlCurRate", title: "Devisenkurs Abrechnungswährung" },
        { field: "tradeCur", title: "Handelswährung" },
        { field: "tradeCurRate", title: "Devisenkurs Handelswährung" },
        { field: "processingFlag", title: "Bearbeitungskennzeichen" },
        { field: "userId", title: "UserId der Bearbeitung" },
        { field: "version", title: "Version" },
        {
            field: "validFrom",
            title: "Gültig ab",
            type: "date",
        },
        {
            field: "validTo",
            title: "Gültig bis",
            type: "date",
        },
        { field: "isSpecuValuable", title: "Element bewertbar" },
        { field: "subInstrumentId", title: "Id des Teilinstrumentes" },
        { field: "calcRetProcFundEur", title: "akk. thes. Ertrag in Euro aus StGA 5533" },
        { field: "interimGainEur", title: "ID904 Zwischengewinn in EUR pro Anteil" },
        { field: "calcPenaltyTaxAmtEur", title: "ID909 berechneter akk. strafbesteuerter Mehrbetrag p. Ant. in EUR" },
        { field: "retProcFundEur", title: "akk. thes. Ertrag in Euro aus Umsatz od. ID905	" },
        { field: "calculationFlag", title: "Berechenbarkeit" },
        { field: "paymentTaxDepositEur", title: "Zahlung aus dem st. Einlagekonto in EUR" },
        {
            field: "retProcOldprofitEur",
            title: "ID917 Schätzwert für fehlende ausschüttungsgl. Erträge p. Ant. in EUR",
        },
        { field: "retProcSubstEur", title: "ID919 Akk. ausgeschüttete Altveräußerungsgewinne p. Ant. in EUR" },
        { field: "retProcAdjEur", title: "ID920 Akk. Substanzausschüttungen p. Ant. in EUR	" },
        { field: "collRetainedProcEur", title: "ID921 Bereinigter akk. ausschüttungsgleicher Ertrag p. Ant. in EUR" },
        { field: "calcRetProcFundEur", title: "Gesammelte thesaurierte Erträge pro Anteil in EUR	" },
        { field: "collPenaltyProcEur", title: "Gesammelte Mehrbeträge pro Anteil in EUR	" },
        {
            field: "fiscalYearStart",
            title: "GD331 Geschäftsjahr von (TTMM)",
            type: "date",
        },
        {
            field: "fiscalYearEnd",
            title: "GD332 Geschäftsjahr bis (TTMM)",
            type: "date",
        },
        { field: "estProfitFundEur", title: "Immobiliengewinn Fonds in EUR aus ID902/ID908" },
        { field: "accAssDistrEur", title: "ID927 Akkumulierte Substanzausschüttung" },
        { field: "indiAcqCostsE", title: "Wertkorrektur AK (SubA)" },
        { field: "indiAcqCostsNkz", title: "AK (SubA) bewertbar" },
    ];

    const [valueAdjDetailsData, setValueAdjDetailsData] = useState<Record<string, string>[]>([]);

    useEffect(() => {
        sendGetRequest({
            path: "/cgresources/valueAdj/getValueAdjDataFromImpId",
            params: { impId: +impId, type: props.type },
        }).then((response) => {
            setValueAdjDetailsData(response.data);
        });
    }, [impId, props.type]);
    return (
        <PopUp
            title={"Wertberichtigungen zu Umsatzreferenz " + props.extTranKey}
            setOpen={props.setOpen}
            content={
                <div style={{ display: "grid", gap: "3rem" }}>
                    <IdSelector label="impId" setId={setImpId} />
                    <Table
                        columnsDefinition={columns}
                        tableData={valueAdjDetailsData}
                        identifier={IDENTIFIER}
                        disableHideColumn={true}
                        pageSizeOptions="alternativ"
                    />
                </div>
            }
        />
    );
};
