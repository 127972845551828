/* author: JESCHKE Moritz */

import { ITableContextMenu } from "components/Table";
import { useState } from "react";
import { INCOME_TAB } from "./TransactionOverview";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { EditPendingLinked } from "components/EditPendingLinked";
import { getIncomeColumns } from "./IncomeTranSearch";

export const EditPendingLinkedIncomeTranInfo = (): React.JSX.Element => {
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <EditPendingLinked
            columns={getIncomeColumns()}
            dataRequest={{
                path: "/cgresources/fttransactions/searchIncomeTranEditPending",
            }}
            identifier="editPendingOverviewIncomeTran"
            title="Erträge"
            contextMenu={{
                element: (
                    <TransactionContextMenu
                        contextMenus={contextMenus}
                        setContextmenus={setContextMenus}
                        currentTabText={INCOME_TAB}
                    />
                ),
                tableElement: contextMenus[INCOME_TAB],
            }}
        />
    );
};
