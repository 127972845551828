/* author: JESCHKE Moritz */

import { Typography } from "@mui/material";
import { customDateValueGetterV2 } from "components/Converter";
import { DataViewV2, IDataViewV2DataSet } from "components/DataViewV2";
import { Footer, IFooterButton } from "components/Footer";
import { NavigateFunction, useLocation, useNavigate } from "react-router";

export const CalculationPotAtDaedline = (): React.JSX.Element => {
    const location = useLocation().state;
    const navigate: NavigateFunction = useNavigate();
    const origin = location.origin ? location.origin : "";
    const data = location.data ? location.data : {};
    const extraData = location.extraData ? location.extraData : {};
    const footerButtons: IFooterButton[] = [
        { title: "Abbrechen", filled: false, onClick: () => navigate("/home") },
        {
            title: "Zurück",
            filled: false,
            onClick: () => navigate("/otherProcessing", { state: { origin: origin } }),
        },
    ];
    const fields: IDataViewV2DataSet[] = [
        { field: "fiscalYear", label: "Steuerjahr" },
        { field: "foreigner", label: "Berechnungs-Modus der FiscalUnit" },
        { field: "totalShareProfit", label: "Änderung des Saldos Aktiengewinn" },
        { field: "totalShareLoss", label: "Änderung des Saldos Aktienverlust" },
        { field: "totalOtherProfit", label: "Änderung des Saldos sonstiger Gewinn" },
        { field: "totalOtherLoss", label: "Änderung des Saldos sonstiger Verlust" },
        { field: "totalTerminLoss", label: "Änderung des Saldos Verlust/Termingeschäft" },
        { field: "totalTwentySixSixloss", label: "Änderung des Saldos Verlust nach §20 Absatz 6 Satz 6 EStG" },
    ];

    return (
        <div style={{ margin: "4rem" }}>
            {
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                    {"Verrechnungstopfstand zu " +
                        extraData["fiscalUnitData.fiscalUnit"] +
                        " per " +
                        customDateValueGetterV2(extraData.limitingDate)}
                </Typography>
            }
            <DataViewV2 data={data} fields={fields} />
            <div id="footer">
                <Footer buttons={footerButtons} />
            </div>
        </div>
    );
};
