import { useState, useEffect } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Card, Typography } from "@mui/material";
import { renewLoginTime } from "application/app-bar/Appbar";
import { setIsLoading } from "application/redux/slices/ApplicationSlice";
import { AppDispatch } from "application/redux/Store";
import { useAppDispatch } from "application/redux/Hooks";
import { TableidentifierType } from "application/redux/slices/UserConfigurationSlice";
import { ITableColumn, ITableContextMenu, Table } from "components/Table";
import { SecIncVap } from "./SecIncVap";

interface IProps {
    bidId: number;
    stga?: string;
}

export const secIncomeColumns: ITableColumn[] = [
    /* { field: "id", title: "TB_BID_ID" },
        { field: "securityId", title: "TB_SECURITY_ID" },
        { field: "incomeStatus", title: "TB_INCOME_STATUS" }, */
    { field: "ed004", title: "ED004" },
    { field: "ed004a", title: "ED004A" },
    {
        field: "ed005",
        title: "ED005",
        type: "numeric",
    },
    {
        field: "ed006",
        title: "ED006",
        type: "numeric",
    },
    {
        field: "ex_date",
        title: "EX_DATE",
        type: "date",
    },
    {
        field: "ed008a",
        title: "ED008A",
        type: "numeric",
    },
    { field: "ed008d", title: "ED008D" },
    {
        field: "ed008e",
        title: "ED008E",
        type: "numeric",
    },
    { field: "ed008f", title: "ED008F" },
    {
        field: "ed009a",
        title: "ED009A",
        type: "numeric",
    },
    {
        field: "ed010a",
        title: "ED010A",
        type: "numeric",
    },
    { field: "ed011", title: "ED011" },
    {
        field: "ed012",
        title: "ED012",
        type: "numeric",
    },
    {
        field: "ed013a",
        title: "ED013A",
        type: "numeric",
    },
    {
        field: "ed013b",
        title: "ED013B",
        type: "numeric",
    },
    {
        field: "ed014a",
        title: "ED014A",
        type: "numeric",
    },
    {
        field: "ed015a",
        title: "ED015A",
        type: "numeric",
    },
    {
        field: "ed016a",
        title: "ED016A",
        type: "numeric",
    },
    {
        field: "ed019",
        title: "ED019",
        type: "date",
    },
    {
        field: "ed020",
        title: "ED020",
        type: "date",
    },
    {
        field: "ed021",
        title: "ED021",
        type: "date",
    },
    {
        field: "ed024a",
        title: "ED024A",
        type: "date",
    },
    {
        field: "ed025a",
        title: "ED025A",
        type: "date",
    },
    { field: "ed028a", title: "ED028A" },
    {
        field: "ed028b",
        title: "ED028B",
        type: "numeric",
    },
    {
        field: "ed030a",
        title: "ED030A",
        type: "numeric",
    },
    { field: "ed032", title: "ED032" },
    { field: "ed033", title: "ED033" },
    {
        field: "ed034",
        title: "ED034",
        type: "numeric",
    },
    {
        field: "ed035",
        title: "ED035",
        type: "numeric",
    },
    {
        field: "ed036a",
        title: "ED036A",
        type: "numeric",
    },
    {
        field: "ed037",
        title: "ED037",
        type: "numeric",
    },
    { field: "ed037a", title: "ED037A" },
    {
        field: "ed037b",
        title: "ED037B",
        type: "numeric",
    },
    { field: "ed037c", title: "ED037C" },
    {
        field: "ed039a",
        title: "ED039A",
        type: "numeric",
    },
    {
        field: "ed042",
        title: "ED042",
        type: "numeric",
    },
    {
        field: "ed043",
        title: "ED043",
        type: "numeric",
    },
    {
        field: "ed044",
        title: "ED044",
        type: "numeric",
    },
    {
        field: "ed047",
        title: "ED047",
        type: "numeric",
    },
    {
        field: "ed048",
        title: "ED048",
        type: "numeric",
    },
    { field: "ed050", title: "ED050" },
    {
        field: "ed053a",
        title: "ED053A",
        type: "numeric",
    },
    {
        field: "ed054a",
        title: "ED054A",
        type: "numeric",
    },
    {
        field: "ed055a",
        title: "ED055A",
        type: "numeric",
    },
    {
        field: "ed056a",
        title: "ED056A",
        type: "numeric",
    },
    {
        field: "ed057a",
        title: "ED057A",
        type: "numeric",
    },
    {
        field: "ed058a",
        title: "ED058A",
        type: "numeric",
    },
    {
        field: "ed059a",
        title: "ED059A",
        type: "numeric",
    },
    {
        field: "ed060",
        title: "ED060",
        type: "date",
    },
    {
        field: "ed061b",
        title: "ED061B",
        type: "numeric",
    },
    {
        field: "ed065a",
        title: "ED065A",
        type: "numeric",
    },
    {
        field: "ed066a",
        title: "ED066A",
        type: "numeric",
    },
    {
        field: "ed070",
        title: "ED070",
        type: "numeric",
    },
    {
        field: "ed073a",
        title: "ED073A",
        type: "numeric",
    },
    {
        field: "ed074",
        title: "ED074",
        type: "numeric",
    },
    { field: "ed074a", title: "ED074A" },
    {
        field: "ed075",
        title: "ED075",
        type: "numeric",
    },
    {
        field: "ed076",
        title: "ED076",
        type: "numeric",
    },
    { field: "ed077", title: "ED077" },
    {
        field: "ed100",
        title: "ED100",
        type: "numeric",
    },
    {
        field: "ed101",
        title: "ED101",
        type: "numeric",
    },
    {
        field: "ed102",
        title: "ED102",
        type: "numeric",
    },
    {
        field: "ed103",
        title: "ED103",
        type: "numeric",
    },
    {
        field: "ed107",
        title: "ED107",
        type: "numeric",
    },
    {
        field: "ed107a",
        title: "ED107A",
        type: "numeric",
    },
    {
        field: "ed109",
        title: "ED109",
        type: "numeric",
    },
    {
        field: "ed110",
        title: "ED110",
        type: "numeric",
    },
    {
        field: "ed111",
        title: "ED111",
        type: "numeric",
    },
    {
        field: "ed112",
        title: "ED112",
        type: "numeric",
    },
    {
        field: "ed113",
        title: "ED113",
        type: "numeric",
    },
    {
        field: "ed116",
        title: "ED116",
        type: "numeric",
    },
    {
        field: "ed117",
        title: "ED117",
        type: "numeric",
    },
    {
        field: "ed118",
        title: "ED118",
        type: "numeric",
    },
    {
        field: "ed119",
        title: "ED119",
        type: "numeric",
    },
    {
        field: "ed120",
        title: "ED120",
        type: "numeric",
    },
    {
        field: "ed125",
        title: "ED125",
        type: "numeric",
    },
    {
        field: "ed126",
        title: "ED126",
        type: "numeric",
    },
    {
        field: "ed127",
        title: "ED127",
        type: "numeric",
    },
    {
        field: "ed128",
        title: "ED128",
        type: "numeric",
    },
    {
        field: "ed129",
        title: "ED129",
        type: "numeric",
    },
    {
        field: "ed130",
        title: "ED130",
        type: "numeric",
    },
    {
        field: "ed130a",
        title: "ED130A",
        type: "numeric",
    },
    { field: "ed132", title: "ED132" },
    { field: "ed133", title: "ED133" },
    {
        field: "ed134",
        title: "ED134",
        type: "numeric",
    },
    { field: "ed134a", title: "ED134A" },
    { field: "ed101a", title: "ED101A" },
    { field: "ed103a", title: "ED103A" },
    { field: "ed112a", title: "ED112A" },
    { field: "ed113a", title: "ED113A" },
    {
        field: "ed134b",
        title: "ED134B",
        type: "numeric",
    },
    { field: "ed134c", title: "ED134C" },
    {
        field: "ed135",
        title: "ED135",
        type: "numeric",
    },
    {
        field: "ed136",
        title: "ED136",
        type: "numeric",
    },
    { field: "ed137", title: "ED137" },
    { field: "ed138", title: "ED138" },
    { field: "ed138a", title: "ED138A" },
    { field: "ed138b", title: "ED138B" },
    { field: "ed138c", title: "ED138C" },
    {
        field: "ed139",
        title: "ED139",
        type: "numeric",
    },
    {
        field: "ed139a",
        title: "ED139A",
        type: "numeric",
    },
    {
        field: "ed139b",
        title: "ED139B",
        type: "numeric",
    },
    {
        field: "ed139c",
        title: "ED139C",
        type: "numeric",
    },
    {
        field: "ed140",
        title: "ED140",
        type: "numeric",
    },
    {
        field: "ed140a",
        title: "ED140A",
        type: "numeric",
    },
    {
        field: "ed140b",
        title: "ED140B",
        type: "numeric",
    },
    {
        field: "ed140c",
        title: "ED140C",
        type: "numeric",
    },
    { field: "ed141", title: "ED141" },
    { field: "ed141a", title: "ED141A" },
    { field: "ed141b", title: "ED141B" },
    { field: "ed141c", title: "ED141C" },
    {
        field: "ed144",
        title: "ED144",
        type: "numeric",
    },
    {
        field: "ev045a",
        title: "EV045A",
        type: "numeric",
    },
    {
        field: "ev045b",
        title: "EV045B",
        type: "numeric",
    },
    {
        field: "ed302a",
        title: "ED302A",
        type: "numeric",
    },
    {
        field: "ed210",
        title: "ED210",
        type: "numeric",
    },
    { field: "ed210a", title: "ED210A" },
    {
        field: "ed207",
        title: "ED207",
        type: "numeric",
    },
    { field: "ed207a", title: "ED207A" },
    {
        field: "ed208",
        title: "ED208",
        type: "numeric",
    },
    { field: "ed208a", title: "ED208A" },
    {
        field: "ed209",
        title: "ED209",
        type: "numeric",
    },
    { field: "ed209a", title: "ED209A" },
    {
        field: "ed200",
        title: "ED200",
        type: "numeric",
    },
    { field: "ed200a", title: "ED200A" },
    {
        field: "ed201",
        title: "ED201",
        type: "numeric",
    },
    { field: "ed201a", title: "ED201A" },
    {
        field: "ed202",
        title: "ED202",
        type: "numeric",
    },
    { field: "ed202a", title: "ED202A" },
    {
        field: "ed203",
        title: "ED203",
        type: "numeric",
    },
    { field: "ed203a", title: "ED203A" },
    {
        field: "ed204",
        title: "ED204",
        type: "numeric",
    },
    { field: "ed204a", title: "ED204A" },
    {
        field: "ed205",
        title: "ED205",
        type: "numeric",
    },
    { field: "ed205a", title: "ED205A" },
    {
        field: "ed206",
        title: "ED206",
        type: "numeric",
    },
    { field: "ed206a", title: "ED206A" },
    {
        field: "ed211",
        title: "ED211",
        type: "numeric",
    },
    {
        field: "ed212",
        title: "ED212",
        type: "numeric",
    },
    {
        field: "ed008h",
        title: "ED008H",
        type: "numeric",
    },
    {
        field: "ed008i",
        title: "ED008I",
        type: "numeric",
    },
    {
        field: "ed214",
        title: "ED214",
        type: "numeric",
    },
    {
        field: "ed215",
        title: "ED215",
        type: "numeric",
    },
    {
        field: "ed216",
        title: "ED216",
        type: "numeric",
    },
    {
        field: "ed219",
        title: "ED219",
        type: "numeric",
    },
    { field: "ed234", title: "ED234" },
    {
        field: "ed318",
        title: "ED318",
        type: "date",
    },
    {
        field: "ed319",
        title: "ED319",
        type: "numeric",
    },
    {
        field: "ed320",
        title: "ED320",
        type: "numeric",
    },
    {
        field: "ed154",
        title: "ED154",
        type: "numeric",
    },
    {
        field: "ed235",
        title: "ED235",
        type: "numeric",
    },
    { field: "ed237", title: "ED237" },
    {
        field: "ed237a",
        title: "ED237A",
        type: "numeric",
    },
    {
        field: "ed321",
        title: "ED321",
        type: "numeric",
    },
    {
        field: "ed400d",
        title: "ED400D",
        type: "numeric",
    },
    {
        field: "ed433",
        title: "ED433",
        type: "numeric",
    },
    {
        field: "ed435",
        title: "ED435",
        type: "numeric",
    },
    {
        field: "ed448",
        title: "ED448",
        type: "numeric",
    },
    {
        field: "ev421a",
        title: "EV421A",
        type: "numeric",
    },
    {
        field: "ev421b",
        title: "EV421B",
        type: "numeric",
    },
    {
        field: "ed124",
        title: "ED124",
        type: "numeric",
    },
    {
        field: "ev422a",
        title: "EV422A",
        type: "numeric",
    },
    {
        field: "ev422b",
        title: "EV422B",
        type: "numeric",
    },
    {
        field: "ed008k",
        title: "ED008K",
        type: "numeric",
    },
    {
        field: "ed476",
        title: "ED476",
        type: "numeric",
    },
    {
        field: "ed476a",
        title: "ED476A",
        type: "numeric",
    },
    { field: "ed476b", title: "ED476B" },
    {
        field: "ed476g",
        title: "ED476G",
        type: "numeric",
    },
    {
        field: "ed476h",
        title: "ED476H",
        type: "numeric",
    },
    {
        field: "ed476s",
        title: "ED476S",
        type: "numeric",
    },
    {
        field: "ed476d",
        title: "ED476D",
        type: "numeric",
    },
    { field: "ed476e", title: "ED476E" },
    {
        field: "ed476k",
        title: "ED476K",
        type: "numeric",
    },
    {
        field: "ed476m",
        title: "ED476M",
        type: "numeric",
    },
    {
        field: "ed476p",
        title: "ED476P",
        type: "numeric",
    },
    {
        field: "ed476r",
        title: "ED476R",
        type: "numeric",
    },
    {
        field: "ev308a",
        title: "EV308A",
        type: "numeric",
    },
    { field: "ev308b", title: "EV308B" },
    {
        field: "ev308c",
        title: "EV308C",
        type: "date",
    },
    { field: "ev308d", title: "EV308D" },
];

export const SecIncome = (props: IProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    const [tableData, setTableData] = useState([]);
    const [vapData, setVapData] = useState([]);
    const [bidId, setBidId] = useState([]);

    const IDENTIFIER: TableidentifierType = "secIncome";

    const [errorMsg, setErrorMsg] = useState<string>("");

    const [currentRowData, setCurrentRowData] = useState<Record<string, string | number>>({});

    const contextMenu: ITableContextMenu = {
        contextActions: [
            {
                label: "VAP monatliche Werte",
                action: async () => {
                    dispatch(setIsLoading({ isLoading: true }));
                    //@ts-ignore
                    setBidId(currentRowData.id);
                    const response = (
                        await sendGetRequest({
                            path: "/security/secincvap",
                            //@ts-ignore
                            params: { bidId: currentRowData.id },
                        })
                    ).data;
                    setVapData(response);
                    if (JSON.stringify(response) === JSON.stringify([])) {
                        //@ts-ignore
                        setErrorMsg("Keine Daten für die bidId: " + currentRowData.id);
                    }
                    dispatch(setIsLoading({ isLoading: false }));
                },
            },
        ],
        setCurrentRowData: setCurrentRowData,
    };

    //call http request onMount
    useEffect(() => {
        async function fetchData() {
            dispatch(setIsLoading({ isLoading: true }));
            const response = await sendGetRequest({
                path: "/security/incomeevents",
                params: { bidId: props.bidId },
            });
            setTableData(response.data);
            dispatch(setIsLoading({ isLoading: false }));
        }

        fetchData();
    }, [props.bidId, props.stga, dispatch]);

    useEffect(() => {
        renewLoginTime();
    }, []);

    return (
        <Card sx={{ height: "100%" }}>
            <Card sx={{ height: "50%" }}>
                <Table
                    columnsDefinition={secIncomeColumns}
                    identifier={IDENTIFIER}
                    tableData={tableData}
                    contextMenu={props.stga === "5800" ? contextMenu : undefined}
                />
            </Card>
            {JSON.stringify(vapData) !== JSON.stringify([]) ? (
                <div>
                    <br />
                    <Typography>{"VAP monatliche Werte für die bidId: " + bidId}</Typography>
                    <SecIncVap data={vapData} />
                </div>
            ) : null}
            {errorMsg !== "" ? (
                <div>
                    <br />
                    <Typography>{errorMsg}</Typography>
                </div>
            ) : null}
        </Card>
    );
};
