/* author: Sindhu Kannan */

import { useState } from "react";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ErrorDetails } from "./ErrorDetails";
import { KeyTableConsts } from "components/KeyTableConsts";
import { getIncomeColumns } from "pages/transaction-data/IncomeTranSearch";
import { TransactionContextMenu } from "pages/transaction-data/TransactionContextMenu";
import { INCOME_TAB } from "pages/transaction-data/TransactionOverview";
import { ITableContextMenu } from "components/Table";

export const FailedIncomeTransacitons = (): React.JSX.Element => {
    const [impId, setImpId] = useState<number>(-1);

    const searchCriterias: ISearchCriteria[] = [
        { field: "branch", label: "Filialnummer" },
        { field: "accountNr", label: "Kontonummer*" },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
        },
        { field: "wkn", label: "WKN*" },
        { field: "isin", label: "ISIN*" },
        {
            field: "",
            label: "Ausführungszeitpunkt",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "relDateFrom", discriminator: "from" },
                    to: { label: "bis", field: "relDateTo", discriminator: "to" },
                },
            },
        },
        {
            field: "taxEvent1",
            label: "Steuerliche Geschäftsart von",
            type: "dropDown",
            comboBoxLowestFieldName: "taxEvent2",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TFT },
        },
        {
            field: "taxEvent2",
            label: "Steuerliche Geschäftsart bis",
            type: "dropDown",
            comboBoxHighestFieldName: "taxEvent1",
            keytable: { tabname: KeyTableConsts.TAX_EVENT_TFT },
        },
    ];

    const searchUrls: ISearchUrl[] = [{ path: "/cgresources/fttransactions/getFailedIncomeTransactions" }];
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <div id="tableWrapper" style={{ margin: "4rem" }}>
            <Search
                title="Suche - Fehlerhafte Erträgnisse"
                searchCriterias={searchCriterias}
                columns={getIncomeColumns()}
                identifier="failedIncomeTrans"
                searchUrls={searchUrls}
                details={{
                    setIdForDetail: setImpId,
                    detailIdentifier: "incomeTranId",
                }}
                customSearchButtonDisable={(searchCriteriaText: Record<string, string>) => {
                    return isSearchCriteriaTextEmpty(searchCriteriaText);
                }}
                isExportable={true}
                checkPairFields={[["taxEvent1", "taxEvent2"]]}
                contextMenu={{
                    element: (
                        <TransactionContextMenu
                            contextMenus={contextMenus}
                            setContextmenus={setContextMenus}
                            currentTabText={INCOME_TAB}
                        />
                    ),
                    tableElement: contextMenus[INCOME_TAB],
                }}
            />
            <div id="errorDetailList">
                <ErrorDetails origin="ftt" impId={impId} />
            </div>
        </div>
    );
};
export const isSearchCriteriaTextEmpty = (searchCriteriaText: Record<string, string>): boolean => {
    for (const key of Object.keys(searchCriteriaText)) {
        if (searchCriteriaText[key].length > 0) {
            return false;
        }
    }
    return true;
};
