/* author: JESCHKE Moritz */

import { IEditDataViewUserConfiguration } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { Location, useLocation, useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";

const DonationPersonEdit = (role: string, section: string): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const messageId: number = Number(params.messageId);
    const personId: number = Number(params.personId === "new" ? -1 : params.personId);

    const location: Location = useLocation();
    const isNew: boolean = location.state && location.state.isNew;

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: ("/donationdata/donation/person/" + role + "ForEdit") as never,
            params: { donationPersonId: personId, isNew: isNew },
        });

    const donationPersonInfo: IEditDataViewUserConfiguration = {
        headline: section,
        attributes: [
            { field: "role", headerName: "Rolle", displayOnly: true },
            { field: "roleNr", headerName: "lfd. Nr. Auftragg./Empf.", displayOnly: true },
            {
                field: "lastName",
                headerName: "Nachname",
            },
            { field: "givenName", headerName: "Vorname" },
            { field: "street1", headerName: "Strasse 1" },
            { field: "street2", headerName: "Strasse 2" },
            { field: "houseNumber", headerName: "Hausnummer" },
            { field: "houseNumberAdd", headerName: "Zusätzliche Hausnummer" },
            { field: "zipCode", headerName: "PLZ" },
            { field: "town", headerName: "Ort" },
            { field: "country", headerName: "Adressland", dropdown: { keyTableTabname: "TB_WML02" } },
            {
                field: "birthDate",
                headerName: "Geburtsdatum",
                type: "date",
            },
            { field: "tin", headerName: "Tax Identification Number (TIN)" },
            { field: "personIdentification", headerName: "Personen-Nr.", displayOnly: true },
            { field: "nps", headerName: "Natürliche Person", dropdown: { keyTableTabname: "YESNO_CHARACTER" } },
            {
                field: "ignoreMatching",
                headerName: "Matching ignorieren",
                dropdown: { keyTableTabname: "YESNO_CHARACTER" },
            },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
        checkInvalidity: [
            {
                condition: (data: Record<string, unknown>): boolean =>
                    !data["lastName"] || String(data["lastName"]) === "",
                relatedFields: ["lastName"],
            },
            {
                condition: (data: Record<string, unknown>): boolean => !data["nps"],
                relatedFields: ["nps"],
            },
        ],
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [donationPersonInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={messageId}
            parentIdentifier="messageId"
            title={section + " bearbeiten"}
            section={section}
            overrideEditValues={[{ field: "role", overrideValue: role.toUpperCase() }]}
        />
    );
};

export const DONATION_PERSON_SECTION_DONOR: string = "Auftraggeber";
export const DonationPersonEditDonor = (): React.JSX.Element =>
    DonationPersonEdit("donor", DONATION_PERSON_SECTION_DONOR);

export const DONATION_PERSON_SECTION_DONEE: string = "Empfänger";
export const DonationPersonEditDonee = (): React.JSX.Element =>
    DonationPersonEdit("donee", DONATION_PERSON_SECTION_DONEE);
