/* author: Aashish Upadhyay  */

import { ITableContextMenu } from "components/Table";
import { useState } from "react";
import { SECURITY_TAB } from "./TransactionOverview";
import { TransactionContextMenu } from "./TransactionContextMenu";
import { EditPendingLinked } from "components/EditPendingLinked";
import { secTranColumns } from "./SecurityTransactionSearchMask";

export const EditPendingLinkedSecTranInfo = (): React.JSX.Element => {
    const [contextMenus, setContextMenus] = useState<Record<string, ITableContextMenu>>({});

    return (
        <EditPendingLinked
            columns={secTranColumns}
            dataRequest={{
                path: "/cgresources/cgtransactions/searchSecTranEditPending",
            }}
            identifier="editPendingOverviewSecTran"
            title="Wertpapiere"
            contextMenu={{
                element: (
                    <TransactionContextMenu
                        contextMenus={contextMenus}
                        setContextmenus={setContextMenus}
                        currentTabText={SECURITY_TAB}
                    />
                ),
                tableElement: contextMenus[SECURITY_TAB],
            }}
        />
    );
};
