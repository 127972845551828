/* author: Aashish Upadhyay*/

import { IEditDataViewUserConfiguration, getField } from "components/EditComponents/FormEditView";
import { Params } from "react-router-dom";
import { GET_REQUEST_TYPE, sendGetRequest } from "application/api/HttpRequestHandler";
import { useParams } from "react-router";
import { EditMask } from "components/EditComponents/EditMask";
import { newDate } from "pages/ted-data/PortfolioTransferDetailEdit";

export const ORDER_SECTION: string = "Auftrag";

export const isYerEditable = (values: Record<string, unknown>): boolean => {
    const EXEC_STATE_EXECUTED: string = "V";
    return (
        getField(values, "execState") === EXEC_STATE_EXECUTED ||
        getField(values, "requestData.execState") === EXEC_STATE_EXECUTED
    );
};

const YerRequestEdit = (type: "single" | "mass"): React.JSX.Element => {
    const params: Readonly<Params<string>> = useParams();
    const requestId: number = Number(params.requestId);
    const section: string | undefined = params.section;

    const isMass: boolean = type === "mass";

    const REQUEST = (): GET_REQUEST_TYPE =>
        sendGetRequest({
            path: type === "single" ? "/request/yer/single/byId-with-keydata" : "/request/yer/mass/byId-with-keydata",
            params: { requestId: requestId },
        });

    const orderEditInfo: IEditDataViewUserConfiguration = {
        headline: ORDER_SECTION,
        attributes: [
            {
                field: "fiscalUnitData.fiscalUnit",
                headerName: "Steuerliche Einheit",
                displayOnly: true,
                hide: isMass,
            },
            {
                field: "accountData.branch",
                headerName: "Filalnummer",
                displayOnly: true,
                hide: isMass,
            },
            {
                field: "accountData.accountNr",
                headerName: "Kontonummer",
                displayOnly: true,
                hide: isMass,
            },
            {
                field: "fromDate",
                headerName: "Zeitraum von",
                type: "date",
                disabled: isYerEditable,
            },
            {
                field: "toDate",
                headerName: "Zeitraum bis",
                type: "date",
                disabled: isYerEditable,
            },
            {
                field: "execState",
                headerName: "Auftragstatus",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "REQ_STATE",
                },
            },
            {
                field: "requestType",
                headerName: "Auftragsart",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "REQ_TYPE",
                },
            },
            {
                field: "extRequestId",
                headerName: "Externe Referenz",
                displayOnly: true,
            },
            {
                field: "clientData.clientId",
                headerName: "Mandant",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "CLIENT",
                },
            },
            {
                field: "processingStartTime",
                headerName: "Verarbeitungsdatum Beginn",
                type: "datetime",
                displayOnly: true,
            },
            {
                field: "processingEndTime",
                headerName: "Verarbeitungsdatum Ende",
                type: "datetime",
                displayOnly: true,
            },
            {
                field: "startProcessingDate",
                headerName: "Starten ab",
                type: "date",
                disabled: isYerEditable,
            },
            {
                field: "mode",
                headerName: "Durchführungsmodus",
                dropdown: {
                    keyTableTabname: "REQ_MODE",
                },
                disabled: isYerEditable,
            },
            {
                field: "source",
                headerName: "Quelle",
                displayOnly: true,
                dropdown: {
                    keyTableTabname: "REQ_SOURCE",
                },
            },
        ],
        latestVersionRequest: REQUEST,
        versionRequest: REQUEST,
        checkInvalidity: [
            {
                condition: (data: Record<string, unknown>): boolean =>
                    data["fromDate"] === undefined || String(data["fromDate"]) === "",
                relatedFields: ["fromDate"],
            },
            {
                condition: (data: Record<string, unknown>): boolean =>
                    data["toDate"] === undefined || String(data["toDate"]) === "",
                relatedFields: ["toDate"],
            },
            {
                condition: (data: Record<string, unknown>): boolean => {
                    const fromDate = newDate(String(data["fromDate"]));
                    const toDate = newDate(String(data["toDate"]));
                    return fromDate.getFullYear() !== toDate.getFullYear() || fromDate > toDate;
                },
                relatedFields: ["fromDate", "toDate"],
            },
        ],
    };

    const completeEditInfo: IEditDataViewUserConfiguration[] = [orderEditInfo];

    return (
        <EditMask
            completeEditInfo={completeEditInfo}
            id01={requestId}
            title={(type === "single" ? "Einzel" : "Masssen") + "aufträge bearbeiten"}
            section={section}
        />
    );
};

export const YerSingleRequestEdit = (): React.JSX.Element => YerRequestEdit("single");
export const YerMassRequestEdit = (): React.JSX.Element => YerRequestEdit("mass");
