import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { GenerateJSTBReport } from "pages/showcase/jstb-report/GenerateJSTBReport";
import { FetchJSTBReport } from "pages/showcase/jstb-report/FetchJSTBReport";

interface IProps {
    isOpen: boolean;
    setDialogOpen: any;
}

export const JSTBDialog = (props: IProps) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    const [fuId, setFuId] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClose = () => {
        props.setDialogOpen(false);
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={props.isOpen} onClose={handleClose}>
                <DialogTitle>JSTB Report</DialogTitle>

                <DialogContent>
                    <TextField
                        //TODO :: Change requestID to fiscalUnitId
                        onChange={(e) => setFuId(e.target.value)}
                        id="outlined-required"
                        label="FiscalUnit Id"
                        variant="outlined"
                        // required
                        sx={{ margin: "1em" }}
                    />

                    {/* {errorMsg !== "" ? ( */}
                    <TextField
                        //TODO :: add year field
                        onChange={(e) => setYear(e.target.value)}
                        id="outlined-required"
                        label="Year"
                        variant="outlined"
                        required
                        sx={{ margin: "1em" }}
                    />
                    {/* ) : null} */}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsFetching(true);
                        }}
                    >
                        Fetch
                    </Button>
                    <Button
                        onClick={() => {
                            setIsGenerating(true);
                        }}
                    >
                        Erstellen
                    </Button>

                    <Button onClick={handleClose} autoFocus>
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
            {isFetching ? (
                <FetchJSTBReport fiscalUnitId={fuId} year={year} setDialogOpen={setIsFetching} isOpen={isFetching} />
            ) : null}

            {isGenerating ? (
                <GenerateJSTBReport
                    fiscalUnitId={fuId}
                    setDialogOpen={setIsGenerating}
                    isOpen={isGenerating}
                    year={year}
                />
            ) : null}
        </div>
    );
};
