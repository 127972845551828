/* author: JESCHKE Moritz */

import {
    BALANCES_INCOMES_TAXES_PER_TAXYEAR,
    CALCULATION_POT,
    CALCULATION_POT_PER_DAY,
} from "application/menu-bar/MenuBarItemList";
import { getField } from "components/EditComponents/FormEditView";
import { KeyTableConsts } from "components/KeyTableConsts";
import { ISearchCriteria, ISearchUrl, Search } from "components/Search";
import { ITableColumn } from "components/Table";
import { useState } from "react";
import { useLocation } from "react-router";

export const OtherProcessingSearch = (): React.JSX.Element => {
    const params: Location = useLocation().state;
    const origin: string = params.origin ? params.origin : "";

    const [currentValues, setCurrentValues] = useState<Record<string, string>>({ initial: "" });

    const searchCriteria: ISearchCriteria[] = [
        {
            field: "fiscalUnit",
            label: "Steuerliche Einheit*",
            disabled:
                (currentValues["branch"] !== undefined && currentValues["branch"] !== "") ||
                (currentValues["accountNr"] !== undefined && currentValues["accountNr"] !== "") ||
                (currentValues["iban"] !== undefined && currentValues["iban"] !== "") ||
                (currentValues["accountType"] !== undefined && currentValues["accountType"] !== "%"),
        },
        {
            field: "branch",
            label: "Filialnummer*",
            disabled: currentValues["fiscalUnit"] !== undefined && currentValues["fiscalUnit"] !== "",
        },
        {
            field: "accountNr",
            label: "Kontonummer*",
            disabled: currentValues["fiscalUnit"] !== undefined && currentValues["fiscalUnit"] !== "",
        },
        {
            field: "iban",
            label: "IBAN*",
            disabled: currentValues["fiscalUnit"] !== undefined && currentValues["fiscalUnit"] !== "",
        },
        {
            field: "accountType",
            label: "Kontoart",
            type: "dropDown",
            keytable: { tabname: KeyTableConsts.ACC_TYPE, allOption: true },
            defaultValue: "*",
            disabled: currentValues["fiscalUnit"] !== undefined && currentValues["fiscalUnit"] !== "",
        },
    ];

    if (origin === CALCULATION_POT) {
        searchCriteria.push({
            field: "",
            label: "Zeitraum",
            type: {
                TBFromToDatePicker: {
                    prefixParentLabel: true,
                    from: { label: "von", field: "validFrom", discriminator: "from" },
                    to: { label: "bis", field: "validTo", discriminator: "to" },
                },
            },
        });
    }

    if (origin === CALCULATION_POT_PER_DAY) {
        searchCriteria.push({
            field: "",
            label: "",
            type: {
                singleDatePicker: {
                    label: "Verrechnungstopfstand per",
                    field: "limitingDate",
                    discriminator: "single",
                },
            },
        });
    }

    const resultListColumnsGroup1: ITableColumn[] = [
        { field: "fiscalUnitData.fiscalUnit", title: "Steuerliche Einheit" },
    ];

    const resultListColumnsGroup2: ITableColumn[] = [
        { field: "fiscalUnitData.fiscalUnit", title: "Steuerliche Einheit" },
        { field: "accountData.branch", title: "Filialnummer" },
        { field: "accountData.accountNr", title: "Kontonummer" },
        { field: "accountData.iban", title: "IBAN" },
        { field: "accountData.type", title: "Kontoart" },
        {
            field: "validFrom",
            title: "Gültig von",
            type: "date",
        },
        {
            field: "validTo",
            title: "Gültig bis",
            type: "date",
        },
    ];

    const searchUrls: ISearchUrl[] = [];
    if (origin === CALCULATION_POT)
        searchUrls.push({ path: "/cgresources/taxaccounting/search", ignoreParams: ["validFrom", "validTo"] });
    else if (origin === CALCULATION_POT_PER_DAY)
        searchUrls.push({ path: "/cgresources/taxaccounting/search", ignoreParams: ["limitingDate"] });
    else {
        searchUrls.push({ path: "/cgresources/taxaccounting/search" });
    }

    const getLinkTo = (): string => {
        if (origin === BALANCES_INCOMES_TAXES_PER_TAXYEAR) {
            return "/balancesIncomeOverview";
        } else if (origin === CALCULATION_POT_PER_DAY) {
            return "/calcPotAtDeadline";
        } else {
            return "/FsaNvSbCalculationpotsDetails";
        }
    };

    return (
        <Search
            columns={resultListColumnsGroup1}
            hideTechnicalColumns
            columnLogic={{
                alternativColumns: [resultListColumnsGroup2],
                columnDecider: (currentSearchCriteria: Record<string, string>) =>
                    currentSearchCriteria["fiscalUnit"] ? 0 : 1,
            }}
            searchCriterias={searchCriteria}
            title={"Suche " + origin}
            searchUrls={searchUrls}
            setCurrentValues={setCurrentValues}
            identifier="otherProcessingSearch"
            linkto={getLinkTo()}
            origin={origin}
            extraData={
                origin === CALCULATION_POT_PER_DAY
                    ? ["fiscalUnitData.fiscalUnit", "limitingDate"]
                    : ["fiscalUnitData.fiscalUnit"]
            }
            sendRequestBeforePageSwitch={(currentRowData: Record<string, string>) => {
                console.log(currentRowData);
                if (origin === BALANCES_INCOMES_TAXES_PER_TAXYEAR) {
                    return {
                        path: "/cgresources/taxbalances",
                        params: { fiscalUnitId: getField(currentRowData, "fiscalUnitData.id") },
                    };
                } else if (origin === CALCULATION_POT) {
                    if (
                        (currentValues["validFrom"] !== undefined &&
                            currentValues["validFrom"] !== "" &&
                            currentValues["validTo"] !== undefined &&
                            currentValues["validTo"] !== "") ||
                        ((currentValues["validFrom"] === undefined || currentValues["validFrom"] === "") &&
                            (currentValues["validTo"] === undefined || currentValues["validTo"] === ""))
                    )
                        return {
                            path: "/cgresources/taxaccounting",
                            params: {
                                fuId: getField(currentRowData, "fiscalUnitData.id"),
                                validFrom: currentValues["validFrom"],
                                validTo: currentValues["validTo"],
                            },
                        };
                    else {
                        return {
                            path: "undefined",
                            params: {
                                msg: "Für die Suche kann entweder kein Datum oder ein Von-Bis-Zeitraum angegeben werden",
                            },
                        };
                    }
                } else if (origin === CALCULATION_POT_PER_DAY) {
                    if (currentValues["limitingDate"] && currentValues["limitingDate"] !== "")
                        return {
                            path: "/cgresources/taxbalances/taxbalance",
                            params: {
                                fiscalUnitId: getField(currentRowData, "fiscalUnitData.id"),
                                limitingDate: currentValues["limitingDate"],
                            },
                        };
                    else {
                        return {
                            path: "undefined",
                            params: {
                                msg: "Das Feld 'Verrechnungstopfstand per' ist erforderlich.",
                            },
                        };
                    }
                } else {
                    return {
                        path: "/cgresources/taxaccounting",
                        params: { fuId: getField(currentRowData, "fiscalUnitData.id") },
                    };
                }
            }}
        />
    );
};
