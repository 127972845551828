/* author: Aashish Upadhyay  */

import { EditPendingLinked } from "components/EditPendingLinked";
import { ITableColumn } from "components/Table";

export const EditPendingLinkedPersonInfo = (): React.JSX.Element => {
    const columns: ITableColumn[] = [
        { field: "lastName", title: "Nachname" },
        { field: "firstName", title: "Vorname" },
        { field: "personNr", title: "Personen Nr." },
        {
            field: "town",
            title: "Ort, Postleitzahl",
            combinedFields: ["zipCode"],
            combinedFieldsSeparator: " ",
        },
        {
            field: "street",
            title: "Straße & Hausnummer",
            combinedFields: ["houseNumber"],
            combinedFieldsSeparator: " ",
        },
        { field: "fiscalUnit", title: "Steuerliche Einheit" },
        { field: "accountNr", title: "Kontonummer" },
        { field: "tin", title: "TIN" },
        { field: "linkedFUCount", title: "Anzahl\nFUs" },
        { field: "cashAccountCount", title: "Anzahl\nKonten" },
        { field: "securityAccountCount", title: "Anzahl\nDepots" },
    ];

    return (
        <EditPendingLinked
            columns={columns}
            dataRequest={{
                path: "/customerstaticdata/person/searchEditPending",
            }}
            identifier="editPendingOverviewPerson"
            title="Personenstammdaten"
            navigate={{ linkTo: "overviewPerson", idField: "id" }}
        />
    );
};
