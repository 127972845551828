import { useEffect, useState } from "react";
import axios from "axios";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Detailview, IDetailView } from "components/Detailview";
import { IOverviewHeadLine, OverviewHeadLine } from "components/OverviewHeadLine";
import { IDataViewV2DataSet } from "components/DataViewV2";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { FsaDataVersions } from "./FsaDataVersions";
import { IOverviewViewStackAdder } from "components/Overview";
import { FsaLimitVersion } from "pages/customer-static-data/FsaLimitVersion";

interface IFsaDetails extends IOverviewViewStackAdder {
    fsaNvId: number;
    fsaLimitId: number;
    icon: React.JSX.Element | null;
    functionTextDisable?: boolean;
}

export const FsaDetails = (props: IFsaDetails): React.JSX.Element => {
    const navigate = useNavigate();

    const [data, setData] = useState<Record<string, string>>({});
    const [limitData, setLimitData] = useState<Record<string, string>>({});
    const [fuData, setFuData] = useState<Record<string, string>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dataKeys: IDataViewV2DataSet[][] = [
        [
            { field: "fsaTyp", label: "FSA Typ" },
            { field: "startingInFiscalYear", label: "Gültig ab" },
        ],
        [
            { field: "lastNamePerson1", label: "Person 1 Nachname" },
            { field: "givenNamePerson1", label: "Person 1 Vorname" },
            { field: "birthDatePerson1", label: "Person 1 Geburtsdatum", type: "date" },
            { field: "tinPerson1", label: "Person 1 TIN" },
            { field: "birthNamePerson1", label: "Person 1 Geburtsname", spacingAfter: true },
            { field: "lastNamePerson2", label: "Person 2 Nachname" },
            { field: "givenNamePerson2", label: "Person 2 Vorname" },
            { field: "birthDatePerson2", label: "Person 2 Geburtsdatum", type: "date" },
            { field: "tinPerson2", label: "Person 2 TIN" },
            { field: "birthNamePerson2", label: "Person 2 Geburtsname", spacingAfter: true },
            { field: "familyStatus", label: "Familienstand" },
        ],
    ];

    const limitDataKeys: IDataViewV2DataSet[][] = [
        [
            { field: "identification", label: "FSA Identifikation Nr." },
            { field: "exemptAmount", label: "Freistellungsbetrag" },
            { field: "taxDate", label: "Steuerliches Zuflussdatum", type: "date" },
            { field: "fiscalYear", label: "Steuer Jahr" },
        ],
    ];

    const techKeys: IDataViewV2DataSet[][] = [
        [
            { field: "execState", label: "Ausführungsstatus" },
            { field: "changeUser", label: "Geändert von" },
            { field: "releaseUser", label: "Freigegeben von" },
            { field: "updateTS", label: "Aktualisierungsdatum", type: "date" },
            { field: "insertTS", label: "Einfügedatum", type: "date" },
        ],
    ];

    const overviewHeadline: IOverviewHeadLine = !props.functionTextDisable
        ? {
              title: "Freistellungsauftrag (FSA) zur",
              fuctionText: {
                  label: fuData.fiscalUnit,
                  onTextClick: () => navigate("/overviewFu/" + fuData.fiscalUnitId),
              },
              hideTimeSliceButton: true,
          }
        : { title: "Freistellungsauftrag (FSA) zur " + fuData.fiscalUnit, hideTimeSliceButton: true };

    const detailDataData: IDetailView = {
        detailData: {
            headline: {
                title: "FSA Data",
            },
            cards: [
                {
                    icon: props.icon,
                    dataKeys: dataKeys,
                    data: data,
                    techKeys: techKeys,
                },
            ],
        },
    };
    const detailDataLimit: IDetailView = {
        detailData: {
            headline: {
                title: "FSA Limit",
            },
            cards: [
                {
                    icon: props.icon,
                    dataKeys: limitDataKeys,
                    data: limitData,
                    techKeys: techKeys,
                },
            ],
        },
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            await Promise.all([
                sendGetRequest({
                    path: "/customerstaticdata/fsaNv/fsaDataByFsaNvId",
                    params: { fsaNvId: props.fsaNvId },
                }),
                sendGetRequest({
                    path: "/customerstaticdata/fsaNv/fsaLimitData",
                    params: { fsaLimitId: props.fsaLimitId },
                }),
                sendGetRequest({
                    path: "/customerstaticdata/fiscalUnit/getFiscalUnitByFsaNvId",
                    params: { fsaNvId: props.fsaNvId },
                }),
            ]).then(
                axios.spread((...responses) => {
                    setData(responses[0].data);
                    setLimitData(responses[1].data);
                    setFuData(responses[2].data);
                    setIsLoading(false);
                })
            );
        }
        fetchData();
    }, []);

    return isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
        </Box>
    ) : (
        <div>
            <OverviewHeadLine
                title={overviewHeadline.title}
                fuctionText={overviewHeadline.fuctionText}
                hideTimeSliceButton={overviewHeadline.hideTimeSliceButton}
            />
            <Detailview
                viewStackAdd={props.viewStackAdd}
                detailData={detailDataData.detailData}
                versionView={<FsaDataVersions fsaNvId={props.fsaNvId} />}
            />
            <Detailview
                viewStackAdd={props.viewStackAdd}
                detailData={detailDataLimit.detailData}
                versionView={<FsaLimitVersion fsaLimitId={props.fsaNvId} />}
            />
        </div>
    );
};
