/* For New Client  */
import { useEffect, useState } from "react";
import { sendGetRequest } from "application/api/HttpRequestHandler";
import { Detailview, IDetailView } from "components/Detailview";
import { AddressVersions } from "./AddressVersions";
import { IOverviewViewStackAdder } from "components/Overview";

interface IAddressDetails extends IOverviewViewStackAdder {
    versionId: number;
    personId: number;
    addressType: string;
    icon: React.JSX.Element | null;
}

export const AddressDetails = (props: IAddressDetails): React.JSX.Element => {
    const [data, setData] = useState({});

    const address: IDetailView = {
        /*technical data*/
        detailData: {
            headline: {
                title: "Adresse",
            },
            cards: [
                {
                    icon: props.icon,
                    dataKeys: [
                        [
                            { field: "addressType", label: "Adressart" },
                            { field: "town", label: "Ort" },
                            { field: "country", label: "Land" },
                            { field: "zipCode", label: "PLZ" },
                            { field: "street1", label: "Strasse 1" },
                            { field: "street2", label: "Strasse 2" },
                            { field: "houseNumber", label: "Hausnummer" },
                            { field: "additionalHouseNumber", label: "Zusätzliche Hausnummer" },
                            { field: "poBox", label: "Postfach" },
                            { field: "telephone", label: "Telefon" },
                            { field: "fax", label: "Fax" },
                            { field: "remark", label: "Bemerkung" },
                        ],
                    ],
                    techKeys: [
                        [
                            { field: "validFrom", label: "Gültig von", type: "date" },
                            { field: "validTo", label: "Gültig bis", type: "date" },
                        ],
                    ],
                    data: data,
                },
            ],
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await sendGetRequest({
                path: "/customerstaticdata/address",
                params: { versionId: props.versionId },
            });
            setData(response.data);
        };

        fetchData();
    }, []);

    return (
        <Detailview
            viewStackAdd={props.viewStackAdd}
            detailData={address.detailData}
            versionView={<AddressVersions personId={props.personId} type={props.addressType} />}
        />
    );
};
