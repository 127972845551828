import { useState } from "react";

export interface RerenderHook {
    render: number;
    rerender: () => void;
}

export const useRerender = (): RerenderHook => {
    const [render, setRender] = useState<number>(1);
    const rerender = () => setRender(render * -1);

    return { render, rerender };
};
