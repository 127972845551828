/* author: JESCHKE Moritz */

import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { sendDeleteRequest, sendGetRequest, sendPutRequest } from "application/api/HttpRequestHandler";
import { Colors } from "components/Colors";
import { InputField } from "components/InputField";
import { ITableColumn, Table } from "components/Table";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { NavigateFunction, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "application/redux/Store";
import { USER_ADMIN } from "pages/login/Login";

interface IAdditionalField {
    name: string;
    fieldType: string;
    fieldTypeDisplay: string;
    mandatory: boolean;
}

interface IAdditionalFieldKeytable {
    key: string;
    value: string;
}

export const ManageAddtionalFields = (): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    const userRole = useSelector((state: RootState) => state.user.userRole);

    useEffect(() => {
        if (userRole !== USER_ADMIN) {
            navigate("/home");
        }
    }, []);

    const columns = [
        {
            field: "name",
            title: "Name",
        },
        {
            field: "fieldType",
            title: "Typ",
            lookup: { T: "Text", C: "Wertetabelle" },
            initialEditValue: "T",
            render: (rowData: IAdditionalField) => rowData.fieldTypeDisplay,
        },
        {
            field: "mandatory",
            title: "Pflichtfeld",
            type: "boolean",
        },
    ];

    const [data, setData] = useState<IAdditionalField[]>([]);
    const [keyTable, setKeyTable] = useState<IAdditionalFieldKeytable[]>([]);

    const fetchData = () =>
        sendGetRequest({ path: "/request/additionalFields" }).then((response) => setData(response.data));

    useEffect(() => {
        fetchData();
    }, []);

    const [selectedRowId, setSelectedRowId] = useState<number>();
    const [newValue, setNewValue] = useState<string>("");

    const fetchKeyTableData = () =>
        sendGetRequest({
            path: "/request/additionalFields/keytable",
            params: { parent: selectedRowId !== undefined ? data[selectedRowId].name : "-1" },
        }).then((response) => setKeyTable(response.data));

    useEffect(() => {
        fetchKeyTableData();
    }, [selectedRowId]);

    return (
        <div style={{ margin: "1rem 4rem" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", marginBottom: "1rem" }}>
                Zusatzfelder verwalten
            </Typography>

            <div style={{ display: "grid", gridTemplateColumns: "65% 35%" }}>
                <Table
                    columnsDefinition={columns as ITableColumn[]}
                    identifier="manageAdditionalFields"
                    tableData={data as unknown as Record<string, string | number>[]}
                    customEdit={{
                        onRowAdd: (newData: IAdditionalField) =>
                            sendPutRequest({
                                path: "/request/additionalFields/modify",
                                body: {
                                    name: newData.name,
                                    nameNeu: newData.name,
                                    fieldType: newData.fieldType,
                                    mandatory: newData.mandatory ? "J" : "N",
                                },
                            }).then(fetchData),
                        onRowUpdate: (newData: IAdditionalField, oldData: IAdditionalField) =>
                            sendPutRequest({
                                path: "/request/additionalFields/modify",
                                body: {
                                    name: oldData.name,
                                    nameNeu: newData.name,
                                    fieldType: newData.fieldType,
                                    mandatory: newData.mandatory ? "J" : "N",
                                },
                            }).then(fetchData),
                        onRowDelete: (oldData: IAdditionalField) => {
                            setSelectedRowId(undefined);
                            return sendDeleteRequest({
                                path: "/request/additionalFields/remove",
                                params: { name: oldData.name },
                            }).then(fetchData);
                        },
                    }}
                    selection={{
                        onSelect: (rowData) => {
                            setSelectedRowId(data.findIndex((i) => i.name === rowData.name));
                        },
                        onDeselect: () => undefined,
                        selectedRows: [selectedRowId],
                        selector: "name",
                    }}
                />

                {selectedRowId !== undefined && data[selectedRowId].fieldType === "C" ? (
                    <Box sx={{ width: "100%", bgcolor: Colors.grey }}>
                        <List>
                            {keyTable.map((value: { key: string; value: string }) => (
                                <ListItem key={value.key}>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            sendDeleteRequest({
                                                path: "/request/additionalFields/kt/remove",
                                                params: { key: value.value, parent: data[selectedRowId].name },
                                            }).then(fetchKeyTableData);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <InputField value={value.value} label="" />
                                </ListItem>
                            ))}

                            <ListItem>
                                <IconButton aria-label="delete" sx={{ opacity: 0 }}>
                                    <DeleteIcon />
                                </IconButton>
                                <InputField
                                    value={newValue}
                                    setValue={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                        setNewValue(event.target.value)
                                    }
                                    label=""
                                />
                                <IconButton
                                    aria-label="save"
                                    onClick={() => {
                                        sendPutRequest({
                                            path: "/request/additionalFields/kt/modify",
                                            body: {
                                                key: newValue,
                                                value: newValue,
                                                parent: data[selectedRowId].name,
                                            },
                                        }).then(fetchKeyTableData);
                                        setNewValue("");
                                    }}
                                >
                                    <CheckRoundedIcon />
                                </IconButton>
                                <IconButton aria-label="clear" onClick={() => setNewValue("")}>
                                    <ClearRoundedIcon />
                                </IconButton>
                            </ListItem>
                        </List>
                    </Box>
                ) : null}
            </div>
        </div>
    );
};
