/* author: JESCHKE Moritz */

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ButtonSecondary } from "./Button";
import { Colors } from "./Colors";

interface IPopUp {
    title: string;
    content: React.JSX.Element;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setValue?: Dispatch<SetStateAction<string>>;
    dialogActionContentFromOtherComponet?: React.JSX.Element;
    onClick?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const PopUp = (props: IPopUp): React.JSX.Element => {
    return (
        <Dialog
            open={true}
            maxWidth={false}
            sx={{
                "& .MuiDialog-container": {
                    maxHeight: "calc(100vh - 4rem)",
                },
            }}
            componentsProps={{
                backdrop: {
                    style: { background: "transparent" },
                },
            }}
        >
            <DialogTitle sx={{ background: Colors.blue, color: Colors.white }}>{props.title}</DialogTitle>
            <DialogContent>{props.content}</DialogContent>
            <Divider sx={{ background: Colors.blue, height: "0.375rem" }} />
            <DialogActions>
                {props.dialogActionContentFromOtherComponet ? (
                    props.dialogActionContentFromOtherComponet
                ) : (
                    <ButtonSecondary title="Schließen" onClick={() => props.setOpen(false)} />
                )}
            </DialogActions>
        </Dialog>
    );
};
