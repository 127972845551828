import { Store } from "application/redux/Store";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./application/app/App";

ReactDOM.render(
    <React.StrictMode>
        {/* provide Redux to the whole application */}
        <Provider store={Store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
